import { BaseService } from "./BaseService";
import { EventEmitter, ServiceEvent, Feature, EventType, Status } from "../Events/ServiceEvents";

export class EventBaseService extends BaseService {
  protected eventEmitter: EventEmitter;

  constructor() {
    super();
    this.eventEmitter = EventEmitter.getInstance();
  }

  protected emitEvent(event: ServiceEvent): void {
    this.eventEmitter.emit(event);
  }

  protected async withEventEmission<T>(feature: Feature, type: EventType, operation: () => Promise<T>): Promise<T> {
    try {
      this.emitEvent({ feature, type, status: Status.InProgress });
      const result = await operation();
      this.emitEvent({ feature, type, status: Status.Success });
      return result;
    } catch (error) {
      this.emitEvent({ feature, type, status: Status.Failed });
      throw error;
    }
  }
}
