<template>
  <div>
    <CompanyInfoField
      :label="$t($ts.invoiceSettings.dueDate.title)"
      :description="$t($ts.invoiceSettings.dueDate.description)"
    >
      <v-text-field
        solo
        v-model="dueDateLength"
        :label="'30 ' + $t($ts.days)"
        type="number"
        @blur="saveChanges('dueDateLength', dueDateLength)"
      ></v-text-field>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField :label="$t($ts.invoiceSettings.tax.title)" :description="$t($ts.invoiceSettings.tax.description)">
      <v-text-field
        solo
        v-model="taxPercentage"
        type="number"
        @blur="saveChanges('taxPercentage', taxPercentage)"
      ></v-text-field>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.invoiceSettings.taxNumber.title)"
      :description="$t($ts.invoiceSettings.taxNumber.description)"
    >
      <v-text-field solo v-model="taxNumber" @blur="saveChanges('organizationNumber', taxNumber)"></v-text-field>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.invoiceSettings.bankAccount.title)"
      :description="$t($ts.invoiceSettings.bankAccount.description)"
    >
      <v-text-field solo v-model="bankAccount" @blur="saveChanges('bankAccount', bankAccount)"></v-text-field>
    </CompanyInfoField>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class GeneralSettings extends Vue {
  @Prop({ required: true }) user!: IUserDTO;

  dueDateLength: number = 30;
  taxPercentage: number = 25;
  taxNumber: string = "";
  bankAccount: string = "";

  created() {
    this.dueDateLength = this.user.dueDateLength;
    this.taxPercentage = this.user.taxPercentage;
    this.taxNumber = this.user.organizationNumber || "";
    this.bankAccount = this.user.bankAccount || "";
  }

  async saveChanges(field: string, value: any) {
    const payload: Partial<IUserDTO> = {
      [field]: value,
    };
    await userModule.updateUser(payload);
    dialogModule.addToDialogQueue({
      text: this.$t(this.$ts.messages.updated).toString(),
    });
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
