<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Loader from "@/components/global/Loader.vue";

@Component({
  name: "monthly-earnings",
  components: { Loader },
})
export default class MonthlyEarned extends Vue {
  @Prop(String) private title;
  @Prop({ default: false }) private hasLoaded: boolean;
  @Prop({ type: [Number, String] }) private amount: number | string;
  @Prop(String) private type;
  @Prop(String) private icon;
  @Prop({ default: 0 }) private marginLeft: number;
  @Prop({ default: 0 }) private marginRight: number | string;
  @Prop({ default: 2 }) private verticalCardAmount: number;
}
</script>

<template>
  <v-card flat height="100%">
    <v-card-text v-if="hasLoaded">
      <span style="color: #74706b; font-size: 22px"
        ><strong>{{ amount }}</strong
        >{{ type }} {{ title }}</span
      ><span style="color: #74706b"></span>
    </v-card-text>
    <div v-else>
      <loader></loader>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.statistics {
  padding: 10px;
  background: #fff;
  height: 127px;
  margin: 1%;
  border-radius: 7px;
  box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  h2 {
    font-size: 1.4em;
    color: #333;
    text-align: center;
    margin: 10px 0;
  }

  p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  img {
    display: block;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 1264px) {
  .statistics {
    width: 98% !important;
    margin: 5px 1% !important;
    padding: 0;
  }
}
</style>
