export interface JsonPatchOperation {
  op: "add" | "remove" | "replace" | "move" | "copy" | "test";
  path: string;
  value?: any;
  from?: string;
}

export function getPropertyName(propertyFunction) {
  return /\.([^\.;]+);?\s*\}$/.exec(propertyFunction.toString())[1];
}

export function createPatchObject<T>(
  partialObject: Partial<T>
): JsonPatchOperation[] {
  let payload: JsonPatchOperation[] = [];

  for (let prop in partialObject) {
    if (partialObject.hasOwnProperty(prop)) {
      let pathName = String(prop);
      let patchElement: JsonPatchOperation = {
        op: "replace",
        path: "/" + pathName,
        value: partialObject[prop],
      };

      payload.push(patchElement);
    }
  }
  return payload;
}
