<template>
  <div>
    <v-dialog max-width="660" v-model="showForm">
      <DynamicForm
        v-if="editingService"
        :data="editingService"
        :entity="$t(this.$ts.entities.service)"
        :metadata="appointmentTypeMetadata"
        :is-editing="isEditing"
        :is-creating="isCreating"
        :translation-path="nonReactiveTranslations"
        @cancel="cancelEdit"
        @save="saveTreatment"
        @create="saveTreatment"
      >
        <!-- <template #custom-fields>
          <CompanyInfoField
            label="Your Custom Field"
            description="Your custom field description"
          >
            <v-text-field />
          </CompanyInfoField>
          <CompanyInfoField
            :label="$t($ts.createService.color)"
            :description="$t($ts.createService.colorDescription)"
          >
            <v-color-picker
              v-model="editingService.color"
              dot-size="25"
              hide-mode-switch
              show-swatches
              hide-sliders
              hide-inputs
              hide-canvas
              swatches-max-height="100"
            />
          </CompanyInfoField>
          <v-divider />
        </template> -->
      </DynamicForm>
    </v-dialog>

    <!-- <div v-if="isLoading">
      <div v-for="i in getSkeletonCount()" :key="i">
        <v-skeleton-loader class="mb-4" type="list-item-avatar-three-line"></v-skeleton-loader>
      </div>
    </div> -->

    <treatment-type-table
      :services="services || []"
      :categories="formattedCategories"
      :extra-services="extraServices"
      :is-extra="isOnboarding"
      :loading="isLoading"
      @edit="startEdit"
      @delete="confirmDelete"
      @reorder="reorderTreatment"
      @category-change="updateCategory"
      @extra-services-change="updateExtraServices"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { IAppointmentTypeDTO, IPostServiceCategoryDTO } from "@shared/types";
import { DialogType, type IDialog } from "@/store/dialog";
import TreatmentTypeTable from "./components/TreatmentTypeTable.vue";
import Loader from "@/components/global/Loader.vue";
import DynamicForm from "./components/DynamicForm.vue";
import { IFieldMetadata } from "@shared/types";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";
import { userModule } from "@/store/modules/user/userModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { serviceCategoryModule } from "@/store/modules/serviceModule/serviceCategoryModule";
import { LocalStorageService } from "@/services/LocalStorageService";

interface Category {
  id: string;
  title: string;
}

@Component({
  name: "treatment-types",
  components: {
    Loader,
    DynamicForm,
    TreatmentTypeTable,
  },
})
export default class TreatmentTypes extends Vue {
  @Prop({ default: false }) public isOnboarding!: boolean;
  @Prop({ default: false }) public extra!: boolean;

  get extraServices(): IAppointmentTypeDTO[] {
    return serviceModule.extraServices;
  }

  get appointmentType(): IAppointmentTypeDTO[] {
    return serviceModule.appointmentTypes;
  }

  get categories(): any[] {
    return serviceCategoryModule.categories;
  }

  get staffMembers(): any[] {
    return staffMemberModule.staffMembers;
  }

  get appointmentTypeMetadata(): any[] {
    return metadataModule.appointmentTypeMetadata;
  }

  public async getCurrentUser() {
    return await userModule.getCurrentUser();
  }

  public async getAppointmentTypes(extra: boolean) {
    return await serviceModule.getAppointmentTypes(extra);
  }

  public async getCategories() {
    return await serviceCategoryModule.getCategories();
  }

  public async editAppointmentType(treatment) {
    return await serviceModule.editService(treatment);
  }

  public async addNewAppointmentType(treatment) {
    return await serviceModule.postService(treatment);
  }

  public async deleteAppointmentType(id) {
    return await serviceModule.deleteService(id);
  }

  public async changeServiceOrder(payload) {
    return await serviceModule.changeServiceOrder(payload);
  }

  public async changeCategoryOfService(payload: IPostServiceCategoryDTO): Promise<void> {
    return await serviceCategoryModule.putCategory(payload);
  }

  public async changeExtraService(payload: { serviceId: string; extraServices: IAppointmentTypeDTO[] }): Promise<void> {
    return await serviceModule.changeExtraService(payload);
  }

  private isLoading = true;
  private showForm = false;
  private editingService: Partial<IAppointmentTypeDTO> | null = null;
  private isEditing = false;
  private isCreating = false;

  get services(): IAppointmentTypeDTO[] {
    return this.extra ? this.extraServices : this.appointmentType || [];
  }

  get formattedCategories(): Category[] {
    return (this.categories || []).map((cat) => ({
      id: String(cat.id),
      title: cat.title,
    }));
  }

  get hasMultipleStaff() {
    return (this.staffMembers || []).length > 1;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.appointmentTypeFormDTO });
  }

  async loadData() {
    this.isLoading = true;
    await Promise.all([this.getAppointmentTypes(this.extra), this.getCategories()]);
    this.isLoading = false;
  }

  toggleForm() {
    this.editingService = { discountPrice: -1 };
    this.isCreating = true;
    this.showForm = !this.showForm;
  }

  async startEdit(service: IAppointmentTypeDTO) {
    this.isEditing = true;
    this.editingService = service;
    this.showForm = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.isCreating = false;
    this.editingService = null;
    this.showForm = false;
  }

  async saveTreatment(treatment: Partial<IAppointmentTypeDTO>) {
    this.isLoading = true;
    try {
      if (this.isEditing) {
        await this.editAppointmentType(treatment);
      } else {
        treatment.isExtraService = this.extra;
        await this.addNewAppointmentType(treatment);
      }
      this.cancelEdit();
    } finally {
      await this.loadData();
    }
  }

  confirmDelete(id: string) {
    const dialog: IDialog = {
      text: this.$t(this.$ts.dialog.confirmDeleteService).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.dialog.deleteServiceHeader).toString(),
      action: () => this.deleteTreatment(id),
    };
    dialogModule.addToDialogQueue(dialog);
  }

  async deleteTreatment(id: string) {
    await this.deleteAppointmentType(id);
  }

  async reorderTreatment(payload: { serviceId: string; direction: number }) {
    await this.changeServiceOrder({
      serviceId: payload.serviceId,
      order: payload.direction,
    });
  }

  async updateCategory(payload: IPostServiceCategoryDTO) {
    await this.changeCategoryOfService(payload);
  }

  async updateExtraServices(payload: { serviceId: string; extraServices: IAppointmentTypeDTO[] }) {
    await this.changeExtraService(payload);
  }

  async created() {
    await this.loadData();
  }

  getSkeletonCount(): number {
    return LocalStorageService.getItemCountOrDefault("services");
  }
}
</script>
