<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="closeDialog" persistent>
    <v-card class="appointment-card">
      <v-card-title style="background-color: #866afe" class="white--text">
        <v-icon left color="white">mdi-calendar-clock</v-icon>
        <span class="headline">{{ $t("Appointment") }} #{{ appointment.id }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-container fluid>
          <!-- Date and Time Section -->
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-card outlined class="time-card">
                <v-card-text>
                  <div class="d-flex align-center mb-2">
                    <v-icon style="color: #866afe" class="mr-2">mdi-calendar</v-icon>
                    <span class="subtitle-1">{{ formattedDate }}</span>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-clock-outline</v-icon>
                    <span class="subtitle-1">{{ formattedTime }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Customer Info -->
            <v-col cols="12" sm="6" v-if="appointment.customerName">
              <v-card outlined class="customer-card">
                <v-card-text>
                  <div class="d-flex align-center mb-2">
                    <v-icon style="color: #866afe" class="mr-2">mdi-account</v-icon>
                    <span class="subtitle-1">{{ appointment.customerName }}</span>
                  </div>
                  <div v-if="appointment.customerPhone" class="d-flex align-center mb-2">
                    <v-icon style="color: #866afe" class="mr-2">mdi-phone</v-icon>
                    <span>{{ appointment.customerPhone }}</span>
                  </div>
                  <div v-if="appointment.customerEmail" class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-email</v-icon>
                    <span>{{ appointment.customerEmail }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="appointment.staffMemberName" class="mb-4">
            <v-col cols="12">
              <v-card outlined class="staff-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-account-tie</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t("Staff Member") }}</div>
                      <div>{{ appointment.staffMemberName }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Services Section -->
          <v-row v-if="services.length > 0" class="mb-4">
            <v-col cols="12">
              <v-card outlined class="service-card">
                <v-card-title class="subtitle-1">
                  <v-icon style="color: #866afe" class="mr-2">mdi-briefcase</v-icon>
                  {{ $t("Services") }}
                </v-card-title>
                <v-card-text>
                  <v-chip
                    v-for="service in services"
                    :key="service"
                    class="mr-2 mb-2"
                    style="border-color: #866afe; color: #866afe"
                    outlined
                  >
                    {{ service }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Price Section -->
          <v-row v-if="appointment.appointmentTypePrice !== undefined" class="mb-4">
            <v-col cols="12">
              <v-card outlined class="price-card">
                <v-card-text class="d-flex align-center">
                  <v-icon style="color: #866afe" class="mr-2">mdi-cash</v-icon>
                  <span class="subtitle-1">{{ $t("Price") }}:</span>
                  <span class="ml-2 font-weight-bold" style="color: #866afe">
                    {{ formatCurrency(appointment.appointmentTypePrice) }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Comments Section -->
          <v-row>
            <v-col cols="12">
              <v-expansion-panels v-if="hasComments">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-icon style="color: #866afe" class="mr-2">mdi-comment-multiple</v-icon>
                    {{ $t("Comments and Notes") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- Customer Comment -->
                    <div v-if="appointment.comment" class="mb-4">
                      <div class="subtitle-1 mb-2" style="color: #866afe">
                        <v-icon style="color: #866afe" small class="mr-1">mdi-account-comment</v-icon>
                        {{ $t("Customer Comment") }}
                      </div>
                      <v-card outlined class="comment-card">
                        <v-card-text>{{ appointment.comment }}</v-card-text>
                      </v-card>
                    </div>

                    <!-- Admin Note -->
                    <div v-if="appointment.adminNote">
                      <div class="subtitle-1 mb-2" style="color: #866afe">
                        <v-icon style="color: #866afe" small class="mr-1">mdi-note-text</v-icon>
                        {{ $t("Administrator Note") }}
                      </div>
                      <v-card outlined class="admin-note-card">
                        <v-card-text>{{ appointment.adminNote }}</v-card-text>
                      </v-card>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn style="color: #866afe" text @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("Close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate, formatTime } from "@/types/formatDateHelper";
import { userModule } from "@/store/modules/user/userModule";
import { IAppointmentInfoDTO } from "@shared/types";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";

@Component
export default class AppointmentInfoDialog extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean;
  @Prop({ type: Object, required: true }) appointment!: IAppointmentInfoDTO;

  get dialog(): boolean {
    return this.value;
  }
  set dialog(val: boolean) {
    this.$emit("input", val);
  }

  get hasComments(): boolean {
    return Boolean(this.appointment.comment || this.appointment.adminNote);
  }

  get formattedDate(): string {
    if (this.appointment.year && this.appointment.month && this.appointment.day) {
      return formatDate(new Date(this.appointment.year, this.appointment.month - 1, this.appointment.day));
    }
    return "";
  }

  get formattedTime(): string {
    if (this.appointment.hour !== undefined && this.appointment.minute !== undefined) {
      const startTime = formatTime(this.appointment.hour, this.appointment.minute);
      const endDate = new Date(
        this.appointment.year,
        this.appointment.month - 1,
        this.appointment.day,
        this.appointment.hour,
        this.appointment.minute + (Number(this.appointment.appointmentDuration) || 0)
      );
      const endTime = formatTime(endDate.getHours(), endDate.getMinutes());
      return `${startTime} - ${endTime}`;
    }
    return "";
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user?.currency || "USD",
    }).format(value);
  }

  get services(): string[] {
    return (this.appointment.serviceIds || []).map((id) => {
      const service = serviceModule.appointmentTypes.find((s) => s.id === id);
      return service ? service.title : `Service #${id}`;
    });
  }

  closeDialog() {
    this.$emit("input", false);
  }
}
</script>

<style scoped>
.appointment-card {
  border-radius: 8px;
}

.time-card,
.customer-card,
.service-card,
.price-card,
.comment-card,
.admin-note-card {
  border-radius: 4px;
  transition: all 0.3s;
}

.time-card:hover,
.customer-card:hover,
.service-card:hover,
.price-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  font-weight: 500;
  font-size: 1.5rem;
}

.subtitle-1 {
  font-weight: 500;
}

.comment-card,
.admin-note-card {
  background-color: #f8f9fa;
}

.service-card .v-chip {
  font-size: 0.875rem;
}

.price-card {
  background-color: #f5f5f5;
}

.v-card-title {
  word-break: break-word;
}

.custom-primary {
  color: #866afe !important;
}

.custom-primary-bg {
  background-color: #866afe !important;
}

.v-expansion-panel-header {
  color: #866afe !important;
}
</style>
