<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import Loader from "../../components/global/Loader.vue";
import { IDialog } from "@/store/dialog";
import { IAppointmentPatch } from "@/store/modules/appointments/appointmentModule";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { formatDate, FormatSpaceType, formatTime } from "../../types/formatDateHelper";
import InvoiceCreator from "../../components/InvoiceCreator.vue";
import InvoicesList from "../../components/InvoicesList.vue";
import AppointmentMenu from "../../components/AppointmentMenu.vue";
import PaymentList from "../../components/PaymentList.vue";
import PaymentCreator from "../../components/global/PaymentCreator.vue";
import { IAppointmentDTO, IAppointmentInfoDTO, IAuditLogDTO, IUserDTO, ILedgerEntryDTO } from "@shared/types";
import { formatDateStringHour } from "../../Utilities/dateUtility";
import AppointmentSummary from "@/components/global/AppointmentSummary.vue";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { Action } from "vuex-class";
import { DialogType } from "@/store/modules/dialogModule/dialogModule";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import store from "@/store/store";
import CreateLedgerEntryDialog from "@/components/global/CreateLedgerEntryDialog.vue";
import LedgerEntriesOverview from "@/components/global/LedgerEntriesOverview.vue";

@Component({
  name: "Appointment",
  components: {
    AppointmentSummary,
    PaymentCreator,
    PaymentList,
    AppointmentMenu,
    InvoicesList,
    InvoiceCreator,
    Loader,
    CustomerModal,
    Sidebar,
    HeaderBar,
    CreateLedgerEntryDialog,
  },
})
export default class Appointment extends Vue {
  public appointment: IAppointmentInfoDTO = null;
  public newPayment: boolean = false;
  public movingAppointment: boolean = false;
  public isLoadingPayments: boolean = false;
  public showLedgerEntryDialog: boolean = false;

  get user() {
    return userModule.user;
  }

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get invoiceStatuses() {
    if (this.user.enablePaymentModule) {
      return [
        this.$t(this.$ts.appointment.status.ny),
        this.$t(this.$ts.appointment.status.invoiceCreated),
        this.$t(this.$ts.appointment.status.invoiceSent),
        this.$t(this.$ts.appointment.status.invoicePaid),
        this.$t(this.$ts.appointment.status.ferdig),
      ];
    } else {
      return [this.$t(this.$ts.appointment.status.ny), this.$t(this.$ts.appointment.status.ferdig)];
    }
  }

  async loadAppointment() {
    console.log("Loading appointment...");
    const params: any = this.$route.params;
    let appointmentNumber: number = params.appointmentNumber;

    try {
      const appointmentResult = await appointmentsModule.getAppointmentByNumber(appointmentNumber);
      console.log("Appointment result: ", appointmentResult);
      this.appointment = appointmentResult;

      //this.invoices = await this.getInvoicesForAppointment(this.appointment.id);
      //this.isLoadingPayments = true;
      //this.payments = await this.getPaymentsForAppointment(this.appointment.id);
      //this.isLoadingPayments = false;
    } catch (e) {
      let dialog: IDialog = {
        text: "Fant ikke denne bestillingen.",
      };
      await dialogModule.addToDialogQueue(dialog);
      this.goBack();
    }
  }

  async created() {
    await this.loadAppointment();
    const params: any = this.$route.params;
    let appointmentNumber: number = params.appointmentNumber;

    await auditLogModule.postAudit({
      dto: {
        message: " went to appointment page - appoinment: " + appointmentNumber,
        type: 4, // PageVisit
        action: 3, // Info
        comment: "",
        hideForUser: true,
      },
    });
  }

  goBack() {
    appointmentsModule.appointmentSource = "";

    if (appointmentsModule.appointmentSource) {
      this.$router.push("/" + appointmentsModule.appointmentSource).catch(() => {});
    } else {
      this.$router.push("/bestillinger").catch(() => {});
    }
  }

  formatDateCorrectly(day: string | number, month: string | number, year: string | number): string {
    let dateStr = month + "/" + day + "/" + year;
    let datObject = new Date(dateStr);
    return formatDate(datObject, FormatSpaceType.DOT);
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    if (this.user?.timeFormat === "12h") {
      const hourNum = typeof hour === 'string' ? parseInt(hour) : hour;
      const minuteNum = typeof minute === 'string' ? parseInt(minute) : minute;
      
      const period = hourNum >= 12 ? this.$t(this.$ts.appointmentView.timeFormat.pm) : this.$t(this.$ts.appointmentView.timeFormat.am);
      const hour12 = hourNum % 12 || 12; // Convert 0 to 12 for 12 AM
      
      return `${hour12}:${minuteNum.toString().padStart(2, '0')} ${period}`;
    }
    
    return formatTime(hour, minute);
  }

  async updateAdminComment(event, selectedEvent: IAppointmentInfoDTO) {
    let update: Partial<IAppointmentDTO> = {
      adminNote: selectedEvent.adminNote,
    };

    const payload: IAppointmentPatch = {
      appointmentId: selectedEvent.id,
      patch: update,
    };

    await appointmentsModule.patchAppointment(payload);
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  getColor(eventId) {
    if (eventId == "Ny") return "#E0E0E0";
    if (eventId == "Faktura opprettet") return "#a5c6d9";
    if (eventId == "Faktura sendt") return "#a5c6d9";
    if (eventId == "Ferdig") return "#a5d9af";
    return "grey";
  }

  async onFinished() {
    this.newPayment = false;
    //this.payments = await this.getPaymentsForAppointment(this.appointment.id);
    await this.loadAppointment();
  }

  moveAppointment() {
    this.movingAppointment = true;
  }

  async onMovedAppointment() {
    this.movingAppointment = false;
    await this.loadAppointment();
  }

  async onChangeCustomer() {
    await this.loadAppointment();
  }

  deleteAppointment() {}

  onDeleted() {
    this.goBack();
  }

  get appointmentStart() {
    const chars = "2020-06-07T07:30:00";

    const start = new Date(
      this.appointment.year,
      this.appointment.month - 1,
      this.appointment.day,
      this.appointment.hour,
      this.appointment.minute,
      0
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    //@ts-ignore
    const localISOTime = new Date(start - tzoffset).toISOString().slice(0, -1);

    return localISOTime.substr(0, chars.length);
  }

  formatAppointmentEnd() {
    let start = new Date(
      this.appointment.year,
      this.appointment.month - 1,
      this.appointment.day,
      this.appointment.hour,
      this.appointment.minute,
      0
    );
    //@ts-ignore
    let end = new Date(
      //@ts-ignore
      start.getTime() + this.appointment.appointmentDuration * 1000 * 60
    );
    let h = end.getHours();
    let m = end.getMinutes();
    return this.formatTimeCorrectly(h, m);
  }

  async updateInvoiceStatus(event, invoice) {
    let update: Partial<IAppointmentDTO> = {
      status: event,
    };

    const payload: IAppointmentPatch = {
      appointmentId: invoice.id,
      patch: update,
    };

    await appointmentsModule.patchAppointment(payload);
  }

  async markAsUseLedgerEntry() {
    const handleConfirm = async () => {
      try {
        await ledgerEntriesModule.createFromAppointment({
          appointmentId: this.appointment.id,
        });
        await this.loadAppointment();
      } catch (error) {
        await dialogModule.addToDialogQueue({
          header: "Error",
          text: "Failed to create ledger entry. " + error.message,
          type: DialogType.Message,
        });
      }
    };

    const dialog: IDialog = {
      header: String(this.$t(this.$ts.appointment.markAsUnpaid)),
      text: String(this.$t(this.$ts.appointment.markAsUnpaidWarning)),
      type: DialogType.YesNo,
      action: handleConfirm,
      cancelAction: () => {}, // Empty function for cancel
    };

    await dialogModule.addToDialogQueue(dialog);
  }

  onLedgerEntrySave(entry: ILedgerEntryDTO) {
    // TODO: Implement save functionality when needed
    console.log("Ledger entry to save:", entry);
  }

  goToCustomer() {
    if (this.appointment && this.appointment.customerId && this.appointment.customerId !== 0 && this.appointment.customerId !== -1) {
      this.$router.push(`/kunder/${this.appointment.customerId}`);
    }
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: this.user?.currency || "USD",
    }).format(value);
  }
}
</script>

<template>
  <loader v-if="appointment == null"></loader>
  <div v-else>
    <v-app-bar class="hidden-lg-and-up" color="white" fixed top>
      <v-container fluid>
        <v-row fluid class="flex-wrap flex-shrink-1" justify="space-between">
          <v-col cols="auto" align="center" jusitfy="center">
            <v-btn class="mobile-header-button" icon large @click="goBack()">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>

          <v-spacer> </v-spacer>

          <v-col cols="auto" align="center" jusitfy="center">
            <appointment-menu
              :appointmentDate="appointmentStart"
              :appointment="appointment"
              :appointmentId="appointment.id"
              @deleted="onDeleted"
              @close="onMovedAppointment"
              @confirmed="onMovedAppointment"
              @changecustomer="onChangeCustomer"
              :displayShowInCalendar="true"
              :spread="true"
              :large="true"
            >
            </appointment-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-row justify-md="center" class="mt-10">
      <v-col xs="12" lg="12">
        <v-card class="appointment-card">
          <v-card-title style="background-color: #866afe" class="white--text">
            <v-icon left color="white">mdi-calendar-check</v-icon>
            <span class="headline">{{ $t($ts.entities.appointment) }} #{{ appointment.number }}</span>
            <v-spacer></v-spacer>
            <appointment-menu
              class="hidden-md-and-down"
              :appointmentDate="appointmentStart"
              :appointment="appointment"
              :appointmentId="appointment.id"
              @deleted="onDeleted"
              @close="onMovedAppointment"
              @confirmed="onMovedAppointment"
              @changecustomer="onChangeCustomer"
              :displayShowInCalendar="true"
              :spread="true"
              :large="true"
              color="white"
            >
            </appointment-menu>
          </v-card-title>

          <v-card-text class="pt-4">
            <v-container fluid>
              <!-- Status Section -->
              <v-row class="mb-4">
                <v-col cols="12">
                  <v-card outlined class="status-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-flag</v-icon>
                        <div class="w-100">
                          <div class="subtitle-1">{{ $t($ts.appointment.appointmentStatus) }}</div>
                          <v-select
                            v-if="appointment != null && appointment.status != null"
                            :color="getColor(appointment.status)"
                            chips
                            dense
                            hide-details
                            v-model="appointment.status"
                            :items="invoiceStatuses"
                            @change="updateInvoiceStatus($event, appointment)"
                            :readonly="!canWriteAppointmentsAndClients"
                            :disabled="!canWriteAppointmentsAndClients"
                            class="mt-1"
                          >
                            <template v-slot:selection="data">
                              <v-chip :color="getColor(data.item)">
                                <span>{{ data.item }}</span>&nbsp;
                              </v-chip>
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Date and Time Section -->
              <v-row class="mb-4">
                <v-col cols="12" sm="6">
                  <v-card outlined class="time-card">
                    <v-card-text>
                      <div class="d-flex align-center mb-2">
                        <v-icon style="color: #866afe" class="mr-2">mdi-calendar</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.date) }}</div>
                          <div>{{ formatDateCorrectly(appointment.day, appointment.month, appointment.year) }}</div>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-clock-outline</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.appointment.time) }}</div>
                          <div>{{ formatTimeCorrectly(appointment.hour, appointment.minute) }} - {{ formatAppointmentEnd() }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Customer Section -->
                <v-col cols="12" sm="6">
                  <v-card outlined class="customer-card" 
                    @click="appointment.customerId && appointment.customerId !== 0 && appointment.customerId !== -1 ? goToCustomer() : null" 
                    :style="appointment.customerId && appointment.customerId !== 0 && appointment.customerId !== -1 ? 'cursor: pointer;' : ''">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-account</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.appointment.customer) }}</div>
                          <div class="d-flex align-center">
                            {{ appointment.customerName }}
                            <v-icon v-if="appointment.customerId && appointment.customerId !== 0 && appointment.customerId !== -1" small class="ml-1" color="#866afe">mdi-open-in-new</v-icon>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Appointment Type Section -->
              <v-row class="mb-4">
                <v-col cols="12" sm="6">
                  <v-card outlined class="type-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-tag</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.appointment.service) }}</div>
                          <div>{{ appointment.appointmentName }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Price Section -->
                <v-col cols="12" sm="6">
                  <v-card outlined class="price-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-cash</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.price) }}</div>
                          <div>{{ formatCurrency(appointment.appointmentTypePrice) }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Notes Section -->
              <v-row class="mb-4" v-if="appointment.adminNote">
                <v-col cols="12">
                  <v-card outlined class="notes-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-text</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.appointment.adminNote) }}</div>
                          <div>{{ appointment.adminNote }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Created Section -->
              <v-row v-if="!appointment.isCustom && appointment.created != null">
                <v-col cols="12">
                  <v-card outlined class="created-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-clock-outline</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.appointment.created) }}</div>
                          <div>{{ formatDateStringHour(appointment.created) }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Custom Event Section -->
              <v-row v-if="appointment.isCustom">
                <v-col cols="12">
                  <v-card outlined class="event-card">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-icon style="color: #866afe" class="mr-2">mdi-star</v-icon>
                        <div>
                          <div class="subtitle-1">{{ $t($ts.event) }}</div>
                          <div>{{ appointment.appointmentName }}</div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="false">
      <!-- <v-col>
        <v-card>
          <LedgerEntriesOverview
            :appointmentId="appointment.id"
            :useLedgerEntry="appointment.useLedgerEntry"
            :customer-name="appointment.customerName"
            :customer-email="appointment.customerEmail"
            :appointment-number="appointment.number.toString()"
            :customer-id="appointment.customerId"
          />
        </v-card>
      </v-col> -->
      <!-- <v-col v-if="user.enablePaymentModule">
        <v-card>
          <v-card-title>{{ $t("payment") }}</v-card-title>

          <v-btn right fab color="success" @click="newPayment = !newPayment"><v-icon>mdi-plus</v-icon></v-btn>
          <v-scroll-y-transition>
            <div class="mt-5">
              <payment-creator
                v-if="newPayment"
                :defaultValue="appointment.appointmentTypePrice"
                :appointment="appointment"
                @finished="onFinished"
              ></payment-creator>
            </div>
          </v-scroll-y-transition>

           <payment-list
            :isLoading="isLoadingPayments"
            @invoice="onFinished"
            :showAllInfo="false"
            :payments="payments"
            class="mt-5"
          ></payment-list>
        </v-card>
      </v-col>  -->
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.appointment-card {
  border-radius: 8px;
}

.status-card,
.time-card,
.customer-card,
.type-card,
.price-card,
.notes-card,
.created-card,
.event-card {
  border-radius: 4px;
  transition: all 0.3s;
}

.status-card:hover,
.time-card:hover,
.customer-card:hover,
.type-card:hover,
.price-card:hover,
.notes-card:hover,
.created-card:hover,
.event-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  font-weight: 500;
  font-size: 1.5rem;
}

.subtitle-1 {
  font-weight: 500;
}

.v-card-title {
  word-break: break-word;
}

.w-100 {
  width: 100%;
}
</style>
