<template>
  <v-container fluid class="account-dashboard">
    <div class="tabs-container">
      <v-tabs v-model="activeTab" height="64" slider-color="primary" class="elevated-tabs">
        <v-tab class="text-button" data-cy="overview-tab">
          <v-icon left small>mdi-view-dashboard</v-icon>
          {{ $t($ts.sidebar.overview) }}
        </v-tab>
        <v-tab v-for="account in moneyAccounts" :key="account.id" :data-cy="'account-tab-' + account.id" class="text-button">
          <v-icon left small>{{ getPaymentMethodIcon(account.paymentMethod) }}</v-icon>
          {{ account.name }}
        </v-tab>
        <v-tab 
          class="text-button" 
          @click.prevent.native="showCreateDialog = true" 
          data-cy="create-new-tab"
          color="deep-purple"
        >
          <v-icon left small>mdi-plus</v-icon>
          <span class="deep-purple--text">{{ $t($ts.actions.create) }}</span>
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="activeTab" class="transparent-tabs">
      <!-- Overview Tab Content -->
      <v-tab-item>
        <v-card flat class="transparent-card">
          <!-- Summary Cards -->
          <v-card-text>
            <v-row class="summary-cards">
              <v-col v-for="(card, index) in summaryCards" 
                     :key="index" 
                     cols="12" md="3" sm="6"
              >
                <v-card outlined :class="['modern-card', card.bgClass]">
                  <v-card-text class="text-center pa-4">
                    <div class="d-flex align-center justify-center mb-2">
                      <v-icon :color="card.iconColor" size="24" class="mr-2">{{ card.icon }}</v-icon>
                      <span class="text-subtitle-1 font-weight-medium">{{ card.title }}</span>
                    </div>
                    <div class="text-h4 font-weight-bold" :class="card.textClass">{{ card.value }}</div>
                    <div class="text-caption" :class="card.textClass">{{ card.subtitle }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Transaction Distribution Bar (Overview) -->
            <v-row class="mt-6">
              <v-col cols="12">
                <div class="text-subtitle-1 font-weight-medium mb-2">{{ $t($ts.moneyAccountOverview.transactionDistribution) }}</div>
                <div class="transaction-bar">
                  <div class="incoming" :style="{ width: incomingPercentage + '%' }"></div>
                  <div class="outgoing" :style="{ width: outgoingPercentage + '%' }"></div>
                </div>
                <div class="d-flex justify-space-between mt-1">
                  <span class="text-caption income-text">{{ $t($ts.moneyAccountOverview.incoming) }}: {{ formatCurrency(accountsSummary?.totalIncomingToday || 0) }} ({{ incomingPercentage }}%)</span>
                  <span class="text-caption expense-text">{{ $t($ts.moneyAccountOverview.outgoing) }}: {{ formatCurrency(accountsSummary?.totalOutgoingToday || 0) }} ({{ outgoingPercentage }}%)</span>
                </div>
              </v-col>
            </v-row>

            <!-- Account Grid -->
            <v-row class="account-grid">
              <v-col v-for="account in moneyAccounts" 
                     :key="account.id" 
                     cols="12" md="6" lg="4"
              >
                <v-card outlined class="modern-card">
                  <v-card-title class="d-flex justify-space-between align-center pa-4">
                    <div>
                      <span class="text-h6 font-weight-bold">{{ account.name }}</span>
                      <div class="mt-1">
                        <v-chip :color="getPaymentMethodColor(account.paymentMethod)" small label class="mr-2 white--text">
                          {{ getPaymentMethodText(account.paymentMethod) }}
                        </v-chip>
                        <v-chip 
                          small 
                          :class="[
                            'percentage-chip white--text',
                            getAccountTodayNetChange(account.id) >= 0 ? 'income-chip' : 'error'
                          ]"
                          label 
                        >
                          <v-icon small left color="white">{{ getAccountTodayNetChange(account.id) >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}</v-icon>
                          {{ calculateDailyChangePercentage(account.id) }}%
                        </v-chip>
                      </div>
                    </div>
                    <v-btn 
                      icon 
                      color="success" 
                      small 
                      class="ml-2" 
                      @click="openAddMoneyDialog(account.id)"
                      v-tooltip="$t($ts.moneyAccountOverview.addMoneyDescription)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-text class="pa-4">
                    <v-row>
                      <v-col cols="12">
                        <div class="d-flex justify-space-between align-center mb-4 primary--text balance-total primary lighten-5">
                          <span class="text-subtitle-1 font-weight-medium">{{ $t($ts.moneyAccountOverview.totalBalance) }}</span>
                          <span class="text-h5 font-weight-bold">{{ formatCurrency(getAccountBalance(account.id)) }}</span>
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <div class="stat-box income-bg pa-3 rounded">
                          <div class="d-flex align-center mb-1">
                            <v-icon small color="success" class="mr-1">mdi-arrow-up-circle</v-icon>
                            <span class="text-caption">{{ $t($ts.moneyAccountOverview.totalIncomingToday) }}</span>
                          </div>
                          <div class="text-subtitle-1 income-text font-weight-medium">
                            {{ formatCurrency(getAccountTodayIncoming(account.id)) }}
                          </div>
                          <div class="text-caption income-text">{{ calculateIncomeRatio(account.id) }}% {{ $t($ts.moneyAccountOverview.changeFromTotal) }}</div>
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <div class="stat-box expense-bg pa-3 rounded">
                          <div class="d-flex align-center mb-1">
                            <v-icon small color="error" class="mr-1">mdi-arrow-down-circle</v-icon>
                            <span class="text-caption">{{ $t($ts.moneyAccountOverview.totalOutgoingToday) }}</span>
                          </div>
                          <div class="text-subtitle-1 expense-text font-weight-medium">
                            {{ formatCurrency(getAccountTodayOutgoing(account.id)) }}
                          </div>
                          <div class="text-caption expense-text">{{ calculateOutgoingRatio(account.id) }}% {{ $t($ts.moneyAccountOverview.changeFromTotal) }}</div>
                        </div>
                      </v-col>

                      <v-col cols="12" class="mt-3">
                        <div class="stat-box grey lighten-4 pa-3 rounded">
                          <div class="d-flex justify-space-between align-center">
                            <div>
                              <div class="text-caption mb-1">{{ $t($ts.moneyAccountOverview.totalNetChangeToday) }}</div>
                              <div class="text-subtitle-1" :class="getAccountTodayNetChange(account.id) >= 0 ? 'income-text' : 'expense-text'">
                                {{ formatCurrency(getAccountTodayNetChange(account.id)) }}
                              </div>
                            </div>
                            <v-icon large :color="getAccountTodayNetChange(account.id) >= 0 ? 'success' : 'error'">
                              {{ getAccountTodayNetChange(account.id) >= 0 ? 'mdi-chart-line-variant' : 'mdi-chart-line-variant-decrease' }}
                            </v-icon>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Account Transaction Distribution Bar -->
                    <div class="mt-4">
                      <div class="text-caption font-weight-medium mb-1">{{ $t($ts.moneyAccountOverview.transactionDistribution) }}</div>
                      <div class="transaction-bar small">
                        <div class="incoming" :style="{ width: accountIncomingPercentage(account.id) + '%' }"></div>
                        <div class="outgoing" :style="{ width: accountOutgoingPercentage(account.id) + '%' }"></div>
                      </div>
                    </div>

                    <div class="text-caption grey--text mt-3">{{ account.description }}</div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="editAccount(account)" class="px-4">
                      <v-icon left small>mdi-pencil</v-icon>
                      {{ $t($ts.actions.edit) }}
                    </v-btn>
                    <v-btn text color="error" @click="deleteAccount(account)" class="px-4">
                      <v-icon left small>mdi-delete</v-icon>
                      {{ $t($ts.actions.delete) }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Individual Account Tabs -->
      <v-tab-item v-for="account in moneyAccounts" :key="account.id">
        <ledger-entries-overview :money-account-id="account.id" />
      </v-tab-item>
    </v-tabs-items>

    <!-- Create/Edit Dialog -->
    <v-dialog v-model="showCreateDialog" max-width="600px">
      <DynamicForm
        v-if="currentAccount"
        :data="currentAccount"
        :entity="$t($ts.entities.cashRegister)"
        :metadata="moneyAccountMetadata"
        :is-editing="isEditing"
        :is-creating="!isEditing"
        :translation-path="nonReactiveTranslations"
        :loading="loading"
        @cancel="closeDialog"
        @save="saveAccount"
        @create="saveAccount"
      >
        <template v-slot:custom-fields>
          <CompanyInfoField :label="$t($ts.paymentMethod.title)">
            <v-select
              v-model="currentAccount.paymentMethod"
              :items="paymentMethodOptions"
              item-text="text"
              item-value="value"
              :label="$t($ts.paymentMethod.title)"
              outlined
              dense
              clearable
              hide-details
            />
          </CompanyInfoField>
          <v-divider class="my-4" />
        </template>
      </DynamicForm>
    </v-dialog>
    <!-- Dialog Component -->
    <!-- <account-dialog
      v-model="showCreateDialog"
      :account="currentAccount"
      :is-editing="isEditing"
      @close="closeDialog"
      @save="saveAccount"
    /> -->
    
    <!-- Manual Ledger Entry Dialog -->
    <ManualLedgerEntryDialog
      v-model="showAddMoneyDialog"
      :money-account-id="selectedAccountId"
      @save="onLedgerEntrySave"
    />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { IMoneyAccountDTO, PaymentMethod, ILedgerEntryDTO } from "@shared/types";
import { DialogType, type IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import LedgerEntriesOverview from "@/components/global/LedgerEntriesOverview.vue";
import ManualLedgerEntryDialog from "@/components/global/ManualLedgerEntryDialog.vue";
import { userModule } from "@/store/modules/user/userModule";
import { CurrencyFormatter } from "@/Utilities/CurrencyFormatter";

@Component({
  components: {
    DynamicForm,
    CompanyInfoField,
    LedgerEntriesOverview,
    ManualLedgerEntryDialog,
  },
})
export default class CashRegisterOverview extends Vue {
  private activeTab = 0;
  private showCreateDialog = false;
  private showAddMoneyDialog = false;
  private selectedAccountId = 0;
  private currentAccount: IMoneyAccountDTO | null = {
    id: 0,
    name: "",
    description: "",
    paymentMethod: PaymentMethod.Cash,
  };
  private isEditing = false;

  get loading() {
    return moneyAccountModule.loading;
  }

  get moneyAccounts() {
    return moneyAccountModule.moneyAccounts;
  }

  get accountsSummary() {
    return moneyAccountModule.accountsSummary;
  }

  get moneyAccountMetadata() {
    return metadataModule.moneyAccountMetadata;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.moneyAccountFormDTO });
  }

  get paymentMethodOptions() {
    return [
      { text: this.$t(this.$ts.paymentMethod.Cash), value: PaymentMethod.Cash },
      { text: this.$t(this.$ts.paymentMethod.Card), value: PaymentMethod.Card },
      { text: this.$t(this.$ts.paymentMethod.Transfer), value: PaymentMethod.Transfer },
      { text: this.$t(this.$ts.paymentMethod.Other), value: PaymentMethod.Other },
    ];
  }

  get summaryCards() {
    return [
      {
        title: this.$t(this.$ts.moneyAccountOverview.totalBalance),
        value: this.formatCurrency(this.accountsSummary?.totalBalance || 0),
        subtitle: `${this.moneyAccounts.length} ${this.$t(this.$ts.entities.cashRegister)}`,
        icon: "mdi-wallet",
        iconColor: "primary",
        bgClass: "primary lighten-5",
        textClass: "primary--text",
      },
      {
        title: this.$t(this.$ts.moneyAccountOverview.totalIncomingToday),
        value: this.formatCurrency(this.accountsSummary?.totalIncomingToday || 0),
        subtitle: `${this.calculateTotalIncomePercentage}% ${this.$t(this.$ts.moneyAccountOverview.ofDailyTotal)}`,
        icon: "mdi-arrow-up-bold-circle",
        iconColor: "success",
        bgClass: "income-bg",
        textClass: "income-text",
      },
      {
        title: this.$t(this.$ts.moneyAccountOverview.totalOutgoingToday),
        value: this.formatCurrency(this.accountsSummary?.totalOutgoingToday || 0),
        subtitle: `${this.calculateTotalOutgoingPercentage}% ${this.$t(this.$ts.moneyAccountOverview.ofDailyTotal)}`,
        icon: "mdi-arrow-down-bold-circle",
        iconColor: "error",
        bgClass: "expense-bg",
        textClass: "expense-text",
      },
      {
        title: this.$t(this.$ts.moneyAccountOverview.totalNetChangeToday),
        value: this.formatCurrency(this.accountsSummary?.totalNetChangeToday || 0),
        subtitle: `${this.calculateTotalNetChangePercentage}% ${this.$t(this.$ts.moneyAccountOverview.changeFromTotal)}`,
        icon: this.accountsSummary?.totalNetChangeToday >= 0 ? "mdi-trending-up" : "mdi-trending-down",
        iconColor: this.accountsSummary?.totalNetChangeToday >= 0 ? "success" : "error",
        bgClass: this.accountsSummary?.totalNetChangeToday >= 0 ? "income-bg" : "expense-bg",
        textClass: this.accountsSummary?.totalNetChangeToday >= 0 ? "income-text" : "expense-text",
      },
    ];
  }

  get calculateTotalIncomePercentage(): string {
    const total = this.accountsSummary?.totalBalance || 0;
    const income = this.accountsSummary?.totalIncomingToday || 0;
    if (!total) return "0.00";
    return ((income / total) * 100).toFixed(1);
  }

  get calculateTotalOutgoingPercentage(): string {
    const total = this.accountsSummary?.totalBalance || 0;
    const outgoing = this.accountsSummary?.totalOutgoingToday || 0;
    if (!total) return "0.00";
    return ((outgoing / total) * 100).toFixed(1);
  }

  get calculateTotalNetChangePercentage(): string {
    const total = this.accountsSummary?.totalBalance || 0;
    const netChange = this.accountsSummary?.totalNetChangeToday || 0;
    if (!total) return "0.00";
    return ((netChange / total) * 100).toFixed(1);
  }

  get totalTransactionsToday() {
    return (this.accountsSummary?.totalIncomingToday || 0) + (this.accountsSummary?.totalOutgoingToday || 0);
  }

  get incomingPercentage() {
    const total = this.totalTransactionsToday;
    return total > 0 ? Math.round((this.accountsSummary?.totalIncomingToday || 0) / total * 100) : 0;
  }

  get outgoingPercentage() {
    const total = this.totalTransactionsToday;
    return total > 0 ? Math.round((this.accountsSummary?.totalOutgoingToday || 0) / total * 100) : 0;
  }

  accountIncomingPercentage(accountId: number) {
    const total = this.getAccountTodayIncoming(accountId) + this.getAccountTodayOutgoing(accountId);
    return total > 0 ? Math.round(this.getAccountTodayIncoming(accountId) / total * 100) : 0;
  }

  accountOutgoingPercentage(accountId: number) {
    const total = this.getAccountTodayIncoming(accountId) + this.getAccountTodayOutgoing(accountId);
    return total > 0 ? Math.round(this.getAccountTodayOutgoing(accountId) / total * 100) : 0;
  }

  formatCurrency(value: number): string {
    return CurrencyFormatter.format(value, this.$i18n.locale);
  }

  private getPaymentMethodText(method: PaymentMethod): string {
    const option = this.paymentMethodOptions.find(opt => opt.value === method);
    return option ? option.text.toString() : "";
  }

  private getPaymentMethodColor(method: PaymentMethod): string {
    switch (method) {
      case PaymentMethod.Cash:
        return "green";
      case PaymentMethod.Card:
        return "blue";
      case PaymentMethod.Transfer:
        return "purple";
      default:
        return "grey";
    }
  }

  private getPaymentMethodIcon(method: PaymentMethod): string {
    switch (method) {
      case PaymentMethod.Cash:
        return "mdi-cash";
      case PaymentMethod.Card:
        return "mdi-credit-card";
      case PaymentMethod.Transfer:
        return "mdi-bank-transfer";
      default:
        return "mdi-help-circle";
    }
  }

  private openCreateDialog() {
    this.isEditing = false;
    this.currentAccount = {
      id: 0,
      name: "",
      description: "",
      paymentMethod: PaymentMethod.Cash,
    };
    this.showCreateDialog = true;
  }

  private editAccount(account: IMoneyAccountDTO) {
    this.isEditing = true;
    this.currentAccount = { ...account };
    this.showCreateDialog = true;
  }

  private closeDialog() {
    this.showCreateDialog = false;
    this.currentAccount = {
      id: 0,
      name: "",
      description: "",
      paymentMethod: PaymentMethod.Cash,
    };
    this.isEditing = false;
  }

  private deleteAccount(account: IMoneyAccountDTO) {
    const dialog: IDialog = {
      text: this.$t(this.$ts.genericEntityDialog.deleteConfirmation, {
        entity: this.$t(this.$ts.entities.cashRegister),
      }).toString(),
      type: DialogType.Choice,
      action: () => this.deleteAccountConfirm(account.id),
    };
    dialogModule.addToDialogQueue(dialog);
  }

  private async deleteAccountConfirm(id: number) {
    await moneyAccountModule.deleteAccount(id);
  }

  private async saveAccount(account: IMoneyAccountDTO) {
    try {
      if (this.isEditing && this.currentAccount?.id) {
        await moneyAccountModule.updateAccount({
          id: this.currentAccount.id,
          account: account,
        });
        this.closeDialog();
      } else {
        await moneyAccountModule.createAccount(account);
        this.closeDialog();
      }
    } catch (error) {
      console.error("Error saving account:", error);
    }
  }

  private getAccountBalance(accountId: number): number {
    if (!this.accountsSummary?.accountBalances) return 0;
    const balance = this.accountsSummary.accountBalances.find(b => b.accountId === accountId);
    return balance?.totalBalance || 0;
  }

  private getAccountTodayIncoming(accountId: number): number {
    if (!this.accountsSummary?.accountBalances) return 0;
    const balance = this.accountsSummary.accountBalances.find(b => b.accountId === accountId);
    return balance?.todayIncoming || 0;
  }

  private getAccountTodayOutgoing(accountId: number): number {
    if (!this.accountsSummary?.accountBalances) return 0;
    const balance = this.accountsSummary.accountBalances.find(b => b.accountId === accountId);
    return balance?.todayOutgoing || 0;
  }

  private getAccountTodayNetChange(accountId: number): number {
    if (!this.accountsSummary?.accountBalances) return 0;
    const balance = this.accountsSummary.accountBalances.find(b => b.accountId === accountId);
    return balance?.todayNetChange || 0;
  }

  private calculateDailyChangePercentage(accountId: number): string {
    const netChange = this.getAccountTodayNetChange(accountId);
    const balance = this.getAccountBalance(accountId);
    if (!balance) return "0.00";
    return ((netChange / balance) * 100).toFixed(2);
  }

  private calculateIncomeRatio(accountId: number): string {
    const income = this.getAccountTodayIncoming(accountId);
    const totalIncome = this.accountsSummary?.totalIncomingToday || 0;
    if (!totalIncome) return "0.00";
    return ((income / totalIncome) * 100).toFixed(1);
  }

  private calculateOutgoingRatio(accountId: number): string {
    const outgoing = this.getAccountTodayOutgoing(accountId);
    const totalOutgoing = this.accountsSummary?.totalOutgoingToday || 0;
    if (!totalOutgoing) return "0.00";
    return ((outgoing / totalOutgoing) * 100).toFixed(1);
  }

  private openAddMoneyDialog(accountId: number) {
    this.selectedAccountId = accountId;
    this.showAddMoneyDialog = true;
  }

  private onLedgerEntrySave(entry: ILedgerEntryDTO) {
    // Refresh the data after a new ledger entry is saved
    moneyAccountModule.getSummary(new Date());
  }

  async created() {
    await Promise.all([
      moneyAccountModule.getAllAccounts(),
      moneyAccountModule.getSummary(new Date()),
    ]);
  }
}
</script>

<style lang="scss" scoped>
.account-dashboard {
  .tabs-container {
    position: relative;
    margin-bottom: 24px;
  }

  .elevated-tabs {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    
    .v-tab {
      letter-spacing: 0.5px;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      
      &--active {
        background: rgba(var(--v-primary-base), 0.08);
      }

      &:last-child {
        &:hover {
          background: rgba(var(--v-deep-purple-base), 0.08);
        }
      }
    }
  }

  .summary-cards {
    position: relative;
    z-index: 2;
  }

  .transparent-card {
    background: transparent;
  }

  .account-grid {
    margin-top: 32px;
  }

  .modern-card {
    transition: all 0.3s ease;
    border-radius: 16px !important;

    .balance-total {
      margin: -16px -16px 16px -16px;
      padding: 16px;
      border-radius: 8px;
    }

    &:hover {
      transform: translateY(-4px) scale(1.02);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .stat-box {
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
  }

  .percentage-chip {
    font-weight: 500;
  }

  .income-chip {
    background-color: #388E3C !important;
  }

  .transaction-bar {
    display: flex;
    height: 16px;
    background-color: #eee;
    border-radius: 8px;
    overflow: hidden;
  }

  .transaction-bar.small {
    height: 8px;
  }

  .incoming {
    background-color: #4CAF50;
  }

  .outgoing {
    background-color: #F44336;
  }

  .income-bg {
    background-color: #E8F5E9 !important;
  }

  .income-text {
    color: #388E3C !important;
  }

  .expense-bg {
    background-color: #FFEBEE !important;
  }

  .expense-text {
    color: #D32F2F !important;
  }

  .text-h4,
  .text-h5 {
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .text-subtitle-1 {
    opacity: 0.9;
    font-weight: 500;
  }
}
</style>