"use strict";

export interface ISubscriptionStatus {
  message: string;
  status: Subscription;
  daysLeftOfTrial: number;
}

export enum Subscription {
  Active = "Active",
  CancelNextPayment = "CancelNextPaymentDate",
  None = "None",
  Trial = "Trial",
  Incomplete = "Incomplete",
  PastDue = "PastDue",
  Unpaid = "Unpaid",
  Expired = "Expired",
  IncompleteExpired = "IncompleteExpired",
  Canceled = "Canceled",
}
