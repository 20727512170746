<script lang="ts" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
import { Component, Vue } from "vue-property-decorator";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { IDialog } from "@/store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import { getApiUrl, getStripePublicKey } from "../../main";
import { getFbConfig } from "../../Utilities/authConfig";
import { formatDateStringHour } from "../../Utilities/dateUtility";
import { IAuditLogDTO, IPostAuditLogDTO } from "@shared/types";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { userModule } from "@/store/modules/user/userModule";
import { firebaseUserModule } from "@/store/modules/firebaseUser/firebaseUserModule";
import { adminModule } from "@/store/modules/admin/adminModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "AuditOverview",
  components: {
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class AuditOverview extends Vue {
  public headers = [];

  get auditLog() {
    return auditLogModule.auditLogs;
  }

  get user() {
    return userModule.user;
  }

  get firebaseUsers() {
    return firebaseUserModule.allFirebaseUsers;
  }

  getFirebaseUsersAsAdmin() {
    return adminModule.getUsers();
  }

  addToDialogQueue(dialog: IDialog) {
    return dialogModule.addToDialogQueue(dialog);
  }

  getAuditLog(ignoreFlag: any = null) {
    return auditLogModule.getAuditLogs(ignoreFlag);
  }

  postAuditLog(audit: IPostAuditLogDTO) {
    return auditLogModule.postAudit({ dto: audit, userId: this.user.userId });
  }

  isLoading: boolean = true;

  statusFilters = ["Info", "Warning", "Error", "Critical"];

  public async created(): Promise<any> {
    let ignoreFlag = this.$route.params.ignoreFlag;
    this.getFirebaseUsersAsAdmin();
    if (ignoreFlag != null) {
      await this.getAuditLog(ignoreFlag);
    } else {
      await this.getAuditLog();
    }
    this.isLoading = false;
  }

  public async mounted(): Promise<any> {
    this.headers = [
      { text: "", align: "start", sortable: true, value: "type" },
      {
        text: this.$t(this.$ts.auditOverview.event),
        sortable: true,
        value: "message",
      },
      { text: this.$t(this.$ts.auditOverview.time), sortable: true, value: "time" },
      {
        text: this.$t(this.$ts.auditOverview.comment),
        sortable: true,
        value: "comment",
      },
    ];
  }

  getChipIcon(eventId) {
    if (eventId == 0) return "mdi-account-circle";
    if (eventId == 1) return "mdi-book-open-page-variant";
    if (eventId == 2) return "mdi-settings";
    if (eventId == 3) return "mdi-account-supervisor-circle";
    if (eventId == 4) return "mdi-magnify";
    if (eventId == 5) return "mdi-star-circle";
  }

  getColorChip(eventId) {
    if (eventId == 0) return "green";
    if (eventId == 1) return "orange";
    if (eventId == 2) return "red lighten-2";
    if (eventId == 3) return "grey darken-2";
  }

  getLabel(eventId) {
    if (eventId == 0) return this.$t(this.$ts.auditOverview.customer);
    if (eventId == 1) return this.$t(this.$ts.auditOverview.booking);
    if (eventId == 2) return this.$t(this.$ts.auditOverview.settings);
    if (eventId == 3) return this.$t(this.$ts.auditOverview.staff);
    if (eventId == 4) return this.$t(this.$ts.auditOverview.track);
    if (eventId == 5) return this.$t(this.$ts.auditOverview.service);
  }
  eventIdToString(eventId) {
    if (eventId == 0) {
      return "Debug log";
    }
  }
  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  readComment(comment) {
    let dialog: IDialog = {
      text: comment,
      fullScreen: true,
      html: true,
    };
    this.addToDialogQueue(dialog);

    let ignoreFlag: number = this.$route.params.ignoreFlag as unknown as number;
    this.getFirebaseUsersAsAdmin();
    if (ignoreFlag != 1) {
      let audit: IPostAuditLogDTO = {
        action: 3,
        type: 4,
        comment: comment,
        message: this.$t(this.$ts.auditOverview.readComment).toString(),
        hideForUser: true,
      };

      this.postAuditLog(audit);
    }
  }

  get stripePublishKey() {
    return getStripePublicKey();
  }
  get apiUrl() {
    return getApiUrl();
  }
  get firebaseConfig() {
    return getFbConfig();
  }
}
</script>

<template>
  <v-col>
    <v-row>
      <div class="treatment-list">
        <div class="treatment">
          <v-data-table
            v-if="!isLoading"
            :headers="headers"
            :items="auditLog"
            :items-per-page="10"
            :single-select="false"
          >
            <template v-slot:no-data>
              {{ $t("auditOverview.noChanges") }}
            </template>

            <template v-slot:item.type="{ item }">
              <v-chip dark :color="getColorChip(item.action)">
                <v-avatar class="pr-1">
                  <v-icon>{{ getChipIcon(item.type) }}</v-icon>
                </v-avatar>
                {{ getLabel(item.type) }}
              </v-chip>
              <!--<v-btn v-if="item.comment != null && item.comment.length > 1" @click="readComment(item.comment)" icon> <v-icon>comment</v-icon></v-btn>-->
            </template>

            <template v-slot:item.comment="{ item }">
              <v-btn v-if="item.comment != null && item.comment.length > 1" @click="readComment(item.comment)" icon>
                <v-icon>comment</v-icon></v-btn
              >
            </template>

            <template v-slot:item.time="{ item }">
              <span>{{ formatDateStringHour(item.time) }}</span>
            </template>
          </v-data-table>
          <v-data-table v-else loading :loading-text="$t($ts.loading)"></v-data-table>
        </div>
      </div>
    </v-row>
  </v-col>
</template>
