<template>
  <v-navigation-drawer 
    v-model="drawerVisible" 
    right 
    fixed 
    temporary
    style="top: 20% !important; height: 80% !important;"
  >
    <v-list nav dense class="py-0">
      <v-list-item-group v-model="selectedItem" active-class="primary-highlight">
        <v-spacer></v-spacer>
        <div v-for="item in navigationItems" :key="item.text">
          <v-list-item 
            dense 
            class="py-1" 
            @click="handleItemClick(item)"
            :class="{ 'primary-highlight': isCurrentRoute(item.route) }"
          >
            <v-list-item-icon class="my-2 mr-3">
              <v-icon :color="isCurrentRoute(item.route) ? 'primary' : ''">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="{ 'primary--text font-weight-medium': isCurrentRoute(item.route) }">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import AuditLogger, { AuditLoggerType } from "../../mixins/AuditLogger";
import {
  PermissionArea,
  PermissionLevel,
  userPermissionsModule,
} from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

interface NavigationItem {
  alt: string;
  text: string;
  icon: string;
  route?: string;
  action?: () => void;
  children?: any[];
  onlySmallMenu?: boolean;
}

@Component({
  name: "NavigationDrawer",
  mixins: [AuditLogger],
})
export default class NavigationDrawer extends Vue implements AuditLoggerType {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) width!: number;

  selectedItem = null;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get drawerVisible(): boolean {
    return this.value;
  }

  set drawerVisible(value: boolean) {
    this.$emit("input", value);
  }

  get navigationItems(): NavigationItem[] {
    const items: NavigationItem[] = [];

    // Only add appointments and clients related items if user has permission
    if (userPermissionsModule.hasPermission(PermissionArea.APPOINTMENTS_AND_CLIENTS, PermissionLevel.READ)) {
      items.push(
        {
          alt: this.$t(this.$ts.mobileHeader.home).toString(),
          text: this.$t(this.$ts.mobileHeader.home).toString(),
          route: "/admin",
          icon: "dashboard",
        },
        {
          alt: this.$t(this.$ts.mobileHeader.calendar).toString(),
          text: this.$t(this.$ts.mobileHeader.calendar).toString(),
          route: "/kalender",
          icon: "calendar_today",
        },
        {
          alt: this.$t(this.$ts.mobileHeader.customers).toString(),
          text: this.$t(this.$ts.mobileHeader.customers).toString(),
          route: "/kunder",
          icon: "account_circle",
        },
        {
          alt: this.$t(this.$ts.mobileHeader.orders).toString(),
          text: this.$t(this.$ts.mobileHeader.orders).toString(),
          route: "/bestillinger",
          icon: "event_note",
        }
      );
    }

    // Add administration items if user has administration permissions
    if (userPermissionsModule.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ)) {
      items.push(
        {
          alt: this.$t(this.$ts.sidebar.orders).toString(),
          text: this.$t(this.$ts.sidebar.orders).toString(),
          route: "/orders",
          icon: "shopping_cart",
        },
        {
          alt: this.$t(this.$ts.sidebar.cashRegister).toString(),
          text: this.$t(this.$ts.sidebar.cashRegister).toString(),
          route: "/cash-register",
          icon: "mdi-cash-register",
        },
        {
          alt: this.$t(this.$ts.sidebar.expenses).toString(),
          text: this.$t(this.$ts.sidebar.expenses).toString(),
          route: "/expenses",
          icon: "payments",
        }
      );

      // Add staff management if user has multiple staff members
      if (staffMemberModule.hasMultipleStaff) {
        items.push({
          alt: this.$t(this.$ts.sidebar.staffManagement).toString(),
          text: this.$t(this.$ts.sidebar.staffManagement).toString(),
          route: "/staff-management",
          icon: "mdi-account-group",
        });
      }
    }

    // Always show settings
    items.push({
      alt: this.$t(this.$ts.mobileHeader.settings).toString(),
      text: this.$t(this.$ts.mobileHeader.settings).toString(),
      // onlySmallMenu: true,
      action: () => this.openSettings(),
      icon: "settings",
    });

    // Only add statistics if user has permission
    if (userPermissionsModule.hasPermission(PermissionArea.STATISTICS_AND_FINANCES, PermissionLevel.READ)) {
      items.push({
        alt: this.$t(this.$ts.mobileHeader.statistics).toString(),
        text: this.$t(this.$ts.mobileHeader.statistics).toString(),
        route: "/statistikk",
        icon: "insert_chart",
      });
    }

    return this.width > 800 ? items.filter((item) => !item.onlySmallMenu) : items;
  }

  handleItemClick(item: NavigationItem): void {
    if (item.action) {
      item.action();
    } else if (item.route) {
      this.logAudit(" went to route from navigation drawer: " + item.route);
      this.$router.push(item.route).catch(() => {});
    }
  }

  openSettings(): void {
    this.logAudit(" opened settings dialog from navigation drawer");
    this.drawerVisible = false;
    this.$emit("settings-click");
  }

  isCurrentRoute(route?: string): boolean {
    if (!route) return false;
    return this.$route.path === route;
  }
}
</script>

<style scoped>
.primary-highlight {
  background-color: var(--v-primary-lighten5) !important;
  border-right: 3px solid var(--v-primary-base) !important;
}
</style>
