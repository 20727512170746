<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t(this.$ts.entities.orders) }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t($ts.placeHolder.typeToSearch)"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
      </v-card-title>

      <responsive-table :items="ordersWithNames" :loading="loading">
        <!-- Desktop Headers -->
        <template v-slot:headers>
          <th>{{ $t($ts.orders.orderNumber) }}</th>
          <th>{{ $t($ts.orders.orderDate) }}</th>
          <th>{{ $t($ts.entities.customer) }}</th>
          <th>{{ $t($ts.orders.staffMember) }}</th>
          <th>{{ $t($ts.orders.totalAmount) }}</th>
          <th>{{ $t($ts.orders.status) }}</th>
          <th class="text-right">{{ $t($ts.orders.actions) }}</th>
        </template>

        <!-- Desktop Row -->
        <template v-slot:desktop-row="{ item }">
          <td>{{ item.number }}</td>
          <td>{{ formatDate(item.orderDate) }}</td>
          <td>
            <router-link v-if="item.customerId" :to="`/kunder/${item.customerId}`" class="customer-link">
              {{ getCustomerName(item.customerId) }}
            </router-link>
            <span v-else>{{ getCustomerName(item.customerId) }}</span>
          </td>
          <td>{{ item.staffMemberName }}</td>
          <td>{{ formatCurrency(item.totalAmount) }}</td>
          <td>
            <!-- <v-chip :color="getStatusColor(item.status)" text-color="white" small>
              {{ $t($ts.orderStatus[item.status]) }}
            </v-chip> -->
          </td>
          <td class="text-right">
            <v-icon small @click="viewOrder(item)">mdi-eye</v-icon>
          </td>
        </template>

        <!-- Mobile Header -->
        <template v-slot:mobile-header="{ item }">
          <div class="d-flex justify-space-between align-center">
            <div>
              <div class="font-weight-medium">#{{ item.number }}</div>
              <div class="text-caption">{{ formatDate(item.orderDate) }}</div>
            </div>
            <div class="text-right">
              <div class="text-h6">{{ formatCurrency(item.totalAmount) }}</div>
              <!-- <v-chip :color="getStatusColor(item.status)" text-color="white" x-small>
                {{ $t($ts.orderStatus[item.status]) }}
              </v-chip> -->
            </div>
          </div>
        </template>

        <!-- Mobile Content -->
        <template v-slot:mobile-content="{ item }">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t($ts.entities.customer) }}</v-list-item-subtitle>
                <v-list-item-title>
                  <router-link v-if="item.customerId" :to="`/kunder/${item.customerId}`" class="customer-link">
                    {{ getCustomerName(item.customerId) }}
                  </router-link>
                  <span v-else>{{ getCustomerName(item.customerId) }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t($ts.orders.staffMember) }}</v-list-item-subtitle>
                <v-list-item-title>{{ item.staffMemberName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-end mt-2">
                  <v-btn small color="primary" @click="viewOrder(item)">
                    <v-icon small left>mdi-eye</v-icon>
                    {{ $t($ts.orders.orderDetails) }}
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </responsive-table>
    </v-card>

    <!-- Create Order Dialog -->
    <v-dialog v-if="showCreateOrderDialog" fullscreen v-model="showCreateOrderDialog" max-width="1200px">
      <CreateOrder v-if="showCreateOrderDialog" @cancel="showCreateOrderDialog = false" @created="handleOrderCreated" />
    </v-dialog>

    <!-- View Order Dialog -->
    <ViewOrderDialog v-if="selectedOrder" :order="selectedOrder" v-model="showViewOrderDialog" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { IOrderDTO, OrderStatus, PaymentMethod } from "@shared/types";
import CreateOrder from "@/components/global/CreateOrder.vue";
import ViewOrderDialog from "@/components/global/ViewOrderDialog.vue";
import { ordersModule } from "@/store/modules/orders/ordersModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { userModule } from "@/store/modules/user/userModule";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";

@Component({
  components: {
    CreateOrder,
    ViewOrderDialog,
    ResponsiveTable,
  },
})
export default class OrdersOverview extends Vue {
  private search = "";
  private showCreateOrderDialog = false;
  private showViewOrderDialog = false;
  private selectedOrder: IOrderDTO | null = null;
  private loading = false;

  get orders(): IOrderDTO[] {
    return ordersModule.orders;
  }

  get ordersWithNames() {
    return this.orders.map((order) => ({
      ...order,
      customerName: this.getCustomerName(order.customerId),
      staffMemberName: this.getStaffMemberName(order.staffId),
    }));
  }

  private getCustomerName(customerId: number): string {
    const customer = customersModule.customers.find((c) => c.id === customerId);
    return customer ? `${customer.firstName} ${customer.lastName}` : this.$t(this.$ts.orders.notSet).toString();
  }

  private getStaffMemberName(staffId: number): string {
    const staffMember = staffMemberModule.staffMembers.find((s) => s.id === staffId);
    return staffMember ? staffMember.fullName : this.$t(this.$ts.orders.notSet).toString();
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }

  private formatDate(date: string | Date): string {
    return new Date(date).toLocaleDateString();
  }

  private formatDateTime(date: string | Date): string {
    return new Date(date).toLocaleString();
  }

  private getStatusColor(status: OrderStatus): string {
    const statusColors = {
      [OrderStatus.Completed]: "success",
      [OrderStatus.Pending]: "warning",
      [OrderStatus.Cancelled]: "error",
      [OrderStatus.Refunded]: "grey",
    };
    return statusColors[status] || "grey";
  }

  private getPaymentMethodIcon(method: PaymentMethod): string {
    const icons = {
      [PaymentMethod.Cash]: "mdi-cash",
      [PaymentMethod.Card]: "mdi-credit-card",
      [PaymentMethod.Transfer]: "mdi-bank-transfer",
      [PaymentMethod.Other]: "mdi-cash-multiple",
    };
    return icons[method] || "mdi-help-circle";
  }

  private getPaymentMethodColor(method: PaymentMethod): string {
    const colors = {
      [PaymentMethod.Cash]: "green",
      [PaymentMethod.Card]: "blue",
      [PaymentMethod.Transfer]: "purple",
      [PaymentMethod.Other]: "grey",
    };
    return colors[method] || "grey";
  }

  private async viewOrder(order: IOrderDTO) {
    this.loading = true;
    const orderId = Number(order.id);
    this.selectedOrder = await ordersModule.getOrder(orderId);
    this.loading = false;
    this.showViewOrderDialog = true;
  }

  private async handleOrderCreated() {
    this.showCreateOrderDialog = false;
    await this.fetchOrders();
  }

  private async fetchOrders() {
    this.loading = true;
    await ordersModule.getOrders();
    this.loading = false;
  }

  async created() {
    await Promise.all([this.fetchOrders(), customersModule.getCustomers(), staffMemberModule.getStaffMembers()]);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .v-chip {
    font-size: 12px;
  }
}

.v-card-title {
  .v-text-field {
    max-width: 300px;
  }
}

.customer-link {
  color: #1976d2;
  text-decoration: none;
}

.customer-link:hover {
  text-decoration: underline;
}
</style>
