<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="closeDialog" persistent>
    <v-card class="expense-card">
      <v-card-title style="background-color: #866afe" class="white--text">
        <v-icon left color="white">mdi-cash-sync</v-icon>
        <span class="headline">{{ $t($ts.entities.recurringExpense) }} #{{ expenseId }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-container fluid>
          <!-- Date Range Section -->
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-card outlined class="time-card">
                <v-card-text>
                  <div class="d-flex align-center mb-2">
                    <v-icon style="color: #866afe" class="mr-2">mdi-calendar-start</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.expenses.startDate) }}</div>
                      <div>{{ formatDate(expense.startDate) }}</div>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-calendar-end</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.expenses.endDate) }}</div>
                      <div>{{ formatDate(expense.endDate) }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Frequency Section -->
            <v-col cols="12" sm="6">
              <v-card outlined class="frequency-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-refresh</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.reoccuringExpense.recurrenceInterval) }}</div>
                      <div>{{ getFrequencyText(expense.recurrenceInterval) }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Category Section -->
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-card outlined class="category-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-tag</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.category) }}</div>
                      <div>{{ expense.category }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Status Section -->
            <v-col cols="12" sm="6">
              <v-card outlined class="status-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-check-circle</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.appointment.appointmentStatus) }}</div>
                      <v-chip
                        small
                        :color="expense.isActive ? 'success' : 'error'"
                        text-color="white"
                        label
                      >
                        {{ expense.isActive ? $t($ts.boolean.on) : $t($ts.boolean.off) }}
                      </v-chip>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Description Section -->
          <v-row class="mb-4" v-if="expense.name">
            <v-col cols="12">
              <v-card outlined class="description-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-text</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.description) }}</div>
                      <div>{{ expense.name }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Money Account Section -->
          <v-row class="mb-4" v-if="expense.moneyAccountId">
            <v-col cols="12">
              <v-card outlined class="account-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-bank</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.sidebar.cashRegister) }}</div>
                      <div>{{ getMoneyAccountName(expense.moneyAccountId) }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Amount Section -->
          <v-row>
            <v-col cols="12">
              <v-card outlined class="amount-card">
                <v-card-text class="d-flex align-center justify-end">
                  <v-icon style="color: #866afe" class="mr-2">mdi-cash-multiple</v-icon>
                  <span class="subtitle-1 mr-2">{{ $t($ts.dtos.recurringExpenseFormDTO.amount.name) }}:</span>
                  <span class="text-h5 font-weight-bold" style="color: #866afe">
                    {{ formatCurrency(expense.amount) }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn style="color: #866afe" text @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t($ts.buttons.close) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate } from "@/types/formatDateHelper";
import { userModule } from "@/store/modules/user/userModule";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";
import { IRecurringExpenseDTO, RecurrenceInterval } from "@shared/types/";
@Component
export default class ViewRecurringExpense extends Vue {
  @Prop({ type: Object, required: true }) expense!: IRecurringExpenseDTO;
  @Prop({ type: Boolean, required: true }) value!: boolean;

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit("input", value);
  }

  get expenseId(): number {
    return typeof this.expense.id === "string" ? parseInt(this.expense.id) : this.expense.id;
  }

  private formatDate(date: string): string {
    return formatDate(new Date(date));
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user?.currency || "USD",
    }).format(value);
  }

  private getMoneyAccountName(moneyAccountId: number): string {
    if (!moneyAccountId) return '-';
    const account = moneyAccountModule.moneyAccounts.find(acc => acc.id === moneyAccountId);
    return account ? account.name : '-';
  }

  private getFrequencyText(frequency: RecurrenceInterval): string {


    
    const recurrenceIntervals = [
      { text: this.$t(this.$ts.reoccuringExpense.intervals.daily).toString(), value: RecurrenceInterval.Daily },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.monthly).toString(), value: RecurrenceInterval.Monthly },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.quarterly).toString(), value: RecurrenceInterval.Quarterly },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.yearly).toString(), value: RecurrenceInterval.Yearly },
    ];

    const text = recurrenceIntervals.find(interval => interval.value === frequency)?.text;
    return text || '-';
  }

  closeDialog() {
    this.$emit("input", false);
  }
}
</script>

<style scoped>
.expense-card {
  border-radius: 8px;
}

.time-card,
.frequency-card,
.category-card,
.status-card,
.description-card,
.account-card,
.amount-card {
  border-radius: 4px;
  transition: all 0.3s;
}

.time-card:hover,
.frequency-card:hover,
.category-card:hover,
.status-card:hover,
.description-card:hover,
.account-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  font-weight: 500;
  font-size: 1.5rem;
}

.subtitle-1 {
  font-weight: 500;
}

.amount-card {
  background-color: #f5f5f5;
}

.v-card-title {
  word-break: break-word;
}
</style> 