import { EventBaseService } from "./Base/EventBaseService";
import {
  IStaffMemberController,
  IStaffMemberDTO,
  IStaffMemberBookingDTO,
  IStaffMemberAnonymousRequestDTO,
  IStaffMemberStatisticsDTO,
  ICommissionDataDTO,
} from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class StaffMemberService extends EventBaseService implements IStaffMemberController {
  private static instance: StaffMemberService;
  private controller = "staffMember";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!StaffMemberService.instance) {
      StaffMemberService.instance = new StaffMemberService();
    }
    return StaffMemberService.instance;
  }

  public async staffMemberHasOAuth(staffMemberID: number) {
    return this.http.getAuthenticated<boolean>(`${this.controller}/hasOAuth/${staffMemberID}`);
  }

  public async getStaffMembers() {
    return this.http.getAuthenticated<IStaffMemberDTO[]>(`${this.controller}/current`);
  }

  public async editServiceStateOnStaffMember(staffMemberId: number, serviceId: number, enabled: boolean) {
    return this.withEventEmission(Feature.StaffMember, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current/service/${staffMemberId}/${serviceId}/${enabled}`)
    );
  }

  public async postStaffMember(staffMember: IStaffMemberDTO) {
    return this.withEventEmission(Feature.StaffMember, EventType.Added, () =>
      this.http.postAuthenticated<void>(`${this.controller}/current`, staffMember)
    );
  }

  public async patchStaffMember(patchDoc: JsonPatchOperation[], staffMemberId: number) {
    return this.withEventEmission(Feature.StaffMember, EventType.Updated, () =>
      this.http.patchAuthenticated<void>(`${this.controller}/current/${staffMemberId}`, patchDoc)
    );
  }

  public async getStaffMemberById(staffMemberId: number) {
    return this.http.getAuthenticated<IStaffMemberDTO>(`${this.controller}/current/${staffMemberId}`);
  }

  public async getStaffMemberByIdAsAnonymous(staffMemberId: number, userId: string) {
    return this.http.get<IStaffMemberBookingDTO>(`${this.controller}/asAnonymous/${staffMemberId}?userId=${userId}`);
  }

  public async getStaffMembersAsAnonymous(adminId: string, request: IStaffMemberAnonymousRequestDTO) {
    return this.http.post<IStaffMemberBookingDTO[]>(`${this.controller}/anonymous/${adminId}`, request);
  }

  public async deleteStaffMember(staffMemberId: number) {
    return this.withEventEmission(Feature.StaffMember, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/current/${staffMemberId}`)
    );
  }

  public async uploadStaffMemberPicture(file: any, staffMemberId: number) {
    return this.withEventEmission(Feature.Image, EventType.Uploaded, () =>
      this.http.putAuthenticatedFormData<void>(`${this.controller}/current/picture/${staffMemberId}`, file)
    );
  }

  public async getStaffMemberCount(adminId: string) {
    return this.http.get<number>(`${this.controller}/count/${adminId}`);
  }

  public async getStaffMemberStatistics(staffMemberId: number, year: number, month: number, day: number) {
    return this.http.getAuthenticated<IStaffMemberStatisticsDTO>(
      `${this.controller}/current/statistics/${staffMemberId}/${year}/${month}/${day}`
    );
  }

  public async getStaffMemberCommissions(year: number, month: number) {
    return this.http.getAuthenticated<ICommissionDataDTO[]>(
      `${this.controller}/current/commissions/${year}/${month}`
    );
  }
}
