<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { IAccountRoleDTO, PermissionLevel } from "@shared/types";
import { accountRoleModule } from "@/store/modules/accountRoleModule/accountRoleModule";

@Component({
  name: "AccountRoleSettings",
  components: { DynamicForm },
})
export default class AccountRoleSettings extends Vue {
  public editingRole: Partial<IAccountRoleDTO> | null = null;
  private showForm = false;
  private isEditing = false;
  private isCreating = false;

  get defaultRole(): Partial<IAccountRoleDTO> {
    return {
      name: "",
      appointmentsAndClients: PermissionLevel.None,
      settings: PermissionLevel.None,
      statisticsAndFinances: PermissionLevel.None,
      administration: PermissionLevel.None,
    };
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.accountRoleDTO });
  }

  async created() {
    await accountRoleModule.getRoles();
  }

  openNewRoleDialog() {
    this.isCreating = true;
    this.isEditing = false;
    this.editingRole = { ...this.defaultRole };
    this.showForm = true;
  }

  startEdit(role: IAccountRoleDTO) {
    this.isEditing = true;
    this.isCreating = false;
    this.editingRole = { ...role };
    this.showForm = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.isCreating = false;
    this.editingRole = null;
    this.showForm = false;
  }

  async onSave(formData: IAccountRoleDTO) {
    try {
      if (this.isEditing) {
        await accountRoleModule.updateRole({
          role: formData,
          id: formData.id,
        });
      } else {
        await accountRoleModule.createRole(formData);
      }

      await accountRoleModule.getRoles();
      this.cancelEdit();
    } catch (error) {
      console.error("Error saving role:", error);
    }
  }

  async deleteRoleHandler(roleId: number) {
    try {
      await accountRoleModule.deleteRole(roleId);
      await accountRoleModule.getRoles();
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  }

  get accountRoleMetadata() {
    return metadataModule.accountRoleMetadata;
  }

  get roles() {
    return accountRoleModule.roles;
  }
}
</script>

<template>
  <div>
    <v-dialog v-model="showForm" max-width="600">
      <DynamicForm
        v-if="editingRole"
        :data="editingRole"
        :entity="$t(this.$ts.entities.accountRole)"
        :metadata="accountRoleMetadata"
        :translation-path="nonReactiveTranslations"
        :is-editing="isEditing"
        :is-creating="isCreating"
        :edit-title="$ts.accountRole.editRole"
        :create-title="$ts.accountRole.createRole"
        @save="onSave"
        @create="onSave"
        @cancel="cancelEdit"
      />
    </v-dialog>

    <v-card flat>
      <v-card-text>
        <v-data-table
          :headers="[
            { text: $t($ts.accountRole.title), value: 'name' },
            {
              text: $t($ts.accountRole.actions),
              value: 'actions',
              sortable: false,
            },
          ]"
          :items="roles"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="startEdit(item)"> mdi-pencil </v-icon>
            <v-icon small @click="deleteRoleHandler(item.id)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped></style>
