import router from "@/router";
import { logout } from "@/Utilities/authConfig";
import firebase from "firebase/app";
import { IDialog } from "../store/modules/dialogModule/dialogModule";
import { i18n } from "@/main";
import type {
  ActionCodeSettings,
  ApplicationVerifier,
  AuthCredential,
  AuthProvider,
  ConfirmationResult,
  User as FirebaseUser,
  IdTokenResult,
  UserCredential,
} from "@firebase/auth-types";

import * as Sentry from "@sentry/vue";

import { IAuditLogDTO, IPostFreshUserDTO, IPostUserDTO, IUserDTO } from "@shared/types";
import { firebaseUserModule } from "@/store/modules/firebaseUser/firebaseUserModule";
import { userModule } from "@/store/modules/user/userModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";

// Skip auth handling if running in Cypress
if (!(window as any).Cypress) {
  function isMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  function isiPad() {
    return /iPad/i.test(navigator.userAgent);
  }

  async function onStateChanged(user) {
    let device = "Desktop";
    if (isMobile()) {
      device = "Mobile";
    }
    if (isiPad()) {
      device = "iPad";
    }

    if (user) {
      userModule.setFirebaseUser(user);
      userModule.hasResolvedUser = false;
      userModule.isLoggedIn = true;

      Sentry.setUser({
        id: user.uid,
        email: user.email,
        username: user.displayName || user.email,
      });

      if (userModule.isNewUser) {
        //We may get name and picture from google/facebook login eventually.
        let newUser: Partial<IPostFreshUserDTO> = {
          emailAddress: user.email,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          language: i18n.locale,
        };

        if (window.location.toString().includes("citaro") || window.location.toString().includes("localhost")) {
          //@ts-ignore
          newUser.defaultPhoneNumberCountryCode = 57; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
          newUser.timeZone = "America/Bogota"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
          newUser.timeFormat = "12h"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
          newUser.currency = "COP";
        }

        try {
          console.warn("We are new here.");
          await userModule.registerUser(newUser as IPostFreshUserDTO);

          await userModule.fetchInitialUserInfo();
          userModule.isNewUser = false;

          router.replace("/admin");

          userModule.isNewUser = false;
        } catch (e) {
          await logout();
          router.replace("/");
          // let dialog: IDialog = {
          //   text: "Kunne ikke registrere. Vennligst kontakt oss for hjelp.",
          // };
          // store.commit("addToDialogQueue", dialog);
          console.error(e);
        }
      } else {
        console.warn("Logging in because its an existing user");
        await userModule.fetchInitialUserInfo();

        let lastUrl = localStorage.getItem("lasturl");
        if (lastUrl != null && lastUrl != "" && lastUrl != "undefined") {
          if (lastUrl == "/") {
            router.replace("/admin");
          } else {
            // router.replace(lastUrl);
          }
        } else {
          router.replace("/admin");
        }
      }
    } else {
      userModule.hasResolvedUser = true;
      userModule.isLoggedIn = false;

      Sentry.setUser(null);

      let metaTags: any = router.currentRoute.meta;

      if (metaTags.requiresAuth == true) {
        router.replace("/");
      }
    }
  }

  setTimeout(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      onStateChanged(user);
    });
  }, 1000);
} else {
  let user: Partial<FirebaseUser> = {
    uid: "test-uid",
    email: "test@example.com",
    getIdToken: () => Promise.resolve("mock-firebase-token"),
    emailVerified: false,
    isAnonymous: false,
    metadata: undefined,
    multiFactor: undefined,
    phoneNumber: "",
    providerData: [],
    refreshToken: "",
    tenantId: "",
    displayName: "",
    photoURL: "",
    providerId: "",
  };

  console.log("Fetching initial user info...");
  userModule.setFirebaseUser(user);

  userModule.getCurrentUser();
  userModule.isLoggedIn = true;
  userModule.hasResolvedUser = true;
}
