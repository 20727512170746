import { Component, Vue } from "vue-property-decorator";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";

export enum SnackbarType {
  Success = "success",
  Error = "error",
  Loading = "loading",
  Default = "default",
}

@Component
export default class SnackbarMixin extends Vue {
  postSnackbarMessage(message: string, type: SnackbarType = SnackbarType.Default) {
    switch (type) {
      case SnackbarType.Success:
        snackbarModule.postSuccessSnackbarMessage(message);
        break;
      case SnackbarType.Error:
        snackbarModule.postErrorSnackbarMessage(message);
        break;
      case SnackbarType.Loading:
        snackbarModule.postLoadingSnackbarMessage(message);
        break;
      default:
        snackbarModule.postSnackbarMessage(message);
    }
  }
}
