<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { IAppointmentForWeeklyCalenderDTO } from "@shared/types";
import { formatOnlyHour } from "@/Utilities/dateUtility";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";

@Component({
  name: "AppointmentListItem",
})
export default class AppointmentListItem extends Vue {
  @Prop({ required: true }) readonly appointment!: IAppointmentForWeeklyCalenderDTO;
  @Prop({ required: true }) readonly hasMultipleStaff!: boolean;
  @Prop({ required: true }) readonly isMobile!: boolean;

  formatDateStringHour(date: Date) {
    return formatOnlyHour(date);
  }

  isDone(item: IAppointmentForWeeklyCalenderDTO) {
    return item.status === "Ferdig";
  }

  getAgendaColor(status: IAppointmentForWeeklyCalenderDTO) {
    return status.color;
  }

  getBlockedText(item: IAppointmentForWeeklyCalenderDTO) {
    if (this.hasMultipleStaff && item.name.length > 0) {
      return item.name + " - " + item.staffMemberName;
    }
    return item.name;
  }

  gotoAppointment(num: number) {
    appointmentsModule.appointmentSource = "admin";
    this.$router.push("/bestillinger/" + num).catch(() => {});
  }

  readNote(note: string) {
    this.$emit("read-note", note);
  }

  readComment(comment: string) {
    this.$emit("read-comment", comment);
  }
}
</script>

<template>
  <v-list-item
    :dark="appointment.isCustom"
    class="ma-1"
    :class="{ 'agenda-disabled': appointment.isCustom }"
    :style="'background-color: ' + getAgendaColor(appointment) + '; border-radius: 4px'"
  >
    <template v-slot:default="{ active, toggle }">
      <v-list-item-content>
        <v-row v-if="appointment.isCustom" style="pointer-events: none">
          <v-col cols="1">
            <v-icon class="mt-1" color="black">block</v-icon>
          </v-col>
          <v-col>
            <v-list-item-subtitle class="text--black" style="font-weight: bold">{{
              getBlockedText(appointment)
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="text--black" style="color: hsla(0, 0%, 100%, 0.7)">{{
              formatDateStringHour(appointment.start) + " - " + formatDateStringHour(appointment.end)
            }}</v-list-item-subtitle>
          </v-col>
        </v-row>
        <v-row v-else @click="gotoAppointment(appointment.appointmentNumber)">
          <v-col v-if="!isMobile" cols="1">
            <v-checkbox
              @click.stop="$emit('toggle-finished', appointment)"
              v-model="appointment.status"
              :true-value="'Ferdig'"
              v-bind:false-value="'Ny'"
            ></v-checkbox>
          </v-col>
          <v-col cols="10">
            <v-list-item-subtitle
              v-if="appointment.customerId === 0 || appointment.customerId === -1 || appointment.customerId == null"
              class="text--white"
              style="font-weight: bold"
              >{{ appointment.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-else class="text--white">
              <router-link
                style="font-weight: bold"
                class="text--white"
                v-if="appointment.appointmentNumber !== 0"
                :to="'/kunder/' + appointment.customerId"
                >{{ appointment.name }}</router-link
              >
            </v-list-item-subtitle>

            <v-list-item-subtitle
              class="text--white"
              v-if="!appointment.isCustom && (!hasMultipleStaff || appointment.staffMemberName.length === 0)"
            >
              {{ appointment.appointmentName }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="text--white"
              v-if="!appointment.isCustom && hasMultipleStaff && appointment.staffMemberName.length > 0"
            >
              {{ appointment.appointmentName }}</v-list-item-subtitle
            >

            <v-list-item-subtitle style="color: hsla(0, 0%, 100%, 0.7)"
              >{{ formatDateStringHour(appointment.start) + " - " + formatDateStringHour(appointment.end) }}
            </v-list-item-subtitle>

            <v-list-item-subtitle
              class="text--white"
              v-if="appointment.adminNote != null && appointment.adminNote.length > 0"
            >
              <span @click.stop="readNote(appointment.adminNote)" style="color: #3f51b5; text-decoration: underline">{{
                $t("readNotes")
              }}</span>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="
                (appointment.comment != null && appointment.comment.length > 0) ||
                (appointment.adminNote != null && appointment.adminNote.length > 0)
              "
            >
              <span
                class="text--white"
                @click.stop="readComment(appointment.comment)"
                style="color: #3f51b5; text-decoration: underline"
                >{{ $t("readCustomerComment") }}</span
              >
            </v-list-item-subtitle>
          </v-col>

          <!-- <v-col>
            <v-scroll-x-transition>
              <v-icon v-if="isDone(appointment)" color="white"> mdi-check </v-icon>
            </v-scroll-x-transition>
          </v-col> -->
        </v-row>
      </v-list-item-content>
    </template>
  </v-list-item>
</template>
