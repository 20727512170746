<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { API_URLS } from "@/main";

@Component({
  name: "ApiEnvironmentSwitcher"
})
export default class ApiEnvironmentSwitcher extends Vue {
  localApiUrl = API_URLS.LOCAL;
  prodApiUrl = API_URLS.PROD;

  get showApiSwitch() {
    return window.location.hostname.includes("localhost");
  }

  get currentApiUrl() {
    return localStorage.getItem("apiUrl") || this.prodApiUrl;
  }

  async switchApiUrl() {
    const newUrl = this.currentApiUrl === this.localApiUrl ? this.prodApiUrl : this.localApiUrl;
    localStorage.setItem("apiUrl", newUrl);
    window.location.reload();
  }
}
</script>

<template>
  <v-card v-if="showApiSwitch">
    <v-card-title>API Environment</v-card-title>
    <v-card-text>
      <v-radio-group v-model="currentApiUrl" @change="switchApiUrl">
        <v-radio :label="'Using Local API'" :value="localApiUrl"></v-radio>
        <v-radio :label="'Using Production API'" :value="prodApiUrl"></v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template> 