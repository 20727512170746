<template>
  <div>
    <v-dialog style="overflow-x: hidden" v-model="dialog" max-width="90%">
      <v-card style="padding: 0">
        <v-card-title fixed v-if="fetchedCustomer != null" class="headline grey lighten-2" primary-title>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click="finished">Lukk</v-btn>
        </v-card-title>

        <v-card-text v-if="fetchedCustomer != null">
          <div class="treatment-widget">
            <div class="treatment">
              <div class="bottom">
                <CustomerModal
                  :hideAppointments="hideAppointments"
                  @delete="deleted"
                  @finish="finished"
                  :customer="fetchedCustomer"
                  :can-modify="canModify"
                ></CustomerModal>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomerModal from "./CustomerModal.vue";
import { ICustomerDTO } from "@shared/types";
import { customersModule } from "@/store/modules/customerModule/customerModule";

@Component({
  name: "CustomerModalWindow",
  components: { CustomerModal },
})
export default class CustomerModalWindow extends Vue {
  @Prop({}) customer;
  @Prop({}) customerId;
  @Prop({ default: false }) hideAppointments;
  @Prop({ type: Boolean, required: true }) canModify!: boolean;

  public fetchedCustomer: ICustomerDTO = null;

  dialogValue: boolean = false;

  async mounted() {
    if (this.customer != null) {
      this.fetchedCustomer = await customersModule.getCustomer(this.customer.id);
    }
    if (this.customerId != null) {
      this.fetchedCustomer = await customersModule.getCustomer(this.customerId);
    }
  }
  get dialog() {
    return this.dialogValue;
  }
  set dialog(value) {
    this.dialogValue = value;
    if (value == false) {
      this.$emit("finish");
    }
  }
  public async created(): Promise<any> {
    this.dialogValue = true;
  }

  deleted(customerId) {
    this.$emit("deleted", customerId);
  }

  finished() {
    this.dialogValue = false;
    this.$emit("finish");
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
