<template>
  <div id="app">
    <v-app class="application">
      <route-header />

      <settings-dialog v-model="settingsOpen" />

      <navigation-drawer
        v-if="mobileNavigation"
        v-model="mobileNavigation"
        :width="width"
        @settings-click="openSettings"
      />

      <DialogQueueDisplay />

      <AppSnackbar />

      <v-dialog max-width="500" v-model="showCreateCustomerDialog" :z-index="9999">
        <create-customer
          v-if="showCreateCustomerDialog"
          :defaultName="createCustomerDefaultName"
          @close="onCloseCreateCustomer"
          @created="onCustomerCreated"
        ></create-customer>
      </v-dialog>

      <div v-if="requireAuth && hasLayout">
        <sidebar />
        <main-header />
      </div>

      <div v-if="hasResolvedUser">
        <main-content />
      </div>
      <div v-else class="loader-wrapper-container">
        <loader />
      </div>

      <div v-if="requireAuth">
        <mobile-header v-if="showMobileToolbar" />
      </div>
    </v-app>
  </div>
</template>


<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Loader from "./components/global/Loader.vue";
import Layout from "./components/Layout.vue";
import DialogQueueDisplay from "./components/global/DialogQueueDisplay.vue";
import Sidebar from "./components/global/Sidebar.vue";
import MainHeader from "./components/global/MainHeader.vue";
import MobileHeader from "./components/MobileHeader.vue";
import RouteHeader from "./components/RouteHeader.vue";
import AppSnackbar from "./components/layout/AppSnackbar.vue";
import SettingsDialog from "./components/layout/SettingsDialog.vue";
import NavigationDrawer from "./components/layout/NavigationDrawer.vue";
import MainContent from "./components/layout/MainContent.vue";
import AuditLogger, { AuditLoggerType } from "./mixins/AuditLogger";
import { userPermissionsModule } from "./store/modules/userPermissionsModule/userPermissionsModule";
import { userModule } from "./store/modules/user/userModule";
import { layoutModule } from "./store/modules/layout/layoutModule";
import { dialogModule } from "./store/modules/dialogModule/dialogModule";
import { customersModule } from "./store/modules/customerModule/customerModule";
import CreateCustomer from "./components/global/CreateCustomer.vue";
import { IUserDTO } from "@shared/types";
import { i18n } from "./main";

// Extend Vue type to include $workbox
declare module "vue/types/vue" {
  interface Vue {
    $workbox: {
      addEventListener: (event: string, callback: () => void) => void;
      messageSW: (message: { type: string }) => void;
    };
  }
}

// Extend IUser type to include language


@Component({
  name: "App",
  components: {
    MobileHeader,
    MainHeader,
    Sidebar,
    DialogQueueDisplay,
    Loader,
    Layout,
    RouteHeader,
    AppSnackbar,
    SettingsDialog,
    NavigationDrawer,
    MainContent,
    CreateCustomer,
  },
  mixins: [AuditLogger],
})
export default class App extends Vue implements AuditLoggerType {
  settingsOpen = false;
  width = 0;
  height = 0;

  get hasResolvedUser() {
    return userModule.hasResolvedUser;
  }

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get mobileNavigation() {
    return layoutModule.mobileNavigation;
  }

  set mobileNavigation(v) {
    layoutModule.setMobileNavigation(v);
  }

  get user() {
    return userModule.user;
  }

  @Watch("user")
  onChangeUser(newUser: IUserDTO) {
    if (newUser?.language != null && newUser.language !== "" && newUser.language !== localStorage.getItem("locale")) {
      try{
        i18n.locale = newUser.language;
      } catch{

      }
      
      this.$i18n.locale = newUser.language;
      localStorage.setItem("locale", newUser.language);
    }
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  getDimensions(): void {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  }

  openSettings(): void {
    this.logAudit(" opened settings dialog");
    this.settingsOpen = true;
  }

  get requireAuth(): boolean {
    return this.$route.meta.requiresAuth != null && this.$route.meta.requiresAuth === true;
  }

  get hasLayout(): boolean {
    return this.$route.meta.hasLayout == null || this.$route.meta.hasLayout === true;
  }

  get showMobileToolbar(): boolean {
    return this.$route.meta.hideMobileHeader == null || this.$route.meta.hideMobileHeader === false;
  }

  get showCreateCustomerDialog() {
    return dialogModule.showCreateCustomerDialog;
  }

  get createCustomerDefaultName() {
    return dialogModule.createCustomerDefaultName;
  }

  async mounted(): Promise<void> {
    if (this.isMobile()) {
      this.getDimensions();
      window.addEventListener("resize", this.getDimensions);
    }
  }

  async created(): Promise<void> {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.$workbox.messageSW({ type: "SKIP_WAITING" });
      });
    }
    try {
    } catch (error) {
      console.error("Failed to fetch initial metadata:", error);
    }
  }

  onCloseCreateCustomer() {
    dialogModule.closeCreateCustomer();
  }

  onCustomerCreated(newCustomer: { id: number }) {
    dialogModule.closeCreateCustomer(newCustomer.id);
    // Refresh customers list
    customersModule.getCustomers();
  }
}
</script>

<style lang="scss">
@use "./styles/transitions";
@use "./styles/app";
</style>
