<template>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{ $t($ts.dialog.confirmDeleteService) }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          {{ $t($ts.buttons.cancel) }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="confirm">
          {{ $t($ts.buttons.delete) }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DeleteExpenseDialog extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;

  get dialogVisible(): boolean {
    return this.visible;
  }

  set dialogVisible(value: boolean) {
    this.$emit("update:visible", value);
  }

  private close() {
    this.dialogVisible = false;
  }

  private confirm() {
    this.$emit("confirm");
    this.close();
  }
}
</script>
