<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { onboardingProgressType } from "@/types/OnboardingProgress";
import { Sector } from "@/types/Sector";
import { IUserDTO } from "@shared/types";
import CompanyInfoField from "../CompanyInfoField.vue";
import Textfield from "../Textfield.vue";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { userModule } from "@/store/modules/user/userModule";
export interface ILocalUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Component({
  name: "Register-user",
  components: {
    Textfield,
    CompanyInfoField,
  },
})
export default class RegisterUser extends Vue {
  @Prop() public currentStep;

  localUser: ILocalUser = { firstName: "", lastName: "", phoneNumber: "" };
  menu: boolean = false;
  modal: boolean = false;
  hasGottenUser: boolean = false;

  validateFormFields(): boolean {
    if (this.localUser.firstName === "") {
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.login.firstNameRequired).toString(),
      });
      return false;
    }

    if (this.localUser.lastName === "") {
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.login.lastNameRequired).toString(),
      });
      return false;
    }
    return true;
  }

  clickHandler(): void {
    this.$emit("register-user-click", this.validateFormFields());
  }

  @Watch("currentStep")
  checkElValue(newval, oldVal) {
    if (oldVal === 1) {
      let update: Partial<IUserDTO> = {
        firstName: this.localUser.firstName,
        lastName: this.localUser.lastName,
        phoneNumber: this.localUser.phoneNumber,
        //@ts-ignore, I have to fix reinforced typings - or just create my own god damn python script
        language: this.$i18n.locale,
      };

      //TODO: Remove this function, I think this is broken.. its not used. authHander.ts is actually doing this.
      if (this.validateFormFields()) {
        //Information for initial staff member
        userModule.updateUser(update);
      }
    }
  }
}
</script>

<template>
  <v-card color="white" style="width: 500px">
    <v-card-text>
      <CompanyInfoField :label="$t($ts.login.firstName)">
        <Textfield v-model="localUser.firstName" :label="$t($ts.login.firstName)" />
      </CompanyInfoField>
      <CompanyInfoField :label="$t($ts.login.lastName)">
        <Textfield v-model="localUser.lastName" :label="$t($ts.login.lastName)" />
      </CompanyInfoField>
      <CompanyInfoField :label="$t($ts.login.phoneNumber)">
        <Textfield v-model="localUser.phoneNumber" :label="$t($ts.login.phoneNumber)" />
      </CompanyInfoField>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" @click="clickHandler">
        {{ $t($ts.login.next) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
