//************************************************************************************
//* Script ***************************************************************************
//************************************************************************************

<script lang="ts">
    "use strict";

    import Vue from "vue";
    import {Component, Prop} from 'vue-property-decorator';
    import {AnimationTypes} from "@/types/AnimationTypes";


    //* Component definition ************************************************************

    @Component({
        name: "loader"
    })

//* Class ***************************************************************************

    export default class Loader extends Vue {
        @Prop({default:AnimationTypes.SPINNER}) animationType:AnimationTypes;

        created(){
        }

    }

</script>

//************************************************************************************
//* Template *************************************************************************
//************************************************************************************

<template>
    <div class="animation-wrapper">
        <div :class="animationType">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<style lang="scss">

.animation-wrapper{
  margin:0 auto;
  height:30px;
  display: block;
  transform: scale(0.5);
}

/*******************************************/
/************** Spinner *******************
*******************************************/

.spinner{
  position:relative;
  width:25px;
  height:25px;
  background:$color-cerulean;
  border-radius:50px;
  -webkit-animation: preloader_5 1.5s infinite linear;
  -moz-animation: preloader_5 1.5s infinite linear;
  -ms-animation: preloader_5 1.5s infinite linear;
  animation: preloader_5 1.5s infinite linear;
  margin: 0 auto;
}
.spinner:after{
  position:absolute;
  width:55px;
  height:55px;
  border-top:10px solid slategray;
  border-bottom:10px solid slategray;
  border-left:10px solid transparent;
  border-right:10px solid transparent;
  border-radius:50px;
  content:'';
  top:-15px;
  left:-15px;
  -webkit-animation: preloader_5_after 1.5s infinite linear;
  -moz-animation: preloader_5_after 1.5s infinite linear;
  -ms-animation: preloader_5_after 1.5s infinite linear;
  animation: preloader_5_after 1.5s infinite linear;
}


@-webkit-keyframes preloader_5 {
  0% {-webkit-transform: rotate(0deg);}
  50% {-webkit-transform: rotate(180deg);background:$color-cerulean}
  100% {-webkit-transform: rotate(360deg);}
}
@-webkit-keyframes preloader_5_after {
  0% {border-top:10px solid #9b59b6;border-bottom:10px solid $darkslategray}
  50% {border-top:10px solid #3498db;border-bottom:10px solid $darkslategray}
  100% {border-top:10px solid #9b59b6;border-bottom:10px solid $darkslategray}
}


@-moz-keyframes preloader_5 {
  0% {-moz-transform: rotate(0deg);}
  50% {-moz-transform: rotate(180deg);}
  100% {-moz-transform: rotate(360deg);}
}
@-moz-keyframes preloader_5_after {
  0% {border-top:10px solid  $darkslategray;border-bottom:10px solid $darkslategray}
  50% {border-top:10px solid  $darkslategray;border-bottom:10px solid $color-cerulean}
  100% {border-top:10px solid  $darkslategray;border-bottom:10px solid $darkslategray}
}

@-ms-keyframes preloader_5 {
  0% {-ms-transform: rotate(0deg);}
  50% {-ms-transform: rotate(180deg);background:$darkslategray}
  100% {-ms-transform: rotate(360deg);}
}
@-ms-keyframes preloader_5_after {
  0% {border-top:10px solid $darkslategray;border-bottom:10px solid $darkslategray;}
  50% {border-top:10px solid $darkslategray;border-bottom:10px solid $darkslategray;}
  100% {border-top:10px solid $darkslategray;border-bottom:10px solid $darkslategray;}
}

@keyframes preloader_5 {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);background:$color-cerulean}
  100% {transform: rotate(360deg);}
}
@keyframes preloader_5_after {
  0% {border-top:10px solid $darkslategray;border-bottom:10px solid $darkslategray;}
  50% {border-top:10px solid $color-cerulean;border-bottom:10px solid $darkslategray;}
  100% {border-top:10px solid $darkslategray;border-bottom:10px solid $darkslategray;}
}


/*******************************************/
/************** Wave *******************
*******************************************/

.waves{
  width: 54px;
  height: 29px;
  margin: 0 auto;
  position: relative;
}
.waves span{
  display:block;
  bottom:0px;
  width: 9px;
  height: 5px;
  background:#9b59b6;
  position:absolute;
  -webkit-animation: waves 1.5s    infinite ease-in-out;
  -moz-animation: waves 1.5s   infinite ease-in-out;
  -ms-animation: waves 1.5s    infinite ease-in-out;
  -o-animation: waves 1.5s     infinite ease-in-out;
  animation: waves 1.5s    infinite ease-in-out;
}
.waves span:nth-child(2){
  left:11px;
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -ms-animation-delay: .2s;
  -o-animation-delay: .2s;
  animation-delay: .2s;
}
.waves span:nth-child(3){
  left:22px;
  -webkit-animation-delay: .4s;
  -moz-animation-delay: .4s;
  -ms-animation-delay: .4s;
  -o-animation-delay: .4s;
  animation-delay: .4s;
}
.waves span:nth-child(4){
  left:33px;
  -webkit-animation-delay: .6s;
  -moz-animation-delay: .6s;
  -ms-animation-delay: .6s;
  -o-animation-delay: .6s;
  animation-delay: .6s;
}
.waves span:nth-child(5){
  left:44px;
  -webkit-animation-delay: .8s;
  -moz-animation-delay: .8s;
  -ms-animation-delay: .8s;
  -o-animation-delay: .8s;
  animation-delay: .8s;
}
@-webkit-keyframes waves {
  0% {height:5px;-webkit-transform:translateY(0px);background:#9b59b6;}
  25% {height:30px;-webkit-transform:translateY(15px);background:#3498db;}
  50% {height:5px;-webkit-transform:translateY(0px);background:#9b59b6;}
  100% {height:5px;-webkit-transform:translateY(0px);background:#9b59b6;}
}
@-moz-keyframes waves {
  0% {height:5px;-moz-transform:translateY(0px);background:#9b59b6;}
  25% {height:30px;-moz-transform:translateY(15px);background:#3498db;}
  50% {height:5px;-moz-transform:translateY(0px);background:#9b59b6;}
  100% {height:5px;-moz-transform:translateY(0px);background:#9b59b6;}
}
@-ms-keyframes waves {
  0% {height:5px;-ms-transform:translateY(0px);background:#9b59b6;}
  25% {height:30px;-ms-transform:translateY(15px);background:#3498db;}
  50% {height:5px;-ms-transform:translateY(0px);background:#9b59b6;}
  100% {height:5px;-ms-transform:translateY(0px);background:#9b59b6;}
}
@keyframes waves {
  0% {height:5px;transform:translateY(0px);background:#9b59b6;}
  25% {height:30px;transform:translateY(15px);background:#3498db;}
  50% {height:5px;transform:translateY(0px);background:#9b59b6;}
  100% {height:5px;transform:translateY(0px);background:#9b59b6;}
}
</style>

//************************************************************************************
//* Css ******************************************************************************
//************************************************************************************

