<template>
  <div class="treatment-list">
    <div class="treatment">
      <v-data-table
        :headers="tableHeaders"
        :items="customers"
        :items-per-page="10"
        show-select
        :search="search"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template v-slot:item="props">
          <tr v-if="isMobile" @click="props.expand(!props.isExpanded)">
            <td>
              <v-row no-gutters align="center" class="mobile-row">
                <v-col cols="auto" class="checkbox-col">
                  <v-checkbox
                    :input-value="props.isSelected"
                    @click.stop="props.select(!props.isSelected)"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-row no-gutters class="customer-info">
                    <v-col cols="12" class="name-col">
                      <router-link :to="'/kunder/' + props.item.id" class="customer-name-link" @click.stop>
                        <span class="customer-name"> {{ props.item.firstName }} {{ props.item.lastName }} </span>
                      </router-link>
                    </v-col>
                    <v-col cols="12">
                      <span class="customer-email">
                        {{ props.item.emailAddress }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr v-else>
            <td>
              <v-checkbox :input-value="props.isSelected" @click="props.select(!props.isSelected)"></v-checkbox>
            </td>
            <td>
              <router-link
                v-if="props.item.firstName != null && props.item.firstName.length > 0"
                :to="'/kunder/' + props.item.id"
              >
                {{ props.item.firstName }} {{ props.item.lastName }}
              </router-link>
              <router-link v-else :to="'/kunder/' + props.item.id">
                {{ $t($ts.customer.customerWithoutName) }}
              </router-link>
            </td>
            <td>{{ props.item.emailAddress }}</td>
            <td>{{ props.item.phoneNumber }}</td>
            <td>{{ props.item.balance !== 0 ? formatCurrency(props.item.balance) : "" }}</td>
            <td v-if="canModify">
              <v-btn @click="$emit('edit', props.item)" icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>{{ formatDateStringHour(props.item.dateAdded) }}</td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <customer-expanded-details v-if="isMobile" :headers="headers" :customer="item"></customer-expanded-details>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatDateStringHour } from "../../../Utilities/dateUtility";
import CustomerExpandedDetails from "./CustomerExpandedDetails.vue";
import { userModule } from "@/store/modules/user/userModule";
import { ICustomerDTO } from "@shared/types";

@Component({
  name: "CustomerTable",
  components: {
    CustomerExpandedDetails,
  },
})
export default class CustomerTable extends Vue {
  @Prop({ type: Array, required: true }) customers!: Partial<ICustomerDTO>[];
  @Prop({ type: String, default: "" }) search!: string;
  @Prop({ type: Boolean, default: false }) isMobile!: boolean;
  @Prop({ type: Array, default: () => [] }) value!: Partial<ICustomerDTO>[];
  @Prop({ type: Boolean, required: true }) canModify!: boolean;

  get tableHeaders() {
    const headers = [
      {
        text: this.$t(this.$ts.customer.name),
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];

    if (this.isMobile) {
      headers.push({
        text: "",
        value: "data-table-expand",
        align: "start",
        sortable: false,
      });
      return headers;
    }

    headers.push(
      {
        text: this.$t(this.$ts.customer.email),
        align: "start",
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t(this.$ts.customer.phoneNumber),
        align: "start",
        sortable: true,
        value: "phoneNumber",
      },
      {
        text: this.$t(this.$ts.customer.credit),
        align: "start",
        sortable: true,
        value: "balance",
      }
    );

    if (this.canModify) {
      headers.push({
        text: this.$t(this.$ts.customer.edit),
        align: "start",
        value: "actions",
        sortable: false,
      });
    }

    headers.push({
      text: this.$t(this.$ts.customer.created),
      align: "start",
      sortable: true,
      value: "dateAdded",
    });

    return headers;
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }
}
</script>

<style scoped>
.treatment-list {
  margin-top: 20px;
}

.mobile-row {
  padding: 12px 0;
}

.checkbox-col {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.customer-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name-col {
  margin-bottom: 4px;
}

.customer-name-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.customer-name {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.customer-email {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
</style>
../../../store/customerModule ../../../store/customerModule/customerModule
../../../store/modules/customerModule/customerModule
