import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { PauseTimeService } from "@/services/api/PauseTimeService";
import { IPauseTimeDTO, IPostPauseTimeDTO, ISimplePauseTimeDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class PauseTimeModule extends VuexModule {
  // State
  pauseTimes: ISimplePauseTimeDTO[] = [];

  // Private service instance
  private pauseTimeService = PauseTimeService.getInstance();

  @Action
  async getPauseTimes(staffMemberId?: number) {
    this.pauseTimes = await this.pauseTimeService.getPauseTimes(staffMemberId);
  }

  @Action
  async addPauseTime(pauseTime: IPostPauseTimeDTO) {
    this.pauseTimes = await this.pauseTimeService.postPauseTime(pauseTime);
    await this.getPauseTimes();
  }

  @Action
  async deletePauseTime(id: number) {
    this.pauseTimes = await this.pauseTimeService.deletePauseTime(id);
    await this.getPauseTimes();
  }
}

export const pauseTimeModule = new PauseTimeModule({ store, name: "pauseTimeModule" });
