<script lang="ts" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
import { Component, Vue } from "vue-property-decorator";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { IDialog } from "@/store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import { formatDateStringHour } from "../../Utilities/dateUtility";
import { formatCurrency } from "@/Utilities/currencyHelper";
import { eventsModule } from "@/store/modules/events/eventsModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { IEventsController } from "@shared/types";

@Component({
  name: "EventsOverview",
  components: {
    CustomerModalWindow,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class EventsOverview extends Vue {
  public headers = [];
  isLoading: boolean = true;
  selectedEvents = [];

  options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["time"],
    sortDesc: [true],
  };

  get events() {
    return eventsModule.events;
  }

  get eventsPagination() {
    return eventsModule.eventsPagination;
  }

  async handleTableUpdate(options) {
    this.isLoading = true;
    try {
      await eventsModule.getEvents({
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        sortBy: options.sortBy.length > 0 ? options.sortBy[0] : "time",
        desc: options.sortDesc.length > 0 ? options.sortDesc[0] : true,
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async created(): Promise<any> {
    await this.handleTableUpdate(this.options);
    await eventsModule.markEventsAsRead();
  }

  public async mounted(): Promise<any> {
    this.headers = [
      { text: "", align: "start", sortable: false, value: "severity" },
      {
        text: this.$t(this.$ts.eventsOverview.message),
        sortable: true,
        value: "message",
      },
      {
        text: this.$t(this.$ts.eventsOverview.time),
        sortable: true,
        value: "time",
      },
      {
        text: this.$t(this.$ts.eventsOverview.comment),
        sortable: false,
        value: "comment",
      },
      {
        text: this.$t(this.$ts.eventsOverview.booking),
        sortable: false,
        value: "messageData",
      },
      {
        text: this.$t(this.$ts.eventsOverview.receipt),
        sortable: false,
        value: "receipt",
      },
      {
        text: this.$t(this.$ts.eventsOverview.reminder),
        sortable: false,
        value: "reminder",
      },
    ];
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  getColor(eventId) {
    if (eventId == 0) return "grey";
    if (eventId == 1) return "red";
    if (eventId == 2) return "green";
    if (eventId == 3) return "orange";
    if (eventId == 4) return "red";
    if (eventId == 5) return "orange";
    if (eventId == 6) return "green";
    if (eventId == 7) return "green";
  }

  getLabel(eventId) {
    if (eventId == 0) return this.$t(this.$ts.eventsOverview.guest);
    if (eventId == 1) return this.$t(this.$ts.eventsOverview.critical);
    if (eventId == 2) return this.$t(this.$ts.eventsOverview.info);
    if (eventId == 3) return this.$t(this.$ts.eventsOverview.warning);
    if (eventId == 4) return this.$t(this.$ts.eventsOverview.bookingCancelled);
    if (eventId == 5) return this.$t(this.$ts.eventsOverview.bookingChanged);
    if (eventId == 6) return this.$t(this.$ts.eventsOverview.booking);
    if (eventId == 7) return this.$t(this.$ts.eventsOverview.customer);
  }
  eventIdToString(eventId) {
    if (eventId == 0) {
      return this.$t(this.$ts.eventsOverview.guest);
    }
    if (eventId == 1) {
      return this.$t(this.$ts.eventsOverview.errorWithSmsSending);
    }
    if (eventId == 2) {
      return this.$t(this.$ts.eventsOverview.timeAddedToExistingCustomer);
    }
    if (eventId == 3) {
      return this.$t(this.$ts.eventsOverview.timeAddedToExistingCustomer);
    }
    if (eventId == 4) {
      return this.$t(this.$ts.eventsOverview.timeCancelled);
    }
    if (eventId == 5) {
      return this.$t(this.$ts.eventsOverview.timeChanged);
    }
    if (eventId == 6) {
      return "";
    }
    if (eventId == 7) {
      return this.$t(this.$ts.eventsOverview.newCustomer);
    }
  }
  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  readComment(comment) {
    let dialog: IDialog = {
      text: comment,
      header: this.$t(this.$ts.eventsOverview.customerComment).toString(),
    };
    dialogModule.addToDialogQueue(dialog);
  }
}
</script>

<template>
  <div>
    <div>
      <div>
        <v-card-text>
          <div>
            <v-data-table
              :headers="headers"
              :items="events"
              :items-per-page="options.itemsPerPage"
              :loading="isLoading"
              :server-items-length="eventsPagination?.total"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
                showFirstLastPage: true,
              }"
              @update:options="handleTableUpdate"
            >
              <template v-slot:[`item.severity`]="{ item }">
                <v-chip small :color="getColor(item.eventId)" dark>{{ getLabel(item.eventId) }}</v-chip>
              </template>

              <template v-slot:[`item.time`]="{ item }">
                {{ formatDateStringHour(item.time) }}
              </template>

              <template v-slot:[`item.messageData`]="{ item }">
                <router-link v-if="item.messageData" :to="'/bestillinger/' + item.messageData">
                  {{ item.messageData }}
                </router-link>
              </template>

              <template v-slot:[`item.receipt`]="{ item }">
                <v-icon :color="item.receipt ? 'green' : 'grey'">
                  {{ item.receipt ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>

              <template v-slot:[`item.reminder`]="{ item }">
                <v-icon :color="item.reminder ? 'green' : 'grey'">
                  {{ item.reminder ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </div>
    </div>
  </div>
</template>
