<script lang="ts">
// @ts-nocheck

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IUser } from "../store/user";
import Loader from "./global/Loader.vue";
import { IAppointmentInfoDTO } from "@shared/types";
import { IDialog } from "@/store/dialog";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "InvoiceCreator",
  components: { Loader },
})
export default class InvoiceCreator extends Vue {
  @Prop({ default: null }) appointment;
  @Prop({ default: false }) creatingNew;
  @Prop({ default: false }) showDialog;
  @Prop({ default: false }) defaultAmount;

  public postalCodeRules = [];
  public cityRules = [];
  public addressRules = [];
  public amountRules = [];
  public priceRules = [];
  public newInvoiceTitle: string = "";

  markAsPaid: boolean = true;
  invoiceValid: boolean = true;
  invoiceForm: boolean = true;
  newInvoice: boolean = false;
  isEditing: boolean = true;
  creatingInvoice: {} = {};
  chosenAppointment: IAppointmentInfoDTO = null;
  price: number = 0;
  discount: number = 0;
  amount: number = 1;
  vat: number = 25;
  invoiceNumber: number = 0;

  get discountRules() {
    return [this.discount <= this.price * this.amount || this.$t(this.$ts.InvoiceCreator.discountRules.maxValue)];
  }

  get typedUser(): IUser {
    return this.user;
  }

  closeNewInvoice() {
    this.chosenAppointment = null;
    this.$emit("close");
  }
  openNewInvoice() {
    this.newInvoice = true;
  }

  get totalSum() {
    return (this.price * this.amount - this.discount) * (1 + this.vat / 100);
  }

  async onChosenAppointment(appointment: IAppointmentInfoDTO) {
    this.invoiceNumber = await this.getInvoiceNumber();
    this.chosenAppointment = appointment;
    this.price = this.chosenAppointment.appointmentTypePrice;

    if (
      this.chosenAppointment.customerInsuranceId != null &&
      this.chosenAppointment.customerInsuranceId != 0 &&
      this.chosenAppointment.customerInsuranceId != -1
    ) {
      let res: Partial<IInsurance> = await this.getInsuranceById(this.chosenAppointment.customerInsuranceId);
      this.chosenAppointment.customerEmail = res.emailAddress;
      this.chosenAppointment.customerCity = res.city;
      this.chosenAppointment.customerPostalCode = res.postalCode;
      this.chosenAppointment.customerAddress = res.address;
      this.chosenAppointment.customerName = res.name;
    }
  }

  async addInvoice(send: boolean) {
    const invoiceForm: any = this.$refs.invoiceForm;

    if (!invoiceForm.validate()) {
      return;
    }

    if (this.user.organizationNumber == 0) {
      let dialog: IDialog = {
        text: this.$t(this.$ts.InvoiceCreator.missingOrgNumber),
      };

      this.addToDialogQueue(dialog);
      return;
    }

    if (this.user.bankAccount == 0) {
      let dialog: IDialog = {
        text: this.$t(this.$ts.InvoiceCreator.missingBankAccount),
      };

      this.addToDialogQueue(dialog);
      return;
    }

    const price: string = this.price as string;
    const vat: string = this.vat as string;

    let newInvoice: IInvoice = {
      id: 0,
      invoiceNumber: 0,
      senderName: "",
      customerName: this.chosenAppointment.customerName,
      customerAddress: this.chosenAppointment.customerAddress,
      customerEmailAddress: this.chosenAppointment.customerEmail,
      customerPhoneNumber: this.chosenAppointment.customerPhone,
      customerPostalCode: this.chosenAppointment.customerPostalCode,
      customerCity: this.chosenAppointment.customerCity,
      customerCountry: "Norge",
      dueDays: 30, //Obsolete
      appointmentId: this.chosenAppointment.id,
      appointmentServicePrice: parseFloat(price),
      chargeAmount: parseFloat(this.totalSum),
      customerAccountId: 0,
      customerId: this.chosenAppointment.customerId,
      bankAccount: this.user.bankAccount,
      organizationNumber: this.user.organizationNumber,
      service: this.chosenAppointment.appointmentName,
      companyName: this.user.companyName,
      companyEmail: this.user.emailAddress,
      companyPhone: this.user.phoneNumber,
      serviceQuantity: this.amount,
      dischargeAmount: this.discount,
      vat: parseFloat(vat),
      status: "Ny",
      appointmentNumber: this.chosenAppointment.number,
      markAsPaid: this.markAsPaid,
    };

    try {
      let res = await this.postInvoice(newInvoice);
      this.postSnackbarMessage(this.$t(this.$ts.InvoiceCreator.invoiceCreated));
      if (send) {
        this.setSendingInvoice(res);
      }

      this.$emit("finished");
    } catch (e) {
      this.postSnackbarMessage(this.$t(this.$ts.InvoiceCreator.couldNotCreate));
    }

    this.closeNewInvoice();
  }

  beforeCreate() {
    //this.$options.components.AppointmentList = require("../pages/admin/AppointmentList.vue").default;
  }

  formatNumber(v) {
    let value = v.toLocaleString("no-NO", {
      style: "currency",
      currency: "NOK",
    }); /* $2,500.00 */
    value = value.replace(",", " ");
    value = value.replace(".", ",");

    return value;
  }

  formatBankAccount(s) {
    if (s == null || s.length == 0 || s == 0) {
      return this.$t(this.$ts.InvoiceCreator.company.missingBankAccount);
    }
    let v = s.toString().split("");
    v.splice(4, 0, " ");
    v.splice(7, 0, " ");
    return v.join("");
  }

  get user() {
    return userModule.user;
  }

  async created() {
    this.postalCodeRules = [(v) => !!v || this.$t(this.$ts.InvoiceCreator.postalCodeRequired)];

    this.cityRules = [(v) => !!v || this.$t(this.$ts.InvoiceCreator.cityRequired)];

    this.addressRules = [(v) => !!v || this.$t(this.$ts.InvoiceCreator.addressRequired)];

    this.amountRules = [
      (v) => !!v || this.$t(this.$ts.InvoiceCreator.amountRules.required),
      (v) => v >= 0 || this.$t(this.$ts.InvoiceCreator.amountRules.positive),
    ];

    this.priceRules = [
      (v) => !!v || this.$t(this.$ts.InvoiceCreator.priceRules.required),
      (v) => v >= 0 || this.$t(this.$ts.InvoiceCreator.priceRules.positive),
    ];

    this.vat = this.user.taxPercentage;
    if (this.vat == null) {
      this.vat = 0;
    }

    if (this.defaultAmount != null) {
      this.price = this.defaultAmount;
    }
    if (this.appointment != null) {
      this.chosenAppointment = this.appointment;
      this.invoiceNumber = await this.getInvoiceNumber();
      this.price = this.chosenAppointment.appointmentTypePrice;

      if (
        this.chosenAppointment.customerInsuranceId != null &&
        this.chosenAppointment.customerInsuranceId != 0 &&
        this.chosenAppointment.customerInsuranceId != -1
      ) {
        let res: Partial<IInsurance> = await this.getInsuranceById(this.chosenAppointment.customerInsuranceId);
        this.chosenAppointment.customerEmail = res.emailAddress;
        this.chosenAppointment.customerCity = res.city;
        this.chosenAppointment.customerPostalCode = res.postalCode;
        this.chosenAppointment.customerAddress = res.address;
        this.chosenAppointment.customerName = res.name;
      }
    } else {
      this.chosenAppointment = null;
    }

    this.newInvoiceTitle = this.$t(this.$ts.InvoiceCreator.title);
    this.amount = 1;
    this.isEditing = true;
  }
}
</script>

<template>
  <div>
    <v-dialog fullscreen v-model="showDialog" :close-on-content-click="false" persistent persist offset-x>
      <v-card color="grey lighten-4" flat style="padding: 0">
        <v-card-title fixed class="headline grey lighten-2" primary-title>
          {{ newInvoiceTitle }}
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click="closeNewInvoice">
            {{ $t($ts.InvoiceCreator.close) }}
          </v-btn>
        </v-card-title>

        <div id="canvas" ref="pdfcontent" class="pa-5">
          <appointment-list
            v-if="chosenAppointment == null"
            @selected="onChosenAppointment"
            :chooseAppointment="true"
            :showPaid="false"
          ></appointment-list>
          <div v-else>
            <v-card flat :class="[isEditing ? '' : 'ma-10']">
              <v-col>
                <v-row>
                  <v-col>
                    <v-row>
                      <img
                        alt="company logo"
                        v-if="typedUser.profilePictureUrl != null"
                        :src="typedUser.profilePictureUrl"
                        width="50"
                        height="50"
                      />
                    </v-row>

                    <v-row>
                      <br />
                      {{ user.companyName }}
                      <br />
                      {{ user.treatmentAdress }}
                      <br />
                      <!-- {{ user.postalCode }}, {{ user.city }} -->
                    </v-row>

                    <v-row>
                      <v-form ref="invoiceForm" v-model="invoiceValid" lazy-validation>
                        <v-divider> </v-divider>

                        <v-col v-if="isEditing">
                          <p>{{ $t($ts.InvoiceCreator.customer.title) }}</p>
                          <v-row class="my-0">
                            <v-text-field
                              v-model="chosenAppointment.customerName"
                              :label="$t($ts.InvoiceCreator.customer.name)"
                              required
                              :outlined="isEditing"
                              hide-details
                              dense
                              solo
                            ></v-text-field>
                          </v-row>

                          <v-row>
                            <v-text-field
                              v-model="chosenAppointment.customerAddress"
                              :label="$t($ts.InvoiceCreator.customer.address)"
                              required
                              dense
                              :rules="addressRules"
                              hide-details
                              :outlined="isEditing"
                              solo
                            ></v-text-field>
                          </v-row>

                          <v-row>
                            <v-text-field
                              v-model="chosenAppointment.customerPostalCode"
                              :label="$t($ts.InvoiceCreator.customer.postalCode)"
                              required
                              :rules="postalCodeRules"
                              :outlined="isEditing"
                              dense
                              hide-details
                              solo
                            ></v-text-field>

                            <v-text-field
                              v-model="chosenAppointment.customerCity"
                              :label="$t($ts.InvoiceCreator.customer.city)"
                              required
                              :outlined="isEditing"
                              hide-details
                              :rules="cityRules"
                              dense
                              solo
                            ></v-text-field>
                          </v-row>
                        </v-col>

                        <v-col v-else>
                          <br />
                          {{ chosenAppointment.customerName }}
                          <br />
                          {{ chosenAppointment.customerAddress }}
                          <br />
                          {{ chosenAppointment.customerPostalCode }},
                          {{ chosenAppointment.customerCity }}
                          <br />
                        </v-col>

                        <v-divider> </v-divider>
                      </v-form>
                    </v-row>
                  </v-col>

                  <v-col>
                    <h3>Faktura</h3>

                    <v-row v-if="invoiceNumber !== 0">
                      <br />
                      {{ $t($ts.InvoiceCreator.invoiceNumber) }}
                      {{ invoiceNumber }}
                      <br />
                      {{ $t($ts.InvoiceCreator.invoiceDate) }}
                      {{ $t($ts.InvoiceCreator.today) }}
                      <br />
                      {{ $t($ts.InvoiceCreator.dueDate) }}
                      {{ $t($ts.InvoiceCreator.thirtyDaysFromNow) }}
                      <br />
                    </v-row>
                    <div v-else>
                      <loader></loader>
                    </div>

                    <br />
                    <v-divider> </v-divider>

                    <br />

                    <strong>{{ user.companyName }}</strong>

                    <div v-if="typedUser.organizationNumber === 0">
                      <strong style="color: red">{{ $t($ts.InvoiceCreator.organization.missing) }}</strong>
                    </div>
                    <v-row>
                      <br />
                      {{ $t($ts.InvoiceCreator.organization.number) }}
                      {{ user.organizationNumber }}
                      <br />
                      {{ $t($ts.InvoiceCreator.organization.phone) }}
                      {{ user.phoneNumber }}
                      <br />
                      {{ $t($ts.InvoiceCreator.organization.email) }}
                      {{ user.emailAddress }}
                      <br />
                    </v-row>
                    <br />
                    <v-divider> </v-divider>
                    <br />
                  </v-col>
                </v-row>

                <v-row>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t($ts.InvoiceCreator.invoice.description) }}
                          </th>
                          <th class="text-left">
                            {{ $t($ts.InvoiceCreator.invoice.price) }}
                          </th>
                          <th class="text-left">
                            {{ $t($ts.InvoiceCreator.invoice.amount) }}
                          </th>
                          <th class="text-left">
                            {{ $t($ts.InvoiceCreator.invoice.discount) }}
                          </th>
                          <th class="text-left">
                            {{ $t($ts.InvoiceCreator.invoice.vat) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <v-text-field
                              v-model="chosenAppointment.appointmentName"
                              required
                              :outlined="isEditing"
                              class="ma-1"
                              dense
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="price"
                              required
                              :outlined="isEditing"
                              type="number"
                              :rules="priceRules"
                              class="ma-1"
                              dense
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="amount"
                              required
                              :rules="amountRules"
                              :outlined="isEditing"
                              width="57"
                              type="number"
                              class="ma-1"
                              dense
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="discount"
                              required
                              :rules="discountRules"
                              :outlined="isEditing"
                              type="number"
                              class="ma-1"
                              dense
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="vat"
                              required
                              :outlined="isEditing"
                              class="ma-1"
                              dense
                            ></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
                <v-row>
                  <br />

                  <strong>
                    {{ $t($ts.InvoiceCreator.invoice.totalAmount) }}:
                    {{ formatNumber(totalSum) }}
                  </strong>

                  <br />
                  <br />
                  <br />
                  <br />
                </v-row>

                <v-divider></v-divider>

                <h2>{{ $t($ts.InvoiceCreator.invoice.paymentInfo) }}</h2>

                <v-row>
                  <br />
                  <br />
                  <v-col>
                    <strong>{{ $t($ts.InvoiceCreator.invoice.number) }}: {{ invoiceNumber }}</strong>
                    <br />
                    {{ $t($ts.InvoiceCreator.invoice.amountToPay) }}:
                    {{ formatNumber(totalSum) }}
                  </v-col>
                  <v-col>
                    <strong
                      style="color: red"
                      v-if="
                        typedUser.bankAccount == null ||
                        typedUser.bankAccount.length === 0 ||
                        typedUser.bankAccount === 0
                      "
                    >
                      {{ $t($ts.InvoiceCreator.missingBankAccount) }}
                    </strong>
                    <div v-else>
                      <strong
                        >{{ $t($ts.InvoiceCreator.bankAccount) }}: {{ formatBankAccount(user.bankAccount) }}</strong
                      >
                    </div>
                  </v-col>

                  <v-col v-if="typedUser.useKID">
                    <strong>KID: [KID nummer]</strong>
                  </v-col>
                </v-row>
              </v-col>
              <v-card-actions>
                <v-row v-if="isEditing">
                  <v-btn @click="closeNewInvoice">{{ $t($ts.InvoiceCreator.cancel) }}</v-btn>
                  <v-btn color="success" @click="addInvoice(false)">
                    {{ $t($ts.InvoiceCreator.createInvoice) }}
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn color="success" @click="addInvoice(true)">
                    {{ $t($ts.InvoiceCreator.createAndSend) }}
                    <v-icon>send</v-icon>
                  </v-btn>
                </v-row>
              </v-card-actions>

              <v-card-actions>
                <v-checkbox
                  v-model="markAsPaid"
                  :label="$t($ts.InvoiceCreator.markAsPaidCheckbox)"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped></style>
