import { userModule } from "@/store/modules/user/userModule";
import { formatCurrencyEnhanced } from "./currencyHelper";

/**
 * Utility class for formatting currency values
 */
export class CurrencyFormatter {
  /**
   * Format a currency value using the user's currency and locale settings
   * Automatically handles special cases like Colombian Peso (COP)
   * @param amount The amount to format
   * @param locale Optional locale override
   * @returns Formatted currency string
   */
  static format(amount: number, locale?: string): string {
    return formatCurrencyEnhanced(
      amount,
      userModule.user?.currency || "USD",
      locale
    );
  }

  /**
   * Format a currency value with a specific currency code
   * @param amount The amount to format
   * @param currencyCode The currency code to use
   * @param locale Optional locale override
   * @returns Formatted currency string
   */
  static formatWithCurrency(amount: number, currencyCode: string, locale?: string): string {
    return formatCurrencyEnhanced(amount, currencyCode, locale);
  }

  /**
   * Format a currency value specifically as Colombian Peso
   * @param amount The amount to format
   * @returns Formatted currency string in Colombian Peso format
   */
  static formatAsCOP(amount: number): string {
    return formatCurrencyEnhanced(amount, "COP", "es-CO");
  }

  /**
   * Format a currency value without the currency symbol
   * @param amount The amount to format
   * @param locale Optional locale override
   * @returns Formatted number string without currency symbol
   */
  static formatNumberOnly(amount: number, locale?: string): string {
    const userCurrency = userModule.user?.currency || "USD";
    
    // Special case for Colombian Peso
    if (userCurrency === "COP") {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    }
    
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }
} 