<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import Loader from "@/components/global/Loader.vue";
import StripePayment from "./StripePayment.vue";
import { formatDate, FormatSpaceType, formatTime } from "@/types/formatDateHelper";
import {
  IAppointmentDTO,
  IAppointmentForWeeklyCalenderDTO,
  IAuditLogDTO,
  IStaffMemberDTO,
  IUserDTO,
  IPostAuditLogDTO,
} from "@shared/types";
import { formatCurrency } from "@/Utilities/currencyHelper";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { userModule } from "@/store/modules/user/userModule";
import { appointmentsModule, IAppointmentPatch } from "@/store/modules/appointments/appointmentModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

@Component({
  name: "appointmentsummary",
  components: { StripePayment, Loader, CustomButton },
})
export default class AppointmentSummary extends Vue {
  @Prop({ default: null }) selectedEvent: IAppointmentForWeeklyCalenderDTO;

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get staffMembers(): Partial<IStaffMemberDTO>[] {
    return staffMemberModule.staffMembers;
  }

  get user(): IUserDTO {
    return userModule.user;
  }

  async viewCustomer(customerId: number) {
    if (!this.canWriteAppointmentsAndClients) {
      return;
    }
    await this.$router.push("/kunder/" + customerId + "/").catch(() => {});
  }

  formatEventDateString(event: IAppointmentForWeeklyCalenderDTO) {
    if (event.start == null) {
      return "";
    }

    let toDateObject = new Date(event.start as string);
    return formatDate(toDateObject, FormatSpaceType.DOT);
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  formatEventTimeString(event: IAppointmentForWeeklyCalenderDTO) {
    let start = event.start;
    let end = event.end;

    if (start == null) {
      return "";
    }

    let toDateObject = new Date(start as string);
    let toDateObject2 = new Date(end as string);
    return (
      formatTime(toDateObject.getHours(), toDateObject.getMinutes()) +
      " - " +
      formatTime(toDateObject2.getHours(), toDateObject2.getMinutes())
    );
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }

  async onChangeStaffMember(e) {
    console.log("On change staff member: ", e);

    const payload = {
      appointmentId: this.selectedEvent.appointmentId,
      staffMemberId: e,
    };

    const auditDto: IPostAuditLogDTO = {
      action: 3,
      type: 4,
      comment: "",
      message: " quickly changed staff member to " + e + " in appointment " + this.selectedEvent.appointmentId,
      hideForUser: true,
    };

    await appointmentsModule.changeAppointmentStaff(payload);
    this.$emit("close");
    await auditLogModule.postAudit({ dto: auditDto });
  }

  updateAdminComment(event, selectedEvent: IAppointmentForWeeklyCalenderDTO) {
    let update: Partial<IAppointmentDTO> = {
      adminNote: selectedEvent.adminNote,
    };

    const payload: IAppointmentPatch = {
      appointmentId: selectedEvent.appointmentId,
      patch: update,
    };

    appointmentsModule.patchAppointment(payload);
  }

  get price() {
    if (this.selectedEvent.appointmentPrice != null) return this.selectedEvent.appointmentPrice;
    //@ts-ignore
    return this.selectedEvent.appointmentTypePrice;
  }

  formatDateCorrectly(day: number, month: number, year: number): string {
    let date = month + "/" + day + "/" + year;
    let datObject = new Date(date);
    return formatDate(datObject, FormatSpaceType.DOT);
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return formatTime(hour, minute);
  }

  get staffMembersProvidingService() {
    return this.staffMembers.filter((x) => {
      return this.selectedEvent.staffMembersProvidingService.includes(x.id);
    });
  }

  formatAppointmentEnd(event) {
    let start = new Date(event.year, event.month - 1, event.day, event.hour, event.minute, 0);
    //@ts-ignore
    let end = new Date(start.getTime() + event.appointmentDuration * 1000 * 60);
    let h = end.getHours();
    let m = end.getMinutes();
    return this.formatTimeCorrectly(h, m);
  }
}
</script>

<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="10" v-if="selectedEvent.createdByCustomer">
        <v-icon>star</v-icon><span>{{ $t($ts.appointmentSummary.fromOnlineBooking) }}</span>
      </v-col>
    </v-row>

    <br />
    <div v-if="!selectedEvent.isCustom">
      <div v-if="selectedEvent.customerId !== 0 && selectedEvent.customerId !== -1">
        <div v-if="selectedEvent.name != null">
          <v-icon>mdi-account-circle</v-icon>
          <template v-if="canWriteAppointmentsAndClients">
            <a
              style="text-decoration: underline"
              @click="viewCustomer(selectedEvent.customerId)"
              class="customer-details"
              >{{ selectedEvent.name }}</a
            >
          </template>
          <template v-else>
            <span class="customer-details">{{ selectedEvent.name }}</span>
          </template>
        </div>
        <br />
        <v-icon>phone</v-icon
        ><a :href="'tel:' + selectedEvent.customerPhone" class="customer-details">{{ selectedEvent.customerPhone }}</a>
      </div>
      <div v-else>
        <div v-if="selectedEvent.name != null">
          <v-icon>mdi-account-circle</v-icon><span class="customer-details">{{ selectedEvent.name }}</span>
        </div>
        <div v-if="selectedEvent.name != null">
          <v-icon>mdi-account-circle</v-icon><span class="customer-details">{{ selectedEvent.name }}</span>
        </div>
        <br />
        <v-icon>phone</v-icon
        ><a :href="'tel:' + selectedEvent.customerPhone" class="customer-details">{{ selectedEvent.customerPhone }}</a>
      </div>
      <v-icon>email</v-icon><span class="customer-details">{{ selectedEvent.customerEmail }}</span>
      <br />
      <v-row style="max-width: 350px">
        <v-col cols="12">
          <v-icon>shopping_basket</v-icon><span class="customer-details">{{ selectedEvent.appointmentName }}</span>
        </v-col>
      </v-row>
    </div>
    <div v-if="user.showServicePrice && selectedEvent.appointmentPrice !== -1">
      <v-icon>mdi-cash</v-icon
      ><span class="customer-details"> {{ $t($ts.currency) }} {{ formatCurrency(price) }} </span>
    </div>

    <div v-if="selectedEvent.start != null">
      <v-icon>calendar_today</v-icon><span class="customer-details">{{ formatEventDateString(selectedEvent) }}</span>
      <br />
      <v-icon>schedule</v-icon><span class="customer-details">{{ formatEventTimeString(selectedEvent) }}</span>
    </div>
    <div v-if="selectedEvent.start == null">
      <v-icon>calendar_today</v-icon
      ><span class="customer-details">{{
        formatDateCorrectly(selectedEvent.day, selectedEvent.month, selectedEvent.year)
      }}</span>
      <br />
      <v-icon>schedule</v-icon
      ><span class="customer-details"
        >{{ formatTimeCorrectly(selectedEvent.hour, selectedEvent.minute) }} -
        {{ formatAppointmentEnd(selectedEvent) }}</span
      >
    </div>

    <div v-for="customField in selectedEvent.customFields" :key="customField[0]">
      <br />
      <span class="customer-details">{{ customField[0] }}: {{ customField[1] }}</span>
      <br />
    </div>
    <br />

    <div v-if="selectedEvent.isCustom">
      <v-row>
        <v-col cols="6">
          <v-icon>sticky_note_2</v-icon><span class="customer-details">{{ selectedEvent.name }}</span>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
    </div>

    <v-row v-if="hasMultipleStaff">
      <v-col v-if="selectedEvent.staffMemberId !== -1 && selectedEvent.staffMemberId !== 0">
        <v-icon>mdi-account-supervisor-circle</v-icon
        ><span class="customer-details">{{ selectedEvent.staffMemberName }}</span>
      </v-col>

      <v-col v-else-if="canWriteAppointmentsAndClients">
        <v-select
          prepend-icon="mdi-account-supervisor-circle"
          :items="staffMembersProvidingService"
          @change="onChangeStaffMember"
          v-model="selectedEvent.staffMemberId"
          item-text="fullName"
          item-value="id"
        >
          <template v-slot:label>
            <span style="color: red; font-size: 24px"></span>{{ $t($ts.appointmentSummary.assignStaff) }}
          </template>
        </v-select>
      </v-col>

      <v-col v-else>
        <v-icon>mdi-account-supervisor-circle</v-icon
        ><span class="customer-details">{{ $t("appointmentSummary.unassigned") }}</span>
      </v-col>
    </v-row>

    <div v-if="selectedEvent.comment != null && selectedEvent.comment.length > 0">
      <v-divider></v-divider>

      <br />
      <v-icon>comment</v-icon><span>{{ selectedEvent.comment }}</span>
    </div>
    <div v-else></div>

    <v-divider v-if="!selectedEvent.isCustom"> </v-divider>
    <br />

    <v-textarea
      v-if="canWriteAppointmentsAndClients"
      full-width
      height="100"
      background-color="amber lighten-4"
      color="orange orange-darken-4"
      style="margin: 0; padding-top: 0; width: 80%"
      outlined
      append-icon="note"
      solo
      no-resize
      rows="3"
      :hint="$t($ts.appointmentSummary.yourNotes)"
      row-height="20"
      persisent-hint
      v-model="selectedEvent.adminNote"
      @change="updateAdminComment($event, selectedEvent)"
    >
    </v-textarea>
    <div v-else class="admin-note" style="margin: 0; padding-top: 0; width: 80%">
      <v-icon>note</v-icon>
      <span>{{ selectedEvent.adminNote || "" }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.application--wrap {
  position: absolute;
}
.v-card__actions {
  padding: 43px 3px 15px;
}
.v-card {
  padding: 8px 22px;
}
.v-card__title {
  padding: 16px 0;
}
.form-wrapper {
  p {
    margin-left: 0 !important;
  }
}
.admin-note {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
  .v-icon {
    margin-right: 8px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}
</style>
