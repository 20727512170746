<script lang="ts">

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Prop} from "vue-property-decorator";

    @Component({
        name: "ToolTip",
        components: {}
    })

    export default class ToolTip extends Vue {

        @Prop({}) text;

        created() {

        }
    }

</script>

<template>
    <v-tooltip right>
        <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{text}}</span>
    </v-tooltip>
</template>


<style scoped>

</style>
