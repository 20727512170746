import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { GoogleCalendarService } from "@/services/api/GoogleCalendarService";
import { IStaffMemberDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class GoogleCalendarModule extends VuexModule {
  private googleCalendarService = GoogleCalendarService.getInstance();

  @Action
  async hasOauthToken(staffMember: IStaffMemberDTO): Promise<boolean> {
    return this.googleCalendarService
      .syncAppWithCalendar()
      .then(() => true)
      .catch(() => false);
  }

  @Action
  async removeOAuthToken(staffMember: IStaffMemberDTO): Promise<void> {
    return this.googleCalendarService.revokeToken(staffMember.id);
  }

  @Action
  async authenticateByCode(payload: { code: string; staffMemberId: number }): Promise<void> {
    return this.googleCalendarService.authenticateByCode({
      Code: payload.code,
      Staff: payload.staffMemberId,
    });
  }
}

export const googleCalendarModule = new GoogleCalendarModule({ store, name: "googleCalendarModule" });
