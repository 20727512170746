import Vue from "vue";
import VueClassComponent from "vue-class-component";
import { registerSW } from 'virtual:pwa-register';

registerSW({ immediate: true })


// Register the router hooks with their names
VueClassComponent.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

import VueI18n from "vue-i18n";
import { en } from "./locales/en";
import { es } from "./locales/es";
import { no } from "./locales/no";
import { LocaleMessages } from "vue-i18n";

import vuetify_es from "vuetify/lib/locale/es";
import vuetify_en from "vuetify/lib/locale/en";
import vuetify_no from "vuetify/lib/locale/no";

import { TranslationSchema } from "./locales/translation.interface";

Vue.use(VueI18n);

// Load locale on app startup
const savedLocale = localStorage.getItem("locale");

let defaultLocale = "no";

if (savedLocale) {
  defaultLocale = savedLocale;
}

// import {  } from "virtual:pwa-register";

// Export the schema using English translations as the base
export const translations: TranslationSchema = en;

// Extend Vue's type definitions to include the schema
declare module "vue/types/vue" {
  interface Vue {
    $ts: TranslationSchema;
  }
}

// Create a proxy for the translations object
function createTranslationsProxy(target: any, path: string = ""): any {
  return new Proxy(target, {
    get(target, prop) {
      if (prop in target) {
        const value = target[prop];
        const newPath = path ? `${path}.${String(prop)}` : String(prop);
        if (typeof value === "object" && value !== null) {
          // Return a new proxy for nested objects with updated path
          return createTranslationsProxy(value, newPath);
        }
        return newPath; // Return the full path of the property
      }
      return undefined;
    },
  });
}

const translationsProxy = createTranslationsProxy(translations);

// Install the proxy as a global property
Vue.prototype.$ts = translationsProxy;

const messages = { en, es, no } as any;

const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});

declare module "vue/types/vue" {
  interface Vue {
    $ts: TranslationSchema;
  }
}

export { i18n };

export { translationsProxy as ts };

import router from "./router";
import App from "./App.vue";
import "./Utilities/authHandler";
import VueStripeCheckout from "vue-stripe-checkout";
import Inject from "./plugins/Inject";
import { AppointmentDistribution } from "@/types/AppointmentDistribution";
import VueQrcodeReader from "vue-qrcode-reader";
import { Sector } from "@/types/Sector";
import { ButtonSize, ModalContent } from "@/types/ButtonSize";
import { MessageType } from "@/types/messageBarTypes";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueGtag from "vue-gtag";
// import VueGapi from 'vue-gapi''
import vuetify from "@/plugins/vuetify"; // path to vuetify export

import store from "./store/store";

import * as Sentry from "@sentry/vue";

import { registerGlobalComponents } from "@/plugins/global-components";
import { initializeSubscribers } from "./services/subscribers/initSubscribers";

// Initialize service event subscribers
initializeSubscribers();

vuetify.framework.lang.current = vuetify_no;

//PWA support???
// import "./registerServiceWorker";

console.log("MODE: ", import.meta.env.MODE);
console.log("Release: ", import.meta.env.VITE_SENTRY_RELEASE);
console.log("Env: ", import.meta.env.VITE_ENVIRONMENT);

// Initialize Sentry after router is imported
if (import.meta.env.VITE_ENVIRONMENT === "production") {
  Sentry.init({
    Vue,
    dsn: "https://f5df26bc645e9b5f71b5f547b971d08d@o306737.ingest.us.sentry.io/4508863267012608",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT || "localhost",
    release: import.meta.env.VITE_SENTRY_RELEASE,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost", 
      new RegExp(`^${import.meta.env.VITE_API_URL.replace('/api/', '')}(/api)?`)
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  

  Sentry.setTag("app", "panel");
}
// } else {
//   Vue.component("ErrorBoundary", {
//     data: () => ({ error: null }),
//     errorCaptured(err, vm, info) {
//       this.error = `${err.stack}\n\nfound in ${info} of component`;
//       return false;
//     },
//     render(h) {
//       if (this.error) {
//         return h("pre", { style: { color: "red" } }, this.error);
//       }
//       return this.$slots.default[0];
//     },
//   });
// }

import "./plugins/axiosSentry";

new Vue({
  i18n,
  router,
  store,
  vuetify: vuetify,
  mounted() {
    // Access query parameters after the app has mounted
    const queryParams = this.$route.query;
    const lang = queryParams.lang; // Read the 'lang' query parameter
    if (lang) {
      i18n.locale = lang;
    } else {
      const domain = window.location.hostname;
      if (domain.includes("citaro")) {
        i18n.locale = "es"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
      }
    }
  },
  render: (h) => h(App),
}).$mount("#app");

export let APIURL2: string;
export let USE_DEV_SITE: boolean;
export let IS_DEVELOPING: boolean;
export let BOOKING_URL: string;
export let NEW_BOOKING_URL: string;
export let CITARO_BOOKING_URL: string;

// IS_DEVELOPING = window.location.hostname === "localhost";
USE_DEV_SITE = false;

let STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY || "pk_test_pOnz4aVIcgOq5uWsMd1KrO5z";
APIURL2 = import.meta.env.VITE_API_URL || "https://timebestillerwebapp.azurewebsites.net/api/";

IS_DEVELOPING = import.meta.env.DEV;

try {
  BOOKING_URL = import.meta.env.VITE_BOOKING_URL || "http://booking.availify.no/";
  CITARO_BOOKING_URL = import.meta.env.VITE_CITARO_BOOKING_URL || "http://booking.citaro.app/";
} catch (e) {
  BOOKING_URL = "http://booking.availify.no/";
  CITARO_BOOKING_URL = "http://booking.citaro.app/";
}

try {
  NEW_BOOKING_URL = import.meta.env.VITE_NEW_BOOKING_URL || "http://booking.availify.no/";
  CITARO_BOOKING_URL = import.meta.env.VITE_CITARO_BOOKING_URL || "http://booking.citaro.app/";
} catch (e) {
  NEW_BOOKING_URL = "http://booking.availify.no/";
  CITARO_BOOKING_URL = "http://booking.citaro.app/";
}

Vue.use(VueStripeCheckout, STRIPE_PUBLIC_KEY);

// Translation provided by Vuetify (typescript)
//import no from 'vuetify/src/locale/no'

// Vue.use(Vue2TouchEvents);

//TODO: Consider eventually?
// async function setLocaleBasedOnIP() {
//   try {
//     const response = await axios.get('https://ipinfo.io?token=YOUR_API_TOKEN');
//     if (response.data && response.data.country === 'CO') {
//       i18n.global.locale = 'es'; // set locale to Spanish for Colombia
//     }
//   } catch (error) {
//     console.error('Error fetching IP info:', error);
//     // Use default locale
//   }
// }

Vue.use(VueGtag, {
  config: { id: "G-1FVGFKQSCW" },
});
// Vue.use(VueGapi, {
//   apiKey: 'AIzaSyBnqnHsXguX7mk97vUsnXT8X0EmSZ6_6uQ',
//   clientId: '605449339616-o0rj3sod6m1v9i0f820jho90jk7be8u8.apps.googleusercontent.com',
//   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//   scope: 'https://www.googleapis.com/auth/calendar',
// })

Vue.use(wysiwyg, { locale: "no", hideModules: { image: true } });
Vue.use(VueQrcodeReader);
Vue.use(Inject, {
  AppointmentDistribution,
  Sector,
  ButtonSize,
  ModalContent,
  MessageType,
});
// Vue.use(Trend)
Vue.config.productionTip = false;

export function getStripePublicKey() {
  return STRIPE_PUBLIC_KEY;
}

export function getApiUrl() {
  return APIURL2;
}

const LOCAL_API = "https://citaroapi-dev.azurewebsites.net/api/";
const PROD_API = "https://timebestillerwebapp.azurewebsites.net/api/";

// Initialize APIURL2 based on localStorage, hostname, or default
APIURL2 = localStorage.getItem("apiUrl") || PROD_API;

// Export these constants for use in Dashboard
export const API_URLS = {
  LOCAL: LOCAL_API,
  PROD: PROD_API,
};
