<template>
  <v-select
    v-model="selectedAccount"
    :items="moneyAccountOptions"
    item-text="text"
    item-value="value"
    :label="showPaymentMethod ? $t($ts.paymentMethod.title) : ''"
    :loading="loading"
    outlined
    dense
    :disabled="disabled"
    :hide-details="!errorMessages"
    :error-messages="errorMessages"
    @change="onChange"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";
import { PaymentMethod } from "@shared/types";

@Component({
  name: "money-account-selector",
})
export default class MoneyAccountSelector extends Vue {
  @Prop({ type: Number, required: false, default: null }) value!: number | null;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: true }) showPaymentMethod!: boolean;
  @Prop({ type: String, default: "" }) errorMessages!: string;

  private selectedAccount: number | null = null;

  @Watch("value", { immediate: true })
  onValueChange(newValue: number | null) {
    this.selectedAccount = newValue;
  }

  @Watch("selectedAccount")
  onSelectedAccountChange(newValue: number | null) {
    this.$emit("input", newValue);
  }

  get loading() {
    return moneyAccountModule.loading;
  }

  get moneyAccountOptions() {
    const accounts = moneyAccountModule.moneyAccounts;
    const accountsByMethod = new Map<PaymentMethod, typeof accounts>();

    // Group accounts by payment method
    accounts.forEach(account => {
      const method = account.paymentMethod;
      if (!accountsByMethod.has(method)) {
        accountsByMethod.set(method, []);
      }
      accountsByMethod.get(method)!.push(account);
    });

    // Create options with appropriate labels
    return accounts.map(account => {
      const methodAccounts = accountsByMethod.get(account.paymentMethod)!;
      const needsName = methodAccounts.length > 1 || !this.showPaymentMethod;
      
      if (this.showPaymentMethod) {
        return {
          text: this.getPaymentMethodText(account.paymentMethod) + (needsName ? ` (${account.name})` : ""),
          value: account.id,
        };
      } else {
        return {
          text: account.name,
          value: account.id,
        };
      }
    });
  }

  private getPaymentMethodText(method: PaymentMethod): string {
    return this.$t(this.$ts.paymentMethod[PaymentMethod[method]]).toString();
  }

  private onChange(value: number | null) {
    this.$emit("change", value);
  }

  async created() {
    if (moneyAccountModule.moneyAccounts.length === 0) {
      await moneyAccountModule.getAllAccounts();
    }
  }
}
</script> 