<template>
  <div>
    <div>
      <div style="margin: 15px 1%">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>
                {{ $t($ts.exportAppointments) }}
              </v-card-title>

              <v-card-actions>
                <v-btn @click="downloadReport()"> <v-icon>download</v-icon> {{ $t($ts.downloadExcel) }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>
                {{ $t($ts.export.dailyFinancialReport) }}
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="selectedYear"
                      :items="yearOptions"
                      :label="$t($ts.statistics.year)"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="selectedMonth"
                      :items="monthOptions"
                      :label="$t($ts.statistics.month)"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="selectedDay"
                      :items="dayOptions"
                      :label="$t($ts.statistics.day)"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn @click="downloadDailyReport()">
                  <v-icon>download</v-icon> {{ $t($ts.export.downloadDailyReport) }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HeaderBar from "../../components/global/MainHeader.vue";
import Loader from "@/components/global/Loader.vue";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { statisticsModule } from "@/store/modules/statistics/statisticsModule";

@Component({
  name: "export-page",
  components: { Loader, HeaderBar },
})
export default class ExportPage extends Vue {
  public language: any | null = null;
  isLoading: boolean = false;
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1;
  selectedDay: number = new Date().getDate();

  get yearOptions() {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => ({
      text: currentYear - i,
      value: currentYear - i,
    }));
  }

  get monthOptions() {
    return [
      { value: 1, text: this.$t(this.$ts.months.january) },
      { value: 2, text: this.$t(this.$ts.months.february) },
      { value: 3, text: this.$t(this.$ts.months.march) },
      { value: 4, text: this.$t(this.$ts.months.april) },
      { value: 5, text: this.$t(this.$ts.months.may) },
      { value: 6, text: this.$t(this.$ts.months.june) },
      { value: 7, text: this.$t(this.$ts.months.july) },
      { value: 8, text: this.$t(this.$ts.months.august) },
      { value: 9, text: this.$t(this.$ts.months.september) },
      { value: 10, text: this.$t(this.$ts.months.october) },
      { value: 11, text: this.$t(this.$ts.months.november) },
      { value: 12, text: this.$t(this.$ts.months.december) },
    ];
  }

  get dayOptions() {
    if (!this.selectedYear || !this.selectedMonth) return [];
    const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => ({
      text: i + 1,
      value: i + 1,
    }));
  }

  async downloadReport() {
    try {
      this.isLoading = true;
      const currentDate = new Date();
      const data = await statisticsModule.downloadDailyFinancialReport({
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        day: currentDate.getDate(),
      });

      // Create blob with the correct type for Excel files
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = `appointments-report-${currentDate.toISOString().split("T")[0]}.xlsx`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading appointments report:", error);
    } finally {
      this.isLoading = false;
    }
  }

  async downloadDailyReport() {
    try {
      this.isLoading = true;
      const data = await statisticsModule.downloadDailyFinancialReport({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: this.selectedDay,
      });

      // Create blob with the correct type for Excel files
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `daily-report-${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading daily report:", error);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>
