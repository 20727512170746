<template>
  <v-card>
    <v-card-title>
      <!-- {{ $t($ts.entities.ledgerEntry) }} -->
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t($ts.placeHolder.typeToSearch)"
        single-line
        hide-details
        class="mr-12"
      ></v-text-field>
      <v-btn
        v-if="canWriteAppointmentsAndClients && (appointmentId || customerId)"
        fab
        small
        dark
        color="#866afe"
        @click="showLedgerEntryDialog = true"
        class="ml-2"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row v-if="!moneyAccountId">
        <v-col cols="12" sm="4">
          <v-card outlined>
            <v-card-text class="text-center">
              <div class="text-h6">{{ $t($ts.ledgerEntryFormDTO.total) }}</div>
              <div class="text-h4">{{ formatCurrency(balanceInfo?.total || 0) }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined>
            <v-card-text class="text-center">
              <div class="text-h6">{{ $t($ts.ledgerEntryFormDTO.paid) }}</div>
              <div class="text-h4">{{ formatCurrency(balanceInfo?.paid || 0) }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined>
            <v-card-text class="text-center">
              <div class="text-h6">{{ $t($ts.ledgerEntryFormDTO.remaining) }}</div>
              <div class="text-h4" :class="{ 'red--text': balanceInfo?.remaining > 0 }">
                {{ formatCurrency(balanceInfo?.remaining || 0) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="entries"
        :search="search"
        :loading="loading"
        :loading-text="$t($ts.loading)"
        class="elevation-1 mt-4"
      >
        <template #item="{ item }">
          <tr
            :class="[
              getRowClass(item),
              item.sourceType === sourceTypes.Order || 
              item.sourceType === sourceTypes.Appointment ||
              item.sourceType === sourceTypes.Expense ||
              item.sourceType === sourceTypes.RecurringExpense
                ? 'clickable-row'
                : '',
            ]"
            @click="
              item.sourceType === sourceTypes.Order || 
              item.sourceType === sourceTypes.Appointment ||
              item.sourceType === sourceTypes.Expense ||
              item.sourceType === sourceTypes.RecurringExpense
                ? viewSource(item)
                : null
            "
  
          >
            <td>{{ formatDate(item.dateTime) }}</td>
            <td>{{ item.description }}</td>
            <td>
              <span
                :class="{
                  'red--text': item.type === LedgerEntryType.Charge,
                  'green--text': item.type === LedgerEntryType.Payment,
                }"
              >
                {{ formatCurrency(item.amount) }}
              </span>
            </td>
            <td>
              <v-chip :color="getTypeColor(item.type)" text-color="white" small label>
                {{ $t($ts.ledgerEntryType[LedgerEntryType[item.type].toLowerCase()]) }}
              </v-chip>
            </td>
            <td>{{ getMoneyAccountName(item.moneyAccountId) }}</td>
            <td>{{ getSourceText(item.sourceType) }}</td>
            <td>
              <v-icon small v-if="item.sourceType === sourceTypes.Order || item.sourceType === sourceTypes.Appointment || item.sourceType === sourceTypes.Expense || item.sourceType === sourceTypes.RecurringExpense">
                mdi-eye
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <CreateLedgerEntryDialog
      v-model="showLedgerEntryDialog"
      :default-customer-id="customerId"
      :default-appointment-id="appointmentId"
      :customer-name="customerName"
      :customer-email="customerEmail"
      :appointment-number="appointmentNumber"
      @save="onLedgerEntrySave"
    />

    <ViewOrderDialog v-model="showOrderDialog" :order="selectedOrder" />

    <appointment-info-dialog
      v-model="showAppointmentDialog"
      :appointment="selectedAppointment"
      v-if="showAppointmentDialog && selectedAppointment"
    />

    <view-one-time-expense
      v-model="showOneTimeExpenseDialog"
      :expense="selectedOneTimeExpense"
      v-if="showOneTimeExpenseDialog && selectedOneTimeExpense"
    />

    <view-recurring-expense
      v-model="showRecurringExpenseDialog"
      :expense="selectedRecurringExpense"
      v-if="showRecurringExpenseDialog && selectedRecurringExpense"
    />
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ILedgerEntryDTO, LedgerEntrySourceType, IOrderDTO, LedgerEntryType } from "@shared/types";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { formatDate } from "@/types/formatDateHelper";
import { userModule } from "@/store/modules/user/userModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { ordersModule } from "@/store/modules/orders/ordersModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import CreateLedgerEntryDialog from "@/components/global/CreateLedgerEntryDialog.vue";
import ViewOrderDialog from "@/components/global/ViewOrderDialog.vue";
import AppointmentInfoDialog from "@/components/global/AppointmentInfoDialog.vue";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";
import ViewOneTimeExpense from "@/components/expenses/ViewOneTimeExpense.vue";
import ViewRecurringExpense from "@/components/expenses/ViewRecurringExpense.vue";
import { expensesModule } from "@/store/modules/expenses/expensesModule";

interface BalanceInfo {
  total: number;
  paid: number;
  remaining: number;
}

@Component({
  components: {
    CreateLedgerEntryDialog,
    ViewOrderDialog,
    AppointmentInfoDialog,
    ViewOneTimeExpense,
    ViewRecurringExpense,
  },
})
export default class LedgerEntriesOverview extends Vue {
  @Prop({ type: Number, required: false }) customerId?: number;
  @Prop({ type: Number, required: false }) appointmentId?: number;
  @Prop({ type: Number, required: false }) moneyAccountId?: number;
  @Prop({ type: String, required: false }) customerName?: string;
  @Prop({ type: String, required: false }) customerEmail?: string;
  @Prop({ type: String, required: false }) appointmentNumber?: string;
  @Prop({ type: Boolean, required: false, default: false }) useLedgerEntry!: boolean;

  private readonly sourceTypes = LedgerEntrySourceType;
  public showLedgerEntryDialog: boolean = false;
  private search = "";
  private loading = false;
  private entries: ILedgerEntryDTO[] = [];
  private balanceInfo: BalanceInfo | null = null;
  private showOrderDialog = false;
  private showAppointmentDialog = false;
  private selectedOrder: IOrderDTO | null = null;
  private selectedAppointmentId: number | null = null;
  selectedAppointment: any;
  readonly LedgerEntryType = LedgerEntryType;
  private showOneTimeExpenseDialog = false;
  private showRecurringExpenseDialog = false;
  private selectedOneTimeExpense: any = null;
  private selectedRecurringExpense: any = null;

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  private headers = [];

  @Watch("customerId")
  @Watch("appointmentId")
  @Watch("moneyAccountId")
  async onPropsChange() {
    await this.loadData();
  }

  async mounted() {
    await this.loadData();

    this.headers = [
      { text: this.$t(this.$ts.date), value: "dateTime" },
      { text: this.$t(this.$ts.dtos.ledgerEntryFormDTO.description.name), value: "description" },
      { text: this.$t(this.$ts.dtos.ledgerEntryFormDTO.amount.name), value: "amount" },
      { text: this.$t(this.$ts.ledgerEntryHeaders.type), value: "type" },
      { text: this.$t(this.$ts.sidebar.cashRegister), value: "moneyAccountId" },
      { text: this.$t(this.$ts.ledgerEntryHeaders.source), value: "source" },
      { text: this.$t(this.$ts.fields.actions), value: "actions", sortable: false },
    ];
  }

  private async loadData() {
    this.loading = true;
    try {
      if (this.moneyAccountId) {
        this.entries = await ledgerEntriesModule.getMoneyAccountEntries(this.moneyAccountId);
      } else if (this.customerId) {
        this.entries = await ledgerEntriesModule.getCustomerEntries(this.customerId);
      } else if (this.appointmentId) {
        // TODO: Add method to get entries by appointmentId
        // For now, we'll use the balance info to show something
        this.entries = [];
      }

      // Load money accounts for displaying names
      await moneyAccountModule.getAllAccounts();

      this.balanceInfo = await ledgerEntriesModule.getDetailedBalance({
        customerId: this.customerId,
        appointmentId: this.appointmentId,
       // moneyAccountId: this.moneyAccountId,
      });
    } finally {
      this.loading = false;
    }
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user?.currency || "USD",
    }).format(value);
  }

  private formatDate(date: string): string {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString(this.$i18n.locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    const formattedTime = formatTimeWithFormat(hour, minute, false, true);
    return `${formattedDate} ${formattedTime}`;
  }

  private getSourceText(sourceType: LedgerEntrySourceType): string {
    switch (sourceType) {
      case LedgerEntrySourceType.Appointment:
        return this.$t(this.$ts.entities.appointment).toString();
      case LedgerEntrySourceType.Order:
        return this.$t(this.$ts.entities.order).toString();
      case LedgerEntrySourceType.Manual:
        return this.$t(this.$ts.ledgerEntry.manual).toString();
      case LedgerEntrySourceType.Expense:
        return this.$t(this.$ts.entities.oneTimeExpense).toString();
      case LedgerEntrySourceType.RecurringExpense:
        return this.$t(this.$ts.entities.recurringExpense).toString();
      default:
        return String(sourceType);
    }
  }

  private onLedgerEntrySave(entry: ILedgerEntryDTO) {
    this.showLedgerEntryDialog = false;
    this.loadData(); // Reload the data after saving
  }

  private async viewSource(entry: ILedgerEntryDTO) {
    if (entry.sourceType === LedgerEntrySourceType.Order) {
      try {
        this.selectedOrder = await ordersModule.getOrder(entry.sourceId);
        this.showOrderDialog = true;
      } catch (error) {
        console.error("Error loading order:", error);
      }
    } else if (entry.sourceType === LedgerEntrySourceType.Appointment) {
      this.selectedAppointmentId = entry.sourceId;

      const appointmentResult = await appointmentsModule.getAppointmentByNumber(entry.sourceId);
      this.selectedAppointment = appointmentResult;

      this.showAppointmentDialog = true;
    } else if (entry.sourceType === LedgerEntrySourceType.Expense) {
      try {
        const expense = await expensesModule.getOneTimeExpense(entry.sourceId);
        this.selectedOneTimeExpense = expense;
        this.showOneTimeExpenseDialog = true;
      } catch (error) {
        console.error("Error loading one-time expense:", error);
      }
    } else if (entry.sourceType === LedgerEntrySourceType.RecurringExpense) {
      try {
        const expense = await expensesModule.getRecurringExpense(entry.sourceId);
        this.selectedRecurringExpense = expense;
        this.showRecurringExpenseDialog = true;
      } catch (error) {
        console.error("Error loading recurring expense:", error);
      }
    }
  }

  private getTypeColor(type: LedgerEntryType): string {
    switch (type) {
      case LedgerEntryType.Charge:
        return "error";
      case LedgerEntryType.Payment:
        return "success";
      case LedgerEntryType.Refund:
        return "warning";
      case LedgerEntryType.Adjustment:
        return "info";
      default:
        return "grey";
    }
  }

  private getRowClass(item: any) {
    switch (item.type) {
      case LedgerEntryType.Charge:
        return "red lighten-5";
      case LedgerEntryType.Payment:
        return "green lighten-5";
      case LedgerEntryType.Refund:
        return "amber lighten-5";
      case LedgerEntryType.Adjustment:
        return "blue lighten-5";
      default:
        return "";
    }
  }

  private getMoneyAccountName(moneyAccountId: number | null): string {
    if (!moneyAccountId) return '-';
    const account = moneyAccountModule.moneyAccounts.find(acc => acc.id === moneyAccountId);
    return account ? account.name : '-';
  }
}
</script>

<style lang="scss" scoped>
.v-data-table ::v-deep tbody tr.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}
</style>
