<template>
  <div>
    <v-dialog v-model="bookOpen" transition="dialog-bottom-transition" fullscreen>
      <create-appointment
        :open="bookOpen"
        @close="bookOpen = false"
        :shoulScroll="false"
        @added="addedAppointment"
      ></create-appointment>
    </v-dialog>

    <v-dialog v-model="settingsOpen" :close-on-content-click="false" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="settingsOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t($ts.mobileHeader.settings) }}</v-toolbar-title>
        </v-toolbar>
        <settings-mobile />
      </v-card>
    </v-dialog>

    <v-dialog v-model="createOrderOpen" fullscreen transition="dialog-bottom-transition">
      <create-order
        :visible="createOrderOpen"
        @cancel="createOrderOpen = false"
        @created="handleOrderCreated"
      />
    </v-dialog>

    <v-navigation-drawer 
      v-model="bookingOptionsVisible" 
      bottom 
      fixed 
      temporary
      style="height: auto !important; padding-bottom: 80px !important;"
    >
      <v-list>
        <v-list-item @click="bookAppointment">
          <v-list-item-icon>
            <v-icon>mdi-calendar-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t($ts.mobileHeader.booking) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="createOrder">
          <v-list-item-icon>
            <v-icon>mdi-cart-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t($ts.entities.order) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="hidden-lg-and-up" color="white" fixed style="height: 70px" v-if="!hideMobileFooter" bottom>
      <v-container fluid>
        <navigation-items
          :selectedIndex="group2"
          @item-click="clickItem"
          @notification-click="group2 = 4"
          @menu-click="toggleDropDownMenu"
          @settings-click="openSettings"
          @new-click="book"
        />
      </v-container>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import CreateAppointment from "./global/CreateAppointment.vue";
import CreateOrder from "./global/CreateOrder.vue";
import NavigationItems from "./navigation/NavigationItems.vue";
import SettingsMobile from "../pages/admin/SettingsMobile.vue";
import AuditLogger, { AuditLoggerType } from "../mixins/AuditLogger";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { layoutModule } from "@/store/modules/layout/layoutModule";
import { inventoryModule } from "@/store/modules/inventory/inventoryModule";

@Component({
  name: "MobileHeader",
  components: {
    CreateAppointment,
    CreateOrder,
    NavigationItems,
    SettingsMobile,
  },
  mixins: [AuditLogger],
})
export default class MobileHeader extends Vue implements AuditLoggerType {
  public group2 = null;
  public bookOpen = false;
  public settingsOpen = false;
  public createOrderOpen = false;
  public bookingOptionsVisible = false;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get canViewAppointmentsAndClients() {
    return userPermissionsModule.canViewAppointmentsAndClients;
  }

  get canWriteAppointmentsAndClients() {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get canViewStatisticsAndFinances() {
    return userPermissionsModule.canViewStatisticsAndFinances;
  }

  get hideMobileFooter() {
    return layoutModule.hideMobileFooter;
  }

  async getEvents(): Promise<void> {
    const date = new Date();
    await appointmentsModule.getBookedAppointmentsForDay(date);
  }

  @Watch("$route")
  async onRouterChange() {
    const p = this.$router.currentRoute.path;

    // Only include routes the user has permission to access
    const routeMap = {
      "/": this.canViewAppointmentsAndClients ? 0 : null,
      "/admin": this.canViewAppointmentsAndClients ? 0 : null,
      "/kunder": this.canViewAppointmentsAndClients ? 2 : null,
      "/kalender": this.canViewAppointmentsAndClients ? 1 : null,
      "/hendelser": this.canViewAppointmentsAndClients ? 4 : null,
      "/settings": 5,
      "/bestillinger": this.canViewAppointmentsAndClients ? 3 : null,
    };

    const mappedValue = routeMap[p];
    this.group2 = mappedValue !== null ? mappedValue : 21328203;
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents();
  }

  async book() {
    if (!this.canWriteAppointmentsAndClients) return;

    // Check if we have inventory items
    if (inventoryModule.inventoryItems.length === 0) {
      await inventoryModule.getAllItems();
    }

    // If we have more than one inventory item, show booking options
    if (inventoryModule.inventoryItems.length > 0) {
      this.logAudit(" opened booking options @ mobile menu");
      this.bookingOptionsVisible = true;
    } else {
      // If no inventory items, proceed with appointment booking
      this.logAudit(" opened new appointment @ mobile menu");
      appointmentsModule.setDefaultCustomerToBook(null);

      const now = new Date();
      appointmentsModule.selectDateToBook({
        year: now.getFullYear(),
        month: now.getMonth(),
        day: now.getDate(),
      });
      this.bookOpen = true;
    }
  }

  openSettings() {
    this.logAudit(" opened settings dialog");
    this.settingsOpen = true;
  }

  clickItem(item) {
    // Check if user has permission for the route
    if (
      (["/", "/admin", "/kunder", "/kalender", "/hendelser", "/bestillinger"].includes(item.route) &&
        !this.canViewAppointmentsAndClients) ||
      (["/statistics"].includes(item.route) && !this.canViewStatisticsAndFinances)
    ) {
      return;
    }

    this.group2 = item.index;

    if (item.action) {
      item.action();
    } else {
      this.logAudit(" click mobile item: " + item.route);
      this.$router.push(item.route).catch(() => {});
    }
  }

  public toggleDropDownMenu(): void {
    layoutModule.setMobileNavigation(true);
    this.logAudit(" opened mobile menu");
  }

  bookAppointment() {
    this.bookingOptionsVisible = false;
    this.logAudit(" opened new appointment @ mobile menu");
    appointmentsModule.setDefaultCustomerToBook(null);

    const now = new Date();
    appointmentsModule.selectDateToBook({
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    });
    this.bookOpen = true;
  }

  createOrder() {
    this.bookingOptionsVisible = false;
    this.createOrderOpen = true;
  }

  handleOrderCreated() {
    this.createOrderOpen = false;
  }

  created() {
    this.onRouterChange();
  }
}
</script>
