<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IDialog } from "@/store/dialog";
import { IAuditLogDTO, IStaffMemberDTO, IUserDTO, VerificationType } from "@shared/types";
import ToolTip from "../../ToolTip.vue";
import CompanyInfoField from "../../global/CompanyInfoField.vue";
import BookingDistribution from "./BookingDistribution.vue";
import Textfield from "@/components/global/Textfield.vue";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "GeneralBookingSettings",
  components: { ToolTip, CompanyInfoField, BookingDistribution, Textfield },
})
export default class GeneralBookingSettings extends Vue {
  public abilityToCancel: boolean = false;
  public hoursToCancel: number = 0;
  public maxDaysInAdvanceForBooking: number = 0;
  public minumumHourBeforeBooking: number = 0;

  public smsVerification: boolean = false;
  public verificationType: VerificationType = VerificationType.None;
  public customerReceiptAndReminderMode: string = "";

  public sendAutomaticallyReminder: boolean = false;
  public sendAutomaticallyReceipt: boolean = false;

  public customerCanSelectStaff: boolean = false;
  public customerCanSearchForAppointments: boolean = false;

  created() {
    this.abilityToCancel = userModule.user.abilityToCancel;
    this.hoursToCancel = userModule.user.hoursToCancel;
    this.maxDaysInAdvanceForBooking = userModule.user.maxDaysInAdvanceForBooking;
    this.minumumHourBeforeBooking = userModule.user.minumumHourBeforeBooking;
    this.verificationType = userModule.user.verificationType;

    this.customerReceiptAndReminderMode = userModule.user.customerReceiptAndReminderMode;
    this.sendAutomaticallyReceipt = userModule.user.sendAutomaticallyReceipt;
    this.sendAutomaticallyReminder = userModule.user.sendAutomaticallyReminder;
    this.customerCanSelectStaff = userModule.user.customerCanSelectStaff;
    this.customerCanSearchForAppointments = userModule.user.customerCanSearchForAppointments;
  }

  async saveField(field: string, value: any, oldValue: any): Promise<void> {
    try {
      // Create audit log based on field
      if (field === "verificationType" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditVerificationChanged).toString(),
          hideForUser: false,
        });
      } else if (field === "abilityToCancel" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditCancellationChanged).toString(),
          hideForUser: false,
        });
      } else if (field === "hoursToCancel" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditHoursToCancelChanged, {
            hours: value,
          }).toString(),
          hideForUser: false,
        });
      } else if (field === "customerReceiptAndReminderMode" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditReceiptReminderChanged).toString(),
          hideForUser: false,
        });
      } else if (field === "maxDaysInAdvanceForBooking" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditMaxDaysChanged, {
            days: value,
          }).toString(),
          hideForUser: false,
        });
      } else if (field === "minumumHourBeforeBooking" && value !== oldValue) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.generalBookingSettings.auditMinHoursChanged, {
            hours: value,
          }).toString(),
          hideForUser: false,
        });
      }

      // Handle special cases for email/SMS requirements
      if (
        (field === "verificationType" && value === VerificationType.Email) ||
        (field === "customerReceiptAndReminderMode" && value === "email")
      ) {
        if (userModule.user.customerRequiredEmailOptional || !userModule.user.customerRequiredEmail) {
          await dialogModule.addToDialogQueue({
            text: this.$t(this.$ts.generalBookingSettings.emailRequired).toString(),
          });

          await userModule.updateUser({
            customerRequiredEmailOptional: false,
            customerRequiredEmail: true,
          });
        }
      } else if (field === "customerReceiptAndReminderMode" && (value === "sms" || value === "whatsapp")) {
        if (userModule.user.customerRequiredPhoneNumberOptional || !userModule.user.customerRequiredPhoneNumber) {
          await dialogModule.addToDialogQueue({
            text: this.$t(this.$ts.generalBookingSettings.phoneRequired).toString(),
          });
          await userModule.updateUser({
            customerRequiredPhoneNumberOptional: false,
            customerRequiredPhoneNumber: true,
          });
        }
      }

      // Save the field
      await userModule.updateUser({ [field]: value });
    } catch (error) {
      console.error(error);
    }
  }

  get verificationOptions() {
    return [
      {
        value: 0,
        label: this.$t(this.$ts.generalBookingSettings.noVerification),
      },
      { value: 1, label: this.$t(this.$ts.generalBookingSettings.email) },
    ];
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }

  booleanToOnOff2(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.yes).toString() : this.$t(this.$ts.boolean.no).toString();
  }

  get user() {
    return userModule.user;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }
}
</script>

<template>
  <v-container>
    <!-- Customer Verification -->
    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.customerVerification)"
      :description="$t($ts.generalBookingSettings.customerVerificationDescription)"
    >
      <v-select
        :disabled="true"
        :items="verificationOptions"
        item-value="value"
        item-text="label"
        v-model="verificationType"
        @change="saveField('verificationType', $event, user.verificationType)"
      ></v-select>
    </CompanyInfoField>

    <v-divider />

    <!-- Cancellation and Modification of Appointments -->
    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.cancellationModification)"
      :description="$t($ts.generalBookingSettings.cancellationModificationDescription)"
    >
      <v-switch
        v-model="abilityToCancel"
        color="#56dcb6"
        :label="booleanToOnOff(abilityToCancel)"
        @change="saveField('abilityToCancel', $event, user.abilityToCancel)"
      ></v-switch>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      v-if="abilityToCancel"
      :label="$t($ts.generalBookingSettings.minimumHoursBefore)"
      :description="$t($ts.generalBookingSettings.minimumHoursBeforeDescription)"
    >
      <Textfield
        :number="true"
        v-model="hoursToCancel"
        @blur="saveField('hoursToCancel', hoursToCancel, user.hoursToCancel)"
      />
    </CompanyInfoField>

    <v-divider />

    <!-- Customer Notifications -->
    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.remindersReceipts)"
      :description="$t($ts.generalBookingSettings.remindersReceiptsDescription)"
    >
      <v-radio-group
        v-model="customerReceiptAndReminderMode"
        :mandatory="true"
        :multiple="false"
        @change="saveField('customerReceiptAndReminderMode', $event, user.customerReceiptAndReminderMode)"
      >
        <v-radio value="email" :label="$t($ts.generalBookingSettings.email)"></v-radio>
        <v-radio value="sms" :label="$t($ts.generalBookingSettings.sms)"></v-radio>
        <v-radio value="whatsapp" :label="$t($ts.generalBookingSettings.whatsapp)"></v-radio>
      </v-radio-group>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.alwaysSendReceipt)"
      :description="$t($ts.generalBookingSettings.alwaysSendReceiptDescription)"
    >
      <v-switch
        v-model="sendAutomaticallyReceipt"
        color="#56dcb6"
        :label="booleanToOnOff2(sendAutomaticallyReceipt)"
        @change="saveField('sendAutomaticallyReceipt', $event, user.sendAutomaticallyReceipt)"
      ></v-switch>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.alwaysSendReminder)"
      :description="$t($ts.generalBookingSettings.alwaysSendReminderDescription)"
    >
      <v-switch
        v-model="sendAutomaticallyReminder"
        color="#56dcb6"
        :label="booleanToOnOff2(sendAutomaticallyReminder)"
        @change="saveField('sendAutomaticallyReminder', $event, user.sendAutomaticallyReminder)"
      ></v-switch>
    </CompanyInfoField>

    <v-divider />

    <!-- Booking Restrictions -->
    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.bookingRestrictions)"
      :description="$t($ts.generalBookingSettings.bookingRestrictionsDescription)"
    >
      <v-text-field
        type="number"
        :label="$t($ts.generalBookingSettings.days)"
        v-model="maxDaysInAdvanceForBooking"
        @blur="saveField('maxDaysInAdvanceForBooking', maxDaysInAdvanceForBooking, user.maxDaysInAdvanceForBooking)"
      ></v-text-field>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.earlyBooking)"
      :description="$t($ts.generalBookingSettings.earlyBookingDescription)"
    >
      <v-text-field
        type="number"
        :label="$t($ts.generalBookingSettings.hours)"
        v-model="minumumHourBeforeBooking"
        @blur="saveField('minumumHourBeforeBooking', minumumHourBeforeBooking, user.minumumHourBeforeBooking)"
      ></v-text-field>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.staffSelection)"
      :description="$t($ts.generalBookingSettings.staffSelectionDescription)"
    >
      <v-switch
        v-model="customerCanSelectStaff"
        color="#56dcb6"
        :label="booleanToOnOff2(customerCanSelectStaff)"
        :disabled="staffMembers.length <= 1 && !user.customerCanSelectStaff"
        @change="saveField('customerCanSelectStaff', $event, user.customerCanSelectStaff)"
      ></v-switch>
    </CompanyInfoField>

    <v-divider />

    <CompanyInfoField
      :label="$t($ts.generalBookingSettings.serviceSearch)"
      :description="$t($ts.generalBookingSettings.serviceSearchDescription)"
    >
      <v-switch
        v-model="customerCanSearchForAppointments"
        color="#56dcb6"
        :label="booleanToOnOff2(customerCanSearchForAppointments)"
        :disabled="staffMembers.length <= 1 && !user.customerCanSearchForAppointments"
        @change="saveField('customerCanSearchForAppointments', $event, user.customerCanSearchForAppointments)"
      ></v-switch>
    </CompanyInfoField>

    <v-divider />

    <BookingDistribution />
  </v-container>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
