import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import store from "@/store/store";

export interface SnackbarObject {
  message: string;
  x: string;
  y: string;
  mode: string;
  color: string;
  isLoadingMessage: boolean;
}

@Module({ generateMutationSetters: true })
class SnackbarModule extends VuexModule {
  // State
  snackbar: SnackbarObject = {
    message: "",
    x: "right",
    y: "top",
    mode: "",
    color: "grey darken-3",
    isLoadingMessage: false,
  };

  @Action
  postSnackbarMessage(message: string | null): void {
    if (!message) {
      this.snackbar = {
        ...this.snackbar,
        message: "",
        isLoadingMessage: false,
      };
      return;
    }

    this.snackbar = {
      message,
      mode: "",
      x: "right",
      y: "top",
      color: "grey darken-3",
      isLoadingMessage: false,
    };
  }

  @Action
  postSuccessSnackbarMessage(message: string): void {
    this.snackbar = {
      message,
      mode: "",
      x: "right",
      y: "top",
      color: "success",
      isLoadingMessage: false,
    };
  }

  @Action
  postErrorSnackbarMessage(message: string): void {
    this.snackbar = {
      message,
      mode: "",
      x: "right",
      y: "top",
      color: "error",
      isLoadingMessage: false,
    };
  }

  @Action
  postLoadingSnackbarMessage(message: string): void {
    this.snackbar = {
      message,
      mode: "",
      x: "right",
      y: "top",
      color: "info",
      isLoadingMessage: true,
    };
  }
}
export const snackbarModule = new SnackbarModule({ store, name: "snackbarModule" });
