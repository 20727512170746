import { VuexModule, Module, Action } from "vuex-class-modules";
import { AuditLogService } from "@/services/api/AuditLogService";
import { IAuditLogDTO, IPostAuditLogDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class AuditLogModule extends VuexModule {
  private readonly auditLogService: AuditLogService;

  constructor(options: any) {
    super(options);
    this.auditLogService = AuditLogService.getInstance();
  }

  // State
  auditLogs: IAuditLogDTO[] = [];

  @Action
  async getAuditLogs(ignoreFlag?: number) {
    this.auditLogs = await this.auditLogService.getCurrentAuditLogs(ignoreFlag);
  }

  @Action
  async postAudit(payload: { userId?: string; dto: IPostAuditLogDTO }) {
    if (payload.userId) {
      await this.auditLogService.postAuditAnonymous(payload.userId, payload.dto);
    } else {
      await this.auditLogService.postAudit(payload.dto);
    }
  }

  @Action
  async postAuditLog(payload: IPostAuditLogDTO) {
    await this.auditLogService.postAudit(payload);
  }
}

export const auditLogModule = new AuditLogModule({ store, name: "auditLogModule" });
