<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import CustomButton from "./global/styledComponents/customButton.vue";
import CustomInput from "./global/styledComponents/CustomInput.vue";

import firebase from "firebase/app";
import { Mixins, Prop } from "vue-property-decorator";
import "firebase/auth"; // Import only the auth module. improtant. otherwise it all willl break apart like a unmitigated disaster.
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import SnackbarMixin from "@/mixins/snackbarMixin";
import { userModule } from "@/store/modules/user/userModule";
const firebaseAuth = firebase.auth();

@Component({
  name: "RegisterFirebaseAccount",
  components: { CustomInput, CustomButton },
})
export default class RegisterFirebaseAccount extends Mixins(SnackbarMixin) {
  @Prop({ default: false }) public onBoardingOnRegister: boolean;

  hasRegistered: boolean = false;

  password: string = "";
  password2: string = "";
  email: string = "";

  created() {}

  public async register(): Promise<void> {
    if (this.email === "" || this.password == "") {
      this.postSnackbarMessage(this.$t(this.$ts.registerFirebase.emailAndPasswordRequired).toString());
      return;
    }

    if (this.password !== this.password2) {
      this.postSnackbarMessage(this.$t(this.$ts.registerFirebase.passwordsMustMatch).toString());
      return;
    }

    try {
      this.hasRegistered = true;

      await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
    } catch (error) {
      let errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        this.postSnackbarMessage(this.$t(this.$ts.registerFirebase.emailAlreadyInUse).toString());
      }
      if (errorCode === "auth/invalid-email") {
        this.postSnackbarMessage(this.$t(this.$ts.registerFirebase.invalidEmail).toString());
      }
      if (errorCode === "auth/weak-password") {
        this.postSnackbarMessage(this.$t(this.$ts.registerFirebase.passwordTooWeak).toString());
      }
    }
  }
}
</script>

<template>
  <div>
    <custom-input :inputType="'email'" v-model="email" :placeholderText="$t($ts.registerFirebase.email)"></custom-input>
    <custom-input
      :inputType="'password'"
      v-model="password"
      :placeholderText="$t($ts.registerFirebase.password)"
    ></custom-input>
    <custom-input
      :inputType="'password'"
      v-model="password2"
      :placeholderText="$t($ts.registerFirebase.passwordAgain)"
    ></custom-input>

    <custom-button
      :filled="true"
      @custom-button-click="register"
      :buttonText="$t($ts.account.registerNewAccount)"
    ></custom-button>
  </div>
</template>

<style scoped></style>
