<template>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <CompanyInfoField :label="$t($ts.dtos.oneTimeExpenseFormDTO.amount.name)">
          <Textfield v-model="currentItem.amount" :number="true" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.entities.category)">
          <EnumField v-model="currentItem.category" :items="categories" hide-details @change="onCategoryChange" />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField v-if="showStaffMemberSelect" :label="$t($ts.entities.staff)">
          <EnumField
            v-model="currentItem.sourceId"
            :items="staffMembers.map((s) => ({ text: s.fullName, value: s.id }))"
            hide-details
          />
        </CompanyInfoField>
        <v-divider v-if="showStaffMemberSelect" class="my-4" />

        <CompanyInfoField :label="$t($ts.dtos.oneTimeExpenseFormDTO.notes.name)">
          <Textfield v-model="currentItem.notes" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.dtos.oneTimeExpenseFormDTO.purchaseDate.name)">
          <DatePickerField v-model="currentItem.purchaseDate" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.sidebar.cashRegister)">
          <MoneyAccountSelector
            :showPaymentMethod="false"
            v-model="currentItem.moneyAccountId"
            data-cy="expense-money-account-select"
          />
        </CompanyInfoField>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#866afe" outlined @click="close">
          {{ $t($ts.buttons.cancel) }}
        </v-btn>
        <v-btn dark color="#866afe" @click="save"> {{ $t($ts.buttons.save) }} <v-icon>save</v-icon> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IPostOneTimeExpenseDTO, ExpenseCategory, IStaffMemberDTO, IOneTimeExpenseDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import DatePickerField from "@/components/global/DatePickerField.vue";
import Textfield from "@/components/global/Textfield.vue";
import EnumField from "@/components/global/EnumField.vue";
import MoneyAccountSelector from "@/components/global/MoneyAccountSelector.vue";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  components: {
    CompanyInfoField,
    DatePickerField,
    Textfield,
    EnumField,
    MoneyAccountSelector,
  },
})
export default class OneTimeExpenseDialog extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Object, required: false }) expense?: IPostOneTimeExpenseDTO;
  @Prop({ type: String, required: true }) title!: string;

  private currentItem: IOneTimeExpenseDTO = {
    amount: 0,
    category: ExpenseCategory.Other,
    purchaseDate: new Date(),
    receipt: "",
    notes: "",
    sourceId: 0,
    amountxxxx: 0,
    createdAt: undefined,
    id: 0,
    moneyAccountId: null,
  };

  private categories = [];

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  created() {
    this.categories = [
      { text: this.$t(this.$ts.expenseCategory.rent), value: ExpenseCategory.Rent },
      { text: this.$t(this.$ts.expenseCategory.utilities), value: ExpenseCategory.Utilities },
      { text: this.$t(this.$ts.expenseCategory.inventory), value: ExpenseCategory.Inventory },
      { text: this.$t(this.$ts.expenseCategory.commission), value: ExpenseCategory.Commission },
      { text: this.$t(this.$ts.expenseCategory.salaries), value: ExpenseCategory.Salaries },
      { text: this.$t(this.$ts.expenseCategory.other), value: ExpenseCategory.Other },
    ];
  }

  get dialogVisible(): boolean {
    return this.visible;
  }

  set dialogVisible(value: boolean) {
    this.$emit("update:visible", value);
  }

  get showStaffMemberSelect(): boolean {
    return (
      this.currentItem.category === ExpenseCategory.Commission || this.currentItem.category === ExpenseCategory.Salaries
    );
  }

  @Watch("expense", { immediate: true })
  onExpenseChange(newValue?: IOneTimeExpenseDTO) {
    if (newValue) {
      this.currentItem = { ...newValue };
    } else {
      this.resetForm();
    }
  }

  private onCategoryChange() {}

  private resetForm() {
    this.currentItem = {
      amount: 0,
      category: ExpenseCategory.Other,
      purchaseDate: new Date(),
      receipt: "",
      notes: "",
      sourceId: 0,
      amountxxxx: 0,
      createdAt: undefined,
      id: 0,
      moneyAccountId: null,
    };
  }

  private close() {
    this.dialogVisible = false;
    this.resetForm();
  }

  private save() {
    this.$emit("save", this.currentItem);
    this.close();
  }
}
</script>
