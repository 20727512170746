<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import CustomButton from "../../global/styledComponents/customButton.vue";
import Loader from "../../global/Loader.vue";
import { IPostResourceDTO, IResourceDTO, IStaffMemberDTO } from "@shared/types";
import { DialogType, IDialog } from "@/store/dialog";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import { resourcesModule } from "@/store/modules/resources/resourcesModule";
import { userModule } from "@/store/modules/user/userModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "resources",
  components: {
    Loader,
    CustomButton,
    BaseFormDialog,
    CompanyInfoField,
    Textfield,
  },
})
export default class Resources extends Vue {
  public newResource: IPostResourceDTO = null;
  public isShowingForm: boolean = false;

  private isLoading: boolean = true;
  private editingResource: IResourceDTO | IPostResourceDTO = null;
  private isEditing: boolean = false;
  public autoApply: boolean = true;
  private isCreating: boolean = false;

  // Initialize as empty arrays
  private nameRules: Array<(v: any) => boolean | string> = [];
  private amountRules: Array<(v: any) => boolean | string> = [];

  private validateField(fieldName: string) {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
  }

  get resources(): IResourceDTO[] {
    return resourcesModule.resources;
  }

  deleteResource(resourceId: number): void {
    resourcesModule.deleteResource(resourceId);
  }

  public toggleAdd(): void {
    let newEditResource: IPostResourceDTO = {
      amount: 1,
      description: "",
      name: "",
    };
    this.editingResource = newEditResource;
    this.isCreating = true;
    this.isShowingForm = !this.isShowingForm;
  }

  public toggleEdit(resource: IResourceDTO): void {
    this.editingResource = resource;
    this.isEditing = true;
    this.isShowingForm = !this.isShowingForm;
  }
  async runEditResource() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      await resourcesModule.editResource(this.editingResource as IResourceDTO);
      this.isEditing = false;
      this.editingResource = null;
    }
  }
  async runAddNewAppointmentType(resource: Partial<IResourceDTO>): Promise<void> {
    if (resource.name == null || resource.name == "") {
      snackbarModule.postSnackbarMessage(this.$t(this.$ts.nameIsRequired).toString());
      return;
    }

    if (resource.amount == null || resource.amount < 1) {
      snackbarModule.postSnackbarMessage(this.$t(this.$ts.amountMustBeOneOrHigher).toString());
      return;
    }

    this.isLoading = true;
    this.isShowingForm = false;

    await resourcesModule.addResource(resource as IPostResourceDTO);

    this.isCreating = false;
    this.editingResource = null;
    this.clearForm();
    this.isLoading = false;
  }

  clearForm(): void {
    if (this.newResource) {
      this.newResource.name = "";
      this.newResource.amount = 1;
      this.newResource.description = "";
    }
  }

  async runDeleteAppointmentType(resourceId: number): Promise<void> {
    const vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.resources.deleteConfirmation).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.resources.deleteHeader).toString(),
      action: function () {
        resourcesModule.deleteResource(resourceId);
      },
    };
    dialogModule.addToDialogQueue(dialog);
  }
  editService(resource) {
    this.isEditing = true;
    this.editingResource = resource;
  }
  runEditService() {
    resourcesModule.editResource(this.editingResource as IResourceDTO);
    this.isEditing = false;
    this.editingResource = null;
  }

  async runCreateResource() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      await resourcesModule.addResource(this.editingResource as IPostResourceDTO);
      this.isCreating = false;
      this.editingResource = null;
    }
  }

  created() {
    // Set validation rules
    this.nameRules = [(v: string) => !!v || this.$t(this.$ts.nameIsRequired).toString()];

    this.amountRules = [(v: number) => v >= 1 || this.$t(this.$ts.amountMustBeOneOrHigher).toString()];

    // Existing created logic
    resourcesModule.getResources();
    this.isLoading = false;
  }

  get showEditDialog() {
    return this.isEditing || this.isCreating;
  }

  set showEditDialog(value) {
    this.isEditing = value;
    this.isCreating = value;
  }

  booleanToNorwegian(value: boolean): string {
    if (value) {
      return this.$t(this.$ts.boolean.yes).toString();
    } else {
      return this.$t(this.$ts.boolean.no).toString();
    }
  }
}
</script>
<template>
  <div>
    <v-dialog v-model="showEditDialog" max-width="600">
      <BaseFormDialog
        v-if="showEditDialog"
        :title="isEditing ? $t($ts.resources.editResource) : $t($ts.resources.createResource)"
        :isEditing="isEditing"
        :isCreating="isCreating"
        :isSaving="isLoading"
        :saveText="$t($ts.save)"
        :createText="$t($ts.create)"
        :cancelText="$t($ts.cancel)"
        @cancel="showEditDialog = false"
        @save="runEditResource"
        @create="runCreateResource"
        ref="baseForm"
        v-model="showEditDialog"
      >
        <!-- Resource Name -->
        <CompanyInfoField :label="$t($ts.resources.name)" :description="$t($ts.resources.nameDescription)">
          <Textfield v-model="editingResource.name" :rules="nameRules" @input="validateField('name')" />
        </CompanyInfoField>

        <v-divider />

        <!-- Description -->
        <CompanyInfoField
          :label="$t($ts.resources.description)"
          :description="$t($ts.resources.descriptionDescription)"
        >
          <Textfield v-model="editingResource.description" type="textarea" />
        </CompanyInfoField>

        <v-divider />

        <!-- Amount -->
        <CompanyInfoField :label="$t($ts.resources.quantity)" :description="$t($ts.resources.quantityDescription)">
          <Textfield
            v-model="editingResource.amount"
            :number="true"
            :rules="amountRules"
            @input="validateField('amount')"
          />
        </CompanyInfoField>
      </BaseFormDialog>
    </v-dialog>

    <loader v-if="isLoading"></loader>

    <div v-if="!isLoading">
      <v-simple-table width="100%" height="100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t($ts.name) }}</th>
              <th class="text-left">{{ $t($ts.description) }}</th>
              <th class="text-left">{{ $t($ts.quantity) }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="resource in resources" :key="resource.id">
              <td>{{ resource.name }}</td>
              <td>{{ resource.description }}</td>
              <td>{{ resource.amount }}</td>
              <td>
                <v-icon @click="toggleEdit(resource)">edit</v-icon>
              </td>
              <td>
                <v-icon @click="deleteResource(resource.id)">delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
