import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "@/components/global/ServiceCategories.vue";
import Resources from "@/components/Settings/Services/Resources.vue";
import StaffMembersOverview from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import AccountRoleSettings from "@/components/Settings/AccountRoleSettings.vue";
import Vue from "vue";

type VueComponent = Vue & ThisType<Vue>;

interface ToolbarAction {
  icon: string;
  handler: string;
  tooltip: string;
}

interface ToolbarComponentConfig {
  actions: ToolbarAction[];
}

interface ToolbarConfig {
  TreatmentTypes: ToolbarComponentConfig;
  ServiceCategories: ToolbarComponentConfig;
  Resources: ToolbarComponentConfig;
  StaffMembersOverview: ToolbarComponentConfig;
  AccountSettings: ToolbarComponentConfig;
  AccountRoleSettings: ToolbarComponentConfig;
  InventoryItems: ToolbarComponentConfig;
  InventoryCategories: ToolbarComponentConfig;
}

export const toolbarConfig: ToolbarConfig = {
  TreatmentTypes: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "toggleForm",
        tooltip: "Add Treatment Type",
      },
    ],
  },
  ServiceCategories: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "toggleForm",
        tooltip: "Add Service Category",
      },
    ],
  },
  Resources: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "toggleAdd",
        tooltip: "Add Resource",
      },
    ],
  },
  StaffMembersOverview: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "toggleForm",
        tooltip: "Add Staff Member",
      },
    ],
  },
  AccountSettings: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "openNewAccountDialog",
        tooltip: "Add Account",
      },
    ],
  },
  AccountRoleSettings: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "openNewRoleDialog",
        tooltip: "Add Role",
      },
    ],
  },
  InventoryItems: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "openCreateDialog",
        tooltip: "Add Item",
      },
    ],
  },
  InventoryCategories: {
    actions: [
      {
        icon: "mdi-plus",
        // @ts-ignore
        handler: "openCreateDialog",
        tooltip: "Add Category",
      },
    ],
  },
};
