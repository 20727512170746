<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import Loader from "../../components/global/Loader.vue";
import { DialogType, IDialog } from "@/store/modules/dialogModule/dialogModule";
import EditCustomer from "../../components/global/EditCustomer.vue";
import { IAuditLogDTO, ICustomerDTO } from "@shared/types";
import { i18n } from "@/main";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import LedgerEntriesOverview from "@/components/global/LedgerEntriesOverview.vue";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "Customer",
  components: { EditCustomer, Loader, CustomerModal, Sidebar, HeaderBar, LedgerEntriesOverview },
})
export default class Customer extends Vue {
  public customer: ICustomerDTO = null;
  deleteCustomerDialog: boolean = false;
  editing: boolean = false;

  get user() {
    return userModule.user;
  }

  onEditedCustomer() {
    this.loadCustomer();
  }

  async created() {
    await this.loadCustomer();

    let customerId: string = this.$route.params.customerId;

    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: " opened customer page: " + customerId,
      message: " opened customer page: " + customerId,
      hideForUser: true,
    };

    auditLogModule.postAudit({ dto: audit as any });
  }

  async loadCustomer() {
    let customerId: number = parseInt(this.$route.params.customerId, 10);
    try {
      this.customer = await customersModule.getCustomer(customerId);
    } catch (e) {
      let dialog: IDialog = {
        text: i18n.t("customer.notFound").toString(),
      };
      dialogModule.addToDialogQueue(dialog);
      this.goBack();
    }
  }

  goBack() {
    this.$router.push("/kunder").catch(() => {});
  }

  async deletedCustomer() {
    try {
      await customersModule.deleteCustomer(this.customer.id);
      this.goBack();
    } catch (e) {
    }
  }

  public editCustomer(): void {
    this.editing = true;
  }

  finished() {
    this.editing = false;
    this.$emit("finished");
  }

  deleteCustomerAction() {
    let vm = this;

    let dialog: IDialog = {
      text: i18n.t("customer.confirmDeleteCustomer").toString(),
      type: DialogType.Choice,
      header: i18n.t("customer.deleteCustomerHeader").toString(),
      action: function () {
        if (vm.customer != null) {
          vm.deletedCustomer();
          vm.finished();
          vm.deleteCustomerDialog = false;
        }
      },
    };

    dialogModule.addToDialogQueue(dialog);
  }

  deleted(customerId) {
    this.$emit("deleted", customerId);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: this.user?.currency || "USD",
    }).format(value);
  }

  updateNotes(customer: ICustomerDTO) {
    customersModule.putCustomer(customer)
      .then(() => {
      })
      .catch(() => {
      });
  }
}
</script>

<template>
  <div>
    <edit-customer @delete="deleted" @finish="finished" v-if="editing" :customer="customer"></edit-customer>
    <loader v-if="customer == null"></loader>
    <div v-if="customer != null">
      <v-app-bar class="hidden-lg-and-up custom-header" flat fixed top>
        <v-container fluid>
          <v-row class="align-center py-2" justify="space-between">
            <v-col cols="auto">
              <v-btn class="mobile-header-button" icon @click="goBack()">
                <v-icon color="white">mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>

            <v-col class="text-center">
              <div class="white--text text-h6">{{ customer.firstName }} {{ customer.lastName }}</div>
            </v-col>

            <v-col cols="auto" class="d-flex align-center">
              <v-btn icon class="mx-1" @click="editCustomer()">
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon class="mx-1" @click="deleteCustomerAction()">
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-row justify-md="center" class="mt-10">
        <v-col xs="12" lg="12">
          <v-card class="customer-card">
            <v-card-title style="background-color: #866afe" class="white--text">
              <v-icon left color="white">mdi-account</v-icon>
              <span class="headline">{{ customer.firstName }} {{ customer.lastName }}</span>
              <v-spacer></v-spacer>
              <div class="hidden-md-and-down">
                <v-btn icon class="mx-1" @click="editCustomer()">
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="mx-1" @click="deleteCustomerAction()">
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-card-text class="pt-4">
              <v-container fluid>
                <!-- Contact Information Section -->
                <v-row class="mb-4">
                  <v-col cols="12">
                    <v-card outlined class="info-card">
                      <v-card-text>
                        <div class="d-flex align-center mb-3">
                          <v-icon style="color: #866afe" class="mr-2">mdi-information</v-icon>
                          <div class="subtitle-1 font-weight-bold">{{ $t("customer.name") }}</div>
                        </div>
                        <v-row>
                          <!-- Phone -->
                          <v-col cols="12" sm="6" md="4">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-phone</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("customer.phoneNumber") }}</div>
                                <div>{{ customer.phoneNumber || "-" }}</div>
                              </div>
                            </div>
                          </v-col>

                          <!-- Email -->
                          <v-col cols="12" sm="6" md="4">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-email</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("customer.email") }}</div>
                                <div>{{ customer.emailAddress || "-" }}</div>
                              </div>
                            </div>
                          </v-col>

                          <!-- Created Date -->
                          <!-- <v-col cols="12" sm="6" md="4">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-calendar</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("customer.created") }}</div>
                                <div>{{ new Date(customer.created).toLocaleDateString() }}</div>
                              </div>
                            </div>
                          </v-col> -->
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Address Section -->
                <v-row class="mb-4" v-if="customer.address">
                  <v-col cols="12">
                    <v-card outlined class="address-card">
                      <v-card-text>
                        <div class="d-flex align-center mb-3">
                          <v-icon style="color: #866afe" class="mr-2">mdi-home</v-icon>
                          <div class="subtitle-1 font-weight-bold">{{ $t("customer.address") }}</div>
                        </div>
                        <div class="ml-8">
                          <div>{{ customer.address }}</div>
                          <div v-if="customer.postalCode || customer.city">
                            {{ customer.postalCode }} {{ customer.city }}
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Additional Information Section -->
                <v-row class="mb-4" v-if="customer.dateOfBirth || customer.gender || customer.ssn">
                  <v-col cols="12">
                    <v-card outlined class="additional-info-card">
                      <v-card-text>
                        <div class="d-flex align-center mb-3">
                          <v-icon style="color: #866afe" class="mr-2">mdi-card-account-details</v-icon>
                          <div class="subtitle-1 font-weight-bold">{{ $t("login.additionalInfo") }}</div>
                        </div>
                        <v-row>
                          <!-- Date of Birth -->
                          <v-col cols="12" sm="6" md="4" v-if="customer.dateOfBirth">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-cake</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("customer.dateOfBirth") }}</div>
                                <div>{{ new Date(customer.dateOfBirth).toLocaleDateString() }}</div>
                              </div>
                            </div>
                          </v-col>

                          <!-- Gender -->
                          <v-col cols="12" sm="6" md="4" v-if="customer.gender">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-gender-male-female</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("gender") }}</div>
                                <div>{{ customer.gender }}</div>
                              </div>
                            </div>
                          </v-col>

                          <!-- SSN -->
                          <v-col cols="12" sm="6" md="4" v-if="customer.ssn">
                            <div class="d-flex align-center">
                              <v-icon style="color: #866afe" class="mr-2">mdi-card-account-details</v-icon>
                              <div>
                                <div class="caption text-uppercase">{{ $t("ssn") }}</div>
                                <div>{{ customer.ssn }}</div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Notes Section -->
                <v-row class="mb-4">
                  <v-col cols="12">
                    <v-card outlined class="notes-card">
                      <v-card-text>
                        <div class="d-flex align-center mb-3">
                          <v-icon style="color: #866afe" class="mr-2">mdi-text</v-icon>
                          <div class="subtitle-1 font-weight-bold">{{ $t("customerModal.notes") }}</div>
                        </div>
                        <v-textarea
                          @change="updateNotes(customer)"
                          :placeholder="$t($ts.writeHere)"
                          outlined
                          v-model="customer.generalInfo"
                          class="mt-2"
                        ></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <customer-modal
        class="mt-4"
        :showCustomerName="false"
        @finished="onEditedCustomer"
        @deleted="deletedCustomer"
        :customer="customer"
      ></customer-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-header {
  background-color: #866afe !important;
}

.v-btn.mobile-header-button {
  height: 48px;
  width: 48px;
}

.customer-card {
  border-radius: 8px;
  margin-bottom: 20px;
}

.info-card,
.address-card,
.additional-info-card,
.notes-card {
  border-radius: 4px;
  transition: all 0.3s;
}

.info-card:hover,
.address-card:hover,
.additional-info-card:hover,
.notes-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  font-weight: 500;
  font-size: 1.5rem;
}

.subtitle-1 {
  font-weight: 500;
}

.caption {
  color: rgba(0, 0, 0, 0.6);
}

.v-card-title {
  word-break: break-word;
}
</style>
