<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../ToolTip.vue";
import { IAuditLogDTO, IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

@Component({
  name: "ServiceOptions",
  components: { ToolTip },
})
export default class ServiceOptions extends Vue {
  public multipleServices: boolean = false;
  public showServicePrice: boolean = false;

  created() {
    this.multipleServices = userModule.user.multipleServices;
    this.showServicePrice = userModule.user.showServicePrice;
  }

  async saveField(field: string, value: boolean): Promise<void> {
    try {
      // Create audit log based on field
      if (field === "multipleServices" && value !== userModule.user.multipleServices) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t("serviceOptions.auditLogs.changedShowInformationBox", {
            setting: this.booleanToOnOff(value),
          }).toString(),
          hideForUser: false,
        });
      } else if (field === "showServicePrice" && value !== userModule.user.showServicePrice) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(this.$ts.serviceOptions.auditLogs.changedShowServicePrice, {
            setting: this.booleanToOnOff(value),
          }).toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await userModule.updateUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      if (field === "multipleServices") {
        this.multipleServices = userModule.user.multipleServices;
      } else if (field === "showServicePrice") {
        this.showServicePrice = userModule.user.showServicePrice;
      }
    }
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.yes).toString() : this.$t(this.$ts.boolean.no).toString();
  }
}
</script>

<template>
  <div>
    <v-card flat>
      <v-card-text>
        <h2>{{ $t($ts.multipleServices) }}</h2>
      </v-card-text>

      <v-card-text>
        <h3>{{ $t($ts.multipleServicesQuestion) }}</h3>
      </v-card-text>
      <v-card-text>
        <v-switch
          v-model="multipleServices"
          color="#56dcb6"
          :label="booleanToOnOff(multipleServices)"
          @change="saveField('multipleServices', $event)"
        ></v-switch>
      </v-card-text>
    </v-card>

    <v-card flat>
      <v-card-text>
        <h3>{{ $t($ts.showServicePrice) }}</h3>
      </v-card-text>
      <v-card-text>
        <v-switch
          v-model="showServicePrice"
          color="#56dcb6"
          :label="booleanToOnOff(showServicePrice)"
          @change="saveField('showServicePrice', $event)"
        ></v-switch>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped></style>
