import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { WorkingDaysService } from "@/services/api/WorkingDaysService";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class WorkingDaysModule extends VuexModule {
  private workingDaysService = WorkingDaysService.getInstance();
  private store: Store<any>;

  constructor(options: any) {
    super(options);
    this.store = options.store;
  }

  // State
  allWorkingDays: boolean[] = [];
  vacationDays: Date[] = [];

  @Action
  async getAllWorkingDays(staffMemberId?: number) {
    this.allWorkingDays = await this.workingDaysService.getAllWorkingDays(staffMemberId);
  }

  @Action
  async updateWorkingDays(payload: { dayOfWeek: number; isWorking: boolean; staffMemberId?: number }) {
    await this.workingDaysService.toggleWorkingHourDay(payload.dayOfWeek, payload.isWorking, payload.staffMemberId);
    await this.getAllWorkingDays(payload.staffMemberId);
  }
}

export const workingDaysModule = new WorkingDaysModule({ store, name: "workingDaysModule" });
