<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IAppointmentTypeDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

@Component({
  name: "service-selection",
  components: { CompanyInfoField },
})
export default class ServiceSelection extends Vue {
  @Prop({ default: false }) useMultipleServices!: boolean;
  @Prop() selectedServiceId!: number;
  @Prop() selectedServiceIds!: number[];
  @Prop() appointmentTypeItems!: IAppointmentTypeDTO[];
  @Prop() user!: any;

  selectServicesFilter(item: any, queryText: string) {
    const textOne = item.title.toLowerCase();
    return textOne.includes(queryText.toLowerCase());
  }

  onChangeServiceMultiple(e: number[]) {
    if (e.includes(-1)) {
      const lastElement = e[e.length - 1];
      this.$emit("update:selectedServiceId", -1);
      this.$emit("update:selectedServiceIds", [lastElement]);
      //@ts-ignore
      this.$refs.multipleselect.blur();
    } else {
      this.$emit("update:selectedServiceId", null);
      this.$emit("update:selectedServiceIds", e);
    }
    this.$emit("service-change");
  }

  onChangeService(e: number) {
    this.$emit("update:selectedServiceId", e);
    this.$emit("service-change");
    //@ts-ignore
    this.$refs.singleselect.blur();
  }
}
</script>

<template>
  <CompanyInfoField :ignore-mobile-layout="true">
    <v-autocomplete
      v-if="!useMultipleServices"
      prepend-icon="shopping_basket"
      @change="onChangeService"
      :value="selectedServiceId"
      :items="appointmentTypeItems"
      item-value="id"
      ref="singleselect"
      :placeholder="$t($ts.placeHolder.typeToSearch)"
      outlined
      dense
      class="format-box"
      :filter="selectServicesFilter"
    >
      <template v-slot:label> <span style="color: red; font-size: 24px">*</span>{{ $t($ts.service) }} </template>

      <template v-slot:item="data">
        <div v-if="data.item.id === -1"><v-icon>mdi-calendar-remove</v-icon> {{ data.item.title }}</div>
        <div v-else>
          <div v-if="data.item != null && data.item.title != null && data.item.title.length > 0">
            {{ data.item.title }} ({{ data.item.durationInMinutes }} min | {{ $t($ts.currency) }}{{ data.item.price }})
          </div>
        </div>
      </template>

      <template v-slot:selection="data">
        <div v-if="data.item.id === -1">
          {{ data.item.title }}
        </div>
        <div v-else>
          <div v-if="data.item != null && data.item.title != null && data.item.title.length > 0">
            {{ data.item.title }}
          </div>
        </div>
      </template>

      <template v-slot:no-data></template>
    </v-autocomplete>

    <v-autocomplete
      multiple
      v-if="useMultipleServices"
      prepend-icon="shopping_basket"
      @change="onChangeServiceMultiple"
      :value="selectedServiceIds"
      :filter="selectServicesFilter"
      :items="appointmentTypeItems"
      item-value="id"
      ref="multipleselect"
      outlined
      dense
      class="format-box"
      :placeholder="$t($ts.placeHolder.typeToSearch)"
    >
      <template v-slot:label> <span style="color: red; font-size: 24px">*</span>{{ $t($ts.service) }} </template>

      <template v-slot:item="data">
        <div v-if="data.item.id === -1"><v-icon>mdi-calendar-remove</v-icon> {{ data.item.title }}</div>
        <div v-else>
          <div v-if="data.item != null && data.item.title != null && data.item.title.length > 0">
            {{ data.item.title }} ({{ data.item.durationInMinutes }} min | {{ $t($ts.currency) }}{{ data.item.price }})
          </div>
        </div>
      </template>

      <template v-slot:selection="data">
        <v-chip>
          {{ data.item.title }}
        </v-chip>
      </template>
    </v-autocomplete>
  </CompanyInfoField>
</template>
