<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "SubscriptionReminderDialog",
})
export default class SubscriptionReminderDialog extends Vue {
  @Prop({ required: true }) readonly show!: boolean;

  gotoSubscription() {
    this.$router.push("/settings/5").catch(() => {});
  }
}
</script>

<template>
  <v-dialog max-width="600px" :value="show" @input="$emit('update:show', $event)">
    <v-card>
      <v-card-text style="font-weight: bold">
        {{ $t("subscriptionWarnings.message1") }}
      </v-card-text>

      <v-card-text style="font-weight: bold">
        {{ $t("subscriptionWarnings.message2") }}
      </v-card-text>

      <v-card-text style="font-weight: bold">
        {{ $t("subscriptionWarnings.message3") }}
      </v-card-text>

      <v-card-actions>
        <v-btn color="success" @click="gotoSubscription">
          {{ $t("buttons.gotoSubscription") }}
        </v-btn>
        <v-btn @click="$emit('update:show', false)">
          {{ $t("buttons.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template> 