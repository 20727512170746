import { IAppointmentTypeDTO, IServiceController, IServiceGroupForBookingDTO } from "@shared/types";

export class MockServiceService implements IServiceController {
  private static instance: MockServiceService;
  private mockServices: IAppointmentTypeDTO[] = [
    {
      id: 1,
      title: "Mock Service 1",
      durationInMinutes: 60,
      serviceDurationInMinutes: 60,
      price: 1000,
      description: "Mock service description",
      isExtraService: false,
      order: 1,
      categoryId: 1,
      categoryName: "Mock Category",
      children: [],
      image: "",
      discountPrice: 0,
      hideInBooking: false,
      autoApply: false,
      showCustomColor: false,
      color: "",
      unavailableForOnlineBooking: false,
      disablePrice: false,
      mustPayOnline: false,
      payOnlinePrice: 0,
      resources: [],
      eligibleForCommission: false,
      commissionPercentage: 0,
    },
  ];

  private constructor() {}

  public static getInstance(): MockServiceService {
    if (!MockServiceService.instance) {
      MockServiceService.instance = new MockServiceService();
    }
    return MockServiceService.instance;
  }

  public async getTreatmentType(appointmentTypeId: number): Promise<any> {
    return Promise.resolve(this.mockServices.find((s) => s.id === appointmentTypeId));
  }

  public async changeExtraService(extraServiceChange: any): Promise<void> {
    return Promise.resolve();
  }

  public async getServices(
    query: Partial<typeof IServiceController.IGetServicesQuery>
  ): Promise<IAppointmentTypeDTO[]> {
    if (query.extra === "true") {
      return Promise.resolve(this.mockServices.filter((s) => s.isExtraService));
    }
    return Promise.resolve(this.mockServices);
  }

  public async getServicesAsAnonymous(userId: string): Promise<IServiceGroupForBookingDTO[]> {
    return Promise.resolve([
      {
        categoryId: 1,
        categoryName: "Mock Category",
        title: "Mock Category",
        services: this.mockServices,
      },
    ]);
  }

  public async putAppointmentType(id: number, categoryId: number, order: number): Promise<void> {
    const service = this.mockServices.find((s) => s.id === id);
    if (service) {
      service.categoryId = categoryId;
      service.order = order;
    }
    return Promise.resolve();
  }

  public async changeOrderForService(serviceId: number, order: number): Promise<void> {
    const service = this.mockServices.find((s) => s.id === serviceId);
    if (service) {
      service.order = order;
    }
    return Promise.resolve();
  }

  public async editService(treatmentType: IAppointmentTypeDTO): Promise<void> {
    const index = this.mockServices.findIndex((s) => s.id === treatmentType.id);
    if (index !== -1) {
      this.mockServices[index] = treatmentType;
    }
    return Promise.resolve();
  }

  public async postService(treatmentType: IAppointmentTypeDTO): Promise<any> {
    const newService = {
      ...treatmentType,
      id: Math.max(...this.mockServices.map((s) => s.id)) + 1,
    };
    this.mockServices.push(newService);
    return Promise.resolve(newService);
  }

  public async assignAppointmentTypeToUser(treatmentTypeId: number): Promise<void> {
    return Promise.resolve();
  }

  public async deleteTreatmentType(id: number): Promise<void> {
    const index = this.mockServices.findIndex((s) => s.id === id);
    if (index !== -1) {
      this.mockServices.splice(index, 1);
    }
    return Promise.resolve();
  }
}
