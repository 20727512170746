<script lang="ts" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
import { Component, Vue, Watch } from "vue-property-decorator";
import { IDialog } from "@/store/dialog";
import { getApiUrl, getStripePublicKey } from "../../main";
import { getFbConfig } from "../../Utilities/authConfig";
import firebase from "firebase/app";
import { formatDateStringHour } from "../../Utilities/dateUtility";

import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { debugLogModule } from "@/store/modules/debugLog/debugLogModule";
import { adminModule } from "@/store/modules/admin/adminModule";

@Component({
  name: "DebugOverview",
  components: {},
})
export default class DebugOverview extends Vue {
  public headers = [
    { text: "", align: "start", sortable: true, value: "severity" },
    { text: "Beskjed", sortable: true, value: "message" },
    { text: "Tid", sortable: true, value: "time" },
    { text: "Details", align: "start", sortable: true, value: "innerMessage" },
    {
      text: "Stack trace",
      align: "start",
      sortable: true,
      value: "stackTrace",
    },
  ];

  public customToken: string = "";

  isLoading: boolean = true;
  events = [];
  selectedEvents = [];

  statusFilters = ["Info", "Warning", "Error", "Critical"];

  get invoiceStatuses() {
    return ["Info", "Warning", "Error", "Critical"];
  }

  get firebaseUsers() {
    return adminModule.users;
  }

  get users() {
    return adminModule.users;
  }

  get debugEvents() {
    return debugLogModule.debugEvents;
  }

  public async created(): Promise<any> {
    await adminModule.getUsers();
    await debugLogModule.getDebugEvents();
    this.isLoading = false;
  }

  getColorChip(eventId) {
    if (eventId == 0) return "blue";
    if (eventId == 1) return "orange";
    if (eventId == 2) return "red lighten-2";
    if (eventId == 3) return "red";
  }

  getLabel(eventId) {
    if (eventId == 0) return "Info";
    if (eventId == 1) return "Warning";
    if (eventId == 2) return "Error";
    if (eventId == 3) return "Critical";
  }
  eventIdToString(eventId) {
    if (eventId == 0) {
      return "Debug log";
    }
  }
  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  get eventsForTable() {
    return this.events.filter((x) => {
      return this.statusFilters.includes(this.getLabel(x.eventId)) || x.eventId == null;
    });
  }

  getColor(eventId) {
    if (eventId == "Info") return "blue";
    if (eventId == "Warning") return "orange";
    if (eventId == "Error") return "red lighten-2";
    if (eventId == "Critical") return "red";
    return "grey";
  }

  readComment(comment) {
    let dialog: IDialog = {
      text: comment,
      fullScreen: true,
      html: true,
    };
    dialogModule.addToDialogQueue(dialog);
  }

  get stripePublishKey() {
    return getStripePublicKey();
  }
  get apiUrl() {
    return getApiUrl();
  }
  get firebaseConfig() {
    return getFbConfig();
  }

  loginWithCustomToken() {
    firebase
      .auth()
      .signInWithCustomToken(this.customToken)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        // ...
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;

        let dialog: IDialog = {
          text: errorMessage,
        };
        dialogModule.addToDialogQueue(dialog);
        // ...
      });
  }

  async attemptAuthentication(uid) {
    this.customToken = await adminModule.getToken(uid);
    this.loginWithCustomToken();
  }
}
</script>

<template>
  <v-col>
    <v-row>
      <v-row>
        <v-col cols="auto">
          <v-card>
            <v-card-title> Stripe </v-card-title>
            <v-card-text> Publish key: {{ stripePublishKey }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card>
            <v-card-title> API </v-card-title>
            <v-card-text> Url: {{ apiUrl }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card>
            <v-card-title> Firebase </v-card-title>
            <v-card-text> Config: {{ firebaseConfig }} </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="treatment-list">
        <v-select
          class="mt-3"
          dense
          chips
          attach
          multiple
          label="Filter"
          v-model="statusFilters"
          :items="invoiceStatuses"
        >
          <template v-slot:selection="data">
            <v-chip dark :color="getColor(data.item)">
              <span>{{ data.item }}</span
              >&nbsp;
            </v-chip>
          </template>
        </v-select>

        <div class="treatment">
          <v-data-table
            v-if="!isLoading"
            :headers="headers"
            :items="eventsForTable"
            :items-per-page="10"
            :single-select="false"
            v-model="selectedEvents"
            :sort-desc="false"
          >
            <!--<template v-slot:group.header="{group}">-->
            <!--<td v-if="group == 'false'" colspan="12">Nye</td>-->
            <!--<td v-if="group == 'true'" colspan="12">Tidligere</td>-->
            <!--</template>-->f

            <template v-slot:no-data> Du har ingen hendelser </template>

            <template v-slot:item.severity="{ item }">
              <v-chip :color="getColorChip(item.eventId)" dark>{{ getLabel(item.eventId) }}</v-chip>
            </template>

            <template v-slot:item.innerMessage="{ item }">
              <v-btn
                v-if="item.innerMessage != null && item.innerMessage.length > 1"
                @click="readComment(item.innerMessage)"
                icon
              >
                <v-icon>comment</v-icon></v-btn
              >
            </template>

            <template v-slot:item.stackTrace="{ item }">
              <v-btn
                v-if="item.stackTrace != null && item.stackTrace.length > 1"
                @click="readComment(item.stackTrace)"
                icon
              >
                <v-icon>comment</v-icon></v-btn
              >
            </template>

            <template v-slot:item.eventId="{ item }">
              <td>{{ eventIdToString(item.eventId) }}</td>
            </template>

            <!-- <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.Message }}kr</td>
            </template> -->

            <template v-slot:item.time="{ item }">
              <span>{{ formatDateStringHour(item.time) }}</span>
            </template>

            <template v-slot:item.hasSeen="{ item }">
              <v-simple-checkbox v-model="item.hasSeen" disabled></v-simple-checkbox>

              <!--<span>{{item.hasSeen}}</span>-->
            </template>
          </v-data-table>
          <v-data-table v-else loading loading-text="Laster... Vennligst vent.."></v-data-table>
        </div>
      </div>
    </v-row>

    <v-row>
      <v-text-field v-model="customToken" label="Custom token"></v-text-field>
      <v-btn @click="loginWithCustomToken">Go</v-btn>
    </v-row>

    <v-col>
      <v-list three-line>
        <template v-for="(fbUser, index) in firebaseUsers">
          <!--<v-subheader-->
          <!--v-text="fbUser.email"-->
          <!--&gt;</v-subheader>-->

          <v-divider :inset="true"></v-divider>

          <v-list-item>
            <v-list-item-avatar>
              <v-img src="/img/login.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="fbUser.email"></v-list-item-title>

              <v-row>
                <v-col cols="auto">
                  <v-list-item-subtitle v-html="fbUser.uid"></v-list-item-subtitle>
                  <v-list-item-subtitle>Last sign in{{ fbUser.userMetaData.lastSignInTimestamp }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    >Last refresh {{ fbUser.userMetaData.lastRefreshTimestamp }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>Created {{ fbUser.userMetaData.creationTimestamp }}</v-list-item-subtitle>
                </v-col>
                <v-col cols="auto">
                  <v-btn @click="attemptAuthentication(fbUser.uid)">Log in</v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
  </v-col>
</template>
../../store/dialogModule @/store/dialog
@/store/modules/events/eventsModule@/store/modules/firebaseUser/firebaseUserModule../../store/modules/dialogModule/dialogModule@/store/modules/dialogModule/dialogModule@/store/modules/debugLog/debugLogModule
@/store/modules/user/userModule
