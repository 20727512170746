<template>
  <div>
    <v-select
      v-model="inputValue"
      outlined
      dense
      :items="items"
      :disabled="disabled"
      :style="selectFieldStyle"
      class="format-box"
      :rules="rules"
      :error-messages="errorMessages"
      validate-on-blur
      @blur="onBlur"
      @input="onInput"
    />
    <p v-if="errorMessages.length > 0" class="error--text">
      {{ errorMessages[0] }}
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EnumField",
})
export default class EnumField extends Vue {
  @Prop() public value!: any;
  @Prop() public items!: Array<{ text: string; value: any }>;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: () => [] }) public rules!: Array<
    (v: any) => boolean | string
  >;

  private errorMessages: string[] = [];

  get selectFieldStyle() {
    return {
      borderRadius: "8px",
      maxWidth: "250px",
      minWidth: "250px",
    };
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit("input", value);
  }

  onInput(value: any) {
    this.validateInput(value);
  }

  onBlur() {
    this.$emit("blur");
    this.validateInput(this.inputValue);
  }

  private validateInput(value: any) {
    this.errorMessages = [];
    for (const rule of this.rules) {
      const result = rule(value);
      if (result !== true && result !== false) {
        this.errorMessages.push(result as string);
        break; // Show only first error message
      }
    }
  }

  mounted() {
    // Initial validation
    this.$nextTick(() => {
      this.validateInput(this.inputValue);
    });
  }
}
</script>

<style scoped>
.format-box {
  margin-bottom: 4px;
}
</style>
