import { BaseService } from "./Base/BaseService";
import { IPauseTimeController, IPauseTimeDTO, IPostPauseTimeDTO, ISimplePauseTimeDTO } from "@shared/types";

export class PauseTimeService extends BaseService implements IPauseTimeController {
  private static instance: PauseTimeService;
  protected controller = "PauseTimes";

  private constructor() {
    super();
  }

  public static getInstance(): PauseTimeService {
    if (!PauseTimeService.instance) {
      PauseTimeService.instance = new PauseTimeService();
    }
    return PauseTimeService.instance;
  }

  public async getPauseTimes(staffMemberId?: number): Promise<ISimplePauseTimeDTO[]> {
    let url = `${this.controller}/current`;
    if (staffMemberId) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<ISimplePauseTimeDTO[]>(url);
  }

  public async postPauseTime(pauseTime: IPostPauseTimeDTO): Promise<ISimplePauseTimeDTO[]> {
    return this.http.postAuthenticated<ISimplePauseTimeDTO[]>(`${this.controller}/current`, pauseTime);
  }

  public async deletePauseTime(id: number): Promise<ISimplePauseTimeDTO[]> {
    return this.http.deleteAuthenticated<ISimplePauseTimeDTO[]>(`${this.controller}/current?id=${id}`);
  }
}
