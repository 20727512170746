import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { CommissionService } from "@/services/api/CommissionService";
import store from "@/store/store";
import { ICommissionDTO } from "@shared/types";
import { ICreateCommissionDTO } from "@shared/types";


@Module({ generateMutationSetters: true })
class CommissionModule extends VuexModule {
  private commissionService = CommissionService.getInstance();

  // State
  commissions: ICommissionDTO[] = [];
  loading = false;

  @Action
  async createCommission(dto: ICreateCommissionDTO): Promise<ICommissionDTO> {
    this.loading = true;
    try {
      return await this.commissionService.createCommission(dto);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getCommissionsForDate(params: { year: number; month: number; day: number }): Promise<ICommissionDTO[]> {
    this.loading = true;
    try {
      const data = await this.commissionService.getCommissionsForDate(
        params.year,
        params.month,
        params.day
      );
      this.commissions = data;
      return data;
    } finally {
      this.loading = false;
    }
  }
}

export const commissionModule = new CommissionModule({ store, name: "commissionModule" }); 