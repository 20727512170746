<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :mini-variant="mini"
    :mini-variant-width="80"
    permanent
    class="hidden-md-and-down"
    color="#876AFF"
    dark
  >
    <v-list-item color="#6751c4" class="px-2">
      <v-list-item-avatar>
        <img v-if="displayProfilePicture" width="50" height="50" alt="profilbilde" :src="user.profilePictureUrl" />
        <img v-else width="50" height="50" alt="profilbilde" src="/img/user-onboarding.png" />
      </v-list-item-avatar>

      <v-list-item-title v-if="user != null && firebaseUserAccount != null">{{
        firebaseUserAccount.name
      }}</v-list-item-title>

      <v-spacer> </v-spacer>

      <v-btn @click="goToSettingsPage()" icon><v-icon>settings</v-icon></v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        v-for="item in itemlist"
        :key="item.text"
        :to="item.route"
        link
        color="white"
        :class="{
          hidden: item.onlySmallMenu != null && item.onlySmallMenu === true && !mini,
        }"
      >
        <v-tooltip color="#2f1d6e" :disabled="!mini" right>
          <template v-slot:activator="{ on: onToolTip }">
            <v-list-item-icon style="width: 32px; height: 32px" v-on="onToolTip">
              <img v-if="item.image" :alt="item.alt" :src="item.image" />
              <v-icon v-else large color="#573DB9">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content color="#2f1d6e">
              <v-list-item-title color="#2f1d6e">
                <span style="color: #ececf3; font-size: 0.9rem"
                  ><strong>{{ $t("sidebar." + item.text) }}</strong></span
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span
            ><strong>{{ $t("sidebar." + item.text) }}</strong></span
          >
        </v-tooltip>
      </v-list-item>

      <v-list-item v-if="canViewAdministration">
        <v-menu offset-y :close-on-content-click="true" :nudge-width="200" transition="slide-y-transition">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip color="#2f1d6e" :disabled="!mini" right>
              <template v-slot:activator="{ on: tooltip }">
                <v-list-item-icon
                  style="width: 32px; height: 32px"
                  v-on="{ ...tooltip, ...menu }"
                  class="cursor-pointer"
                >
                  <v-icon large color="#573DB9">payments</v-icon>
                </v-list-item-icon>

                <v-list-item-content color="#2f1d6e" v-on="menu" class="cursor-pointer">
                  <v-list-item-title color="#2f1d6e">
                    <span style="color: #ececf3; font-size: 0.9rem">
                      <strong>{{ $t("sidebar.administration") }}</strong>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <span
                ><strong>{{ $t("sidebar.administration") }}</strong></span
              >
            </v-tooltip>
          </template>

          <v-list dense color="#876AFF" class="py-0">
            <v-list-item
              v-for="item in inventoryTabs"
              :key="item.text"
              :to="item.route"
              link
              color="white"
              dense
              class="py-0"
            >
              <v-list-item-icon style="width: 28px; height: 28px; margin: 4px" class="ml-1">
                <v-icon color="#573DB9">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content class="py-0">
                <v-list-item-title>
                  <span style="color: #ececf3; font-size: 0.9rem">
                    <strong>{{ $t(item.translationKey) }}</strong>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list dense class="pb-4">
        <v-list-item color="white" v-if="showNotificationBell" class="py-2">
          <v-list-item-icon>
            <notification-bell></notification-bell>
          </v-list-item-icon>

          <v-list-item-content color="#573DB9">
            <v-list-item-title color="#573DB9">
              <span style="color: #ececf3; font-size: 0.9rem">
                <strong>{{ $t($ts.sidebar.notifications) }}</strong>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openChat" color="white" class="py-2">
          <v-list-item-icon>
            <v-icon large color="#573DB9">contact_support</v-icon>
          </v-list-item-icon>

          <v-list-item-content color="#573DB9">
            <v-list-item-title color="#573DB9">
              <span style="color: #ececf3; font-size: 0.9rem">
                <strong>{{ $t($ts.sidebar.contactSupport) }}</strong>
              </span>
              <v-icon small class="ml-3">exit_to_app</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="d-flex justify-end px-2 mt-4">
          <v-btn icon @click.stop="toggleMini()" class="chevron-btn">
            <v-icon large v-if="!mini">mdi-chevron-left</v-icon>
            <v-icon large v-if="mini">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationBell from "./NotificationBell.vue";
import { PermissionArea, PermissionLevel } from "@/store/userPermissions";
import { layoutModule } from "@/store/modules/layout/layoutModule";
import { userModule } from "@/store/modules/user/userModule";
import { firebaseUserModule } from "@/store/modules/firebaseUser/firebaseUserModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

// Import images
import dashboardImg from "/img/dashboard.png";
import customersImg from "/img/customers.png";
import calendarsImg from "/img/calendars.png";
import overviewImg from "/img/overview.png";
import settingsImg from "/img/settings.png";
import paymentImg from "/img/paymenticon.png";
import commerceImg from "/img/commerce.png";

interface NavigationItem {
  alt: string;
  text: string;
  image: string;
  route: string;
  icon: string;
  onlySmallMenu?: boolean;
  children?: NavigationItem[];
  translationKey?: string;
}

@Component({ name: "sidebar", components: { NotificationBell } })
export default class Sidebar extends Vue {
  public drawer: boolean = true;
  public showListGroup: boolean = false;
  public showInventoryGroup: boolean = false;

  get canViewAppointments(): boolean {
    return userPermissionsModule.hasPermission(PermissionArea.APPOINTMENTS_AND_CLIENTS, PermissionLevel.READ);
  }

  get canViewAdministration(): boolean {
    return userPermissionsModule.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ);
  }

  get mini() {
    return layoutModule.mini;
  }

  get itemlist(): NavigationItem[] {
    const items: NavigationItem[] = [
      {
        alt: "home",
        text: "home",
        image: dashboardImg,
        route: "/admin",
        icon: "home",
      },
    ];

    // Only add these items if user has permission to view appointments and clients
    if (this.canViewAppointments) {
      items.push(
        {
          alt: "customers",
          text: "customers",
          image: customersImg,
          route: "/kunder",
          icon: "account_circle",
        },
        {
          alt: "calendar",
          text: "calendar",
          image: calendarsImg,
          route: "/kalender",
          icon: "calendar_today",
        },
        {
          alt: "overview",
          text: "overview",
          image: overviewImg,
          route: "/bestillinger",
          icon: "event_note",
        }
      );
    }

    // Add orders to main sidebar if user has administration permissions
    if (this.canViewAdministration) {
      items.push({
        alt: "orders",
        text: "orders",
        image: "",
        route: "/orders",
        icon: "shopping_cart",
      });
    }

    items.push({
      alt: "settings",
      text: "settings",
      image: settingsImg,
      route: "/settings",
      icon: "calendar_today",
      onlySmallMenu: true,
    });

    return items;
  }

  get appointmentTabs() {
    if (userModule.user == null) {
      return [];
    }

    const baseItems = [
      {
        alt: "overview",
        children: [],
        text: "overview",
        image: overviewImg,
        route: "/bestillinger",
        icon: "event_note",
      },
    ];

    if (userModule.user.enablePaymentModule) {
      baseItems.push(
        {
          alt: "payments",
          text: "payments",
          image: paymentImg,
          route: "/betalinger",
          icon: "event_note",
          children: [],
        },
        {
          alt: "invoices",
          text: "invoices",
          image: commerceImg,
          route: "/faktura",
          icon: "event_note",
          children: [],
        }
      );
    }

    return baseItems;
  }

  get getSidebarWidth() {
    if (layoutModule.mini) {
      return 80;
    } else {
      return 256;
    }
  }

  get user() {
    return userModule.user;
  }

  get firebaseUserAccount() {
    return firebaseUserModule.currentFirebaseUser;
  }

  toggleMini() {
    layoutModule.setMini(!layoutModule.mini);

    if (layoutModule.mini) {
      localStorage.setItem("sidebarmini", "true");
    } else {
      localStorage.setItem("sidebarmini", "false");
    }
  }

  get displayProfilePicture() {
    return userModule.user != null && userModule.user.profilePictureUrl != null;
  }

  openChat(): void {
    window.open("http://m.me/availify/", "_blank");
  }

  public goToSettingsPage(): void {
    this.$router.push("/settings").catch(() => {});
  }

  public async created(): Promise<void> {
    let defaultMini = localStorage.getItem("sidebarmini");
    if (defaultMini != null) {
      if (defaultMini == "true") {
        layoutModule.setMini(true);
      } else {
        layoutModule.setMini(false);
      }
    }
  }

  @Watch("$route")
  onChangeRoute(newRoute, oldroute) {
    if (
      newRoute.name == "InventoryItems" ||
      newRoute.name == "Orders" ||
      newRoute.name == "Expenses" ||
      newRoute.name == "CommissionSettlement" ||
      newRoute.name == "Statistikk" ||
      newRoute.name == "Eksporter"
    ) {
      this.showInventoryGroup = true;
    } else {
      this.showInventoryGroup = false;
    }
  }

  get showNotificationBell(): boolean {
    return this.canViewAppointments;
  }

  get appointmentsLabel(): string {
    return this.$t(this.$ts.sidebar.appointments).toString();
  }

  get inventoryTabs() {
    const ts = this.$ts.sidebar;
    const canViewStatistics = userPermissionsModule.hasPermission(
      PermissionArea.STATISTICS_AND_FINANCES,
      PermissionLevel.READ
    );

    const financeItems = [];

    // Add staff management if user has administration permissions and multiple staff members
    if (this.canViewAdministration && staffMemberModule.hasMultipleStaff) {
      financeItems.push({
        alt: "staffManagement",
        text: "staffManagement",
        translationKey: ts.staffManagement,
        icon: "mdi-account-group",
        route: "/staff-management",
      });

      // Add Commission Settlement menu item
      financeItems.push({
        alt: "commissions",
        text: "commissions",
        translationKey: ts.commissions,
        icon: "mdi-cash-multiple",
        route: "/commission-settlement",
      });
      
      financeItems.push({
        alt: "cashRegister",
        text: "cashRegister",
        image: "",
        translationKey: ts.cashRegister,
        route: "/cash-register",
        icon: "mdi-cash-register",
      });

      financeItems.push({
      alt: "expenses",
      text: "expenses",
      translationKey: ts.expenses,
      icon: "payments",
      route: "/expenses",
    });

    if (canViewStatistics) {
      financeItems.push({
        alt: "statistics",
        text: "statistics",
        translationKey: ts.statistics,
        icon: "insert_chart",
        route: "/statistikk",
      });
    }

    financeItems.push({
      alt: "export",
      text: "export",
      translationKey: ts.export,
      icon: "download",
      route: "/eksporter",
    });
    }

  

    return financeItems;
  }

  get inventoryLabel(): string {
    return this.$t(this.$ts.sidebar.inventory).toString();
  }
}
</script>
<style lang="scss" scoped>
.hidden {
  display: none;
}
.chevron-btn {
  margin: 4px;
  height: 40px;
  width: 40px;
  background-color: rgba(87, 61, 185, 0.1);
  &:hover {
    background-color: rgba(87, 61, 185, 0.2);
  }
}
.cursor-pointer {
  cursor: pointer;
}
.router-link-active {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.sidebar {
  width: 70px;
  position: fixed;
  background: #876aff;
  height: 100vh;

  .logout {
    bottom: 10px;
    position: absolute;
    cursor: pointer;
  }
  .logo {
    height: 70px !important;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: block;
      margin-left: 14px;
    }
  }
  .menulist {
    float: left;
    width: 100%;
    height: 60px;
    transition: 0.3s;
    a {
      height: 60px;
      width: 100%;
      float: left;
    }
    img {
      margin-top: 15px;
    }
    &:hover {
      background: #876aff;
      transition: 0.3s;
    }
    &:hover .menulist-description {
      display: block;
    }
    .menulist-description {
      position: absolute;
      background: #876aff;
      width: 150px;
      height: 30px;
      margin-left: 80px;
      color: white;
      text-align: center;
      margin-top: 15px;
      transition: 0.3s;
      border-radius: 4px;
      display: none;
      p {
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }
  /*.marginTop{*/
  /*margin-top: 120px;*/
  /*}*/
  img {
    margin: 0 auto;
    display: block;
  }

  .menulist-description {
    position: relative;
    background: #88b7d5;
    border: 4px solid #876aff;
  }
  .menulist-description:after,
  .menulist-description:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .menulist-description:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #876aff;
    border-width: 6px;
    margin-top: -5px;
  }
  .menulist-description:before {
    border-color: rgba(123, 245, 220, 0);
    border-width: 10px;
    margin-top: -9px;
  }
}

@media only screen and (max-width: 1264px) {
  .sidebar {
    display: none;
  }
}
</style>
../../store/dialogModule @/store/userPermissionsModule @/store/dialog ../../store/modules/dialogModule/dialogModule
../../store/modules/user
