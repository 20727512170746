<template>
  <v-container fluid class="pa-0 d-flex" style="height: 100vh; overflow: hidden">
    <!-- Sidebar List -->
    <v-col cols="12" md="3" v-if="settings" style="overflow-y: auto; margin: 0px !important; padding: 0px !important">
      <v-list dense>
        <transition-group appear name="slide-fade" tag="div" class="settings-list">
          <!-- Me Settings -->
          <settings-group v-if="settings.me?.length" :title="$t($ts.settings.me)" key="me">
            <settings-list-item
              v-for="item in settings.me"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Administration Settings -->
          <settings-group
            v-if="settings.administration?.length"
            :title="$t($ts.settings.administration)"
            key="administration"
          >
            <settings-list-item
              v-for="item in settings.administration"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Services Settings -->
          <settings-group v-if="settings.services?.length" :title="$t($ts.settings.services)" key="services">
            <settings-list-item
              v-for="item in settings.services"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Products Settings -->
          <settings-group v-if="settings.products?.length" :title="$t($ts.settings.products)" key="products">
            <settings-list-item
              v-for="item in settings.products"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Staff Settings -->
          <staff-settings
            v-if="settings.staff?.length"
            :staff-members="staffMembers"
            key="staff"
            @show-settings-dialog="handleStaffSettingsDialog"
          />

          <!-- Booking Settings -->
          <settings-group v-if="settings.booking?.length" :title="$t($ts.settings.booking)" key="booking">
            <settings-list-item
              v-for="item in settings.booking"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Calendar Settings -->
          <settings-group v-if="settings.calendar?.length" :title="$t($ts.settings.calendar)" key="calendar">
            <settings-list-item
              v-for="item in settings.calendar"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>

          <!-- Functions Settings -->
          <settings-group v-if="settings.functions?.length" :title="$t($ts.settings.functions)" key="functions">
            <settings-list-item
              v-for="item in settings.functions"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :selected="currentRoute === item.route"
              @click="showSettingsDialog(item.route, item.disabled)"
            />
          </settings-group>
        </transition-group>
      </v-list>
    </v-col>

    <v-dialog
      v-if="isMobile"
      v-model="dialog"
      fullscreen
      transition="dialog-slide-right"
      style="border-radius: 0px"
      :retain-focus="false"
    >
      <v-card>
        <v-toolbar style="border-radius: 0px" flat dark color="#876aff">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ currentSettingTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(action, index) in currentToolbarActions"
            :key="index"
            icon
            dark
            @click="handleAction(action.handler)"
            :title="action.tooltip"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-0">
          <keep-alive>
            <component
              :is="currentComponent"
              v-if="currentComponent"
              v-bind="componentProps"
              ref="currentComponentRef"
            />
          </keep-alive>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Main Component Container -->
    <v-col v-if="!isMobile" cols="12" md="9" class="pa-4 pa-md-2" style="overflow-y: auto">
      <v-card flat>
        <v-toolbar flat dark color="#7441ff">
          <v-toolbar-title>{{ currentSettingTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(action, index) in currentToolbarActions"
            :key="index"
            icon
            dark
            @click="handleAction(action.handler)"
            :title="action.tooltip"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <keep-alive>
            <component
              :is="currentComponent"
              v-if="currentComponent"
              v-bind="componentProps"
              ref="currentComponentRef"
            />
          </keep-alive>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SettingsGroup from "@/components/Settings/SettingsGroup.vue";
import SettingsListItem from "@/components/Settings/SettingsListItem.vue";
import StaffSettings from "@/components/Settings/StaffSettings.vue";
import { createSettingsData } from "@/data/settingsData";
import { IStaffMemberDTO, IUserDTO } from "@shared/types";
import { Getter, Action } from "vuex-class";
import { PermissionArea, PermissionLevel } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { TranslateResult } from "vue-i18n";

interface ISettingsItem {
  id: number;
  label: TranslateResult;
  icon: string;
  route: string;
  disabled: boolean;
}

interface ISettings {
  me?: ISettingsItem[];
  administration?: ISettingsItem[];
  products?: ISettingsItem[];
  services?: ISettingsItem[];
  staff?: ISettingsItem[];
  booking?: ISettingsItem[];
  calendar?: ISettingsItem[];
  functions?: ISettingsItem[];
}

interface IToolbarAction {
  handler: string;
  tooltip: string;
  icon: string;
}

interface IRouteComponentInfo {
  component: string;
  title: string;
}

interface IRouteComponentMap {
  [key: string]: IRouteComponentInfo;
}

// Import all settings components
import CompanyInfoCard from "@/components/global/CompanyInfoCard.vue";
import SystemSettings from "@/components/Settings/SystemSettings.vue";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "@/components/global/ServiceCategories.vue";
import Resources from "@/components/Settings/Services/Resources.vue";
import SubscriptionInformation from "@/components/Settings/SubscriptionInformation.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import PersonalAccountSettings from "@/components/Settings/PersonalAccountSettings.vue";
import BookingNotificationSettings from "@/components/Settings/Booking/BookingNotificationSettings.vue";
import BookingPageFieldSettings from "@/components/Settings/Booking/Booking-page/BookingPageFieldSettings.vue";
import GeneralBookingSettings from "@/components/Settings/Booking/GeneralBookingSettings.vue";
import BookingPageGeneralSettings from "@/components/Settings/Booking/Booking-page/BookingPageGeneralSettings.vue";
import ServiceOptions from "@/components/Settings/Services/ServiceOptions.vue";
import StaffMembersSettings from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import CalendarStyleSettings from "@/components/Settings/Calendar/CalendarStyleSettings.vue";
import EmailListSettings from "@/components/Settings/Functions/EmailListSettings.vue";
import ExtraServices from "@/components/Settings/Services/ExtraServices.vue";
import BookingPageInformationSettings from "@/components/Settings/Booking/Booking-page/BookingPageInformationSettings.vue";
import BookingPageCustomerSettings from "@/components/Settings/Booking/Booking-page/BookingPageCustomerSettings.vue";
import InvoiceSettings from "@/components/InvoiceSettings.vue";
import AccountRoleSettings from "@/components/Settings/AccountRoleSettings.vue";

// Import staff-related components
import StaffMemberDetailsSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberDetailsSettings.vue";
import StaffMemberServicesSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberServicesSettings.vue";
import StaffMemberAlertSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberAlertSettings.vue";
import WorkingHours from "@/components/Settings/StaffMembers/StaffMember/WorkingHours.vue";
import VacationDays from "@/components/Settings/StaffMembers/StaffMember/VacationDays.vue";
import StaffMemberGoogleCalendarSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberGoogleCalendarSettings.vue";
import StaffMembersOverview from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import AuditOverview from "@/pages/admin/AuditOverview.vue";
import OnlineTransactionsSettings from "@/components/Settings/Functions/OnlineTransactionsSettings.vue";
import InventoryItems from "@/pages/admin/InventoryItems.vue";
import InventoryCategories from "@/pages/admin/InventoryCategories.vue";

import { toolbarConfig } from "./toolbarconfig";

import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { userModule } from "@/store/modules/user/userModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";

@Component({
  components: {
    SettingsGroup,
    SettingsListItem,
    StaffSettings,
    CompanyInfoCard,
    SystemSettings,
    TreatmentTypes,
    ServiceCategories,
    Resources,
    SubscriptionInformation,
    AccountSettings,
    AccountRoleSettings,
    PersonalAccountSettings,
    BookingNotificationSettings,
    BookingPageFieldSettings,
    GeneralBookingSettings,
    BookingPageGeneralSettings,
    ServiceOptions,
    StaffMembersSettings,
    CalendarStyleSettings,
    EmailListSettings,
    ExtraServices,
    BookingPageInformationSettings,
    BookingPageCustomerSettings,
    StaffMemberDetailsSettings,
    StaffMemberServicesSettings,
    WorkingHours,
    VacationDays,
    StaffMemberGoogleCalendarSettings,
    StaffMembersOverview,
    AuditOverview,
    InvoiceSettings,
    OnlineTransactionsSettings,
    StaffMemberAlertSettings,
    InventoryItems,
    InventoryCategories,
  },
})
export default class SettingsMobile extends Vue {
  dialog = false;
  currentComponent: string | null = null;
  currentSettingTitle = "";
  componentProps = {};
  currentRoute = "";
  private settingsData: ISettings | null = null;

  // Map routes to component names and titles
  routeComponentMap: IRouteComponentMap = {};

  get currentToolbarActions(): IToolbarAction[] {
    return (toolbarConfig[this.currentComponent as keyof typeof toolbarConfig]?.actions || []) as IToolbarAction[];
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  handleAction(handlerName: string) {
    const component = this.$refs.currentComponentRef as any;
    if (component && typeof component[handlerName] === "function") {
      component[handlerName]();
    } else {
      console.warn(`Handler ${handlerName} is not defined on ${this.currentComponent}`);
    }
  }

  showSettingsDialog(route: string, disabled: boolean): void {
    if (!disabled && route) {
      const componentInfo = this.routeComponentMap[route];
      if (componentInfo) {
        this.currentComponent = componentInfo.component;
        this.currentSettingTitle = componentInfo.title;
        this.currentRoute = route;
        this.dialog = true;
      }
    }
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  get settings(): ISettings {
    const meSection: ISettings = {
      me: [
        {
          id: 1001,
          label: this.$t(this.$ts.settings.myAccount),
          icon: "mdi-account-circle",
          route: "/settings/my-account",
          disabled: false,
        },
      ],
    };

    // If user doesn't have settings read permission, only show me section
    if (!userPermissionsModule.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)) {
      return meSection;
    }

    // Otherwise, build full settings object with permission checks
    const fullSettings: ISettings = {
      ...meSection,
    };

    // Only show administration section if user has administration read permission
    if (userPermissionsModule.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ)) {
      fullSettings.administration = [
        {
          id: 0,
          label: this.$t(this.$ts.settings.companyInfo),
          icon: "mdi-account-details",
          route: "/settings/company-info",
          disabled: false,
        },
        {
          id: 5,
          label: this.$t(this.$ts.settings.subscription),
          icon: "mdi-credit-card-outline",
          route: "/settings/subscription",
          disabled: false,
        },
        {
          id: 6,
          label: this.$t(this.$ts.settings.accounts),
          icon: "mdi-account-outline",
          route: "/settings/accounts",
          disabled: false,
        },
        {
          id: 7,
          label: this.$t(this.$ts.settings.accountRoles),
          icon: "mdi-account-key",
          route: "/settings/account-roles",
          disabled: false,
        },
        {
          id: 204311,
          label: this.$t(this.$ts.settings.system),
          icon: "mdi-web",
          route: "/settings/system",
          disabled: false,
        },
        {
          id: 948328402,
          label: this.$t(this.$ts.settings.auditLog),
          icon: "mdi-format-list-bulleted-square",
          route: "/settings/audit-log",
          disabled: false,
        },
      ];
    }

    // Staff settings
    if (userPermissionsModule.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)) {
      fullSettings.staff = [
        {
          id: 2,
          label: this.$t(this.$ts.settings.staff),
          icon: "mdi-account-group",
          route: "/settings/staff",
          disabled: false,
        },
      ];
    }

    // Products settings
    if (userPermissionsModule.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)) {
      fullSettings.products = [
        {
          id: 50001,
          label: this.$t(this.$ts.settings.products),
          icon: "mdi-package-variant-closed",
          route: "/settings/products",
          disabled: false,
        },
        {
          id: 50002,
          label: this.$t(this.$ts.settings.productCategories),
          icon: "mdi-shape",
          route: "/settings/product-categories",
          disabled: false,
        },
      ];
    }

    const servicesItems = [
      {
        id: 3,
        label: this.$t(this.$ts.settings.services),
        icon: "mdi-star-circle",
        route: "/settings/services",
        disabled: false,
      },
      {
        id: 20,
        label: this.$t(this.$ts.settings.extraServices),
        icon: "mdi-star-plus",
        route: "/settings/extra-services",
        disabled: true,
      },
      {
        id: 4,
        label: this.$t(this.$ts.settings.categories),
        icon: "mdi-shape",
        route: "/settings/categories",
        disabled: false,
      },
      {
        id: 43243,
        label: this.$t(this.$ts.settings.resources),
        icon: "mdi-cube-outline",
        route: "/settings/resources",
        disabled: false,
      },
      {
        id: 12,
        label: this.$t(this.$ts.settings.options),
        icon: "mdi-cog",
        route: "/settings/options",
        disabled: false,
      },
    ];

    const enabledServicesItems = servicesItems.filter((item) => !item.disabled);
    if (enabledServicesItems.length > 0) {
      fullSettings.services = enabledServicesItems;
    }

    const bookingItems = [
      {
        id: 10,
        label: this.$t(this.$ts.settings.booking),
        icon: "mdi-calendar",
        route: "/settings/booking",
        disabled: false,
      },
      {
        id: 40,
        label: this.$t(this.$ts.settings.information),
        icon: "mdi-information",
        route: "/settings/booking/information",
        disabled: false,
      },
      {
        id: 8,
        label: this.$t(this.$ts.settings.alerts),
        icon: "mdi-bell-ring",
        route: "/settings/booking/alerts",
        disabled: false,
      },
      {
        id: 11,
        label: this.$t(this.$ts.settings.bookingSide),
        icon: "mdi-link",
        route: "/settings/booking/side",
        disabled: false,
      },
      {
        id: 9,
        label: this.$t(this.$ts.settings.customerInformation),
        icon: "mdi-pen",
        route: "/settings/booking/customer-information",
        disabled: false,
      },
      {
        id: 41,
        label: this.$t(this.$ts.settings.customerAccount),
        icon: "mdi-account",
        route: "/settings/booking/customer-account",
        disabled: false,
      },
    ];

    const enabledBookingItems = bookingItems.filter((item) => !item.disabled);
    if (enabledBookingItems.length > 0) {
      fullSettings.booking = enabledBookingItems;
    }

    const calendarItems = [
      {
        id: 30,
        label: this.$t(this.$ts.settings.calendar),
        icon: "mdi-calendar-range",
        route: "/settings/calendar",
        disabled: false,
      },
    ];

    const enabledCalendarItems = calendarItems.filter((item) => !item.disabled);
    if (enabledCalendarItems.length > 0) {
      fullSettings.calendar = enabledCalendarItems;
    }

    const functionsItems = [
      {
        id: 2042,
        label: this.$t(this.$ts.settings.newsletter),
        icon: "mdi-email-mark-as-unread",
        route: "/settings/functions/newsletter",
        disabled: false,
      },
      {
        id: 20,
        label: this.$t(this.$ts.settings.customerAccounts),
        icon: "mdi-lock",
        route: "/settings/functions/customer-accounts",
        disabled: true,
      },
      {
        id: 21,
        label: this.$t(this.$ts.settings.invoicesAndPayments),
        icon: userModule.user.enablePaymentModule ? "mdi-cash" : "mdi-lock",
        route: "/settings/functions/invoices-and-payments",
        disabled: !userModule.user.enablePaymentModule,
      },
      {
        id: 23422,
        label: this.$t(this.$ts.settings.customFields),
        icon: "mdi-lock",
        route: "/settings/functions/custom-fields",
        disabled: true,
      },
      {
        id: 23142,
        label: this.$t(this.$ts.settings.giftCards),
        icon: "mdi-lock",
        route: "/settings/functions/gift-cards",
        disabled: true,
      },
      {
        id: 2323121,
        label: this.$t(this.$ts.settings.customerFollowUp),
        icon: "mdi-lock",
        route: "/settings/functions/customer-follow-up",
        disabled: true,
      },
      {
        id: 231323,
        label: this.$t(this.$ts.settings.cashRegisterSystem),
        icon: "mdi-lock",
        route: "/settings/functions/cash-register-system",
        disabled: true,
      },
      {
        id: 23,
        label: this.$t(this.$ts.settings.multipleLocations),
        icon: "mdi-lock",
        route: "/settings/functions/multiple-locations",
        disabled: true,
      },
      {
        id: 204311,
        label: this.$t(this.$ts.settings.onlineTransactions),
        icon: "mdi-credit-card-outline",
        route: "/settings/functions/online-transactions",
        disabled: false,
      },
    ];

    const enabledFunctionsItems = functionsItems.filter((item) => !item.disabled);
    if (enabledFunctionsItems.length > 0) {
      fullSettings.functions = enabledFunctionsItems;
    }

    return fullSettings;
  }

  handleStaffSettingsDialog(dialogInfo: { component: string; title: string; props: any }) {
    // Force component re-render by temporarily setting it to null
    this.currentComponent = null;
    this.$nextTick(() => {
      this.currentComponent = dialogInfo.component;
      this.currentSettingTitle = dialogInfo.title;
      this.componentProps = dialogInfo.props || {};
      // Set the current route based on the staff member if available
      if (dialogInfo.props?.staffMember) {
        const staffId = dialogInfo.props.staffMember.id;
        // Extract the route from the component name
        switch (dialogInfo.component) {
          case "StaffMemberDetailsSettings":
            this.currentRoute = `/settings/staff/${staffId}/information`;
            break;
          case "StaffMemberServicesSettings":
            this.currentRoute = `/settings/staff/${staffId}/services`;
            break;
          case "WorkingHours":
            this.currentRoute = `/settings/staff/${staffId}/working-hours`;
            break;
          case "VacationDays":
            this.currentRoute = `/settings/staff/${staffId}/friday`;
            break;
          case "StaffMemberGoogleCalendarSettings":
            this.currentRoute = `/settings/staff/${staffId}/google-calendar`;
            break;
          default:
            this.currentRoute = "/settings/staff";
        }
      } else {
        this.currentRoute = "/settings/staff";
      }
      this.dialog = true;
    });
  }

  async created() {
    staffMemberModule.getStaffMembers();
    //this.settings = createSettingsData();
    this.routeComponentMap = {
      "/settings/my-account": {
        component: "PersonalAccountSettings",
        title: this.$t(this.$ts.settings.myAccount).toString(),
      },
      "/settings/company-info": {
        component: "CompanyInfoCard",
        title: this.$t(this.$ts.settings.companyInfo).toString(),
      },
      "/settings/system": {
        component: "SystemSettings",
        title: this.$t(this.$ts.settings.system).toString(),
      },
      "/settings/account-roles": {
        component: "AccountRoleSettings",
        title: this.$t(this.$ts.settings.accountRoles).toString(),
      },
      "/settings/services": {
        component: "TreatmentTypes",
        title: this.$t(this.$ts.settings.services).toString(),
      },
      "/settings/categories": {
        component: "ServiceCategories",
        title: this.$t(this.$ts.settings.categories).toString(),
      },
      "/settings/audit-log": {
        component: "AuditOverview",
        title: this.$t(this.$ts.settings.auditLog).toString(),
      },
      "/settings/resources": {
        component: "Resources",
        title: this.$t(this.$ts.settings.resources).toString(),
      },
      "/settings/subscription": {
        component: "SubscriptionInformation",
        title: this.$t(this.$ts.settings.subscription).toString(),
      },
      "/settings/accounts": {
        component: "AccountSettings",
        title: this.$t(this.$ts.settings.accounts).toString(),
      },
      "/settings/booking/alerts": {
        component: "BookingNotificationSettings",
        title: this.$t(this.$ts.settings.alerts).toString(),
      },
      "/settings/booking/customer-information": {
        component: "BookingPageFieldSettings",
        title: this.$t(this.$ts.settings.customerInformation).toString(),
      },
      "/settings/booking": {
        component: "GeneralBookingSettings",
        title: this.$t(this.$ts.settings.booking).toString(),
      },
      "/settings/booking/side": {
        component: "BookingPageGeneralSettings",
        title: this.$t(this.$ts.settings.bookingSide).toString(),
      },
      "/settings/options": {
        component: "ServiceOptions",
        title: this.$t(this.$ts.settings.options).toString(),
      },
      "/settings/staff": {
        component: "StaffMembersSettings",
        title: this.$t(this.$ts.settings.staff).toString(),
      },
      "/settings/calendar": {
        component: "CalendarStyleSettings",
        title: this.$t(this.$ts.settings.calendar).toString(),
      },
      "/settings/functions/newsletter": {
        component: "EmailListSettings",
        title: this.$t(this.$ts.settings.newsletter).toString(),
      },
      "/settings/extra-services": {
        component: "ExtraServices",
        title: this.$t(this.$ts.settings.extraServices).toString(),
      },
      "/settings/booking/information": {
        component: "BookingPageInformationSettings",
        title: this.$t(this.$ts.settings.information).toString(),
      },
      "/settings/booking/customer-account": {
        component: "BookingPageCustomerSettings",
        title: this.$t(this.$ts.settings.customerAccount).toString(),
      },
      "/settings/functions/invoices-and-payments": {
        component: "InvoiceSettings",
        title: this.$t(this.$ts.settings.invoicesAndPayments).toString(),
      },
      "/settings/functions/online-transactions": {
        component: "OnlineTransactionsSettings",
        title: this.$t(this.$ts.settings.onlineTransactions).toString(),
      },
      "/settings/products": {
        component: "InventoryItems",
        title: this.$t(this.$ts.settings.products).toString(),
      },
      "/settings/product-categories": {
        component: "InventoryCategories",
        title: this.$t(this.$ts.settings.productCategories).toString(),
      },
    };

    if (!this.isMobile) {
      let defaultRoute = userPermissionsModule.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)
        ? "/settings/company-info"
        : "/settings/my-account";

      let current = this.routeComponentMap[defaultRoute];
      this.currentComponent = current.component;
      this.currentSettingTitle = current.title;
      this.currentRoute = defaultRoute;
    }
  }
}
</script>

<style>
/* Global styles for page transition */
.dialog-slide-right-enter-active,
.dialog-slide-right-leave-active {
  transition: transform 0.3s ease-in-out;
}

.dialog-slide-right-enter,
.dialog-slide-right-leave-to {
  transform: translateX(100%);
}

.v-dialog.v-dialog--fullscreen {
  transition: transform 0.3s ease-in-out;
}

.settings-list {
  position: relative;
  will-change: transform, opacity;
}

.slide-fade-enter-active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left center;
  will-change: transform, opacity;
}

.slide-fade-move {
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-enter {
  transform: translateX(-30px);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

/* Add a small delay for each subsequent item */
.slide-fade-enter-active:nth-child(1) {
  transition-delay: 0.05s;
}
.slide-fade-enter-active:nth-child(2) {
  transition-delay: 0.1s;
}
.slide-fade-enter-active:nth-child(3) {
  transition-delay: 0.15s;
}
.slide-fade-enter-active:nth-child(4) {
  transition-delay: 0.2s;
}
.slide-fade-enter-active:nth-child(5) {
  transition-delay: 0.25s;
}
.slide-fade-enter-active:nth-child(6) {
  transition-delay: 0.3s;
}

.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
