<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import CompanyInfoField from "../global/CompanyInfoField.vue";
import { IUserDTO } from "@shared/types";
import TextField from "../global/Textfield.vue";

import { TranslationSchema } from "@/locales/translation.interface";
import { userModule } from "@/store/modules/user/userModule";
@Component({
  name: "SystemSettings",
  components: {
    CompanyInfoField,
    TextField,
  },
})
export default class SystemSettings extends Vue {
  languages = [
    { key: "en", name: "English", flagEmojiCode: "&#127464;&#127466;" },
    { key: "no", name: "Norsk", flagEmojiCode: "&#127475;&#127476;" },
    { key: "es", name: "Español", flagEmojiCode: "&#127462;&#127463;" },
  ];

  timeFormats = ["12h", "24h"];

  editUser: Partial<IUserDTO> = {
    language: "",
    timeFormat: "",
    timeZone: "",
    currency: "",
  };

  timeZones = [
    { key: "America/Bogota", name: "Bogota (UTC-5)" },
    { key: "America/New_York", name: "New York (UTC-5/UTC-4)" },
    { key: "America/Chicago", name: "Chicago (UTC-6/UTC-5)" },
    { key: "America/Los_Angeles", name: "Los Angeles (UTC-8/UTC-7)" },
    { key: "Europe/London", name: "London (UTC/UTC+1)" },
    { key: "Europe/Paris", name: "Paris (UTC+1/UTC+2)" },
    { key: "Asia/Dubai", name: "Dubai (UTC+4)" },
    { key: "Asia/Tokyo", name: "Tokyo (UTC+9)" },
  ];

  currencies = [
    { key: "USD", name: "US Dollar ($)" },
    { key: "EUR", name: "Euro (€)" },
    { key: "GBP", name: "British Pound (£)" },
    { key: "INR", name: "Indian Rupee (₹)" },
    { key: "COP", name: "Colombian Peso ($)" },
    { key: "NOK", name: "Norwegian Krone (kr)" },
  ];

  mounted() {
    this.editUser.language = userModule.user.language;
    this.editUser.timeFormat = userModule.user.timeFormat;
    this.editUser.timeZone = userModule.user.timeZone;
    this.editUser.currency = userModule.user.currency;
    this.editUser.defaultPhoneNumberCountryCode = userModule.user.defaultPhoneNumberCountryCode;
  }

  async onLanguageChange(value: string): Promise<void> {
    try {
      await userModule.updateUser({ language: value });

      if (value != null && value != "" && value != localStorage.getItem("locale")) {
        this.$i18n.locale = value;
        localStorage.setItem("locale", value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onTimeFormatChange(value: string): Promise<void> {
    await userModule.updateUser({ timeFormat: value });
  }

  async onTimeZoneChange(value: string): Promise<void> {
    await userModule.updateUser({ timeZone: value });
  }

  async onCurrencyChange(value: string): Promise<void> {
    await userModule.updateUser({ currency: value });
  }

  async saveField(field: string, value: string | number): Promise<void> {
    try {
      await userModule.updateUser({ [field]: value });
    } catch (error) {
      console.error(error.message);
    }
  }
}
</script>

<template>
  <v-container>
    <CompanyInfoField
      :label="$t($ts.systemsSettings.language)"
      :description="$t($ts.systemsSettings.languageDescription)"
    >
      <v-select
        v-model="editUser.language"
        :items="languages"
        item-text="name"
        item-value="key"
        persistent-hint
        max-width="300"
        @change="onLanguageChange($event)"
      ></v-select>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.systemsSettings.timeFormat)"
      :description="$t($ts.systemsSettings.timeFormatDescription)"
    >
      <v-select
        v-model="editUser.timeFormat"
        :items="timeFormats"
        persistent-hint
        max-width="300"
        @change="onTimeFormatChange($event)"
      ></v-select>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.systemsSettings.timeZone)"
      :description="$t($ts.systemsSettings.timeZoneDescription)"
    >
      <v-select
        v-model="editUser.timeZone"
        :items="timeZones"
        item-text="name"
        item-value="key"
        persistent-hint
        max-width="300"
        @change="onTimeZoneChange($event)"
      ></v-select>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.systemsSettings.currency)"
      :description="$t($ts.systemsSettings.currencyDescription)"
    >
      <v-select
        v-model="editUser.currency"
        :items="currencies"
        item-text="name"
        item-value="key"
        persistent-hint
        max-width="300"
        @change="onCurrencyChange($event)"
      ></v-select>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.systemsSettings.defaultCountryCode)"
      :description="$t($ts.systemsSettings.defaultCountryCodeDescription)"
    >
      <TextField
        @blur="saveField('defaultPhoneNumberCountryCode', editUser.defaultPhoneNumberCountryCode)"
        v-model="editUser.defaultPhoneNumberCountryCode"
      />
    </CompanyInfoField>
  </v-container>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
../../store/dialogModule @/store/dialog ../../store/modules/dialogModule/dialogModule
