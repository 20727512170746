import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { LedgerEntriesService } from "@/services/api/LedgerEntriesService";
import {
  ILedgerEntryDTO,
  ILedgerEntryDetailsDTO,
  ICreateAppointmentLedgerEntryDTO,
  IDetailedBalanceDTO,
  ILedgerEntriesController,
  ICreateOrderLedgerEntryDTO,
} from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class LedgerEntriesModule extends VuexModule {
  private ledgerService = LedgerEntriesService.getInstance();

  // State
  entries: ILedgerEntryDTO[] = [];
  loading = false;

  @Action
  async getCustomerEntries(customerId: number): Promise<ILedgerEntryDTO[]> {
    this.loading = true;
    try {
      const data = await this.ledgerService.getCustomerEntries(customerId);
      this.entries = data;
      return data;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getMoneyAccountEntries(moneyAccountId: number): Promise<ILedgerEntryDTO[]> {
    this.loading = true;
    try {
      const data = await this.ledgerService.getMoneyAccountEntries(moneyAccountId);
      this.entries = data;
      return data;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getAllMoneyAccountEntriesForDate(params: { year: number; month: number; day: number }): Promise<ILedgerEntryDTO[]> {
    this.loading = true;
    try {
      return await this.ledgerService.getAllMoneyAccountEntriesForDate(
        params.year,
        params.month,
        params.day
      );
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getCustomerBalance(customerId: number): Promise<number> {
    this.loading = true;
    try {
      return await this.ledgerService.getCustomerBalance(customerId);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getEntryWithAppointmentDetails(id: number): Promise<ILedgerEntryDetailsDTO> {
    this.loading = true;
    try {
      return await this.ledgerService.getEntryWithAppointmentDetails(id);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createFromAppointment(dto: ICreateAppointmentLedgerEntryDTO): Promise<ILedgerEntryDTO> {
    this.loading = true;
    try {
      return await this.ledgerService.createFromAppointment(dto);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async handleArchivedAppointment(appointmentId: number): Promise<void> {
    this.loading = true;
    try {
      await this.ledgerService.handleArchivedAppointment(appointmentId);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createFromOrder(dto: ICreateOrderLedgerEntryDTO): Promise<ILedgerEntryDTO> {
    this.loading = true;
    try {
      return await this.ledgerService.createFromOrder(dto);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getDetailedBalance(
    query: typeof ILedgerEntriesController.IGetDetailedBalanceQuery
  ): Promise<IDetailedBalanceDTO> {
    this.loading = true;
    try {
      return await this.ledgerService.getDetailedBalance(query);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createManualLedgerEntry(entry: ILedgerEntryDTO): Promise<ILedgerEntryDTO> {
    this.loading = true;
    try {
      return await this.ledgerService.createManual(entry);
    } finally {
      this.loading = false;
    }
  }
}

export const ledgerEntriesModule = new LedgerEntriesModule({ store, name: "ledgerEntriesModule" });
