<script lang="ts">
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import FrontPageHeader from "@/components/global/FrontPageHeader.vue";
import router from "@/router";
import App from "@/App.vue";

import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { IDialog, DialogType, DialogMutations, dialogModule } from "@/store/modules/dialogModule/dialogModule";

import firebase from "firebase/app";

const firebaseAuth = firebase.auth();
import { IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import SnackbarMixin from "@/mixins/snackbarMixin";

@Component({
  name: "Login",
  components: { CustomButton, FrontPageHeader, App, CustomInput },
})
export default class Login extends Mixins(SnackbarMixin) {
  async registerUser(payload: any) {
    return await userModule.registerUser(payload);
  }

  addToDialogQueue(dialog) {
    dialogModule.addToDialogQueue(dialog);
  }

  public isShowingForgotPasswordField: boolean = false;
  public error: string = "";
  public password: string = "";
  public password2: string = "";
  public email: string = "";
  public isLoading: boolean = false;
  public isShowingRegisterUserForm: boolean = false;

  //FIREBASE REGISTER USER
  public showRegisterForm(): void {
    this.isShowingRegisterUserForm = !this.isShowingRegisterUserForm;
  }

  public async register(): Promise<void> {
    if (this.email === "" || this.password == "") {
      this.postSnackbarMessage(this.$t(this.$ts.login.fillEmailPassword).toString());
      return;
    }

    if (this.password !== this.password2) {
      this.postSnackbarMessage(this.$t(this.$ts.login.passwordsMustMatch).toString());
      return;
    }

    try {
      userModule.isNewUser = true;
      await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
    } catch (error) {
      let errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        this.postSnackbarMessage(this.$t(this.$ts.login.emailInUse).toString());
      }
      if (errorCode === "auth/invalid-email") {
        this.postSnackbarMessage(this.$t(this.$ts.login.invalidEmail).toString());
      }
      if (errorCode === "auth/weak-password") {
        this.postSnackbarMessage(this.$t(this.$ts.login.weakPassword).toString());
      }
    } finally {
    }
  }

  //FIREBASE LOGIN USER
  public login(): void {
    this.isLoading = true;
    userModule.isNewUser = false;

    firebaseAuth
      .signInWithEmailAndPassword(this.email, this.password)
      .then((res) => {
        this.isLoading = false;

        if (res.additionalUserInfo.isNewUser) {
          router.push("/admin");
        } else {
          router.push("/admin");
        }
      })
      .catch((error) => {
        this.isLoading = false;
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/user-not-found") {
          this.postSnackbarMessage(this.$t(this.$ts.login.userNotFound).toString());
          this.isLoading = false;
        }
        if (errorCode === "auth/wrong-password") {
          this.postSnackbarMessage(this.$t(this.$ts.login.wrongEmailPassword).toString());
          this.isLoading = false;
        }
        if (errorCode === "auth/invalid-email") {
          this.postSnackbarMessage(this.$t(this.$ts.login.invalidEmail).toString());
          this.isLoading = false;
        }
      });
  }

  public async googleLogin(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    try {
      const result = await firebaseAuth.signInWithPopup(provider);
      let user: any = result.additionalUserInfo;

      if (result.additionalUserInfo.isNewUser) {
        let newUser: Partial<IUserDTO> = {
          firstName: user.profile.name,
          lastName: user.profile.family_name,
          phoneNumber: "",
          emailAddress: user.profile.email,
          profilePictureUrl: user.profile.picture,
          appointmentDistributionInMinutes: 60,
          // @ts-ignore
          language: this.$i18n.locale,
          // @ts-ignore
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        console.log(window.location.toString());
        if (window.location.toString().includes("citaro") || window.location.toString().includes("localhost")) {
          //@ts-ignore
          newUser.defaultPhoneNumberCountryCode = 57; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
          newUser.timeZone = "America/Bogota"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
          newUser.timeFormat = "12h"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
        }
        console.log(newUser);
        await this.registerUser(newUser);
        router.push("/admin");
      }
    } catch (error) {
      // Handle Errors here.
      let errorCode = error.code;
      let errorMessage = error.message;
      // The email of the user's account used.
      let email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      let credential = error.credential;
      // ...
    }
  }

  public goToLandingPage(): void {
    router.replace("/");
  }

  public forgot(): void {
    if (this.email == "") {
      let dialog: IDialog = {
        text: this.$t(this.$ts.login.fillEmailForReset).toString(),
      };
      this.addToDialogQueue(dialog);
    } else {
      let dialog: IDialog = {
        type: DialogType.Choice,
        text: this.$t(this.$ts.login.sendNewPasswordTo, {
          email: this.email,
        }).toString(),
        action: this.forgotYes,
      };
      this.addToDialogQueue(dialog);
    }
  }

  goToLogin(): void {
    this.isShowingRegisterUserForm = false;
  }

  forgotYes() {
    let vm = this;
    firebaseAuth
      .sendPasswordResetEmail(this.email)
      .then(function () {
        vm.addToDialogQueue({ text: vm.$t(vm.$ts.login.emailSent).toString() });
      })
      .catch(function (error) {
        vm.addToDialogQueue({ text: error.message });
      });
  }

  public created() {
    let route: string = this.$route.params.register;
    if (route === "login") {
      this.isShowingRegisterUserForm = true;
    }
  }
}
</script>
<template>
  <div style="background: white; height: 100%">
    <front-page-header>
      <!--            <button @click="goToLandingPage"><img style="right:119px !important;" class="arrow" src="../../../public/img/left-arrow.png" alt="login icon"> Tilbake </button>-->
    </front-page-header>
    <div style="height: 100%">
      <div v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-row v-if="isShowingRegisterUserForm" justify="center">
        <v-col lg="4" md="6" xs="12">
          <v-card color="#FAFAFA">
            <v-row>
              <v-col xs="3" sm="6">
                <img style="width: 200px; position: relative; float: left" src="/img/Hello-pana.svg" />
              </v-col>
              <v-col>
                <v-card-title style="word-break: break-word">{{ $t($ts.login.tryFree) }}</v-card-title>
              </v-col>
            </v-row>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    solo
                    outlined
                    v-model="email"
                    :label="$t($ts.email)"
                    :placeholder="$t($ts.email)"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    solo
                    outlined
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :label="$t($ts.login.password)"
                    :placeholder="$t($ts.login.password)"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    solo
                    outlined
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    v-model="password2"
                    :label="$t($ts.login.repeatPassword)"
                    :placeholder="$t($ts.login.repeatPassword)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-btn block color="success" @click="register">{{ $t($ts.login.register) }}</v-btn>
              </v-row>
            </v-card-text>
            <v-card-actions> </v-card-actions>

            <v-card-text>
              <span>{{ $t($ts.login.haveAccount) }} </span>
              <a class="not-a-member-text" style="text-decoration: underline; font-weight: bold" @click="goToLogin">{{
                $t($ts.login.loginHere)
              }}</a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Login form -->
      <div v-else>
        <v-row justify="center">
          <v-col lg="4" md="6" xs="12">
            <v-card color="#FAFAFA">
              <v-row>
                <!--                      <v-col xs="3" sm="6">-->
                <!--                        <img style="width: 200px; position: relative; float:left" src="../../../public/img/Hello-pana.svg"/>-->
                <!--                      </v-col>-->
                <v-col justify="center" align-content="center">
                  <v-card-title style="word-break: break-word; text-align: center; display: block; font-weight: bold">{{
                    $t($ts.login.login)
                  }}</v-card-title>
                </v-col>
              </v-row>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      prepend-inner-icon="mdi-email"
                      solo
                      outlined
                      v-model="email"
                      :label="$t($ts.email)"
                      :placeholder="$t($ts.email)"
                      type="email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      solo
                      outlined
                      prepend-inner-icon="mdi-lock"
                      type="password"
                      v-model="password"
                      :label="$t($ts.login.password)"
                      :placeholder="$t($ts.login.password)"
                    ></v-text-field>
                    <a
                      class="not-a-member-text"
                      style="text-align: right; float: right; font-weight: bold; text-decoration: underline"
                      @click="forgot"
                      >{{ $t($ts.login.forgot) }}</a
                    >
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text>
                <v-row>
                  <v-col class="text-center">
                    <v-btn type="submit" color="primary" @click="login" :loading="isLoading">
                      {{ $t($ts.login.login) }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="py-5">
                  <v-btn block outlined @click="showRegisterForm">{{ $t($ts.login.register) }}</v-btn>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>

          <img
            class="hidden-sm-and-down"
            style="pointer-events: none; float: left; top: 250px; width: 500px; position: absolute; left: 100px"
            src="/img/Login-pana.svg"
          />
        </v-row>

        <!--<button class="google" @click="googleLogin"> <img src="../../../public/img/google-icon.png"  alt="google-login"> Logg inn med Google </button>-->
      </div>

      <!--            <div class="left-section">-->
      <!--                <div class="login-content">-->
      <!--                    <img src="img/doc.png" alt="doc" >-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-application--wrap {
  background: white !important;
}

.arrow {
  right: 129px !important;
}

body {
  background: #ffffff !important;
}
h1 {
  font-size: 3em;
  padding-bottom: 15px;
  // color:#876AFF;
}
.selling-points-wrapper {
  width: 100%;
  float: left;
  height: 450px;
  padding: 30px 0px;

  .selling-points {
    width: 70%;
    float: left;
    margin: 80px 15% 0 15%;
    h2 {
      font-size: 2.2em;
      text-align: center;
    }
    .point {
      width: 33.333%;
      padding: 50px;
      float: left;
      img {
        display: block;
        margin: 0 auto;
        width: 55px;
      }
      h3 {
        text-align: center;
        margin: 10px 0;
        color: #595959;
      }
      p {
        text-align: center;
        margin: 10px 0;
        color: #969696;
      }
    }
  }
}

.left-section,
.right-section {
  width: 30%;
  float: left;
  height: 100%;
  p {
    margin-top: 10px;
  }
}
.right-section {
  width: 70%;
  button {
    width: auto;
  }
  .google {
    width: 100%;
    height: 38px;
    background: #efefef;
    line-height: 28px;
    text-indent: 10px;
    color: #797d7c;
    transition: 0.3s;
    margin-top: 50px;
    img {
      height: 20px;
      width: 20px;
      position: absolute;
      margin-top: 3px;
      float: left;
      margin-left: -29px;
    }
    &:hover {
      background: whitesmoke;
      transition: 0.3s;
    }
  }
  .not-a-member-text {
    float: right;
    color: #3173a3;
    text-decoration: underline;
    margin-top: 21px;
    margin-right: -1px;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      color: #1f4a69;
    }
  }
}

.login-content {
  padding: 40px 0 0 65px;
  margin-top: 40px;
  img {
    height: 200px;
    margin-top: -109px;
    margin-right: 49px;
    float: right;
  }
}

@media only screen and (max-width: 1264px) {
  .login-wrapper {
    width: 100%;
    margin: 200px 0%;
    float: left;
    padding: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .login-wrapper {
    width: 100%;
    margin: 10px 0%;
    float: left;
    height: auto;
    padding: 50px;
    text-align: center;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1em;
      color: #7f7f7f;
      margin-bottom: 5px;
    }
    .left-section {
      display: none;
    }
    .right-section {
      width: 100%;
      .google {
        margin-top: 0;
      }
    }
    .not-a-member-text {
      width: 100%;
    }
  }
}
</style>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule
