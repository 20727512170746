<template>
  <v-row>
    <v-col cols="auto" v-if="canModify">
      <v-btn class="mr-3" right color="success" @click="$emit('toggle-form')">
        <v-icon>{{ isShowingForm ? "mdi-minus" : "mdi-plus" }}</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="auto" v-if="canModify">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="$emit('delete-selected')"
            class="white--text"
            :disabled="!hasSelectedCustomers"
            color="red"
            v-on="on"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t($ts.customer.deleteSelectedCustomers) }}</span>
      </v-tooltip>
    </v-col>

    <v-spacer></v-spacer>
    <v-col>
      <slot name="search"></slot>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "CustomerActions",
})
export default class CustomerActions extends Vue {
  @Prop({ type: Boolean, required: true }) isShowingForm!: boolean;
  @Prop({ type: Boolean, required: true }) hasSelectedCustomers!: boolean;
  @Prop({ type: Boolean, required: true }) canModify!: boolean;
}
</script>
