<script lang="ts">

    import {Component, Vue} from 'vue-property-decorator';
    import WorkingHours from "../Settings/StaffMembers/StaffMember/WorkingHours.vue";
    import VacationDays from "../Settings/StaffMembers/StaffMember/VacationDays.vue";

    @Component({
        name: 'selectWorktime',
        components: {VacationDays, WorkingHours}
    })
    export default class selectWorktime extends Vue {

    }
</script>

<template>
    <div>
        <v-row>
              <WorkingHours></WorkingHours>
        </v-row>
    </div>
</template>

