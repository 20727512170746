import { EventBaseService } from "./Base/EventBaseService";
import { IPostResourceDTO, IResourceController, IResourceDTO } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class ResourceService extends EventBaseService implements IResourceController {
  private static instance: ResourceService;
  private controller = "Resources";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!ResourceService.instance) {
      ResourceService.instance = new ResourceService();
    }
    return ResourceService.instance;
  }

  public async getResources() {
    return this.http.sendRequest<IResourceDTO[]>(IResourceController.GetResourcesRoute);
  }

  public async editResource(resourceDTO: IResourceDTO) {
    return this.withEventEmission(Feature.Resource, EventType.Updated, () =>
      this.http.sendRequest<void>(IResourceController.EditResourceRoute, { data: resourceDTO })
    );
  }

  public async addResource(resourceDTO: IPostResourceDTO) {
    return this.withEventEmission(Feature.Resource, EventType.Added, () =>
      this.http.sendRequest<void>(IResourceController.AddResourceRoute, { data: resourceDTO })
    );
  }

  public async deleteResource(resourceId: number) {
    return this.withEventEmission(Feature.Resource, EventType.Deleted, () =>
      this.http.sendRequest<void>(IResourceController.DeleteResourceRoute, { params: { resourceId } })
    );
  }
  public addService(resourceId: number, serviceId: number): Promise<void> {
    return this.withEventEmission(Feature.Resource, EventType.Updated, () =>
      this.http.sendRequest<void>(IResourceController.AddServiceRoute, { params: { resourceId, serviceId } })
    );
  }
}
