<template>
  <v-dialog max-width="660" v-model="dialogVisible" @input="onDialogChange">
    <BaseFormDialog
      v-if="item"
      ref="baseForm"
      :title="$t($ts.headers.buyStock)"
      :is-editing="false"
      :is-creating="true"
      :is-saving="isSaving"
      :save-text="$t($ts.buttons.save)"
      :create-text="$t($ts.buttons.create)"
      :cancel-text="$t($ts.buttons.cancel)"
      @cancel="closeDialog"
      @create="saveAddStock"
    >
      <v-form ref="form" v-model="isValid">
        <CompanyInfoField :label="$t($ts.dtos.addStockFormDTO.quantity.name)">
          <v-text-field
            v-model="quantity"
            type="number"
            outlined
            dense
            hide-details
            data-cy="form-field-quantity"
            @input="onQuantityChange"
          ></v-text-field>
        </CompanyInfoField>

        <v-divider class="my-4" />

        
        <!-- <CompanyInfoField :label="$t($ts.dtos.addStockFormDTO.purchaseDate.name)">
          <DatePickerField
            v-model="addStockData.purchaseDate"
            outlined
            dense
            hide-details
            data-cy="form-field-purchaseDate"
          ></DatePickerField>
        </CompanyInfoField> -->

        <!-- <v-divider class="my-4" /> -->
        
        
        <CompanyInfoField :label="$t($ts.sidebar.cashRegister)">
          <MoneyAccountSelector
          :showPaymentMethod="false"
            v-model="selectedMoneyAccountId"
            data-cy="stock-money-account-select"
          />
        </CompanyInfoField>
        <v-divider class="my-4" />
        
        <CompanyInfoField :label="$t($ts.dtos.inventoryItemFormDTO.costPrice.name)">
          <v-switch
            v-model="useTotalPrice"
            :label="useTotalPrice ? $t($ts.addStockFormDTO.totalPrice) : $t($ts.addStockFormDTO.unitPrice)"
            class="mb-4"
            @change="onPriceModeChange"
          ></v-switch>
        </CompanyInfoField>


        <CompanyInfoField v-if="!useTotalPrice" :label="$t($ts.addStockFormDTO.unitPrice)">
          <v-text-field
            v-model="purchasePrice"
            type="number"
            outlined
            dense
            hide-details
            @input="updateTotalPrice"
          ></v-text-field>
        </CompanyInfoField>
        
        <CompanyInfoField v-else :label="$t($ts.addStockFormDTO.totalPrice)">
          <v-text-field 
            type="number" 
            outlined
            dense
            hide-details
            v-model="totalPurchasePrice"
            @input="updateUnitPrice"
          ></v-text-field>
        </CompanyInfoField>
        
        <v-divider class="my-4" />
      </v-form>
    </BaseFormDialog>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { inventoryModule } from "@/store/modules/inventory/inventoryModule";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { IInventoryItemDTO, LedgerEntryType, LedgerEntrySourceType, LedgerEntryStatus } from "@shared/types";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import MoneyAccountSelector from "@/components/global/MoneyAccountSelector.vue";
import DatePickerField from "@/components/global/DatePickerField.vue";

@Component({
  components: {
    BaseFormDialog,
    CompanyInfoField,
    MoneyAccountSelector,
    DatePickerField
  },
})
export default class AddStockDialog extends Vue {
  @Prop({ required: true }) readonly value!: boolean;
  @Prop({ required: false, default: null }) readonly item!: IInventoryItemDTO | null;

  private selectedMoneyAccountId: number | null = null;
  private totalPurchasePrice: number = 0;
  private useTotalPrice: boolean = false;
  private isValid: boolean = false;
  private isSaving: boolean = false;
  private quantity: number = 0;
  private purchasePrice: number = 0;

  get dialogVisible(): boolean {
    return this.value;
  }

  set dialogVisible(value: boolean) {
    this.$emit('input', value);
  }

  
  @Watch('item')
  onItemChange(newValue: IInventoryItemDTO | null) {
    if (newValue) {
      this.resetForm();
    }
  }

  get addStockData() {
    return {
      quantity: this.quantity,
      purchasePrice: this.purchasePrice,
      purchaseDate: new Date().toISOString().split("T")[0],
      batchNumber: "",
      inventoryItemId: Number(this.item?.id ?? 0),
      moneyAccountId: this.selectedMoneyAccountId,
      useTotalPrice: this.useTotalPrice,
      totalPurchasePrice: this.totalPurchasePrice
    };
  }

  onQuantityChange(value: any) {
    this.quantity = Number(value);
    this.updateTotalPrice();
  }

  updateTotalPrice(value?: any) {
    if (value !== undefined) {
      this.purchasePrice = Number(value);
    }
    if (this.quantity && this.purchasePrice) {
      this.totalPurchasePrice = this.quantity * this.purchasePrice;
    }
  }

  updateUnitPrice(value?: any) {
    if (value !== undefined) {
      this.totalPurchasePrice = Number(value);
    }
    if (this.quantity && this.totalPurchasePrice) {
      this.purchasePrice = this.totalPurchasePrice / this.quantity;
    }
  }

  onPriceModeChange() {
    if (this.useTotalPrice) {
      this.updateTotalPrice();
    } else {
      this.updateUnitPrice();
    }
  }

  @Watch('value')
  onValueChange(newValue: boolean) {
    if (newValue) {
      this.resetForm();
    }
  }

  private resetForm() {
    this.selectedMoneyAccountId = null;
    this.totalPurchasePrice = 0;
    this.useTotalPrice = false;
    this.quantity = 0;
    
    // Ensure purchasePrice is properly initialized from item's costPrice
      this.purchasePrice = Number(this.item.costPrice);

  }

  private onDialogChange(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  private closeDialog() {
    this.dialogVisible = false;
  }

  private async saveAddStock() {
    if (!this.item) return;
    
    const form = this.$refs.form as any;
    if (!form || !form.validate()) return;

    // Validate quantity
    if (!this.quantity || this.quantity <= 0) {
      return;
    }

    try {
      this.isSaving = true;
      
      // Convert the date string to a DateTime with noon as the time
      const purchaseDate = new Date(this.addStockData.purchaseDate);
      purchaseDate.setHours(12, 0, 0, 0);

      // Add the stock
      await inventoryModule.addStock({
        inventoryItemId: this.item.id,
        quantity: this.quantity,
        purchasePrice: this.purchasePrice,
        purchaseDate: purchaseDate,
        batchNumber: this.addStockData.batchNumber,
        moneyAccountId: this.selectedMoneyAccountId,
        totalPurchasePrice: this.totalPurchasePrice,
        useTotalPrice: this.useTotalPrice
      });

      this.closeDialog();
    } catch (error) {
      console.error("Failed to add stock:", error);

    } finally {
      this.isSaving = false;
    }
  }

  mounted() {
    if (this.item) {
      this.resetForm();
    }
  }
}
</script> 

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
