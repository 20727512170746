<script lang="ts" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
import { Component, Vue } from "vue-property-decorator";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import AppointmentList from "./AppointmentList.vue";
import InvoiceCreator from "../../components/InvoiceCreator.vue";
import InvoicesList from "../../components/InvoicesList.vue";
import { userModule } from "@/store/modules/user/userModule";
import { invoicesModule } from "@/store/modules/invoices/invoicesModule";

@Component({
  name: "EventsOverview",
  components: {
    InvoicesList,
    InvoiceCreator,
    AppointmentList,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class EventsOverview extends Vue {
  get user() {
    return userModule.user;
  }

  public newInvoice: boolean = false;
  public isLoading: boolean = true;

  selectedEvents = [];

  public async created(): Promise<any> {
    this.loadInvoices();
  }

  async loadInvoices() {
    this.isLoading = true;
    await invoicesModule.getInvoices();
    this.isLoading = false;
  }

  onFinished() {
    this.newInvoice = false;
  }

  get invoices() {
    return invoicesModule.invoices;
  }
}
</script>

<template>
  <div>
    <invoice-creator @close="onFinished" @finished="onFinished" :showDialog="newInvoice"></invoice-creator>

    <div class="">
      <div class="">
        <invoices-list @update="loadInvoices" :isLoading="isLoading" :invoices="invoices">
          <v-btn bottom right @click="newInvoice = true" color="success">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </invoices-list>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
