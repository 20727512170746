<script lang="ts" xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
import { Component, Vue } from "vue-property-decorator";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { IDialog } from "@/store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import AppointmentList from "./AppointmentList.vue";
import { AppointmentInfo } from "../../store/appointments/appointmentTypings";
import InvoiceCreator from "../../components/InvoiceCreator.vue";
import InvoicesList from "../../components/InvoicesList.vue";
import PaymentList from "../../components/PaymentList.vue";
import PaymentCreator from "../../components/global/PaymentCreator.vue";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { paymentsModule } from "@/store/modules/payments/paymentsModule";

@Component({
  name: "PaymentsOverview",
  components: {
    PaymentCreator,
    PaymentList,
    InvoicesList,
    InvoiceCreator,
    AppointmentList,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class PaymentsOverview extends Vue {
  get user() {
    return userModule.user;
  }

  public showDialog: boolean = false;
  public isLoading: boolean = true;

  selectedEvents = [];

  public chosenAppointment: AppointmentInfo = null;

  public async created(): Promise<any> {
    try {
      this.isLoading = true;
      //await this.getPayments(); //where is the payment controller lol?
      this.isLoading = false;
    } catch (e) {
      let dialog: IDialog = {
        text: "Kunne ikke hente betalinger",
      };
      dialogModule.addToDialogQueue(dialog);
    }
  }

  onFinished() {
    this.showDialog = false;
  }
  onChosenAppointment(appointment) {
    this.chosenAppointment = appointment;
  }

  onFinishedPayment() {
    this.showDialog = false;
    this.chosenAppointment = null;
  }

  get dialog() {
    return this.showDialog;
  }
  set dialog(v) {
    this.chosenAppointment = null;
    this.showDialog = false;
  }

  get payments() {
    // return paymentsModule. i guess paymenets? i;
    return [];
  }
}
</script>

<template>
  <div>
    <!--<invoice-creator @close="onFinisasdshed" @finished="onFinished" :showDialog="newInvoice"></invoice-creator>-->

    <v-dialog v-model="dialog">
      <!--<v-card v-if="chosenAppointment == null">-->

      <!--<v-card-title fixed-->
      <!--class="headline pa-3 grey lighten-2"-->
      <!--primary-title-->
      <!--&gt;-->
      <!--Velg bestilling-->
      <!--<v-spacer>-->
      <!--</v-spacer>-->
      <!--<v-btn color="blue darken-1" text @click="dialog = false">Lukk</v-btn>-->

      <!--</v-card-title>-->

      <!--&lt;!&ndash;<appointment-list @chosen="onChosenAppointment" :chooseAppointment="true" :showPaid="false"></appointment-list>&ndash;&gt;-->
      <!--</v-card>-->
      <div>
        <payment-creator :appointment="chosenAppointment" @finished="onFinishedPayment"></payment-creator>
      </div>
    </v-dialog>
    <div class="">
      <v-card flat>
        <!--<v-card-title>Betalinger</v-card-title>-->

        <v-row>
          <v-col>
            <v-btn bottom right @click="showDialog = true" color="success">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <payment-list :isLoading="isLoading" :payments="payments"></payment-list>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
