<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import Loader from "@/components/global/Loader.vue";
import { formatDate, formatTime } from "@/types/formatDateHelper";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { DialogType, IDialog } from "@/store/dialog";
import CustomerModal from "../../components/global/CustomerModal.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import CreateAppointment from "../../components/global/CreateAppointment.vue";
import { IAppointmentDTO, IAuditLogDTO, IUserDTO, IAppointmentInfoDTO, IAppointmentController } from "@shared/types";
import { IAppointmentPatch } from "@/store/modules/appointments/appointmentModule";
import { formatDateString, formatDateStringHour } from "@/Utilities/dateUtility";

import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "Appointment-list",
  components: {
    CreateAppointment,
    CustomerModalWindow,
    CustomerModal,
    CustomButton,
    Loader,
    Sidebar,
    HeaderBar,
  },
})
export default class AppointmentList extends Vue {
  updateInvoiceStatus($event: any, item: any) {
    throw new Error("Method not implemented.");
  }
  public toggleIndex: number | null = null;

  @Prop({}) customerId;
  @Prop({ default: true }) viewCustomerDetails;
  @Prop({ default: true }) showPaid;
  @Prop({ default: false }) chooseAppointment;
  @Prop({ default: true }) tableActions;

  displayChoice = false;
  displayChoice2 = false;
  viewingCustomer = null;

  options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

  statusFilters = [];

  private defaultSorting: string = "created";
  private defaultDesc: string = "true";

  props: any;

  get user() {
    return userModule.user;
  }

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get invoiceStatuses() {
    if (this.user.enablePaymentModule) {
      return [
        { text: this.$t(this.$ts.appointment.status.ny), value: "Ny" },
        {
          text: this.$t(this.$ts.appointment.status.invoiceCreated),
          value: "Faktura opprettet",
        },
        {
          text: this.$t(this.$ts.appointment.status.invoiceSent),
          value: "Faktura sendt",
        },
        {
          text: this.$t(this.$ts.appointment.status.invoicePaid),
          value: "Faktura betalt",
        },
        { text: this.$t(this.$ts.appointment.status.ferdig), value: "Ferdig" },
      ];
    } else {
      return [
        { text: this.$t(this.$ts.appointment.status.ny), value: "Ny" },
        { text: this.$t(this.$ts.appointment.status.ferdig), value: "Ferdig" },
      ];
    }
  }

  readingNote: IAppointmentInfoDTO | null = null;

  appointments: IAppointmentInfoDTO[] | null = null;
  isLoading: boolean = true;

  public search: string = "";
  selectedAppointments = [];
  tags = [];
  private bookOpen: boolean = false;
  private isReadingNoteDialog: boolean = false;
  selectedStaffMember: number = 0;

  get appointmentsForOverview() {
    return appointmentsModule.appointmentsForOverview;
  }

  get appointmentsPagination() {
    return appointmentsModule.appointmentsPagination;
  }

  get hasAppointments(): boolean {
    return (this.appointmentsForOverview && this.appointmentsForOverview.length > 0) || !this.isLoading;
  }

  get hasMultipleStaff() {
    return staffMemberModule.staffMembers.length > 1;
  }

  get staffMemberOptions() {
    return [{ id: 0, fullName: this.$t(this.$ts.all) }, ...calendarModule.staffMemberOptions];
  }

  async handleTableUpdate(options: any) {
    this.isLoading = true;
    try {
      const query: Partial<typeof IAppointmentController.IGetAppointmentsForAdminQuery> = {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        sortBy: options.sortBy[0],
        desc: options.sortDesc[0],
        staffMemberId: this.selectedStaffMember,
      };
      if (this.customerId) {
        query.customerId = this.customerId;
      }
      await appointmentsModule.getAppointmentsForOverview(query);
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    let overViewSorting = localStorage.getItem("overviewsorting");
    let desc = localStorage.getItem("overviewsortingdesc");
    let savedFilters = localStorage.getItem("statusfilter");
    if (savedFilters != null) {
      const filterValues = savedFilters.split(",");
      this.statusFilters = filterValues.map((value) => {
        return {
          value: value,
          text: this.getLabel(value),
        };
      });
    }

    if (this.statusFilters.some((filter) => filter.value !== "")) {
      console.log("There are status filters with non-empty values.");
    } else {
      this.statusFilters = [
        { value: "Ny", text: this.$t(this.$ts.appointment.status.ny) },
        {
          value: "Faktura opprettet",
          text: this.$t(this.$ts.appointment.status.invoiceCreated),
        },
        {
          value: "Faktura sendt",
          text: this.$t(this.$ts.appointment.status.invoiceSent),
        },
        {
          value: "Faktura betalt",
          text: this.$t(this.$ts.appointment.status.invoicePaid),
        },
        { value: "Ferdig", text: this.$t(this.$ts.appointment.status.ferdig) },
      ];
    }

    if (overViewSorting != null) {
      this.defaultSorting = overViewSorting;
    }
    if (desc != null) {
      this.defaultDesc = desc;
    }

    await this.handleTableUpdate({
      page: 1,
      itemsPerPage: 10,
      sortBy: [this.defaultSorting],
      sortDesc: [this.defaultDesc === "true"],
      staffMemberId: this.selectedStaffMember,
    });
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return formatTime(hour, minute);
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
  formatDateString(date: Date) {
    return formatDateString(date);
  }

  deletedCustomer() {
    this.viewingCustomer = null;
  }
  public get headers() {
    if (this.viewCustomerDetails) {
      if (!this.displayChoice2) {
        let array = [
          {
            text: this.$t(this.$ts.appointment.number),
            sortable: true,
            value: "number",
          },
          {
            text: this.$t(this.$ts.appointment.service),
            sortable: true,
            value: "appointmentName",
          },
          {
            text: this.$t(this.$ts.appointment.time),
            sortable: true,
            value: "date",
          },
          {
            text: this.$t(this.$ts.appointment.customer),
            sortable: true,
            value: "customerName",
          },
          {
            text: this.$t(this.$ts.entities.staff),
            sortable: true,
            value: "staffMemberName",
          },
          {
            text: this.$t(this.$ts.appointment.appointmentStatus),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointment.comment),
            value: "comment",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointment.adminNote),
            value: "adminNote",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointment.created),
            sortable: true,
            value: "created",
          },
        ];

        if (!this.showPaid) {
          this.statusFilters = [{ value: "Ny", text: this.$t(this.$ts.appointment.status.ny) }];
        }

        if (this.chooseAppointment) {
          array.splice(0, 0, {
            text: "",
            value: "selectAppointment",
            sortable: true,
          });
        }

        return array;
      } else {
        let array = [
          {
            text: this.$t(this.$ts.appointment.number),
            sortable: true,
            value: "number",
          },
          {
            text: this.$t(this.$ts.appointment.service),
            sortable: false,
            value: "appointmentName",
          },
          {
            text: this.$t(this.$ts.appointment.time),
            sortable: true,
            value: "date",
          },
          {
            text: this.$t(this.$ts.appointment.customer),
            sortable: true,
            value: "customerName",
          },
          {
            text: this.$t(this.$ts.email),
            sortable: true,
            value: "customerEmail",
          },
          { text: this.$t(this.$ts.phoneNumber), value: "customerPhone" },
          {
            text: this.$t(this.$ts.address),
            value: "customerAddress",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.postalCode),
            value: "customerPostalCode",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointmentList.createdByCustomer),
            value: "createdByCustomer",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointmentList.status),
            value: "status",
            sortable: true,
          },
          {
            text: this.$t(this.$ts.appointment.comment),
            value: "comment",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointment.adminNote),
            value: "adminNote",
            sortable: false,
          },
          {
            text: this.$t(this.$ts.appointment.created),
            sortable: true,
            value: "created",
          },
        ];

        if (this.chooseAppointment) {
          array.splice(0, 0, {
            text: "",
            value: "selectAppointment",
            sortable: true,
          });
        }

        return array;
      }
    } else {
      return [
        {
          text: this.$t(this.$ts.appointment.number),
          sortable: true,
          value: "number",
        },
        {
          text: this.$t(this.$ts.appointment.service),
          sortable: true,
          value: "appointmentName",
        },
        {
          text: this.$t(this.$ts.appointment.time),
          sortable: true,
          value: "date",
        },
        // {text: this.$t($ts.appointment.createdByCustomer), value: 'createdByCustomer', sortable: false},
        {
          text: this.$t(this.$ts.appointmentList.status),
          value: "status",
          sortable: true,
        },
        {
          text: this.$t(this.$ts.appointment.comment),
          value: "comment",
          sortable: false,
        },
        {
          text: this.$t(this.$ts.appointment.adminNote),
          value: "adminNote",
          sortable: false,
        },
        {
          text: this.$t(this.$ts.appointment.created),
          sortable: true,
          value: "created",
        },
      ];
    }
  }
  get appointmentsForDataTable() {
    let returnValue: any = []; //TODO: Type appointment for overview

    returnValue = this.appointmentsForOverview;

    let filteredByStatus = returnValue.filter((x) => {
      return this.statusFilters.some((filter) => filter.value === x.status);
    });

    if (this.defaultSorting == null) {
      return filteredByStatus;
    }

    let sortValue = this.defaultSorting;

    let desc = true;
    if (this.defaultDesc != null) {
      desc = this.defaultDesc == "true";
    }

    if (desc) {
      return filteredByStatus.sort(this.dynamicSortDesc(sortValue));
    } else {
      return filteredByStatus.sort(this.dynamicSort(sortValue));
    }
  }
  dynamicSortDesc(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result = a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  getDesc() {
    if (this.defaultDesc != null) {
      return this.defaultDesc == "true";
    }
    return true;
  }
  getSort() {
    let overViewSorting = localStorage.getItem("overviewsorting");
    if (overViewSorting != null) {
      return overViewSorting;
    }
    return this.defaultSorting;
  }
  sortByChanged(newSortBy) {
    this.defaultSorting = newSortBy;
    localStorage.setItem("overviewsorting", newSortBy);
  }
  sortByChangedDesc(newSortDesc) {
    this.defaultDesc = newSortDesc.toString();
    localStorage.setItem("overviewsortingdesc", newSortDesc.toString());
  }
  onStatusChange() {
    localStorage.setItem("statusfilter", this.statusFilters.join(","));
  }
  readComment(comment) {
    snackbarModule.postSnackbarMessage(comment);
  }
  readNote(note) {
    this.readingNote = note;
    this.isReadingNoteDialog = true;
  }

  async updateAdminComment(event, selectedEvent: IAppointmentInfoDTO) {
    let update: Partial<IAppointmentDTO> = {
      adminNote: selectedEvent.adminNote,
    };

    const payload: IAppointmentPatch = {
      appointmentId: selectedEvent.id,
      patch: update,
    };

    this.isReadingNoteDialog = false;
    this.readingNote = null;

    await appointmentsModule.patchAppointment(payload);
  }

  onSelectedAppointment(item) {
    this.$emit("selected", item);
  }
  editCustomer(item) {
    if (item.customerId && item.customerId !== 0 && item.customerId !== -1) {
      this.$router.push("/kunder/" + item.customerId).catch(() => {});
    }
  }
  viewCustomer(item) {
    if (item.customerId && item.customerId !== 0 && item.customerId !== -1) {
      this.viewingCustomer = item.customerId;
    }
  }
  logShowMoreCustomerInfo() {
    auditLogModule.postAudit({
      dto: {
        message: "Show more customer info",
        type: 4, // PageVisit
        action: 3, // Info
        comment: JSON.stringify({ showMore: this.displayChoice2 }),
        hideForUser: true,
      },
    });
  }
  get hasSelectedAppointments() {
    return this.selectedAppointments.length === 0;
  }
  get hasSelectedAllFilters() {
    return this.statusFilters.length === this.invoiceStatuses.length;
  }
  getLabel(status) {
    switch (status) {
      case "Ny":
        return this.$t(this.$ts.appointment.status.ny);
      case "Faktura opprettet":
        return this.$t(this.$ts.appointment.status.invoiceCreated);
      case "Faktura sendt":
        return this.$t(this.$ts.appointment.status.invoiceSent);
      case "Faktura betalt":
        return this.$t(this.$ts.appointment.status.invoicePaid);
      case "Ferdig":
        return this.$t(this.$ts.appointment.status.ferdig);
      default:
        return "???";
    }
  }
  getColor(status) {
    switch (status) {
      case "Ny":
        return "light";
      case "Faktura opprettet":
        return "orange";
      case "Faktura sendt":
        return "yellow";
      case "Faktura betalt":
        return "green";
      case "Ferdig":
        return "#80cc74";
      default:
        return "grey";
    }
  }
  deleteSelectedAppointments() {
    let vm = this;

    let dialog: IDialog = {
      text: vm.selectedAppointments.length + " " + vm.$t(vm.$ts.appointmentList.delete_appointments_message),
      type: DialogType.Choice,
      header: vm.$t(vm.$ts.appointmentList.delete_appointments_header).toString(),
      action: function () {
        vm.selectedAppointments.forEach((x) => {
          appointmentsModule.deleteBookedAppointment(x.id);
        });
        vm.selectedAppointments = [];
      },
    };

    dialogModule.addToDialogQueue(dialog);
  }
}
</script>

<template>
  <div>
    <v-dialog max-width="600" persistent v-model="isReadingNoteDialog">
      <v-card v-if="readingNote != null">
        <v-card-title>
          {{ $t($ts.dialog.notes) }}
        </v-card-title>
        <v-textarea
          full-width
          filled
          outlined
          :label="$t($ts.dialog.notes)"
          solo
          no-resize
          rows="7"
          :hint="$t($ts.dialog.notes)"
          row-height="20"
          persisent-hint
          v-model="readingNote.adminNote"
          :readonly="!canWriteAppointmentsAndClients"
          :disabled="!canWriteAppointmentsAndClients"
        >
        </v-textarea>
        <v-card-actions>
          <v-btn @click="updateAdminComment($event, readingNote)" :disabled="!canWriteAppointmentsAndClients">{{
            $t($ts.buttons.ok)
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customer-modal-window
      @finish="viewingCustomer = null"
      :hideAppointments="true"
      @delete="deletedCustomer"
      :customerId="viewingCustomer"
      v-if="viewingCustomer != null"
    ></customer-modal-window>

    <v-card flat v-if="user">
      <v-card-text>
        <v-row>
          <v-col cols="auto" v-if="hasMultipleStaff">
            <v-select
              v-model="selectedStaffMember"
              :items="staffMemberOptions"
              item-value="id"
              item-text="fullName"
              style="min-width: 200px"
              dense
              outlined
              hide-details
              :label="$t($ts.selectStaffMember)"
              class="header-select"
              @change="handleTableUpdate(options)"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-account-supervisor-circle</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!hasSelectedAppointments" cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="deleteSelectedAppointments"
                  class="white--text"
                  :disabled="hasSelectedAppointments"
                  color="red"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t($ts.appointment.deleteSelectedAppointments) }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto">
            <v-select
              style="max-width: 800px"
              v-if="tableActions"
              class="mt-3"
              dense
              chips
              small-chips
              attach
              multiple
              :label="$t($ts.appointment.filter)"
              @change="onStatusChange"
              v-model="statusFilters"
              :items="invoiceStatuses"
              item-text="text"
              item-value="value"
            >
              <template v-slot:selection="{ item }">
                <v-chip small :color="getColor(item.value)" class="white--text mr-1">
                  <span>{{ getLabel(item.value) }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip :color="getColor(item.value)" small class="mr-2">
                  <span>{{ getLabel(item.value) }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-text-field
              v-if="tableActions"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t($ts.search)"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <div v-if="!displayChoice && hasAppointments">
          <v-data-table
            :headers="headers"
            :items="appointmentsForOverview"
            :items-per-page="10"
            :single-select="false"
            v-model="selectedAppointments"
            :loading="isLoading"
            :search="search"
            :hide-default-footer="!tableActions"
            :hide-default-header="!tableActions"
            :server-items-length="appointmentsPagination?.total || 0"
            :options.sync="options"
            @update:options="handleTableUpdate"
            show-select
          >
            <template #no-data>
              {{ $t($ts.appointment.noAppointments) }}
            </template>

            <template #[`item.number`]="{ item }">
              <router-link v-if="item.number !== 0" :to="'/bestillinger/' + item.number">{{ item.number }}</router-link>
              <span v-else></span>
            </template>

            <template #[`item.date`]="{ item }">
              <span>{{ formatDateStringHour(item.date) }}</span>
            </template>

            <template #[`item.comment`]="{ item }">
              <span>
                <v-btn v-if="item.comment != null && item.comment.length > 1" @click="readComment(item.comment)" icon>
                  <v-icon>comment</v-icon>
                </v-btn>
              </span>
            </template>

            <template #[`item.adminNote`]="{ item }">
              <span>
                <v-btn
                  v-if="item.adminNote != null && item.adminNote.length > 1"
                  @click="readNote(item)"
                  icon
                  :disabled="!canWriteAppointmentsAndClients"
                >
                  <v-icon>comment</v-icon>
                </v-btn>
                <v-btn v-else @click="readNote(item)" icon :disabled="!canWriteAppointmentsAndClients">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </span>
            </template>

            <template #[`item.created`]="{ item }">
              <span>{{ formatDateStringHour(item.created) }}</span>
            </template>

            <template #[`item.appointmentName`]="{ item }">
              <span>{{ item.appointmentName }}</span>
            </template>

            <template #[`item.customerName`]="{ item }">
              <router-link v-if="item.customerId && item.customerId !== 0 && item.customerId !== -1" :to="'/kunder/' + item.customerId">
                {{ item.customerName }}
              </router-link>
              <span v-else>{{ item.customerName }}</span>
            </template>

            <template #[`item.staffMemberName`]="{ item }">
              <span>{{ item.staffMemberName }}</span>
            </template>

            <template #[`item.createdByCustomer`]="{ item }">
              <v-simple-checkbox v-model="item.createdByCustomer" disabled></v-simple-checkbox>
            </template>

            <template #[`item.status`]="{ item }">
              <v-select
                style="max-width: 150px"
                dense
                v-if="item != null && item.status != null"
                :color="getColor(item.status)"
                chips
                attach
                v-model="item.status"
                :items="invoiceStatuses"
                item-text="text"
                item-value="value"
                return-object
                @change="updateInvoiceStatus($event, item)"
                :readonly="!canWriteAppointmentsAndClients"
                :disabled="!canWriteAppointmentsAndClients"
              >
                <template #selection="data">
                  <v-chip :color="getColor(data.item.value)">
                    <span>{{ getLabel(data.item.value) }}</span>
                  </v-chip>
                </template>
              </v-select>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon 
                small 
                class="mr-2" 
                @click="editCustomer(item)"
                :disabled="!item.customerId || item.customerId === 0 || item.customerId === -1"
                :style="!item.customerId || item.customerId === 0 || item.customerId === -1 ? 'opacity: 0.5; cursor: default;' : ''"
              >
                mdi-pencil
              </v-icon>
            </template>

            <template #[`item.selectAppointment`]="{ item }">
              <v-btn @click="onSelectedAppointment(item)">{{ $t($ts.appointment.select) }}</v-btn>
            </template>

            <template #[`item.info`]="{ item }">
              <v-icon 
                small 
                class="mr-2" 
                @click="viewCustomer(item)"
                :disabled="!item.customerId || item.customerId === 0 || item.customerId === -1"
                :style="!item.customerId || item.customerId === 0 || item.customerId === -1 ? 'opacity: 0.5; cursor: default;' : ''"
              >
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </div>

        <v-data-table :loading-text="$t($ts.appointment.loading)" v-else loading> </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line) ::v-deep .v-select__selections {
  padding-top: 10px;
}
</style>
