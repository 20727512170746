import { VuexModule, Module, Action } from "vuex-class-modules";
import store from "@/store/store";

export enum PermissionArea {
  APPOINTMENTS_AND_CLIENTS = "appointmentsAndClients",
  SETTINGS = "settings",
  STATISTICS_AND_FINANCES = "statisticsAndFinances",
  ADMINISTRATION = "administration",
}

export enum PermissionLevel {
  NONE = "none",
  READ = "read",
  READ_WRITE = "readwrite",
}

interface Permission {
  level: PermissionLevel;
}

interface Permissions {
  appointmentsAndClients: Permission;
  settings: Permission;
  statisticsAndFinances: Permission;
  administration: Permission;
}

@Module({ generateMutationSetters: true })
class UserPermissionsModule extends VuexModule {
  private permissions: Permissions | null = null;

  private getAllPermissions(): Permissions {
    return {
      appointmentsAndClients: { level: PermissionLevel.READ_WRITE },
      settings: { level: PermissionLevel.READ_WRITE },
      statisticsAndFinances: { level: PermissionLevel.READ_WRITE },
      administration: { level: PermissionLevel.READ_WRITE },
    };
  }

  // Getters
  get getPermissions(): Permissions | null {
    return this.permissions;
  }

  get hasPermission() {
    return (area: keyof Permissions, requiredLevel: PermissionLevel): boolean => {
      if (!this.permissions) return false;

      const permission = this.permissions[area];
      if (!permission) return false;

      // Define permission hierarchy
      const levels: { [key in PermissionLevel]: number } = {
        [PermissionLevel.NONE]: 0,
        [PermissionLevel.READ]: 1,
        [PermissionLevel.READ_WRITE]: 2,
      };

      // Check if user's permission level is greater than or equal to required level
      return levels[permission.level] >= levels[requiredLevel];
    };
  }

  // Appointments & Clients
  get canViewAppointmentsAndClients(): boolean {
    return this.hasPermission(PermissionArea.APPOINTMENTS_AND_CLIENTS, PermissionLevel.READ);
  }

  get canWriteAppointmentsAndClients(): boolean {
    return this.hasPermission(PermissionArea.APPOINTMENTS_AND_CLIENTS, PermissionLevel.READ_WRITE);
  }

  // Settings
  get canViewSettings(): boolean {
    return this.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ);
  }

  get canWriteSettings(): boolean {
    return this.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ_WRITE);
  }

  // Statistics & Finances
  get canViewStatisticsAndFinances(): boolean {
    return this.hasPermission(PermissionArea.STATISTICS_AND_FINANCES, PermissionLevel.READ);
  }

  get canWriteStatisticsAndFinances(): boolean {
    return this.hasPermission(PermissionArea.STATISTICS_AND_FINANCES, PermissionLevel.READ_WRITE);
  }

  // Administration
  get canViewAdministration(): boolean {
    return this.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ);
  }

  get canWriteAdministration(): boolean {
    return this.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ_WRITE);
  }

  @Action
  setUserPermissions(firebaseUser: any): void {
    // If user is owner or first user (no role), grant all permissions
    if (firebaseUser?.isOwner || !firebaseUser?.role) {
      this.permissions = this.getAllPermissions();
    }
    // Otherwise use role permissions if they exist
    else if (firebaseUser?.role?.permissions) {
      this.permissions = firebaseUser.role.permissions;
    }
    // Fallback to no permissions
    else {
      this.permissions = null;
    }
  }
}

export const userPermissionsModule = new UserPermissionsModule({ store, name: "userPermissionsModule" });
