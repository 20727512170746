import { userModule } from "@/store/modules/user/userModule";

export class LocalStorageService {
  private static getStorageKey(prefix: string): string {
    const user = userModule.user;
    if (!user?.userId) return null;
    return `${prefix}_count_${user.userId}`;
  }

  public static saveItemCount(prefix: string, count: number): void {
    const storageKey = this.getStorageKey(prefix);
    if (storageKey) {
      localStorage.setItem(storageKey, count.toString());
    }
  }

  public static getItemCount(prefix: string): number | null {
    const storageKey = this.getStorageKey(prefix);
    if (!storageKey) return null;

    const savedCount = localStorage.getItem(storageKey);
    return savedCount ? parseInt(savedCount) : null;
  }

  public static getItemCountOrDefault(prefix: string, defaultMin: number = 3, defaultMax: number = 5): number {
    const count = this.getItemCount(prefix);
    return count ?? Math.floor(Math.random() * (defaultMax - defaultMin + 1)) + defaultMin;
  }
}
