import { VuexModule, Module, Action } from "vuex-class-modules";
import { CustomFieldService } from "@/services/api/CustomFieldService";
import { ICustomFieldDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class CustomFieldModule extends VuexModule {
  private readonly customFieldService: CustomFieldService;

  constructor(options: any) {
    super(options);
    this.customFieldService = CustomFieldService.getInstance();
  }

  // State
  customFields: ICustomFieldDTO[] = [];

  @Action
  async getCustomFields() {
    this.customFields = await this.customFieldService.get();
  }

  @Action
  async putCustomField(customField: ICustomFieldDTO) {
    await this.customFieldService.put(customField);
    await this.getCustomFields();
  }

  @Action
  async postCustomField(customField: ICustomFieldDTO) {
    await this.customFieldService.post(customField);
    await this.getCustomFields();
  }

  @Action
  async deleteCustomField(id: number) {
    await this.customFieldService.delete(id);
    await this.getCustomFields();
  }
}

export const customFieldModule = new CustomFieldModule({ store, name: "customFieldsModule" });
