import {
  IStaffMemberController,
  IStaffMemberDTO,
  IStaffMemberBookingDTO,
  IStaffMemberAnonymousRequestDTO,
  IAppointmentTypeDTO,
  IStaffMemberStatisticsDTO,
} from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";

export class MockStaffMemberService implements IStaffMemberController {
  private static instance: MockStaffMemberService;
  private mockStaffMembers: IStaffMemberDTO[] = [
    {
      id: 1,
      profilePictureUrl: "",
      services: [] as IAppointmentTypeDTO[],
      workingDays: [true, true, true, true, true, false, false],
      vacationDays: [],
      companyRole: 1,
      firstName: "Staff",
      lastName: "Member",
      fullName: "Staff Member",
      phoneNumber: "+4712345678",
      emailAddress: "staff@example.com",
      role: "Staff",
      gender: "male",
      sector: 1,
      dateOfBirth: null,
      notificationType: 1,
      mondayOpenFromMidnight: 480, // 8:00
      tuesdayOpenFromMidnight: 480,
      wednesdayOpenFromMidnight: 480,
      thursdayOpenFromMidnight: 480,
      fridayOpenFromMidnight: 480,
      saturdayOpenFromMidnight: 0,
      sundayOpenFromMidnight: 0,
      mondayCloseFromMidnight: 1020, // 17:00
      tuesdayCloseFromMidnight: 1020,
      wednesdayCloseFromMidnight: 1020,
      thursdayCloseFromMidnight: 1020,
      fridayCloseFromMidnight: 1020,
      saturdayCloseFromMidnight: 0,
      sundayCloseFromMidnight: 0,
      color: "#4CAF50",
      imageUrl: "",
      commissionPercentage: 0,
      eligibleForCommission: false,
    },
  ];

  private constructor() {}
  public getStaffMemberStatistics(
    staffMemberId: number,
    year: number,
    month: number,
    day: number
  ): Promise<IStaffMemberStatisticsDTO> {
    return Promise.resolve({
      services: [],
      products: [],
      payments: [],
    });
  }

  public static getInstance(): MockStaffMemberService {
    if (!MockStaffMemberService.instance) {
      MockStaffMemberService.instance = new MockStaffMemberService();
    }
    return MockStaffMemberService.instance;
  }

  public async staffMemberHasOAuth(staffMemberID: number): Promise<boolean> {
    return Promise.resolve(false);
  }

  public async getStaffMembers(): Promise<IStaffMemberDTO[]> {
    return Promise.resolve(this.mockStaffMembers);
  }

  public async editServiceStateOnStaffMember(
    staffMemberId: number,
    serviceId: number,
    enabled: boolean
  ): Promise<void> {
    const staffMember = this.mockStaffMembers.find((s) => s.id === staffMemberId);
    if (staffMember) {
      // In a real implementation, we would need to handle the full service object
      // For mock purposes, we'll just maintain the enabled state
      return Promise.resolve();
    }
    return Promise.resolve();
  }

  public async postStaffMember(staffMember: IStaffMemberDTO): Promise<any> {
    const newStaffMember = {
      ...staffMember,
      id: Math.max(...this.mockStaffMembers.map((s) => s.id)) + 1,
    };
    this.mockStaffMembers.push(newStaffMember);
    return Promise.resolve(newStaffMember);
  }

  public async patchStaffMember(patchDoc: JsonPatchOperation[], staffMemberId: number): Promise<any> {
    const staffMember = this.mockStaffMembers.find((s) => s.id === staffMemberId);
    if (staffMember) {
      // Simple implementation - just return success
      return Promise.resolve(staffMember);
    }
    return Promise.reject(new Error("Staff member not found"));
  }

  public async deleteStaffMember(staffMemberId: number): Promise<any> {
    const index = this.mockStaffMembers.findIndex((s) => s.id === staffMemberId);
    if (index !== -1) {
      this.mockStaffMembers.splice(index, 1);
    }
    return Promise.resolve();
  }

  public async uploadStaffMemberPicture(file: any[], staffMemberId: number): Promise<any> {
    const staffMember = this.mockStaffMembers.find((s) => s.id === staffMemberId);
    if (staffMember) {
      staffMember.profilePictureUrl = "mock-image-url";
    }
    return Promise.resolve();
  }

  public async getStaffMembersAsAnonymous(
    adminId: string,
    request: IStaffMemberAnonymousRequestDTO
  ): Promise<IStaffMemberBookingDTO[]> {
    return Promise.resolve(
      this.mockStaffMembers.map((staff) => ({
        ...staff,
        nextAvailable: new Date(),
      }))
    );
  }

  public async getStaffMemberCount(adminId: string): Promise<number> {
    return Promise.resolve(this.mockStaffMembers.length);
  }
}
