<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../../ToolTip.vue";
import CompanyInfoField from "../../../global/CompanyInfoField.vue";
import { IAuditLogDTO, IPostAuditLogDTO, IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

@Component({
  name: "BookingPageInformationSettings",
  components: {
    ToolTip,
    CompanyInfoField,
  },
})
export default class BookingPageInformationSettings extends Vue {
  public showInformationBox: boolean = false;
  public informationBoxText: string = "";
  public cancellationBoxText: string = "";

  created() {
    this.showInformationBox = userModule.user.showInformationBox;
    this.informationBoxText = userModule.user.informationBoxText;
    this.cancellationBoxText = userModule.user.cancellationBoxText;
  }

  async saveChanges() {
    if (this.showInformationBox !== userModule.user.showInformationBox) {
      const audit: IPostAuditLogDTO = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t("bookingPageInformationSettings.auditLogs.changedShowInformationBox", {
          setting: this.booleanToOnOff(this.showInformationBox),
        }).toString(),
        hideForUser: false,
      };
      await auditLogModule.postAuditLog(audit);
    }

    if (this.informationBoxText !== userModule.user.informationBoxText) {
      const audit: IPostAuditLogDTO = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t(this.$ts.bookingPageInformationSettings.auditLogs.changedInformationBoxText).toString(),
        hideForUser: false,
      };
      await auditLogModule.postAuditLog(audit);
    }

    const update: Partial<IUserDTO> = {
      showInformationBox: this.showInformationBox,
      informationBoxText: this.informationBoxText,
      cancellationBoxText: this.cancellationBoxText,
    };

    await userModule.updateUser(update);
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }

  get isOldDomain() {
    return window.location.toString().includes("vergba");
  }
}
</script>

<template>
  <div>
    <v-container>
      <!-- Information Box Section -->
      <CompanyInfoField :label="$t($ts.showInformationBox)" :description="$t($ts.shouldInformationBoxBeDisplayed)">
        <v-switch v-model="showInformationBox" color="#56dcb6" :label="booleanToOnOff(showInformationBox)" />
      </CompanyInfoField>

      <v-divider />

      <CompanyInfoField
        :label="$t($ts.informationBox)"
        v-if="showInformationBox"
        :description="$t($ts.informationForCustomerOnBookingPage)"
      >
        <wysiwyg v-model="informationBoxText"></wysiwyg>
      </CompanyInfoField>

      <v-divider />

      <!-- Cancellation Information Section -->
      <CompanyInfoField
        :label="$t($ts.cancellationInformation)"
        :description="$t($ts.customizeInformationCustomerSeesWhenCancelling)"
      >
        <wysiwyg v-model="cancellationBoxText"></wysiwyg>
      </CompanyInfoField>

      <v-divider />

      <!-- Save Button -->
      <v-card-actions>
        <v-btn color="success" @click="saveChanges">{{ $t($ts.buttons.save) }}</v-btn>
      </v-card-actions>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
