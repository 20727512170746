
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "../../components/global/MainHeader.vue";
    import AdminCustomers from "../../components/global/AdminCustomers.vue";


    @Component({
        name:'customers',
        components:{AdminCustomers, Sidebar, HeaderBar}
    })
    export default class Customers extends Vue{
        created(){
        }


    }
</script>

<template>
            <admin-customers></admin-customers>
</template>

