import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { Container } from "@/services/api/Container";
import {
  IInventoryItemCategoryDTO,
  IPostInventoryItemCategoryDTO,
  IUpdateInventoryItemCategoryDTO,
  IInventoryItemCategoryController,
} from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class InventoryItemCategoryModule extends VuexModule {
  private inventoryItemCategoryService: IInventoryItemCategoryController;
  private store: Store<any>;

  constructor(options: any) {
    super(options);
    this.store = options.store;
    this.inventoryItemCategoryService = Container.getInstance().get<IInventoryItemCategoryController>(
      "IInventoryItemCategoryController"
    );
  }

  public categories: IInventoryItemCategoryDTO[] = [];
  public loading: boolean = false;

  @Action
  async getCategories(): Promise<void> {
    this.loading = true;
    try {
      const categories = await this.inventoryItemCategoryService.getCategories();
      this.categories = [...categories]; // Create new array reference
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getCategory(id: number): Promise<IInventoryItemCategoryDTO> {
    this.loading = true;
    try {
      return await this.inventoryItemCategoryService.getCategory(id);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createCategory(category: IPostInventoryItemCategoryDTO): Promise<IInventoryItemCategoryDTO> {
    this.loading = true;
    try {
      const newCategory = await this.inventoryItemCategoryService.createCategory(category);
      await this.getCategories(); // Refresh the list
      return newCategory;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async updateCategory(payload: {
    id: number;
    category: IUpdateInventoryItemCategoryDTO;
  }): Promise<IInventoryItemCategoryDTO> {
    this.loading = true;
    try {
      const updatedCategory = await this.inventoryItemCategoryService.updateCategory(payload.id, payload.category);
      await this.getCategories(); // Refresh the list
      return updatedCategory;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async deleteCategory(id: number): Promise<void> {
    this.loading = true;
    try {
      await this.inventoryItemCategoryService.deleteCategory(id);
      await this.getCategories(); // Refresh the list
    } finally {
      this.loading = false;
    }
  }
}

export const inventoryItemCategoryModule = new InventoryItemCategoryModule({
  store,
  name: "inventoryItemCategoryModule",
});
