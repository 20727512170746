<template>
  <div>
    <!-- Desktop view -->
    <v-simple-table
      v-if="$vuetify.breakpoint.mdAndUp"
      width="100%"
      height="100%"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <slot name="headers"></slot>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <slot name="desktop-row" :item="item" :index="index"></slot>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- Mobile view -->
    <div v-else class="mobile-view">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, index) in items"
          :key="index"
          class="mb-2"
        >
          <v-expansion-panel-header>
            <slot name="mobile-header" :item="item" :index="index">
              <!-- Default mobile header if no slot provided -->
              <div
                class="d-flex align-center justify-space-between"
                style="width: 100%"
              >
                <div>{{ item.title || "Title" }}</div>
              </div>
            </slot>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-card-text>
              <slot name="mobile-content" :item="item" :index="index"></slot>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "responsive-table",
})
export default class ResponsiveTable extends Vue {
  @Prop({ required: true }) items!: any[];
}
</script>

<style scoped>
.mobile-view {
  max-width: 100%;
}
</style>
