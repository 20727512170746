import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

// Default Firebase config for production
const defaultConfig = {
  apiKey: "AIzaSyBQqkT7q1GezrmpiggWDEZPmiVLGIKYc4g",
  authDomain: "timebestiller.firebaseapp.com",
  databaseURL: "https://timebestiller.firebaseio.com",
  projectId: "timebestiller",
  storageBucket: "timebestiller.appspot.com",
  messagingSenderId: "605449339616",
  appId: "1:605449339616:web:40f5469e2d3f05c4",
};

// Try to get Firebase config from environment variables
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FB_API_KEY || defaultConfig.apiKey,
  authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN || defaultConfig.authDomain,
  databaseURL: import.meta.env.VITE_FB_DATABASE_URL || defaultConfig.databaseURL,
  projectId: import.meta.env.VITE_FB_PROJECT_ID || defaultConfig.projectId,
  storageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET || defaultConfig.storageBucket,
  messagingSenderId: import.meta.env.VITE_FB_MESSAGING_SENDER_ID || defaultConfig.messagingSenderId,
  appId: import.meta.env.VITE_FB_APP_ID || defaultConfig.appId,
};

// Initialize Firebase
let app: firebase.app.App;
try {
  app = firebase.initializeApp(firebaseConfig);
} catch (error) {
  if (error.code === "app/duplicate-app") {
    app = firebase.app();
  } else {
    console.error("Firebase initialization error", error);
    throw error;
  }
}

export const auth = app.auth();

// Safely initialize messaging if supported
export const messaging = firebase.messaging.isSupported() 
  ? app.messaging()
  : null;

export default app;
