import { VuexModule, Module, Action } from "vuex-class-modules";
import { UpdateLogService } from "@/services/api/UpdateLogService";
import { IUpdateLogDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class UpdateLogModule extends VuexModule {
  private readonly updateLogService: UpdateLogService;

  constructor(options: any) {
    super(options);
    this.updateLogService = UpdateLogService.getInstance();
  }

  // State
  latestUpdate: IUpdateLogDTO | null = null;

  @Action
  async getCurrentUpdateLogs() {
    const update = await this.updateLogService.getCurrentUpdateLogs();
    this.latestUpdate = update;
    return update;
  }

  @Action
  async postAudit(dto: IUpdateLogDTO) {
    await this.updateLogService.postAudit(dto);
  }
}

export const updateLogModule = new UpdateLogModule({ store, name: "updateLogModule" });
