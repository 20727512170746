import { getCurrentMonth, getWeekNumber } from "@/Utilities/dateUtility";
import {
  appointmentDistribution,
  IAppointmentForm,
  IBookedAppointmentCustomer,
  IBookedAppointments,
  ILatestDeletedAppointment,
  ISimpleDate,
  selectHour,
} from "./appointmentTypings";
import { DialogActions, DialogType, IDialog } from "../dialogModule/dialogModule";
import {
  IAppointmentChangeAsCustomAccount,
  IAppointmentDTO,
  IAppointmentForWeeklyCalenderDTO,
  IAppointmentInfoDTO,
  ICustomerAccountDTO,
  IEditAppointmentDTO,
  IPostAppointmentDTO,
  IPostBookingDTO,
  IPaginatedAppointmentResponseDTO,
  IAppointmentController,
} from "@shared/types";
import { createPatchObject } from "@/Utilities/ObjectUtility";
import { AppointmentService } from "@/services/api/AppointmentService";

import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { i18n } from "@/main";
import store from "@/store/store";

export interface IAppointmentPatch {
  appointmentId: number;
  patch: Partial<IAppointmentDTO>;
}

@Module({ generateMutationSetters: true })
class AppointmentsModule extends VuexModule {
  private readonly appointmentService: AppointmentService;

  constructor(options: any) {
    super(options);
    this.appointmentService = AppointmentService.getInstance();
  }

  // State
  appointments: IAppointmentDTO[] | null = null;
  selectedDate: ISimpleDate = null;
  selectHour: string | null = null;
  appointmentDistribution: appointmentDistribution | null = null;
  bookedAppointments: IBookedAppointments | null = null;
  bookedAppointmentCustomers: IBookedAppointmentCustomer | null = null;
  latestDeletedAppointment: ILatestDeletedAppointment | null = null;
  allAppointments: IAppointmentInfoDTO[] | null = null;
  appointmentsForToday: IAppointmentForWeeklyCalenderDTO[] | null = null;
  events: IAppointmentForWeeklyCalenderDTO[] | null = null;
  appointmentsForOverview: IAppointmentInfoDTO[] | null = null;
  appointmentsPagination: Omit<IPaginatedAppointmentResponseDTO, "items"> | null = null;
  appointmentSource: string = "";
  appointmentForm: IAppointmentForm | null = null;
  defaultCustomerToBook: number | null = null;
  forceDefaultEndTime: string = "";

  @Action
  selectHourToBook(selectedHour: string): void {
    this.bookedAppointmentCustomers = null;
    this.selectHour = selectedHour;
  }

  @Action
  selectDateToBook(selectedDate: ISimpleDate): void {
    this.bookedAppointmentCustomers = null;
    this.selectedDate = selectedDate;
  }

  @Action
  async getAppointmentsForOverview(
    options?: Partial<typeof IAppointmentController.IGetAppointmentsForAdminQuery>
  ): Promise<IPaginatedAppointmentResponseDTO> {
    const response = await this.appointmentService.getAppointmentsForAdmin(options);
    this.appointmentsForOverview = response.items;
    this.appointmentsPagination = {
      total: response.total,
      page: response.page,
      itemsPerPage: response.itemsPerPage,
      sortBy: response.sortBy,
      sortDesc: response.sortDesc,
    };
    return response;
  }

  @Action
  async downloadAppointments() {
    return this.appointmentService.downloadExcelRapport();
  }

  @Action
  async bookAppointment(appointment: IPostAppointmentDTO) {
    await this.appointmentService.postAppointment(appointment);

    //TODO: Should the module be responsible for this?

    if (appointment.month == getCurrentMonth()) {
      // Commented dispatch calls preserved
      // await this.context.dispatch("getMoneyMade", getCurrentMonth(), { root: true });
      // await this.context.dispatch("getAppointmentsDone", getCurrentMonth(), { root: true });
    }
    return appointment;
  }

  @Action
  async checkAppointmentOverlap(appointment: IPostAppointmentDTO) {
    return this.appointmentService.checkAppointmentOverlap(appointment);
  }

  @Action
  async getBookedAppointmentsForMonth(payload: Date) {
    const appointments = await this.appointmentService.getAppointmentsForMonthAdminForCalender(
      payload.getMonth() + 1,
      payload.getFullYear()
    );
    this.events = appointments;
    return appointments;
  }

  @Action
  async getBookedAppointmentsForTwoWeeks(payload: Date) {
    const week = getWeekNumber(payload);
    const appointments = await this.appointmentService.getAppointmentsForWeekAdminForCalender(
      week,
      payload.getFullYear(),
      true
    );
    this.events = appointments;
    return appointments;
  }

  @Action
  async getBookedAppointmentsForWeek(payload: Date) {
    const week = getWeekNumber(payload);
    const appointments = await this.appointmentService.getAppointmentsForWeekAdminForCalender(
      week,
      payload.getFullYear()
    );
    this.events = appointments;
    return appointments;
  }

  @Action
  async getBookedAppointmentsForDay(payload: Date) {
    const appointments = await this.appointmentService.getAppointmentsForAdminForEventCalender(
      payload.getDate(),
      payload.getMonth() + 1,
      payload.getFullYear()
    );
    this.appointmentsForToday = appointments;
    return appointments;
  }

  @Action
  async getAppointmentByNumber(appointmentNumber: number) {
    return this.appointmentService.getAppointmentByNumber(appointmentNumber);
  }

  @Action
  async getAllAppointments() {
    const response = await this.appointmentService.getAppointmentsForAdmin(null);
    this.allAppointments = response.items;
    return response;
  }

  @Action
  async deleteBookedAppointment(appointmentId: number) {
    await this.appointmentService.archiveAppointment(appointmentId);
    await this.getAppointmentsForOverview();
    this.latestDeletedAppointment = {
      latestDeletedAppointment: { appointmentId, appointmentNumber: 0 },
    };
  }

  @Action
  async changeAppointmentAsCustomerAccount(appointment: IAppointmentChangeAsCustomAccount) {
    return this.appointmentService.changeAsCustomerAccount(appointment);
  }

  @Action
  async changeAppointmentStaff(payload: { staffMemberId: number; appointmentId: number }) {
    return this.appointmentService.changeStaffMember(payload.staffMemberId, payload.appointmentId);
  }

  @Action
  async patchAppointment(payload: IAppointmentPatch) {
    const patchDoc = createPatchObject(payload.patch);
    return await this.appointmentService.patchAppointment(patchDoc, payload.appointmentId);
  }

  @Action
  async updateAppointment(appointment: IEditAppointmentDTO) {
    return this.appointmentService.editAppointment(appointment);
  }

  @Action
  async getCustomerAccountAppointments(customerAccount: ICustomerAccountDTO) {
    return this.appointmentService.getCustomerAccountAppointments(customerAccount);
  }

  @Action
  async verifyAppointment(verificationCode: { code: string; userId: string }) {
    return this.appointmentService.verifyAppointment(verificationCode.code, verificationCode.userId);
  }

  @Action
  async getAppointmentsForAdminCustomer(customerId: number) {
    return this.appointmentService.getAppointmentsForAdminCustomer(customerId);
  }

  @Action
  setDefaultCustomerToBook(customerId: number | null) {
    this.defaultCustomerToBook = customerId;
  }

  @Action
  public async getCustomerAppointments(customerId: number): Promise<IAppointmentInfoDTO[]> {
    try {
      const response = await fetch(`/api/Appointment/customer/${customerId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch customer appointments");
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching customer appointments:", error);
      throw error;
    }
  }
}

export const appointmentsModule = new AppointmentsModule({ store, name: "appointmentsModule" });
