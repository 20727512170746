import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { ResourceService } from "@/services/api/ResourceService";
import { IPostResourceDTO, IResourceDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class ResourcesModule extends VuexModule {
  private resourceService = ResourceService.getInstance();

  // State
  resources: IResourceDTO[] = [];

  @Action
  async getResources(): Promise<void> {
    this.resources = await this.resourceService.getResources();
  }

  @Action
  async addResource(resource: IPostResourceDTO): Promise<void> {
    await this.resourceService.addResource(resource);
    await this.getResources();
  }

  @Action
  async editResource(resource: IResourceDTO): Promise<void> {
    await this.resourceService.editResource(resource);
    await this.getResources();
  }

  @Action
  async deleteResource(resourceId: number): Promise<void> {
    await this.resourceService.deleteResource(resourceId);
    await this.getResources();
  }
}

export const resourcesModule = new ResourcesModule({ store, name: "resourcesModule" });
