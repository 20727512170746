<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import HeaderBar from "../../components/global/MainHeader.vue";
import selectCustomer from "../../components/global/CreateAppointment.vue";
import WeekCalendar from "../../components/global/calendar/WeekCalendar.vue";
import { IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";

@Component({
  name: "Calendar-page",
  components: { WeekCalendar, HeaderBar, selectCustomer },
})
export default class CalendarPage extends Vue {
  public async created(): Promise<void> {
    await userModule.getCurrentUser();
  }

  public destroyed() {
    appointmentsModule.selectHourToBook(null);
    appointmentsModule.appointments = null;
  }

  @Watch("latestDeletedAppointment")
  updateAppointmentCustomerViewOnDelete() {
    appointmentsModule.bookedAppointmentCustomers = null;
  }

  get user() {
    return userModule.user;
  }

  get selectHour() {
    return appointmentsModule.selectHour;
  }

  get bookedAppointmentCustomers() {
    return appointmentsModule.bookedAppointmentCustomers;
  }

  get latestDeletedAppointment() {
    return appointmentsModule.latestDeletedAppointment;
  }
}
</script>

<template>
  <div>
    <v-card class="pa-0" flat>
      <WeekCalendar></WeekCalendar>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (max-width: 1000px) {
  .content {
    padding: 21px 100px;
  }
}

@media only screen and (max-width: 1264px) {
  .content {
    padding: 21px 0;
  }
}
</style>
