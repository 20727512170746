<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action, Mutation } from "vuex-class";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { invoicesModule } from "@/store/modules/invoices/invoicesModule";

@Component({
  name: "InvoiceSender",
  components: {},
})
export default class InvoiceSender extends Vue {
  markAsPaid: boolean = true;
  public sendingInvoiceCC: string = "";

  sendInvoiceValid: boolean = true;

  public emailRules = [(v) => !!v || "Epost er påkrevd", (v) => /.+@.+\..+/.test(v) || "Epost må være gyldig"];

  created() {
    this.sendingInvoiceCC = this.user.emailAddress;
  }

  get showSendingInvoice() {
    return invoicesModule.sendingInvoice != null;
  }

  set showSendingInvoice(v) {
    if (v == false) {
      invoicesModule.setSendingInvoice(null);
    }
  }

  cancelSend() {
    this.showSendingInvoice = false;
  }

  get user() {
    return userModule.user;
  }

  get sendingInvoice() {
    return invoicesModule.sendingInvoice;
  }

  async sendInvoiceToEmailAddress() {
    if (!this.sendingInvoice) return;

    let payload = {
      id: this.sendingInvoice.id,
      emailAddress: this.sendingInvoice.customerEmailAddress,
      cc: this.sendingInvoiceCC,
      markAsPaid: this.markAsPaid,
    };

    await invoicesModule.sendInvoice(payload);
    snackbarModule.postSnackbarMessage("Faktura sendt!");
    this.showSendingInvoice = false;
    this.$emit("sent");
  }
}
</script>

<template>
  <v-dialog v-model="showSendingInvoice" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Send faktura</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="sendingInvoice.customerEmailAddress"
                :rules="emailRules"
                label="Send til"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="sendingInvoiceCC" :rules="emailRules" label="CC" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="markAsPaid" label="Marker som betalt"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelSend">Avbryt</v-btn>
        <v-btn color="blue darken-1" text @click="sendInvoiceToEmailAddress">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
