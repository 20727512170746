<template>
  <div>
    <v-subheader>{{ $t($ts.settings.staff) }}</v-subheader>
    <v-divider></v-divider>

    <!-- Overview -->
    <settings-list-item
      :icon="staffOverview.icon"
      :label="staffOverview.label"
      :disabled="staffOverview.disabled"
      :selected="isStaffOverviewSelected"
      @click="showSettingsDialog(staffOverview.route, staffOverview.disabled)"
    />

    <!-- Staff Members -->
    <div v-for="staff in staffMembersForList" :key="staff.id">
      <v-list-group v-model="staff.expanded" no-action activator prepend-icon="mdi-account-supervisor-circle">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> {{ staff.firstName }} {{ staff.lastName }} </v-list-item-title>
          </v-list-item-content>
        </template>

        <settings-list-item
          v-for="subItem in staffSubSettings"
          :key="subItem.id + '-' + staff.id"
          :icon="subItem.icon"
          :label="subItem.label"
          :disabled="subItem.disabled"
          :selected="isStaffSubSettingSelected(staff.id, subItem.route(staff.id))"
          @click="showSettingsDialog(subItem.route(staff.id), subItem.disabled, staff)"
          class="pl-8"
        />
      </v-list-group>
    </div>
  </div>
</template>

<style scoped>
.v-list-group__header.v-list-item--active {
  color: inherit;
}
</style>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SettingsListItem from "./SettingsListItem.vue";
import { IStaffMemberDTO } from "@shared/types";
import VueI18n from "vue-i18n";

interface StaffSubSetting {
  id: number;
  label: VueI18n.TranslateResult;
  icon: string;
  route: (staffId: number) => string;
  disabled: boolean;
}

interface RouteComponent {
  component: string;
  title: string;
}

@Component({
  name: "StaffSettings",
  components: {
    SettingsListItem,
  },
})
export default class StaffSettings extends Vue {
  @Prop({ type: Array, required: true })
  readonly staffMembers!: IStaffMemberDTO[];

  staffMembersForList: (IStaffMemberDTO & { expanded: boolean })[] = [];
  currentStaffId: number | null = null;
  currentSubRoute: string | null = null;

  staffOverview = {
    id: 0,
    label: "",
    icon: "",
    route: "",
    disabled: false,
  };

  staffSubSettings: StaffSubSetting[] = [];
  routeComponentMap: Record<string, RouteComponent> = {};

  @Watch("staffMembers")
  staffMembersChanged(newVal: IStaffMemberDTO[]) {
    this.staffMembersForList = newVal.map((staff) => ({
      ...staff,
      expanded: this.staffMembersForList.find((s) => s.id === staff.id)?.expanded || false,
    }));
  }

  // Dynamic route mapping for staff members
  getStaffRouteComponent(route: string): RouteComponent | undefined {
    // Extract the base route pattern by replacing the staff ID with a placeholder
    const baseRoute = route.replace(/\/\d+\//, "/{id}/");
    const componentMaps: Record<string, RouteComponent> = {
      "/settings/staff/{id}/information": {
        component: "StaffMemberDetailsSettings",
        title: this.$t(this.$ts.settings.information).toString(),
      },
      "/settings/staff/{id}/services": {
        component: "StaffMemberServicesSettings",
        title: this.$t(this.$ts.settings.services).toString(),
      },
      "/settings/staff/{id}/working-hours": {
        component: "WorkingHours",
        title: this.$t(this.$ts.settings.workingHours).toString(),
      },
      "/settings/staff/{id}/friday": {
        component: "VacationDays",
        title: this.$t(this.$ts.settings.friday).toString(),
      },
      "/settings/staff/{id}/google-calendar": {
        component: "StaffMemberGoogleCalendarSettings",
        title: this.$t(this.$ts.settings.googleCalendar).toString(),
      },
      "/settings/staff/{id}/alerts": {
        component: "StaffMemberAlertSettings",
        title: this.$t(this.$ts.settings.alerts).toString(),
      },
    };
    return componentMaps[baseRoute];
  }

  created() {
    this.routeComponentMap = {
      "/settings/staff": {
        component: "StaffMembersOverview",
        title: this.$t(this.$ts.settings.overview).toString(),
      },
    };
    this.staffOverview = {
      id: 13,
      label: this.$t(this.$ts.settings.overview).toString(),
      icon: "mdi-account-supervisor-circle",
      route: "/settings/staff",
      disabled: false,
    };

    this.staffMembersForList = this.staffMembers.map((staff) => ({
      ...staff,
      expanded: false,
    }));

    this.staffSubSettings = [
      {
        id: 14,
        label: this.$t(this.$ts.settings.information),
        icon: "mdi-account-circle",
        route: (staffId: number) => `/settings/staff/${staffId}/information`,
        disabled: false,
      },
      {
        id: 16,
        label: this.$t(this.$ts.settings.services),
        icon: "mdi-star-circle",
        route: (staffId: number) => `/settings/staff/${staffId}/services`,
        disabled: false,
      },
      {
        id: 17,
        label: this.$t(this.$ts.settings.workingHours),
        icon: "mdi-clock-outline",
        route: (staffId: number) => `/settings/staff/${staffId}/working-hours`,
        disabled: false,
      },
      {
        id: 18,
        label: this.$t(this.$ts.settings.friday),
        icon: "mdi-calendar-clock",
        route: (staffId: number) => `/settings/staff/${staffId}/friday`,
        disabled: false,
      },
      {
        id: 19,
        label: this.$t(this.$ts.settings.googleCalendar),
        icon: "mdi-calendar-sync",
        route: (staffId: number) => `/settings/staff/${staffId}/google-calendar`,
        disabled: false,
      },
      {
        id: 20,
        label: this.$t(this.$ts.settings.alerts),
        icon: "mdi-bell-ring",
        route: (staffId: number) => `/settings/staff/${staffId}/alerts`,
        disabled: false,
      },
    ];
  }

  showSettingsDialog(route: string, disabled: boolean, staff?: IStaffMemberDTO) {
    if (!disabled && route) {
      let componentInfo;

      // Check if it's a staff member specific route
      if (route.includes("/staff/") && route.split("/").length > 3) {
        componentInfo = this.getStaffRouteComponent(route);
      } else {
        componentInfo = this.routeComponentMap[route];
      }

      if (componentInfo) {
        if (staff) {
          this.currentStaffId = staff.id;
          this.currentSubRoute = route;
        } else {
          this.currentStaffId = null;
          this.currentSubRoute = route;
        }
        this.$emit("show-settings-dialog", {
          component: componentInfo.component,
          title: componentInfo.title,
          props: staff ? { staffMember: staff } : undefined,
        });
      }
    }
  }

  get isStaffOverviewSelected(): boolean {
    return this.currentSubRoute === "/settings/staff" && !this.currentStaffId;
  }

  isStaffSubSettingSelected(staffId: number, route: string): boolean {
    return this.currentStaffId === staffId && this.currentSubRoute === route;
  }
}
</script>
