<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import ToolTip from "../../../ToolTip.vue";
import { IAuditLogDTO, IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

@Component({
  name: "BookingPageGeneralSettings",
  components: { ToolTip },
})
export default class BookingPageGeneralSettings extends Vue {
  currentAdminInfo: Partial<IUserDTO> = {
    bookingLink: "",
    connectedBookingLink: "",
    bookingLocationName: "",
  };

  setCurrentUser(): void {
    this.currentAdminInfo.bookingLink = userModule.user.bookingLink;
    this.currentAdminInfo.connectedBookingLink = userModule.user.connectedBookingLink;
    this.currentAdminInfo.bookingLocationName = userModule.user.bookingLocationName;
  }

  created() {
    this.setCurrentUser();
  }

  async saveField(field: string, value: string): Promise<void> {
    try {
      // Validate bookingLink length
      if (field === "bookingLink" && value.length < 3) {
        snackbarModule.postSnackbarMessage(
          this.$t(this.$ts.bookingPageGeneralSettings.validation.bookingLinkTooShort).toString()
        );
        // Reset to previous value
        this.currentAdminInfo.bookingLink = userModule.user.bookingLink;
        return;
      }

      // Create audit log based on field
      if (field === "bookingLink" && value !== userModule.user.bookingLink) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t("bookingPageGeneralSettings.auditLogs.changedBookingLink", {
            link: value,
          }).toString(),
          hideForUser: false,
        });
      } else if (field === "connectedBookingLink" && value !== userModule.user.connectedBookingLink) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t("bookingPageGeneralSettings.auditLogs.changedCommonLink", {
            link: value,
          }).toString(),
          hideForUser: false,
        });
      } else if (field === "bookingLocationName" && value !== userModule.user.bookingLocationName) {
        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t("bookingPageGeneralSettings.auditLogs.changedLocationName", {
            name: value,
          }).toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await userModule.updateUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this.currentAdminInfo[field] = userModule.user[field];
    }
  }

  get isOldDomain(): boolean {
    return window.location.toString().includes("vergba");
  }

  get isCitaroDomain(): boolean {
    return window.location.toString().includes("citaro");
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-card flat width="100%" height="100%">
            <v-card-text>
              <h2>{{ $t($ts.link) }}</h2>
            </v-card-text>

            <v-card-text>
              <h3 v-if="isOldDomain">
                {{
                  $t("yourBookingLinkWillBe", {
                    link: "https://booking.vergba-app.no/" + currentAdminInfo.bookingLink,
                  })
                }}
              </h3>
              <h3 v-if="!isOldDomain && !isCitaroDomain">
                {{
                  $t("yourBookingLinkWillBe", {
                    link: "https://booking.availify.no/" + currentAdminInfo.bookingLink,
                  })
                }}
              </h3>

              <h3 v-if="isCitaroDomain">
                {{
                  $t("yourBookingLinkWillBe", {
                    link: "https://booking.citaro.app/" + currentAdminInfo.bookingLink,
                  })
                }}
              </h3>

              <v-text-field
                type="text"
                v-model="currentAdminInfo.bookingLink"
                :label="$t($ts.bookingID)"
                @blur="saveField('bookingLink', currentAdminInfo.bookingLink)"
              ></v-text-field>

              <h3>{{ $t($ts.connectSystemWithCommonBookingLink) }}</h3>
              <h3 v-if="isOldDomain">
                {{
                  $t("yourCommonBookingLinkWillBe", {
                    link: "https://booking.vergba-app.no/velg/" + currentAdminInfo.connectedBookingLink,
                  })
                }}
              </h3>
              <h3 v-if="!isOldDomain">
                {{
                  $t("yourCommonBookingLinkWillBe", {
                    link: "https://booking.availify.no/velg/" + currentAdminInfo.connectedBookingLink,
                  })
                }}
              </h3>

              <v-text-field
                type="text"
                v-model="currentAdminInfo.connectedBookingLink"
                :label="$t($ts.commonID)"
                @blur="saveField('connectedBookingLink', currentAdminInfo.connectedBookingLink)"
              ></v-text-field>

              <h3>{{ $t($ts.locationName) }}</h3>
              <v-text-field
                type="text"
                v-model="currentAdminInfo.bookingLocationName"
                :label="$t($ts.locationName)"
                @blur="saveField('bookingLocationName', currentAdminInfo.bookingLocationName)"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
