import { auth, messaging } from "../firebase";
import { AxiosRequestConfig } from "axios";

export interface AuthConfig {
  headers: {
    Authorization: string;
  };
  credentials: boolean;
  responseType?: string;
}

// Default Firebase config for production (later, we will use the environment variables but we need to edit the yml and github secrets)
let fb_apiKey = "AIzaSyBQqkT7q1GezrmpiggWDEZPmiVLGIKYc4g";
let fb_authDomain = "timebestiller.firebaseapp.com";
let fb_databaseURL = "https://timebestiller.firebaseio.com";
let fb_projectId = "timebestiller";
let fb_storageBucket = "timebestiller.appspot.com";
let fb_messagingSenderId = "605449339616";
let fb_appId = "1:605449339616:web:40f5469e2d3f05c4";

// Try to get Firebase config from environment variables
try {
  // fb_apiKey = import.meta.env.VITE_FB_API_KEY || fb_apiKey;
  // fb_authDomain = import.meta.env.VITE_FB_AUTH_DOMAIN || fb_authDomain;
  // fb_databaseURL = import.meta.env.VITE_FB_DATABASE_URL || fb_databaseURL;
  // fb_projectId = import.meta.env.VITE_FB_PROJECT_ID || fb_projectId;
  // fb_storageBucket = import.meta.env.VITE_FB_STORAGE_BUCKET || fb_storageBucket;
  // fb_messagingSenderId = import.meta.env.VITE_FB_MESSAGING_SENDER_ID || fb_messagingSenderId;
  // fb_appId = import.meta.env.VITE_FB_APP_ID || fb_appId;
} catch (e) {
  if (window.location.hostname !== "localhost") {
    console.warn("Failed to load Firebase config from environment variables");
  }
}

const FB_CONFIG = {
  apiKey: fb_apiKey,
  authDomain: fb_authDomain,
  databaseURL: fb_databaseURL,
  projectId: fb_projectId,
  storageBucket: fb_storageBucket,
  messagingSenderId: fb_messagingSenderId,
  appId: fb_appId,
};

// console.log("FB config: ", FB_CONFIG);

export function getFbConfig() {
  return FB_CONFIG;
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    if (!messaging) {
      console.warn('Firebase messaging is not supported in this browser');
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await messaging.getToken();
      return token;
    } else {
      console.log("Notification permission not granted");
      return null;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return null;
  }
};

export async function getToken() {
  let currentUser = auth.currentUser;
  if (currentUser == null) {
    console.warn("Current user is null");
    await auth.signOut();
    return "NULL";
  }
  let idToken = await currentUser.getIdToken(true);
  return idToken;
}

async function authConfig(): Promise<AxiosRequestConfig> {
  let token = await getToken();
  let authConfig: AxiosRequestConfig = {
    headers: { Authorization: "bearer " + token },
    credentials: true,
  };
  return authConfig;
}

export async function googleAuthConfig(): Promise<AxiosRequestConfig> {
  let token = await getToken();

  let authConfig: AxiosRequestConfig = {
    headers: {
      Authorization: "bearer " + token,
      "Content-Type": "application/octet-stream; charset=utf-8",
      "X-Requested-With": "JXMLHttpRequest",
    },
    credentials: true,
  };
  return authConfig;
}

export function logout(): Promise<void> {
  return auth.signOut();
}

export function isLoggedIn(): boolean {
  return auth.currentUser !== null;
}

export default authConfig;
