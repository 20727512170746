<template>
  <v-dialog v-model="dialogVisible" max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-row align="center" class="mb-4">
          <v-col cols="12" md="6">
            <v-select
              v-model="selectedYear"
              :items="yearOptions"
              style="width: 150px"
              dense
              outlined
              hide-details
              class="header-select mr-4"
              @change="refreshData"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-calendar</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="selectedMonth"
              :items="monthOptions"
              item-value="value"
              item-text="text"
              style="width: 180px"
              dense
              outlined
              hide-details
              class="header-select"
              @change="refreshData"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-calendar-month</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          disable-sort
          disable-pagination
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th>{{ $t($ts.appointment.time) }}</th>
                <th v-for="staff in commissionData" :key="staff.staffMemberId">
                  {{ staff.staffMemberName }}
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ formatDate(item.date) }}</td>
              <td
                v-for="staff in commissionData"
                :key="staff.staffMemberId"
                :class="getCellClass(item.date, staff.dailyCommissions.find(c => c.date === item.date))"
              >
                {{ formatCommissionCell(staff.dailyCommissions.find(c => c.date === item.date)) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#866afe" outlined @click="close">
          {{ $t($ts.buttons.cancel) }}
        </v-btn>
        <v-btn dark color="#866afe" @click="save">
          {{ $t($ts.buttons.save) }}
          <v-icon>save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ICommissionDataDTO, DailyCommissionDTO } from "@shared/types";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "CreateCommissionsDialog",
})
export default class CreateCommissionsDialog extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: String, required: true }) title!: string;

  private selectedYear = new Date().getFullYear();
  private selectedMonth = new Date().getMonth() + 1;
  private commissionData: ICommissionDataDTO[] = [];

  get dialogVisible(): boolean {
    return this.visible;
  }

  set dialogVisible(value: boolean) {
    this.$emit("update:visible", value);
  }

  get yearOptions() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  get monthOptions() {
    return [
      { value: 1, text: this.$t(this.$ts.months.january) },
      { value: 2, text: this.$t(this.$ts.months.february) },
      { value: 3, text: this.$t(this.$ts.months.march) },
      { value: 4, text: this.$t(this.$ts.months.april) },
      { value: 5, text: this.$t(this.$ts.months.may) },
      { value: 6, text: this.$t(this.$ts.months.june) },
      { value: 7, text: this.$t(this.$ts.months.july) },
      { value: 8, text: this.$t(this.$ts.months.august) },
      { value: 9, text: this.$t(this.$ts.months.september) },
      { value: 10, text: this.$t(this.$ts.months.october) },
      { value: 11, text: this.$t(this.$ts.months.november) },
      { value: 12, text: this.$t(this.$ts.months.december) },
    ];
  }

  get tableData() {
    if (!this.commissionData.length) return [];

    const days = [];
    const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      days.push({
        date: new Date(this.selectedYear, this.selectedMonth - 1, day).toISOString(),
      });
    }

    return days;
  }

  get tableHeaders() {
    if (!this.commissionData.length) return [];

    return [
      { text: this.$t(this.$ts.appointment.time), value: "date" },
      ...this.commissionData.map((staff) => ({
        text: staff.staffMemberName,
        value: `staff_${staff.staffMemberId}`,
      })),
    ];
  }

  async created() {
    await this.refreshData();
  }

  async refreshData() {
    this.commissionData = await staffMemberModule.getStaffMemberCommissions({
      year: this.selectedYear,
      month: this.selectedMonth,
    });
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString(this.$i18n.locale);
  }

  formatCommissionCell(commission?: DailyCommissionDTO) {
    if (!commission) return "0 / 0";
    return `${this.formatCurrency(commission.paidCommission)} / ${this.formatCurrency(commission.totalCommission)}`;
  }

  formatCurrency(amount: number) {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(amount);
  }

  getCellClass(dateStr: string, commission?: DailyCommissionDTO) {
    if (!commission) return "";

    const date = new Date(dateStr);
    const now = new Date();
    const isPast = date < now;

    if (commission.paidCommission === 0 && isPast) {
      return "bg-red-lighten-4";
    }

    if (commission.paidCommission === commission.totalCommission) {
      return "bg-green-lighten-4";
    }

    if (commission.paidCommission > 0 && commission.paidCommission !== commission.totalCommission) {
      return "bg-yellow-lighten-4";
    }

    return "";
  }

  close() {
    this.dialogVisible = false;
  }

  save() {
    // Here you would implement the logic to save any changes
    this.close();
  }
}
</script>

<style scoped>
.v-data-table ::v-deep tbody tr:hover {
  background-color: transparent !important;
}

.bg-red-lighten-4 {
  background-color: #ffcdd2 !important;
}

.bg-yellow-lighten-4 {
  background-color: #fff9c4 !important;
}

.bg-green-lighten-4 {
  background-color: #c8e6c9 !important;
}
</style> 