import { BaseService } from "./Base/BaseService";
import { IFirebaseUserController, IFirebaseUserDTO } from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";

export class FirebaseUserService extends BaseService implements IFirebaseUserController {
  private static instance: FirebaseUserService;
  private controller = "FirebaseUser"; // Matches backend route api/FirebaseUser

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!FirebaseUserService.instance) {
      FirebaseUserService.instance = new FirebaseUserService();
    }
    return FirebaseUserService.instance;
  }

  public async getCurrentFirebaseUser(): Promise<IFirebaseUserDTO> {
    return this.http.getAuthenticated<IFirebaseUserDTO>(`${this.controller}/current`);
  }

  public async postFirebaseAccountAsync(staffMember: any): Promise<void> {
    return this.http.postAuthenticated<void>(`${this.controller}/current`, staffMember);
  }

  public async patchFirebaseUser(patchDoc: JsonPatchOperation[], firebaseUserId: string): Promise<void> {
    return this.http.patchAuthenticated<void>(`${this.controller}/current?firebaseUserId=${firebaseUserId}`, patchDoc);
  }

  public async deleteFirebaseUser(firebaseUserId: string): Promise<void> {
    return this.http.deleteAuthenticated<void>(`${this.controller}/current/${firebaseUserId}`);
  }

  public async getAll(): Promise<IFirebaseUserDTO[]> {
    return this.http.getAuthenticated<IFirebaseUserDTO[]>(`${this.controller}/current/all`);
  }
}
