<script lang="ts">
// @ts-nocheck
import { Doughnut } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  extends: Doughnut,
})
export default class ProductsChart extends Vue {
  @Prop({ required: true }) chartData!: any;
  @Prop() options!: any;

  public mounted(): void {
    this.renderChart(this.chartData, this.options);
  }
}
</script>
