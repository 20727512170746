<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action, Mutation } from "vuex-class";
import { formatDate, FormatSpaceType } from "../types/formatDateHelper";
import { Prop } from "vue-property-decorator";
import { IDialog } from "@/store/dialog";
import { AppointmentInfo } from "../store/appointments/appointmentTypings";
import InvoiceSender from "./InvoiceSender.vue";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

import { invoicesModule } from "@/store/modules/invoices/invoicesModule";
import { IInvoiceDTO } from "@shared/types";

@Component({
  name: "InvoicesList",
  components: { InvoiceSender },
})
export default class InvoicesList extends Vue {
  @Prop({ default: null }) appointment: AppointmentInfo;
  @Prop({ default: null }) customerId: number;

  @Prop({ default: [] }) invoices: Array<IInvoiceDTO>;

  @Prop({ default: false }) public isLoading: boolean;

  search = "";

  set headers(e) {}
  get headers() {
    let array = [
      {
        text: this.$t(this.$ts.invoiceList.invoiceNumber),
        align: "start",
        sortable: true,
        value: "invoiceNumber",
      },
      {
        text: this.$t(this.$ts.invoiceList.orderNumber),
        align: "start",
        sortable: true,
        value: "appointmentNumber",
      },
      {
        text: this.$t(this.$ts.invoiceList.amount),
        align: "start",
        sortable: true,
        value: "chargeAmount",
      },
      {
        text: this.$t(this.$ts.invoiceList.customer),
        sortable: true,
        value: "customerName",
      },
      {
        text: this.$t(this.$ts.invoiceList.invoiceDate),
        sortable: true,
        value: "date",
      },
      {
        text: this.$t(this.$ts.invoiceList.dueDate),
        sortable: true,
        value: "dueDate",
      },
      {
        text: this.$t(this.$ts.invoiceList.status),
        sortable: true,
        value: "status",
      },
      {
        text: this.$t(this.$ts.invoiceList.download),
        sortable: true,
        value: "download",
      },
      {
        text: this.$t(this.$ts.invoiceList.send),
        sortable: true,
        value: "send",
      },
    ];

    if (this.user.useKID) {
      let kidheader = {
        text: this.$t(this.$ts.invoiceList.kid),
        sortable: true,
        value: "kid",
      };
      array.splice(3, 0, kidheader);
    }
    return array;
  }

  invoiceStatuses = [
    this.$t(this.$ts.invoiceList.statuses.new),
    this.$t(this.$ts.invoiceList.statuses.sent),
    this.$t(this.$ts.invoiceList.statuses.paid),
  ];

  public statusFilters = [
    this.$t(this.$ts.invoiceList.statuses.new),
    this.$t(this.$ts.invoiceList.statuses.sent),
    this.$t(this.$ts.invoiceList.statuses.paid),
  ];

  async sendInvoice(invoice: IInvoiceDTO) {
    invoicesModule.setSendingInvoice(invoice);
  }

  async onSent() {
    await this.loadInvoices();
  }

  async downloadAsPdf(invoice: IInvoiceDTO) {
    let data = await invoicesModule.getPdf(invoice.id);
    //@ts-ignore
    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async loadInvoices() {
    let vm = this;
    if (vm.appointment == null && vm.customerId == null) {
      vm.$emit("update");
    } else if (vm.appointment != null) {
      await invoicesModule.getInvoicesForAppointment(vm.appointment.id);
    }
  }
  async created() {
    try {
      await this.loadInvoices();
    } catch (e) {
      let dialog: IDialog = {
        text: "Kunne ikke hente faktura",
      };
      dialogModule.addToDialogQueue(dialog);
    }
  }

  async updateInvoiceStatus(event, invoice) {
    await invoicesModule.changeStatus({ invoiceId: invoice.id, status: event });
    await this.loadInvoices();
  }

  get invoicesForDataTable() {
    let returnValue: Array<IInvoiceDTO> = this.invoices;
    return returnValue.filter((x) => {
      return this.statusFilters.includes(x.status);
    });
  }

  get hasInvoices() {
    return this.invoices.length > 0 || !this.isLoading;
  }

  getColor(eventId) {
    if (eventId == "Ny") return "#E0E0E0";
    if (eventId == "Sendt") return "#a5c6d9";
    if (eventId == "Betalt") return "#a5d9af";
    return "grey";
  }

  formatDateDot(date: Date) {
    if (date == null) {
      return "";
    }

    //@ts-ignore
    let toDateObject = new Date(date as string);
    return formatDate(toDateObject, FormatSpaceType.DOT);
  }

  get hasSelectedAllFilters() {
    return this.statusFilters.length == this.invoiceStatuses.length;
  }

  get user() {
    return userModule.user;
  }
}
</script>

<template>
  <div>
    <invoice-sender @sent="onSent"></invoice-sender>

    <v-card flat>
      <v-row>
        <v-col>
          <slot></slot>
        </v-col>
        <v-col cols="auto">
          <v-select
            max-width="300"
            class="mt-3"
            dense
            chips
            attach
            multiple
            label="Filter"
            v-model="statusFilters"
            :items="invoiceStatuses"
          >
            <template #selection="data">
              <span v-if="hasSelectedAllFilters && data.item === 'Betalt'">{{ $t($ts.invoiceList.all) }}</span>
              <v-chip v-else :color="getColor(data.item)">
                <span>{{ data.item }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t($ts.invoiceList.search)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        v-if="hasInvoices"
        :headers="headers"
        :items="invoicesForDataTable"
        :items-per-page="10"
        :single-select="false"
        :sort-desc="false"
        :search="search"
        :loading="isLoading"
        :loading-text="$t($ts.invoiceList.loading)"
      >
        <template #no-data>{{ $t($ts.invoiceList.noInvoices) }}</template>

        <template #[`item.appointmentNumber`]="{ item }">
          <router-link v-if="item.appointmentNumber !== 0" :to="'/bestillinger/' + item.appointmentNumber">{{
            item.appointmentNumber
          }}</router-link>
          <span v-else></span>
        </template>

        <template v-if="user.useKID" #[`item.kid`]="{ item }">
          <span v-if="item.kid != null && item.kid.length > 0">{{ item.kid }}</span>
          <span v-else></span>
        </template>

        <template #[`item.status`]="{ item }">
          <v-select
            dense
            v-if="item != null && item.status != null"
            :color="getColor(item.status)"
            chips
            attach
            v-model="item.status"
            :items="invoiceStatuses"
            @change="updateInvoiceStatus($event, item)"
          >
            <template #selection="data">
              <v-icon v-if="data.item === 'Ny'">mdi-plus</v-icon>
              <v-icon color="#A5C6D9" v-if="data.item === 'Sendt'">send</v-icon>
              <v-icon color="success" v-if="data.item === 'Betalt'">done</v-icon>
              <span>{{ data.item }}</span>
            </template>
          </v-select>
        </template>

        <template #[`item.download`]="{ item }">
          <v-btn icon @click="downloadAsPdf(item)"><v-icon>picture_as_pdf</v-icon></v-btn>
        </template>

        <template #[`item.send`]="{ item }">
          <v-btn icon @click="sendInvoice(item)"><v-icon>send</v-icon></v-btn>
        </template>

        <template #[`item.customerName`]="{ item }">
          <router-link
            v-if="item.customerId != null && item.customerId !== -1 && item.customerId !== 0"
            :to="'/kunder/' + item.customerId"
            >{{ item.customerName }}</router-link
          >
          <span v-else>{{ item.customerName }}</span>
        </template>

        <template #items="props">
          <td class="text-xs-left">{{ props.item.customerAddress }}</td>
          <td class="text-xs-left">{{ props.item.customerEmailAddress }}</td>
          <td class="text-xs-left">{{ props.item.customerPhoneNumber }}</td>
          <td class="text-xs-left">{{ props.item.customerPostalCode }}</td>
          <td class="text-xs-left">{{ props.item.customerCity }}</td>
        </template>

        <template #[`item.date`]="{ item }">
          <span>{{ formatDateDot(item.date) }}</span>
        </template>

        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDateDot(item.dueDate) }}</span>
        </template>
      </v-data-table>

      <v-data-table v-else loading> </v-data-table>
    </v-card>
  </div>
</template>

<style scoped></style>
