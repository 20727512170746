<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import Loader from "@/components/global/Loader.vue";
import StripePayment from "./StripePayment.vue";
import { DialogType, IDialog } from "@/store/dialog";
import { ISubscriptionStatus, Subscription } from "@/types/HasSubscription";
import { formatDate } from "@/types/formatDateHelper";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { subscriptionModule } from "@/store/modules/subscription/subscriptionModule";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "checkout",
  components: { StripePayment, Loader, CustomButton },
})
export default class Checkout extends Vue {
  isLoading: boolean = false;
  isShowingCancelModal: boolean = false;
  checkout: boolean = false;

  public amount: Number = 25000;
  public description: String = "Abonnement / 250 mnd";

  triggerCancelSubscriptionModal(): void {
    this.isShowingCancelModal = true;
    let dialog: IDialog = {
      text: this.$t(this.$ts.subscription.cancelSubscriptionConfirm).toString(),
      header: this.$t(this.$ts.subscription.subscription).toString(),
      type: DialogType.Choice,
      action: this.cancelSubscription,
    };
    dialogModule.addToDialogQueue(dialog);
  }

  async cancelSubscription(): Promise<any> {
    this.isLoading = true;
    await subscriptionModule.cancelSubscription(true);
    await subscriptionModule.getSubscriptionStatus();
    this.isLoading = false;
    this.isShowingCancelModal = false;
  }
  async continueSubscription(): Promise<any> {
    this.isLoading = true;
    await subscriptionModule.cancelSubscription(false);
    await subscriptionModule.getSubscriptionStatus();
    this.isLoading = false;
  }

  get hasActiveSubscription(): boolean {
    return subscriptionModule.subscriptionStatus.status === Subscription.Active;
  }
  get hasCanceledSubscriptionPlan(): boolean {
    return subscriptionModule.subscriptionStatus.status === Subscription.CancelNextPayment;
  }

  get hasTrialPlan(): boolean {
    return subscriptionModule.subscriptionStatus.status === Subscription.Trial;
  }

  get hasPaymentIssues(): boolean {
    return (
      subscriptionModule.subscriptionStatus.status === Subscription.Expired ||
      subscriptionModule.subscriptionStatus.status === Subscription.Unpaid ||
      subscriptionModule.subscriptionStatus.status === Subscription.PastDue ||
      subscriptionModule.subscriptionStatus.status === Subscription.IncompleteExpired ||
      subscriptionModule.subscriptionStatus.status === Subscription.Incomplete
    );
  }

  get hasNoSubscription() {
    return subscriptionModule.subscriptionStatus.status == Subscription.None;
  }

  get getYearlyFinish() {
    let d = new Date(this.user.lastYearlySubscription);

    console.log("D: ", d);

    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 1, month, day);

    return formatDate(c);
  }
  async created() {
    await subscriptionModule.getSubscriptionStatus();
    await subscriptionModule.getSubscriptionPlan();
  }

  async recievedPaymentToken(token) {
    await subscriptionModule.createSubscription(token);
  }

  async recievedFormData(subscriptionFormData) {
    await subscriptionModule.createSubscription(subscriptionFormData);
    this.isLoading = true;
    await subscriptionModule.getSubscriptionStatus();
    await subscriptionModule.getSubscriptionPlan();
    this.isLoading = false;
    this.checkout = false;
  }

  get subscriptionStatus() {
    return subscriptionModule.subscriptionStatus;
  }
  get subscriptionExpirationDate() {
    return subscriptionModule.subscriptionPlan.nextPayment; //TODO: Find this out.
  }

  get user() {
    return userModule.user;
  }
}
</script>

<template>
  <div>
    <v-alert
      v-if="hasNoSubscription && user.lastYearlySubscription == null"
      dense
      border="left"
      type="error"
      icon="mdi-alert-octagon"
    >
      {{ $t($ts.subscription.noTrial) }}
    </v-alert>

    <v-alert v-if="hasPaymentIssues" dense border="left" type="error" icon="mdi-alert-octagon">
      {{ $t($ts.subscription.paymentIssue) }}
    </v-alert>

    <v-alert v-if="hasTrialPlan" dense border="left" type="info" icon="mdi-alert-circle-outline">
      {{
        $t("subscription.trialDaysLeft", {
          days: this.subscriptionStatus.daysLeftOfTrial,
        })
      }}
    </v-alert>

    <v-alert
      v-if="hasCanceledSubscriptionPlan && subscriptionExpirationDate.length > 5"
      dense
      border="left"
      type="info"
      icon="mdi-alert-circle-outline"
    >
      {{
        $t("subscription.cancelledWithDate", {
          date: subscriptionExpirationDate,
        })
      }}
    </v-alert>

    <v-alert
      v-if="hasCanceledSubscriptionPlan && subscriptionExpirationDate.length <= 5"
      dense
      border="left"
      type="info"
      icon="mdi-alert-circle-outline"
    >
      {{ $t($ts.subscription.cancelledWithoutDate) }}
    </v-alert>

    <v-alert
      v-if="hasActiveSubscription || user.lastYearlySubscription != null"
      dense
      border="left"
      type="success"
      color="green"
      icon="mdi-check-circle"
    >
      {{ $t($ts.subscription.active) }}
    </v-alert>

    <stripe-payment
      v-if="checkout"
      @closed="checkout = false"
      @recieveformdata="recievedFormData"
      @recievepaymenttoken="recievedPaymentToken"
      :description="description"
      :amount="amount"
    >
    </stripe-payment>

    <!-- Purchase a subscription -->
    <loader v-if="isLoading"></loader>

    <v-card-text v-if="user.lastYearlySubscription != null">
      {{ $t("subscription.yearlyWithSMS", { date: getYearlyFinish }) }}
    </v-card-text>
    <v-card-text v-if="user.lastYearlySubscription == null">
      <p>{{ $t($ts.subscription.monthlyCost) }}</p>
      <p>{{ $t($ts.subscription.automaticPayment) }}</p>
      <p style="font-weight: bold">{{ $t($ts.subscription.smsCost) }}</p>

      <v-btn
        color="success"
        @click="checkout = true"
        v-if="!hasActiveSubscription && !hasCanceledSubscriptionPlan && !isLoading"
      >
        {{ $t($ts.subscription.purchase) }}
      </v-btn>

      <!-- Continue subscription -->
      <v-btn color="success" @click="continueSubscription" v-if="hasCanceledSubscriptionPlan && !isLoading">
        {{ $t($ts.subscription.reactivate) }}
      </v-btn>

      <!--  Cancel subscription subscription -->
      <v-btn @click="triggerCancelSubscriptionModal" v-if="hasActiveSubscription && !isLoading">
        {{ $t($ts.subscription.cancel) }}
      </v-btn>
    </v-card-text>

    <div></div>
  </div>
</template>

<style lang="scss" scoped>
.application--wrap {
  position: absolute;
}
.v-card__actions {
  padding: 43px 3px 15px;
}
.v-card {
  padding: 8px 22px;
}
.v-card__title {
  padding: 16px 0;
}
.form-wrapper {
  p {
    margin-left: 0 !important;
  }
}
</style>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule
