<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" md="4">
        <v-card outlined class="pa-4 payment-status-card">
          <div class="d-flex align-center mb-4">
            <div class="section-title">
              <v-icon small color="primary" class="mr-2">mdi-bank</v-icon>
              {{ $t($ts.orders.sections.financialDetails) }}
            </div>
          </div>
          <money-account-selector
            v-model="selectedMoneyAccountId"
            data-cy="money-account-select"
            class="payment-method-field"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card outlined class="pa-4 payment-status-card">
          <div class="d-flex align-center mb-4">
            <div class="section-title">
              <v-icon small color="primary" class="mr-2">mdi-cash-register</v-icon>
              {{ $t($ts.orders.labels.paymentStatus) }}
            </div>
          </div>

          <v-radio-group
            v-model="paymentStatus"
            column
          >
            <v-radio
              v-for="option in paymentStatusOptions"
              :key="option.value"
              :value="option.value"
              :disabled="isDropInCustomer && option.value !== PaymentStatus.Paid"
              :label="option.text"
              :color="option.value === PaymentStatus.Paid ? 'success' : option.value === PaymentStatus.PartiallyPaid ? 'warning' : 'error'"
            >
            </v-radio>
          </v-radio-group>

          <v-slide-y-transition>
            <v-text-field
              v-if="paymentStatus === PaymentStatus.PartiallyPaid"
              v-model.number="partialPaymentAmount"
              :label="$t($ts.orders.partialAmount)"
              type="number"
              outlined
              dense
              class="mt-4"
              :min="0"
              :max="totalAmount"
              :rules="[
                (v) => v > 0 || $t($ts.orders.validation.amountGreaterThanZero),
                (v) => v < totalAmount || $t($ts.orders.validation.amountLessThanTotal),
              ]"
              :hint="`${$t($ts.orders.maximumAmount)} ${formatCurrency(totalAmount)}`"
              persistent-hint
              prepend-icon="mdi-cash-multiple"
              data-cy="partial-payment-amount"
            />
          </v-slide-y-transition>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card outlined class="pa-4">
          <div class="order-summary-content">
            <!-- Order Details Column -->
            <div class="order-summary-section mb-6">
              <div class="section-title mb-3">
                <v-icon small color="primary" class="mr-2">mdi-information</v-icon>
                {{ $t($ts.orders.sections.orderDetails) }}
              </div>
              <div class="summary-row">
                <span class="label">{{ $t($ts.orders.labels.customer) }}</span>
                <span class="value">{{ customerName }}</span>
              </div>
              <div class="summary-row">
                <span class="label">{{ $t($ts.orders.labels.orderDate) }}</span>
                <span class="value">{{ formatDate(orderDate) }}</span>
              </div>
            </div>

            <!-- Financial Details Column -->
            <div class="order-summary-section">
              <div class="section-title mb-3">
                <v-icon small color="primary" class="mr-2">mdi-cash-multiple</v-icon>
                {{ $t($ts.orders.sections.financialDetails) }}
              </div>
              <div class="summary-row">
                <span class="label">{{ $t($ts.orders.labels.totalAmount) }}</span>
                <span class="value font-weight-bold primary--text">
                  {{ formatCurrency(totalAmount) }}
                </span>
              </div>
              <template v-if="paymentStatus === PaymentStatus.PartiallyPaid">
                <div class="summary-row">
                  <span class="label">{{ $t($ts.orders.labels.partialPayment) }}</span>
                  <span class="value partial-payment-text">{{ formatCurrency(partialPaymentAmount) }}</span>
                </div>
                <div class="summary-row">
                  <span class="label">{{ $t($ts.orders.labels.remainingBalance) }}</span>
                  <span class="value error--text">
                    {{ formatCurrency(totalAmount - partialPaymentAmount) }}
                  </span>
                </div>
              </template>
              <div v-if="paymentStatus === PaymentStatus.Unpaid" class="summary-row">
                <span class="label">{{ $t($ts.orders.labels.paymentStatus) }}</span>
                <span class="value error--text">
                  <v-icon small color="error" class="mr-1">mdi-close-circle</v-icon>
                  {{ $t($ts.orders.paymentStatus.unpaid) }}
                </span>
              </div>
              <div v-if="paymentStatus === PaymentStatus.Paid" class="summary-row">
                <span class="label">{{ $t($ts.orders.labels.paymentStatus) }}</span>
                <span class="value paid-status-text">
                  <v-icon small color="success" class="mr-1">mdi-check-circle</v-icon>
                  {{ $t($ts.orders.paymentStatus.paidInFull) }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PaymentMethod, LedgerEntryType } from "@shared/types";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { userModule } from "@/store/modules/user/userModule";
import MoneyAccountSelector from "./MoneyAccountSelector.vue";
import { CurrencyFormatter } from "@/Utilities/CurrencyFormatter";

enum PaymentStatus {
  Paid = "paid",
  PartiallyPaid = "partially_paid",
  Unpaid = "unpaid",
}

@Component({
  name: "payment-handler",
  components: {
    MoneyAccountSelector,
  },
})
export default class PaymentHandler extends Vue {
  @Prop({ required: true }) readonly totalAmount!: number;
  @Prop({ required: true }) readonly customerId!: number;
  @Prop({ required: false }) readonly sourceId?: number;
  @Prop({ required: false }) readonly sourceType?: number;
  @Prop({ required: false }) readonly referenceNumber?: string;
  @Prop({ required: false, default: () => new Date().toISOString().substr(0, 10) }) readonly orderDate!: string;
  @Prop({ required: false, default: true }) readonly createLedgerEntries!: boolean;

  private selectedMoneyAccountId: number | null = null;
  private paymentStatus: PaymentStatus = PaymentStatus.Paid;
  private partialPaymentAmount: number = 0;
  private isProcessing = false;
  private containerWidth = 0;
  private resizeObserver: ResizeObserver | null = null;

  readonly PaymentStatus = PaymentStatus;

  @Watch('customerId', { immediate: true })
  onCustomerIdChange(newCustomerId: number) {
    // If the customer is a drop-in customer (id 0, -1, or 1), force payment status to Paid
    if (newCustomerId === 0 || newCustomerId === -1 || newCustomerId === 1) {
      this.paymentStatus = PaymentStatus.Paid;
    }
  }

  get isCompact(): boolean {
    return this.containerWidth < 600;
  }

  get isDropInCustomer(): boolean {
    return this.customerId === -1 || this.customerId === 0 || this.customerId === -1 || this.customerId === 1;
  }

  mounted() {
    this.setupContainerObserver();
  }

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  setupContainerObserver() {
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.containerWidth = entry.contentRect.width;
        }
      });

      const element = this.$el as HTMLElement;
      if (element) {
        this.resizeObserver.observe(element);
      }
    }
  }

  get customerName(): string {
    const customer = customersModule.customers.find((c) => c.id === this.customerId);
    if (!customer) return String(this.$t(this.$ts.orders.unknownCustomer));
    return `${customer.firstName} ${customer.lastName || ""}`.trim();
  }

  formatDate(date: string): string {
    return new Date(date).toLocaleDateString(this.$i18n.locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  get paymentStatusOptions() {
    return [
      { 
        text: this.$t(this.$ts.orders.paymentStatus.paidInFull), 
        value: PaymentStatus.Paid 
      },
      { 
        text: this.$t(this.$ts.orders.partialPayment), 
        value: PaymentStatus.PartiallyPaid 
      },
      { 
        text: this.$t(this.$ts.orders.paymentStatus.unpaid), 
        value: PaymentStatus.Unpaid 
      },
    ];
  }

  get isValid() {
    if (!this.selectedMoneyAccountId) return false;

    switch (this.paymentStatus) {
      case PaymentStatus.Paid:
        return true;
      case PaymentStatus.PartiallyPaid:
        return this.partialPaymentAmount > 0 && this.partialPaymentAmount < this.totalAmount;
      case PaymentStatus.Unpaid:
        return true;
      default:
        return false;
    }
  }

  getPaymentInfo() {
    return {
      paymentStatus: this.paymentStatus,
      partialPaymentAmount: this.paymentStatus === PaymentStatus.PartiallyPaid ? this.partialPaymentAmount : null,
      moneyAccountId: this.selectedMoneyAccountId,
    };
  }

  formatCurrency(value: number): string {
    return CurrencyFormatter.format(value, this.$i18n.locale);
  }

  async processPayment(orderId?: number) {

    const result = {
        moneyAccountId: this.selectedMoneyAccountId,
        paymentStatus: this.paymentStatus,
        partialPaymentAmount: this.partialPaymentAmount,
      };

      console.log("Payment result: in side", result);

      if(!this.createLedgerEntries){
        return result;
      }



    if (!this.isValid || this.isProcessing) return;

    this.isProcessing = true;
    try {
     
   
      const sourceId = orderId || this.sourceId || 0;

 

      if(this.createLedgerEntries){
        await ledgerEntriesModule.createManualLedgerEntry({
        id: 0,
        amount: this.totalAmount,
        customerId: this.customerId,
        description: this.referenceNumber || "",
        sourceId: sourceId,
        sourceType: this.sourceType || 1,
        status: 0,
        type: LedgerEntryType.Charge,
      });

      }//Create the charge entry for the customer regardless
     

      
      // Create the payment ledger entries based on payment status
      switch (this.paymentStatus) {
        case PaymentStatus.Paid:
          // Create a full payment ledger entry
          if(this.createLedgerEntries){
            await ledgerEntriesModule.createManualLedgerEntry({
            id: 0,
            amount: this.totalAmount,
            customerId: this.customerId,
            description: this.referenceNumber || "",
            sourceId: sourceId,
            sourceType: this.sourceType || 1,
            status: 0,
            type: LedgerEntryType.Payment,
            moneyAccountId: this.selectedMoneyAccountId,
          });

          }
          
          break;

        case PaymentStatus.PartiallyPaid:
          if (this.partialPaymentAmount > 0 && this.createLedgerEntries) {
            // Then create the partial payment entry
            await ledgerEntriesModule.createManualLedgerEntry({
              id: 0,
              amount: this.partialPaymentAmount,
              customerId: this.customerId,
              dateTime: null,
              description: this.referenceNumber || "",
              sourceId: sourceId,
              sourceType: this.sourceType || 1,
              status: 0,
              type: LedgerEntryType.Payment,
              moneyAccountId: this.selectedMoneyAccountId,
            });
          }
          break;

        case PaymentStatus.Unpaid:
          // Dont create a payment entry for the unpaid status
          break;
      }

      this.$emit("payment-processed", result);
    } catch (error) {
      console.error("Failed to process payment:", error);
      this.$emit("payment-error", error);
    } finally {
      this.isProcessing = false;
    }
  }

  reset() {
    this.selectedMoneyAccountId = null;
    this.paymentStatus = PaymentStatus.Paid;
    this.partialPaymentAmount = 0;
    this.isProcessing = false;
  }
}
</script>

<style lang="scss" scoped>
.payment-handler {
  width: 100%;
  transition: all 0.3s ease;

  &--compact {
    .payment-method-field {
      max-width: 100%;
    }
  }
}

.payment-method-field {
  max-width: 250px;
  transition: max-width 0.3s ease;
}

.v-radio-group {
  margin-bottom: 16px;
}

.payment-status-card {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}

.order-summary-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

.order-summary-section {
  .section-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--v-primary-base);
    padding-bottom: 8px;
    border-bottom: 2px solid var(--v-primary-base);
  }
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }

  .label {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }

  .value {
    min-width: 200px;
    text-align: right;
    font-weight: 500;
  }
}

.paid-status-text {
  color: #2e7d32 !important;
  font-weight: 600;
}

.partial-payment-text {
  color: #f57c00 !important;
  font-weight: 600;
}
</style> 