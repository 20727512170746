<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { MessageType } from "@/types/messageBarTypes";

@Component({
  name: "Update-message",
  components: { CustomButton },
})
export default class UpdateMessage extends Vue {
  @Prop({ default: MessageType.UPDATE }) messageType: MessageType | undefined;
  @Prop({ default: "text" }) message: string | undefined;

  updateCache(): void {
    localStorage.removeItem("hasUpdate");
    navigator.serviceWorker.ready.then((res) => {
      res.waiting.postMessage({ action: "skipWaiting" });
    });
    window.location.reload();
  }

  purchaseSubscription(): void {
    this.$router.push("/settings/5").catch(() => {});
  }
}
</script>

<template>
  <div
    class="update-wrapper"
    :class="{ alert: messageType === MessageType.PURCHASE }"
  >
    <div class="update-content">
      <p>{{ message }}</p>
      <custom-button
        v-if="messageType === MessageType.UPDATE"
        @custom-button-click="updateCache"
        :buttonText="'Oppdater'"
      ></custom-button>
      <custom-button
        :isFlat="true"
        v-if="messageType === MessageType.PURCHASE"
        @custom-button-click="purchaseSubscription"
        :buttonText="'Gå til abonnement'"
      >
      </custom-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.update-wrapper {
  top: 0;
  width: 100%;
  min-height: auto;
  padding: 10px;
  text-align: center;
  background: #ececf3;
  position: fixed;
  z-index: 99;
  border-bottom: 1px solid #ccc;
  .update-content {
    display: flex;
    justify-content: center;
    button {
      display: inline-block;
    }
    p {
      display: inline-block;
      margin-right: 20px;
      margin-top: 8px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 1264px) {
}
</style>
