import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { ExpensesService } from "@/services/api/ExpensesService";
import {
  IPostRecurringExpenseDTO,
  IPostOneTimeExpenseDTO,
  IRecurringExpenseDTO,
  IOneTimeExpenseDTO,
} from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class ExpensesModule extends VuexModule {
  private expensesService = ExpensesService.getInstance();

  // State
  oneTimeExpenses: IOneTimeExpenseDTO[] = [];
  recurringExpenses: IRecurringExpenseDTO[] = [];

  @Action
  async getOneTimeExpense(id: number): Promise<IOneTimeExpenseDTO> {
    return await this.expensesService.getSingleOneTime(id);
  }

  @Action
  async getRecurringExpense(id: number): Promise<IRecurringExpenseDTO> {
    return await this.expensesService.getSingleRecurring(id);
  }

  @Action
  async getRecurringExpenses(): Promise<IRecurringExpenseDTO[]> {
    const data = await this.expensesService.getAllRecurring();
    this.recurringExpenses = data;
    return data;
  }

  @Action
  async getOneTimeExpenses(): Promise<IOneTimeExpenseDTO[]> {
    try {
      const data = await this.expensesService.getAllOneTime();
      this.oneTimeExpenses = data || [];
      return this.oneTimeExpenses;
    } catch (error) {
      console.error("Failed to fetch one-time expenses:", error);
      return [];
    }
  }

  @Action
  async createRecurringExpense(dto: IPostRecurringExpenseDTO): Promise<IRecurringExpenseDTO> {
    const expense = await this.expensesService.createRecurring(dto);
    await this.getRecurringExpenses();
    await this.getRecurringExpenses();
    return expense;
  }

  @Action
  async createOneTimeExpense(dto: IPostOneTimeExpenseDTO): Promise<IOneTimeExpenseDTO> {
    console.log("Creatging one time..");
    const expense = await this.expensesService.createOneTime(dto);
    console.log("One time created");
    console.log("Getting one time expenses..");

    await this.getOneTimeExpenses();
    await this.getRecurringExpenses();
    return expense;
  }

  @Action
  async deleteOneTimeExpense(id: number): Promise<void> {
    await this.expensesService.deleteOneTime(id);
    await this.getOneTimeExpenses();
  }

  @Action
  async deleteRecurringExpense(id: number): Promise<void> {
    await this.expensesService.deleteRecurring(id);
    await this.getRecurringExpenses();
  }

  @Action
  async editOneTimeExpense(payload: { id: number; dto: IPostOneTimeExpenseDTO }): Promise<IOneTimeExpenseDTO> {
    const expense = await this.expensesService.editOneTime(payload.id, payload.dto);
    await this.getOneTimeExpenses();
    return expense;
  }

  @Action
  async editRecurringExpense(payload: { id: number; dto: IPostRecurringExpenseDTO }): Promise<IRecurringExpenseDTO> {
    const expense = await this.expensesService.editRecurring(payload.id, payload.dto);
    await this.getRecurringExpenses();
    return expense;
  }
}

export const expensesModule = new ExpensesModule({ store, name: "expenses" });
