<template>
  <div>
    <v-subheader>{{ title }}</v-subheader>
    <v-divider></v-divider>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SettingsGroup",
})
export default class SettingsGroup extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
}
</script>
