<template>
  <v-list-item
    :disabled="disabled"
    @click="$emit('click')"
    class="cursor-pointer"
    :class="{ 'selected-item': selected }"
  >
    <v-list-item-icon>
      <v-icon :color="selected ? 'primary' : undefined">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title :class="{ 'font-weight-bold': selected }">{{
        label
      }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon right v-if="isMobile">
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SettingsListItem",
})
export default class SettingsListItem extends Vue {
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly selected!: boolean;

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.selected-item {
  background-color: #e9f7f6;
}

.selected-item .v-list-item__title {
  color: var(--v-primary-base);
}
</style>
