<template>
  <div>
    <v-divider></v-divider>
    <h3>{{ $t($ts.workingHours.breaks).toString() }}</h3>

    <v-simple-table>
      <template>
        <tbody>
          <tr v-for="pauseTime in pauseTimes" :key="pauseTime.id">
            <td>{{ pauseTime.start }}</td>
            <td>{{ pauseTime.end }}</td>
            <td>
              <v-btn @click="$emit('delete', pauseTime.id)" icon>
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          :rules="timeRules"
          :label="$t($ts.workingHours.start).toString()"
          placeholder="e.g. 11:00"
          v-model="localStart"
          @input="updateStart"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          :rules="timeRules"
          :label="$t($ts.workingHours.end).toString()"
          placeholder="e.g. 12:00"
          v-model="localEnd"
          @input="updateEnd"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center">
        <v-btn @click="$emit('add')" :disabled="!isValid">
          {{ $t($ts.workingHours.addBreak).toString() }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface PauseTime {
  id: number;
  start: string;
  end: string;
}

@Component({
  name: "BreakTimes",
})
export default class BreakTimes extends Vue {
  @Prop({ required: true }) pauseTimes!: PauseTime[];
  @Prop({ required: true }) start!: string;
  @Prop({ required: true }) end!: string;

  localStart = "";
  localEnd = "";

  public timeRules = [
    (v) => !!v || this.$t(this.$ts.workingHours.timeRequired).toString(),
    (v) => {
      let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return pattern.test(v) || this.$t(this.$ts.workingHours.invalidFormat).toString();
    },
  ];

  @Watch("start")
  onStartChange(val: string) {
    this.localStart = val;
  }

  @Watch("end")
  onEndChange(val: string) {
    this.localEnd = val;
  }

  created() {
    this.localStart = this.start;
    this.localEnd = this.end;
  }

  updateStart(val: string) {
    this.$emit("update:start", val);
  }

  updateEnd(val: string) {
    this.$emit("update:end", val);
  }

  get isValid() {
    return this.timeRules.every((rule) => rule(this.localStart) === true && rule(this.localEnd) === true);
  }
}
</script>
