<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { IAppointmentTypeDTO, IStaffMemberDTO } from "@shared/types";
import { Prop } from "vue-property-decorator";
import Loader from "../../../global/Loader.vue";
import { getToken } from "@/Utilities/authConfig";
import { DialogType, IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { googleCalendarModule } from "@/store/modules/googleCalendar/googleCalendarModule";

@Component({
  name: "StaffMemberGoogleCalendarSettings",
  components: { Loader },
})
export default class StaffMemberGoogleCalendarSettings extends Vue {
  public loading: boolean = false;

  addToDialogQueue(dialog) {
    dialogModule.addToDialogQueue(dialog);
  }

  removeOAuthToken(staffMember) {
    googleCalendarModule.removeOAuthToken(staffMember);
  }

  hasOauthToken(staffMember) {
    return googleCalendarModule.hasOauthToken(staffMember);
  }

  @Prop({}) staffMember: IStaffMemberDTO;

  public e6 = 1;
  public loadingToken = true;
  public hasOAuth = false;
  public token = "";
  public attempts = 0;
  public interval = null;

  async removeSyncConfirm() {
    await this.removeOAuthToken(this.staffMember);
    this.hasOAuth = false;
    this.e6 = 1;
    await this.listenToLogin();
  }

  async removeSync() {
    let dialog: IDialog = {
      text: this.$t(this.$ts.staffMemberGoogleCalendar.confirmRemoveSync).toString(),
      type: DialogType.Choice,
      action: this.removeSyncConfirm,
    };
    this.addToDialogQueue(dialog);
  }

  async listenToLogin() {
      let res = await this.hasOauthToken(this.staffMember);
      if (res) {
        if (this.attempts == 0) {
          this.e6 = 4;
        } else {
          this.e6 = 3;
        }
        this.hasOAuth = true;
        clearInterval(this.interval);
      } else {
        this.attempts++;
      }
  }
  async created() {
    await this.fetchToken();

    await this.listenToLogin();
  }

  async destroyed() {
    clearInterval(this.interval);
  }

  async fetchToken() {
    this.loadingToken = true;
    let token = await getToken();
    this.token = token;
    this.loadingToken = false;
  }
}
</script>

<template>
  <div>
    <v-card-text>
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          {{ $t($ts.staffMemberGoogleCalendar.syncGoogleCalendar) }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card color="grey lighten-3" class="mb-12" height="100%">
            <v-card-title style="word-break: break-word">
              {{ $t($ts.staffMemberGoogleCalendar.syncGoogleCalendarWithAvailify) }}
            </v-card-title>

            <v-row>
              <v-col cols="7">
                <v-img src="/img/google-calendar-1.svg"></v-img>
              </v-col>

              <v-col cols="5">
                <ul>
                  <li style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.benefit1) }}
                  </li>
                  <li class="mt-1" style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.benefit2) }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6 = 2">
            {{ $t($ts.staffMemberGoogleCalendar.start) }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          {{ $t($ts.staffMemberGoogleCalendar.connectGoogleAccount) }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card color="grey lighten-3" class="mb-12" height="200px">
            <v-card-title style="word-break: break-word">
              {{ $t($ts.staffMemberGoogleCalendar.clickButtonToLogin) }}
            </v-card-title>
            <v-card-actions>
              <iframe
                style="overflow: hidden"
                scrolling="no"
                v-if="loadingToken === false"
                :src="'https://googleauth.availify.no?token=' + token + '&staff=' + staffMember.id"
                width="350"
                height="65"
                title="description"
              ></iframe>
            </v-card-actions>
          </v-card>
          <v-btn :disabled="!hasOAuth" color="primary" @click="e6 = 3">
            {{ $t($ts.staffMemberGoogleCalendar.continue) }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          {{ $t($ts.staffMemberGoogleCalendar.addEventsToAppleCalendar) }}
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card flat color="white" class="mb-12" height="400px">
            <v-card-text class="text-h6">
              {{ $t($ts.staffMemberGoogleCalendar.useAppleDevices) }}
            </v-card-text>

            <div class="text-white">
              <strong style="text-decoration: underline">
                1.
                {{ $t($ts.staffMemberGoogleCalendar.addGoogleCalendarToApp) }}
              </strong>
              <ol>
                <li>{{ $t($ts.staffMemberGoogleCalendar.step1) }}</li>
                <li>{{ $t($ts.staffMemberGoogleCalendar.step2) }}</li>
                <li>{{ $t($ts.staffMemberGoogleCalendar.step3) }}</li>
                <!-- ...other steps... TODO: Jeez, they fucked this one up... -->
              </ol>
            </div>

            <div class="mt-1">
              <strong style="text-decoration: underline">
                2. {{ $t($ts.staffMemberGoogleCalendar.setAsDefaultCalendar) }}
              </strong>
              <ol>
                <li>{{ $t($ts.staffMemberGoogleCalendar.defaultStep1) }}</li>
                <li>{{ $t($ts.staffMemberGoogleCalendar.defaultStep2) }}</li>
                <!-- ...other steps... shit -->
              </ol>
            </div>
          </v-card>
          <v-btn color="primary" @click="e6 = 4">
            {{ $t($ts.staffMemberGoogleCalendar.continue) }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="4">
          {{ $t($ts.staffMemberGoogleCalendar.done) }}
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card dark class="mb-12" height="500px" color="#876AFF">
            <v-card-title style="word-break: break-word">
              <v-icon class="mx-1">mdi-check</v-icon>
              {{ $t($ts.staffMemberGoogleCalendar.synchronizedWithGoogle) }}
            </v-card-title>

            <v-row>
              <v-col cols="6">
                <ul>
                  <li style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.syncBenefit1) }}
                  </li>
                  <li class="mt-4" style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.syncBenefit2) }}
                  </li>
                  <li class="mt-4" style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.syncBenefit3) }}
                  </li>
                  <li class="mt-4" style="font-size: 16px">
                    {{ $t($ts.staffMemberGoogleCalendar.syncBenefit4) }}
                  </li>
                </ul>
              </v-col>

              <v-col cols="6">
                <v-img src="/img/google-calendar-1.svg"></v-img>
              </v-col>
            </v-row>
          </v-card>
          <v-btn color="primary" @click="e6--">
            <v-icon class="mx-1">mdi-help-circle</v-icon>
            {{ $t($ts.staffMemberGoogleCalendar.howToConnectAppleCalendar) }}
          </v-btn>
          <br />
          <v-btn class="mt-5" @click="removeSync">
            <v-icon class="mx-1">mdi-delete</v-icon>
            {{ $t($ts.staffMemberGoogleCalendar.removeSync) }}
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </div>
</template>

<style scoped></style>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule
