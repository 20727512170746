import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType } from "./Events/ServiceEvents";
import { IMoneyAccountController, IMoneyAccountDTO, IMoneyAccountSummaryDTO } from "@shared/types";

export class MoneyAccountService extends EventBaseService implements IMoneyAccountController {
  private static instance: MoneyAccountService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!MoneyAccountService.instance) {
      MoneyAccountService.instance = new MoneyAccountService();
    }
    return MoneyAccountService.instance;
  }

  public async getAll(): Promise<IMoneyAccountDTO[]> {
    return this.http.sendRequest<IMoneyAccountDTO[]>(IMoneyAccountController.GetAllRoute);
  }

  public async getById(id: number): Promise<IMoneyAccountDTO> {
    return this.http.sendRequest<IMoneyAccountDTO>(IMoneyAccountController.GetByIdRoute, { params: { id } });
  }

  public async create(dto: IMoneyAccountDTO): Promise<IMoneyAccountDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<IMoneyAccountDTO>(IMoneyAccountController.CreateRoute, { data: dto })
    );
  }

  public async update(id: number, dto: IMoneyAccountDTO): Promise<IMoneyAccountDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Updated, () =>
      this.http.sendRequest<IMoneyAccountDTO>(IMoneyAccountController.UpdateRoute, { params: { id }, data: dto })
    );
  }

  public async delete(id: number): Promise<void> {
    return this.withEventEmission(Feature.Finance, EventType.Deleted, () =>
      this.http.sendRequest<void>(IMoneyAccountController.DeleteRoute, { params: { id } })
    );
  }

  public async getSummary(query: Partial<{ year: number; month: number; day: number; }>): Promise<IMoneyAccountSummaryDTO> {
    return this.http.sendRequest<IMoneyAccountSummaryDTO>(IMoneyAccountController.GetSummaryRoute, { query });
  }
} 