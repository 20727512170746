<template>
  <div>
    <v-dialog max-width="500" v-model="dialog">
      <create-customer
        @close="onClose"
        v-if="o != null"
        @created="onEditedCustomer"
        :editingCustomer="customer"
      ></create-customer>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CreateCustomer from "./CreateCustomer.vue";
import { ICustomerDTO } from "@shared/types";
import Loader from "./Loader.vue";
import DatePicker from "./DatePicker.vue";

@Component({
  name: "EditCustomer",
  components: { CreateCustomer, Loader, DatePicker },
})
export default class EditCustomer extends Vue {
  @Prop({}) customer: ICustomerDTO;

  public editedCustomer: Partial<ICustomerDTO> | null = null;
  public o: Partial<ICustomerDTO> | null = null;
  public dialog: boolean = true;

  public isShowingDatePicker: boolean = false;

  @Watch("dialog")
  dialogHandler(newValue) {
    if (!newValue) {
      this.$emit("finish");
    }
  }
  onClose() {
    this.dialog = false;
    this.$emit("finish");
  }
  onEditedCustomer() {
    this.$emit("finish");
  }

  public async created(): Promise<any> {
    this.dialog = true;

    let value = this.customer.sendSmsReminder;

    this.editedCustomer = {
      dateOfBirth: this.customer.dateOfBirth,
      emailAddress: this.customer.emailAddress,
      firstName: this.customer.firstName,
      phoneNumber: this.customer.phoneNumber,
      gender: this.customer.gender,
      postalCode: this.customer.postalCode,
      address: this.customer.address,
      city: this.customer.city,
      insuranceId: this.customer.insuranceId,
      ssn: this.customer.ssn,
      id: this.customer.id,
      sendEmailReminder: this.customer.sendEmailReminder,
    };

    let o = {
      dateOfBirth: this.customer.dateOfBirth,
      emailAddress: this.customer.emailAddress,
      firstName: this.customer.firstName,
      phoneNumber: this.customer.phoneNumber,
      gender: this.customer.gender,
      postalCode: this.customer.postalCode,
      address: this.customer.address,
      city: this.customer.city,
      insuranceId: this.customer.insuranceId,
      ssn: this.customer.ssn,
      id: this.customer.id,
      sendEmailReminder: this.customer.sendEmailReminder,
      sendSmsReminder: this.customer.sendSmsReminder,
    };

    this.o = o;
  }
}
</script>
