// @ts-ignore
import Vue from "vue";
// @ts-ignore
import Router from "vue-router";
import Login from "./pages/user/Login.vue";
import Settings from "./pages/admin/Settings.vue";
import Customers from "./pages/admin/Customers.vue";
import Customer from "./pages/admin/Customer.vue";
import Appointment from "./pages/admin/Appointment.vue";
import CalendarPage from "./pages/admin/CalendarPage.vue";
import InvoicesOverview from "./pages/admin/InvoicesOverview.vue";
import PaymentsOverview from "./pages/admin/PaymentsOverview.vue";
import Dashboard from "./pages/admin/Dashboard.vue";
import Onboarding from "./pages/Onboarding.vue";
import Statistics from "./pages/admin/Statistics.vue";
import ExportPage from "./pages/admin/ExportPage.vue";
import GiftCard from "@/pages/admin/GiftCard.vue";
import Services from "@/pages/admin/services.vue";
import Register from "@/pages/user/register.vue";
import EventsOverview from "@/pages/admin/EventsOverview.vue";
import DebugOverview from "@/pages/admin/DebugOverview.vue";
import AuditOverview from "@/pages/admin/AuditOverview.vue";
import CompanyInfoCard from "@/components/global/CompanyInfoCard.vue";
import SystemSettings from "@/components/Settings/SystemSettings.vue";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "@/components/global/ServiceCategories.vue";
import Resources from "@/components/Settings/Services/Resources.vue";
import SubscriptionInformation from "@/components/Settings/SubscriptionInformation.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import BookingNotificationSettings from "@/components/Settings/Booking/BookingNotificationSettings.vue";
import BookingPageFieldSettings from "@/components/Settings/Booking/Booking-page/BookingPageFieldSettings.vue";
import GeneralBookingSettings from "@/components/Settings/Booking/GeneralBookingSettings.vue";
import BookingPageGeneralSettings from "@/components/Settings/Booking/Booking-page/BookingPageGeneralSettings.vue";
import ServiceOptions from "@/components/Settings/Services/ServiceOptions.vue";
import StaffMembersSettings from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import CalendarStyleSettings from "@/components/Settings/Calendar/CalendarStyleSettings.vue";
import EmailListSettings from "@/components/Settings/Functions/EmailListSettings.vue";
import ExtraServices from "@/components/Settings/Services/ExtraServices.vue";
import BookingPageInformationSettings from "@/components/Settings/Booking/Booking-page/BookingPageInformationSettings.vue";
import BookingPageCustomerSettings from "@/components/Settings/Booking/Booking-page/BookingPageCustomerSettings.vue";

import AppointmentListPage from "./pages/admin/AppointmentListPage.vue";
import StaffMembersOverview from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import StaffMemberDetailsSettings from "./components/Settings/StaffMembers/StaffMember/StaffMemberDetailsSettings.vue";
import StaffMemberServicesSettings from "./components/Settings/StaffMembers/StaffMember/StaffMemberServicesSettings.vue";
import WorkingHours from "./components/Settings/StaffMembers/StaffMember/WorkingHours.vue";
import StaffMemberGoogleCalendarSettings from "./components/Settings/StaffMembers/StaffMember/StaffMemberGoogleCalendarSettings.vue";
import VacationDays from "./components/Settings/StaffMembers/StaffMember/VacationDays.vue";
import Expenses from "@/views/Expenses.vue";
import InventoryItems from "@/pages/admin/InventoryItems.vue";
import CashRegisterOverview from "@/pages/admin/CashRegisterOverview.vue";

import * as Sentry from "@sentry/vue";
import OrdersOverview from "./pages/admin/OrdersOverview.vue";
import InventoryCategories from "./pages/admin/InventoryCategories.vue";
import StaffMemberManagement from "@/components/global/staff/StaffMemberManagement.vue";
import CommissionSettlement from "@/components/global/staff/CommissionSettlement.vue";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { PermissionArea, PermissionLevel } from "@/store/userPermissions";

Vue.use(Router);

let router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", name: "login", component: Login },
    { path: "/login", name: "login ", component: Login },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/system",
      name: "SystemSettings",
      component: SystemSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/services",
      name: "Services",
      component: TreatmentTypes,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/categories",
      name: "Categories",
      component: ServiceCategories,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/resources",
      name: "Resources",
      component: Resources,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/subscription",
      name: "Subscription",
      component: SubscriptionInformation,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/accounts",
      name: "Accounts",
      component: AccountSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking/alerts",
      name: "BookingAlerts",
      component: BookingNotificationSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking/customer-information",
      name: "CustomerInformation",
      component: BookingPageFieldSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking",
      name: "Booking",
      component: GeneralBookingSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking/side",
      name: "BookingSide",
      component: BookingPageGeneralSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/options",
      name: "Options",
      component: ServiceOptions,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff",
      name: "Staff",
      component: StaffMembersSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/calendar",
      name: "CalendarSettings",
      component: CalendarStyleSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/functions/newsletter",
      name: "Newsletter",
      component: EmailListSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/audit-log",
      name: "AuditLog",
      component: AuditOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/extra-services",
      name: "ExtraServices",
      component: ExtraServices,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking/information",
      name: "BookingInformation",
      component: BookingPageInformationSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/booking/customer-account",
      name: "CustomerAccount",
      component: BookingPageCustomerSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff",
      name: "StaffOverview",
      component: StaffMembersOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff/:staffId/information",
      name: "StaffInformation",
      component: StaffMemberDetailsSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff/:staffId/services",
      name: "StaffServices",
      component: StaffMemberServicesSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff/:staffId/working-hours",
      name: "StaffWorkingHours",
      component: WorkingHours,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff/:staffId/google-calendar",
      name: "StaffGoogleCalendar",
      component: StaffMemberGoogleCalendarSettings,
      meta: { requiresAuth: true },
    },
    {
      path: "/settings/staff/:staffId/friday",
      name: "StaffFriday",
      component: VacationDays,
      meta: { requiresAuth: true },
    },
    // Original routes
    {
      path: "/kunder",
      name: "Kunder",
      component: Customers,
      meta: { requiresAuth: true },
    },
    {
      path: "/kunder/:customerId",
      name: "Kunde",
      component: Customer,
      meta: { requiresAuth: true, hideMobileHeader: true },
    },
    {
      path: "/kalender",
      name: "Kalender",
      component: CalendarPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/kalender/:appointmentId",
      name: "KalenderBestilling",
      component: CalendarPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/admin",
      name: "Hjem",
      component: Dashboard,
      meta: { requiresAuth: true },
    },
    {
      path: "/oauth2callback",
      name: "oAuthCallBack",
      component: ExportPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/onboarding",
      name: "Onboarding",
      component: Onboarding,
      meta: { requiresAuth: true, hasLayout: false },
    },
    {
      path: "/statistikk",
      name: "Statistikk",
      component: Statistics,
      meta: { requiresAuth: true },
    },
    {
      path: "/eksporter",
      name: "Eksporter",
      component: ExportPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/bestillinger",
      name: "Bestillinger",
      component: AppointmentListPage,
      meta: { requiresAuth: true },
    },
    {
      path: "/bestillinger/:appointmentNumber",
      name: "Bestilling",
      component: Appointment,
      meta: { requiresAuth: true, hideMobileHeader: true },
    },
    {
      path: "/faktura",
      name: "Faktura",
      component: InvoicesOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/betalinger",
      name: "Betalinger",
      component: PaymentsOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/gavekort",
      name: "Gavekort",
      component: GiftCard,
      meta: { requiresAuth: true },
    },
    {
      path: "/tjenester",
      name: "Tjenester",
      component: Services,
      meta: { requiresAuth: true },
    },
    {
      path: "/hendelser",
      name: "Hendelser",
      component: EventsOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/logg/",
      name: "Logg",
      component: AuditOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/logg/:ignoreFlag",
      name: "AdminLogg",
      component: AuditOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/debug",
      name: "Debug",
      component: DebugOverview,
      meta: { requiresAuth: true },
    },
    {
      path: "/expenses",
      name: "Expenses",
      component: Expenses,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/inventoryitems",
      name: "InventoryItems",
      component: InventoryItems,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/inventoryitems/categories",
      name: "InventoryCategories",
      component: InventoryCategories,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/orders",
      name: "Orders",
      component: OrdersOverview,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    { path: "/register", name: "Register", component: Register },
    {
      path: "/staff-management",
      name: "StaffManagement",
      component: StaffMemberManagement,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/commission-settlement",
      name: "CommissionSettlement",
      component: CommissionSettlement,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/cash-register",
      name: "CashRegister",
      component: CashRegisterOverview,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
  ],
  mode: "history",
});

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function getBaseRoute(path: string) {
  const segments = path.split("/").filter((segment) => segment);
  return segments.length > 0 ? segments[0] : "";
}

router.beforeEach((to, from, next) => {
  Sentry.addBreadcrumb({
    category: "navigation",
    message: `Navigating from ${from.name} to ${to.name}`,
    level: "info",
  });

  // Check for admin permission requirement
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!userPermissionsModule.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ)) {
      next("/admin"); // Redirect to home if user doesn't have admin permissions
      return;
    }
  }

  if (isMobile()) {
    const fromBase = getBaseRoute(from.path);
    const toBase = getBaseRoute(to.path);

    if (fromBase === toBase && fromBase !== "") {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      to.meta.transition = toDepth < fromDepth ? "slide-right" : "slide-left";
    } else {
      to.meta.transition = "";
    }
  } else {
    to.meta.transition = "";
  }

  if (from.name != to.name) {
    next();
  }

  next();
});

router.afterEach((to, from) => {
  if (to.path != null) {
    localStorage.setItem("lasturl", to.path);
  }
});

export default router;
