import { TranslationSchema } from "./translation.interface";

export const en: TranslationSchema = {
  dtos: {
    accountRoleDTO: {
      name: {
        name: "Role Name",
        description: "The name of the account role",
      },
      appointmentsAndClients: {
        name: "Appointments & Clients",
        description: "Permission level for appointments and clients",
      },
      settings: {
        name: "Settings",
        description: "Permission level for settings",
      },
      statisticsAndFinances: {
        name: "Statistics & Finances",
        description: "Permission level for statistics and finances",
      },
      administration: {
        name: "Administration",
        description: "Permission level for administration",
      },
    },
    appointmentTypeFormDTO: {
      title: {
        name: "Title",
        description: "The title of the appointment type",
      },
      description: {
        name: "Description",
        description: "Description of the service",
      },
      price: {
        name: "Price",
        description: "Price of the service",
      },
      durationInMinutes: {
        name: "Duration (minutes)",
        description: "Duration of the service in minutes",
      },
      //   serviceDurationInMinutes: {
      //     name: "Service Duration (minutes)",
      //     description: "Actual duration of the service in minutes",
      //   },
      hideInBooking: {
        name: "Hide in Booking",
        description: "When enabled, this service will not be visible in the booking page",
      },
      autoApply: {
        name: "Auto Apply",
        description: "When enabled, this service will be automatically applied",
      },
      showCustomColor: {
        name: "Show Custom Color",
        description: "When enabled, you can set a custom color for this service",
      },
      isExtraService: {
        name: "Extra Service",
        description: "When enabled, this service will be treated as an extra service",
      },
      color: {
        name: "Color",
        description: "Custom color for the service",
      },
      unavailableForOnlineBooking: {
        name: "Unavailable for Online Booking",
        description: "When enabled, this service cannot be booked online by customers",
      },
      disablePrice: {
        name: "Disable Price",
        description: "When enabled, the price will not be shown for this service",
      },
      mustPayOnline: {
        name: "Must Pay Online",
        description: "When enabled, customers must pay online for this service",
      },
      payOnlinePrice: {
        name: "Online Payment Price",
        description: "Price for online payment",
      },
      eligibleForCommission: {
        name: "Eligible for Commission",
        description: "When enabled, this service will be eligible for commission",
      },
      commissionPercentage: {
        name: "Commission Percentage",
        description: "The commission percentage to the staff member that provided the service",
      },
      eligibleForCustomPrice: {
        name: "Eligible for Custom Price",
        description: "When enabled, this service will be eligible for a custom price",
      },
    },
    serviceCategoryFormDTO: {
      title: {
        name: "Title",
        description: "The title of the service category",
      },
    },
    staffMemberFormDTO: {
      firstName: {
        name: "First Name",
        description: "The first name of the staff member",
      },
      lastName: {
        name: "Last Name",
        description: "The last name of the staff member",
      },
      phoneNumber: {
        name: "Phone Number",
        description: "The phone number of the staff member (required if you want to send notifications directly to staff member)",
      },
      emailAddress: {
        name: "Email",
        description: "The email of the staff member (optional)",
      },
      eligibleForCommission: {
        name: "Eligible for Commission",
        description: "When enabled, this staff member will be eligible for commission",
      },
      eligibleForCommissionProducts: {
        name: "Eligible for Commission Products",
        description: "When enabled, this staff member will be eligible for commission for products",
      },
      eligibleForCommissionServices: {
        name: "Eligible for Commission Services",
        description: "When enabled, this staff member will be eligible for commission for services",
      },
      commissionPercentageProducts: {
        name: "Commission Percentage Products",
        description: "The commission percentage for products",
      },
      commissionPercentageServices: {
        name: "Commission Percentage Services",
        description: "The commission percentage for services",
      },
      overrideProductsCommission: {
        name: "Override Products Commission",
        description: "When enabled, this staff member will override the commission percentage for products",
      },
      overrideServicesCommission: {
        name: "Override Services Commission",
        description: "When enabled, this staff member will override the commission percentage for services",
      },
    },
    inventoryItemFormDTO: {
      name: {
        name: "Name",
        description: "The name of the inventory item",
      },
      description: {
        name: "Description",
        description: "The description of the inventory item",
      },
      salePrice: {
        name: "Sale Price",
        description: "The sale price of the inventory item (price per unit)",
      },
      currentStock: {
        name: "Current Stock",
        description: "The current stock of the inventory item",
      },
      costPrice: {
        name: "Cost Price",
        description: "The purchase cost price of the inventory item from your supplier (price per unit)",
      },
      minimumStockThreshold: {
        name: "Minimum Stock Threshold",
        description: "The minimum stock threshold of the inventory item",
      },
      reorderLevel: {
        name: "Reorder Level",
        description: "The reorder level of the inventory item",
      },
      isStockItem: {
        name: "Is Stock Item",
        description: "If the item must be purchased from asupplier and is a physical product that must be restocked",
      },
      eligibleForCommission: {
        name: "Eligible for Commission",
        description: "When enabled, this inventory item will be eligible for commission",
      },
      commissionPercentage: {
        name: "Commission Percentage",
        description: "The commission percentage to the staff member that sold the product",
      },
    },
    addStockFormDTO: {
      quantity: {
        name: "Quantity",
        description: "The quantity of the inventory item",
      },

      purchaseDate: {
        name: "Purchase Date",
        description: "The purchase date of the inventory item",
      },
    },
    inventoryItemCategoryFormDTO: {
      name: {
        name: "Name",
        description: "The name of the inventory item category",
      },
      description: {
        name: "Description",
        description: "The description of the inventory item category",
      },
    },
    ledgerEntryFormDTO: {
      amount: {
        name: "Amount",
        description: "The amount of the ledger entry",
      },
      description: {
        name: "Description",
        description: "The description of the ledger entry",
      },
    },
    oneTimeExpenseFormDTO: {
      amount: {
        name: "Amount",
        description: "The amount of the expense",
      },
      notes: {
        name: "Notes",
        description: "The notes of the expense",
      },
      purchaseDate: {
        name: "Purchase Date",
        description: "The purchase date of the expense",
      },
    },
    recurringExpenseFormDTO: {
      amount: {
        name: "Amount",
        description: "The amount of the expense",
      },
      endDate: {
        name: "End Date",
        description: "The end date of the expense",
      },
      name: {
        name: "Name",
        description: "The name of the expense",
      },
      startDate: {
        name: "Start Date",
        description: "The start date of the expense",
      },
    },
    moneyAccountFormDTO: {
      name: {
        name: "Name",
        description: "The name of the money account",
      },
      description: {
        name: "Description",
        description: "The description of the money account",
      },
    },
  },
  headers: {
    buyStock: "Buy Stock",
  },

  welcome: "Welcome",
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  days: {
    sunday: "Sun",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Update",
  },
  subscriptionWarnings: {
    message1: "Hello! You have now tried the program for free for 14 days. We hope you find it useful.",
    message2: "If you wish to continue using the program, please purchase a subscription in 'Settings' -> 'Subscription'",
    message3: "If you have any questions, please contact us. Thank you!",
  },
  buttons: {
    gotoSubscription: "Go to Subscription",
    close: "Close",
    ok: "Ok",
    save: "Save",
    delete: "Delete",
    cancel: "Cancel",
    edit: "Edit",
    create: "Create",
  },

  noAppointmentsToday: "No appointments today!",
  readNotes: "Read notes",
  readCustomerComment: "Read customer's comment",
  dialog: {
    customerComment: "Customer's Comment",
    notes: "Notes",
    confirmDeleteService: "Are you sure you want to delete this service?",
    deleteServiceHeader: "Delete Service",
    cancel: "Cancel",
    save: "Save",
    confirm: "OK",
    no: "No",
    yes: "Yes",
  },
  messages: {
    updated: "Updated",
    categoryChanged: "Category changed",
    categoryOrderChanged: "Category order changed",
    serviceOrderChanged: "Service order changed",
    categoryUpdated: "Category updated",
    newCategoryAdded: "New category added",
    categoryDeleted: "Category deleted",
    newServiceAdded: "New service added",
    couldNotAddService: "Could not add service",
    serviceDeleted: "Service deleted",
    serviceUpdated: "Service updated",
    inProgress: "{feature} is being {action}...",
    success: "{feature} was {action}",
    failed: "Could not {action} {feature}",
    imageUploaded: "Image has been uploaded successfully",
    imageUploading: "Uploading image...",
    imageUploadSuccess: "Image uploaded successfully",
    deleteConfirmation: "Are you sure you want to delete this item?",
    itemCreated: "Item created successfully",
    itemUpdated: "Item updated successfully",
    itemDeleted: "Item deleted successfully",
  },
  staff: "Staff",
  date: "Date",
  service: "Service",
  price: "Price",
  customerAccount: "Customer Account",
  automaticAssignExistingCustomerAccount: "Automatically assign existing customer account to new bookings based on field.",
  createAssignCustomerAccountAutomatically: "Create/Assign customer account automatically upon booking",
  customerAccountWillBeAssignedBasedOnPhoneNumber: "Customer account will be assigned to existing based on phone number.",
  newCustomField: "New Custom Field",
  selectFieldsCustomerFillsWhenBooking: "Select fields the customer fills out when booking",
  optional: "Optional",
  customFields: "Custom Fields",
  add: "Add",
  link: "Link",
  yourBookingLinkWillBe: "Your booking link will be {link}",
  connectSystemWithCommonBookingLink: "Connect the system with a common booking link",
  yourCommonBookingLinkWillBe: "Your common booking link will be {link}",
  bookingID: "Booking ID",
  commonID: "Common ID",
  locationName: "Location Name",
  showInformationBox: "Show Information Box",
  shouldInformationBoxBeDisplayed: "Should the information box be displayed on the booking page?",
  informationBox: "Information Box",
  informationForCustomerOnBookingPage: "Information for the customer on the front page of the booking page",
  cancellationInformation: "Cancellation Information",
  customizeInformationCustomerSeesWhenCancelling: "Customize the information the customer sees when cancelling",
  receiveEmailOnAppointment: "Receive Email on Appointment",
  doYouWantToReceiveEmailOnNewAppointment: "Do you want to receive an email when a new appointment is received?",
  whichEmailAddressToReceive: "Which email address should receive the email upon booking?",
  receiveSMSOnAppointment: "Receive SMS on Appointment",
  doYouWantToReceiveSMSOnNewAppointment: "Do you want to receive an SMS when a new appointment is received?",
  whichPhoneNumberToReceiveSMS: "Which phone number should receive the SMS upon booking?",
  receiveWhatsAppOnAppointment: "Receive WhatsApp on Appointment",
  doYouWantToReceiveWhatsAppOnNewAppointment: "Do you want to receive a WhatsApp message when a new appointment is received?",
  whichWhatsAppNumberToReceive: "Which WhatsApp number should receive the message?",
  ssn: "Social Security Number",
  birthdate: "Birthdate",
  gender: "Gender",
  city: "City",
  postalCode: "Postal Code",
  pause: "Pause",
  event: "Event",
  from: "From",
  to: "To",
  durationMismatch: "The duration of the booking differs from the service duration.",
  priceMismatch: "The price of the booking differs from the service price.",
  cancel: "Cancel",
  create: "Create",
  confirm: "Confirm",
  invalidTimeFormat: "Invalid time format",
  enterCustomerName: "You must enter a customer name.",
  selectService: "You must select a service.",
  editEndTimeWithoutStart: "Attempted to edit end time without setting start time.",
  minutesAbbreviation: "min",
  currency: "USD",
  creationCancelled: "Creation cancelled.",
  customEvent: "Custom event",
  monthlyEarningsTitle: "Earnings for {month}",
  monthlyAppointmentsTitle: "Appointments for {month}",
  totalCustomers: "Total Customers",
  earningsTrend: "Earnings Trend",
  servicesBooked: "Services Booked",
  moneyEarnedIn: "Earnings in {month}",
  appointmentsIn: "Appointments in {month}",
  appointmentBookingLinkTitle: "Appointment Booking Link",

  appointmentBookingLinkDescription: "Use the link below to book an appointment.",
  bookingLinkLabel: "Booking Link",
  copyLinkButton: "Copy Link",
  linkCopiedMessage: "Link copied to clipboard!",
  closePreview: "Close Preview",
  previewBookingPage: "Preview Booking Page",
  contactUs: "Contact Us",
  sidebar: {
    home: "Home",
    customers: "Customers",
    calendar: "Calendar",
    settings: "Settings",
    appointments: "Appointments",
    overview: "Overview",
    payments: "Payments",
    invoices: "Invoices",
    statistics: "Statistics",
    export: "Export",
    notifications: "Notifications",
    contactSupport: "Contact Support",
    log: "Log",
    journal: "Journal",
    inventory: "Inventory",
    inventoryItems: "Inventory Items",
    orders: "Orders",
    expenses: "Expenses",
    commissions: "Commissions",
    staffManagement: "Staff Management",
    finances: "Finances",
    administration: "Administration",
    cashRegister: "Cash Register",
  },
  name: "Name",
  email: "Email",
  phoneNumber: "Phone Number",
  address: "Address",
  postalCodeCity: "Postal Code/City",
  edit: "Edit",
  created: "Created",
  deleteSelectedCustomers: "Delete selected customers",
  search: "Search",
  searchForCustomer: "Search for customer",
  noCustomersYet: "You have no customers yet",
  noSearchResults: "No search results",
  customerWithoutName: "Customer without name",
  confirmDeleteCustomers: "Are you sure you want to delete {count} customers?",
  deleteCustomerHeader: "Delete customer(s)?",
  customersDeleted: "{count} customers have been deleted",
  customer: {
    notFound: "Customer not found.",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    address: "Address",
    postalCodeCity: "Postal Code/City",
    edit: "Edit",
    created: "Created",
    deleteSelectedCustomers: "Delete selected customers",
    search: "Search",
    searchForCustomer: "Search for customer",
    noCustomersYet: "You have no customers yet",
    noSearchResults: "No search results",
    customerWithoutName: "Customer without name",
    confirmDeleteCustomers: "Are you sure you want to delete {count} customers?",
    deleteCustomerHeader: "Delete customer(s)?",
    customersDeleted: "{count} customers have been deleted",
    deleted: "Customer {name} has been deleted.",
    couldNotDelete: "Could not delete customer.",
    confirmDeleteCustomer: "Are you sure you want to delete this customer?",
    credit: "Credit",
    dropIn: "Drop-in",
  },
  calendar: {
    week: "Week",
    month: "Month",
    day: "Day",
    sideBySide: "Side-by-side",
    threeFourDays: "3-4 days",
    heading: "Calendar",
  },
  appointment: {
    deleteSelectedAppointments: "Delete Selected Appointments",
    filter: "Filter",
    all: "All",
    showMoreCustomerInfo: "Show More Customer Info",
    noAppointments: "No Appointments",
    select: "Select",
    loading: "Loading... Please wait...",
    status: {
      ny: "New",
      invoiceCreated: "Invoice Created",
      invoiceSent: "Invoice Sent",
      invoicePaid: "Invoice Paid",
      ferdig: "Completed",
    },
    comment: "Comment",
    adminNote: "Admin Note",
    created: "Created",
    number: "Number",
    service: "Service",
    time: "Time",
    customer: "Customer",
    appointmentStatus: "Status",
    createAppointment: "Create Appointment",
    editAppointment: "Edit Appointment",
    markAsUnpaid: "Mark as Unpaid",
    markAsUnpaidWarning: "Warning: This action is irreversible. A new ledger entry will be created for this appointment. Continue?",
  },
  companyInfo: {
    companyName: "Company Name",
    companyNameDescription: "The name of the company as shown to the customer",
    companyAddress: "Company Address",
    companyAddressDescription: "The address of your company as shown to the customer",
    phoneNumber: "Phone Number",
    phoneNumberDescription: "The phone number of your company as shown to the customer",
    email: "Email",
    emailDescription: "The email of your company as shown to the customer",
    companyLogo: "Company Logo",
    companyLogoDescription: "The logo of your company as shown to the customer",
  },
  snackbar: {
    imageTooLarge: "Image must be smaller than 200 KB",
    invalidImageFormat: "Image must be in JPG or PNG format",
    enterServiceName: "Service name must be provided.",
    negativeDiscountPrice: "Discount price cannot be negative.",
    enterDuration: "Duration in minutes must be provided.",
    durationMustBeNumber: "Duration in minutes must be a number.",
    priceMustBeNumber: "Price must be a number.",
    negativePrice: "Price cannot be negative.",
    invalidDuration: "Invalid duration.",
    durationExceedsLimit: "A single service cannot last longer than 12 hours.",
  },
  account: {
    registerNewAccount: "Register New Account",
    editAccount: "Edit Account",
    allAccounts: "All Accounts",
    lastSignIn: "Last signed in: {date}",
    created: "Created: {date}",
    yourAccount: "Your Account",
    deleted: "Deleted",
    deleteConfirmation: "Are you sure you want to delete this account? The account will be disabled and marked as deleted.",
    deleteSuccess: "Account successfully deleted",
    deleteError: "Failed to delete account",
  },
  role: {
    administrator: "Administrator",
    user: "User",
  },
  logout: "Log out",
  changePassword: "Change Password",
  logoutConfirm: "Do you want to log out?",
  forgotPassword: "Do you want to send a password reset link to {email}?",
  passwordResetEmailSent: "A password reset email has been sent to you.",
  subscription: {
    yourPlan: "Your Plan",
    nextPayment: "Next Payment",
    previousPayment: "Previous Payment",
    paymentInformation: "Payment Information",
    cardEnding: "Visa ending in {card}",
    noTrial: "Your trial period has expired. Please purchase a subscription to continue using the program.",
    paymentIssue: "There is an issue with your payment. Please contact us.",
    trialDaysLeft: "You have {days} days left of your free trial.",
    cancelledWithDate: "The subscription will not renew next month. It is active until {date}.",
    cancelledWithoutDate: "The subscription will not renew next month. It is active until the end of the month.",
    active: "You have an active subscription.",
    yearlyWithSMS: "You have a subscription valid until {date} with SMS included.",
    monthlyCost: "The subscription costs 250kr per month, no binding period. Cancel anytime. Upon cancellation, the subscription will last until the next payment date.",
    automaticPayment: "The payment is automatic per month. If you prefer an invoice instead, feel free to ask us.",
    smsCost: "If you have chosen to send receipts/reminders via SMS, or SMS notification upon booking, it costs 1kr per SMS and will be added automatically to the payment.",
    purchase: "Purchase subscription",
    reactivate: "Reactivate subscription",
    cancel: "Cancel subscription",
    cancelSubscriptionConfirm: "Are you sure you want to cancel your subscription?",
    subscription: "Subscription",
  },
  createCustomer: "Create Customer",
  editCustomer: "Edit Customer",
  lastName: "Last Name",
  dateOfBirth: "Date of Birth",
  male: "Male",
  female: "Female",
  selectInsurance: "Select Insurance",
  noInsuranceData: "Insurances can be added in settings.",
  customerUpdated: "Customer updated.",
  customerCreated: "Customer created.",
  settings: {
    staffSettings: {
      alerts: {
        singleStaffBanner: "This staff member is the only staff member in the system. Therefore, it is not possible to change the notification settings for a particular staff member. Please use Settings -> Booking -> Notifications",
        notificationPreference: "Notification Preference",
        notificationHint: "Select the notification method for this staff member.",
        none: "None",
        email: "Email",
        whatsapp: "WhatsApp",
        sms: "SMS",
      },
    },
    me: "Me",
    myAccount: "My Account",
    administration: "Administration",
    companyInfo: "Company Information",
    subscription: "Subscription",
    accounts: "Accounts",
    accountRoles: "Account Roles",
    services: "Services",
    extraServices: "Extra Services",
    categories: "Categories",
    resources: "Resources",
    options: "Options",
    staff: "Staff",
    calendar: "Calendar",
    functions: "Functions",
    newsletter: "Newsletter",
    customerAccounts: "Customer Accounts",
    invoicesAndPayments: "Invoices and Payments",
    booking: "Booking",
    information: "Information",
    alerts: "Alerts",
    bookingSide: "Booking Page",
    customerInformation: "Customer Information",
    customerAccount: "Customer Accounts",
    workingHours: "Working Hours",
    friday: "Vacation Days",
    googleCalendar: "Google Calendar",
    overview: "Overview",
    customFields: "Custom Fields",
    giftCards: "Gift Cards",
    customerFollowUp: "Customer Follow-Up",
    cashRegisterSystem: "Cash Register System",
    multipleLocations: "Multiple Locations",
    system: "System",
    auditLog: "Audit Log",
    staffUpdated: "Staff updated.",
    staffDeleted: "Staff deleted.",
    roleCreated: "Role created.",
    roleUpdated: "Role updated.",
    roleDeleted: "Role deleted.",
    onlineTransactions: "Online Transactions",
    onlineTransactionsDescription: "Configure online payment providers",
    paymentProvider: "Payment Provider",
    paymentProviderDescription: "Select which payment provider to use for online transactions",
    paymentProviderNone: "None",
    paymentProviderPayU: "PayU",
    paymentProviderStripe: "Stripe (Coming Soon)",
    paymentProviderVipps: "Vipps (Coming Soon)",
    products: "Products",
    productCategories: "Product Categories",
  },
  resourceName: "Resource Name",
  description: "Description",
  save: "Save",
  quantity: "Quantity",
  multipleServicesQuestion: "Should the customer be able to choose multiple services in one booking?",
  fields: {
    name: "Name",
    description: "Description",
    actions: "Actions",
    orderNumber: "Order Number",
    orderDate: "Order Date",
    totalAmount: "Total Amount",
    status: "Status",
  },
  showServicePrice: "Show price for services",
  serviceName: "Service Name",
  sort: "Sort",
  duration: "Duration",
  category: "Category",
  extraServices: "Extra Services",
  boolean: {
    yes: "Yes",
    no: "No",
    on: "On",
    off: "Off",
  },
  categories: "Categories",
  services: "Services",
  staffMembersOverview: {
    minOneStaffRequired: "You must have at least one staff member for the program to function.",
    confirmDeleteStaff: "Do you want to delete this staff member?",
    staff: "Staff",
    yourStaff: "Your Staff",
    newStaff: "New Staff",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",
    created: "Created",
    delete: "Delete",
    lastLoggedIn: "Last Logged In",
    editStaff: "Edit Staff",
    actions: "Actions",
    allFieldsRequired: "All fields are required",
    createNewStaff: "Create New Staff Member",
    firstNameDescription: "The staff member's first name",
    lastNameDescription: "The staff member's last name",
    emailOptional: "Email address (optional)",
    phoneOptional: "Phone number (optional)",
    role: "Role",
    roleOptional: "Staff member's role (optional)",
    requiredFieldsMissing: "First name and last name are required",
    errorCreatingStaff: "Could not create staff member",
    errorUpdatingStaff: "Could not update staff member",
    errorDeletingStaff: "Could not delete staff member",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    invalidEmail: "Invalid email address",
    invalidPhoneNumber: "Invalid phone number",
  },
  staffMemberDetails: {
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    colorForCalendar: "Color for Calendar",
    selectColor: "Select 'Color' under 'Calendar' -> 'Show staff in event' to choose a color for staff.",
    save: "Save",
    profileImage: "Profile Image",
    uploadImage: "Upload Image",
  },
  staffMemberGoogleCalendar: {
    syncGoogleCalendar: "Synchronize Google Calendar with Availify",
    syncGoogleCalendarWithAvailify: "Synchronize Google Calendar with Availify",
    benefit1: "Get bookings directly into your Google and Apple calendars.",
    benefit2: "Events in Google/Apple calendars will automatically be added to Availify and blocked for booking.",
    start: "Start",
    connectGoogleAccount: "Connect to a Google account",
    clickButtonToLogin: "Click the button to log in",
    continue: "Continue",
    addEventsToAppleCalendar: "Add Google Calendar events to Apple Calendar",
    useAppleDevices: "Do you use iPhone/iPad? Synchronize Google with Apple Calendar.",
    addGoogleCalendarToApp: "Add Google Calendar to the 'Calendar' app",
    step1: "Open the device settings on your iPhone or iPad.",
    step2: "Scroll and tap on 'Calendar'.",
    step3: "Tap 'Accounts' -> 'Add Account' -> 'Google'",
    // ... other steps as needed ...
    setAsDefaultCalendar: "Set Google Calendar as default calendar",
    defaultStep1: "Open the device settings on your iPhone or iPad.",
    defaultStep2: "Scroll and tap on 'Calendar'.",
    // ... other steps as needed ...
    done: "Done",
    synchronizedWithGoogle: "You are synchronized with Google Calendar",
    syncBenefit1: "Your appointments are now synchronized between Google and your Apple calendar on Mac/iPhone/iPad.",
    syncBenefit2: "When you or the customer book/change/remove appointments in Availify, these times will be updated in your Google and Apple calendars as events.",
    syncBenefit3: "You can also create new events in your Google and Apple calendars, which will be marked as busy for online booking in Availify.",
    syncBenefit4: "If you change or remove events in your Google and Apple calendars, they will also be changed/removed in Availify.",
    howToConnectAppleCalendar: "How to connect Apple Calendar to iPhone/iPad",
    removeSync: "Remove Synchronization",
    confirmRemoveSync: "Are you sure you want to remove synchronization?",
  },
  vacationDays: {
    markDayAs: "Mark day as:",
    available: "Available",
    notAvailable: "Not Available",
  },
  workingHours: {
    timeRequired: "Time is required",
    invalidFormat: "Invalid format (HH:MM)",
    invalidTimeFormat: "Could not update opening hours. Check if you have entered the correct format (HH:MM)",
    updated: "Opening hours updated.",
    availableOnThisDay: "Available on this day: {value}",
    openingHourFor: "Opening hour for {day}",
    closingHourFor: "Closing hour for {day}",
    breaks: "Breaks",
    start: "Start",
    end: "End",
    addBreak: "Add Break",
    save: "Save",
  },
  generalBookingSettings: {
    customerVerification: "Customer Verification",
    customerVerificationDescription: "What must the customer verify to book an appointment?",
    cancellationModification: "Cancellation/Modification of Appointments",
    cancellationModificationDescription: "Should the customer be able to change/cancel the appointment?",
    minimumHoursBefore: "Minimum hours before the appointment",
    minimumHoursBeforeDescription: "Number of hours before the appointment that the customer can cancel",
    remindersReceipts: "Reminders/Receipts to Customers",
    remindersReceiptsDescription: "How should receipts/reminders for the appointment be sent?",
    alwaysSendReceipt: "Always send receipt",
    alwaysSendReceiptDescription: "Box does not need to be checked by the customer",
    alwaysSendReminder: "Always send reminder",
    alwaysSendReminderDescription: "Box does not need to be checked by the customer",
    bookingRestrictions: "Booking Restrictions",
    bookingRestrictionsDescription: "How many days in advance can a customer book?",
    earlyBooking: "How early can a customer book before the appointment?",
    earlyBookingDescription: "Number of hours before the appointment can be booked",
    noVerification: "No Verification",
    email: "Email",
    sms: "SMS",
    auditVerificationChanged: "Changed setting 'Customer Verification'",
    auditCancellationChanged: "Changed setting 'Cancellation/Modification of Appointments'",
    auditHoursToCancelChanged: "Changed setting 'Minimum hours before appointment cancellation' to {hours} hours",
    auditReceiptReminderChanged: "Changed setting 'Reminders/Receipts to Customers'",
    auditMaxDaysChanged: "Changed setting 'Booking Restrictions' number of days a customer can book in advance to {days} days.",
    auditMinHoursChanged: "Changed setting 'Booking Restrictions' how early a customer can book before the appointment {hours} hours.",
    emailRequired: "Email field has been set to required because information is sent via email.",
    phoneRequired: "Phone number field has been set to required because receipts and reminders are sent via SMS.",
    days: "Days",
    hours: "Hours",
    whatsapp: "WhatsApp",
    staffSelection: "Staff Selection",
    staffSelectionDescription: "Allow customers to choose which staff member will perform their service",
    auditStaffSelectionChanged: "Changed staff selection setting",
    serviceSearch: "Service Search",
    serviceSearchDescription: "Allow customers to search for services by name",
    auditServiceSearchChanged: "Changed service search setting",
  },
  bookingDistribution: {
    timeDivision: "Time Division",
    selectTimeSlots: "Select time slots that can be booked.",
    confirmChange: "Are you sure you want to change the time division to {minutes} minutes?",
    changeTimeFormat: "Change Time Format",
    auditChange: "Changed time division to {minutes} minutes for booking",
  },
  // CalendarStyleSettings.vue
  hideDaysOutsideWorkingHours: "Hide days outside working hours",
  cannotActivateWithMultipleStaff: "Cannot be activated with multiple staff",
  events: "Events",
  showServiceNameInEvent: "Show service name in event",
  showStaffInEvent: "Show staff in event",
  showTimeInEvent: "Show time in event",
  color: "Color",

  // EmailListSettings.vue
  newsletter: "Newsletter",
  askCustomersToSignUpForNewsletter: "Ask customers to sign up for newsletter upon booking",
  subscribers: "Subscribers",
  noSubscribersYet: "Here will be an overview of all subscribers",
  signedUp: "Signed Up",
  loading: "Loading... Please wait...",
  downloadExcel: "Download Excel (.xlsx)",
  exportAppointments: "Export all appointments",
  eventsOverview: {
    message: "Message",
    time: "Time",
    comment: "Comment",
    booking: "Booking",
    receipt: "Receipt",
    reminder: "Reminder",
    seen: "Seen",
    new: "New",
    previous: "Previous",
    noEvents: "You have no events",
    guest: "Guest",
    critical: "Critical",
    info: "Info",
    warning: "Warning",
    customer: "Customer",
    errorWithSmsSending: "Something went wrong with SMS sending. SMS verification has been turned off",
    timeAddedToExistingCustomer: "Booked time was automatically added to existing customer - but there were differences",
    timeCancelled: "Booked time was cancelled",
    timeChanged: "Booked time was changed",
    newCustomer: "New customer.",
    customerComment: "Customer Comment",
    bookingCancelled: "Booking cancelled",
    bookingChanged: "Booking changed",
  },
  onboarding: {
    user: "User",
    company: "Company",
    workingHours: "Working Hours",
    services: "Services",
    finish: "Finish",
    pickWorkingHours: "Pick Working Hours",
    createServices: "Create Services",
    yourUser: "Your User",
    yourCompany: "Your Company",
  },
  auditOverview: {
    event: "Event",
    time: "Time",
    comment: "Comment",
    noChanges: "Here will be an overview of all changes you make",
    customer: "Customer",
    booking: "Booking",
    settings: "Settings",
    staff: "Staff",
    track: "Track",
    service: "Service",
    readComment: "Read comment on log",
  },
  customerModal: {
    notes: "Notes",
    createdByCustomer: "Created by customer via online booking",
    createdByStaff: "Created by staff",
    statistics: {
      appointmentsThisMonth: "Appointments this month",
      appointmentsOverall: "Appointments overall",
      totalUsedForServicesThisMonth: "Total used for services this month",
      totalUsedForServicesOverall: "Total used for services overall",
    },
    payments: "Payments",
    newJournal: "Add New Journal",
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    dialog: {
      unsavedChangesWarning: "You have unsaved changes in the journal. If you leave the page, your changes will be lost.",
      exitConfirmation: "You have unsaved changes in the journal. Are you sure you want to exit?",
    },
    journal: "Journal",
    generalText: "General Text",
  },
  nameIsRequired: "Name is required",
  amountMustBeOneOrHigher: "Amount must be 1 or higher",
  resources: {
    deleteConfirmation: "Are you sure you want to delete this resource?",
    deleteHeader: "Delete Resource",
    name: "Name",
    description: "Description",
    quantity: "Quantity",
    createResource: "Create Resource",
    editResource: "Edit Resource",
    nameDescription: "Name of the resource",
    descriptionDescription: "Description of the resource",
    quantityDescription: "Quantity of the resource",
  },
  functions: {
    contactUs: "Contact Us",
    customerLogin: "Customer Login",
    invoicesAndPayments: "Invoices and Payments",
    customFields: "Custom Fields",
    newsletter: "Newsletter",
    giftCards: "Gift Cards",
    customerFollowUp: "Customer Follow-Up",
  },
  login: {
    fillEmailPassword: "Please fill in your email and password",
    passwordsMustMatch: "Passwords must match",
    emailInUse: "Email already in use",
    invalidEmail: "Invalid email",
    weakPassword: "Password is too weak",
    userNotFound: "User not found",
    wrongEmailPassword: "Wrong email or password",
    fillEmailForReset: "Please fill in your email to reset your password",
    sendNewPasswordTo: "Send new password to",
    emailSent: "Email sent",
    tryFree: "Try free",
    repeatPassword: "Repeat password",
    password: "Password",
    register: "Register",
    haveAccount: "Already have an account?",
    loginHere: "Login here",
    login: "Login",
    forgot: "Forgot password?",
    next: "Next",
    firstName: "First name",
    lastName: "Last name",
    phoneNumber: "Phone number",
    phoneNumberRequired: "Phone number is required",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    additionalInfo: "Additional Information",
  },
  systemsSettings: {
    selectLanguage: "Select Language",
    languageDescription: "Select the language for the system, both for the dashboard and the booking site.",
    language: "Language",
    timeFormat: "Time Format",
    timeFormatDescription: "Select the time format for the system, both for the dashboard and the booking site.",
    timeZone: "Time Zone",
    timeZoneDescription: "Select the time zone for the system. Contact us to change.",
    defaultCountryCode: "Default Country Code",
    defaultCountryCodeDescription: "Select the default country code for the system. This will be used for setting the default country code on the booking page, and determining the country code for SMS to the staff.",
    currency: "Currency",
    currencyDescription: "Select the currency for the system. This will be used for displaying prices and financial information.",
  },
  user: {
    updateFailed: "Could not update user.",
    smsVerificationError: "Cannot use SMS verification without a subscription.",
    bookingLinkExists: "This company name already exists. Is it your company? Contact us.",
    loginError: "Something went wrong with login. If this problem persists, please contact us.",
    imageUploaded: "Image has been uploaded.",
    imageUploadError: "Image is too large or wrong format. Use JPG or PNG.",
  },
  hideInBooking: "Hide in Booking",
  discount: "Discount",
  newPrice: "New Price",
  assignToAllStaff: "Assign to All Staff",
  appointmentSummary: {
    fromOnlineBooking: "From online booking",
    assignStaff: "Assign staff",
    yourNotes: "Your notes",
  },
  appointmentMenu: {
    moveAppointmentMessage: "Click on a time in the calendar to move the appointment.",
    confirmDeleteAppointment: "Are you sure you want to delete this appointment?",
    deleteAppointmentHeader: "Delete Appointment",
    appointmentDeleted: "The appointment has been deleted.",
    edit: "Edit",
    delete: "Delete",
    findInCalendar: "Find in Calendar",
  },
  customerModalMessages: {
    customerUpdated: "Customer updated.",
    customerUpdateFailed: "Customer update failed.",
  },
  multipleServices: "Multiple Services",
  emailAddress: "Email Address",
  commitChart: {
    currentYear: "Earned {year}",
    previousYear: "Earned {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Move appointment to",
  },
  appointments: {
    creatingAppointment: "Creating appointment...",
    appointmentCreated: "The appointment has been created!",
    overlappingAppointment: "The booking overlaps with another appointment. Do you still want to create the appointment?",
    alreadyBooked: "The appointment you are trying to book has already been booked by someone else.",
    overlappingWithNext: "The appointment you are trying to book overlaps with the next appointment. Please choose a shorter time.",
  },
  customerName: "Customer Name",
  createAppointmentMessage: {
    invalidTimeFormat: "Invalid time format",
    creationCancelled: "Creation cancelled.",
    enterCustomerName: "You must enter a customer name.",
    selectService: "You must select a service.",
    editEndTimeWithoutStart: "Attempted to edit end time without setting start time.",
    createNewCustomer: "Create new customer for {name}",
    appointmentCreationFailed: "Appointment creation failed. Please contact customer service.",
    overlappingAppointment: {
      title: "Overlapping Appointment",
      message: "This time slot overlaps with another appointment. Do you want to create it anyway?",
    },
  },
  bookingPageCustomerSettings: {
    auditLogs: {
      changedAssignmentType: "Changed setting 'Automatic  assignment new customer' to {setting}",
      changedCustomerAccountCreation: "Changed setting 'Create/Assign customer account automatically upon booking' to {setting}",
    },
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Changed setting 'Booking-ID' to {link}",
      changedCommonLink: "Changed setting 'Common-ID' to {link}",
      changedLocationName: "Changed setting 'Location name' to {name}",
    },
    validation: {
      bookingLinkTooShort: "Booking link must contain at least 3 characters",
    },
  },
  mobileHeader: {
    home: "Home",
    calendar: "Calendar",
    customers: "Customers",
    new: "New",
    orders: "Orders",
    settings: "Settings",
    log: "Log",
    statistics: "Statistics",
    overview: "Overview",
    booking: "Booking",
    staff: "Staff",
  },
  password: "Password",
  dialogMessages: {
    confirm: "Confirm",
    cancel: "Cancel",
    no: "No",
  },
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    statistics: {
      serviceName: "Service",
      bookings: "Bookings",
      customers: "Customers",
    },
  },
  serviceCategories: {
    nameRequired: "Name is required",
    confirmDelete: "Are you sure you want to delete this category?",
    deleteHeader: "Delete service",
    editCategory: "Edit category",
    name: "Name",
    cancel: "Cancel",
    save: "Save",
    categoryName: "Category name",
    create: "Create",
    sort: "Sort",
    table: {
      name: "Name",
      sort: "Sort",
    },
    validation: {
      nameRequired: "Category name is required",
    },
    createCategory: "Create category",
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Changed the text in the information box.",
      changedShowInformationBox: "Changed the setting 'Show information box' to {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice: "Changed the setting 'Show service price' to {setting}",
      changedMultipleServices: "Changed the setting 'Multiple services' to {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices: "Changed the setting 'Customer field' to {field}",
    },
  },
  routes: {
    login: "Login",
    login_: "Login", // Note: "login " has a trailing space; adjusted key to "login_"
    Settings: "Settings",
    CompanyInfo: "Company Info",
    SystemSettings: "System Settings",
    Services: "Services",
    Categories: "Categories",
    Resources: "Resources",
    Subscription: "Subscription",
    Accounts: "Accounts",
    BookingAlerts: "Booking Alerts",
    CustomerInformation: "Customer Information",
    Booking: "Booking",
    BookingSide: "Booking Side",
    Options: "Alternativer",
    Staff: "Staff",
    CalendarSettings: "Calendar Settings",
    Newsletter: "Newsletter",
    AuditLog: "Audit Log",
    ExtraServices: "Extra Services",
    BookingInformation: "Booking Information",
    CustomerAccount: "Customer Account",
    Kunder: "Customers",
    Kunde: "Customer",
    Kalender: "Calendar",
    KalenderBestilling: "Calendar Booking",
    Hjem: "Home",
    oAuthCallBack: "OAuth Callback",
    Onboarding: "Onboarding",
    muscleGroupSelector: "Muscle Group Selector",
    Statistikk: "Statistics",
    Eksporter: "Export",
    Bestillinger: "Orders",
    Bestilling: "Order",
    Faktura: "Invoice",
    Betalinger: "Payments",
    Gavekort: "Gift Cards",
    Tjenester: "Services",
    Hendelser: "Events",
    Logg: "Log",
    AdminLogg: "Admin Log",
    Debug: "Debug",
    Register: "Register",
    StaffWorkingHours: "Working Hours",
    StaffGoogleCalendar: "Google Calendar",
    StaffFriday: "Vacation Days",
    StaffServices: "Services",
    StaffInformation: "Staff Information",
    StaffOverview: "Staff Overview",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Hide days outside working hours",
    showServiceNameInEvent: "Show service name in event",
    showTimeInEvent: "Show time in event",
    showStaffInEvent: "Show staff in event",
  },
  statistics: {
    serviceName: "Service Name",
    bookings: "Bookings",
    customers: "Customers",
    allMonths: "All Months",
    data: "Data",
    current: "Current",
    previous: "Previous",
    day: "Day",
    earnings: "Earnings",
    month: "Month",
    year: "Year",
    showPerStaffMember: "Show Per Staff Member",
    appointments: "Appointments",
    totalExpenses: "Total Expenses",
    totalNetProfit: "Total Net Profit",
    commission: "Commission",
  },
  all: "All",
  createService: {
    title: "Create Service",
    editTitle: "Edit Service",
    assignToAllStaff: "Assign to all staff",
    assignToAllStaffDescription: "If checked, the service will be assigned to all staff members.",
    discount: "Discount",
    discountDescription: "If checked, the service will have a discount.",
    newPrice: "New Price",
    newPriceDescription: "The new price of the service.",
    hideInBooking: "Hide in Booking",
    hideInBookingDescription: "Hide the service in the booking page.",
    serviceName: "Service Name",
    serviceNameDescription: "The name of the service.",
    description: "Description",
    descriptionDescription: "The description of the service.",
    price: "Price",
    priceDescription: "The price of the service.",
    duration: "Duration",
    durationDescription: "The duration of the service in minutes.",
    durationForStaff: "Duration for Staff",
    durationForStaffDescription: "The duration in minutes for staff to complete the service.",
    durationForCustomer: "Duration for Customer",
    durationForCustomerDescription: "The duration in minutes shown to customers.",
    color: "Color",
    colorDescription: "The color of the service in the calendar.",
    showCustomColor: "Show custom color",
    showCustomColorDescription: "If checked, the service will have a custom color.",
    customColor: "Custom color",
    customColorDescription: "The custom color of the service.",
    discountPrice: "Discount Price",
    discountPriceDescription: "The discount price of the service.",
    discountPriceChange: "Discount Price Changed",
    discountPriceChangeDescription: "The discount price of the service has been changed.",
    mustPayOnline: "Must Pay Online",
    mustPayOnlineDescription: "If checked, customers must pay online when booking this service.",
    payOnlinePrice: "Online Payment Price",
    payOnlinePriceDescription: "The amount that must be paid online when booking this service.",
  },

  validation: {
    required: "This field is required",
    min: "Value must be at least {value}",
    max: "Value must be less than {value}",
    number: "Must be a valid number",
    email: "Must be a valid email address",
    phone: "Must be a valid phone number",
    length: "Must be less than {value} characters",
    pattern: "Invalid format",
  },
  form: {
    fields: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      city: "City",
      postalCode: "Postal Code",
      message: "Message",
      password: "Password",
      confirmPassword: "Confirm Password",
      birthdate: "Birthdate",
      ssn: "Social Security Number",
      gender: "Gender",
      title: "Title",
      description: "Description",
      price: "Price",
      duration: "Duration",
      color: "Color",
      discount: "Discount",
      onlinePayment: "Online Payment",
    },
    descriptions: {
      name: "Enter your full name",
      email: "Enter your email address",
      phone: "Enter your phone number",
      address: "Enter your address",
      city: "Enter your city",
      postalCode: "Enter your postal code",
      message: "Enter your message",
      password: "Enter your password",
      confirmPassword: "Confirm your password",
      birthdate: "Enter your birthdate",
      ssn: "Enter your social security number",
      gender: "Select your gender",
      title: "Enter title",
      description: "Enter description",
      price: "Enter price",
      duration: "Enter duration",
      color: "Select color",
      discount: "Enter discount",
      onlinePayment: "Configure online payment",
    },
  },
  writeHere: "Write here...",
  registerFirebase: {
    email: "Email",
    password: "Password",
    passwordAgain: "Password again",
    register: "Register",
    emailAlreadyInUse: "Email already in use",
    invalidEmail: "Invalid email",
    passwordTooWeak: "Password too weak",
    emailAndPasswordRequired: "Email and password are required",
    passwordsMustMatch: "Passwords must match",
  },
  placeHolder: {
    typeToSearch: "Type to search",
  },
  selectDate: "Select Date",
  invoiceSettings: {
    kidNumber: {
      title: "KID Number",
      lengthTitle: "KID Length (2-25)",
      lengthDescription: "Set the length of the KID number",
      modTitle: "KID Control Digit",
      modDescription: "Set the control digit for the KID number",
      description: "Configure your KID number settings for invoices",
      invalidKidLength: "Invalid KID number length",
      useKid: "Use KID",
      kidLength: "KID Length (2-25)",
      kidMod: "KID Control Digit",
      agreementNote: "Remember to set up an OCR agreement before using this feature. You can find the form on Nets' website or do this electronically through your bank's website.",
    },
    tax: {
      title: "Value Added Tax (%)",
      description: "Set the default VAT percentage for your invoices",
    },
    taxNumber: {
      title: "Tax Identification Number",
      description: "Set the tax identification number for your invoices. Could be organization number or identification number.",
    },
    bankAccount: {
      title: "Bank Account",
      description: "Set the bank account for your invoices",
    },
    dueDate: {
      title: "Days Until Due Date",
      description: "Set how many days after the invoice date the due date should be set",
    },
    tabs: {
      general: "General",
      kid: "KID",
      dian: "DIAN",
    },
    dian: {
      title: "DIAN Settings",
      description: "Configure your DIAN (Dirección de Impuestos y Aduanas Nacionales) settings",
      comingSoon: "DIAN integration coming soon",
    },
  },
  invoiceList: {
    invoiceNumber: "Invoice No.",
    orderNumber: "Order No.",
    amount: "Amount (USD)",
    customer: "Customer",
    invoiceDate: "Invoice Date",
    dueDate: "Due Date",
    status: "Status",
    download: "Download",
    send: "Send",
    kid: "KID",
    statuses: {
      new: "New",
      sent: "Sent",
      paid: "Paid",
    },
    noInvoices: "No invoices",
    loading: "Loading... Please wait...",
    couldNotFetch: "Could not fetch invoices",
    search: "Search",
    all: "All",
  },
  InvoiceCreator: {
    title: "Create Invoice",
    editTitle: "Edit Invoice",
    close: "Close",
    postalCodeRequired: "Postal code is required",
    cityRequired: "City is required",
    addressRequired: "Address is required",
    amountRules: {
      required: "Amount is required",
      positive: "Amount must be a positive value",
    },
    priceRules: {
      required: "Amount is required",
      positive: "Amount must have a positive value",
    },
    discountRules: {
      maxValue: "Discount cannot be higher than price",
    },
    createInvoice: "Create Invoice",
    createAndSend: "Create and Send",
    cancel: "Cancel",
    markAsPaidCheckbox: "Mark appointment as 'Invoice Created'",
    missingOrgNumber: "Please fill in your organization number in Settings.",
    missingBankAccount: "Please fill in your bank account number in Settings.",
    invoiceCreated: "Invoice created!",
    couldNotCreate: "Could not create invoice",
    customer: {
      name: "Customer Name",
      address: "Customer Address",
      postalCode: "Postal Code",
      city: "City",
      title: "Customer",
    },
    invoice: {
      number: "Invoice No.",
      date: "Invoice Date",
      dueDate: "Due Date",
      description: "Description",
      price: "Price",
      amount: "Amount",
      discount: "Discount",
      vat: "VAT (%)",
      totalAmount: "Total amount to pay (incl. VAT)",
      paymentInfo: "Payment Information",
      title: "Invoice",
      amountToPay: "Amount to pay",
    },
    company: {
      orgNumber: "Org. No.",
      phone: "Phone",
      email: "Email",
      bankAccount: "Bank Account",
      missingBankAccount: "MISSING BANK ACCOUNT",
    },
    invoiceNumber: "Invoice No.",
    invoiceDate: "Invoice Date",
    dueDate: "Due Date",
    totalToPay: "Amount to pay",
    bankAccount: "Bank Account",
    today: "[Today]",
    thirtyDaysFromNow: "[In 30 days]",
    organization: {
      number: "Org. No.",
      missing: "Missing organization number",
      phone: "Phone",
      email: "Email",
    },
  },
  dashboard: {
    hints: {
      title: "Dashboard Hints",
      servicesWithoutStaff: "Services without assigned staff members",
      assignStaff: "Assign Staff",
      serviceUnavailableExplanation: "This service is currently unavailable for booking because no staff member is set to perform it.",
      serviceAssignmentSteps: "To make this service available for booking, follow these steps:",
      step1Settings: "Go to Settings",
      step2StaffMembers: "Select Staff Members",
      step3SelectStaff: "Choose a staff member to edit",
      step4Services: "Go to the Services tab",
      step5EnableService: "Enable this service for the staff member",
      goToSettings: "Go to Settings",
    },
  },
  accountRole: {
    title: "Account Roles",
    editRole: "Edit Role",
    createRole: "Create New Role",
    newRole: "New Role",
    actions: "Actions",
    permissionLevels: {
      none: "None",
      read: "Read",
      readWrite: "Read/Write",
    },
    owner: "Owner",
    ownerRole: "This account has owner privileges and its role cannot be changed.",
  },
  entities: {
    service: "Service",
    customer: "Customer",
    appointment: "Appointment",
    accountRole: "Account Role",
    account: "Account",
    staff: "Staff",
    category: "Category",
    resource: "Resource",
    option: "Option",
    extraService: "Extra Service",
    giftCard: "Gift Card",
    image: "Image",
    notification: "Notification",
    googleCalendar: "Google Calendar",
    event: "Event",
    debugLog: "Debug Log",
    serviceCategory: "Service Category",
    statistics: "Statistics",
    subscription: "Subscription",
    expense: "Expense",
    finance: "Finance",
    inventoryItem: "Inventory Item",
    order: "Order",
    orderItem: "Order Item",
    ledgerEntry: "Ledger Entry",
    oneTimeExpense: "One-Time Expense",
    recurringExpense: "Recurring Expense",
    expenseCategory: "Expense Category",
    stock: "Stock",
    orders: "Orders",
    inventoryItemCategory: "Inventory Item Category",
    cashRegister: "Cash Register",
  },
  actions: {
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    upload: "Upload",
    addStock: "Add Stock",
  },

  actionsPastTense: {
    create: "created",
    edit: "edited",
    delete: "deleted",
    upload: "uploaded",
  },
  appointmentList: {
    createdByCustomer: "Created by Customer",
    status: "Status",
    delete_appointments_message: "Are you sure you want to delete the selected appointments?",
    delete_appointments_header: "Delete Appointments",
    delete_appointments_confirmationMessage: "The selected appointments have been deleted successfully",
  },
  selectStaffMember: "Select Staff Member",
  expenses: {
    date: "Date",
    startDate: "Start Date",
    endDate: "End Date",
  },
  expenseCategory: {
    rent: "Rent",
    utilities: "Utilities",
    inventory: "Inventory",
    commission: "Commission",
    salaries: "Salaries",
    other: "Other",
  },
  expenseCategoryAsAction: {
    inventory: "Purchase stock",
    salaries: "Pay salary",
    rent: "Pay rent",
    utilities: "Purchase utilities",
    commission: "Pay commission",
    other: "Other",
  },
  genericEntityDialog: {
    deleteConfirmation: "Are you sure you want to delete the selected {entity}?",
    deleteHeader: "Delete {entity}",
    deleteSuccess: "{entity} deleted successfully",
    itemCreated: "{entity} created successfully",
    itemUpdated: "{entity} updated successfully",
    itemDeleted: "{entity} deleted successfully",
    confirmDelete: "Are you sure you want to delete the selected {entity}?",
  },
  orderStatus: {
    Completed: "Completed",
    Pending: "Pending",
    Cancelled: "Cancelled",
  },
  orders: {
    createOrder: "Create Order",
    viewOrder: "View Order",
    totalAmount: "Total Amount",
    orderDate: "Order Date",
    status: "Status",
    actions: "Actions",
    orderNumber: "Order Number",
    title: "Orders",
    staffMember: "Staff Member",
    notSet: "Not Set",
    cancel: "Cancel",
    create: "Create",
    cartSummary: "Cart Summary",
    uncategorized: "Uncategorized",
    quantity: "Quantity",
    price: "Price",
    validation: {
      selectCustomer: "Please select a customer",
      addItems: "Please add items to the order",
      amountGreaterThanZero: "Amount must be greater than 0",
      amountLessThanTotal: "Amount must be less than total",
    },
    orderDetails: "Order Details",
    items: "Items",
    item: "Item",
    total: "Total",
    partialPayment: "Partial Payment",
    partialAmount: "Partial Amount",
    partialAmountHint: "Enter partial payment amount",
    anonymousCustomer: "Anonymous Customer",
    unknownCustomer: "Unknown Customer",
    noOrdersToday: "No orders today",
    noPurchasesToday: "No purchases today",
    noProducts: {
      title: "No Products",
      message: "No products available",
      action: "Add Products",
    },
    searchProducts: "Search products...",
    allProducts: "All Products",
    inStock: "in stock",
    outOfStock: "Out of Stock",
    shoppingCart: "Shopping Cart",
    cartEmpty: "Your cart is empty",
    addItemsToStart: "Add items from the catalog to get started",
    subtotal: "Subtotal",
    steps: {
      customerAndStaff: {
        title: "Customer & Staff",
        subtitle: "Select customer and staff member",
      },
      items: {
        title: "Items",
        subtitle: "Select items for order",
      },
      payment: {
        title: "Payment",
        subtitle: "Choose payment method",
      },
    },
    sections: {
      customerInfo: "Customer Information",
      staffInfo: "Staff Member",
      orderDate: "Order Date",
      orderDetails: "Order Details",
      financialDetails: "Financial Details",
    },
    navigation: {
      next: "Next",
      back: "Back",
    },
    labels: {
      customer: "Customer:",
      orderDate: "Order Date:",
      totalAmount: "Total Amount:",
      partialPayment: "Partial Payment:",
      remainingBalance: "Remaining Balance:",
      paymentStatus: "Payment Status:",
    },
    paymentStatus: {
      unpaid: "Unpaid",
      paidInFull: "Paid in Full",
    },
    maximumAmount: "Maximum amount:",
  },
  paymentMethod: {
    Cash: "Cash",
    Card: "Card",
    Transfer: "Transfer",
    Other: "Other",
    title: "Payment Method",
  },
  ledgerEntryFormDTO: {
    total: "Total",
    paid: "Paid",
    remaining: "Remaining",
  },
  ledgerEntry: {
    charge: "Charge",
    payment: "Payment",
    refund: "Refund",
    type: "Type",
    manual: "Manual",
  },
  ledgerEntryType: {
    charge: "Charge",
    payment: "Payment",
    refund: "Refund",
    adjustment: "Adjustment",
  },
  ledgerEntryHeaders: {
    type: "Type",
    source: "Source",
  },
  staffMemberManagement: {
    servicesPerformed: "Services Performed",
    paymentsReceived: "Payments Received",
    productsSold: "Products Sold",
  },
  reoccuringExpense: {
    recurrenceInterval: "Recurrence Interval",
    intervals: {
      daily: "Daily",
      monthly: "Monthly",
      quarterly: "Quarterly",
      yearly: "Yearly",
    },
  },
  export: {
    dailyFinancialReport: "Daily Financial Report",
    downloadDailyReport: "Download Daily Report",
  },
  itemTooltips: {
    clickForDetails: "Click for details",
  },
  customerStatistics: {
    appointments: {
      thisMonth: "Appointments this month",
      total: "Total appointments",
    },
    moneySpent: {
      title: "Money spent",
      thisMonth: "Money spent this month",
      total: "Total money spent",
    },
    ranking: {
      title: "Customer ranking",
      thisYear: "This year",
      overall: "Overall",
      ofCustomers: "of customers",
    },
    products: {
      thisMonth: "Products this month",
      total: "Total products",
    },
    charts: {
      mostUsedServices: "Most used services",
      mostPurchasedProducts: "Most purchased products",
      spendingHistory: "Spending history",
    },
  },
  moneyAccountOverview: {
    totalBalance: "Total Balance",
    totalIncomingToday: "Today's Income",
    totalOutgoingToday: "Today's Expenses",
    totalNetChangeToday: "Today's Net Change",
    ofDailyTotal: "of daily total",
    changeFromTotal: "change from total",
    transactionDistribution: "Transaction Distribution",
    incoming: "Incoming",
    outgoing: "Outgoing",
    addMoney: "Add Money",
    addMoneyDescription: "Add money manually to the cash register",
    manualEntry: "Manual Entry",
    amount: "Amount",
    description: "Description"
  },
  addStockFormDTO: {
    totalPrice: "Total Price",
    unitPrice: "Unit Price",
  },
  appointmentView: {
    timeFormat: {
      am: "AM",
      pm: "PM"
    },
    customerDetails: "Customer Details",
    appointmentDetails: "Appointment Details",
    financialDetails: "Financial Details"
  },
  commissionSettlement: {
    title: "Staff Commission Settlement",
    services: "Services Commission",
    products: "Products Commission",
    total: "Total Commission",
    payCommission: "Pay Commission",
    paid: "Paid",
    noEligibleStaff: "No eligible staff members with commission for this date",
    unassignedAppointmentsWarning: "There are appointments without assigned staff members. Please assign staff before continuing:",
    assign: "Assign",
    assignStaffTitle: "Assign Staff Member",
    selectStaff: "Select Staff Member",
    selectMoneyAccountError: "Please select a money account before paying commission",
    commissionPayment: "Commission Payment",
    commissionPaidSuccess: "Commission successfully paid to",
    commissionPaymentError: "Error paying commission. Please try again.",
    staffAssignedSuccess: "Staff member successfully assigned to appointment",
    staffAssignmentError: "Error assigning staff member. Please try again."
  },
};

export default en;
