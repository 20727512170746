<template>
  <div class="select-sales-items">
    <!-- Main Layout -->
    <v-row>
      <!-- Product Catalog Section -->
      <v-col cols="12" md="8">
        <!-- Search and Category Filter Bar -->
        <div class="filter-bar d-flex align-center mb-6">
          <v-text-field
            v-model="searchQuery"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t($ts.orders.searchProducts)"
            outlined
            dense
            clearable
            hide-details
            class="search-field"
          ></v-text-field>

          <v-chip-group v-model="selectedCategory" active-class="primary--text" class="ml-4 category-chips" show-arrows>
            <v-chip filter outlined :value="null" :class="{ 'selected-category': selectedCategory === null }">
              {{ $t($ts.orders.allProducts) }}
            </v-chip>
            <v-chip
              v-for="category in Array.from(itemsByCategory.entries())"
              :key="category[0]"
              filter
              outlined
              :value="category[0]"
              :class="{ 'selected-category': selectedCategory === category[0] }"
            >
              <v-icon left small>{{ getCategoryIcon(category[0]) }}</v-icon>
              {{ getCategoryName(category[0]) }}
              <v-chip x-small label class="ml-2" :color="selectedCategory === category[0] ? 'primary' : 'grey'">
                {{ category[1].length }}
              </v-chip>
            </v-chip>
          </v-chip-group>
        </div>

        <!-- Products Grid -->
        <v-row>
          <v-col
            v-for="item in filteredItems"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            xl="3"
            class="d-flex product-col pa-2"
          >
            <v-hover v-slot="{ hover }">
              <v-card class="product-card" :elevation="hover ? 4 : 1" :class="{ 'on-hover': hover }">
                <!-- Product Image -->
                <div class="product-image-container">
                  <v-img
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                    height="140"
                    cover
                    class="white--text align-end product-image"
                  >
                    <!-- Stock Badge -->
                    <v-chip
                      v-if="item.currentStock > 0"
                      small
                      label
                      :color="getStockColor(item.currentStock, item.minimumStockThreshold)"
                      class="stock-badge ma-2"
                      text-color="white"
                    >
                      {{ item.currentStock }} {{ $t($ts.orders.inStock) }}
                    </v-chip>
                  </v-img>
                  <v-img v-else height="140" class="grey lighten-2">
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-icon size="48" color="grey">mdi-image-outline</v-icon>
                    </v-row>
                    <!-- Stock Badge -->
                    <v-chip
                      v-if="item.currentStock > 0"
                      small
                      label
                      :color="getStockColor(item.currentStock, item.minimumStockThreshold)"
                      class="stock-badge ma-2"
                      text-color="white"
                    >
                      {{ item.currentStock }} {{ $t($ts.orders.inStock) }}
                    </v-chip>
                  </v-img>

                  <!-- Out of Stock Overlay -->
                  <v-overlay v-if="item.currentStock <= 0" absolute color="grey darken-3" opacity="0.7">
                    <div class="text-center px-2">
                      <v-chip color="error" small label>{{ $t($ts.orders.outOfStock) }}</v-chip>
                    </div>
                  </v-overlay>

                  <!-- Add to Cart Overlay -->
                  <v-overlay
                    v-if="hover && item.currentStock > 0"
                    absolute
                    :opacity="0.7"
                    color="success"
                    class="add-overlay"
                    @click="quickAddToCart(item)"
                  >
                    <v-icon size="48" color="white" :class="{ 'click-animation': isAddingItem === item.id }"
                      >mdi-plus-circle</v-icon
                    >
                  </v-overlay>
                </div>

                <v-card-title class="product-title py-1 px-2">{{ item.name }}</v-card-title>

                <v-card-text class="py-1 px-2">
                  <div class="d-flex align-center justify-space-between">
                    <span class="text-subtitle-1 primary--text font-weight-bold">
                      {{ formatCurrency(item.salePrice) }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>

      <!-- Cart Section -->
      <v-col cols="12" md="4">
        <v-card class="cart-card" elevation="2">
          <v-card-title class="cart-header primary">
            <span class="white--text">{{ $t($ts.orders.shoppingCart) }}</span>
            <v-spacer></v-spacer>
            <v-chip outlined dark>{{ totalItems }} {{ $t($ts.orders.items) }}</v-chip>
          </v-card-title>

          <v-list class="cart-items py-0">
            <template v-if="cart.length > 0">
              <v-list-item v-for="item in cart" :key="item.id" class="cart-item py-2">
                <v-list-item-avatar tile size="50" class="rounded-lg">
                  <v-img :src="item.imageUrl" v-if="item.imageUrl" cover></v-img>
                  <v-icon v-else size="32" class="grey lighten-2">mdi-image-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                  <v-list-item-title class="subtitle-2">{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    <span class="primary--text">{{ formatCurrency(item.salePrice) }}</span>
                    <span class="mx-1">×</span>
                    <span>{{ item.quantity }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="my-0">
                  <div class="d-flex flex-column align-end">
                    <span class="caption primary--text font-weight-medium mb-1">
                      {{ formatCurrency(item.quantity * item.salePrice) }}
                    </span>
                    <v-btn
                      icon
                      small
                      color="error"
                      class="remove-btn"
                      @click.stop="removeFromCart(item)"
                      :ripple="false"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </template>

            <v-scale-transition>
              <div v-if="cart.length === 0" class="empty-cart pa-8 text-center">
                <v-icon size="64" color="grey lighten-1">mdi-cart-outline</v-icon>
                <div class="mt-4 text-h6 grey--text">{{ $t($ts.orders.cartEmpty) }}</div>
                <div class="subtitle-2 grey--text text--darken-1">{{ $t($ts.orders.addItemsToStart) }}</div>
              </div>
            </v-scale-transition>
          </v-list>

          <template v-if="cart.length > 0">
            <v-divider></v-divider>
            <v-card-text class="cart-summary pa-4">
              <div class="d-flex justify-space-between mb-2">
                <span class="subtitle-1">{{ $t($ts.orders.subtotal) }}</span>
                <span class="subtitle-1">{{ formatCurrency(totalAmount) }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <span class="text-h6">{{ $t($ts.orders.total) }}</span>
                <span class="text-h6 primary--text">{{ formatCurrency(totalAmount) }}</span>
              </div>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IInventoryItemDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";

interface CartItem extends IInventoryItemDTO {
  quantity: number;
}

@Component({
  name: "select-sales-items",
})
export default class SelectSalesItems extends Vue {
  @Prop({ type: Array, required: true }) readonly inventoryItems!: IInventoryItemDTO[];

  private cart: CartItem[] = [];
  private expandedPanel: number[] = [0]; // First panel expanded by default
  private quantityOverrides: { [key: number]: number } = {}; // For manual quantity changes
  private searchQuery: string = "";
  private selectedCategory: number | null = null;
  private isAddingItem: number | null = null;

  get itemQuantities(): { [key: number]: number } {
    const quantities: { [key: number]: number } = {};
    this.inventoryItems.forEach((item) => {
      // Use override if it exists, otherwise use default value
      quantities[item.id] =
        this.quantityOverrides[item.id] !== undefined ? this.quantityOverrides[item.id] : item.currentStock > 0 ? 1 : 0;
    });
    return quantities;
  }

  get itemsByCategory(): Map<number, IInventoryItemDTO[]> {
    const groupedItems = new Map<number, IInventoryItemDTO[]>();

    this.inventoryItems.forEach((item) => {
      const categoryId = item.categoryId || 0;
      if (!groupedItems.has(categoryId)) {
        groupedItems.set(categoryId, []);
      }
      const items = groupedItems.get(categoryId);
      if (items) {
        items.push(item);
      }
    });

    return groupedItems;
  }

  get filteredItems(): IInventoryItemDTO[] {
    let items =
      this.selectedCategory !== null ? this.itemsByCategory.get(this.selectedCategory) || [] : this.inventoryItems;

    const searchQuery = this.searchQuery.toLowerCase();
    return items.filter(
      (item) =>
        searchQuery === "" ||
        item.name.toLowerCase().includes(searchQuery) ||
        item.description?.toLowerCase().includes(searchQuery)
    );
  }

  get cartItemsByCategory(): Map<number, CartItem[]> {
    const groupedItems = new Map<number, CartItem[]>();

    this.cart.forEach((item) => {
      const categoryId = item.categoryId || 0;
      if (!groupedItems.has(categoryId)) {
        groupedItems.set(categoryId, []);
      }
      const items = groupedItems.get(categoryId);
      if (items) {
        items.push(item);
      }
    });

    return groupedItems;
  }

  get cartItems(): CartItem[] {
    return this.cart;
  }

  get totalItems(): number {
    return this.cart.reduce((sum, item) => sum + item.quantity, 0);
  }

  get totalAmount(): number {
    return this.cart.reduce((sum, item) => sum + item.quantity * item.salePrice, 0);
  }

  getCategoryName(categoryId: number): string {
    if (categoryId === 0) return this.$t(this.$ts.orders.uncategorized).toString();
    const item = this.inventoryItems.find((item) => item.categoryId === categoryId);
    return item?.category?.name || this.$t(this.$ts.orders.uncategorized).toString();
  }

  getCategoryIcon(categoryId: number): string {
    // You can customize this based on your category types
    const iconMap: { [key: number]: string } = {
      0: "mdi-package-variant", // Default
      1: "mdi-food", // Example: Food category
      2: "mdi-tshirt-crew", // Example: Clothing category
      3: "mdi-laptop", // Example: Electronics category
    };
    return iconMap[categoryId] || "mdi-package-variant";
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }

  getStockColor(currentStock: number, threshold: number): string {
    if (currentStock <= 0) return "error";
    if (currentStock <= threshold) return "warning";
    return "success";
  }

  getQuantityError(item: IInventoryItemDTO): string {
    const quantity = this.itemQuantities[item.id] || 0;
    const existingCartItem = this.cart.find((i) => i.id === item.id);
    const cartQuantity = existingCartItem?.quantity || 0;

    if (item.currentStock <= 0) {
      return "Out of stock";
    }

    if (quantity > item.currentStock - cartQuantity) {
      return `Maximum available: ${item.currentStock - cartQuantity}`;
    }

    return "";
  }

  canAddToCart(item: IInventoryItemDTO): boolean {
    const quantity = this.itemQuantities[item.id] || 0;
    const existingCartItem = this.cart.find((i) => i.id === item.id);
    const cartQuantity = existingCartItem?.quantity || 0;

    return item.currentStock > 0 && quantity > 0 && quantity <= item.currentStock - cartQuantity;
  }

  updateQuantity(itemId: number, value: string) {
    const numValue = parseInt(value) || 0;
    const item = this.inventoryItems.find((i) => i.id === itemId);

    if (!item) return;

    if (numValue > item.currentStock) {
      this.quantityOverrides[itemId] = item.currentStock;
    } else if (numValue < 0) {
      this.quantityOverrides[itemId] = 0;
    } else {
      this.quantityOverrides[itemId] = numValue;
    }

    this.$emit("update:quantities", this.itemQuantities);
  }

  async addToCart(item: IInventoryItemDTO) {
    const quantity = this.itemQuantities[item.id] || 0;
    if (quantity <= 0) return;

    const existingItem = this.cart.find((i) => i.id === item.id);
    if (existingItem) {
      const newQuantity = existingItem.quantity + quantity;
      // Check if new total quantity would exceed stock
      if (newQuantity > item.currentStock) {
        return;
      }
      existingItem.quantity = newQuantity;
    } else {
      this.cart.push({ ...item, quantity });
    }

    // Reset quantity after adding to cart
    this.quantityOverrides[item.id] = 1;
    this.$emit("update:cart", this.cart);
  }

  removeFromCart(item: CartItem) {
    const index = this.cart.findIndex((i) => i.id === item.id);
    if (index > -1) {
      this.cart.splice(index, 1);
      this.$emit("update:cart", this.cart);
    }
  }

  async quickAddToCart(item: IInventoryItemDTO) {
    if (item.currentStock <= 0) return;

    this.isAddingItem = item.id;

    const existingItem = this.cart.find((i) => i.id === item.id);
    if (existingItem) {
      // Check if adding one more would exceed stock
      if (existingItem.quantity + 1 > item.currentStock) {
        this.isAddingItem = null;
        return;
      }
      existingItem.quantity += 1;
    } else {
      this.cart.push({ ...item, quantity: 1 });
    }

    this.$emit("update:cart", this.cart);

    // Reset the animation after a short delay
    setTimeout(() => {
      this.isAddingItem = null;
    }, 300);
  }

  @Watch("inventoryItems", { immediate: true })
  onInventoryItemsChange() {
    // Reset overrides when inventory items change
    this.quantityOverrides = {};
  }
}
</script>

<style lang="scss" scoped>
.select-sales-items {
  .filter-bar {
    background: white;
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 2;

    .search-field {
      min-width: 260px;
      max-width: 320px;
    }

    .category-chips {
      flex-wrap: nowrap;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .selected-category {
    background-color: var(--v-primary-lighten4);
    border-color: var(--v-primary-base);
  }

  .product-col {
    display: flex;
  }

  .product-card {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    flex-direction: column;

    &:hover {
      transform: translateY(-2px);
    }

    .product-image-container {
      position: relative;
      background: #f5f5f5;
      cursor: pointer;

      .product-image {
        transition: all 0.3s ease;
      }

      .stock-badge {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }

      .add-overlay {
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          .v-icon {
            transform: scale(1.2);
          }
        }

        .v-icon {
          transition: transform 0.2s ease;

          &.click-animation {
            animation: clickPulse 0.3s ease-out;
          }
        }
      }
    }

    .product-title {
      font-size: 0.95rem;
      line-height: 1.2;
      height: 36px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .cart-card {
    position: sticky;
    top: 24px;
    border-radius: 12px;
    overflow: hidden;

    .cart-header {
      padding: 16px;
    }

    .cart-items {
      max-height: calc(100vh - 300px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
      }
    }

    .cart-item {
      transition: background-color 0.2s ease;
      position: relative;

      &:hover {
        background-color: var(--v-primary-lighten5);

        .remove-btn {
          opacity: 1;
          transform: translateX(0);
        }
      }

      .remove-btn {
        opacity: 0.7;
        transition: all 0.2s ease;
        transform: translateX(4px);
        border: 1px solid rgba(var(--v-error-base), 0.12);

        &:hover {
          opacity: 1;
          background-color: var(--v-error-lighten5);
          transform: scale(1.1);
        }
      }
    }

    .empty-cart {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .cart-summary {
      background-color: var(--v-primary-lighten5);
    }
  }
}

@keyframes clickPulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
