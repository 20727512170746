import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { NotificationService } from "@/services/api/NotificationService";
import { INotificationDTO } from "@shared/types";
import { i18n } from "@/main";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class NotificationsModule extends VuexModule {
  private notificationService = NotificationService.getInstance();

  // State
  notifications: INotificationDTO[] = [];
  unreadNotificationsCount = 0;

  @Action
  async getNotifications(): Promise<void> {
    const data = await this.notificationService.getNotificationsForCurrentUser();
    this.notifications = data.map((notification) => ({
      ...notification,
      createdTime: new Date(notification.createdTime),
      displayTime: new Date(notification.createdTime).toLocaleString(i18n.locale).substring(0, 16),
    }));
    this.unreadNotificationsCount = this.notifications.filter((n) => !n.hasSeen).length;
  }

  @Action
  async addNotification(notification: INotificationDTO): Promise<void> {
    await this.notificationService.postNotification(notification);
    await this.getNotifications();
  }

  @Action
  async markAllAsRead(): Promise<void> {
    await this.notificationService.markAllNotifcationsAsRead();
    this.notifications.forEach((notification) => (notification.hasSeen = true));
    await this.getNotifications();
  }
}

export const notificationsModule = new NotificationsModule({ store, name: "notificationsModule" });
