import { VuexModule, Module, Action, Mutation } from "vuex-class-modules";
import store from "@/store/store";
import { IServiceStatStaffMemberDTO } from "@shared/types";
import { IProductStatDTO } from "@shared/types";
import { IPaymentStatDTO } from "@shared/types";
import { IStaffMemberController } from "@shared/types";
import { Container } from "@/services/api/Container";

@Module({ generateMutationSetters: true })
class StaffMemberStatisticsModule extends VuexModule {
  private readonly staffMemberService: IStaffMemberController;

  constructor(options: any) {
    super(options);
    this.staffMemberService = Container.getInstance().get<IStaffMemberController>("IStaffMemberController");
  }

  // State
  currentStatisticsPayments: IPaymentStatDTO[] = [];
  currentStatisticsProducts: IProductStatDTO[] = [];
  currentStatisticsServices: IServiceStatStaffMemberDTO[] = [];

  // Mutations
  @Mutation
  setStatisticsPayments(payments: IPaymentStatDTO[]) {
    this.currentStatisticsPayments = [...payments];
  }

  @Mutation
  setStatisticsProducts(products: IProductStatDTO[]) {
    this.currentStatisticsProducts = [...products];
  }

  @Mutation
  setStatisticsServices(services: IServiceStatStaffMemberDTO[]) {
    this.currentStatisticsServices = [...services];
  }

  // Actions
  @Action
  async getStaffMemberStatistics(payload: { staffMemberId: number; year: number; month: number; day: number }) {
    console.log("[Store Action] Getting statistics with payload:", JSON.stringify(payload, null, 2));
    console.log("[Store Action] Calling staffMemberService.getStaffMemberStatistics");

    const statistics = await this.staffMemberService.getStaffMemberStatistics(
      payload.staffMemberId,
      payload.year,
      payload.month,
      payload.day
    );

    console.log("[Store Action] Raw API Response:", JSON.stringify(statistics, null, 2));

    this.setStatisticsPayments(statistics.payments);
    this.setStatisticsProducts(statistics.products);
    this.setStatisticsServices(statistics.services);

    return statistics;
  }
}

export const staffMemberStatisticsModule = new StaffMemberStatisticsModule({
  store,
  name: "staffMemberStatisticsModule",
});
