import axios from "axios";
import * as Sentry from "@sentry/vue";

// Add at the top of the file
declare module "axios" {
  export interface AxiosRequestConfig {
    sentrySpan?: any; // or more specific Sentry span type if available
  }
}

// Helper to safely create spans
const startSpan = (config) => {
  try {
    let span;
    Sentry.startSpan(
      {
        name: `${config.method?.toUpperCase()} ${config.url}`,
        op: "http.client",
      },
      (s) => {
        span = s;
      } // Capture the span in closure
    );
    return span;
  } catch (e) {
    console.warn("Failed to create Sentry span:", e);
    return null;
  }
};

axios.interceptors.request.use(
  (config) => {
    const span = startSpan(config);
    if (span) {
      config.sentrySpan = span;
    }

    return config;
  },
  (error) => {
    Sentry.captureException(error, {
      extra: { type: "request_error" },
    });
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.config.sentrySpan) {
      response.config.sentrySpan.end();
    }

    Sentry.addBreadcrumb({
      category: "my-xhr",
      type: "http",
      level: "info",
      message: `${response.config.method?.toUpperCase()} ${
        response.config.url
      }`,
      data: {
        status: response.status,
        statusText: response.statusText,
        url: response.config.url,
      },
    });

    return response;
  },
  (error) => {
    if (error.config?.sentrySpan) {
      error.config.sentrySpan.end();
    }

    Sentry.captureException(error, {
      extra: {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        statusText: error.response?.statusText,
        responseData: error.response?.data,
      },
    });

    return Promise.reject(error);
  }
);
