<template>
  <responsive-table :items="loading ? skeletonItems : services">
    <!-- Desktop Headers -->
    <template v-slot:headers>
      <th class="text-left">{{ $t($ts.name) }}</th>
      <th class="text-left">{{ $t($ts.sort) }}</th>
      <th class="text-left">{{ $t($ts.price) }}</th>
      <th class="text-left">{{ $t($ts.duration) }}</th>
      <th v-if="!isExtra" class="text-left">{{ $t($ts.category) }}</th>
      <th v-if="!isExtra" class="text-left">{{ $t($ts.extraServices) }}</th>
      <th class="text-left"></th>
      <th class="text-left"></th>
    </template>

    <!-- Desktop Row -->
    <template v-slot:desktop-row="{ item: service }">
      <td>
        <v-skeleton-loader v-if="loading" type="text" width="120"></v-skeleton-loader>
        <template v-else>{{ service.title }}</template>
      </td>
      <td>
        <v-skeleton-loader v-if="loading" type="button" width="80"></v-skeleton-loader>
        <template v-else>
          <v-icon @click="onReorder(String(service.id), -1)">expand_more</v-icon>
          <v-icon @click="onReorder(String(service.id), 1)">expand_less</v-icon>
        </template>
      </td>
      <td>
        <v-skeleton-loader v-if="loading" type="text" width="80"></v-skeleton-loader>
        <template v-else>{{ $t($ts.currency) }} {{ formatCurrency(service.price) }}</template>
      </td>
      <td>
        <v-skeleton-loader v-if="loading" type="text" width="60"></v-skeleton-loader>
        <template v-else>{{ service.durationInMinutes }} min</template>
      </td>
      <td v-if="!isExtra" style="max-width: 150px">
        <v-skeleton-loader v-if="loading" type="list-item" width="120"></v-skeleton-loader>
        <v-select
          v-else-if="categories"
          :value="getCategoryTitleById(String(service.categoryId))"
          @change="onCategoryChange($event, String(service.id))"
          :items="categoryOptions"
        ></v-select>
      </td>
      <td v-if="!isExtra" style="max-width: 150px">
        <v-skeleton-loader v-if="loading" type="list-item" width="120"></v-skeleton-loader>
        <v-select
          v-else-if="extraServices"
          item-value="id"
          item-text="title"
          attack
          chips
          multiple
          v-model="service.children"
          @change="onExtraServicesChange($event, String(service.id))"
          :items="extraServices"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span v-if="item.title.length >= 10">{{ item.title.substr(0, 10) }}...</span>
              <span v-if="item.title.length < 10">{{ item.title }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ service.children.length - 1 }}) </span>
          </template>
        </v-select>
      </td>
      <td>
        <v-skeleton-loader v-if="loading" type="button" width="40"></v-skeleton-loader>
        <v-icon v-else @click="$emit('edit', service)">edit</v-icon>
      </td>
      <td>
        <v-skeleton-loader v-if="loading" type="button" width="40"></v-skeleton-loader>
        <v-icon v-else @click="$emit('delete', String(service.id))">delete</v-icon>
      </td>
    </template>

    <!-- Mobile Header -->
    <template v-slot:mobile-header="{ item: service }">
      <div class="d-flex align-center justify-space-between" style="width: 100%">
        <div class="service-title">
          <v-skeleton-loader v-if="loading" type="text" width="120"></v-skeleton-loader>
          <template v-else>{{ service.title }}</template>
        </div>
        <div class="service-price">
          <v-skeleton-loader v-if="loading" type="text" width="80"></v-skeleton-loader>
          <template v-else>{{ $t($ts.currency) }} {{ formatCurrency(service.price) }}</template>
        </div>
      </div>
    </template>

    <!-- Mobile Content -->
    <template v-slot:mobile-content="{ item: service }">
      <div class="mb-4">
        <div class="d-flex align-center mb-2">
          <v-icon small class="mr-2">mdi-clock-outline</v-icon>
          <span>
            <v-skeleton-loader v-if="loading" type="text" width="60"></v-skeleton-loader>
            <template v-else>{{ service.durationInMinutes }} min</template>
          </span>
        </div>

        <div v-if="!isExtra" class="mb-4">
          <div class="caption grey--text mb-1">
            {{ $t($ts.category) }}
          </div>
          <v-skeleton-loader v-if="loading" type="list-item" width="120"></v-skeleton-loader>
          <v-select
            v-else-if="categories"
            dense
            :value="getCategoryTitleById(String(service.categoryId))"
            @change="onCategoryChange($event, String(service.id))"
            :items="categoryOptions"
            class="mt-0 pt-0"
          ></v-select>
        </div>

        <div v-if="!isExtra" class="mb-4">
          <div class="caption grey--text mb-1">
            {{ $t($ts.extraServices) }}
          </div>
          <v-skeleton-loader v-if="loading" type="list-item" width="120"></v-skeleton-loader>
          <v-select
            v-else-if="extraServices"
            dense
            item-value="id"
            item-text="title"
            chips
            multiple
            v-model="service.children"
            @change="onExtraServicesChange($event, String(service.id))"
            :items="extraServices"
            class="mt-0 pt-0"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip small v-if="index === 0">
                <span v-if="item.title.length >= 10">{{ item.title.substr(0, 10) }}...</span>
                <span v-if="item.title.length < 10">{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ service.children.length - 1 }}) </span>
            </template>
          </v-select>
        </div>

        <div class="d-flex align-center justify-space-between mt-4">
          <div class="d-flex align-center">
            <v-skeleton-loader v-if="loading" type="button" width="80"></v-skeleton-loader>
            <template v-else>
              <v-btn small icon class="mr-2" @click="onReorder(String(service.id), -1)">
                <v-icon>expand_more</v-icon>
              </v-btn>
              <v-btn small icon @click="onReorder(String(service.id), 1)">
                <v-icon>expand_less</v-icon>
              </v-btn>
            </template>
          </div>

          <div class="d-flex">
            <v-skeleton-loader v-if="loading" type="button" width="80"></v-skeleton-loader>
            <template v-else>
              <v-btn small icon class="mr-2" @click="$emit('edit', service)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn small icon color="error" @click="$emit('delete', String(service.id))">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </template>
  </responsive-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import type { IAppointmentTypeDTO } from "@shared/types";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";
import { formatCurrency } from "@/Utilities/currencyHelper";
import { LocalStorageService } from "@/services/LocalStorageService";

interface Category {
  id: string;
  title: string;
}

@Component({
  name: "treatment-type-table",
  components: {
    ResponsiveTable,
  },
})
export default class TreatmentTypeTable extends Vue {
  @Prop({ required: true }) services!: IAppointmentTypeDTO[];
  @Prop({ required: true }) categories!: Category[];
  @Prop({ required: true }) extraServices!: IAppointmentTypeDTO[];
  @Prop({ required: true }) isExtra!: boolean;
  @Prop({ default: false }) loading!: boolean;

  get categoryOptions(): string[] {
    return this.categories.map((x) => x.title);
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  getCategoryTitleById(id: string): string {
    const cat = this.categories.find((x) => x.id === id);
    return cat?.title || "";
  }

  getCategoryIdByTitle(title: string): string {
    const cat = this.categories.find((x) => x.title === title);
    return cat?.id ?? "";
  }

  onReorder(serviceId: string, direction: number): void {
    this.$emit("reorder", { serviceId, direction });
  }

  onCategoryChange(categoryName: string, serviceId: string): void {
    const categoryId = this.getCategoryIdByTitle(categoryName);
    this.$emit("category-change", { serviceId, categoryId, order: 1 });
  }

  onExtraServicesChange(extraServices: IAppointmentTypeDTO[], serviceId: string): void {
    this.$emit("extra-services-change", { serviceId, extraServices });
  }

  get skeletonItems(): any[] {
    const count = LocalStorageService.getItemCountOrDefault("services");
    return Array(count).fill({
      id: "skeleton",
      title: "",
      price: 0,
      durationInMinutes: 0,
      categoryId: "",
      children: [],
    });
  }
}
</script>

<style scoped>
.service-title {
  font-weight: 500;
}

.service-price {
  font-weight: 500;
  color: #7441ff;
}
</style>
