//************************************************************************************ //******************************
EXAMPLE USAGE **************************************
<!--
<date-picker
        @cancel="cancel"
        @save="save"
        :isShowingDatePicker="isShowingDatePicker">
</date-picker>
-->
**************************************************************************************
//************************************************************************************

<template>
  <v-dialog v-model="isShowingDatePicker" persistent width="290">
    <v-date-picker
      ref="picker"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      locale="nb-no"
      color="#876AFF"
      v-model="selectedDate"
      scrollable
      actions
    >
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleCancelEvent">Avbryt</v-btn>
          <v-btn color="success" @click="handleSaveEvent">OK</v-btn>
        </v-card-actions>
      </template>
    </v-date-picker>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "DatePicker",
})
export default class DatePicker extends Vue {
  @Prop({ default: false }) isShowingDatePicker: boolean;
  public selectedDate: Date | string = "";

  @Watch("isShowingDatePicker")
  onChanged(n: boolean): void {
    if (n) {
      setTimeout(() => {
        const picker: any = this.$refs.picker;
        picker.activePicker = "YEAR";
      }, 10);
    }
  }

  public handleCancelEvent(event: string): void {
    this.$emit("cancel", event);
  }

  public handleSaveEvent(event: string): void {
    this.$emit("save", event, this.selectedDate);
  }
}
</script>

<style scoped lang="scss">
.application--wrap {
  position: absolute;
}

.v-card__actions {
  padding: 0 !important;
}
.v-card {
  padding: 0 !important;
}
.v-card__title {
  padding: 0 !important;
}
.form-wrapper {
  p {
    margin-left: 0 !important;
    margin-bottom: 1px;
  }
}
</style>
