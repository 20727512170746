import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType, Status } from "./Events/ServiceEvents";
import {
  IInventoryItemCategoryController,
  IInventoryItemCategoryDTO,
  IPostInventoryItemCategoryDTO,
  IUpdateInventoryItemCategoryDTO,
} from "@shared/types";

export class InventoryItemCategoryService extends EventBaseService implements IInventoryItemCategoryController {
  private static instance: InventoryItemCategoryService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!InventoryItemCategoryService.instance) {
      InventoryItemCategoryService.instance = new InventoryItemCategoryService();
    }
    return InventoryItemCategoryService.instance;
  }

  public async getCategories() {
    return this.http.sendRequest<IInventoryItemCategoryDTO[]>(IInventoryItemCategoryController.GetCategoriesRoute);
  }

  public async getCategory(id: number) {
    return this.http.sendRequest<IInventoryItemCategoryDTO>(IInventoryItemCategoryController.GetCategoryRoute, {
      params: { id },
    });
  }

  public async createCategory(dto: IPostInventoryItemCategoryDTO) {
    return this.withEventEmission(Feature.Inventory, EventType.Added, () =>
      this.http.sendRequest<IInventoryItemCategoryDTO>(IInventoryItemCategoryController.CreateCategoryRoute, {
        data: dto,
      })
    );
  }

  public async updateCategory(id: number, dto: IUpdateInventoryItemCategoryDTO) {
    return this.withEventEmission(Feature.Inventory, EventType.Updated, () =>
      this.http.sendRequest<IInventoryItemCategoryDTO>(IInventoryItemCategoryController.UpdateCategoryRoute, {
        params: { id },
        data: dto,
      })
    );
  }

  public async deleteCategory(id: number) {
    return this.withEventEmission(Feature.Inventory, EventType.Deleted, () =>
      this.http.sendRequest<IInventoryItemCategoryDTO>(IInventoryItemCategoryController.DeleteCategoryRoute, {
        params: { id },
      })
    );
  }
}
