export enum Feature {
  User = "USER",
  Appointment = "APPOINTMENT",
  Service = "SERVICE",
  StaffMember = "STAFF_MEMBER",
  Notification = "NOTIFICATION",
  GoogleCalendar = "GOOGLE_CALENDAR",
  Event = "EVENT",
  DebugLog = "DEBUG_LOG",
  Resource = "RESOURCE",
  ServiceCategory = "SERVICE_CATEGORY",
  Statistics = "STATISTICS",
  Subscription = "SUBSCRIPTION",
  Image = "IMAGE",
  Customer = "CUSTOMER",
  Finance = "FINANCE",
  InventoryItem = "INVENTORY_ITEM",
  Expense = "EXPENSE",
  LedgerEntry = "LEDGER_ENTRY",
  Order = "ORDER",
  OrderItem = "ORDER_ITEM",
  OneTimeExpense = "ONE_TIME_EXPENSE",
  RecurringExpense = "RECURRING_EXPENSE",
  ExpenseCategory = "EXPENSE_CATEGORY",
  Inventory = "Inventory",
}

export enum EventType {
  Deleted = "DELETED",
  Added = "ADDED",
  Updated = "UPDATED",
  Uploaded = "UPLOADED",
}

export enum Status {
  Failed = "FAILED",
  Success = "SUCCESS",
  InProgress = "IN_PROGRESS",
}

export interface ServiceEvent {
  feature: Feature;
  type: EventType;
  status: Status;
  message?: string;
}

export type ServiceEventHandler = (event: ServiceEvent) => void;

export class EventEmitter {
  private static instance: EventEmitter;
  private handlers: ServiceEventHandler[] = [];

  private constructor() {}

  public static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  }

  public subscribe(handler: ServiceEventHandler): void {
    this.handlers.push(handler);
  }

  public unsubscribe(handler: ServiceEventHandler): void {
    this.handlers = this.handlers.filter((h) => h !== handler);
  }

  public emit(event: ServiceEvent): void {
    this.handlers.forEach((handler) => handler(event));
  }
}
