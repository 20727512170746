import { VuexModule, Module } from "vuex-class-modules";
import { FormMetadata, FormType, IFieldMetadata } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class MetadataModule extends VuexModule {
  // Getters
  get appointmentTypeMetadata(): IFieldMetadata[] {
    return FormMetadata.appointmenttype;
  }

  get serviceCategoryMetadata(): IFieldMetadata[] {
    return FormMetadata.servicecategory;
  }

  get accountRoleMetadata(): IFieldMetadata[] {
    return FormMetadata.accountrole;
  }

  get staffMemberMetadata(): IFieldMetadata[] {
    return FormMetadata.staffmember;
  }

  get inventoryItemMetadata(): IFieldMetadata[] {
    return FormMetadata.inventoryitem;
  }

  get addStockMetadata(): IFieldMetadata[] {
    return FormMetadata.addstock;
  }

  get inventoryItemCategoryMetadata(): IFieldMetadata[] {
    return FormMetadata.inventoryitemcategory;
  }

  get moneyAccountMetadata(): IFieldMetadata[] {
    return FormMetadata.moneyaccount;
  }

  get getMetaData(): FormMetadata {
    return FormMetadata;
  }
}

const metadataModule = new MetadataModule({ store, name: "metadataModule" });

export { metadataModule, FormType };
