import { BaseService } from "./Base/BaseService";
import { IAuditLogController, IAuditLogDTO, IPostAuditLogDTO } from "@shared/types";

export class AuditLogService extends BaseService implements IAuditLogController {
  private static instance: AuditLogService;
  private controller = "AuditLog"; // Matches backend route api/AuditLog

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!AuditLogService.instance) {
      AuditLogService.instance = new AuditLogService();
    }
    return AuditLogService.instance;
  }

  public async getCurrentAuditLogs(ignoreFlag?: number): Promise<IAuditLogDTO[]> {
    let url = `${this.controller}/current`;
    if (ignoreFlag !== undefined) {
      url += `?ignoreFlag=${ignoreFlag}`;
    }
    return this.http.getAuthenticated<IAuditLogDTO[]>(url);
  }

  public async postAudit(dto: IPostAuditLogDTO): Promise<any> {
    return this.http.postAuthenticated<void>(`${this.controller}/current`, dto);
  }

  public async postAuditAnonymous(userId: string, dto: IPostAuditLogDTO): Promise<any> {
    return this.http.postAuthenticated<void>(`${this.controller}/asAnonymous/${userId}`, dto);
  }
}
