<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import MonthlyEarnings from "@/components/global/statistics/MonthlyEarned.vue";
import AppointmentBookingLink from "@/components/global/AppointmentBookingLink.vue";
import TokenLink from "@/components/global/TokenLink.vue";
import { castMonthFromNumberToStringName } from "@/types/formatDateHelper";

@Component({
  name: "StatisticsCards",
  components: {
    MonthlyEarnings,
    AppointmentBookingLink,
    TokenLink
  }
})
export default class StatisticsCards extends Vue {
  @Prop({ required: true }) readonly appointmentsDone!: number;
  @Prop({ required: true }) readonly moneyMade!: string;
  @Prop({ required: true }) readonly showServicePrice!: boolean;

  get currentMonth() {
    let date = new Date();
    let month = date.getMonth() + 1;
    return castMonthFromNumberToStringName(month);
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col xs="12" md="6">
        <MonthlyEarnings 
          :hasLoaded="true" 
          :title="$t('appointmentsIn', { month: currentMonth })" 
          :amount="appointmentsDone" 
          :type="''" 
          :icon="'img/stopwatch.png'"
        />
      </v-col>
      <v-col xs="12" md="6" v-if="showServicePrice">
        <MonthlyEarnings 
          :hasLoaded="true" 
          :title="$t('moneyEarnedIn', { month: currentMonth })" 
          :amount="moneyMade" 
          :icon="'img/cash.png'"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <AppointmentBookingLink />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TokenLink />
      </v-col>
    </v-row>
  </div>
</template> 