export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function isiPad() {
  return /iPad/i.test(navigator.userAgent);
}

export function isDesktop() {
  return !isMobile() && !isiPad();
}
