import { EventBaseService } from "./Base/EventBaseService";
import { IAppointmentTypeDTO, IServiceController, IServiceGroupForBookingDTO } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class ServiceService extends EventBaseService implements IServiceController {
  private static instance: ServiceService;
  private controller = "service";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!ServiceService.instance) {
      ServiceService.instance = new ServiceService();
    }
    return ServiceService.instance;
  }

  public async getTreatmentType(appointmentTypeId: number): Promise<any> {
    return this.http.getAuthenticated<any>(`${this.controller}/${appointmentTypeId}`);
  }

  public async changeExtraService(extraServiceChange: any): Promise<void> {
    return this.withEventEmission(Feature.Service, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/changeExtraService/current`, extraServiceChange)
    );
  }

  public async getServices(
    query: Partial<typeof IServiceController.IGetServicesQuery>
  ): Promise<IAppointmentTypeDTO[]> {
    let url = `${this.controller}/current`;
    if (query.extra !== undefined) {
      url += `?extra=${query.extra}`;
    }
    return this.http.getAuthenticated<IAppointmentTypeDTO[]>(url);
  }

  public async getServicesAsAnonymous(userId: string): Promise<IServiceGroupForBookingDTO[]> {
    return this.http.getAuthenticated<IServiceGroupForBookingDTO[]>(`${this.controller}/asAnonymous?userId=${userId}`);
  }

  public async putAppointmentType(id: number, categoryId: number, order: number): Promise<void> {
    return this.withEventEmission(Feature.Service, EventType.Updated, () =>
      this.http.putAuthenticated<void>(
        `${this.controller}/changeCategoryOrder/current?id=${id}&categoryId=${categoryId}&order=${order}`
      )
    );
  }

  public async changeOrderForService(serviceId: number, order: number): Promise<void> {
    return this.withEventEmission(Feature.Service, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/changeOrder/current?serviceId=${serviceId}&order=${order}`)
    );
  }

  public async editService(treatmentType: IAppointmentTypeDTO): Promise<void> {
    return this.withEventEmission(Feature.Service, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current`, treatmentType)
    );
  }

  public async postService(treatmentType: IAppointmentTypeDTO): Promise<any> {
    return this.withEventEmission(Feature.Service, EventType.Added, () =>
      this.http.postAuthenticated<any>(`${this.controller}/current`, treatmentType)
    );
  }

  public async assignAppointmentTypeToUser(treatmentTypeId: number): Promise<void> {
    return this.http.putAuthenticated<void>(`${this.controller}/assign/current/${treatmentTypeId}`);
  }

  public async deleteTreatmentType(id: number): Promise<void> {
    return this.withEventEmission(Feature.Service, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/current?id=${id}`)
    );
  }
}
