<template>
  <v-autocomplete
    :prepend-icon="hideDecorators ? undefined : 'account_box'"
    :items="customers"
    v-model="selectedCustomerId"
    :filter="customerFilter"
    :clearable="!disabled"
    :disabled="disabled"
    :item-text="(item) => item.firstName + (item.lastName ? ' ' + item.lastName : '')"
    item-value="id"
    outlined
    dense
    class="format-box"
    ref="customeraccountselect"
    :label="hideDecorators ? undefined : $t($ts.customerAccount)"
  >
    <template v-slot:selection="{ item }">
      <div class="customer-info">
        <span class="customer-name">{{ item.firstName + (item.lastName ? " " + item.lastName : "") }}</span>
        <span v-if="item.emailAddress" class="customer-detail">
          <v-icon small color="grey">mdi-email</v-icon>
          {{ item.emailAddress }}
        </span>
        <span v-if="item.phoneNumber" class="customer-detail">
          <v-icon small color="grey">mdi-phone</v-icon>
          {{ item.phoneNumber }}
        </span>
      </div>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="customer-name">{{
          item.firstName + (item.lastName ? " " + item.lastName : "")
        }}</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="item.emailAddress" class="customer-detail">
            <v-icon small color="grey">mdi-email</v-icon>
            {{ item.emailAddress }}
          </span>
          <span v-if="item.phoneNumber" class="customer-detail">
            <v-icon small color="grey">mdi-phone</v-icon>
            {{ item.phoneNumber }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <template v-slot:prepend-item>
      <v-list-item ripple @click="createNewCustomer(newCustomerDefaultName)">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon color="primary" class="mr-2">mdi-plus</v-icon>
            {{ $t($ts.createAppointmentMessage.createNewCustomer, { name: newCustomerDefaultName }) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ICustomerDTO } from "@shared/types";
import CreateCustomer from "./CreateCustomer.vue";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "customer-select",
  components: { CreateCustomer },
})
export default class CustomerSelect extends Vue {
  @Prop({ type: [String, Number], default: null }) readonly value!: string | number | null;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly hideDecorators!: boolean;

  private newCustomerDefaultName = "";

  get selectedCustomerId() {
    return this.value || 1; // Default to Drop-in customer (ID 1) if no value is provided
  }

  get latestCreatedCustomerId() {
    return dialogModule.latestCreatedCustomerId;
  }

  @Watch("latestCreatedCustomerId")
  onLatestCreatedCustomerChangexx(newCustomerId: number | null) {
    if (newCustomerId) {
      this.selectedCustomerId = newCustomerId;
    }
  }

  set selectedCustomerId(value: string | number | null) {
    this.$emit("input", value);
  }

  get customers() {
    // Create a Drop-in customer and add it to the top of the list
    const dropInCustomer: Partial<ICustomerDTO> = {
      id: 1,
      firstName: this.$t(this.$ts.customer.dropIn) as string,
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
    };
    
    // Check if Drop-in customer already exists in the list
    const existingDropIn = customersModule.customers.find(c => c.id === 1);
    if (existingDropIn) {
      return customersModule.customers;
    } else {
      return [dropInCustomer, ...customersModule.customers] as ICustomerDTO[];
    }
  }

  @Watch("dialogModule.latestCreatedCustomerId")
  onLatestCreatedCustomerChange(newCustomerId: number | null) {
    if (newCustomerId) {
      this.selectedCustomerId = newCustomerId;
      const selectedCustomer = this.customers.find((c) => c.id === newCustomerId);
      if (selectedCustomer) {
        this.$emit("customer-selected", selectedCustomer);
      }
      // Reset the latest created customer ID to avoid reselecting on component remount
      dialogModule.closeCreateCustomer();
    }
  }

  onCustomerChange(customerId: number | null) {
    if (customerId) {
      const selectedCustomer = this.customers.find((c) => c.id === customerId);
      this.$emit("customer-selected", selectedCustomer);
    } else {
      this.$emit("customer-selected", null);
    }
  }

  createNewCustomer(name: string) {
    // Close the autocomplete menu
    const autocomplete = this.$refs.customeraccountselect as any;
    if (autocomplete) {
      autocomplete.isMenuActive = false;
    }
    dialogModule.openCreateCustomer(name);
  }

  customerFilter(item: ICustomerDTO, queryText: string) {
    const searchText = queryText.toLowerCase();
    const firstName = (item.firstName || "").toLowerCase();
    const lastName = (item.lastName || "").toLowerCase();
    const fullName = `${firstName} ${lastName}`.trim();
    const email = (item.emailAddress || "").toLowerCase();
    const phone = (item.phoneNumber || "").toLowerCase();

    this.newCustomerDefaultName = queryText;

    return fullName.includes(searchText) || email.includes(searchText) || phone.includes(searchText);
  }
}
</script>

<style lang="scss" scoped>
.format-box {
  max-width: 100%;
}

.customer-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.customer-name {
  font-weight: 500;
}

.customer-detail {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;

  .v-icon {
    font-size: 16px;
  }
}

:deep(.v-list-item__subtitle) {
  display: flex !important;
  gap: 12px;
}
</style>
