<template>
  <div>
    <div v-if="requireAuth && hasLayout">
      <v-main>
        <v-container fluid>
          <layout v-if="requireAuth && hasLayout">
            <transition :name="$router.currentRoute.meta.transition">
              <router-view></router-view>
            </transition>
          </layout>
        </v-container>
      </v-main>
    </div>
    <div v-else>
      <router-view v-slot="{ Component, route }">
        <transition mode="out-in" :name="route.meta.transition">
          <component :key="route.fullPath" :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "../Layout.vue";

@Component({
  name: "MainContent",
  components: { Layout },
})
export default class MainContent extends Vue {
  get requireAuth(): boolean {
    return (
      this.$route.meta.requiresAuth != null &&
      this.$route.meta.requiresAuth === true
    );
  }

  get hasLayout(): boolean {
    return (
      this.$route.meta.hasLayout == null || this.$route.meta.hasLayout === true
    );
  }
}
</script>

<style lang="scss" scoped>
.v-main {
  @media only screen and (max-width: 1264px) {
    padding: 0 !important;
    margin-bottom: 50px !important;
  }
}

.container--fluid {
  @media only screen and (max-width: 1264px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
