<template>
  <v-card>
    <v-card-title>
      {{ $t($ts.entities.recurringExpense) }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t($ts.placeHolder.typeToSearch)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-toolbar flat>
      <v-toolbar-title></v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="openCreateDialog">
        <v-icon left>mdi-plus</v-icon>
        {{ $t($ts.entities.recurringExpense) }}
      </v-btn>
    </v-toolbar>

    <responsive-table :items="recurringExpenses" :loading="loading">
      <!-- Desktop Headers -->
      <template v-slot:headers>
        <th>{{ $t($ts.form.fields.name) }}</th>
        <th>{{ $t($ts.form.fields.price) }}</th>
        <th>{{ $t($ts.expenses.startDate) }}</th>
        <th>{{ $t($ts.category) }}</th>
        <th>{{ $t($ts.reoccuringExpense.recurrenceInterval) }}</th>
        <th class="text-right">{{ $t($ts.fields.actions) }}</th>
      </template>

      <!-- Desktop Row -->
      <template v-slot:desktop-row="{ item }">
        <td>{{ item.name }}</td>
        <td>{{ formatCurrency(item.amount) }}</td>
        <td>{{ formatDate(item.startDate) }}</td>
        <td>{{ $t($ts.expenseCategoryAsAction[ExpenseCategory[item.category].toLowerCase()]) }}</td>
        <td>{{ $t($ts.reoccuringExpense.intervals[RecurrenceInterval[item.recurrenceInterval].toLowerCase()]) }}</td>
        <td class="text-right">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </td>
      </template>

      <!-- Mobile Header -->
      <template v-slot:mobile-header="{ item }">
        <div class="d-flex justify-space-between align-center">
          <div>
            <div class="font-weight-medium">{{ item.name }}</div>
            <div class="text-caption">
              {{ $t($ts.expenseCategoryAsAction[ExpenseCategory[item.category].toLowerCase()]) }}
            </div>
          </div>
          <div class="text-h6">{{ formatCurrency(item.amount) }}</div>
        </div>
      </template>

      <!-- Mobile Content -->
      <template v-slot:mobile-content="{ item }">
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.expenses.startDate) }}</v-list-item-subtitle>
              <v-list-item-title>{{ formatDate(item.startDate) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.reoccuringExpense.recurrenceInterval) }}</v-list-item-subtitle>
              <v-list-item-title>
                {{ $t($ts.reoccuringExpense.intervals[RecurrenceInterval[item.recurrenceInterval].toLowerCase()]) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <div class="d-flex justify-end mt-2">
                <v-btn small text color="primary" class="mr-2" @click="editItem(item)">
                  <v-icon small left>edit</v-icon>
                  {{ $t($ts.actions.edit) }}
                </v-btn>
                <v-btn small text color="error" @click="deleteItem(item)">
                  <v-icon small left>delete</v-icon>
                  {{ $t($ts.actions.delete) }}
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </responsive-table>

    <!-- Recurring expense dialog -->
    <recurring-expense-dialog :visible.sync="dialog" :expense="editedItem" :title="formTitle" @save="saveExpense" />

    <!-- Delete dialog -->
    <delete-expense-dialog :visible.sync="dialogDelete" @confirm="deleteItemConfirm" />
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { expensesModule } from "@/store/modules/expenses/expensesModule";
import { IRecurringExpenseDTO, IPostRecurringExpenseDTO, ExpenseCategory, RecurrenceInterval } from "@shared/types";
import RecurringExpenseDialog from "@/components/expenses/RecurringExpenseDialog.vue";
import DeleteExpenseDialog from "@/components/expenses/DeleteExpenseDialog.vue";
import { userModule } from "@/store/modules/user/userModule";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";

@Component({
  components: {
    RecurringExpenseDialog,
    DeleteExpenseDialog,
    ResponsiveTable,
  },
})
export default class RecurringExpensesTable extends Vue {
  private search = "";
  private dialog = false;
  private dialogDelete = false;
  private editedIndex = -1;
  private editedItem: IPostRecurringExpenseDTO | null = null;
  private loading = false;
  private expenses: IRecurringExpenseDTO[] = [];
  private ExpenseCategory = ExpenseCategory;
  private RecurrenceInterval = RecurrenceInterval;

  private defaultItem: IPostRecurringExpenseDTO = {
    name: "",
    amount: 0,
    category: 0,
    recurrenceInterval: 0,
    startDate: new Date(),
    endDate: null,
    sourceId: 0,
  };

  get recurringExpenses() {
    return this.expenses;
  }

  get formTitle() {
    return this.editedIndex === -1 ? this.$t(this.$ts.actions.create) : this.$t(this.$ts.actions.edit);
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user?.currency,
    }).format(value);
  }

  private formatDate(date: Date | null | undefined): string {
    if (!date) return "-";
    return new Date(date).toLocaleDateString(this.$i18n.locale);
  }

  private openCreateDialog() {
    this.editedIndex = -1;
    this.editedItem = { ...this.defaultItem };
    this.dialog = true;
  }

  private editItem(item: IRecurringExpenseDTO) {
    this.editedIndex = this.recurringExpenses.indexOf(item);
    this.editedItem = {
      name: item.name,
      amount: item.amount,
      category: item.category,
      recurrenceInterval: item.recurrenceInterval,
      startDate: item.startDate,
      endDate: item.endDate,
      sourceId: item.sourceId || 0,
    };
    this.dialog = true;
  }

  private deleteItem(item: IRecurringExpenseDTO) {
    this.editedIndex = this.recurringExpenses.indexOf(item);
    this.dialogDelete = true;
  }

  private async deleteItemConfirm() {
    try {
      this.loading = true;
      const expense = this.recurringExpenses[this.editedIndex];
      if (expense?.id) {
        await expensesModule.deleteRecurringExpense(expense.id);
        await this.loadExpenses();
      }
    } catch (error) {
      console.error("Failed to delete expense:", error);
    } finally {
      this.loading = false;
    }
  }

  private async saveExpense(expense: IPostRecurringExpenseDTO) {
    try {
      this.loading = true;
      if (this.editedIndex > -1) {
        const existingExpense = this.recurringExpenses[this.editedIndex];
        if (existingExpense?.id) {
          await expensesModule.editRecurringExpense({
            id: existingExpense.id,
            dto: expense,
          });
        }
      } else {
        await expensesModule.createRecurringExpense(expense);
      }
      await this.loadExpenses();
    } catch (error) {
      console.error("Failed to save expense:", error);
    } finally {
      this.loading = false;
    }
  }

  private async loadExpenses() {
    try {
      this.loading = true;
      const response = await expensesModule.getRecurringExpenses();
      this.expenses = response || [];
    } catch (error) {
      console.error("Failed to load expenses:", error);
      this.expenses = [];
    } finally {
      this.loading = false;
    }
  }

  async created() {
    await this.loadExpenses();
  }
}
</script>
