<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn style="margin-top: 3px" v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>

    <v-card style="max-width: 220px">
      <v-card-text>
        <v-select
          v-model="selectedOption"
          :items="intervalOptions"
          dense
          outlined
          hide-details
          class="interval-select mb-2"
          style="max-width: 120px"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mr-2">mdi-magnify</v-icon>
          </template>
          <template v-slot:append-inner>
            <span>min</span>
          </template>
        </v-select>

        <v-select
          v-model="calendarType"
          :items="calendarOptions"
          dense
          outlined
          hide-details
          class="mb-2"
          style="max-width: 120px"
          item-value="value"
          item-text="text"
        ></v-select>

        <v-select
          v-model="currentStaffMember"
          :items="staffMemberOptions"
          dense
          outlined
          hide-details
          class="mb-2"
          style="max-width: 120px"
          item-value="id"
          item-text="fullName"
        >
          <template v-slot:item="data">
            <v-icon>mdi-account-supervisor-circle</v-icon>
            {{ data.item.fullName }}
          </template>
          <template v-slot:selection="data">
            <v-icon>mdi-account-supervisor-circle</v-icon>
            {{ data.item.fullName }}
          </template>
        </v-select>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
import { IStaffMemberDTO } from "@shared/types";

@Component({
  name: "CalendarSettingsDialog",
})
export default class CalendarSettingsDialog extends Vue {
  menu: boolean = false;

  get staffMemberOptions() {
    return calendarModule.staffMemberOptions;
  }

  get calendarOptions() {
    return calendarModule.calendarOptions;
  }

  get intervalOptions() {
    return [15, 30, 60, 90];
  }

  get calendarType() {
    return calendarModule.calendarType;
  }

  set calendarType(value: string) {
    calendarModule.updateCalendarType(value);
  }

  get selectedOption() {
    return calendarModule.intervalMinutes;
  }

  set selectedOption(value: number) {
    calendarModule.updateIntervalMinutes(value);
  }

  get currentStaffMember() {
    return calendarModule.currentStaffMember;
  }

  set currentStaffMember(value: number) {
    calendarModule.updateCurrentStaffMember(value);
  }
}
</script>

<style scoped>
/* ... existing styles ... */
</style>
