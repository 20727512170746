export function formatCurrency(value: number | string): string {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (value != null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return "";
}

/**
 * Enhanced currency formatter that formats currency based on the provided currency code
 * Uses Colombian formatting (es-CO) when currency is COP
 * @param amount The amount to format
 * @param currencyCode The currency code (e.g., 'USD', 'EUR', 'COP')
 * @param locale The locale to use for formatting (defaults to user's locale)
 * @returns Formatted currency string
 */
export function formatCurrencyEnhanced(amount: number, currencyCode: string, locale?: string): string {
  // Use Colombian formatting for COP
  if (currencyCode === 'COP') {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }
  
  // Use provided locale or default
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}
