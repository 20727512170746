import {Sector} from "../../types/Sector";
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FrontPageHeader from "../../components/global/FrontPageHeader.vue";
import App from "@/App.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { ISector, Sector } from "@/types/Sector";
import RegisterFirebaseAccount from "../../components/RegisterFirebaseAccount.vue";
@Component({
  name: "Login",
  components: {
    RegisterFirebaseAccount,
    CustomButton,
    FrontPageHeader,
    App,
    CustomInput,
  },
})
export default class Register extends Vue {
  isShowingForgotPasswordField: boolean = false;
  error: string = "";

  isLoading: boolean = false;
  pickedSector: number | null = null;
  pickedSectorToRead: null | Sector = null;
  dialog: boolean = false;

  goToLogin(): void {
    this.$router.push("/login");
  }

  public goToLandingPage(): void {
    this.$router.push("/");
  }
}
</script>
<template>
  <div>
    <front-page-header>
      <!--            <button @click="goToLandingPage"><img style="right:119px !important;" class="arrow" src="../../../public/img/left-arrow.png" alt="login icon"> Tilbake </button>-->
    </front-page-header>

    <v-row>
      <v-col xs="12" md="6">
        <v-card>
          <v-card-title>Registrer gratis prøveperiode</v-card-title>
          <register-firebase-account :onBoardingOnRegister="true" @success=""></register-firebase-account>

          <v-card-text>
            <p class="not-a-member-text" @click="goToLogin">Har du bruker? Logg inn her</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="login-wrapper">
      <div v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
select {
  width: 100%;
  float: left;
  height: 50px;
  padding: 5px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 4px 0px;
  border: none;
  border-radius: 4px;
  font-size: 19px;
  -webkit-box-shadow: 0px 0px 1px 1px #2d2d2d2d;
  box-shadow: 0px 0px 1px 1px #2d2d2d2d;
  color: #333;
}

.section-wrapper {
  width: 100%;
  height: 250px;
  display: inline-block;
  &__container {
    width: 70%;
    margin: 10px 15%;
    h2 {
      text-align: center;
      font-size: 2em;
      padding: 70px;
    }
    .section {
      width: 45%;
      margin: 10px 2.5%;
      height: 200px;
      display: inline-block;
      overflow: auto;
      box-shadow: 0px 0px 12px 0px #3333331a;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background: #f6f6f6;
        transition: 0.3s;
      }
      h3 {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        padding: 10px 0;
      }
      img {
        display: block;
        margin: 5px auto;
        height: 50px;
      }
      p {
        text-align: center;
        font-weight: bold;
        padding: 5px 20px;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.arrow {
  right: 129px !important;
}

body {
}
h1 {
  font-size: 3em;
  padding-bottom: 15px;
  // color:#876AFF;
}
.selling-points-wrapper {
  width: 100%;
  float: left;
  height: 450px;
  padding: 30px 0px;

  .selling-points {
    width: 70%;
    float: left;
    margin: 80px 15% 0 15%;
    h2 {
      font-size: 2.2em;
      text-align: center;
    }
    .point {
      width: 33.333%;
      padding: 50px;
      float: left;
      img {
        display: block;
        margin: 0 auto;
        width: 55px;
      }
      h3 {
        text-align: center;
        margin: 10px 0;
        color: #595959;
      }
      p {
        text-align: center;
        margin: 10px 0;
        color: #969696;
      }
    }
  }
}

.login-wrapper {
  width: 50%;
  margin: 200px 25%;
  float: left;
  button {
    margin-top: 8px;
    text-transform: uppercase;
    /*padding:0 20px;*/
    float: left;
  }
}
.left-section,
.right-section {
  width: 30%;
  float: left;
  height: 100%;
  p {
    margin-top: 10px;
  }
}
.right-section {
  width: 70%;
  button {
    width: auto;
  }
  .google {
    width: 100%;
    height: 38px;
    background: #efefef;
    line-height: 28px;
    text-indent: 10px;
    color: #797d7c;
    transition: 0.3s;
    margin-top: 50px;
    img {
      height: 20px;
      width: 20px;
      position: absolute;
      margin-top: 3px;
      float: left;
      margin-left: -29px;
    }
    &:hover {
      background: whitesmoke;
      transition: 0.3s;
    }
  }
  .not-a-member-text {
    float: right;
    color: #4397d5;
    text-decoration: underline;
    margin-top: 21px;
    margin-right: -1px;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.login-content {
  padding: 40px 0 0 65px;
  margin-top: 40px;
  img {
    height: 200px;
    margin-top: -109px;
    margin-right: 49px;
    float: right;
  }
}

@media only screen and (max-width: 1264px) {
  .login-wrapper {
    width: 100%;
    margin: 200px 0%;
    float: left;
    padding: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .section-wrapper {
    width: 100%;
    height: auto;
    display: inline-block;

    &__container {
      width: 100%;
      margin: 10px 0%;

      .section {
        width: 95%;
        margin: 2.5%;
      }
    }
  }

  .login-wrapper {
    width: 100%;
    margin: 10px 0%;
    float: left;
    height: auto;
    padding: 50px;
    text-align: center;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1em;
      color: #7f7f7f;
      margin-bottom: 5px;
    }
    .left-section {
      display: none;
    }
    .right-section {
      width: 100%;
      .google {
        margin-top: 0;
      }
    }
    .not-a-member-text {
      width: 100%;
    }
  }
}
</style>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule ../../store/modules/user
