import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType, Status } from "./Events/ServiceEvents";
import { IOrderController, IOrderDTO, IPostOrderDTO, ILedgerPaymentDTO, IPostLedgerPaymentDTO } from "@shared/types";

export class OrderService extends EventBaseService implements IOrderController {
  private static instance: OrderService;

  private constructor() {
    super();
  }
  public getOrdersByCustomerId(customerId: number): Promise<IOrderDTO[]> {
    return this.http.sendRequest<IOrderDTO[]>(IOrderController.GetOrdersByCustomerIdRoute, { params: { customerId } });
  }

  public static getInstance() {
    if (!OrderService.instance) {
      OrderService.instance = new OrderService();
    }
    return OrderService.instance;
  }

  public async getOrders() {
    return this.http.sendRequest<IOrderDTO[]>(IOrderController.GetOrdersRoute);
  }

  public async getOrder(id: number) {
    return this.http.sendRequest<IOrderDTO>(IOrderController.GetOrderRoute, { params: { id } });
  }

  public async createOrder(dto: IPostOrderDTO): Promise<IOrderDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<IOrderDTO>(IOrderController.CreateOrderRoute, { data: dto })
    );
  }

  public async processPayment(dto: IPostLedgerPaymentDTO): Promise<ILedgerPaymentDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<ILedgerPaymentDTO>(IOrderController.ProcessPaymentRoute, { data: dto })
    );
  }
}
