import { BaseService } from "./Base/BaseService";
import { IEmailListController, IEmailListEntryDTO } from "@shared/types";

export class EmailListService
  extends BaseService
  implements IEmailListController
{
  private static instance: EmailListService;
  private controller = "EmailList"; // Matches backend route api/EmailList

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!EmailListService.instance) {
      EmailListService.instance = new EmailListService();
    }
    return EmailListService.instance;
  }

  public async get(): Promise<IEmailListEntryDTO[]> {
    return this.http.getAuthenticated<IEmailListEntryDTO[]>(
      `${this.controller}/current`
    );
  }
}
