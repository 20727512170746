<template>
  <div>
    <edit-customer
      @finish="editingCustomer = null"
      v-if="editingCustomer != null && canModifyCustomers"
      :customer="editingCustomer"
    ></edit-customer>

    <customer-modal-window
      @finish="viewingCustomer = null"
      @delete="deletedCustomer"
      :customer="viewingCustomer"
      :can-modify="canModifyCustomers"
      v-if="viewingCustomer != null"
    ></customer-modal-window>

    <div v-if="isLoading && filteredCustomers.length == 0">
      <div class="treatment-widget">
        <loader></loader>
      </div>
    </div>

    <v-card flat>
      <div v-if="!isLoading || customers.length > 0">
        <v-card-text>
          <customer-actions
            :is-showing-form="isShowingCustomerForm"
            :has-selected-customers="!hasSelectedCustomers"
            :can-modify="canModifyCustomers"
            @toggle-form="toggleCustomerForm"
            @delete-selected="deleteSelectedCustomers"
          >
            <template v-slot:search>
              <customer-search-bar v-if="!displayChoice" v-model="search"></customer-search-bar>
            </template>
          </customer-actions>

          <input
            v-if="!isShowingCustomerForm && customers.length > 0 && displayChoice"
            type="text"
            class="search-for-user"
            :placeholder="$t($ts.customer.searchForCustomer).toString()"
            v-model="searchQuery"
            @keyup="filterCustomers"
          />

          <div v-else>
            <div class="no-customers-wrapper" v-if="!isLoading && displayChoice">
              <h3>{{ $t($ts.customer.noCustomersYet).toString() }}</h3>
            </div>
          </div>

          <div
            class="no-customers-wrapper"
            v-if="filteredCustomers.length == 0 && !isLoading && searchQuery.length > 0"
          >
            <h3>{{ $t($ts.customer.noSearchResults).toString() }}</h3>
          </div>
        </v-card-text>

        <v-dialog max-width="500" v-model="isShowingCustomerForm">
          <create-customer
            v-if="isShowingCustomerForm && canModifyCustomers"
            @close="isShowingCustomerForm = false"
            @created="onCreatedCustomer"
          ></create-customer>
        </v-dialog>

        <customer-table
          v-if="!displayChoice"
          :customers="filteredCustomers"
          :search="search"
          :is-mobile="isMobile()"
          :can-modify="canModifyCustomers"
          v-model="selectedCustomers"
          @edit="editCustomer"
        ></customer-table>

        <customer-list
          v-else
          :customers="filteredCustomers"
          :can-modify="canModifyCustomers"
          @delete="deletedCustomer"
        ></customer-list>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DialogType, IDialog } from "@/store/dialog";
import CustomerTable from "./customers/CustomerTable.vue";
import CustomerSearchBar from "./customers/CustomerSearchBar.vue";
import CustomerActions from "./customers/CustomerActions.vue";
import CustomerList from "./customers/CustomerList.vue";
import EditCustomer from "./EditCustomer.vue";
import CustomerModalWindow from "./CustomerModalWindow.vue";
import CustomerModal from "./CustomerModal.vue";
import CreateCustomer from "./CreateCustomer.vue";
import Loader from "./Loader.vue";
import {
  PermissionArea,
  PermissionLevel,
  userPermissionsModule,
} from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { ICustomerDTO } from "@shared/types";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "AdminCustomers",
  components: {
    CreateCustomer,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomerTable,
    CustomerSearchBar,
    CustomerActions,
    CustomerList,
  },
})
export default class AdminCustomers extends Vue {
  public isShowingCustomerForm: boolean = false;
  public searchQuery: string = "";
  public editingCustomer = null;
  public viewingCustomer = null;
  selectedCustomers: Partial<ICustomerDTO>[] = [];
  search: string = "";
  isLoading: boolean = true;
  displayChoice: boolean = false;

  get canModifyCustomers(): boolean {
    return userPermissionsModule.hasPermission(PermissionArea.APPOINTMENTS_AND_CLIENTS, PermissionLevel.READ_WRITE);
  }

  get customers() {
    return customersModule.customers;
  }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public toggleCustomerForm(): void {
    this.isShowingCustomerForm = !this.isShowingCustomerForm;
  }

  public filterCustomers(): void {}

  async deletedCustomer(customerId: number) {
    await customersModule.deleteCustomer(customerId);
  }

  editCustomer(customer: Partial<ICustomerDTO>) {
    this.editingCustomer = customer;
  }

  get hasSelectedCustomers() {
    return this.selectedCustomers.length === 0;
  }

  get filteredCustomers() {
    return this.customers.filter((customer: Partial<ICustomerDTO>) => {
      if (customer.firstName != null) {
        return customer.firstName.toUpperCase().match(this.searchQuery.toUpperCase());
      } else {
        return false;
      }
    });
  }

  async deleteSelectedCustomers() {
    let vm = this;
    let dialog: IDialog = {
      text: this.$t(this.$ts.customer.confirmDeleteCustomers, {
        count: vm.selectedCustomers.length,
      }).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.customer.deleteCustomerHeader).toString(),
      action: async function () {
        await snackbarModule.postSnackbarMessage(
          vm
            .$t("customer.customersDeleted", {
              count: vm.selectedCustomers.length,
            })
            .toString()
        );
        for (const customer of vm.selectedCustomers) {
          await customersModule.deleteCustomer(customer.id);
        }
        vm.selectedCustomers = [];
      },
    };
    await dialogModule.addToDialogQueue(dialog);
  }

  onCreatedCustomer() {
    this.isShowingCustomerForm = false;
  }

  public async created(): Promise<any> {
    this.isLoading = true;
    await customersModule.getCustomers();
    this.isLoading = false;
  }
}
</script>

<style scoped>
.treatment-list {
  margin-top: 20px;
}
.rotate {
  transform: rotate(180deg);
}
</style>
