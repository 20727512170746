import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { MoneyAccountService } from "@/services/api/MoneyAccountService";
import { IMoneyAccountDTO, IMoneyAccountSummaryDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class MoneyAccountModule extends VuexModule {
  private moneyAccountService = MoneyAccountService.getInstance();

  // State
  moneyAccounts: IMoneyAccountDTO[] = [];
  accountsSummary: IMoneyAccountSummaryDTO | null = null;
  loading = false;

  @Action
  async getSummary(date?: Date): Promise<IMoneyAccountSummaryDTO> {
    this.loading = true;
    try {
      const query = date ? {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      } : {};
      const summary = await this.moneyAccountService.getSummary(query);
      this.accountsSummary = summary;
      return summary;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getAllAccounts(): Promise<IMoneyAccountDTO[]> {
    this.loading = true;
    try {
      const data = await this.moneyAccountService.getAll();
      this.moneyAccounts = data;
      return data;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getAccountById(id: number): Promise<IMoneyAccountDTO> {
    this.loading = true;
    try {
      return await this.moneyAccountService.getById(id);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createAccount(account: IMoneyAccountDTO): Promise<IMoneyAccountDTO> {
    this.loading = true;
    try {
      const newAccount = await this.moneyAccountService.create(account);
      this.moneyAccounts.push(newAccount);
      return newAccount;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async updateAccount({ id, account }: { id: number; account: IMoneyAccountDTO }): Promise<IMoneyAccountDTO> {
    this.loading = true;
    try {
      const updatedAccount = await this.moneyAccountService.update(id, account);
      const index = this.moneyAccounts.findIndex(a => a.id === id);
      if (index !== -1) {
        this.moneyAccounts[index] = updatedAccount;
      }
      return updatedAccount;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async deleteAccount(id: number): Promise<void> {
    this.loading = true;
    try {
      await this.moneyAccountService.delete(id);
      const index = this.moneyAccounts.findIndex(a => a.id === id);
      if (index !== -1) {
        this.moneyAccounts.splice(index, 1);
      }
    } finally {
      this.loading = false;
    }
  }
}

export const moneyAccountModule = new MoneyAccountModule({ store, name: "moneyAccountModule" }); 