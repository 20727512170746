import { VuexModule, Module, Action, Mutation } from "vuex-class-modules";
import { Store } from "vuex";
import { InvoiceService } from "@/services/api/InvoiceService";
import { IInvoiceDTO, IPostInvoiceDTO, ISendInvoiceDTO } from "@shared/types";

@Module({ generateMutationSetters: true })
class InvoicesModule extends VuexModule {
  private invoiceService = InvoiceService.getInstance();

  // State
  invoices: IInvoiceDTO[] = [];
  loading = false;
  sendingInvoice: IInvoiceDTO | null = null;

  @Mutation
  setSendingInvoice(invoice: IInvoiceDTO | null) {
    this.sendingInvoice = invoice;
  }

  @Action
  async getInvoices(): Promise<void> {
    this.loading = true;
    try {
      const data = await this.invoiceService.get();
      this.invoices = data;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createInvoice(dto: IPostInvoiceDTO): Promise<IInvoiceDTO> {
    this.loading = true;
    try {
      const invoice = await this.invoiceService.post(dto);
      await this.getInvoices(); // Refresh the list
      return invoice;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async sendInvoice(dto: ISendInvoiceDTO): Promise<void> {
    this.loading = true;
    try {
      await this.invoiceService.sendInvoice(dto);
      await this.getInvoices(); // Refresh the list
    } finally {
      this.loading = false;
    }
  }

  @Action
  async changeStatus(payload: { invoiceId: number; status: string }): Promise<void> {
    this.loading = true;
    try {
      await this.invoiceService.changeStatus(payload.invoiceId, payload.status);
      await this.getInvoices(); // Refresh the list
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getInvoicesForCustomer(customerId: number): Promise<IInvoiceDTO[]> {
    this.loading = true;
    try {
      return await this.invoiceService.getInvoicesForCustomer(customerId);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getInvoicesForAppointment(appointmentId: number): Promise<IInvoiceDTO[]> {
    this.loading = true;
    try {
      return await this.invoiceService.getInvoicesForAppointment(appointmentId);
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getNextInvoiceNumber(): Promise<number> {
    this.loading = true;
    try {
      return await this.invoiceService.getInvoiceNumber();
    } finally {
      this.loading = false;
    }
  }

  @Action
  async getPdf(invoiceId: number): Promise<Blob> {
    this.loading = true;
    try {
      return await this.invoiceService.getPdf(invoiceId);
    } finally {
      this.loading = false;
    }
  }
}

let invoicesModule: InvoicesModule | null = null;

export function initializeInvoicesModule(store: Store<any>): InvoicesModule {
  if (!invoicesModule) {
    invoicesModule = new InvoicesModule({
      store,
      name: "invoicesModule",
    });
  }
  return invoicesModule;
}

export { invoicesModule };
