import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType } from "./Events/ServiceEvents";
import {
  ILedgerEntriesController,
  ILedgerEntryDTO,
  ILedgerEntryDetailsDTO,
  ICreateAppointmentLedgerEntryDTO,
  IDetailedBalanceDTO,
  ICreateOrderLedgerEntryDTO,
} from "@shared/types";

export class LedgerEntriesService extends EventBaseService implements ILedgerEntriesController {
  private static instance: LedgerEntriesService;

  private constructor() {
    super();
  }
  public createFromOrder(dto: ICreateOrderLedgerEntryDTO): Promise<ILedgerEntryDTO> {
    return this.http.sendRequest<ILedgerEntryDTO>(ILedgerEntriesController.CreateFromOrderRoute, { data: dto });
  }
  public static getInstance() {
    if (!LedgerEntriesService.instance) {
      LedgerEntriesService.instance = new LedgerEntriesService();
    }
    return LedgerEntriesService.instance;
  }

  public async getCustomerEntries(customerId: number): Promise<ILedgerEntryDTO[]> {
    return this.http.sendRequest<ILedgerEntryDTO[]>(ILedgerEntriesController.GetCustomerEntriesRoute, {
      params: { customerId },
    });
  }

  public async getMoneyAccountEntries(moneyAccountId: number): Promise<ILedgerEntryDTO[]> {
    return this.http.sendRequest<ILedgerEntryDTO[]>(ILedgerEntriesController.GetMoneyAccountEntriesRoute, {
      params: { moneyAccountId },
    });
  }

  public async getAllMoneyAccountEntriesForDate(year: number, month: number, day: number): Promise<ILedgerEntryDTO[]> {
    return this.http.sendRequest<ILedgerEntryDTO[]>(ILedgerEntriesController.GetAllMoneyAccountEntriesForDateRoute, {
      params: { year, month, day },
    });
  }

  public async getCustomerBalance(customerId: number): Promise<number> {
    return this.http.sendRequest<number>(ILedgerEntriesController.GetCustomerBalanceRoute, { params: { customerId } });
  }

  public async getEntryWithAppointmentDetails(id: number): Promise<ILedgerEntryDetailsDTO> {
    return this.http.sendRequest<ILedgerEntryDetailsDTO>(ILedgerEntriesController.GetEntryWithAppointmentDetailsRoute, {
      params: { id },
    });
  }

  public async createFromAppointment(dto: ICreateAppointmentLedgerEntryDTO): Promise<ILedgerEntryDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<ILedgerEntryDTO>(ILedgerEntriesController.CreateFromAppointmentRoute, { data: dto })
    );
  }

  public async handleArchivedAppointment(appointmentId: number): Promise<any> {
    return this.withEventEmission(Feature.Finance, EventType.Updated, () =>
      this.http.sendRequest<void>(ILedgerEntriesController.HandleArchivedAppointmentRoute, {
        params: { appointmentId },
      })
    );
  }

  public async getDetailedBalance(
    query: typeof ILedgerEntriesController.IGetDetailedBalanceQuery
  ): Promise<IDetailedBalanceDTO> {
    return this.http.sendRequest<IDetailedBalanceDTO>(ILedgerEntriesController.GetDetailedBalanceRoute, {
      query: query,
    });
  }

  public async createManual(entry: ILedgerEntryDTO): Promise<ILedgerEntryDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<ILedgerEntryDTO>(ILedgerEntriesController.CreateManualRoute, { data: entry })
    );
  }
}
