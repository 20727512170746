<script lang="ts">
import Vue from "vue";
import CalendarClass from "../../../abstract-classes/Calendar";
import { Component, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/global/Loader.vue";
import CustomerModal from "@/components/global/CustomerModal.vue";
import { DialogType, IDialog } from "@/store/dialog";
import SelectCustomer from "@/components/global/CreateAppointment.vue";
import { getMonthName, getWeekDay } from "@/Utilities/dateUtility";
import CustomerModalWindow from "../CustomerModalWindow.vue";
import AppointmentMenu from "../../AppointmentMenu.vue";
import { CalendarInterval, IWorkingDays } from "@/store/workingHours";
import { IAppointmentPatch, appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { AppointmentInfo } from "@/store/appointments/appointmentTypings";
import {
  IAppointmentDTO,
  IAppointmentForWeeklyCalenderDTO,
  IAuditLogDTO,
  IStaffMemberDTO,
  IUserDTO,
} from "@shared/types";
import CreateAppointment from "../CreateAppointment.vue";
import AppointmentSummary from "@/components/global/AppointmentSummary.vue";
import { formatDate } from "@/types/formatDateHelper";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";
import CalendarDesktopHeader from "./CalendarDesktopHeader.vue";
import CalendarSettingsDialog from "./CalendarSettingsDialog.vue";
import EventCard from "./EventCard.vue";
import CalendarMobileHeader from "./CalendarMobileHeader.vue";
import EventMenu from "./EventMenu.vue";
import { isMobile, isiPad } from "@/Utilities/deviceUtility";
import { userModule } from "@/store/modules/user/userModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";
import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { layoutModule } from "@/store/modules/layout/layoutModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

interface CalendarRef extends Vue {
  prev: () => void;
  next: () => void;
}

@Component({
  name: "WeekCalendar",
  components: {
    AppointmentSummary,
    CreateAppointment,
    AppointmentMenu,
    CustomerModalWindow,
    SelectCustomer,
    CustomerModal,
    Loader,
    CalendarDesktopHeader,
    CalendarSettingsDialog,
    EventCard,
    CalendarMobileHeader,
    EventMenu,
  },
})
export default class WeekCalendar extends CalendarClass {
  @Prop({ default: null }) adminId: string | null;

  isLoading: boolean = true;
  isLoadingSettings: boolean = true;

  public calendarScroll = 0;

  // Add cached events property
  private cachedEvents: Array<IAppointmentForWeeklyCalenderDTO> = [];
  private cachedFilteredEvents: Array<IAppointmentForWeeklyCalenderDTO> = [];

  // Add to component data properties
  private loadedDays: Set<string> = new Set(); // Store as ISO strings YYYY-MM-DD
  private isLoadingNewDays: boolean = false;
  currentCalendarDate: Date;

  get user(): IUserDTO {
    return userModule.user;
  }

  get allWorkingDays(): boolean[] {
    return workingDaysModule.allWorkingDays;
  }

  get bookingSettings() {
    return userModule.bookingSettings;
  }

  get bookingSettingsIntervals() {
    return userModule.bookingIntervals;
  }

  get appointmentsForOverview() {
    return appointmentsModule.appointmentsForOverview;
  }

  get staffMembers(): Partial<IStaffMemberDTO>[] {
    return staffMemberModule.staffMembers;
  }

  get hasMultipleStaff(): boolean {
    return staffMemberModule.hasMultipleStaff;
  }

  get mini(): boolean {
    return layoutModule.mini;
  }

  flashInterval = null;
  staffMemberView = false;

  appointmentToMove = null;

  public events: Array<IAppointmentForWeeklyCalenderDTO> = [];
  public filteredEvents: Array<IAppointmentForWeeklyCalenderDTO> = [];

  get getCalendarType(): string {
    return calendarModule.calendarType;
  }

  get getCurrentStaffMember(): number {
    return calendarModule.currentStaffMember;
  }

  get staffMemberCategories(): string[] {
    return calendarModule.staffMemberCategories;
  }

  get getIntervalMinutes(): number {
    return calendarModule.intervalMinutes;
  }

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  //Show event info? (Clicked on event)
  public bookOpen: boolean = false;

  movingAppointment = null;
  selectedEvent: IAppointmentForWeeklyCalenderDTO | null = null;
  selectedElement = null;
  selectedOpen: boolean = false;
  start = null;
  dragTimeout: number | null = null;
  end = null;
  public focus: string = "";

  width = 0;
  height = 0;

  startTime = "";
  public highlightEvent: IAppointmentForWeeklyCalenderDTO | null = null;
  private hasResolvedHighlightedEvent: boolean = false;
  private defaultStaffMemberId: number = null;

  // Add to data properties
  dragEvent: IAppointmentForWeeklyCalenderDTO | null = null;
  dragTime: number | null = null;
  createEvent: IAppointmentForWeeklyCalenderDTO | null = null;
  createStart: number | null = null;
  extendOriginal: number | null = null;

  isDraggedEvent: boolean = false;
  draggedEventTimes: { start: number; end: number } | null = null;

  @Watch("getCurrentStaffMember")
  currentStaffMemberChanged(newValue) {
    // Only filter existing events when staff member changes
    this.filterEventsOnStaffMember();
  }

  get weekday() {
    // return [0]; //TODO: Maybe 1 day week calendar in Dashboard?

    if (this.user.hideIrrelevantDaysInCalendar == false) {
      return [1, 2, 3, 4, 5, 6, 0];
    } else {
      let arrayToReturn = [];
      let index = 0;
      this.allWorkingDays.forEach((isWorking) => {
        if (isWorking) {
          arrayToReturn.push(index);
        }
        index++;
      });
      if (arrayToReturn.includes(0)) {
        let index = arrayToReturn.indexOf(0);
        arrayToReturn.splice(index, 1);
        arrayToReturn.push(0);
      }
      if (arrayToReturn.length == 0) {
        return [1, 2, 3, 4, 5, 6, 0];
      } else {
        return arrayToReturn;
      }
    }
  }

  get showCalendar() {
    const validEnd = this.end != null || this.getCalendarType === "day" || this.getCalendarType === "4day";

    // Always show calendar if we have cached events
    const showCalendar =
      (this.isLoading == false || this.cachedEvents.length > 0) &&
      this.isLoadingSettings == false &&
      this.start != null &&
      validEnd &&
      this.hasResolvedHighlightedEvent;
    return showCalendar;
  }

  get monthName() {
    //I might consider a strategy pattern. Instead of having a switch statement, I could have a strategy for each type of calendar.
    //So, a different component for each type of calendar, and then I could just call the correct strategy based on the calendar type.
    if (this.getCalendarType === "day" || this.getCalendarType === "category") {
      if (!this.start) {
        return "";
      }

      const startMonth = this.start;

      const startDay = this.start.day;

      return `${getMonthName(startMonth.month - 1, true)} ${startDay}`;
    } else {
      if (!this.start || !this.end) {
        return "";
      }

      const startMonth = this.start;
      const endMonth = this.end;
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = this.start.year;
      const endYear = this.end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = this.start.day;
      const endDay = this.end.day;

      if (suffixMonth.month != startMonth.month) {
        return `${getMonthName(startMonth.month - 1, true)} ${startDay} - ${getMonthName(
          suffixMonth.month - 1,
          true
        )} ${endDay}`;
      } else {
        return `${getMonthName(startMonth.month - 1, true)} ${startDay} - ${endDay}`;
      }
    }
  }

  get firstInterval() {
    if (this.getIntervalMinutes == 60) {
      return 5;
    }
    if (this.getIntervalMinutes == 90) {
      return 3;
    }
    if (this.getIntervalMinutes == 30) {
      return 11;
    }
    if (this.getIntervalMinutes == 15) {
      return 23;
    }
  }
  get intervalCount() {
    // Aim at ending on 24:00
    if (this.getIntervalMinutes == 60) {
      return "20";
    }
    if (this.getIntervalMinutes == 90) {
      return "14";
    }
    if (this.getIntervalMinutes == 30) {
      return "38";
    }
    if (this.getIntervalMinutes == 15) {
      return "74";
    }
  }

  async calendarChanged({ start, end }) {
    this.start = start;
    this.end = end;
    // Only fetch events if not already loading
    if (!this.isLoading) {
      this.isLoading = true;
      const newDate = this.getTimezoneAdjustedDate(new Date(start.year, start.month - 1, start.day));
      this.currentCalendarDate = newDate;
      await this.getEvents(newDate);
    }
  }

  private searchingForAppointment(): boolean {
    const params = this.$route.params as any;
    let highlightAppointmentId = params.appointmentId;

    return highlightAppointmentId != null;
  }

  get staffMemberId() {
    return this.user.staffMemberId;
  }

  toggleFooter() {
    if (this.height < 500) {
      layoutModule.setHideMobileFooter(true);
    } else {
      layoutModule.setHideMobileFooter(false);
    }
  }
  getDimensions() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;

    this.toggleFooter();
  }

  destroyed() {
    window.removeEventListener("resize", this.getDimensions);
  }
  public async mounted(): Promise<void> {
    const vm = this;

    if (isMobile()) {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      window.addEventListener("resize", this.getDimensions);
      this.toggleFooter();
    }

    if (this.staffMembers == null || this.staffMembers.length === 0) {
      await staffMemberModule.getStaffMembers();
    }

    if (this.searchingForAppointment()) {
      vm.flashInterval = setInterval((x) => {
        vm.flashHighlightedEvent();
      }, 1000);
    }

    if (this.bookingSettings == null || this.allWorkingDays == null || this.bookingSettingsIntervals.length === 0) {
      this.isLoadingSettings = true;
      await userModule.getBookingSettings();
      await userModule.getBookingIntervalSettings();
      await workingDaysModule.getAllWorkingDays(this.staffMemberId);
      this.isLoadingSettings = false;
    } else {
      this.isLoadingSettings = false;
    }

    const now = this.getTimezoneAdjustedDate(new Date());
    let startTime = {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    };
    this.start = startTime;

    this.start = startTime;

    //TODO: I might consider making this in a "preference-loader" component that's run on first load. so that there's no flickering
    //because right now it renders 30, then 60
    let defaultIntervalMinutes = localStorage.getItem("calendar-zoom");
    if (defaultIntervalMinutes != null) {
      calendarModule.updateIntervalMinutes(parseInt(defaultIntervalMinutes));
    }

    let defaultMode = localStorage.getItem("calendarmode");
    if (defaultMode != null) {
      calendarModule.updateCalendarType(defaultMode);
    }

    let defaultStaffmemberview = localStorage.getItem("staffmemberview");
    if (defaultStaffmemberview != null) {
      if (defaultStaffmemberview == "true") {
        this.staffMemberView = true;
      }
    }
    let defaultCurrentstaffmember = localStorage.getItem("currentstaffmember");
    if (defaultCurrentstaffmember != null) {
      calendarModule.updateCurrentStaffMember(parseInt(defaultCurrentstaffmember));
    }

    if (!this.searchingForAppointment()) {
      this.hasResolvedHighlightedEvent = true;
      console.log("Getting events for now:", now);
      await this.getEvents(now);
    }
  }

  roundTime(time: string, minutesToRound) {
    let [hours, minutes] = time.split(":");
    let hoursNumber: number = parseInt(hours);
    let minutesNumber: number = parseInt(minutes);

    // Convert hours and minutes to time in minutes
    let newTime = hoursNumber * 60 + minutesNumber;

    let rounded = Math.floor(newTime / minutesToRound) * minutesToRound;
    let rHr = "" + Math.floor(rounded / 60);
    let rMin = "" + (rounded % 60);

    return rHr.padStart(2, "0") + ":" + rMin.padStart(2, "0");
  }

  // Add this helper function
  private getTimezoneAdjustedDate(date: string | Date): Date {
    try {
      if (date instanceof Date) {
        // Validate the Date object
        if (isNaN(date.getTime())) {
          console.warn("Invalid Date object provided, using current date");
          return new Date();
        }
        // If it's already a Date object, create new date at midnight
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        // For Date objects, we don't need timezone adjustment since they're already in local time
        return newDate;
      }
      // Handle string input
      if (!date) {
        console.warn("Invalid date string provided, using current date");
        return new Date();
      }
      // For date strings like "2024-11-20", create date explicitly in local timezone
      const [year, month, day] = date.split("-").map(Number);
      const localDate = new Date(year, month - 1, day, 0, 0, 0, 0);
      if (isNaN(localDate.getTime())) {
        console.warn("Invalid date string format, using current date");
        return new Date();
      }
      return localDate;
    } catch (error) {
      console.warn("Error processing date:", error);
      return new Date();
    }
  }

  get startTimeForCalendar() {
    if (this.startTime == "" || this.startTime == " ") {
      return new Date();
    } else {
      return this.startTime;
    }
  }

  gotoAppointment(num) {
    appointmentsModule.appointmentSource = "kalender";
    this.$router.push("/bestillinger/" + num).catch(() => {});
  }

  get calendarTypeForCalendar() {
    return this.getCalendarType;
  }

  filterEventsOnStaffMember() {
    calendarModule.filterEvents();
    this.filteredEvents = calendarModule.filteredEvents;
  }

  // Calendar ref type
  $refs!: {
    calendar: CalendarRef;
  };

  // Add helper method to check if a date range is loaded
  private isDateRangeLoaded(start: Date, end: Date): boolean {
    const current = new Date(start);
    while (current <= end) {
      const dateString = current.toISOString().split("T")[0];
      if (!this.loadedDays.has(dateString)) {
        return false;
      }
      current.setDate(current.getDate() + 1);
    }
    return true;
  }

  // Add helper to add days to loaded set
  private addDaysToLoaded(start: Date, end: Date): void {
    const current = new Date(start);
    while (current <= end) {
      this.loadedDays.add(current.toISOString().split("T")[0]);
      current.setDate(current.getDate() + 1);
    }
  }

  async getEvents(providedDate?: Date) {
    let needsLoading = false;
    let startDate: Date;
    let endDate: Date;

    // Determine date range based on calendar type
    if (providedDate == null) {
      providedDate = this.getTimezoneAdjustedDate(new Date());
    }
    startDate = new Date(providedDate);

    if (this.getCalendarType === "week") {
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
    } else if (this.getCalendarType === "day") {
      endDate = startDate;
    } else if (this.getCalendarType === "4day") {
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 3);
    } else if (this.getCalendarType === "month") {
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    }

    // Check if we need to load new days
    needsLoading = !this.isDateRangeLoaded(startDate, endDate);

    // Always cache current events if we have any
    if (this.events.length > 0) {
      const newCachedEvents = [...this.events].filter(
        (event) => !this.cachedEvents.some((cached) => cached.appointmentId === event.appointmentId)
      );
      this.cachedEvents = [...this.cachedEvents, ...newCachedEvents];

      const newCachedFilteredEvents = [...this.filteredEvents].filter(
        (event) => !this.cachedFilteredEvents.some((cached) => cached.appointmentId === event.appointmentId)
      );
      this.cachedFilteredEvents = [...this.cachedFilteredEvents, ...newCachedFilteredEvents];
    }

    if (needsLoading) {
      this.isLoadingNewDays = true;
    }
    this.isLoading = true;

    try {
      if (this.getCalendarType === "week" || this.getCalendarType === "day" || this.getCalendarType === "category") {
        this.events = await appointmentsModule.getBookedAppointmentsForWeek(providedDate);
      } else if (this.getCalendarType === "month") {
        this.events = await appointmentsModule.getBookedAppointmentsForMonth(providedDate);
      } else if (this.getCalendarType === "4day") {
        this.events = await appointmentsModule.getBookedAppointmentsForTwoWeeks(providedDate);
      }

      // Add the loaded days to our set
      this.addDaysToLoaded(startDate, endDate);

      if (this.start != null) {
        this.isLoading = false;
      }

      await this.setStartTimeToHighlightedDateAndSetHihglightEvent();

      if (this.getCurrentStaffMember != 0 && this.staffMembers.length > 1) {
        this.filterEventsOnStaffMember();
      } else {
        this.filteredEvents = this.events;
      }

      await calendarModule.setEvents(this.events);
      this.filteredEvents = calendarModule.filteredEvents;
    } catch (error) {
      console.error("Error loading events:", error);
      if (this.cachedEvents.length > 0) {
        this.events = this.cachedEvents;
        this.filteredEvents = this.cachedFilteredEvents;
      }
    }

    this.isLoading = false;
    this.isLoadingNewDays = false;
  }

  async setStartTimeToHighlightedDateAndSetHihglightEvent() {
    const params = this.$route.params as any;
    let highlightAppointmentId = params.appointmentId;

    if (highlightAppointmentId != null) {
      let appointment = await this.getAppointment(highlightAppointmentId);

      const appointmentDate = new Date(
        appointment.year,
        appointment.month - 1,
        appointment.day,
        appointment.hour,
        appointment.minute,
        0
      );
      this.startTime = appointmentDate.toISOString().substr(0, 10);

      this.highlightEvent = this.events.find((x) => x.appointmentNumber == highlightAppointmentId);
      this.hasResolvedHighlightedEvent = true;
    }
  }

  flashHighlightedEvent() {
    if (this.highlightEvent != null) {
      if (this.highlightEvent.color == "green") {
        this.highlightEvent.color = "#57c95b";
      } else {
        this.highlightEvent.color = "green";
      }
    }
  }

  async confirmMove(date, time, appointmentId) {
    let selectHourSplit = time.split(":");
    let hour: number = parseInt(selectHourSplit[0]) as number;
    let min: number = parseInt(selectHourSplit[1]) as number;

    let newDate = new Date(date);
    let fixedDate = {
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    };

    let update: Partial<IAppointmentDTO> = {
      year: fixedDate.year,
      month: fixedDate.month,
      day: fixedDate.day,
      hour: hour,
      minute: min,
    };

    const payload: IAppointmentPatch = {
      appointmentId: appointmentId,
      patch: update,
    };

    await this.updateAppointment(payload);

    await this.getEvents(this.currentCalendarDate);
  }

  changeCategory({ category }) {
    console.log("Change category:", category);
    let defaultStaff = this.staffMembers.find((x) => {
      return (x.firstName + " " + x.lastName).trim() == category.trim();
    });

    if (defaultStaff != null) this.defaultStaffMemberId = defaultStaff.id;
  }

  clickTime({ date, time }, event: MouseEvent | TouchEvent) {
    let vm = this;

    if (!this.canWriteAppointmentsAndClients) {
      return;
    }

    if (this.appointmentToMove != null) {
      let id = this.appointmentToMove;
      this.appointmentToMove = null;

      let useTime = this.roundTime(time, this.getIntervalMinutes);

      let displayDate = this.getTimezoneAdjustedDate(new Date(date));
      let wot = formatDate(displayDate);

      let dialog: IDialog = {
        text: this.$t(this.$ts.calendarMessages.moveAppointmentTo) + " " + wot + " " + useTime + "?",
        type: DialogType.Choice,
        action: function () {
          vm.confirmMove(date, useTime, id);
        },
      };

      dialogModule.addToDialogQueue(dialog);
      return;
    }

    console.log("Event:", event);

    this.selectedEvent = event as any;

    // Don't open dialog if we just finished dragging
    if (this.isDraggedEvent) {
      this.isDraggedEvent = false;
      return;
    }

    if (this.selectedOpen) {
      return;
    }

    const open = () => {
      if (this.selectedOpen) {
        this.bookOpen = false;
        return;
      }
      let newDate = this.getTimezoneAdjustedDate(date);

      let selectedDateToGetAppointmentsFrom = {
        day: newDate.getDate(),
        month: newDate.getMonth(),
        year: newDate.getFullYear(),
      };
      this.selectDate(selectedDateToGetAppointmentsFrom);

      let useTime = this.roundTime(time, this.getIntervalMinutes);

      this.selectHour(useTime);

      //@ts-ignore
      this.selectedEvent = event as IAppointmentForWeeklyCalender;

      setTimeout(() => (this.bookOpen = true), 10);
    };

    if (this.bookOpen) {
      this.bookOpen = false;
      setTimeout(open, 10);
    } else {
      setTimeout(open, 10);
    }
  }

  get getCalendarMargin() {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    //No default padding
    if (width < 1263) {
      return {
        "margin-top": "10px",
      };
    } else {
      //Has main header
      return {
        "margin-top": "40px",
      };
    }
  }

  showEvent({ nativeEvent, event }) {
    // Prevent showing event menu during navigation
    if (this.isLoading) {
      return;
    }

    if (this.highlightEvent != null) {
      clearInterval(this.flashInterval);
      this.highlightEvent.color = "green";
    }

    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  weekdayFormat(weekday) {
    return getWeekDay(weekday.weekday);
  }
  eventFormat(event) {
    if (this.getCalendarType == "month") {
      return (
        "<strong>" + event.input.name + "</strong>  - <span>" + event.start.time + "-" + event.end.time + "</span>"
      );
    } else {
      let html = "<strong>" + event.input.name + "</strong>";
      if (this.user.showTimeInEvent) {
        console.log("True");
        html += "<br> <span>" + event.start.time + "-" + event.end.time + "</span>";
      }
      console.log("Show service name in event: ", this.user.showServiceNameInEvent);

      if (this.user.showServiceNameInEvent && !event.input.isCustom) {
        console.log("True");

        html += "<br> <span>" + event.input.appointmentName + "</span>";
      }

      if (this.user.showStaffMemberInEvent == 1) {
        html += "<br> <span>" + event.input.staffMemberName + "</span>";
      }
      return html;
    }
  }
  intervalFormat(interval) {
    if (isMobile()) {
      if (interval.minute != 0) {
        return "";
      } else {
        return interval.time.substring(0, 2);
      }
    } else {
      return formatTimeWithFormat(interval.hour, interval.minute, true);
    }
  }

  formatTimeWithFormatForEvent(eventInfo) {
    return formatTimeWithFormat(eventInfo.hour, eventInfo.minute, false, false);
  }

  intervalStyle(interval) {
    if (this.hasMultipleStaff && this.getCurrentStaffMember === 0) {
      return { background: "white" };
    }

    let color = "#F7F7F7";

    if (interval.time == "05:00") {
      return { background: color };
    }
    if (interval.time == "00:00") {
      return { background: color };
    }
    if (interval.time == "24:00") {
      return { background: color };
    }

    if (interval.category != null && interval.category != "") {
      let staffMember: Partial<IStaffMemberDTO> = this.staffMembers.find(
        (x) => x.firstName + " " + x.lastName == interval.category
      );

      if (staffMember == null) {
        //Shouldn't happen, we should be more aggressive loading staffmembers. sometimes old staff members from a previous account is still in memory
        return { background: color };
      }

      //bookingSettingsIntervals is different because we sent "1" as staff member in the backend --- we recieve array of staff members that is formatted like the old ones

      let bookingSettingsInterval = this.bookingSettingsIntervals.find((x) => {
        return x.staffMemberId === staffMember.id;
      });
      let o = bookingSettingsInterval.intervals[interval.weekday];
      return this.getHourColorForStaffWeekdayInterval(interval, color, o);
    }

    let staffMember: Partial<IStaffMemberDTO> = this.staffMembers.find((x) => x.id === this.staffMemberId);

    let bookingSettingsInterval = this.bookingSettingsIntervals.find((x) => {
      return x.staffMemberId === staffMember.id;
    });
    let o = bookingSettingsInterval.intervals[interval.weekday];

    return this.getHourColorForStaffWeekdayInterval(interval, color, o);
  }

  getHourColorForStaffWeekdayInterval(interval, color, bookingSettingsIntervals) {
    let workingHoursForThisDay: CalendarInterval = bookingSettingsIntervals;
    if (workingHoursForThisDay == null) {
      return { background: color };
    }

    //TODO: Move this logic backend
    let intervalTime = new Date();
    intervalTime.setHours(interval.hour);
    intervalTime.setMinutes(interval.minute);
    intervalTime.setSeconds(0);

    //TODO: Do this operation only once if keep in front-end...
    let startTimeForThisWeekDay = new Date();
    startTimeForThisWeekDay.setHours(workingHoursForThisDay.openHour);
    startTimeForThisWeekDay.setMinutes(workingHoursForThisDay.openMinute);
    startTimeForThisWeekDay.setSeconds(0);

    let endTimeForThisWeekday = new Date();
    endTimeForThisWeekday.setHours(workingHoursForThisDay.closeHour);
    endTimeForThisWeekday.setMinutes(workingHoursForThisDay.closeMinute);
    endTimeForThisWeekday.setSeconds(0);

    if (intervalTime >= endTimeForThisWeekday || intervalTime < startTimeForThisWeekDay) {
      return { background: color };
    }

    return { background: "white" };
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents(this.currentCalendarDate);
  }

  get isMobile() {
    return isMobile();
  }

  async onDeleted() {
    if (this.selectedEvent) {
      const deletedAppointmentId = this.selectedEvent.appointmentId;
      // Remove from cached events
      this.cachedEvents = this.cachedEvents.filter((event) => event.appointmentId !== deletedAppointmentId);
      this.cachedFilteredEvents = this.cachedFilteredEvents.filter(
        (event) => event.appointmentId !== deletedAppointmentId
      );
    }

    setTimeout(() => (this.selectedEvent = null), 10);
    setTimeout(() => (this.selectedElement = null), 10);
    this.selectedOpen = false;
    await this.getEvents();
  }

  async onMoved() {
    this.selectedOpen = false;

    await this.getEvents(this.currentCalendarDate);
  }

  addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  getEventColor(event) {
    if (this.appointmentToMove === event.appointmentId) {
      return this.addAlpha(event.color, 0.5);
    } else {
      return event.color;
    }
  }

  get now() {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    //@ts-ignore
    const now = new Date(new Date() - tzoffset);
    //Why? If it's a sunday 12:39, it's actually monday.
    //But umm
    //Super fucking edge-case, but if it's sunday 12:39, new Date().getDay will show Sunday
    //fuck it. i don't understand. it works tho.

    let nowWeekDay = now.getDay();
    let isWorkingToday = this.allWorkingDays[nowWeekDay];
    if (isWorkingToday || this.user.hideIrrelevantDaysInCalendar == false) {
      return now.toISOString().substr(0, 10);
    } else {
      //Handle edge-case, where if we don't work on this current day (say, Sunday) and the current day is Sunday,
      //And we hide non-working days in the calendar, a v-calendar bug will appear where
      //The week calendar will include Monday of the next week too...
      let traversed = 0;
      let newWeekDayIndex = 0;
      let direction = 1;

      while (traversed < 7) {
        traversed++;
        newWeekDayIndex += direction;
        if (nowWeekDay === 0) {
          newWeekDayIndex = 6;
          direction = -1;
        }
        let isWorkingInIndex = this.allWorkingDays[newWeekDayIndex];
        if (isWorkingInIndex) {
          let latestWorkingDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * traversed);
          return latestWorkingDay.toISOString().substr(0, 10);
        }
      }
      let now = new Date().toISOString().substr(0, 10);
      return now; //Okay... so there is no working days... uh... just return now I guess. Why use a calendar if no days? *shrug*
    }
  }

  async onSummaryClose() {
    this.selectedOpen = false;

    await this.getEvents();
  }

  moveAppointment(id) {
    this.appointmentToMove = id;
    this.selectedOpen = false;
  }

  get hideMobileFooter() {
    return layoutModule.hideMobileFooter;
  }

  goPrevious(e) {
    if (Math.abs(e.touchendX - e.touchstartX) > 100) {
      let calendar: any = this.$refs.calendar;
      if (calendar != null) {
        calendar.prev();
      }
    }
  }

  getFirstWordOfString(value) {
    return value.split(" ")[0];
  }

  goNext(e) {
    if (Math.abs(e.touchendX - e.touchstartX) > 100) {
      let calendar: any = this.$refs.calendar;
      if (calendar != null) {
        calendar.next();
      }
    }
  }

  // Methods that use appointments module
  async getAppointment(appointmentNumber: number) {
    return await appointmentsModule.getAppointmentByNumber(appointmentNumber);
  }

  selectDate(date: any) {
    appointmentsModule.selectDateToBook(date);
  }

  selectHour(hour: any) {
    appointmentsModule.selectHourToBook(hour);
  }

  async updateAppointment(payload: IAppointmentPatch) {
    await appointmentsModule.patchAppointment(payload);
  }

  async loadAppointmentsOverview() {
    await appointmentsModule.getAppointmentsForOverview();
  }

  // Navigation methods
  async prev(event?: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }
    // Close any open menus
    this.selectedOpen = false;
    this.selectedEvent = null;
    this.selectedElement = null;

    const calendar = this.$refs.calendar;
    if (calendar && typeof (calendar as any).prev === "function") {
      (calendar as any).prev();
      // Calendar change event will handle fetching events
    }
  }

  async next(event?: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }
    // Close any open menus
    this.selectedOpen = false;
    this.selectedEvent = null;
    this.selectedElement = null;

    const calendar = this.$refs.calendar;
    if (calendar && typeof (calendar as any).next === "function") {
      (calendar as any).next();
      // Calendar change event will handle fetching events
    }
  }

  // Add new methods
  startDrag({ event, timed }: { event: IAppointmentForWeeklyCalenderDTO; timed: boolean }): void {
    if (event && timed) {
      this.isDraggedEvent = true;
      this.dragEvent = event;
      this.dragTime = null;
      this.extendOriginal = null;
    }
  }

  startTimeDrag(tms: any): void {
    const mouse = this.toTime(tms);
    if (this.dragEvent && this.dragTime === null) {
      const startTime = new Date(this.dragEvent.start).getTime();
      this.dragTime = mouse - startTime;
    } else {
      if (!this.dragTimeout) {
        this.dragTimeout = window.setTimeout(() => {
          this.createStart = this.roundDraggableTime(mouse);
          const startDate = new Date(this.createStart);
          const endDate = new Date(this.createStart + 30 * 60 * 1000); // 30 minutes later
          this.createEvent = {
            appointmentId: Math.random(),
            appointmentNumber: Math.random(),
            name: "New Event",
            color: this.rndElement(this.colors),
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            isCustom: false,
            staffMemberName: "Demo Staff",
            appointmentName: "Demo Appointment",
            status: "Pending",
            adminNote: "",
            customerId: 0,
            staffMemberId: 0,
            appointmentPrice: 0,
            appointmentTypeIds: [],
            booked: false,
            category: "",
            createdByCustomer: false,
            staffMembersProvidingService: [],
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
            hour: startDate.getHours(),
            minute: startDate.getMinutes(),
            comment: "",
            customerEmail: "",
            customerPhone: "",
            customFields: [],
            duration: 30,
            isRegularCustomer: false,
          };
          this.events.push(this.createEvent);
          this.dragTimeout = null;
        }, 200);
      }
    }
  }

  private rnd(a: number, b: number): number {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }

  private rndElement<T>(arr: T[]): T {
    return arr[this.rnd(0, arr.length - 1)];
  }
  get colors() {
    return [
      "rgba(117, 117, 117, 0.5)", // #757575 at 50% opacity
    ];
  }

  mouseMove(tms: any): void {
    const mouse = this.toTime(tms);

    if (this.dragEvent && this.dragTime !== null) {
      const startTime = new Date(this.dragEvent.start).getTime();
      const endTime = new Date(this.dragEvent.end).getTime();
      const duration = endTime - startTime;
      const newStartTime = mouse - this.dragTime;
      const newStart = this.roundDraggableTime(newStartTime);
      const newEnd = newStart + duration;

      this.dragEvent.start = new Date(newStart).toISOString();
      this.dragEvent.end = new Date(newEnd).toISOString();
    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = this.roundDraggableTime(mouse, false);
      const min = Math.min(mouseRounded, this.createStart);
      const max = Math.max(mouseRounded, this.createStart);

      this.createEvent.start = new Date(min).toISOString();
      this.createEvent.end = new Date(max).toISOString();
    }
  }

  roundDraggableTime(time: number, down: boolean = true): number {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
  }

  endDrag(): void {
    if (this.dragTimeout) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = null;
    }

    if (this.dragEvent) {
      console.log("Drag event exists:", this.dragEvent);

      // Store the dragged event times before clearing
      this.draggedEventTimes = {
        start: this.dragEvent.start,
        end: this.dragEvent.end,
      };
      console.log("Dragged event times stored:", this.draggedEventTimes);

      // Open create appointment with dragged event times
      let startDate = new Date(this.draggedEventTimes.start);
      console.log("Start date from dragged event:", startDate);

      let selectedDate = {
        day: startDate.getDate(),
        month: startDate.getMonth(),
        year: startDate.getFullYear(),
      };
      console.log("Selected date:", selectedDate);
      this.selectDate(selectedDate);

      let startTime = `${startDate.getHours().toString().padStart(2, "0")}:${startDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      console.log("Start time:", startTime);
      this.selectHour(startTime);

      // setTimeout(() => {
      //   this.bookOpen = true;
      //   // The create-appointment component will need to be modified to accept these props
      //   this.$nextTick(() => {
      //     const createAppointmentComponent = this.$refs.createAppointment;
      //     if (createAppointmentComponent) {
      //       createAppointmentComponent.serviceId = -1;
      //       createAppointmentComponent.fromDraggedEvent = true;
      //       createAppointmentComponent.draggedEventStart = this.draggedEventTimes.start;
      //       createAppointmentComponent.draggedEventEnd = this.draggedEventTimes.end;
      //     }
      //   });
      // }, 10);
    } else {
      console.log("No drag event to process.");
    }

    console.log("Resetting drag and create event states.");

    const eventIndex = this.events.findIndex((event: any) => event.timed !== null && event.timed === true);
    console.log("Event index found:", eventIndex);
    if (eventIndex !== -1) {
      const event = this.events[eventIndex];
      console.log("Added event:", event);
      this.events.splice(eventIndex, 1);
      console.log("Event removed from events array");

      const start = new Date(event.start);
      const end = new Date(event.end);
      console.log("Start:", start, "End:", end);

      let selectedDateToGetAppointmentsFrom = {
        day: start.getDate(),
        month: start.getMonth(),
        year: start.getFullYear(),
      };
      console.log("Selected date to get appointments from:", selectedDateToGetAppointmentsFrom);

      this.selectDate(selectedDateToGetAppointmentsFrom);

      let useTime = `${start.getHours().toString().padStart(2, "0")}:${start.getMinutes().toString().padStart(2, "0")}`;
      let useTimeEnd = `${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`;
      console.log("Use time:", useTime);
      console.log("Use time end:", useTimeEnd);

      this.selectHour(useTime);
      appointmentsModule.forceDefaultEndTime = useTimeEnd;

      this.bookOpen = true;

      auditLogModule.postAuditLog({
        action: 1,
        type: 2,
        comment: "",
        message: "He created a new event with dragging: " + useTime + "-" + useTimeEnd,
        hideForUser: true,
      });
    }

    this.dragTime = null;
    this.dragEvent = null;
    this.createEvent = null;
    this.createStart = null;
    this.extendOriginal = null;
  }

  cancelDrag(): void {
    if (this.dragTimeout) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = null;
    }

    console.log("cancelDrag: Entering cancelDrag method");
    if (this.createEvent) {
      console.log("cancelDrag: createEvent exists");
      if (this.extendOriginal) {
        console.log("cancelDrag: extendOriginal exists");
        this.createEvent.end = new Date(this.extendOriginal).toISOString();
        console.log("cancelDrag: createEvent.end set to", this.createEvent.end);
      } else {
        console.log("cancelDrag: extendOriginal does not exist");
        const i = this.filteredEvents.indexOf(this.createEvent);
        console.log("cancelDrag: Index of createEvent in filteredEvents is", i);
        if (i !== -1) {
          this.filteredEvents.splice(i, 1);
          console.log("cancelDrag: createEvent removed from filteredEvents");
        }
      }
    }
    this.createEvent = null;
    console.log("cancelDrag: createEvent set to null");
    this.createStart = null;
    console.log("cancelDrag: createStart set to null");
    this.dragTime = null;
    console.log("cancelDrag: dragTime set to null");
    this.dragEvent = null;
    console.log("cancelDrag: dragEvent set to null");
  }

  private createDemoEvent(): IAppointmentForWeeklyCalenderDTO {
    const now = new Date();
    const end = new Date(now.getTime() + 30 * 60 * 1000); // 30 minutes later
    return {
      appointmentId: Math.random(),
      appointmentNumber: Math.random(),
      name: "Demo Event",
      color: "#" + Math.floor(Math.random() * 16777215).toString(16),
      start: now.toISOString(),
      end: end.toISOString(),
      isCustom: false,
      staffMemberName: "Demo Staff",
      appointmentName: "Demo Appointment",
      status: "Pending",
      adminNote: "",
      customerId: 0,
      staffMemberId: 0,
      appointmentPrice: 0,
      appointmentTypeIds: [],
      booked: false,
      category: "",
      createdByCustomer: false,
      staffMembersProvidingService: [],
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
      hour: now.getHours(),
      minute: now.getMinutes(),
      comment: "",
      customerEmail: "",
      customerPhone: "",
      customFields: [],
      duration: 30,
      isRegularCustomer: false,
    };
  }

  private toTime(tms: { year: number; month: number; day: number; hour: number; minute: number }): number {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }

  private roundTimeInMilliseconds(time: number, down: boolean = true): number {
    const roundTo = 15 * 60 * 1000; // Match existing 15-minute interval
    return down ? time - (time % roundTo) : time + (roundTo - (time % roundTo));
  }

  extendBottom(event: IAppointmentForWeeklyCalenderDTO): void {
    this.createEvent = event;
    this.createStart = new Date(event.start).getTime();
    this.extendOriginal = new Date(event.end).getTime();
  }
}
</script>

<template>
  <div>
    <!-- this shold actually be a child of the header of mobile -->

    <div>
      <CalendarDesktopHeader
        class="hidden-xs-only"
        @next="next()"
        @prev="prev()"
        :monthName="monthName"
      ></CalendarDesktopHeader>

      <CalendarMobileHeader v-if="!hideMobileFooter" :monthName="monthName" @prev="prev()" @next="next()" />

      <v-sheet :style="getCalendarMargin" height="100%">
        <div v-if="!showCalendar && !cachedEvents.length">
          <loader></loader>
        </div>
        <!-- 
        <span v-for="event in cachedEvents" :key="event.appointmentId">
          {{ event.appointmentId }}
        </span> -->

        <!-- Add overlay loader -->
        <template v-if="isLoadingNewDays || isLoading">
          <div class="calendar-overlay">
            <loader></loader>
          </div>
        </template>

        <v-calendar
          v-touch="{ left: (e) => goNext(e), right: (e) => goPrevious(e) }"
          v-if="!isLoadingSettings"
          v-show="showCalendar"
          :first-interval="firstInterval"
          ref="calendar"
          v-model="focus"
          :start="startTimeForCalendar"
          :now="now"
          :weekdays="weekday"
          :type="calendarTypeForCalendar"
          :events="isLoading ? cachedFilteredEvents : filteredEvents"
          category-show-all
          :categories="staffMemberCategories"
          :weekday-format="weekdayFormat"
          :event-overlap-threshold="30"
          :interval-style="intervalStyle"
          :interval-format="intervalFormat"
          :event-name="eventFormat"
          :event-color="getEventColor"
          @change="calendarChanged"
          event-overlap-mode="stack"
          :interval-count="intervalCount"
          :interval-minutes="getIntervalMinutes"
          @click:event="showEvent"
          @click:time="clickTime"
          @mousedown:event="startDrag"
          @mousedown:time="startTimeDrag"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
          interval-height="40"
          :max-days="5"
        >
          <template v-slot:category="{ category }">
            <div class="v-calendar-category__category" :data-category="category">
              <!-- Category header content -->
              {{ category }}
            </div>
          </template>

          <!-- Customize the category column -->
          <template v-slot:category-column="{ category }">
            <div class="v-calendar-category__column" :data-category="category">
              <!-- Column content will be rendered here -->
            </div>
          </template>

          <template v-slot:time-category="slotData">
            <div class="time-slot" :style="{ height: slotData.timeIntervalHeight + 'px' }">
              <!-- Time slot content -->
            </div>
          </template>

          <template v-slot:event="{ event, timed, eventParsed }">
            <!-- <div
              class="v-event-draggable"
              :style="{
                opacity: isLoading || (dragEvent && dragEvent.appointmentId === event.appointmentId) ? '0.5' : '1',
                cursor: dragEvent ? 'grabbing' : 'grab',
              }"
            > -->
            <EventCard :event="event" :eventParsed="eventParsed" :user="user" />
            <!-- </div> -->
            <!-- <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div> -->
          </template>
        </v-calendar>
      </v-sheet>

      <EventMenu
        :selectedOpen.sync="selectedOpen"
        :selectedEvent="selectedEvent"
        :selectedElement="selectedElement"
        @deleted="onDeleted"
        @moved="onMoved"
        @move="moveAppointment"
        @goto-appointment="gotoAppointment"
        @summary-close="onSummaryClose"
      />

      <v-dialog
        v-model="bookOpen"
        v-if="bookOpen"
        :close-on-content-click="false"
        offset-x
        :fullscreen="isMobile"
        :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        max-width="700px"
      >
        <create-appointment
          ref="createAppointment"
          :open="bookOpen"
          :default-override-staff-member="defaultStaffMemberId"
          @close="bookOpen = false"
          :shoulScroll="false"
          @added="addedAppointment"
        ></create-appointment>
      </v-dialog>
    </div>
  </div>
</template>

<style scoped>
.calendar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  cursor: ns-resize;
  z-index: 2;
}

.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 2px;
  height: 4px;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  width: 24px;
  margin-left: -12px;
  opacity: 0.8;
  content: "";
  background: rgba(0, 0, 0, 0.1);
}

.v-event-drag-bottom:hover::after {
  display: block;
}
</style>
