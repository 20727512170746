<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../ToolTip.vue";
import { IUserDTO } from "@shared/types";
import CompanyInfoField from "../../global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import { userModule } from "@/store/modules/user/userModule";
import { askForPermissioToReceiveNotifications } from "@/Utilities/authConfig";
@Component({
  name: "BookingNotificationSettings",
  components: { ToolTip, CompanyInfoField, Textfield },
})
export default class BookingNotificationSettings extends Vue {
  public recieveEmailOnAppointment: boolean = false;
  public recieveSMSOnAppointment: boolean = false;
  public emailAddressToReceive: string = "";
  public phoneNumberToReceive: string = "";
  public recieveWhatsAppOnAppointment: boolean = false;
  public whatsAppNumberToReceive: string = "";

  created() {
    this.recieveEmailOnAppointment = userModule.user.recieveEmailOnAppointment;
    this.recieveSMSOnAppointment = userModule.user.recieveSMSOnAppointment;
    this.recieveWhatsAppOnAppointment = userModule.user.recieveWhatsAppOnAppointment;
    this.emailAddressToReceive = userModule.user.emailAddressToReceive;

    this.phoneNumberToReceive = userModule.user.phoneNumberToReceive;
    this.whatsAppNumberToReceive = userModule.user.whatsAppNumberToReceive;
  }

  enablePushNotifications() {
    askForPermissioToReceiveNotifications();
  }
  


  async saveChanges(field: string, value: string | boolean): Promise<void> {
    try {
      const updatedUserPayload: Partial<IUserDTO> = {
        [field]: value,
      };

      await userModule.updateUser(updatedUserPayload);
    } catch (error) {
      console.error(error);
    }
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <!-- Receive Email on Appointment - Switch -->
        <CompanyInfoField
          :label="$t($ts.receiveEmailOnAppointment)"
          :description="$t($ts.doYouWantToReceiveEmailOnNewAppointment)"
        >
          <v-switch
            v-model="recieveEmailOnAppointment"
            color="#56dcb6"
            :label="booleanToOnOff(recieveEmailOnAppointment)"
            @change="saveChanges('recieveEmailOnAppointment', $event)"
          ></v-switch>
        </CompanyInfoField>

        <v-divider />

        <!-- Receive Email on Appointment - Email Text Field -->
        <CompanyInfoField
          v-if="recieveEmailOnAppointment"
          :label="$t($ts.emailAddress)"
          :description="$t($ts.whichEmailAddressToReceive)"
        >
          <Textfield
            v-model="emailAddressToReceive"
            @blur="saveChanges('emailAddressToReceive', emailAddressToReceive)"
          />
        </CompanyInfoField>

        <v-divider />

        <!-- Receive SMS on Appointment - Switch -->
        <CompanyInfoField
          :label="$t($ts.receiveSMSOnAppointment)"
          :description="$t($ts.doYouWantToReceiveSMSOnNewAppointment)"
        >
          <v-switch
            v-model="recieveSMSOnAppointment"
            color="#56dcb6"
            :label="booleanToOnOff(recieveSMSOnAppointment)"
            @change="saveChanges('recieveSMSOnAppointment', $event)"
          ></v-switch>
        </CompanyInfoField>

        <v-divider />

        <!-- Receive SMS on Appointment - Phone Text Field -->
        <CompanyInfoField
          v-if="recieveSMSOnAppointment"
          :label="$t($ts.phoneNumber)"
          :description="$t($ts.whichPhoneNumberToReceiveSMS)"
        >
          <Textfield v-model="phoneNumberToReceive" @blur="saveChanges('phoneNumberToReceive', phoneNumberToReceive)" />
        </CompanyInfoField>

        <v-divider />

        <!-- Receive WhatsApp on Appointment - Switch -->
        <CompanyInfoField
          :label="$t($ts.receiveWhatsAppOnAppointment)"
          :description="$t($ts.doYouWantToReceiveWhatsAppOnNewAppointment)"
        >
          <v-switch
            v-model="recieveWhatsAppOnAppointment"
            color="#56dcb6"
            :label="booleanToOnOff(recieveWhatsAppOnAppointment)"
            @change="saveChanges('recieveWhatsAppOnAppointment', $event)"
          ></v-switch>
        </CompanyInfoField>

        <v-divider />

        <!-- Receive WhatsApp on Appointment - Phone Text Field -->
        <CompanyInfoField
          v-if="recieveWhatsAppOnAppointment"
          :label="$t($ts.whichWhatsAppNumberToReceive)"
          :description="$t($ts.whichWhatsAppNumberToReceive)"
        >
          <Textfield
            v-model="whatsAppNumberToReceive"
            @blur="saveChanges('whatsAppNumberToReceive', whatsAppNumberToReceive)"
          />
        </CompanyInfoField>

        <CompanyInfoField
          label="Click 2 Enable push notifications?"
          description="Enable push notifications for your device"
        >
          <v-btn color="#56dcb6" @click="enablePushNotifications">Enable push notifications</v-btn>
        </CompanyInfoField>

        <v-divider />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
