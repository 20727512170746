export interface IEntity {
  id: number;
}

export abstract class LocalRepository<T extends IEntity> {
  protected data: T[] = [];

  constructor(initialData?: T[]) {
    if (initialData) {
      this.data = [...initialData];
    }
  }

  protected setData(data: T[]): void {
    this.data = [...data];
  }

  protected createCopy<U>(item: U): U {
    return JSON.parse(JSON.stringify(item));
  }

  protected async getAll(): Promise<T[]> {
    return this.data.map((item) => this.createCopy(item));
  }

  protected async getById(id: number): Promise<T | null> {
    const item = this.data.find((item) => item.id === id);
    return item ? this.createCopy(item) : null;
  }

  protected async create(data: Partial<T>): Promise<T> {
    const newItem = {
      ...data,
      id: Math.max(0, ...this.data.map((e) => e.id)) + 1,
    } as T;

    this.data.push(newItem);
    return this.createCopy(newItem);
  }

  protected async update(id: number, data: Partial<T>): Promise<T> {
    const index = this.data.findIndex((item) => item.id === id);
    if (index === -1) {
      throw new Error("Entity not found");
    }

    const updatedItem = {
      ...this.data[index],
      ...data,
    };

    this.data[index] = updatedItem;
    return this.createCopy(updatedItem);
  }

  protected async delete(id: number): Promise<void> {
    const index = this.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.data.splice(index, 1);
    }
  }
}
