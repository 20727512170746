<template>
  <v-row fluid class="flex-nowrap flex-shrink-1 ma-0 pa-0" justify="space-between">
    <v-col class="ma-0 pa-1" align="center" justify="center" v-for="item in itemList" :key="item.text">
      <v-btn class="mobile-header-button" icon @click="$emit('item-click', item)">
        <img v-if="selectedIndex === item.index" :width="iconSize" :height="iconSize" :src="item.image2" alt="" />
        <img v-else :width="iconSize" :height="iconSize" :src="item.image" alt="" />
      </v-btn>
    </v-col>

    <v-col v-if="canWriteAppointmentsAndClients" class="ma-0 pa-1" align="center" justify="center">
      <v-btn icon @click="$emit('new-click')">
        <img :width="iconSize" :height="iconSize" src="/img/mobile/plus.png" alt="" />
      </v-btn>
    </v-col>

    <v-col v-if="canViewAppointmentsAndClients" class="ma-0 pa-1" align="center" justify="center">
      <notification-bell
        :width="iconSize"
        :height="iconSize"
        :group2="selectedIndex"
        @clicked="$emit('notification-click')"
        :useImage="true"
      ></notification-bell>
    </v-col>

    <v-col class="ma-0 pa-1" align="center" justify="center">
      <v-btn icon @click="$emit('menu-click')">
        <img :width="iconSize" :height="iconSize" src="/img/mobile/dots.svg" alt="" />
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import NotificationBell from "../global/NotificationBell.vue";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "NavigationItems",
  components: { NotificationBell },
})
export default class NavigationItems extends Vue {
  @Prop({ required: true }) selectedIndex!: number;

  get canViewStatisticsAndFinances() {
    return userPermissionsModule.canViewStatisticsAndFinances;
  }
  get canWriteAppointmentsAndClients() {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }
  get canViewAppointmentsAndClients() {
    return userPermissionsModule.canViewAppointmentsAndClients;
  }
  get canViewAdministration() {
    return userPermissionsModule.canViewAdministration;
  }

  get iconSize() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 420 ? 24 : 32;
  }

  get itemList() {
    const width = document.documentElement.clientWidth;
    const items = [];

    if (this.canViewAppointmentsAndClients) {
      items.push(
        {
          index: 0,
          alt: this.$t(this.$ts.mobileHeader.home),
          text: this.$t(this.$ts.mobileHeader.home),
          image: "/img/mobile/dashboard.svg",
          image2: "/img/mobile/dashboard_fill.svg",
          route: "/admin",
          icon: "dashboard",
        },
        {
          index: 1,
          alt: this.$t(this.$ts.mobileHeader.calendar),
          text: this.$t(this.$ts.mobileHeader.calendar),
          image: "/img/mobile/calendar.svg",
          image2: "/img/mobile/calendar_fill.svg",
          route: "/kalender",
          icon: "calendar",
        }
      );

      if (width > 800) {
        items.push(
          {
            index: 2,
            alt: this.$t(this.$ts.mobileHeader.customers),
            text: this.$t(this.$ts.mobileHeader.customers),
            image: "/img/mobile/customer.svg",
            image2: "/img/mobile/customer_fill.svg",
            route: "/kunder",
            icon: "account_circle",
          },
          {
            index: 3,
            alt: this.$t(this.$ts.mobileHeader.orders),
            text: this.$t(this.$ts.mobileHeader.orders),
            image: "/img/mobile/overview.svg",
            image2: "/img/mobile/overview_fill.svg",
            route: "/bestillinger",
            icon: "inbox",
          }
        );
      }
    }

    // // Add administration items if user has administration permissions
    // if (this.canViewAdministration) {
    //   items.push(
    //     {
    //       index: 4,
    //       alt: this.$t(this.$ts.sidebar.orders),
    //       text: this.$t(this.$ts.sidebar.orders),
    //       image: "/img/mobile/cart.svg",
    //       image2: "/img/mobile/cart_fill.svg",
    //       route: "/orders",
    //       icon: "shopping_cart",
    //     },
    //     {
    //       index: 5,
    //       alt: this.$t(this.$ts.sidebar.expenses),
    //       text: this.$t(this.$ts.sidebar.expenses),
    //       image: "/img/mobile/expenses.svg",
    //       image2: "/img/mobile/expenses_fill.svg",
    //       route: "/expenses",
    //       icon: "payments",
    //     }
    //   );

    //   // Add staff management if user has multiple staff members
    //   if (staffMemberModule.hasMultipleStaff) {
    //     items.push({
    //       index: 6,
    //       alt: this.$t(this.$ts.sidebar.staffManagement),
    //       text: this.$t(this.$ts.sidebar.staffManagement),
    //       image: "/img/mobile/staff.svg",
    //       image2: "/img/mobile/staff_fill.svg",
    //       route: "/staff-management",
    //       icon: "mdi-account-group",
    //     });
    //   }
    // }

    if (width > 800) {
      items.push({
        index: 7,
        alt: this.$t(this.$ts.mobileHeader.settings),
        text: this.$t(this.$ts.mobileHeader.settings),
        image: "/img/mobile/settings.svg",
        image2: "/img/mobile/settings_fill.svg",
        action: () => this.$emit("settings-click"),
        icon: "calendar_today",
      });
    }

    return items;
  }
}
</script>
