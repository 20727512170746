<template>
  <v-container fluid>
    <!-- <v-tabs v-model="activeTab">
        <v-tab data-cy="inventory-items-tab">{{ $t(this.$ts.entities.inventoryItem) }}</v-tab>
        <v-tab data-cy="inventory-categories-tab">{{ $t(this.$ts.entities.inventoryItemCategory) }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item> -->
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t($ts.placeHolder.typeToSearch)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-dialog max-width="660" v-model="dialog">
      <DynamicForm
        v-if="currentItem"
        :data="currentItem"
        :entity="$t(this.$ts.entities.inventoryItem)"
        :metadata="inventoryItemMetadata"
        :is-editing="isEditing"
        :is-creating="!isEditing"
        :translation-path="nonReactiveTranslations"
        @cancel="closeDialog"
        @save="saveItem"
        @create="saveItem"
      >
        <template v-slot:custom-fields>
          <CompanyInfoField :label="$t($ts.entities.inventoryItemCategory)">
            <v-select
              v-model="currentItem.categoryId"
              :items="categories"
              item-text="name"
              item-value="id"
              :label="$t($ts.entities.inventoryItemCategory)"
              clearable
              hide-details
            ></v-select>
          </CompanyInfoField>
          <v-divider class="my-4" />
        </template>
      </DynamicForm>
    </v-dialog>

    <AddStockDialog 
      v-model="addStockDialog" 
      :item="addStockItem" 
      @close="closeAddStockDialog"
    />

    <responsive-table :items="inventoryItems" :loading="loading">
      <!-- Desktop Headers -->
      <template v-slot:headers>
        <th>{{ $t($ts.name) }}</th>
        <th>{{ $t($ts.entities.inventoryItemCategory) }}</th>
        <th>{{ $t($ts.dtos.inventoryItemFormDTO.currentStock.name) }}</th>
        <th>{{ $t($ts.dtos.inventoryItemFormDTO.salePrice.name) }}</th>
        <th>{{ $t($ts.dtos.inventoryItemFormDTO.costPrice.name) }}</th>
        <th>{{ $t($ts.fields.actions) }}</th>
      </template>

      <!-- Desktop Row -->
      <template v-slot:desktop-row="{ item }">
        <td>
          <div class="d-flex align-center">
            <ImageUpload
              :current-image-url="item.imageUrl"
              :size="50"
              :is-loading="imageLoading && selectedItemId === item.id"
              :default-icon="'mdi-image-outline'"
              @file-selected="(file) => onImageSelected(file, item.id)"
              class="mr-4"
            />
            {{ item.name }}
          </div>
        </td>
        <td>{{ getCategoryName(item.categoryId) }}</td>
        <td>
          <v-chip :color="getStockColor(item.currentStock, item.minimumStockThreshold)" text-color="white" small>
            {{ item.currentStock }}
          </v-chip>
        </td>
        <td>{{ formatCurrency(item.salePrice) }}</td>
        <td>{{ formatCurrency(item.costPrice) }}</td>
        <td>
          <v-btn small color="primary" class="mr-2" @click="openAddStockDialog(item)">
            <v-icon small left>mdi-plus-circle</v-icon>
            {{ $t($ts.actions.addStock) }}
          </v-btn>
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </td>
      </template>

      <!-- Mobile Header -->
      <template v-slot:mobile-header="{ item }">
        <div class="d-flex align-center">
          <ImageUpload
            :current-image-url="item.imageUrl"
            :size="40"
            :is-loading="imageLoading && selectedItemId === item.id"
            :default-icon="'mdi-image-outline'"
            @file-selected="(file) => onImageSelected(file, item.id)"
            class="mr-3"
          />
          <div>
            <div class="font-weight-medium">{{ item.name }}</div>
            <div class="text-caption">{{ getCategoryName(item.categoryId) }}</div>
          </div>
        </div>
      </template>

      <!-- Mobile Content -->
      <template v-slot:mobile-content="{ item }">
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.dtos.inventoryItemFormDTO.currentStock.name) }}</v-list-item-subtitle>
              <v-list-item-title>
                <v-chip :color="getStockColor(item.currentStock, item.minimumStockThreshold)" text-color="white" x-small>
                  {{ item.currentStock }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.dtos.inventoryItemFormDTO.salePrice.name) }}</v-list-item-subtitle>
              <v-list-item-title>{{ formatCurrency(item.salePrice) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.dtos.inventoryItemFormDTO.costPrice.name) }}</v-list-item-subtitle>
              <v-list-item-title>{{ formatCurrency(item.costPrice) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <div class="d-flex justify-space-around mt-2">
                <v-btn small color="primary" @click="openAddStockDialog(item)">
                  <v-icon small left>mdi-plus-circle</v-icon>
                  {{ $t($ts.actions.addStock) }}
                </v-btn>
                <v-btn small icon class="mx-2" @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn small icon color="error" @click="deleteItem(item)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </responsive-table>
    <!-- </v-tab-item>

        <v-tab-item>
          <InventoryCategories />
        </v-tab-item>
      </v-tabs-items> -->
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { inventoryModule } from "@/store/modules/inventory/inventoryModule";
import { inventoryItemCategoryModule } from "@/store/modules/inventory/inventoryItemCategoryModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { IInventoryItemDTO, IPostInventoryItemDTO, IFieldMetadata, DisplayType, IAddStockDTO, LedgerEntryType, LedgerEntrySourceType, LedgerEntryStatus } from "@shared/types";
import { DialogType, type IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import ImageUpload from "@/components/global/ImageUpload.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import InventoryCategories from "./InventoryCategories.vue";
import { userModule } from "@/store/modules/user/userModule";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";
import AddStockDialog from "@/components/Inventory/AddStockDialog.vue";

@Component({
  components: {
    DynamicForm,
    ImageUpload,
    InventoryCategories,
    CompanyInfoField,
    ResponsiveTable,
    AddStockDialog,
  },
})
export default class InventoryItems extends Vue {
  private search = "";
  private dialog = false;
  private currentItem: IPostInventoryItemDTO | IInventoryItemDTO | null = null;
  private isEditing = false;
  private addStockDialog = false;
  private addStockItem: IInventoryItemDTO | null = null;
  private imageLoading = false;
  private selectedItemId: number | null = null;
  private activeTab: number = 0;
  private selectedMoneyAccountId: number | null = null;
  private totalPurchasePrice: number = 0;
  private isPricePerUnit: boolean = true;

  get loading() {
    return inventoryModule.loading;
  }

  get inventoryItems() {
    return inventoryModule.inventoryItems;
  }

  get categories() {
    return inventoryItemCategoryModule.categories;
  }

  get addStockMetadata() {
    return metadataModule.addStockMetadata;
  }

  get inventoryItemMetadata() {
    return metadataModule.inventoryItemMetadata;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.inventoryItemFormDTO });
  }

  get nonReactiveAddStockMetadata() {
    return Object.freeze({ ...this.$ts.dtos.addStockFormDTO });
  }

  get addStockData() {
    return {
      quantity: 0,
      purchasePrice: Number(this.isPricePerUnit ? this.addStockItem?.costPrice ?? 0 : 0),
      purchaseDate: new Date().toISOString().split("T")[0],
      batchNumber: "",
      inventoryItemId: Number(this.addStockItem?.id ?? 0),
      moneyAccountId: this.selectedMoneyAccountId,
    };
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }

  private getStockColor(currentStock: number, threshold: number): string {
    if (currentStock <= 0) return "error";
    if (currentStock <= threshold) return "warning";
    return "success";
  }

  private async onImageSelected(file: File, itemId: number): Promise<void> {
    this.imageLoading = true;
    this.selectedItemId = itemId;
    let formData = new FormData();
    formData.append("file", file);
    try {
      await inventoryModule.uploadItemImage({ itemId, formData });
    } catch (error) {
    } finally {
      this.imageLoading = false;
      this.selectedItemId = null;
    }
  }

  private openCreateDialog() {
    this.isEditing = false;
    this.currentItem = {
      name: "",
      description: "",
      salePrice: 0,
      costPrice: 0,
      currentStock: 0,
      minimumStockThreshold: 0,
      reorderLevel: 0,
      sku: "",
      imageUrl: "",
      isStockItem: false,
      categoryId: null,
      eligibleForCommission: false,
      category: null,
      commissionPercentage: 0,
      createdAt: new Date(),
      id: 0,
    };
    this.dialog = true;
  }

  private editItem(item: IInventoryItemDTO) {
    this.isEditing = true;
    this.currentItem = { ...item };
    this.dialog = true;
  }

  private closeDialog() {
    this.dialog = false;
    this.currentItem = null;
    this.isEditing = false;
  }

  private deleteItem(item: IInventoryItemDTO) {
    const dialog: IDialog = {
      text: this.$t(this.$ts.genericEntityDialog.deleteConfirmation, {
        entity: this.$t(this.$ts.entities.inventoryItem),
      }).toString(),
      type: DialogType.Choice,
      action: () => this.deleteItemConfirm(item.id),
    };
    dialogModule.addToDialogQueue(dialog);
  }

  private async deleteItemConfirm(id: number) {
    await inventoryModule.deleteItem(id);
  }

  private async saveItem(item: IPostInventoryItemDTO) {
    try {
      if (this.isEditing && (this.currentItem as IInventoryItemDTO)?.id) {
        await inventoryModule.updateItem({
          id: (this.currentItem as IInventoryItemDTO).id,
          item: item,
        });
      } else {
        await inventoryModule.createItem(item);
      }
    } catch (error) {
    } finally {
      this.closeDialog();
    }
  }

  private openAddStockDialog(item: IInventoryItemDTO) {
    this.addStockItem = { ...item };
    this.addStockDialog = true;
  }

  private closeAddStockDialog() {
    this.addStockDialog = false;
    this.addStockItem = null;
  }

  private getCategoryName(categoryId: number | null): string {
    if (!categoryId) return this.$t(this.$ts.orders.uncategorized).toString();
    const category = this.categories.find((c) => c.id === categoryId);
    return category ? category.name : this.$t(this.$ts.orders.uncategorized).toString();
  }

  private updateTotalPrice(value: number): void {
    if (this.addStockData.quantity > 0) {
      this.totalPurchasePrice = value * this.addStockData.quantity;
    }
  }

  private updatePricePerUnit(value: number): void {
    if (this.addStockData.quantity > 0) {
      this.addStockData.purchasePrice = value / this.addStockData.quantity;
    }
  }

  async created() {
    await Promise.all([
      inventoryModule.getAllItems(),
      inventoryItemCategoryModule.getCategories(),
      moneyAccountModule.getAllAccounts(),
    ]);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .v-chip {
    font-size: 12px;
  }

  ::v-deep tbody tr {
    height: 80px !important;
  }

  ::v-deep tbody td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
</style>
