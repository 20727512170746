import { BaseService } from "./Base/BaseService";
import { IVacationDayController, IVacationDayDTO } from "@shared/types";

export class VacationDayService extends BaseService implements IVacationDayController {
  private static instance: VacationDayService;

  private constructor() {
    super();
  }
  public getVacationDays(staffMemberId?: number): Promise<IVacationDayDTO[]> {
    return this.http.sendRequest<IVacationDayDTO[]>(IVacationDayController.GetVacationDaysRoute, {
      params: { staffMemberId },
    });
  }

  public getVacationDaysAnonymous(
    query: Partial<typeof IVacationDayController.IGetVacationDaysAnonymousQuery>
  ): Promise<IVacationDayDTO[]> {
    return this.http.sendRequest<IVacationDayDTO[]>(IVacationDayController.GetVacationDaysAnonymousRoute, {
      query: query,
    });
  }

  public static getInstance() {
    if (!VacationDayService.instance) {
      VacationDayService.instance = new VacationDayService();
    }
    return VacationDayService.instance;
  }

  public async addVacationDays(query: Partial<typeof IVacationDayController.IAddVacationDaysQuery>) {
    return this.http.sendRequest<void>(IVacationDayController.AddVacationDaysRoute, {
      query: query,
    });
  }

  public async removeVacationDay(query: Partial<typeof IVacationDayController.IRemoveVacationDayQuery>) {
    return this.http.sendRequest<void>(IVacationDayController.RemoveVacationDayRoute, {
      query: query,
    });
  }
}
