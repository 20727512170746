import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { VacationDayService } from "@/services/api/VacationDayService";
import { IVacationDayController, IVacationDayDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class VacationDaysModule extends VuexModule {
  // State
  vacationDays: IVacationDayDTO[] = [];

  // Private service instance
  private vacationDayService = VacationDayService.getInstance();

  @Action
  async getVacationDays(staffMemberId?: number) {
    this.vacationDays = await this.vacationDayService.getVacationDays(staffMemberId);
  }

  @Action
  async addVacationDays(payload: typeof IVacationDayController.IAddVacationDaysQuery) {
    await this.vacationDayService.addVacationDays(payload);
    await this.getVacationDays(payload.staffMemberId);
  }

  @Action
  async removeVacationDay(payload: typeof IVacationDayController.IRemoveVacationDayQuery) {
    await this.vacationDayService.removeVacationDay(payload);
    await this.getVacationDays(payload.staffMemberId);
  }
}

export const vacationDaysModule = new VacationDaysModule({ store, name: "vacationDaysModule" });
