import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { Container } from "@/services/api/Container";
import {
  IUserDTO,
  IPostUserDTO,
  ICalendarInterval,
  IUserBookingSettingsAsHoursAndMinutesDTO,
  IStaffMemberCalendarIntervalsDTO,
  IPostFreshUserDTO,
  IUserController,
} from "@shared/types";
import type { User as FirebaseUser } from "@firebase/auth-types";
import { Sector } from "@/types/Sector";
import { createPatchObject, JsonPatchOperation } from "@/Utilities/ObjectUtility";
import { i18n } from "@/main";
import { DialogActions } from "@/store/modules/dialogModule/dialogModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";
import { subscriptionModule } from "../subscription/subscriptionModule";
import { serviceModule } from "../serviceModule/serviceModule";
import { firebaseUserModule } from "../firebaseUser/firebaseUserModule";
import { metadataModule } from "../metadata/metadataModule";
import { staffMemberModule } from "../staffMember/staffMemberModule";
import { customersModule } from "../customerModule/customerModule";
import store from "@/store/store";
import { moneyAccountModule } from "../moneyAccount/moneyAccountModule";
@Module({ generateMutationSetters: true })
class UserModule extends VuexModule {
  private userService: IUserController;
  private store: Store<any>;
  userModule: any;

  constructor(options: any) {
    super(options);
    this.store = options.store;
    this.userService = Container.getInstance().get<IUserController>();
  }

  // State
  user: IUserDTO | null = null;
  userToBook: IUserDTO | null = null;
  isLoggedIn = false;
  hasResolvedUser = false;
  isNewUser = false;
  selectedSector: Sector | null = null;
  firebaseUser: FirebaseUser | null | Partial<FirebaseUser> = null;
  bookingIntervals: IStaffMemberCalendarIntervalsDTO[] = [];
  bookingSettings: IUserBookingSettingsAsHoursAndMinutesDTO | null = null;
  @Action
  async fetchInitialUserInfo() {
    const [user] = await Promise.all([
      this.getBookingIntervalSettings(),
      this.getBookingSettings(),
      workingDaysModule.getAllWorkingDays(),
      serviceModule.getAppointmentTypes(),
      customersModule.getCustomers(),
      userModule.getBookingIntervalSettings(),
      staffMemberModule.getStaffMembers(),
      firebaseUserModule.getCurrentFirebaseUser(),
      moneyAccountModule.getAllAccounts(),
      this.getCurrentUser(),
      // subscriptionModule.getSubscriptionStatus(),
    ]);
    return user;
  }

  @Action
  async getCurrentUser() {
    const user = await this.userService.getCurrentUser();
    this.user = user;
    this.isLoggedIn = true;
    this.hasResolvedUser = true;
    return user;
  }

  @Action
  async getUser(userId: string): Promise<IUserDTO> {
    return this.userService.getUser(userId);
  }

  @Action
  async getUserByUsername(username: string): Promise<IUserDTO> {
    return this.userService.getUserByUsername(username);
  }

  @Action
  async updateUserProfile(userDto: IPostUserDTO): Promise<void> {
    await this.userService.putUserProfileOnly(userDto);
    await this.getCurrentUser();
  }

  @Action
  async updateUser(patch: Partial<IUserDTO>): Promise<void> {
    const patchPayload = createPatchObject(patch);
    await this.userService.patchUser(patchPayload);
    await this.getCurrentUser();
  }

  @Action
  async putUser(userDto: IPostUserDTO): Promise<void> {
    await this.userService.putUser(userDto);
    await this.getCurrentUser();
  }

  @Action
  async postUser(userDto: IPostFreshUserDTO): Promise<void> {
    await this.userService.postUser(userDto);
    await this.getCurrentUser();
  }

  @Action
  async registerUser(userDto: IPostFreshUserDTO): Promise<void> {
    await this.userService.postUser(userDto);
    await this.getCurrentUser();
  }

  @Action
  async updateUserImage(payload: any): Promise<boolean> {
    console.log("Payload: ", payload);
    await this.userService.postPicture(payload);
    await this.getCurrentUser();
    return true;
  }

  @Action
  async getUserAsAnonymous(adminId: string): Promise<IUserDTO> {
    const user = await this.userService.getUser(adminId);
    this.userToBook = user;
    return user;
  }

  @Action
  async getUserByNameAsAnonymous(companyName: string): Promise<IUserDTO> {
    const user = await this.userService.getUserByUsername(companyName);
    this.userToBook = user;
    return user;
  }

  @Action
  async deleteCompanyEmployee(id: string): Promise<void> {
    await this.userService.deleteCompanyEmployee(id);
  }

  @Action
  async getBookingIntervalSettings(staffMemberId?: number): Promise<void> {
    const intervals = await this.userService.getBookingIntervalSettings(staffMemberId);
    this.bookingIntervals = intervals;
  }

  @Action
  async getBookingSettings(staffMemberId?: number): Promise<IUserBookingSettingsAsHoursAndMinutesDTO> {
    const settings = await this.userService.getBookingSettings(staffMemberId);
    this.bookingSettings = settings;
    return settings;
  }

  @Action
  async updateBookingSettings(payload: {
    settings: IUserBookingSettingsAsHoursAndMinutesDTO;
    staffMemberId?: number;
  }): Promise<void> {
    await this.userService.putBookingSettings(payload.settings, payload.staffMemberId);
    await this.getBookingSettings(payload.staffMemberId);
  }

  @Action
  setFirebaseUser(user: FirebaseUser | null | Partial<FirebaseUser>): void {
    this.firebaseUser = user;
    this.isLoggedIn = user !== null;
  }

  @Action
  setSelectedSector(sector: Sector | null): void {
    this.selectedSector = sector;
  }

  @Action
  setUserToBook(user: IUserDTO | null): void {
    this.userToBook = user;
  }

  @Action
  setIsNewUser(isNew: boolean): void {
    this.isNewUser = isNew;
  }
}

export const userModule = new UserModule({ store, name: "userModule" });
