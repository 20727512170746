import { TranslationSchema } from "./translation.interface";

export const no: TranslationSchema = {
  dtos: {
    accountRoleDTO: {
      name: {
        name: "Rolle Navn",
        description: "Navnet på rollen",
      },
      appointmentsAndClients: {
        name: "Avtaler & Kunder",
        description: "Tilgangsnivå for avtaler og kunder",
      },
      settings: {
        name: "Innstillinger",
        description: "Tilgangsnivå for innstillinger",
      },
      statisticsAndFinances: {
        name: "Statistikk & Finanser",
        description: "Tilgangsnivå for statistikk og finanser",
      },
      administration: {
        name: "Administrasjon",
        description: "Tilgangsnivå for administrasjon",
      },
    },
    appointmentTypeFormDTO: {
      title: {
        name: "Tittel",
        description: "Navn på tjenesten",
      },
      description: {
        name: "Beskrivelse",
        description: "Beskrivelse av tjenesten",
      },
      price: {
        name: "Pris",
        description: "Pris på tjenesten",
      },
      durationInMinutes: {
        name: "Varighet (minutter)",
        description: "Varighet av tjenesten i minutter",
      },
      //   serviceDurationInMinutes: {
      //     name: "Tjenestevarighet (minutter)",
      //     description: "Faktisk varighet av tjenesten i minutter",
      //   },
      hideInBooking: {
        name: "Skjul i bestilling",
        description: "Når aktivert, vil denne tjenesten ikke være synlig på bestillingssiden",
      },
      autoApply: {
        name: "Auto-bruk",
        description: "Når aktivert, vil denne tjenesten bli automatisk brukt",
      },
      showCustomColor: {
        name: "Vis tilpasset farge",
        description: "Når aktivert, kan du sette en tilpasset farge for denne tjenesten",
      },
      isExtraService: {
        name: "Ekstra tjeneste",
        description: "Når aktivert, vil denne tjenesten bli behandlet som en ekstra tjeneste",
      },
      color: {
        name: "Farge",
        description: "Tilpasset farge for tjenesten",
      },
      unavailableForOnlineBooking: {
        name: "Ikke tilgjengelig for nettbestilling",
        description: "Når aktivert, kan ikke denne tjenesten bestilles online av kunder",
      },
      disablePrice: {
        name: "Deaktiver pris",
        description: "Når aktivert, vil ikke prisen vises for denne tjenesten",
      },
      mustPayOnline: {
        name: "Må betale online",
        description: "Når aktivert, må kunder betale online for denne tjenesten",
      },
      payOnlinePrice: {
        name: "Online betalingspris",
        description: "Pris for online betaling",
      },
      eligibleForCommission: {
        name: "Berettiget til provisjon",
        description: "Når aktivert, vil denne tjenesten være berettiget til provisjon",
      },
      commissionPercentage: {
        name: "Provisjonsprosent",
        description: "Prosentandelen av provisjonen til ansatt som utførte tjenesten",
      },
      eligibleForCustomPrice: {
        name: "Berettiget til tilpasset pris",
        description: "Når aktivert, kan prisen for denne tjenesten tilpasses",
      },
    },
    serviceCategoryFormDTO: {
      title: {
        name: "Tittel",
        description: "Tittelen på tjenestekategorien",
      },
    },
    staffMemberFormDTO: {
      firstName: {
        name: "Fornavn",
        description: "Fornavnet til ansatt",
      },
      lastName: {
        name: "Etternavn",
        description: "Etternavnet til ansatt",
      },
      phoneNumber: {
        name: "Telefonnummer",
        description: "Telefonnummeret til ansatt (nødvendig hvis du vil sende notifikasjoner direkte til ansatt)",
      },
      emailAddress: {
        name: "E-post",
        description: "E-posten til ansatt (valgfritt)",
      },
      eligibleForCommission: {
        name: "Berettiget til provisjon",
        description: "Når aktivert, vil denne ansatte være berettiget til provisjon",
      },
      commissionPercentageProducts: {
        name: "Provisjonsprosent for produkter",
        description: "Prosentandelen av provisjonen til ansatt for produkter",
      },
      commissionPercentageServices: {
        name: "Provisjonsprosent for tjenester",
        description: "Prosentandelen av provisjonen til ansatt for tjenester",
      },
      overrideProductsCommission: {
        name: "Overskriv provisjon for produkter",
        description: "Når aktivert, vil denne ansatte anulere provisjonen for produkter",
      },
      overrideServicesCommission: {
        name: "Overskriv provisjon for tjenester",
        description: "Når aktivert, vil denne ansatte anulere provisjonen for tjenester",
      },
      eligibleForCommissionProducts: {
        name: "Berettiget til provisjon for produkter",
        description: "Når aktivert, vil denne ansatte være berettiget til provisjon for produkter",
      },
      eligibleForCommissionServices: {
        name: "Berettiget til provisjon for tjenester",
        description: "Når aktivert, vil denne ansatte være berettiget til provisjon for tjenester",
      },

    },
    inventoryItemFormDTO: {
      name: {
        name: "Navn",
        description: "Navnet på varene",
      },
      description: {
        name: "Beskrivelse",
        description: "Beskrivelsen av varene",
      },
      salePrice: {
        name: "Salgsverdi",
        description: "Salgsverdien til varene",
      },
      currentStock: {
        name: "Nåværende lagerbeholdning",
        description: "Nåværende beholdning av varene",
      },
      costPrice: {
        name: "Kostpris",
        description: "Innkjøpsspris til varene (per enhet)",
      },
      minimumStockThreshold: {
        name: "Minimum lagerbeholdning",
        description: "Minimum beholdning av varene",
      },
      reorderLevel: {
        name: "Reorder Level",
        description: "Reorder Level til varene",
      },
      isStockItem: {
        name: "Er lagervare",
        description: "Hvis varene må kjøpes fra en leverandør og er et fysisk produkt som må kjøpes på nytt når den det er utsolgt",
      },
      eligibleForCommission: {
        name: "Berettiget til provisjon",
        description: "Når aktivert, vil denne varen være berettiget til provisjon",
      },
      commissionPercentage: {
        name: "Provisjonsprosent",
        description: "Prosentandelen av provisjonen til ansatt som solgte varen",
      },
    },
    addStockFormDTO: {
      quantity: {
        name: "Antall",
        description: "Antall varer som blir lagt til",
      },
      purchaseDate: {
        name: "Innkjøpsdato",
        description: "Innkjøpsdatoen til varene",
      },
    },
    inventoryItemCategoryFormDTO: {
      name: {
        name: "Navn",
        description: "Navnet på varelagerkategorien",
      },
      description: {
        name: "Beskrivelse",
        description: "Beskrivelsen av varelagerkategorien",
      },
    },
    ledgerEntryFormDTO: {
      amount: {
        name: "Beløp",
        description: "Beløpet i betalingen",
      },
      description: {
        name: "Beskrivelse",
        description: "Beskrivelsen av betalingen",
      },
    },
    oneTimeExpenseFormDTO: {
      amount: {
        name: "Beløp",
        description: "Beløpet i utgiften",
      },
      notes: {
        name: "Notater",
        description: "Notatene til utgiften",
      },
      purchaseDate: {
        name: "Dato",
        description: "Datoen til utgiften",
      },
    },
    recurringExpenseFormDTO: {
      amount: {
        name: "Beløp",
        description: "Beløpet i utgiften",
      },
      endDate: {
        name: "Sluttdato",
        description: "Sluttdatoen til utgiften",
      },
      name: {
        name: "Navn",
        description: "Navnet på utgiften",
      },
      startDate: {
        name: "Startdato",
        description: "Startdatoen til utgiften",
      },
    },
    moneyAccountFormDTO: {
      name: {
        name: "Navn",
        description: "Navnet på konten",
      },
      description: {
        name: "Beskrivelse",
        description: "Beskrivelsen av konten",
      },
    },
  },
  headers: {
    buyStock: "Innkjøp",
  },
  welcome: "Velkommen",
  months: {
    january: "Januar",
    february: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Desember",
  },
  days: {
    sunday: "Søn",
    monday: "Man",
    tuesday: "Tir",
    wednesday: "Ons",
    thursday: "Tor",
    friday: "Fre",
    saturday: "Lør",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Oppdater",
  },
  subscriptionWarnings: {
    message1: "Hei! Du har nå prøvd programmet gratis i 14 dager. Vi håper du finner det nyttig.",
    message2: "Hvis du ønsker å fortsette å bruke programmet, vennligst kjøp et abonnement under 'Innstillinger' -> 'Abonnement'",
    message3: "Hvis du har noen spørsmål, vennligst kontakt oss. Takk!",
  },
  buttons: {
    gotoSubscription: "Gå til Abonnement",
    close: "Lukk",
    ok: "Ok",
    save: "Lagre",
    delete: "Slett",
    cancel: "Avbryt",
    edit: "Rediger",
    create: "Opprett",
  },

  noAppointmentsToday: "Ingen avtaler i dag!",
  readNotes: "Les notater",
  readCustomerComment: "Les kundens kommentar",
  dialog: {
    customerComment: "Kundens Kommentar",
    notes: "Notater",
    confirmDeleteService: "Er du sikker på at du vil slette denne tjenesten?",
    deleteServiceHeader: "Slett Tjeneste",
    cancel: "Avbryt",
    save: "Lagre",
    confirm: "Bekreft",
    yes: "Ja",
    no: "Nei",
  },
  messages: {
    updated: "Oppdatert",
    categoryChanged: "Kategori endret",
    categoryOrderChanged: "Kategori rekkefølge endret",
    serviceOrderChanged: "Tjeneste rekkefølge endret",
    categoryUpdated: "Kategori oppdatert",
    newCategoryAdded: "Ny kategori lagt til",
    categoryDeleted: "Kategori slettet",
    newServiceAdded: "Ny tjeneste lagt til",
    couldNotAddService: "Kunne ikke legge til tjeneste",
    serviceDeleted: "Tjeneste slettet",
    serviceUpdated: "Tjeneste oppdatert",
    inProgress: "{feature} blir {action}...",
    success: "{feature} ble {action}",
    failed: "Kunne ikke {action} {feature}",
    imageUploaded: "Bildet har blitt lastet opp",
    imageUploading: "Laster opp bilde...",
    imageUploadSuccess: "Bildet ble lastet opp",
    deleteConfirmation: "Er du sikker på at du vil slette denne varen?",
    itemCreated: "Varen ble opprettet",
    itemUpdated: "Varen ble oppdatert",
    itemDeleted: "Varen ble slettet",
  },
  staff: "Ansatte",
  date: "Dato",
  service: "Tjeneste",
  price: "Pris",
  customerAccount: "Kundekonto",
  automaticAssignExistingCustomerAccount: "Tildel eksisterende kundekonto automatisk til nye bestillinger basert på felt.",
  createAssignCustomerAccountAutomatically: "Opprett/Tildel kundekonto automatisk ved bestilling",
  customerAccountWillBeAssignedBasedOnPhoneNumber: "Kundekonto tildeles eksisterende basert på telefonnummer.",
  newCustomField: "Nytt Egendefinert Felt",
  selectFieldsCustomerFillsWhenBooking: "Velg feltene kunden fyller ut ved bestilling",
  optional: "Valgfritt",
  customFields: "Egendefinerte Felt",
  add: "Legg til",
  link: "Lenke",
  yourBookingLinkWillBe: "Din bestillingslenke vil være {link}",
  connectSystemWithCommonBookingLink: "Koble systemet med en felles bestillingslenke",
  yourCommonBookingLinkWillBe: "Din felles bestillingslenke vil være {link}",
  bookingID: "Bestillings-ID",
  commonID: "Felles ID",
  locationName: "Stedsnavn",
  showInformationBox: "Vis Informasjonsboks",
  shouldInformationBoxBeDisplayed: "Skal informasjonsboksen vises på bestillingssiden?",
  informationBox: "Informasjonsboks",
  informationForCustomerOnBookingPage: "Informasjon til kunden på forsiden av bestillingssiden",
  cancellationInformation: "Avbestillingsinformasjon",
  customizeInformationCustomerSeesWhenCancelling: "Tilpass informasjonen kunden ser ved avbestilling",
  receiveEmailOnAppointment: "Motta e-post ved avtale",
  doYouWantToReceiveEmailOnNewAppointment: "Ønsker du å motta en e-post når en ny avtale er mottatt?",
  whichEmailAddressToReceive: "Hvilken e-postadresse skal motta e-posten ved bestilling?",
  receiveSMSOnAppointment: "Motta SMS ved avtale",
  doYouWantToReceiveSMSOnNewAppointment: "Ønsker du å motta en SMS når en ny avtale er mottatt?",
  whichPhoneNumberToReceiveSMS: "Hvilket telefonnummer skal motta SMS ved bestilling?",
  ssn: "Personnummer",
  birthdate: "Fødselsdato",
  gender: "Kjønn",
  city: "By",
  postalCode: "Postnummer",
  pause: "Pause",
  event: "Hendelse",
  from: "Fra",
  to: "Til",
  durationMismatch: "Varigheten av bestillingen avviker fra tjenestens varighet.",
  priceMismatch: "Prisen på bestillingen avviker fra tjenestens pris.",
  cancel: "Avbryt",
  create: "Opprett",
  confirm: "Bekreft",
  invalidTimeFormat: "Ugyldig tidsformat",
  enterCustomerName: "Du må angi et kundenavn.",
  selectService: "Du må velge en tjeneste.",
  editEndTimeWithoutStart: "Forsøkte å redigere sluttid uten å sette starttid.",
  minutesAbbreviation: "min",
  currency: "NOK",
  creationCancelled: "Oppretting avbrutt.",
  customEvent: "Egendefinert hendelse",
  monthlyEarningsTitle: "Inntekter for {month}",
  monthlyAppointmentsTitle: "Avtaler for {month}",
  totalCustomers: "Totalt Antall Kunder",
  earningsTrend: "Inntektstrend",
  servicesBooked: "Bestilte Tjenester",
  moneyEarnedIn: "Inntekter i {month}",
  appointmentsIn: "Avtaler i {month}",
  appointmentBookingLinkTitle: "Bestillingslenke for Avtale",
  appointmentBookingLinkDescription: "Bruk lenken nedenfor for å bestille en avtale.",
  bookingLinkLabel: "Bestillingslenke",
  copyLinkButton: "Kopier Lenke",
  linkCopiedMessage: "Lenke kopiert til utklippstavlen!",
  closePreview: "Lukk Forhåndsvisning",
  previewBookingPage: "Forhåndsvis Bestillingsside",
  contactUs: "Kontakt Oss",
  sidebar: {
    home: "Hjem",
    customers: "Kunder",
    calendar: "Kalender",
    settings: "Innstillinger",
    appointments: "Avtaler",
    overview: "Oversikt",
    payments: "Betalinger",
    invoices: "Fakturaer",
    statistics: "Statistikk",
    export: "Eksporter",
    notifications: "Varsler",
    contactSupport: "Kontakt Support",
    log: "Logg",
    journal: "Journal",
    inventory: "Varelager",
    inventoryItems: "Varer",
    orders: "Ordrer",
    expenses: "Utgifter",
    commissions: "Provisjoner",
    staffManagement: "Ansattstyring",
    finances: "Økonomi",
    administration: "Administrasjon",
    cashRegister: "Kassen",
  },
  name: "Navn",
  email: "E-post",
  phoneNumber: "Telefonnummer",
  address: "Adresse",
  postalCodeCity: "Postnummer/By",
  edit: "Rediger",
  created: "Opprettet",
  deleteSelectedCustomers: "Slett valgte kunder",
  search: "Søk",
  searchForCustomer: "Søk etter kunde",
  noCustomersYet: "Du har ingen kunder ennå",
  noSearchResults: "Ingen søkeresultater",
  customerWithoutName: "Kunde uten navn",
  confirmDeleteCustomers: "Er du sikker på at du vil slette {count} kunder?",
  customersDeleted: "{count} kunder er slettet",
  customer: {
    notFound: "Kunde ikke funnet.",
    name: "Navn",
    email: "E-post",
    phoneNumber: "Telefonnummer",
    address: "Adresse",
    postalCodeCity: "Postnummer/By",
    edit: "Rediger",
    created: "Opprettet",
    deleteSelectedCustomers: "Slett valgte kunder",
    search: "Søk",
    searchForCustomer: "Søk etter kunde",
    noCustomersYet: "Du har ingen kunder ennå",
    noSearchResults: "Ingen søkeresultater",
    customerWithoutName: "Kunde uten navn",
    confirmDeleteCustomers: "Er du sikker på at du vil slette {count} kunder?",
    deleteCustomerHeader: "Slett kunde(r)?",
    customersDeleted: "{count} kunder er slettet",
    deleted: "Slettet",
    couldNotDelete: "Kunne ikke slette",
    confirmDeleteCustomer: "Er du sikker på at du vil slette denne kunden?",
    credit: "Kreditt",
    dropIn: "Drop-in",
  },
  calendar: {
    week: "Uke",
    month: "Måned",
    day: "Dag",
    sideBySide: "Side om side",
    threeFourDays: "3-4 dager",
    heading: "Kalender",
  },
  appointment: {
    deleteSelectedAppointments: "Slett Valgte Avtaler",
    filter: "Filtrer",
    all: "Alle",
    showMoreCustomerInfo: "Vis Mer Kundeinformasjon",
    noAppointments: "Ingen Avtaler",
    select: "Velg",
    loading: "Laster... Vennligst vent...",
    status: {
      ny: "Ny",
      invoiceCreated: "Faktura Opprettet",
      invoiceSent: "Faktura Sendt",
      invoicePaid: "Faktura Betalt",
      ferdig: "Ferdig",
    },
    comment: "Kommentar",
    adminNote: "Admin Notat",
    created: "Opprettet",
    number: "Nummer",
    service: "Tjeneste",
    time: "Tid",
    customer: "Kunde",
    appointmentStatus: "Status",
    createAppointment: "Opprett Avtale",
    editAppointment: "Rediger Avtale",
    markAsUnpaid: "Merk som Ubetalt",
    markAsUnpaidWarning: "Advarsel: Denne handlingen kan ikke angres. Det vil bli opprettet en ny regnskapspost for denne avtalen. Vil du fortsette?",
  },
  companyInfo: {
    companyName: "Bedriftsnavn",
    companyNameDescription: "Bedriftsnavnet som vises til kunden",
    companyAddress: "Bedriftsadresse",
    companyAddressDescription: "Bedriftsadressen som vises til kunden",
    phoneNumber: "Telefonnummer",
    phoneNumberDescription: "Telefonnummeret til bedriften som vises til kunden",
    email: "E-post",
    emailDescription: "E-posten til bedriften som vises til kunden",
    companyLogo: "Bedriftslogo",
    companyLogoDescription: "Logoen til bedriften som vises til kunden",
  },
  snackbar: {
    imageTooLarge: "Bildet må være mindre enn 200 KB",
    invalidImageFormat: "Bildet må være i JPG- eller PNG-format",
    enterServiceName: "Tjenestenavn må oppgis.",
    negativeDiscountPrice: "Rabattpris kan ikke være negativ.",
    enterDuration: "Varighet i minutter må oppgis.",
    durationMustBeNumber: "Varighet i minutter må være et tall.",
    priceMustBeNumber: "Pris må være et tall.",
    negativePrice: "Pris kan ikke være negativ.",
    invalidDuration: "Ugyldig varighet.",
    durationExceedsLimit: "En enkelt tjeneste kan ikke vare lenger enn 12 timer.",
  },
  account: {
    allAccounts: "Alle Kontoer",
    registerNewAccount: "Registrer Ny Konto",
    editAccount: "Rediger Konto",
    lastSignIn: "Sist innlogget: {date}",
    created: "Opprettet: {date}",
    deleted: "Slettet",
    deleteConfirmation: "Er du sikker på at du vil slette denne kontoen? Kontoen vil bli deaktivert og markert som slettet.",
    deleteSuccess: "Kontoen ble slettet",
    deleteError: "Kunne ikke slette kontoen",
    yourAccount: "Din Konto",
  },
  role: {
    administrator: "Administrator",
    user: "Bruker",
  },
  logout: "Logg ut",
  changePassword: "Endre Passord",
  logoutConfirm: "Vil du logge ut?",
  forgotPassword: "Vil du sende en tilbakestillingslenke til {email}?",
  passwordResetEmailSent: "En e-post for tilbakestilling av passord er sendt.",
  subscription: {
    yourPlan: "Ditt Abonnement",
    nextPayment: "Neste Betaling",
    previousPayment: "Forrige Betaling",
    paymentInformation: "Betalingsinformasjon",
    cardEnding: "Visa som slutter på {card}",
    noTrial: "Din prøveperiode har utløpt. Kjøp et abonnement for å fortsette å bruke programmet.",
    paymentIssue: "Det er et problem med betalingen. Kontakt oss.",
    trialDaysLeft: "Du har {days} dager igjen av din gratis prøveperiode.",
    cancelledWithDate: "Abonnementet fornyes ikke neste måned. Det er aktivt til {date}.",
    cancelledWithoutDate: "Abonnementet fornyes ikke neste måned. Det er aktivt til slutten av måneden.",
    active: "Du har et aktivt abonnement.",
    yearlyWithSMS: "Du har et abonnement som er gyldig til {date} med SMS inkludert.",
    monthlyCost: "Abonnementet koster 250 kr per måned, uten bindingstid. Si opp når som helst. Ved oppsigelse varer abonnementet til neste betalingsdato.",
    automaticPayment: "Betalingen er automatisk per måned. Hvis du foretrekker faktura, vennligst be om det.",
    smsCost: "Hvis du har valgt å sende kvitteringer/påminnelser via SMS, eller SMS-varsling ved bestilling, koster det 1 kr per SMS og legges automatisk til betalingen.",
    purchase: "Kjøp abonnement",
    reactivate: "Reaktiver abonnement",
    cancel: "Avbryt abonnement",
    cancelSubscriptionConfirm: "Er du sikker på at du vil avbryte abonnementet?",
    subscription: "Abonnement",
  },
  createCustomer: "Opprett Kunde",
  editCustomer: "Rediger Kunde",
  lastName: "Etternavn",
  dateOfBirth: "Fødselsdato",
  male: "Mann",
  female: "Kvinne",
  selectInsurance: "Velg Forsikring",
  noInsuranceData: "Forsikringer kan legges til under innstillinger.",
  customerUpdated: "Kunde oppdatert.",
  customerCreated: "Kunde opprettet.",
  settings: {
    staffSettings: {
      alerts: {
        singleStaffBanner: "Denne ansatte er den eneste ansatte i systemet. Derfor er det ikke mulig å endre notifikasjonsinnstillinger for en bestemt ansatte. Vennligst bruk Settings -> Booking -> Alerts",
        notificationPreference: "Notifikasjonsinnstilling",
        notificationHint: "Velg notifikasjonsmetode for denne ansatte.",
        none: "Ingen",
        email: "E-post",
        whatsapp: "WhatsApp",
        sms: "SMS",
      },
    },
    me: "Meg",
    myAccount: "Min konto",
    administration: "Administrasjon",
    companyInfo: "Bedriftsinformasjon",
    subscription: "Abonnement",
    accounts: "Kontoer",
    accountRoles: "Kontoroller",
    services: "Tjenester",
    extraServices: "Ekstratjenester",
    categories: "Kategorier",
    resources: "Ressurser",
    options: "Valg",
    staff: "Ansatte",
    calendar: "Kalender",
    functions: "Funksjoner",
    newsletter: "Nyhetsbrev",
    customerAccounts: "Kundekontoer",
    invoicesAndPayments: "Fakturaer og Betalinger",
    booking: "Bestilling",
    information: "Informasjon",
    alerts: "Varsler",
    bookingSide: "Bestillingsside",
    customerInformation: "Kundeinformasjon",
    customerAccount: "Kundekontoer",
    workingHours: "Arbeidstider",
    friday: "Fridager",
    googleCalendar: "Google Kalender",
    overview: "Oversikt",
    customFields: "Egendefinerte Felt",
    giftCards: "Gavekort",
    customerFollowUp: "Oppfølging av Kunde",
    cashRegisterSystem: "Kassesystem",
    multipleLocations: "Flere Steder",
    system: "System",
    auditLog: "Handlingslogg",
    staffUpdated: "Ansatte oppdatert.",
    staffDeleted: "Ansatte slettet.",
    roleCreated: "Rolle opprettet.",
    roleUpdated: "Rolle oppdatert.",
    roleDeleted: "Rolle slettet.",
    onlineTransactions: "Nettbetalinger",
    onlineTransactionsDescription: "Konfigurer betalingsleverandører",
    paymentProvider: "Betalingsleverandør",
    paymentProviderDescription: "Velg hvilken betalingsleverandør som skal brukes for nettbetalinger",
    paymentProviderNone: "Ingen",
    paymentProviderPayU: "PayU",
    paymentProviderStripe: "Stripe (Kommer snart)",
    paymentProviderVipps: "Vipps (Kommer snart)",
    products: "Produkter",
    productCategories: "Produktkategorier",
  },
  resourceName: "Ressursnavn",
  description: "Beskrivelse",
  save: "Lagre",
  quantity: "Mengde",
  multipleServicesQuestion: "Skal kunden kunne velge flere tjenester i én bestilling?",
  fields: {
    name: "Navn",
    description: "Beskrivelse",
    actions: "Handlinger",
    orderNumber: "Ordre Nummer",
    orderDate: "Ordre Dato",
    totalAmount: "Total Beløp",
    status: "Status",
  },
  showServicePrice: "Vis pris på tjenester",
  serviceName: "Tjenestenavn",
  sort: "Sorter",
  duration: "Varighet",
  category: "Kategori",
  extraServices: "Ekstratjenester",
  boolean: {
    yes: "Ja",
    no: "Nei",
    on: "På",
    off: "Av",
  },
  categories: "Kategorier",
  services: "Tjenester",
  staffMembersOverview: {
    minOneStaffRequired: "Du må ha minst én ansatt for at programmet skal fungere.",
    confirmDeleteStaff: "Vil du slette denne ansatte?",
    staff: "Ansatte",
    yourStaff: "Dine Ansatte",
    newStaff: "Ny Ansatt",
    firstName: "Fornavn",
    lastName: "Etternavn",
    emailAddress: "E-postadresse",
    phoneNumber: "Telefonnummer",
    created: "Opprettet",
    delete: "Slett",
    lastLoggedIn: "Sist Innlogget",
    editStaff: "Rediger Ansatt",
    actions: "Handlinger",
    allFieldsRequired: "Alle felt er påkrevd",
    createNewStaff: "Opprett Ny Ansatt",
    firstNameDescription: "Den ansattes fornavn",
    lastNameDescription: "Den ansattes etternavn",
    emailOptional: "E-postadresse (valgfritt)",
    phoneOptional: "Telefonnummer (valgfritt)",
    role: "Rolle",
    roleOptional: "Ansattes rolle (valgfritt)",
    requiredFieldsMissing: "Fornavn og etternavn er påkrevd",
    errorCreatingStaff: "Kunne ikke opprette ansatt",
    errorUpdatingStaff: "Kunne ikke oppdatere ansatt",
    errorDeletingStaff: "Kunne ikke slette ansatt",
    firstNameRequired: "Fornavn er påkrevd",
    lastNameRequired: "Etternavn er påkrevd",
    invalidEmail: "Ugyldig e-postadresse",
    invalidPhoneNumber: "Ugyldig telefonnummer",
  },
  staffMemberDetails: {
    firstName: "Fornavn",
    lastName: "Etternavn",
    phoneNumber: "Telefonnummer",
    emailAddress: "E-postadresse",
    colorForCalendar: "Farge for kalender",
    selectColor: "Velg 'Farge' under 'Kalender' -> 'Vis ansatt i hendelse' for å velge en farge for ansatte.",
    save: "Lagre",
    profileImage: "Profilbilde",
    uploadImage: "Last opp bilde",
  },
  staffMemberGoogleCalendar: {
    confirmRemoveSync: "Er du sikker på at du vil fjerne synkroniseringen?",
    syncGoogleCalendar: "Synkroniser Google Kalender med Availify",
    syncGoogleCalendarWithAvailify: "Synkroniser Google Kalender med Availify",
    benefit1: "Motta bestillinger direkte i Google og Apple kalendere.",
    benefit2: "Hendelser i Google/Apple kalendere vil automatisk bli lagt til i Availify og blokkert for bestilling.",
    connectGoogleAccount: "Koble til en Google-konto",
    clickButtonToLogin: "Klikk på knappen for å logge inn",
    addEventsToAppleCalendar: "Legg til hendelser fra Google Kalender til Apple Kalender",
    useAppleDevices: "Bruker du iPhone/iPad? Synkroniser Google med Apple Kalender.",
    addGoogleCalendarToApp: "Legg til Google Kalender i appen",
    start: "Start",
    continue: "Fortsett",
    step1: "Trinn 1",
    step2: "Trinn 2",
    step3: "Trinn 3",
    removeSync: "Fjern Synkronisering",
    setAsDefaultCalendar: "Sett som standardkalender",
    defaultStep1: "Standard trinn 1",
    defaultStep2: "Standard trinn 2",
    done: "Ferdig",
    synchronizedWithGoogle: "Synkronisert med Google",
    syncBenefit1: "Synkroniseringsfordel 1",
    syncBenefit2: "Synkroniseringsfordel 2",
    syncBenefit3: "Synkroniseringsfordel 3",
    syncBenefit4: "Synkroniseringsfordel 4",
    howToConnectAppleCalendar: "Hvordan koble til Apple Kalender",
  },
  vacationDays: {
    markDayAs: "Merk dagen som:",
    available: "Tilgjengelig",
    notAvailable: "Ikke Tilgjengelig",
  },
  workingHours: {
    timeRequired: "Tid er påkrevd",
    invalidFormat: "Ugyldig format (TT:MM)",
    invalidTimeFormat: "Kunne ikke oppdatere åpningstider. Sjekk om du har angitt riktig format (TT:MM)",
    updated: "Åpningstider oppdatert.",
    availableOnThisDay: "Tilgjengelig denne dagen: {value}",
    openingHourFor: "Åpningstid for {day}",
    closingHourFor: "Stengetid for {day}",
    breaks: "Pauser",
    start: "Start",
    end: "Slutt",
    addBreak: "Legg til Pause",
    save: "Lagre",
  },
  generalBookingSettings: {
    customerVerification: "Kundeverifisering",
    customerVerificationDescription: "Hva må kunden bekrefte for å bestille en avtale?",
    cancellationModification: "Avbestilling/Endring av Avtaler",
    cancellationModificationDescription: "Skal kunden kunne endre/avbestille avtalen?",
    minimumHoursBefore: "Minste timer før avtalen",
    minimumHoursBeforeDescription: "Antall timer før avtalen som kunden kan avbestille",
    remindersReceipts: "Påminnelser/Kvitteringer til Kunder",
    remindersReceiptsDescription: "Hvordan skal kvitteringer/påminnelser for avtalen sendes?",
    alwaysSendReceipt: "Send alltid kvittering",
    alwaysSendReceiptDescription: "Boksen trenger ikke å være merket av kunden",
    alwaysSendReminder: "Send alltid påminnelse",
    alwaysSendReminderDescription: "Boksen trenger ikke å være merket av kunden",
    bookingRestrictions: "Bestillingsbegrensninger",
    bookingRestrictionsDescription: "Hvor mange dager i forveien kan en kunde bestille?",
    earlyBooking: "Hvor tidlig kan en kunde bestille før avtalen?",
    earlyBookingDescription: "Antall timer før avtalen kan bestilles",
    noVerification: "Ingen Verifisering",
    email: "E-post",
    sms: "SMS",
    auditVerificationChanged: "Endret innstilling 'Kundeverifisering'",
    auditCancellationChanged: "Endret innstilling 'Avbestilling/Endring av Avtaler'",
    auditHoursToCancelChanged: "Endret innstilling 'Minste timer før avbestilling av avtale' til {hours} timer",
    auditReceiptReminderChanged: "Endret innstilling 'Påminnelser/Kvitteringer til Kunder'",
    auditMaxDaysChanged: "Endret innstilling 'Bestillingsbegrensninger' antall dager en kunde kan bestille i forveien til {days} dager.",
    auditMinHoursChanged: "Endret innstilling 'Bestillingsbegrensninger' hvor tidlig en kunde kan bestille før avtalen {hours} timer.",
    emailRequired: "E-postfeltet er satt til påkrevd fordi informasjon sendes via e-post.",
    phoneRequired: "Telefonnummerfeltet er satt til påkrevd fordi kvitteringer og påminnelser sendes via SMS.",
    days: "Dager",
    hours: "Timer",
    whatsapp: "WhatsApp",
    staffSelection: "Valg av ansatt",
    staffSelectionDescription: "La kunder velge hvilken ansatt som skal utføre tjenesten",
    auditStaffSelectionChanged: "Endret innstilling for valg av ansatt",
    serviceSearch: "Søk etter tjenester",
    serviceSearchDescription: "La kunder søke etter tjenester etter navn",
    auditServiceSearchChanged: "Endret innstilling for søk etter tjenester",
  },
  bookingDistribution: {
    timeDivision: "Tidsinndeling",
    selectTimeSlots: "Velg tidsluker som kan bestilles.",
    confirmChange: "Er du sikker på at du vil endre tidsinndelingen til {minutes} minutter?",
    changeTimeFormat: "Endre Tidsformat",
    auditChange: "Endret tidsinndeling til {minutes} minutter for bestilling",
  },
  hideDaysOutsideWorkingHours: "Skjul dager utenfor arbeidstid",
  cannotActivateWithMultipleStaff: "Kan ikke aktiveres med flere ansatte",
  events: "Hendelser",
  showServiceNameInEvent: "Vis tjenestens navn i hendelsen",
  showStaffInEvent: "Vis ansatte i hendelsen",
  showTimeInEvent: "Vis tid i hendelsen",
  color: "Farge",

  newsletter: "Nyhetsbrev",
  askCustomersToSignUpForNewsletter: "Be kunder melde seg på nyhetsbrevet ved bestilling",
  subscribers: "Abonnenter",
  noSubscribersYet: "Her vil det være en oversikt over alle abonnenter",
  signedUp: "Registrert",
  loading: "Laster... Vennligst vent...",
  downloadExcel: "Last ned Excel (.xlsx)",
  exportAppointments: "Eksporter alle avtaler",
  eventsOverview: {
    message: "Melding",
    time: "Tid",
    comment: "Kommentar",
    booking: "Bestilling",
    receipt: "Kvittering",
    reminder: "Påminnelse",
    seen: "Sett",
    new: "Ny",
    previous: "Forrige",
    noEvents: "Du har ingen hendelser",
    guest: "Gjest",
    critical: "Kritisk",
    info: "Info",
    warning: "Advarsel",
    customer: "Kunde",
    errorWithSmsSending: "Noe gikk galt med SMS-sending. SMS-verifisering er deaktivert.",
    timeAddedToExistingCustomer: "Bestilt tid ble automatisk lagt til eksisterende kunde - men det var forskjeller.",
    timeCancelled: "Bestilt tid ble kansellert.",
    timeChanged: "Bestilt tid ble endret.",
    newCustomer: "Ny kunde.",
    customerComment: "Kundekommentar",
    bookingCancelled: "Bestilling avbrutt",
    bookingChanged: "Bestilling endret",
  },
  onboarding: {
    user: "Bruker",
    company: "Bedrift",
    workingHours: "Arbeidstider",
    services: "Tjenester",
    finish: "Fullfør",
    pickWorkingHours: "Velg Arbeidstider",
    createServices: "Opprett Tjenester",
    yourUser: "Din Bruker",
    yourCompany: "Din Bedrift",
  },
  auditOverview: {
    event: "Hendelse",
    time: "Tid",
    comment: "Kommentar",
    noChanges: "Her vil det være en oversikt over alle endringene du gjør",
    customer: "Kunde",
    booking: "Bestilling",
    settings: "Innstillinger",
    staff: "Ansatte",
    track: "Spor",
    service: "Tjeneste",
    readComment: "Les kommentar i logg",
  },
  customerModal: {
    notes: "Notater",
    createdByCustomer: "Opprettet av kunde via online booking",
    createdByStaff: "Opprettet av ansatt",
    statistics: {
      appointmentsThisMonth: "Avtaler denne måneden",
      appointmentsOverall: "Avtaler totalt",
      totalUsedForServicesThisMonth: "Total brukt for tjenester denne måneden",
      totalUsedForServicesOverall: "Total brukt for tjenester totalt",
    },
    payments: "Betalinger",
    newJournal: "Legg til ny journal",
    buttons: {
      cancel: "Avbryt",
      save: "Lagre",
    },
    dialog: {
      unsavedChangesWarning: "Du har ulagrede endringer i journalen. Hvis du forlater siden, vil dine endringer gå tapt.",
      exitConfirmation: "Du har ulagrede endringer i journalen. Er du sikker på at du vil forlate siden?",
    },
    journal: "Journal",
    generalText: "Generell tekst",
  },
  nameIsRequired: "Navn er påkrevd",
  amountMustBeOneOrHigher: "Beløp må være 1 eller høyere",
  resources: {
    deleteConfirmation: "Er du sikker på at du vil slette denne ressursen?",
    deleteHeader: "Slett Ressurs",
    name: "Navn",
    description: "Beskrivelse",
    quantity: "Antall",
    createResource: "Opprett Ressurs",
    editResource: "Rediger Ressurs",
    nameDescription: "Navn på ressursen",
    descriptionDescription: "Beskrivelse av ressursen",
    quantityDescription: "Antall av ressursen",
  },
  functions: {
    contactUs: "Kontakt Oss",
    customerLogin: "Kundelogin",
    invoicesAndPayments: "Fakturaer og Betalinger",
    customFields: "Egendefinerte Felt",
    newsletter: "Nyhetsbrev",
    giftCards: "Gavekort",
    customerFollowUp: "Kundeoppfølging",
  },
  login: {
    fillEmailPassword: "Vennligst fyll inn e-post og passord",
    passwordsMustMatch: "Passordene må være like",
    emailInUse: "E-postadressen er allerede i bruk",
    invalidEmail: "Ugyldig e-postadresse",
    weakPassword: "Passordet er for svakt",
    userNotFound: "Bruker ikke funnet",
    wrongEmailPassword: "Feil e-post eller passord",
    fillEmailForReset: "Vennligst fyll inn e-postadressen din for å tilbakestille passordet",
    sendNewPasswordTo: "Send nytt passord til",
    emailSent: "E-post sendt",
    tryFree: "Prøv gratis",
    repeatPassword: "Gjenta passord",
    password: "Passord",
    register: "Registrer",
    haveAccount: "Har du allerede en konto?",
    loginHere: "Logg inn her",
    login: "Logg inn",
    forgot: "Glemt passord?",
    next: "Neste",
    firstName: "Fornavn",
    lastName: "Etternavn",
    phoneNumber: "Telefonnummer",
    phoneNumberRequired: "Telefonnummer er påkrevd",
    firstNameRequired: "Fornavn er påkrevd",
    lastNameRequired: "Etternavn er påkrevd",
    additionalInfo: "Tilleggsinformasjon",
  },
  systemsSettings: {
    selectLanguage: "Velg Språk",
    languageDescription: "Velg språket for systemet, både for dashbordet og bestillingssiden.",
    language: "Språk",
    timeFormat: "Tidformat",
    timeFormatDescription: "Velg tidformatet for systemet, både for dashbordet og bestillingssiden.",
    timeZone: "Tidssone",
    timeZoneDescription: "Velg tidssonen for systemet. Kontakt oss for å endre.",
    defaultCountryCode: "Standard landkode",
    defaultCountryCodeDescription: "Velg standard landkoden for systemet. Dette vil bli brukt for å sette standard landkoden på bestillingssiden, og for å bestemme landkoden for SMS til personalet.",
    currency: "Valuta",
    currencyDescription: "Velg valuta for systemet. Dette vil bli brukt for å vise priser og finansiell informasjon.",
  },
  user: {
    updateFailed: "Kunne ikke oppdatere bruker.",
    smsVerificationError: "Kan ikke bruke SMS-verifisering uten et abonnement.",
    bookingLinkExists: "Denne bedriftsnavnet eksisterer fra før av. Ditt bedriftsnavn? Kontakt oss.",
    loginError: "Noe gikk galt med innlogging. Hvis dette problemet fortsetter, vennligst kontakt oss.",
    imageUploaded: "Bildet er lastet opp.",
    imageUploadError: "Bildet er for stort eller feil format. Bruk JPG eller PNG.",
  },
  hideInBooking: "Skjul i booking",
  discount: "Rabatt",
  newPrice: "Ny pris",
  assignToAllStaff: "Tilordne til alle ansatte",
  appointmentSummary: {
    fromOnlineBooking: "Fra online booking",
    assignStaff: "Tilordne ansatte",
    yourNotes: "Dine notater",
  },
  appointmentMenu: {
    moveAppointmentMessage: "Klikk på tid i kalenderen for å flytte avtalen.",
    confirmDeleteAppointment: "Er du sikker på at du vil slette denne avtalen?",
    deleteAppointmentHeader: "Slett Avtale",
    appointmentDeleted: "Avtalen har blitt slettet.",
    edit: "Rediger",
    delete: "Slett",
    findInCalendar: "Søk i Kalender",
  },
  customerModalMessages: {
    customerUpdated: "Kunde oppdatert.",
    customerUpdateFailed: "Kunde oppdatering feilet.",
  },
  multipleServices: "Flere Tjenester",
  emailAddress: "E-postadresse",
  commitChart: {
    currentYear: "Tjent {year}",
    previousYear: "Tjent {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Flytte tid til",
  },
  appointments: {
    creatingAppointment: "Oppretter avtale...",
    appointmentCreated: "Avtalen har blitt opprettet!",
    overlappingAppointment: "Avtalen overlapper med en annen avtale. Vil du opprette avtalen likevel?",
    alreadyBooked: "Avtalen du prøver å bestille har allerede blitt bestilt av noen andre.",
    overlappingWithNext: "Avtalen du prøver å bestille overlapper med neste avtale. Velg en kortere tid.",
  },
  customerName: "Kundenavn",
  createAppointmentMessage: {
    invalidTimeFormat: "Ugyldig tidformat",
    creationCancelled: "Oppretting av avtale avbrutt.",
    enterCustomerName: "Du må skrive inn et kundenavn.",
    selectService: "Du må velge en tjeneste.",
    editEndTimeWithoutStart: "Prøvde å redigere sluttid uten å sette starttid.",
    createNewCustomer: "Opprett ny kunde for {name}",
    appointmentCreationFailed: "Avtale oppretting feilet. Vær snill kontakt kundeservice.",
    overlappingAppointment: {
      title: "Overlappende Avtale",
      message: "Dette tidspunktet overlapper med en annen avtale. Vil du opprette den likevel?",
    },
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Endret innstilling 'Booking-ID' til {link}",
      changedCommonLink: "Endret innstilling 'Common-ID' til {link}",
      changedLocationName: "Endret innstilling 'Lokasjon navn' til {name}",
    },
    validation: {
      bookingLinkTooShort: "Booking link må inneholde minst 3 tegn",
    },
  },
  password: "Passord",
  dialogMessages: {
    confirm: "Bekreft",
    cancel: "Avbryt",
    no: "Nei",
  },
  $vuetify: {
    badge: "Skilt",
    close: "Lukk",
    dataIterator: {
      noResultsText: "Fant ingen matchende elementer.",
      loadingText: "Laster elementer...",
    },
    dataTable: {
      itemsPerPageText: "Rader per side:",
      ariaLabel: {
        sortDescending: "Sortert synkende.",
        sortAscending: "Sortert stigende.",
        sortNone: "Ikke sortert.",
        activateNone: "Aktiver for å fjerne sortering.",
        activateDescending: "Aktiver for å sortere synkende.",
        activateAscending: "Aktiver for å sortere stigende.",
      },
      sortBy: "Sorter etter",
    },
    dataFooter: {
      itemsPerPageText: "Elementer per side:",
      itemsPerPageAll: "Alle",
      nextPage: "Neste side",
      prevPage: "Forrige side",
      firstPage: "Første side",
      lastPage: "Siste side",
      pageText: "{0}-{1} av {2}",
    },
    datePicker: {
      itemsSelected: "{0} valgt",
      nextMonthAriaLabel: "Neste måned",
      nextYearAriaLabel: "Neste år",
      prevMonthAriaLabel: "Forrige måned",
      prevYearAriaLabel: "Forrige år",
    },
    noDataText: "Ingen data er tilgjengelig",
    carousel: {
      prev: "Forrige bilde",
      next: "Neste bilde",
      ariaLabel: {
        delimiter: "Karusellbilde {0} av {1}",
      },
    },
    calendar: {
      moreEvents: "{0} flere",
    },
    fileInput: {
      counter: "{0} filer",
      counterSize: "{0} filer ({1} totalt)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Paginasjonsnavigasjon",
        next: "Neste side",
        previous: "Forrige side",
        page: "Gå til side {0}",
        currentPage: "Gjeldende side, side {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    statistics: {
      serviceName: "Tjeneste",
      bookings: "Bestillinger",
      customers: "Kunder",
    },
  },
  serviceCategories: {
    nameRequired: "Navn må oppgis",
    confirmDelete: "Er du sikker på at du vil slette denne kategorien?",
    deleteHeader: "Slett tjeneste",
    editCategory: "Rediger kategori",
    name: "Navn",
    cancel: "Avbryt",
    save: "Lagre",
    categoryName: "Navn på kategori",
    create: "Opprett",
    sort: "Sorter",
    table: {
      name: "Navn",
      sort: "Sorter",
    },
    validation: {
      nameRequired: "Navn på kategorien er påkrevd",
    },
    createCategory: "Opprett kategori",
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Endret teksten i informasjonsboksen.",
      changedShowInformationBox: "Endret innstilling 'Vis informasjonsboksen' til {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice: "Endret innstilling 'Vis pris på tjenester' til {setting}",
      changedMultipleServices: "Endret innstilling 'Flervalg av tjenester' til {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices: "Endret innstilling 'Kundefelt' til {field}",
    },
  },
  routes: {
    login: "Logg inn",
    login_: "Logg inn",
    Settings: "Innstillinger",
    CompanyInfo: "FirmaInfo",
    SystemSettings: "Systeminnstillinger",
    Services: "Tjenester",
    Categories: "Kategorier",
    Resources: "Ressurser",
    Subscription: "Abonnement",
    Accounts: "Kontoer",
    BookingAlerts: "BestillingsVarsler",
    CustomerInformation: "KundeInformasjon",
    Booking: "Bestilling",
    BookingSide: "BestillingsSide",
    Options: "Alternativer",
    Staff: "Personale",
    CalendarSettings: "Kalenderinnstillinger",
    Newsletter: "Nyhetsbrev",
    AuditLog: "Revisjonslogg",
    ExtraServices: "EkstraTjenester",
    BookingInformation: "BestillingsInformasjon",
    CustomerAccount: "KundeKonto",
    Kunder: "Kunder",
    Kunde: "Kunde",
    Kalender: "Kalender",
    KalenderBestilling: "KalenderBestilling",
    Hjem: "Hjem",
    oAuthCallBack: "OAuthTilbakekalling",
    Onboarding: "Oppstart",
    muscleGroupSelector: "MuskelgruppeVelger",
    Statistikk: "Statistikk",
    Eksporter: "Eksporter",
    Bestillinger: "Bestillinger",
    Bestilling: "Bestilling",
    Faktura: "Faktura",
    Betalinger: "Betalinger",
    Gavekort: "Gavekort",
    Tjenester: "Tjenester",
    Hendelser: "Hendelser",
    Logg: "Logg",
    AdminLogg: "AdminLogg",
    Debug: "Feilsøking",
    Register: "Registrer",
    StaffWorkingHours: "Arbeidstider",
    StaffGoogleCalendar: "Google Kalender",
    StaffFriday: "Feriedager",
    StaffServices: "Tjenester",
    StaffInformation: "Personaleinformasjon",
    StaffOverview: "Personaleoversikt",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Skjul dager utenfor arbeidstid",
    showServiceNameInEvent: "Vis tjenestenavn i hendelse",
    showTimeInEvent: "Vis tidspunkt i hendelse",
    showStaffInEvent: "Vis ansatt i hendelse",
  },
  statistics: {
    serviceName: "Tjenestenavn",
    bookings: "Bestillinger",
    customers: "Kunder",
    allMonths: "Alle Måneder",
    data: "Data",
    current: "Nåværende",
    previous: "Forrige",
    day: "Dag",
    earnings: "Inntjening",
    month: "Måned",
    year: "År",
    showPerStaffMember: "Vis Per Ansatt",
    appointments: "Avtaler",
    totalExpenses: "Totale Utgifter",
    totalNetProfit: "Total Netto Fortjeneste",
    commission: "Provisjon",
  },
  all: "Alle",

  createService: {
    title: "Opprett Tjeneste",
    editTitle: "Rediger Tjeneste",
    assignToAllStaff: "Tilordne til alle ansatte",
    assignToAllStaffDescription: "Hvis merket, vil tjenesten bli tilordnet til alle ansatte.",
    discount: "Rabatt",
    discountDescription: "Hvis merket, vil tjenesten ha en rabatt.",
    newPrice: "Ny Pris",
    newPriceDescription: "Den nye prisen til tjenesten.",
    hideInBooking: "Skjul i Bestilling",
    hideInBookingDescription: "Skjul tjenesten i bestillingssiden.",
    serviceName: "Tjenestenavn",
    serviceNameDescription: "Navnet til tjenesten.",
    description: "Beskrivelse",
    descriptionDescription: "Beskrivelsen til tjenesten.",
    price: "Pris",
    priceDescription: "Prisen til tjenesten.",
    duration: "Varighet",
    durationDescription: "Varigheten til tjenesten i minutter.",
    durationForStaff: "Varighet for Personal",
    durationForStaffDescription: "Varigheten i minutter til tjenesten for at personal skal kunne fullføre den.",
    durationForCustomer: "Varighet for Kunde",
    durationForCustomerDescription: "Varigheten i minutter til tjenesten som vises til kundene.",
    color: "Farge",
    colorDescription: "Fargen til tjenesten i kalenderen.",
    showCustomColor: "Vis egendefinert farge",
    showCustomColorDescription: "Hvis merket, vil tjenesten ha en egendefinert farge.",
    customColor: "Egendefinert farge",
    customColorDescription: "Egendefinert fargen til tjenesten.",
    discountPrice: "Rabattert pris",
    discountPriceDescription: "Den rabatterte prisen til tjenesten.",
    discountPriceChange: "Rabattert pris endret",
    discountPriceChangeDescription: "Den rabatterte prisen til tjenesten har blitt endret.",
    mustPayOnline: "Må betale på nett",
    mustPayOnlineDescription: "Hvis merket, må kunder betale på nett ved bestilling av denne tjenesten.",
    payOnlinePrice: "Nettbetalingspris",
    payOnlinePriceDescription: "Beløpet som må betales på nett ved bestilling av denne tjenesten.",
  },
  validation: {
    required: "Dette feltet er påkrevd",
    min: "Verdien må være minst {value}",
    max: "Verdien må være mindre enn {value}",
    number: "Må være et gyldig tall",
    email: "Må være en gyldig e-postadresse",
    phone: "Må være et gyldig telefonnummer",
    length: "Må være mindre enn {value} tegn",
    pattern: "Ugyldig format",
  },
  writeHere: "Skriv her...",
  registerFirebase: {
    email: "E-post",
    password: "Passord",
    passwordAgain: "Passord igjen",
    register: "Registrer",
    emailAlreadyInUse: "E-post allerede i bruk",
    invalidEmail: "Ugyldig e-post",
    passwordTooWeak: "Passord for svakt",
    emailAndPasswordRequired: "Email og passord er påkrevd",
    passwordsMustMatch: "Passordene må være like",
  },
  placeHolder: {
    typeToSearch: "Skriv for å søke",
  },
  selectDate: "Velg dato",
  dashboard: {
    hints: {
      title: "Dashbord Hint",
      servicesWithoutStaff: "Tjenester uten tilordnet personale",
      assignStaff: "Tilordne Personale",
      serviceUnavailableExplanation: "Denne tjenesten er for øyeblikket ikke tilgjengelig for bestilling fordi ingen ansatte er satt til å utføre den.",
      serviceAssignmentSteps: "For å gjøre denne tjenesten tilgjengelig for bestilling, følg disse trinnene:",
      step1Settings: "Gå til Innstillinger",
      step2StaffMembers: "Velg Ansatte",
      step3SelectStaff: "Velg en ansatt å redigere",
      step4Services: "Gå til Tjenester-fanen",
      step5EnableService: "Aktiver denne tjenesten for den ansatte",
      goToSettings: "Gå til Innstillinger",
    },
  },
  accountRole: {
    title: "Konto Roller",
    editRole: "Rediger Rolle",
    createRole: "Opprett Ny Rolle",
    newRole: "Ny Rolle",
    actions: "Handlinger",
    permissionLevels: {
      none: "Ingen",
      read: "Lese",
      readWrite: "Lese/Skrive",
    },
    owner: "Eier",
    ownerRole: "Denne kontoen har eierrettigheter og rollen kan ikke endres.",
  },
  receiveWhatsAppOnAppointment: "Motta WhatsApp ved avtale",
  doYouWantToReceiveWhatsAppOnNewAppointment: "Ønsker du å motta en WhatsApp når en ny avtale er mottatt?",
  whichWhatsAppNumberToReceive: "Hvilket WhatsApp-nummer skal motta meldingen ved bestilling?",
  bookingPageCustomerSettings: {
    auditLogs: {
      changedAssignmentType: "Endret innstilling 'Automatisk tildeling ny kunde' til {setting}",
      changedCustomerAccountCreation: "Endret innstilling 'Opprett/Tildel kundekonto automatisk ved bestilling' til {setting}",
    },
  },
  mobileHeader: {
    home: "Hjem",
    calendar: "Kalender",
    customers: "Kunder",
    new: "Ny",
    orders: "Bestillinger",
    settings: "Innstillinger",
    log: "Logg",
    statistics: "Statistikk",
    overview: "Oversikt",
    booking: "Bestilling",
    staff: "Personale",
  },
  invoiceSettings: {
    kidNumber: {
      title: "KID-nummer",
      lengthTitle: "KID-lengde (2-25)",
      lengthDescription: "Sett lengden på KID-nummeret",
      modTitle: "KID-kontrollsiffer",
      modDescription: "Sett kontrollsifferet for KID-nummeret",
      description: "Konfigurer innstillinger for KID-nummer på fakturaer",
      invalidKidLength: "Ugyldig KID-lengde",
      useKid: "Bruk KID",
      kidLength: "KID-lengde (2-25)",
      kidMod: "KID-kontrollsiffer",
      agreementNote: "Husk å sette opp en OCR-avtale før du bruker denne funksjonen. Du finner skjemaet på Nets sin nettside eller kan gjøre det elektronisk via nettbanken din.",
    },
    tax: {
      title: "Merverdiavgift (%)",
      description: "Sett standard MVA-prosent for fakturaene dine",
    },
    taxNumber: {
      title: "Organisasjonsnummer",
      description: "Sett organisasjonsnummeret for fakturaene dine. Kan være organisasjonsnummer eller personnummer.",
    },
    bankAccount: {
      title: "Bankkonto",
      description: "Sett bankkontoen for fakturaene dine",
    },
    dueDate: {
      title: "Dager til forfallsdato",
      description: "Sett hvor mange dager etter fakturadato forfallsdatoen skal settes",
    },
    tabs: {
      general: "Generelt",
      kid: "KID",
      dian: "DIAN",
    },
    dian: {
      title: "DIAN-innstillinger",
      description: "Konfigurer DIAN-innstillingene dine (Dirección de Impuestos y Aduanas Nacionales)",
      comingSoon: "DIAN-integrasjon kommer snart",
    },
  },
  invoiceList: {
    invoiceNumber: "Fakturanr.",
    orderNumber: "Ordrenr.",
    amount: "Beløp (NOK)",
    customer: "Kunde",
    invoiceDate: "Fakturadato",
    dueDate: "Forfallsdato",
    status: "Status",
    download: "Last ned",
    send: "Send",
    kid: "KID",
    statuses: {
      new: "Ny",
      sent: "Sendt",
      paid: "Betalt",
    },
    noInvoices: "Ingen fakturaer",
    loading: "Laster... Vennligst vent...",
    couldNotFetch: "Kunne ikke hente fakturaer",
    search: "Søk",
    all: "Alle",
  },
  InvoiceCreator: {
    title: "Opprett Faktura",
    editTitle: "Rediger Faktura",
    close: "Lukk",
    postalCodeRequired: "Postnummer er påkrevd",
    cityRequired: "By er påkrevd",
    addressRequired: "Adresse er påkrevd",
    amountRules: {
      required: "Beløp er påkrevd",
      positive: "Beløp må være positivt",
    },
    priceRules: {
      required: "Beløp er påkrevd",
      positive: "Beløp må være positivt",
    },
    discountRules: {
      maxValue: "Rabatten kan ikke være større enn prisen",
    },
    createInvoice: "Opprett Faktura",
    createAndSend: "Opprett og Send",
    cancel: "Avbryt",
    markAsPaidCheckbox: "Merk avtale som 'Faktura Opprettet'",
    missingOrgNumber: "Vennligst fyll ut organisasjonsnummer i Innstillinger.",
    missingBankAccount: "Vennligst fyll ut bankkonto i Innstillinger.",
    invoiceCreated: "Faktura opprettet!",
    couldNotCreate: "Kunne ikke opprette faktura",
    customer: {
      name: "Kundenavn",
      address: "Kundeadresse",
      postalCode: "Postnummer",
      city: "By",
      title: "Kunde",
    },
    invoice: {
      number: "Fakturanr.",
      date: "Fakturadato",
      dueDate: "Forfallsdato",
      description: "Beskrivelse",
      price: "Pris",
      amount: "Beløp",
      discount: "Rabatt",
      vat: "MVA (%)",
      totalAmount: "Totalt å betale (inkl. MVA)",
      paymentInfo: "Betalingsinformasjon",
      title: "Faktura",
      amountToPay: "Beløp å betale",
    },
    company: {
      orgNumber: "Org.nr.",
      phone: "Telefon",
      email: "E-post",
      bankAccount: "Bankkonto",
      missingBankAccount: "MANGLER BANKKONTO",
    },
    invoiceNumber: "Fakturanr.",
    invoiceDate: "Fakturadato",
    dueDate: "Forfallsdato",
    totalToPay: "Beløp å betale",
    bankAccount: "Bankkonto",
    today: "[I dag]",
    thirtyDaysFromNow: "[Om 30 dager]",
    organization: {
      number: "Org.nr.",
      missing: "Mangler organisasjonsnummer",
      phone: "Telefon",
      email: "E-post",
    },
  },
  form: {
    fields: {
      name: "Navn",
      email: "E-post",
      phone: "Telefon",
      address: "Adresse",
      city: "By",
      postalCode: "Postnummer",
      message: "Melding",
      password: "Passord",
      confirmPassword: "Bekreft Passord",
      birthdate: "Fødselsdato",
      ssn: "Personnummer",
      gender: "Kjønn",
      title: "Tittel",
      description: "Beskrivelse",
      price: "Pris",
      duration: "Varighet",
      color: "Farge",
      discount: "Rabatt",
      onlinePayment: "Nettbetaling",
    },
    descriptions: {
      name: "Skriv inn ditt fulle navn",
      email: "Skriv inn din e-postadresse",
      phone: "Skriv inn ditt telefonnummer",
      address: "Skriv inn din adresse",
      city: "Skriv inn din by",
      postalCode: "Skriv inn ditt postnummer",
      message: "Skriv inn din melding",
      password: "Skriv inn ditt passord",
      confirmPassword: "Bekreft ditt passord",
      birthdate: "Skriv inn din fødselsdato",
      ssn: "Skriv inn ditt personnummer",
      gender: "Velg ditt kjønn",
      title: "Skriv inn tittel",
      description: "Skriv inn beskrivelse",
      price: "Skriv inn pris",
      duration: "Skriv inn varighet",
      color: "Velg farge",
      discount: "Skriv inn rabatt",
      onlinePayment: "Konfigurer nettbetaling",
    },
  },
  entities: {
    account: "Konto",
    appointment: "Time",
    service: "Tjeneste",
    staff: "Ansatt",
    extraService: "Ekstra tjeneste",
    giftCard: "Gavekort",
    image: "Bilde",
    customer: "Kunde",
    accountRole: "Kontorolle",
    category: "Kategori",
    resource: "Ressurs",
    option: "Alternativ",
    notification: "Varsel",
    googleCalendar: "Google Kalender",
    event: "Hendelse",
    debugLog: "Feilsøkingslogg",
    serviceCategory: "Tjenestekategori",
    statistics: "Statistikk",
    subscription: "Abonnement",
    expense: "Utgifter",
    finance: "Finans",
    inventoryItem: "Varelager",
    order: "Ordre",
    orderItem: "Ordrevare",
    ledgerEntry: "Bokføring",
    oneTimeExpense: "Enkeltutgift",
    recurringExpense: "Gjentakende utgift",
    expenseCategory: "Utgiftkategori",
    stock: "Lager",
    orders: "Bestillinger",
    inventoryItemCategory: "Varelagerkategori",
    cashRegister: "Kasse",
  },
  actions: {
    create: "Opprett",
    edit: "Rediger",
    delete: "Slett",
    upload: "Last opp",
    addStock: "Legg til lager",
  },
  actionsPastTense: {
    create: "Opprettet",
    edit: "Redigert",
    delete: "Slettet",
    upload: "Lastet opp",
  },
  appointmentList: {
    createdByCustomer: "Opprettet av Klient",
    status: "Status",
    delete_appointments_message: "Er du sikker på at du vil slette de valgte avtalene?",
    delete_appointments_header: "Slett Avtaler",
    delete_appointments_confirmationMessage: "De valgte avtalene har blitt slettet vellykket",
  },
  selectStaffMember: "Velg Personal",
  deleteCustomerHeader: "Slett kunde",
  expenses: {
    date: "Dato",
    startDate: "Startdato",
    endDate: "Sluttdato",
  },
  expenseCategory: {
    rent: "Leie",
    utilities: "Forsyninger",
    inventory: "Inventar",
    commission: "Provisjon",
    salaries: "Lønn",
    other: "Annet",
  },
  expenseCategoryAsAction: {
    inventory: "Kjøp lager",
    salaries: "Betal lønn",
    rent: "Betal leie",
    utilities: "Kjøp forsyninger",
    commission: "Betal provisjon",
    other: "Annet",
  },
  genericEntityDialog: {
    deleteConfirmation: "Er du sikker på at du vil slette {entity}?",
    deleteHeader: "Slett {entity}",
    deleteSuccess: "{entity} slettet vellykket",
    itemCreated: "{entity} opprettet vellykket",
    itemUpdated: "{entity} oppdatert vellykket",
    itemDeleted: "{entity} slettet vellykket",
    confirmDelete: "Er du sikker på at du vil slette {entity}?",
  },
  orderStatus: {
    Completed: "Fullført",
    Pending: "Pågående",
    Cancelled: "Avbrutt",
  },
  orders: {
    createOrder: "Opprett Ordre",
    viewOrder: "Vis Ordre",
    totalAmount: "Totalbeløp",
    orderDate: "Ordredato",
    status: "Status",
    actions: "Handlinger",
    orderNumber: "Ordrenummer",
    title: "Ordrer",
    staffMember: "Ansatt",
    notSet: "Ikke Satt",
    cancel: "Avbryt",
    create: "Opprett",
    cartSummary: "Handlekurv Oppsummering",
    uncategorized: "Ukategorisert",
    quantity: "Antall",
    price: "Pris",
    validation: {
      selectCustomer: "Vennligst velg en kunde",
      addItems: "Vennligst legg til varer i ordren",
      amountGreaterThanZero: "Beløpet må være større enn 0",
      amountLessThanTotal: "Beløpet må være mindre enn totalen",
    },
    orderDetails: "Ordredetaljer",
    items: "Varer",
    item: "Vare",
    total: "Total",
    partialPayment: "Delbetaling",
    partialAmount: "Delbeløp",
    partialAmountHint: "Angi delbetalingsbeløp",
    anonymousCustomer: "Anonym Kunde",
    unknownCustomer: "Ukjent Kunde",
    noOrdersToday: "Ingen ordrer i dag",
    noPurchasesToday: "Ingen kjøp i dag",
    noProducts: {
      title: "Ingen Produkter",
      message: "Ingen produkter tilgjengelig",
      action: "Legg til Produkter",
    },
    searchProducts: "Søk etter produkter...",
    allProducts: "Alle Produkter",
    inStock: "på lager",
    outOfStock: "Utsolgt",
    shoppingCart: "Handlekurv",
    cartEmpty: "Handlekurven din er tom",
    addItemsToStart: "Legg til varer fra katalogen for å komme i gang",
    subtotal: "Delsum",
    steps: {
      customerAndStaff: {
        title: "Kunde & Ansatt",
        subtitle: "Velg kunde og ansatt",
      },
      items: {
        title: "Varer",
        subtitle: "Velg varer til ordren",
      },
      payment: {
        title: "Betaling",
        subtitle: "Velg betalingsmetode",
      },
    },
    sections: {
      customerInfo: "Kundeinformasjon",
      staffInfo: "Ansatt",
      orderDate: "Ordredato",
      orderDetails: "Ordredetaljer",
      financialDetails: "Finansielle Detaljer",
    },
    navigation: {
      next: "Neste",
      back: "Tilbake",
    },
    labels: {
      customer: "Kunde:",
      orderDate: "Ordredato:",
      totalAmount: "Totalbeløp:",
      partialPayment: "Delbetaling:",
      remainingBalance: "Gjenstående Beløp:",
      paymentStatus: "Betalingsstatus:",
    },
    paymentStatus: {
      unpaid: "Ubetalt",
      paidInFull: "Fullstendig Betalt",
    },
    maximumAmount: "Maksimalt beløp:",
  },
  paymentMethod: {
    Cash: "Kontant",
    Card: "Kort",
    Transfer: "Overføring",
    Other: "Annet",
    title: "Betalingsmetode",
  },
  ledgerEntryFormDTO: {
    total: "Total",
    paid: "Betalt",
    remaining: "Gjenstående",
  },
  ledgerEntry: {
    charge: "Belastning",
    payment: "Betaling",
    refund: "Refusjon",
    type: "Type",
    manual: "Manuell",
  },
  ledgerEntryType: {
    charge: "Belastning",
    payment: "Betaling",
    refund: "Refusjon",
    adjustment: "Justering",
  },
  ledgerEntryHeaders: {
    type: "Type",
    source: "Kilde",
  },
  staffMemberManagement: {
    servicesPerformed: "Tjenester utført",
    paymentsReceived: "Betalinger mottatt",
    productsSold: "Varer solgt",
  },
  reoccuringExpense: {
    recurrenceInterval: "Gjentagelsesintervall",
    intervals: {
      daily: "Daglig",
      monthly: "Månedlig",
      quarterly: "Kvartalsvis",
      yearly: "Årlig",
    },
  },
  export: {
    dailyFinancialReport: "Daglig Økonomirapport",
    downloadDailyReport: "Last Ned Dagsrapport",
  },
  itemTooltips: {
    clickForDetails: "Klikk for detaljer",
  },
  customerStatistics: {
    appointments: {
      thisMonth: "Avtaler denne måneden",
      total: "Totalt antall avtaler",
    },
    moneySpent: {
      title: "Penger brukt",
      thisMonth: "Penger brukt denne måneden",
      total: "Totalt penger brukt",
    },
    ranking: {
      title: "Kunderangering",
      thisYear: "Dette året",
      overall: "Totalt",
      ofCustomers: "av kunder",
    },
    products: {
      thisMonth: "Produkter denne måneden",
      total: "Totalt antall produkter",
    },
    charts: {
      mostUsedServices: "Mest brukte tjenester",
      mostPurchasedProducts: "Mest kjøpte produkter",
      spendingHistory: "Utgiftshistorikk",
    },
  },
  moneyAccountOverview: {
    totalBalance: "Total Saldo",
    totalIncomingToday: "Dagens Inntekter",
    totalOutgoingToday: "Dagens Utgifter",
    totalNetChangeToday: "Dagens Netto Endring",
    ofDailyTotal: "av daglig total",
    changeFromTotal: "endring fra total",
    transactionDistribution: "Transaksjonsfordeling",
    incoming: "Innkommende",
    outgoing: "Utgående",
    addMoney: "Legg til penger",
    addMoneyDescription: "Legg til penger manuelt i kassen",
    manualEntry: "Manuell registrering",
    amount: "Beløp",
    description: "Beskrivelse"
  },
  addStockFormDTO: {
    totalPrice: "Total Pris",
    unitPrice: "Enhets Pris",
  },
  appointmentView: {
    timeFormat: {
      am: "AM",
      pm: "PM"
    },
    customerDetails: "Kundeinformasjon",
    appointmentDetails: "Avtaleinformasjon",
    financialDetails: "Økonomiske detaljer"
  },
  commissionSettlement: {
    title: "Provisjonsoppgjør for ansatte",
    services: "Provisjon for tjenester",
    products: "Provisjon for produkter",
    total: "Total provisjon",
    payCommission: "Betal provisjon",
    paid: "Betalt",
    noEligibleStaff: "Ingen kvalifiserte ansatte med provisjon for denne datoen",
    unassignedAppointmentsWarning: "Det er avtaler uten tildelte ansatte. Vennligst tildel ansatte før du fortsetter:",
    assign: "Tildel",
    assignStaffTitle: "Tildel ansatt",
    selectStaff: "Velg ansatt",
    selectMoneyAccountError: "Vennligst velg en pengekonto før du betaler provisjon",
    commissionPayment: "Provisjonsbetaling",
    commissionPaidSuccess: "Provisjon betalt til",
    commissionPaymentError: "Feil ved betaling av provisjon. Vennligst prøv igjen.",
    staffAssignedSuccess: "Ansatt tildelt avtalen",
    staffAssignmentError: "Feil ved tildeling av ansatt. Vennligst prøv igjen."
  },
};

export default no;
