<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { IUserDTO } from "@shared/types";
import GeneralSettings from "./invoice-settings/GeneralSettings.vue";
import KidSettings from "./invoice-settings/KidSettings.vue";
import DianSettings from "./invoice-settings/DianSettings.vue";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "InvoiceSettings",
  components: {
    GeneralSettings,
    KidSettings,
    DianSettings,
  },
})
export default class InvoiceSettings extends Vue {
  public currentTab: number = 0;

  get user() {
    return userModule.user;
  }
}
</script>

<template>
  <div>
    <v-tabs v-model="currentTab" background-color="#7440fe" dark>
      <v-tab :key="0">{{ $t($ts.invoiceSettings.tabs.general) }}</v-tab>
      <v-tab disabled :key="1">{{ $t($ts.invoiceSettings.tabs.kid) }}</v-tab>
      <v-tab :key="2">{{ $t($ts.invoiceSettings.tabs.dian) }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <GeneralSettings :user="user" />
      </v-tab-item>

      <v-tab-item>
        <KidSettings :user="user" />
      </v-tab-item>

      <v-tab-item>
        <DianSettings />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;

.v-tabs {
  margin-bottom: 20px;
}
</style>
