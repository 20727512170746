<template>
  <v-card style="border-radius: 0; background: rgba(255, 255, 255, 0); overflow: hidden; height: inherit" flat>
    <div style="position: relative" v-if="eventParsed.input.createdByCustomer">
      <v-icon style="position: absolute; right: 0; top: 0" small color="white">star</v-icon>
    </div>

    <div
      style="position: relative"
      v-if="eventParsed.input.adminNote != null && eventParsed.input.adminNote.length > 0"
    >
      <v-icon style="position: absolute; right: 15px; top: 0" small color="white">note</v-icon>
    </div>

    <div style="position: relative" v-if="eventParsed.input.comment != null && eventParsed.input.comment.length > 0">
      <v-icon style="position: absolute; right: 30px; top: 0" small color="white">message</v-icon>
    </div>

    <v-row justify="start" class="pr-3">
      <v-col cols="auto">
        <div style="margin-left: 5px">
          <span style="color: white; font-weight: bold">
            {{ getFirstWordOfString(event.name) }}
          </span>
        </div>
      </v-col>

      <v-spacer></v-spacer>
    </v-row>

    <div v-if="user.showTimeInEvent">
      <span style="color: white; margin-left: 5px">{{
        formatTimeWithFormatForEvent(eventParsed.start) + "-" + formatTimeWithFormatForEvent(eventParsed.end)
      }}</span>
    </div>

    <div style="margin-left: 5px" v-if="user.showServiceNameInEvent && !eventParsed.input.isCustom">
      <span style="color: white">{{ eventParsed.input.appointmentName }}</span>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IUserDTO } from "@shared/types";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";

@Component({
  name: "EventCard",
})
export default class EventCard extends Vue {
  @Prop({ required: true }) event!: any;
  @Prop({ required: true }) eventParsed!: any;
  @Prop({ required: true }) user!: IUserDTO;

  getFirstWordOfString(value: string): string {
    return value.split(" ")[0];
  }

  formatTimeWithFormatForEvent(eventInfo: any): string {
    return formatTimeWithFormat(eventInfo.hour, eventInfo.minute, false, false);
  }
}
</script>

<style scoped>
.v-card {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* You can add scoped styles here if necessary */
</style>
