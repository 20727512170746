import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({});

//ctrl+shift+page-up or ctrl+shift-page-down to disable or enable cursor tab
//it's a fucking relief to have it gone, honesly.

// store.subscribe((mutation, state) => {
//   // Create a safe copy of the state (removing circular references)
//   const stateCopy = JSON.parse(
//     JSON.stringify({
//       module: mutation.type.split("/")[0],
//       mutation: mutation.type,
//       payload: mutation.payload,
//       // Only include relevant parts of state to avoid huge payloads
//       relevantState: state[mutation.type.split("/")[0]],
//     })
//   );

//   Sentry.addBreadcrumb({
//     category: "my-vuex",
//     message: `Mutation: ${mutation.type}`,
//     level: "info",
//     data: stateCopy,
//   });

//   // Optional: Track specific mutations more carefully
//   if (mutation.type.includes("auth/") || mutation.type.includes("critical/")) {
//     Sentry.setContext("store-change", stateCopy);
//   }
// });
