<template>
  <v-snackbar
    v-model="isShowingSnackbar"
    :timeout="snackbarDuration"
    :color="snackbarColor"
    elevation="6"
    rounded="pill"
    transition="bounce-transition"
    class="pa-0"
  >
    <v-card flat :color="snackbarColor" class="d-flex align-center pa-2" :class="textColorClass">
      <v-icon left class="mr-3" :class="[{ 'loading-icon': displayingSnackbar.isLoadingMessage }, textColorClass]">
        {{ snackbarIcon }}
      </v-icon>
      <span class="text-body-2">{{ displayingSnackbar.message }}</span>
      <v-spacer />
      <v-btn
        v-if="!displayingSnackbar.isLoadingMessage"
        icon
        x-small
        :class="textColorClass"
        @click="isShowingSnackbar = false"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { SnackbarObject } from "../../store/snackbar";
import Loader from "../global/Loader.vue";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";

@Component({
  name: "AppSnackbar",
  components: { Loader },
})
export default class AppSnackbar extends Vue {
  isShowingSnackbar = false;
  snackbarDuration = 4000;
  snackbarTimeout: number | undefined;

  get displayingSnackbar(): SnackbarObject {
    return this.snackbar;
  }

  get snackbar() {
    return snackbarModule.snackbar;
  }

  get snackbarColor(): string {
    switch (this.displayingSnackbar.color) {
      case "success":
        return "#4CAF50"; // Material Design Green
      case "error":
        return "#FF5252"; // Material Design Red
      case "info":
        return "#2196F3"; // Material Design Blue
      default:
        return "#424242"; // Material Design Dark Grey
    }
  }

  get textColorClass(): string {
    return "white--text";
  }

  get snackbarIcon(): string {
    if (this.displayingSnackbar.isLoadingMessage) {
      return "mdi-loading";
    }

    switch (this.displayingSnackbar.color) {
      case "success":
        return "mdi-check-circle";
      case "error":
        return "mdi-alert-circle";
      case "info":
        return "mdi-information";
      default:
        return "mdi-bell";
    }
  }

  @Watch("snackbar")
  onChangeSnackbar(newVal: SnackbarObject): void {
    if (!newVal.message) {
      this.isShowingSnackbar = false;
      return;
    }

    if (this.snackbarTimeout != null) {
      clearTimeout(this.snackbarTimeout);
    }

    this.isShowingSnackbar = true;

    if (!newVal.isLoadingMessage) {
      this.snackbarTimeout = setTimeout(() => {
        this.isShowingSnackbar = false;
      }, this.snackbarDuration);
    }
  }
}
</script>

<style lang="scss">
.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bounce-transition-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-transition-leave-active {
  animation: bounce-out 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.5) translateY(100%);
    opacity: 0;
  }
  50% {
    transform: scale(1.05) translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.05) translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.5) translateY(100%);
    opacity: 0;
  }
}
</style>
