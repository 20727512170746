<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "./MainHeader.vue";
import { Action, Getter } from "vuex-class";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import authConfig from "@/Utilities/authConfig";
import { IS_DEVELOPING } from "@/main";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";

@Component({
  name: "token-link",
  components: { CustomButton, Sidebar, HeaderBar },
})
export default class TokenLink extends Vue {
  public copyText: string = "";
  isDeveloping: boolean = IS_DEVELOPING;
  //Vuex

  public copyLink(): void {
    const copy: any = this.$refs.copy;
    copy.select();
    document.execCommand("copy");
    snackbarModule.postSnackbarMessage("Copied to clipboard.");
  }

  public created(): void {
    authConfig().then((x) => {
      this.copyText = x.headers.Authorization as string;
    });
  }

  get isProd() {
    return process.env.NODE_ENV === "production";
  }
}
</script>

<template>
  <v-card v-if="!isProd" class="appointment-link-widget">
    <p>Auth token</p>
    <input type="text" ref="copy" :value="copyText" />
    <v-card-actions>
      <custom-button :filled="true" @custom-button-click="copyLink" :buttonText="'Kopier'"></custom-button>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.appointment-link-widget {
  /*background: white;*/
  /*width: 100%;*/
  /*float: left;*/
  /*min-height: 150px;*/
  /*border-radius: 5px;*/
  /*padding: 25px;*/

  iframe {
    margin-top: 50px;
    box-shadow: 0px 0px 9px 1px #c5c5c5;
    border: none;
  }

  input[type="text"] {
    width: 100%;
    float: left;
    height: 45px;
    padding: 25px 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 4px 0px;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    -webkit-box-shadow: 0px 0px 1px 1px #2d2d2d2d;
    box-shadow: 0px 0px 1px 1px #2d2d2d2d;
    color: rgba(8, 46, 51, 0.34);
  }

  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    padding: 10px 25px;
  }

  .preview {
    margin-left: 10px;
  }
}
</style>
