export class ConfigService {
  private static instance: ConfigService;
  private _apiUrl: string;

  private constructor() {
    // Try to get API URL from environment variables, fallback to default if not set
    this._apiUrl =
      import.meta.env.VITE_API_URL ||
      (import.meta.env.MODE === "production"
        ? "https://timebestillerwebapp.azurewebsites.net/api/"
        : "https://citaroapi-dev.azurewebsites.net/api/");
  }

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  set apiUrl(value: string) {
    this._apiUrl = value;
  }
}

// "https://citaroapi-dev.azurewebsites.net/api/");
