<template>
  <v-container>
    <div>
      <v-row align="center" class="mb-4" justify="space-between">
        <v-col cols="auto">
          <v-card flat v-if="hasMultipleStaff">
            <v-card-text>
              <v-select
                v-model="selectedStaffMember"
                :items="staffMemberOptions"
                item-value="id"
                item-text="fullName"
                style="min-width: 200px"
                dense
                outlined
                hide-details
                :label="$t($ts.selectStaffMember)"
                class="header-select"
                @change="refreshStats"
              >
                <template v-slot:prepend>
                  <v-icon left>mdi-account-supervisor-circle</v-icon>
                </template>
              </v-select>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="auto">
          <v-card flat>
            <v-card-text class="d-flex">
              <v-select
                v-model="selectedYear"
                :items="yearOptions"
                style="min-width: 150px"
                dense
                outlined
                hide-details
                class="header-select mr-4"
                @change="refreshStats"
              >
                <template v-slot:prepend>
                  <v-icon left>mdi-calendar</v-icon>
                </template>
              </v-select>

              <v-select
                v-model="selectedMonth"
                :items="monthOptions"
                item-value="value"
                item-text="text"
                style="min-width: 150px"
                dense
                outlined
                hide-details
                class="header-select"
                @change="refreshStats"
              >
                <template v-slot:prepend>
                  <v-icon left>mdi-calendar-month</v-icon>
                </template>
              </v-select>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card flat>
        <v-tabs v-model="activeTab" background-color="#866afe" dark>
          <v-tab>{{ $t($ts.earningsTrend) }}</v-tab>
          <v-tab>{{ $t($ts.statistics.earnings) }}</v-tab>
          <v-tab>{{ $t($ts.servicesBooked) }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Overview Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- <v-row>
                  <v-col>
                    <v-skeleton-loader
                      v-if="loadingAppointments"
                      type="list-item-two-line"
                      height="100"
                      class="mx-auto"
                    ></v-skeleton-loader>
                    <monthly-earnings
                      v-else
                      :hasLoaded="true"
                      :marginLeft="1"
                      :verticalCardAmount="3"
                      :title="$t($ts.monthlyAppointmentsTitle, { month: currentMonthOrYear })"
                      :amount="appointmentsDone"
                      :type="''"
                      :icon="'img/stopwatch.png'"
                    ></monthly-earnings>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader
                      v-if="loadingMoneyMade"
                      type="list-item-two-line"
                      height="100"
                      class="mx-auto"
                    ></v-skeleton-loader>
                    <monthly-earnings
                      v-else
                      :hasLoaded="true"
                      :marginLeft="1"
                      :verticalCardAmount="3"
                      :title="$t($ts.monthlyEarningsTitle, { month: currentMonthOrYear })"
                      :amount="formattedToCurrencyMoneyMade"
                      :type="''"
                      :icon="'img/cash.png'"
                    ></monthly-earnings>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="12">
                    <v-card flat>
                      <!-- <v-card-title class="justify-center">
                        <h4 v-if="selectedStaffMember > 0">
                          {{ $t($ts.earningsTrend) }} - {{ selectedStaffMemberName }}
                        </h4>
                        <h4 v-else>{{ $t($ts.earningsTrend) }}</h4>
                      </v-card-title> -->
                      <v-card-text>
                        <v-skeleton-loader v-if="loadingChart" type="image" class="mx-auto"></v-skeleton-loader>
                        <template v-else>
                          <commit-chart
                            v-if="selectedMonth == 0"
                            :currentYear="earnedThisAndPreviousYear.current"
                            :previousYear="earnedThisAndPreviousYear.previous"
                            :year="selectedYear"
                          ></commit-chart>
                          <daily-commit-chart
                            v-if="selectedMonth > 0"
                            :data="splitByDay"
                            :year="selectedYear"
                            :month="selectedMonth"
                            :monthName="currentMonthName"
                          ></daily-commit-chart>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Details Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-radio-group v-model="displayMode" row dense class="mt-0 pt-0 mr-4">
                  <v-radio :label="$t($ts.statistics.earnings)" value="earnings"></v-radio>
                  <v-radio :label="$t($ts.statistics.appointments)" value="appointments"></v-radio>
                </v-radio-group>
                <v-switch
                  v-model="showPerStaffMember"
                  :label="$t($ts.statistics.showPerStaffMember)"
                  class="mt-0 pt-0"
                  dense
                  color="#866afe"
                ></v-switch>

                <!-- Monthly Data Table -->
                <v-data-table
                  v-show="selectedMonth == 0"
                  :headers="showPerStaffMember ? monthlyHeadersWithStaff : monthlyHeaders"
                  :items="showPerStaffMember ? getMonthlyTableDataWithStaff : getMonthlyTableData"
                  :loading="loadingChart"
                  hide-default-footer
                  class="mb-6 custom-table horizontal-scroll"
                  dense
                >
                  <template v-slot:loading>
                    <v-skeleton-loader type="table-row-divider@6" class="mx-auto"></v-skeleton-loader>
                  </template>
                </v-data-table>

                <!-- Daily Data Table -->
                <v-data-table
                  v-show="selectedMonth > 0"
                  :headers="
                    showPerStaffMember
                      ? dailyHeadersWithStaff
                      : displayMode === 'earnings'
                      ? [
                          { text: $t($ts.statistics.day), value: 'day' },
                          { text: $t($ts.statistics.earnings), value: 'earnings' },
                          { text: $t($ts.statistics.totalExpenses), value: 'expenses' },
                          { text: $t($ts.statistics.totalNetProfit), value: 'netProfit' },
                        ]
                      : [
                          { text: $t($ts.statistics.day), value: 'day' },
                          { text: $t($ts.statistics.appointments), value: 'amount' },
                        ]
                  "
                  :items="showPerStaffMember ? getDailyTableDataWithStaff : getDailyTableData"
                  :loading="loadingChart"
                  :items-per-page="-1"
                  hide-default-footer
                  class="horizontal-scroll"
                  dense
                >
                  <template v-slot:loading>
                    <v-skeleton-loader type="table-row-divider@6" class="mx-auto"></v-skeleton-loader>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Services Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="serviceStats || []"
                  :sort-by="['bookings']"
                  :sort-desc="[true]"
                  :loading="loadingServices"
                >
                  <template v-slot:loading>
                    <v-skeleton-loader type="table-row-divider@6" class="mx-auto"></v-skeleton-loader>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HeaderBar from "../../components/global/MainHeader.vue";
import MonthlyEarnings from "@/components/global/statistics/MonthlyEarned.vue";
import CommitChart from "../../components/global/CommitChart.vue";
import { Action, Getter } from "vuex-class";
import Calendar from "@/abstract-classes/Calendar";
import Loader from "@/components/global/Loader.vue";
import { castMonthFromNumberToStringName } from "@/types/formatDateHelper";
import ServiceStats from "@/components/global/ServiceStats.vue";
import { formatCurrency } from "@/Utilities/currencyHelper";
import { IStaffMemberDTO } from "@shared/types";
import DailyCommitChart from "@/components/global/DailyCommitChart.vue";
import { statisticsModule } from "@/store/modules/statistics/statisticsModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
@Component({
  name: "statistics",
  components: { ServiceStats, Loader, HeaderBar, MonthlyEarnings, CommitChart, DailyCommitChart },
})
export default class Statistics extends Calendar {
  displayMode: string = "earnings";
  activeTab: number = 0;
  showPerStaffMember: boolean = false;
  isLoading: boolean = false;
  currentMonth: string = "";
  selectedStaffMember: number = 0;
  selectedYear: number = new Date().getFullYear();
  selectedMonth: number = 0;
  loadingAppointments: boolean = false;
  loadingMoneyMade: boolean = false;
  loadingChart: boolean = false;
  loadingServices: boolean = false;

  private readonly STORAGE_KEY = "statistics_settings";

  get hasMultipleStaff() {
    return staffMemberModule.staffMembers.length > 1;
  }

  get splitByDay() {
    return statisticsModule.splitByDay;
  }

  get earnedThisAndPreviousYear() {
    return statisticsModule.earnedThisAndPreviousYear || { current: [], previous: [] };
  }

  get currentMonthOrYear() {
    return this.selectedMonth
      ? `${castMonthFromNumberToStringName(this.selectedMonth)} ${this.selectedYear}`
      : this.selectedYear;
  }

  headers: any[] = [];
  customerHeaders: any[] = [];

  get yearOptions() {
    const currentYear = new Date().getFullYear();
    return [
      currentYear - 5,
      currentYear - 4,
      currentYear - 3,
      currentYear - 2,
      currentYear - 1,
      currentYear,
      currentYear + 1,
    ];
  }

  get selectedStaffMemberName() {
    return staffMemberModule.staffMembers.find((x) => x.id === this.selectedStaffMember)?.fullName;
  }

  get monthOptions() {
    return [
      { value: 0, text: this.$t(this.$ts.statistics.allMonths) },
      { value: 1, text: this.$t(this.$ts.months.january) },
      { value: 2, text: this.$t(this.$ts.months.february) },
      { value: 3, text: this.$t(this.$ts.months.march) },
      { value: 4, text: this.$t(this.$ts.months.april) },
      { value: 5, text: this.$t(this.$ts.months.may) },
      { value: 6, text: this.$t(this.$ts.months.june) },
      { value: 7, text: this.$t(this.$ts.months.july) },
      { value: 8, text: this.$t(this.$ts.months.august) },
      { value: 9, text: this.$t(this.$ts.months.september) },
      { value: 10, text: this.$t(this.$ts.months.october) },
      { value: 11, text: this.$t(this.$ts.months.november) },
      { value: 12, text: this.$t(this.$ts.months.december) },
    ];
  }

  get monthlyHeaders() {
    if (this.displayMode === "earnings") {
      return [
        { text: this.$t(this.$ts.statistics.month), value: "month" },
        { text: this.$t(this.$ts.statistics.earnings), value: "earnings" },
        { text: this.$t(this.$ts.statistics.totalExpenses), value: "totalExpenses" },
        { text: this.$t(this.$ts.statistics.totalNetProfit), value: "netProfit" },
      ];
    } else {
      return [
        { text: this.$t(this.$ts.statistics.month), value: "month" },
        { text: this.$t(this.$ts.statistics.appointments), value: "appointments" },
      ];
    }
  }

  get monthlyHeadersWithStaff() {
    return [
      { text: this.$t(this.$ts.statistics.month), value: "month" },
      ...staffMemberModule.staffMembers.map((staff) => ({
        text: staff.fullName,
        value: `staff_${staff.id}`,
      })),
    ];
  }

  get getMonthlyTableData() {
    const months = [
      this.$t(this.$ts.months.january),
      this.$t(this.$ts.months.february),
      this.$t(this.$ts.months.march),
      this.$t(this.$ts.months.april),
      this.$t(this.$ts.months.may),
      this.$t(this.$ts.months.june),
      this.$t(this.$ts.months.july),
      this.$t(this.$ts.months.august),
      this.$t(this.$ts.months.september),
      this.$t(this.$ts.months.october),
      this.$t(this.$ts.months.november),
      this.$t(this.$ts.months.december),
    ];

    if (this.displayMode === "earnings") {
      if (this.yearlyProfit) {
        return months.map((month: string, index: number) => ({
          month,
          earnings: formatCurrency(this.yearlyProfit.monthlyEarnings[index] || 0),
          totalExpenses: formatCurrency(this.yearlyProfit.monthlyTotalExpenses[index] || 0),
          netProfit: formatCurrency(this.yearlyProfit.monthlyNetProfit[index] || 0),
        }));
      }
      return [];
    } else {
      return months.map((month: string, index: number) => ({
        month,
        appointments: statisticsModule.appointmentsByMonth?.current?.[index] || 0,
      }));
    }
  }

  get currentMonthName() {
    const months = [
      this.$t(this.$ts.months.january),
      this.$t(this.$ts.months.february),
      this.$t(this.$ts.months.march),
      this.$t(this.$ts.months.april),
      this.$t(this.$ts.months.may),
      this.$t(this.$ts.months.june),
      this.$t(this.$ts.months.july),
      this.$t(this.$ts.months.august),
      this.$t(this.$ts.months.september),
      this.$t(this.$ts.months.october),
      this.$t(this.$ts.months.november),
      this.$t(this.$ts.months.december),
    ];

    if (this.selectedMonth == 0) {
      return this.$t(this.$ts.statistics.allMonths);
    } else {
      return months[this.selectedMonth - 1];
    }
  }

  get staffMemberOptions() {
    return calendarModule.staffMemberOptions;
  }

  get getMonthlyTableDataWithStaff() {
    if (this.displayMode === "earnings") {
      if (!statisticsModule.staffEarnings) return [];

      const months = [
        this.$t(this.$ts.months.january),
        this.$t(this.$ts.months.february),
        this.$t(this.$ts.months.march),
        this.$t(this.$ts.months.april),
        this.$t(this.$ts.months.may),
        this.$t(this.$ts.months.june),
        this.$t(this.$ts.months.july),
        this.$t(this.$ts.months.august),
        this.$t(this.$ts.months.september),
        this.$t(this.$ts.months.october),
        this.$t(this.$ts.months.november),
        this.$t(this.$ts.months.december),
      ];

      return months.map((month: string, index: number) => {
        const rowData: any = { month };
        statisticsModule.staffEarnings.forEach((staff) => {
          const amount = staff.earnings[index];
          rowData[`staff_${staff.staffId}`] =
            amount != null
              ? this.$t(this.$ts.currency) + " " + amount.toLocaleString()
              : this.$t(this.$ts.currency) + " 0";
        });
        return rowData;
      });
    } else {
      if (!statisticsModule.staffAppointments) return [];

      const months = [
        this.$t(this.$ts.months.january),
        this.$t(this.$ts.months.february),
        this.$t(this.$ts.months.march),
        this.$t(this.$ts.months.april),
        this.$t(this.$ts.months.may),
        this.$t(this.$ts.months.june),
        this.$t(this.$ts.months.july),
        this.$t(this.$ts.months.august),
        this.$t(this.$ts.months.september),
        this.$t(this.$ts.months.october),
        this.$t(this.$ts.months.november),
        this.$t(this.$ts.months.december),
      ];

      return months.map((month: string, index: number) => {
        const rowData: any = { month };
        statisticsModule.staffAppointments.forEach((staff) => {
          rowData[`staff_${staff.staffId}`] = staff.appointments[index] || 0;
        });
        return rowData;
      });
    }
  }

  get getDailyTableData() {
    if (!this.splitByDay) return [];

    if (this.displayMode === "earnings") {
      const daysInMonth = this.splitByDay.earnings.length;
      return Array.from({ length: daysInMonth }, (_, index) => {
        const day = index + 1;
        return {
          day: `${String(day).padStart(2, "0")}.${String(this.selectedMonth).padStart(2, "0")}.${String(
            this.selectedYear
          ).slice(-2)}`,
          earnings:
            this.splitByDay.earnings[index] != null
              ? this.$t(this.$ts.currency) + " " + this.splitByDay.earnings[index].toLocaleString()
              : this.$t(this.$ts.currency) + " 0",
          expenses:
            this.splitByDay.totalExpenses[index] != null
              ? this.$t(this.$ts.currency) + " " + this.splitByDay.totalExpenses[index].toLocaleString()
              : this.$t(this.$ts.currency) + " 0",
          netProfit:
            this.splitByDay.netProfit[index] != null
              ? this.$t(this.$ts.currency) + " " + this.splitByDay.netProfit[index].toLocaleString()
              : this.$t(this.$ts.currency) + " 0",
        };
      });
    } else {
      // For appointments mode
      return (
        statisticsModule.appointmentsByDay?.map((count: number, index: number) => ({
          day: `${String(index + 1).padStart(2, "0")}.${String(this.selectedMonth).padStart(2, "0")}.${String(
            this.selectedYear
          ).slice(-2)}`,
          amount: count || 0,
        })) || []
      );
    }
  }

  get getDailyTableDataWithStaff() {
    if (this.displayMode === "earnings") {
      if (!statisticsModule.dailyStaffEarnings) return [];

      const daysInMonth = this.splitByDay?.earnings.length || 31;
      const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

      return days.map((day: number, index: number) => {
        const rowData: any = {
          day: `${String(day).padStart(2, "0")}.${String(this.selectedMonth).padStart(2, "0")}.${String(
            this.selectedYear
          ).slice(-2)}`,
        };
        statisticsModule.dailyStaffEarnings.forEach((staff) => {
          const amount = staff.earnings[index];
          rowData[`staff_${staff.staffId}`] =
            amount != null
              ? this.$t(this.$ts.currency) + " " + amount.toLocaleString()
              : this.$t(this.$ts.currency) + " 0";
        });
        return rowData;
      });
    } else {
      if (!statisticsModule.dailyStaffAppointments) return [];

      const daysInMonth = this.splitByDay?.earnings.length || 31;
      const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

      return days.map((day: number, index: number) => {
        const rowData: any = {
          day: `${String(day).padStart(2, "0")}.${String(this.selectedMonth).padStart(2, "0")}.${String(
            this.selectedYear
          ).slice(-2)}`,
        };
        statisticsModule.dailyStaffAppointments.forEach((staff) => {
          rowData[`staff_${staff.staffId}`] = staff.appointments[index] || 0;
        });
        return rowData;
      });
    }
  }

  get dailyHeadersWithStaff() {
    return [
      { text: this.$t(this.$ts.statistics.day), value: "day" },
      ...staffMemberModule.staffMembers.map((staff) => ({
        text: staff.fullName,
        value: `staff_${staff.id}`,
      })),
    ];
  }

  get formattedMonthYear() {
    return this.selectedMonth
      ? `${castMonthFromNumberToStringName(this.selectedMonth)} ${this.selectedYear}`
      : String(this.selectedYear);
  }

  get columnTitle() {
    return `${
      this.displayMode === "earnings"
        ? this.$t(this.$ts.statistics.earnings)
        : this.$t(this.$ts.statistics.appointments)
    } ${this.formattedMonthYear}`;
  }

  private saveSettings() {
    const settings = {
      selectedStaffMember: this.selectedStaffMember,
      selectedYear: this.selectedYear,
      selectedMonth: this.selectedMonth,
      activeTab: this.activeTab,
      displayMode: this.displayMode,
      showPerStaffMember: this.showPerStaffMember,
    };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(settings));
  }

  private loadSettings() {
    const savedSettings = localStorage.getItem(this.STORAGE_KEY);
    if (savedSettings) {
      const settings = JSON.parse(savedSettings);
      this.selectedStaffMember = settings.selectedStaffMember;
      this.selectedYear = settings.selectedYear;
      this.selectedMonth = settings.selectedMonth;
      this.activeTab = settings.activeTab;
      this.displayMode = settings.displayMode;
      this.showPerStaffMember = settings.showPerStaffMember;
    }
  }

  @Watch("selectedStaffMember")
  onStaffMemberChange() {
    this.saveSettings();
  }

  @Watch("selectedYear")
  onYearChange() {
    this.saveSettings();
  }

  @Watch("selectedMonth")
  onMonthChange() {
    this.saveSettings();
  }

  @Watch("activeTab")
  onActiveTabChange(newVal: number) {
    this.saveSettings();
    // Refresh data when switching back to overview tab
    if (newVal === 0) {
      //this.refreshStats();
    }
  }

  @Watch("showPerStaffMember")
  onShowPerStaffMemberChange(newVal: boolean) {
    this.saveSettings();
    this.refreshStats();
  }

  @Watch("displayMode")
  onDisplayModeChange() {
    this.saveSettings();
    this.refreshStats();
  }

  get appointmentsDone() {
    return statisticsModule.appointmentsDone;
  }

  get serviceStats() {
    return statisticsModule.serviceStats;
  }

  get yearlyEarningsComparison() {
    return statisticsModule.earnedThisAndPreviousYear; //TODO: I guess? the preivous was yearlyEarningsComparison, but it didnt exist
  }

  get formattedToCurrencyMoneyMade() {
    return this.$t(this.$ts.currency) + " " + formatCurrency(statisticsModule.moneyMade);
  }

  get customerAmount() {
    return customersModule.customerAmount;
  }

  async created(): Promise<void> {
    this.headers = [
      { text: this.$t(this.$ts.statistics.serviceName), value: "serviceName" },
      { text: this.$t(this.$ts.statistics.bookings), value: "bookings" },
    ];

    try {
      // First get staff members
      await staffMemberModule.getStaffMembers();

      // Load saved settings
      this.loadSettings();

      // If there are multiple staff members and no saved selection, set the first one as selected
      if (this.hasMultipleStaff && staffMemberModule.staffMembers.length > 0 && !this.selectedStaffMember) {
        this.selectedStaffMember = 0;
      } else if (!this.selectedStaffMember) {
        this.selectedStaffMember = 0;
      }

      // Initial load with loading states
      await this.refreshStats();
    } catch (error) {
      console.error("Error loading initial stats:", error);
    }
  }

  async refreshStats() {
    const staffMemberId = this.selectedStaffMember || 0;
    const month = this.selectedMonth;
    const year = this.selectedYear;

    try {
      // Start loading states
      this.loadingServices = true;
      this.loadingMoneyMade = true;
      this.loadingAppointments = true;
      this.loadingChart = true;

      // Execute all requests in parallel
      const promises: Promise<any>[] = [
        statisticsModule.getServicesBookedStats({ staffMemberId, year, month }),
        statisticsModule.getMoneyMadeForMonth({ month: month || month, staffMemberId, year }),
        statisticsModule.getNumberOfAppointments({ month: month || month, staffMemberId, year }),
      ];

      if (this.showPerStaffMember) {
        if (month > 0) {
          promises.push(statisticsModule.getDailyEarnedByStaff({ year, month }));
          promises.push(statisticsModule.getDailyAppointmentsByStaff({ year, month }));
        } else {
          promises.push(statisticsModule.getEarnedByStaff({ year }));
          promises.push(statisticsModule.getAppointmentsByStaff({ year }));
        }
      } else {
        // Add appointments data fetching for non-staff-member view
        if (month > 0) {
          promises.push(statisticsModule.getAppointmentsByDay({ staffMemberId, year, month }));
          promises.push(statisticsModule.getMonthlyProfit({ year, month, staffMemberId }));
        } else {
          promises.push(statisticsModule.getAppointmentsByMonth({ staffMemberId, year }));
          promises.push(statisticsModule.getYearlyProfit({ year, staffMemberId }));
          promises.push(statisticsModule.getAllYearProfit({ staffMemberId }));
        }
      }

      await Promise.all(promises);

      // Handle chart data separately
      if (month > 0) {
        await statisticsModule.getSplitByDay({ staffMemberId, year, month });
      } else {
        await statisticsModule.getEarnedThisAndPreviousYear({ staffMemberId, year });
      }

      // Set all loading states to false after all promises resolve
      this.loadingServices = false;
      this.loadingMoneyMade = false;
      this.loadingAppointments = false;
      this.loadingChart = false;

      // Update current month display
      this.currentMonth = month
        ? castMonthFromNumberToStringName(month)
        : String(this.$t(this.$ts.statistics.allMonths));
    } catch (error) {
      console.error("Error refreshing stats:", error);
    }
  }

  get yearlyProfit() {
    return statisticsModule.yearlyProfit;
  }

  get allYearProfit() {
    return statisticsModule.allYearProfit;
  }
}
</script>
<style lang="scss" scoped>
.content {
  .trend-wrapper {
    margin: 15px 0%;
    width: 98%;
    height: auto;
    background: #fff;
    border-radius: 7px;
    float: left;
    svg {
      height: auto;
    }
    h2 {
      text-align: center;
      margin: 20px 9px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 1264px) {
  .trend-wrapper {
    margin: 6px 1% !important;
  }
}

.header-select {
  font-size: 14px;
}

.header-select ::v-deep .v-input__slot {
  min-height: 36px !important;
  margin-bottom: 0 !important;
}

.v-card {
  .v-card__text {
    padding: 12px 16px;
  }
}

.v-skeleton-loader {
  border-radius: 7px;

  &.mx-auto {
    margin: 0 auto;
  }
}

::v-deep .custom-table {
  .v-data-table-header {
    th {
      background-color: #866afe !important;
      color: white !important;
      font-weight: 600;
    }
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #866afe !important;
  color: white !important;
  font-weight: 600;
}

::v-deep .v-radio .v-icon {
  color: #866afe !important;
}

::v-deep .v-radio--is-checked .v-icon {
  color: #866afe !important;
}

::v-deep .v-tabs .v-tabs-slider {
  background: #4a35b5 !important;
}

.horizontal-scroll {
  :deep(.v-data-table__wrapper) {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 500px;

    table {
      min-width: 100%;
      table-layout: auto;
    }

    th,
    td {
      white-space: nowrap;
      min-width: 120px;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background: white;
    }

    thead tr th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #866afe;
    }

    th:first-child {
      z-index: 2;
      background: #866afe;
    }
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #866afe #f0f0f0;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #866afe, #4a35b5);
  border-radius: 4px;
  border: none;
  transition: background 0.2s ease;
}

*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #4a35b5, #866afe);
}

*::-webkit-scrollbar-corner {
  background: #f0f0f0;
}
</style>
