<template>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <CompanyInfoField :label="$t($ts.dtos.recurringExpenseFormDTO.name.name)">
          <Textfield v-model="editedItem.name" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.dtos.recurringExpenseFormDTO.amount.name)">
          <Textfield v-model="editedItem.amount" :number="true" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.entities.category)">
          <EnumField v-model="editedItem.category" :items="categories" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.reoccuringExpense.recurrenceInterval)">
          <EnumField v-model="editedItem.recurrenceInterval" :items="recurrenceIntervals" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.dtos.recurringExpenseFormDTO.startDate.name)">
          <DatePickerField v-model="editedItem.startDate" hide-details />
        </CompanyInfoField>
        <v-divider class="my-4" />

        <CompanyInfoField :label="$t($ts.dtos.recurringExpenseFormDTO.endDate.name)">
          <DatePickerField v-model="editedItem.endDate" hide-details />
        </CompanyInfoField>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#866afe" outlined @click="close">
          {{ $t($ts.buttons.cancel) }}
        </v-btn>
        <v-btn dark color="#866afe" @click="save"> {{ $t($ts.buttons.save) }} <v-icon>save</v-icon> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IPostRecurringExpenseDTO, ExpenseCategory, RecurrenceInterval } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import DatePickerField from "@/components/global/DatePickerField.vue";
import Textfield from "@/components/global/Textfield.vue";
import EnumField from "@/components/global/EnumField.vue";

@Component({
  components: {
    CompanyInfoField,
    DatePickerField,
    Textfield,
    EnumField,
  },
})
export default class RecurringExpenseDialog extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Object, required: false }) expense?: IPostRecurringExpenseDTO;
  @Prop({ type: String, required: true }) title!: string;

  private startDateMenu = false;
  private endDateMenu = false;
  private editedItem: IPostRecurringExpenseDTO = {
    name: "",
    amount: 0,
    category: ExpenseCategory.Other,
    recurrenceInterval: RecurrenceInterval.Monthly,
    startDate: new Date(),
    endDate: null,
    sourceId: 0,
  };

  private categories: { text: string; value: ExpenseCategory }[] = [];
  private recurrenceIntervals: { text: string; value: RecurrenceInterval }[] = [];

  created() {
    this.categories = [
      { text: this.$t(this.$ts.expenseCategory.rent).toString(), value: ExpenseCategory.Rent },
      { text: this.$t(this.$ts.expenseCategory.utilities).toString(), value: ExpenseCategory.Utilities },
      { text: this.$t(this.$ts.expenseCategory.inventory).toString(), value: ExpenseCategory.Inventory },
      { text: this.$t(this.$ts.expenseCategory.other).toString(), value: ExpenseCategory.Other },
    ];

    this.recurrenceIntervals = [
      { text: this.$t(this.$ts.reoccuringExpense.intervals.daily).toString(), value: RecurrenceInterval.Daily },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.monthly).toString(), value: RecurrenceInterval.Monthly },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.quarterly).toString(), value: RecurrenceInterval.Quarterly },
      { text: this.$t(this.$ts.reoccuringExpense.intervals.yearly).toString(), value: RecurrenceInterval.Yearly },
    ];
  }

  get dialogVisible(): boolean {
    return this.visible;
  }

  set dialogVisible(value: boolean) {
    this.$emit("update:visible", value);
  }

  @Watch("expense", { immediate: true })
  onExpenseChange(newValue?: IPostRecurringExpenseDTO) {
    if (newValue) {
      this.editedItem = { ...newValue };
    } else {
      this.resetForm();
    }
  }

  private resetForm() {
    this.editedItem = {
      name: "",
      amount: 0,
      category: ExpenseCategory.Other,
      recurrenceInterval: RecurrenceInterval.Monthly,
      startDate: new Date(),
      endDate: null,
      sourceId: 0,
    };
  }

  private close() {
    this.dialogVisible = false;
    this.resetForm();
  }

  private save() {
    this.$emit("save", this.editedItem);
    this.close();
  }
}
</script>
