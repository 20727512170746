createa
<script lang="ts">
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import DatePicker from "@/components/global/DatePicker.vue";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { allHoursDividedByQuartersWithinOpeningTimes, isValidDate } from "@/Utilities/dateUtility";
import { IDialog } from "@/store/dialog";
import { DialogType } from "@/store/modules/dialogModule/dialogModule";
import { CalendarInterval } from "@/store/workingHours";
import { IAppointmentTypeDTO, IPostAppointmentDTO, IStaffMemberDTO, IUserDTO } from "@shared/types";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { ICustomerDTO } from "@shared/types";
import ServiceSelection from "@/components/appointments/ServiceSelection.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import CustomerSelect from "@/components/global/CustomerSelect.vue";
import CustomerModal from "./CustomerModal.vue";
import PaymentHandler from "./PaymentHandler.vue";

import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { userModule } from "@/store/modules/user/userModule";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { ISimpleDate } from "@/store/modules/appointments/appointmentTypings";
import SnackbarMixin from "@/mixins/snackbarMixin";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";

// Add interface for payment result
interface IPaymentResult {
  paymentStatus: string;
  partialPaymentAmount: number | null;
  moneyAccountId: number | null;
}

// Add interface extending IUserDTO
interface IExtendedUserDTO extends IUserDTO {
  moneyAccounts?: { id: number; name: string }[];
}

@Component({
  name: "create-appointment",
  components: {
    CustomButton,
    DatePicker,
    ServiceSelection,
    CompanyInfoField,
    CustomerSelect,
    PaymentHandler,
  },
})
export default class CreateAppointment extends Mixins(SnackbarMixin) {
  //Fields
  public searchQuery: string = "";
  public showCustomerList: boolean = false;
  public currentCustomerChoice: string = "";
  public selectedServiceId: number = 0;
  public selectedServiceIds: number[] = [];
  public selectedCustomerId: number = 0;
  public hasValidatedCustomer: boolean = false;
  public newAppointment: IPostAppointmentDTO;

  @Prop({}) defaultSelectedServiceIds: number[];
  @Prop({}) defaultSelectedCustomerId: number;
  @Prop({}) defaultOverrideCustomerName: string;
  @Prop({}) defaultOverrideDuration: number;
  @Prop({}) defaultCustomerCreateEmail: string;
  @Prop({}) defaultCustomerCreatePhone: string;
  @Prop({}) defaultOverridePrice: number;
  @Prop({}) defaultOverrideStaffMember: number;

  @Prop({}) open: boolean;

  @Prop({}) shoulScroll: boolean;
  @Prop({ default: false }) edit: boolean;
  @Prop({ default: 0 }) editingAppointmentId: number;

  public canSubmitNewAppointment = true;
  public menu = false;
  public serviceOptions = [];
  private newCustomerDefaultName: string = "";

  private overrideDuration: number = -1;
  private overridePrice: number = -1;
  private overrideCustomerName: string = "";
  private overrideStaffMember: number = -1;

  public allHoursValid: boolean = false;
  public appointmentTypeItems = [];

  public fromTimeToSelect: string = null;
  public eventDescription = "";

  public workingHourRule = [
    (v) => {
      let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return (v) => (v && pattern.test(v)) || this.$t(this.$ts.invalidTimeFormat).toString();
    },
  ];
  private failedLastEndTimeValidation: boolean = false;
  private failedLastStartTimeValidation: boolean = false;

  private sendSmsReminder: boolean = false;
  private sendEmailReminder: boolean = false;

  public saving: boolean = false;
  private paymentHandlerRef: any = null;

  @Watch("open")
  openHandler(newValue) {
    console.warn("Opening...");

    if (newValue == false) {
      //The dialog has closed the dialog for adding appointment - reset values
      this.overrideDuration = -1; //Duration
      this.overridePrice = -1; //Duration
      this.selectedCustomerId = 0; //Customer
      this.selectedServiceId = 0; //Service
      this.selectedServiceIds = []; //Service
      this.overrideStaffMember = -1; //Staff member

      appointmentsModule.selectHourToBook(null);
      appointmentsModule.selectDateToBook(null);

      this.overrideCustomerName = "";

      let toForm: any = this.$refs.toform;
      let fromForm: any = this.$refs.fromform;
      if (toForm != null) toForm.resetValidation();
      if (fromForm != null) fromForm.resetValidation();
    } else {
      this.fillInDefaultsFromProps();
    }
  }

  get useMultipleServices() {
    return userModule.user.multipleServices;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  get customers() {
    return customersModule.customers;
  }

  get moneyAccounts() {
    return moneyAccountModule.moneyAccounts;
  }

  public created(): void {
    this.serviceOptions = this.getServiceOptions();
    this.insertCustomEventServiceInServiceList();

    let toForm: any = this.$refs.toform;
    let fromForm: any = this.$refs.fromform;
    if (toForm != null) toForm.resetValidation();
    if (fromForm != null) fromForm.resetValidation();
  }
  async mounted() {
    if (customersModule.defaultCustomerToBook != null && customersModule.defaultCustomerToBook.id !== 0) {
      this.selectCustomerForAppointment(customersModule.defaultCustomerToBook.id);
    }

    if (appointmentsModule.forceDefaultEndTime == "") {
      this.fillInDefaultsFromProps();
    } else {
      this.fromTimeToSelect = this.selectHour;
    }

    this.onForceDefaultEndTimeChange("");
    
    // Initialize payment handler ref
    this.$nextTick(() => {
      this.paymentHandlerRef = this.$refs.paymentHandler;
    });
  }

  get forceDefaultEndTime() {
    return appointmentsModule.forceDefaultEndTime;
  }

  @Watch("forceDefaultEndTime")
  onForceDefaultEndTimeChange(newValue) {
    console.log("Force default end time: ", appointmentsModule.forceDefaultEndTime);
    if (appointmentsModule.forceDefaultEndTime != "") {
      this.selectNewHourEndInstead(appointmentsModule.forceDefaultEndTime);
      ///appointmentsModule.forceDefaultEndTime = "";
      this.selectedServiceId = -1; //It's a custom event by default we assume since we define the end time
    }
  }

  setDefaultCustomerToBook(customer: ICustomerDTO | null) {
    customersModule.defaultCustomerToBook = customer;
  }

  destroyed() {
    this.setDefaultCustomerToBook(null);
    this.selectCustomerForAppointment(null);
  }

  get selectHour() {
    return appointmentsModule.selectHour;
  }

  //Methods
  public filterCustomers(): void {}

  get hasSelectedService() {
    return this.selectedServiceId !== 0 || this.selectedServiceIds.length > 0;
  }

  validateSelectedCustomerFromCurrentCustomers(): boolean {
    let selectHourSplit = this.selectHour.split(":");
    let hour: number = parseInt(selectHourSplit[0]) as number;
    let min: number = parseInt(selectHourSplit[1]) as number;

    if (isNaN(hour) || isNaN(min) || this.selectHour.length !== 5) {
      this.postSnackbarMessage(this.$t(this.$ts.invalidTimeFormat).toString());
      return false;
    }
    if (this.selectedServiceId != -1) {
      if (!this.hasSelectedService) {
        this.postSnackbarMessage(this.$t(this.$ts.selectService).toString());
        return false;
      }
    }

    return true;
  }

  sum(total, num) {
    return total + num;
  }

  get originalServicePrice() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return "";
    }
    let prices = services.map((x) => x.price);

    return prices.reduce(this.sum);
  }

  get selectedServicePrice(): number {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return -1;
    }
    if (this.overridePrice != -1) {
      return this.overridePrice;
    } else {
      let prices = services.map((x) => {
        if (x != null) {
          if (x.discountPrice === -1 || x.discountPrice == null) {
            return x.price;
          } else {
            return x.discountPrice;
          }
        }
      });

      return prices.reduce(this.sum);
    }
  }

  get selectedServiceDuration() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return -1;
    }

    let durations = services.map((x) => (x ? x.durationInMinutes : 0));

    let totalDuration = durations.reduce(this.sum);
    return totalDuration;
  }

  get selectHourEndTime() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0 || this.selectHour == null || this.selectHour === "") {
      return "";
    }

    let dur = this.selectedServiceDuration;

    // Only use override duration if it's explicitly set
    if (this.overrideDuration !== -1) {
      dur = this.overrideDuration;
    }

    // If no duration is set (shouldn't happen with valid services), return empty
    if (dur === -1) {
      return "";
    }

    let selectHourSplit = this.selectHour.split(":");
    let hour: number = parseInt(selectHourSplit[0]);
    let minute: number = parseInt(selectHourSplit[1]);

    // Create date object for start time
    const startDate = new Date();
    startDate.setHours(hour, minute, 0);

    // Add duration in minutes
    const endDate = new Date(startDate.getTime() + dur * 60000);

    // Format the end time
    let endHour: string = endDate.getHours().toString();
    if (endDate.getHours() < 10) endHour = "0" + endHour;

    let endMinute: string = endDate.getMinutes().toString();
    if (endDate.getMinutes() < 10) endMinute = "0" + endMinute;

    return endHour + ":" + endMinute;
  }

  onCustomerNameChange(event) {
    this.overrideCustomerName = event;
  }

  addToDialogQueue(dialog: IDialog) {
    dialogModule.addToDialogQueue(dialog);
  }

  onCustomerChange(event) {
    let select: any = this.$refs.customeraccountselect;
    select.blur();
    if (event != null && event != 0 && event != -1) {
      this.overrideCustomerName = "";
    } else {
      this.selectedCustomerId = 0;
      this.overrideCustomerName = "";
    }
  }

  get selectedCustomerName() {
    if (this.overrideCustomerName != "") {
      return this.overrideCustomerName;
    }
    if (this.selectedCustomerId != null) {
      let customer = this.customers.find((x) => x.id == this.selectedCustomerId);
      if (customer == null) {
        return "";
      } else {
        return customer.firstName;
      }
    }
  }

  hasNotSelectedService() {
    return this.selectedServiceId != 0 && this.selectedServiceIds.length == 0;
  }

  getSelectedServices(): IAppointmentTypeDTO[] {
    if (typeof this.appointmentType.find != "function") {
      return null;
    }

    let array = [];

    if (this.hasNotSelectedService()) {
      let service: IAppointmentTypeDTO = this.appointmentTypeItems.find((x) => x.id == this.selectedServiceId);
      if (service == null) {
        return [];
      }
      array.push(service);
      return array;
    } else {
      let services: IAppointmentTypeDTO[] = this.selectedServiceIds.map((id) => {
        return this.appointmentTypeItems.find((x) => x.id == id);
      });

      return services;
    }
  }

  public selectCustomerForAppointment(customer: number): void {
    if (customer != null && customer !== 0) {
      // this.searchQuery = customer.firstName;
      // this.currentCustomerChoice = customer.firstName;
      this.selectedCustomerId = customer;
    } else {
      this.searchQuery = null;
      this.currentCustomerChoice = null;
      this.selectedCustomerId = 0;
    }

    this.showCustomerList = false;
  }

  get selectedDateISO() {
    let chars = "2020-06-07T07:30:00";

    if (this.selectedDate == null) {
      return "";
    }

    // Create a Date object from the ISimpleDate
    // Fix: Use UTC date to avoid timezone issues
    const date = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day));
    console.log("selectedDateISO getter - date object:", date);
    console.log("selectedDateISO getter - ISO string:", date.toISOString().substr(0, chars.length));
    return date.toISOString().substr(0, chars.length);
  }

  set selectedDateISO(value) {
    if (value == "") {
      return;
    }
    console.log("selectedDateISO setter - input value:", value);
    
    // Fix: Parse the date string properly to handle timezone issues
    let date = new Date(value);
    console.log("selectedDateISO setter - parsed date:", date);
    
    if (isValidDate(date)) {
      // Convert Date to ISimpleDate
      const newSelectedDate = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
      console.log("selectedDateISO setter - new selectedDate:", newSelectedDate);
      this.selectedDate = newSelectedDate;
    } else {
      console.warn("Selected not valid date: ", date);
    }
  }

  get selectedDate() {
    return appointmentsModule.selectedDate;
  }

  set selectedDate(value) {
    if (value == null) {
      return;
    }
    appointmentsModule.selectDateToBook(value);
  }

  get prettySelectedDate() {
    if (this.selectedDate == null) {
      return this.$t(this.$ts.selectDate).toString();
    }
    
    // Create a date object with the selected date
    // Use UTC to avoid timezone issues
    const date = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day));
    
    // Log for debugging
    console.log("prettySelectedDate - selectedDate:", this.selectedDate);
    console.log("prettySelectedDate - date object:", date);
    console.log("prettySelectedDate - formatted:", formatDate(date, FormatSpaceType.DOT));
    
    return formatDate(date, FormatSpaceType.DOT);
  }

  public async setDateToBook(): Promise<void> {
    let hour: number = parseInt(this.selectHour.substr(0, 2)) as number;
    let minute: number = parseInt(this.selectHour.substr(3, 2)) as number;
    
    // Get the date values from selectedDate
    let day: number = this.selectedDate.day;
    let month: number = this.selectedDate.month + 1; // Convert from 0-indexed to 1-indexed for API
    let year: number = this.selectedDate.year;
    
    // Log for debugging
    console.log("setDateToBook - selectedDate:", this.selectedDate);
    console.log("setDateToBook - day:", day, "month:", month, "year:", year);

    let vm = this;

    if (isNaN(this.overrideDuration)) {
      let dialog: IDialog = {
        text: this.$t(vm.$ts.createAppointmentMessage.invalidTimeFormat).toString(),
      };
      this.addToDialogQueue(dialog);
      return;
    }

    let serviceIds = []; // this.selectedServiceId;
    if (this.selectedServiceId != 0 && this.selectedServiceId != null) {
      serviceIds.push(this.selectedServiceId);
    } else {
      serviceIds = this.selectedServiceIds;
    }

    // Get payment information if payment handler exists and is valid
    let paymentStatus = "new";
    let partialPaymentAmount = null;
    let moneyAccountId = null;
    
    if (this.paymentHandlerRef && this.paymentHandlerRef.isValid) {
      const paymentInfo = await this.paymentHandlerRef.getPaymentInfo();
      if (paymentInfo) {
        paymentStatus = paymentInfo.paymentStatus;
        partialPaymentAmount = paymentInfo.partialPaymentAmount;
        moneyAccountId = paymentInfo.moneyAccountId;
      }
    }

    let newAppointment: IPostAppointmentDTO = {
      customerId: this.selectedCustomerId,
      customerName: this.overrideCustomerName,
      serviceIds: serviceIds,
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute,
      duration: this.overrideDuration,
      price: this.overridePrice,
      smsReminder: this.sendSmsReminder,
      emailReminder: this.sendEmailReminder,
      whatsappReminder: false,
      comment: "",
      paid: false,
      status: "new",
      number: 0,
      id: 0,
      description: this.eventDescription,
      staffMemberId: this.overrideStaffMember,
      paymentStatus: paymentStatus,
      partialPaymentAmount: partialPaymentAmount,
      moneyAccountId: moneyAccountId,
    };

    this.newAppointment = newAppointment;

    if (serviceIds.length == 0) {
      this.postSnackbarMessage(this.$t(this.$ts.createAppointmentMessage.selectService).toString());
      this.hasValidatedCustomer = false;
      return;
    }
  }

  public async bookNewAppointment(): Promise<void> {
    this.saving = true;
    appointmentsModule.forceDefaultEndTime = "";

    await this.setDateToBook(); //Setting this.appointment
    if (this.validateSelectedCustomerFromCurrentCustomers()) {
      if (
        this.newAppointment.hour < 0 ||
        this.newAppointment.hour > 23 ||
        this.newAppointment.minute < 0 ||
        this.newAppointment.minute > 59
      ) {
        let dialog: IDialog = {
          text: this.$t(this.$ts.createAppointmentMessage.invalidTimeFormat).toString(),
        };

        this.addToDialogQueue(dialog);
        return;
      }
      try {
        // Check for overlapping appointments first
        const isOverlapping = await appointmentsModule.checkAppointmentOverlap(this.newAppointment);

        if (isOverlapping) {
          // Show confirmation dialog
          const dialog: IDialog = {
            header: this.$t(this.$ts.createAppointmentMessage.overlappingAppointment.title).toString(),
            text: this.$t(this.$ts.createAppointmentMessage.overlappingAppointment.message).toString(),
            type: DialogType.Choice,
            action: async () => {
              try {
                this.newAppointment.forceCreate = true;
                // Process payment if payment handler exists and is valid
                // if (this.paymentHandlerRef && this.paymentHandlerRef.isValid) {
                //   await this.paymentHandlerRef.processPayment();
                // }
                await appointmentsModule.bookAppointment(this.newAppointment);
                this.$emit("added");
              } catch (e) {
                console.error("Error booking appointment:", e);
                let errorDialog: IDialog = {
                  text: this.$t(this.$ts.createAppointmentMessage.appointmentCreationFailed).toString(),
                };
                this.addToDialogQueue(errorDialog);
              } finally {
                this.saving = false;
              }
            },
            cancelAction: () => {
              this.saving = false;
            },
          };
          this.addToDialogQueue(dialog);
          return;
        }

        // Process payment if payment handler exists and is valid
        if (this.paymentHandlerRef && this.paymentHandlerRef.isValid) {
          const paymentResult = await this.paymentHandlerRef.processPayment();
          console.log("Payment result:", paymentResult);

          this.newAppointment.paymentStatus = paymentResult.paymentStatus;
          this.newAppointment.partialPaymentAmount = paymentResult.partialPaymentAmount;
          this.newAppointment.moneyAccountId = paymentResult.moneyAccountId;
        }

        await appointmentsModule.bookAppointment(this.newAppointment);
        this.$emit("added");
      } catch (e) {
        console.error("Error booking appointment:", e);
        let dialog: IDialog = {
          text: this.$t(this.$ts.createAppointmentMessage.appointmentCreationFailed).toString(),
        };
        this.addToDialogQueue(dialog);
      } finally {
        this.saving = false;
      }
    }
  }

  public async editAppointment(): Promise<void> {
    this.saving = true;
    this.setDateToBook(); //Setting this.appointment

    let update = {
      ...this.newAppointment,
      adminNote: "", // Add required adminNote field for IEditAppointmentDTO
    };
    update.id = this.editingAppointmentId;

    await appointmentsModule.updateAppointment(update);

    this.$emit("added");
    this.$emit("confirm");
    this.$emit("close");

    appointmentsModule.forceDefaultEndTime = "";

    this.saving = false;
  }

  public toggleNewCustomerForm(): void {
    // This method is no longer needed since customer creation is handled by CustomerSelect
  }

  //Computed
  public get filteredCustomers() {
    if (this.customers != null) {
      return this.customers.filter((customer) => {
        if (customer.firstName != null) {
          return customer.firstName.toUpperCase().match(this.searchQuery.toUpperCase());
        }
      });
    }
    return null;
  }

  insertCustomEventServiceInServiceList() {
    if (Array.isArray(this.appointmentType)) {
      //Do npt have custom event as an option in edit
      let allServices = JSON.parse(JSON.stringify(this.appointmentType));

      if (!this.edit) {
        let busyService: Partial<IAppointmentTypeDTO> = {
          title: this.$t(this.$ts.customEvent).toString(),
          id: -1,
          price: 0,
          durationInMinutes: 30,
        };
        allServices.unshift(busyService);
      }

      this.appointmentTypeItems = allServices;
    }
  }

  fillInDefaultsFromProps() {
    this.canSubmitNewAppointment = true;
    console.warn("Filling fromTimeToSelect from props: " + this.selectHour);
    this.fromTimeToSelect = this.selectHour;
    this.sendEmailReminder = false;
    this.sendSmsReminder = false;

    if (this.defaultSelectedServiceIds != null) {
      if (this.defaultSelectedServiceIds.length == 1 && this.user.multipleServices == false) {
        this.selectedServiceId = this.defaultSelectedServiceIds[0];
      } else {
        this.selectedServiceIds = this.defaultSelectedServiceIds;
      }
    }
    if (this.defaultSelectedCustomerId != null) {
      this.selectedCustomerId = this.defaultSelectedCustomerId;
      //FOX
    }
    if (this.defaultOverrideDuration != null) {
      this.overrideDuration = this.defaultOverrideDuration;
    }
    if (this.defaultOverridePrice != null) {
      this.overridePrice = this.defaultOverridePrice;
    }
    if (this.defaultOverrideStaffMember != null) {
      this.overrideStaffMember = this.defaultOverrideStaffMember;
    }

    if (
      this.defaultOverrideCustomerName != null &&
      this.defaultOverrideCustomerName != "" &&
      (this.defaultSelectedCustomerId == null ||
        this.defaultSelectedCustomerId == 0 ||
        this.defaultSelectedCustomerId == -1)
    ) {
      this.overrideCustomerName = this.defaultOverrideCustomerName;
    }
  }

  //Lifecycles

  get bookingSettingsIntervals() {
    return userModule.bookingIntervals;
  }

  // @Watch("appointmentType")
  // onValueChanged() {
  //   console.log("Appointment type watch triggered.");
  //   this.serviceOptions = this.getServiceOptions();
  //   this.insertCustomEventServiceInServiceList();
  // }

  get hoursToChoose() {
    // console.log("hoursToChoose", this.selectedDate);
    if (this.selectedDate == null) {
      return [];
    }

    // console.log("hoursToChoose", this.selectedDate);

    // const date: Date = this.getDateOfSelectedDate();
    // const weekday = date.getDay();
    // console.log("hoursToChoose", weekday);

    // let interval: any = null;

    // console.log("hoursToChoose", this.bookingSettingsIntervals);
    // console.log("staffMembers", this.staffMembers);
    // if (this.staffMembers.length === 1) {
    //   interval = this.bookingSettingsIntervals[0];
    // } else {
    //   interval = this.bookingSettingsIntervals.find((x) => {
    //     return x.staffMemberId === this.overrideStaffMember;
    //   });
    // }

    // console.log("hoursToChoose", interval);

    // if (interval == null) interval = this.bookingSettingsIntervals[0];

    // let workingHoursForThisDay: CalendarInterval = interval.intervals[weekday];

    // return allHoursDividedByQuartersWithinOpeningTimes(
    //   workingHoursForThisDay.openHour,
    //   workingHoursForThisDay.openMinute,
    //   workingHoursForThisDay.closeHour,
    //   workingHoursForThisDay.closeMinute,
    //   true
    // );

    return allHoursDividedByQuartersWithinOpeningTimes(0, 0, 24, 0, true); //meh lets do just all of them cus they dont show up when i do very late ones or early ones
  }

  private getDateOfSelectedDate(): Date {
    // Use UTC to avoid timezone issues
    const date = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day, 1, 0));
    console.log("getDateOfSelectedDate - input:", this.selectedDate, "output:", date);
    return date;
  }
  
  private getDateOfSelectedDateAndSelectedHour(): Date {
    let selectHourSplit = this.selectHour.split(":");
    //@ts-ignore
    let hour: number = parseInt(selectHourSplit[0]) as number;
    //@ts-ignore
    let min: number = parseInt(selectHourSplit[1]) as number;

    // Use UTC to avoid timezone issues
    const date = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day, hour, min));
    console.log("getDateOfSelectedDateAndSelectedHour - input:", this.selectedDate, hour, min, "output:", date);
    return date;
  }

  get hoursToChooseForEnd() {
    if (this.selectHour == null || this.selectHour === "") {
      return [];
    }

    const date = this.getDateOfSelectedDateAndSelectedHour();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const weekday = date.getDay();

    if (this.selectedServiceId == -1) {
      return allHoursDividedByQuartersWithinOpeningTimes(hours, minutes, 24, 0);
    }

    let interval: any = null;

    if (this.staffMembers.length === 1) {
      interval = this.bookingSettingsIntervals[0];
    } else {
      interval = this.bookingSettingsIntervals.find((x) => {
        return x.staffMemberId === this.overrideStaffMember;
      });
    }

    if (interval == null) interval = this.bookingSettingsIntervals[0];

    let workingHoursForThisDay: CalendarInterval = interval.intervals[weekday];

    return allHoursDividedByQuartersWithinOpeningTimes(
      hours,
      minutes,
      workingHoursForThisDay.closeHour,
      workingHoursForThisDay.closeMinute,
      true
    );
  }

  selectNewHourInstead(event) {
    let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    let isValid = pattern.test(event);
    if (isValid) {
    }

    this.fromTimeToSelect = event;
    if (isValid) {
      console.warn("selectNewHourInstead: " + this.fromTimeToSelect);

      appointmentsModule.selectHourToBook(this.fromTimeToSelect);
      this.failedLastStartTimeValidation = false;
    } else {
      this.failedLastStartTimeValidation = true;
    }
  }

  changeOverridePrice(event) {
    this.overridePrice = parseInt(event);
  }

  selectNewHourEndInstead(event: string) {
    console.log("Event received:", event);
    let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    let isValid = pattern.test(event) || event === "24:00"; //Cheap hack
    console.log("Is valid time format:", isValid);
    if (!isValid) {
      this.failedLastEndTimeValidation = true;
      console.log("Failed last end time validation:", this.failedLastEndTimeValidation);
      return;
    }
    this.failedLastEndTimeValidation = false;
    console.log("Failed last end time validation:", this.failedLastEndTimeValidation);

    if (this.selectHour == null) {
      console.warn(this.$t(this.$ts.editEndTimeWithoutStart).toString());
      return;
    }

    let selectHourSplit = this.selectHour.split(":");
    console.log("Select hour split:", selectHourSplit);
    //@ts-ignore
    let selectedHour: number = selectHourSplit[0] as number;
    //@ts-ignore
    let selectedMin: number = selectHourSplit[1] as number;
    console.log("Selected hour:", selectedHour, "Selected minute:", selectedMin);

    let selectedStartDate = new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      selectedHour,
      selectedMin
    );
    console.log("Selected start date:", selectedStartDate);

    let newEndSplit = event.split(":");
    console.log("New end split:", newEndSplit);
    //@ts-ignore
    let newEndHour: number = newEndSplit[0] as number;
    //@ts-ignore
    let newEndMinute: number = newEndSplit[1] as number;
    console.log("New end hour:", newEndHour, "New end minute:", newEndMinute);

    let newEndDate = new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      newEndHour,
      newEndMinute
    );
    console.log("New end date:", newEndDate);

    //@ts-ignore
    this.overrideDuration = (newEndDate - selectedStartDate) / (1000 * 60); //Difference in minutes
    console.log("Override duration (minutes):", this.overrideDuration);
  }

  getServiceOptions() {
    console.log("services: " + this.appointmentType);
    return this.appointmentType.map((x) => {
      return `${x.title} | ${x.durationInMinutes}${this.$t(this.$ts.minutesAbbreviation)} | ${x.price} ${this.$t(
        this.$ts.currency
      )}`;
    });
  }

  get isValidPrice() {
    return !isNaN(this.selectedServicePrice) && this.selectedServicePrice != -1;
  }

  cancelCreating() {
    this.saving = false;
    appointmentsModule.forceDefaultEndTime = "";
    this.$emit("close");
  }

  get user(): IExtendedUserDTO {
    return userModule.user;
  }

  get appointmentType() {
    return serviceModule.appointmentTypes;
  }

  customerFilter(item, queryText) {
    const textOne = item.firstName.toLowerCase();

    this.newCustomerDefaultName = queryText;

    return textOne.includes(queryText.toLowerCase());
  }

  selectServicesFilter(item, queryText) {
    const textOne = item.title.toLowerCase();
    return textOne.includes(queryText.toLowerCase());
  }
  selectHourFilter(item, queryText) {
    const textOne = item.toLowerCase();

    return textOne.includes(queryText.toLowerCase());
  }
  onCreatedCustomer(customerId: number) {
    this.overrideCustomerName = "";
    this.selectCustomerForAppointment(customerId);
  }

  formatTimeForDisplay(time: string): string {
    // You can implement your time formatting logic here based on user settings
    // Example: Convert "14:30" to "2:30 PM" for 12-hour format
    if (!time || time.length === 0) return "";

    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours);

    // Example condition - replace with your actual user settings check
    const use24Hour = this.user.timeFormat === "24h";

    if (use24Hour) {
      return time; // Keep 24-hour format
    } else {
      const period = hour >= 12 ? "PM" : "AM";
      const displayHour = hour % 12 || 12;
      return `${displayHour}:${minutes} ${period}`;
    }
  }

  async onChangeStaffMember(e) {
    await userModule.getBookingIntervalSettings(e);
  }
  onServiceChange() {
    this.overrideDuration = -1;
    this.overridePrice = -1;
  }

  get hasSelectedValidCustomerAccount() {
    //@ts-ignore
    return !(
      this.selectedCustomerId == null ||
      //@ts-ignore
      this.selectedCustomerId == "" ||
      this.selectedCustomerId === 0 ||
      this.selectedCustomerId === -1
    );
  }
  get disableConfirm() {
    let validPrice = this.isValidPrice;
    let invalidTime =
      this.failedLastStartTimeValidation ||
      this.failedLastEndTimeValidation ||
      this.selectHour == null ||
      this.selectHour === "";
    let hasSelectedService = this.hasSelectedService;

    if (this.selectedServiceId == -1) {
      //Custom event
      return invalidTime;
    } else {
      //Service
      return invalidTime || !validPrice || !hasSelectedService;
    }
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }

  saveDatePicker() {
    console.log("Selected date before save:", this.selectedDateISO);
    console.log("Current selectedDate:", this.selectedDate);
    
    // Fix: Ensure we're using the correct date from the date picker
    const selectedDate = new Date(this.selectedDateISO);
    
    // Update the selectedDate with the correct values
    this.selectedDate = {
      day: selectedDate.getDate(),
      month: selectedDate.getMonth(), // JavaScript months are 0-indexed (0-11)
      year: selectedDate.getFullYear()
    };
    
    console.log("Updated selectedDate:", this.selectedDate);
    
    //@ts-ignore
    this.$refs.menu.save(this.selectedDateISO);
  }

  onPaymentProcessed(result: IPaymentResult) {
    // Handle successful payment processing
    console.log("Payment processed:", result);
  }

  onPaymentError(error: any) {
    // Handle payment error
    console.error("Payment error:", error);
  }

  get someOfServicesHaveCustomPrice() {

    if(this.useMultipleServices){
      let servicesChosen = this.selectedServiceIds;
    let services = this.appointmentType;

      return servicesChosen.some((x) => services.find((y) => y.id === x)?.eligibleForCustomPrice);
    } else {
      return this.selectedServiceId !== -1 && this.appointmentType.find((x) => x.id === this.selectedServiceId)
        ?.eligibleForCustomPrice;
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column" style="height: 100%">
    <v-card flat class="d-flex flex-column flex-grow-1">
      <v-card-title v-if="!edit">
        {{ $t($ts.appointment.createAppointment) }}
      </v-card-title>
      <v-card-title v-if="edit">
        {{ $t($ts.appointment.editAppointment) }}
      </v-card-title>
      <!-- Main content -->
      <v-card-text class="flex-grow-1">
        <v-col style="padding: 0px !important" px-0>
          <!-- Staff Member Selection -->
          <CompanyInfoField :ignore-mobile-layout="true" class="mb-2" v-if="hasMultipleStaff">
            <v-select
              prepend-icon="mdi-account-supervisor-circle"
              :items="staffMembers"
              @change="onChangeStaffMember"
              v-model="overrideStaffMember"
              item-text="fullName"
              item-value="id"
              outlined
              dense
              class="format-box"
            >
              <template v-slot:label> <span style="color: red; font-size: 24px"></span>{{ $t($ts.staff) }} </template>
            </v-select>
          </CompanyInfoField>

          <!-- Service Selection -->
          <service-selection
            class="mb-2"
            :use-multiple-services="useMultipleServices"
            :selected-service-id.sync="selectedServiceId"
            :selected-service-ids.sync="selectedServiceIds"
            :appointment-type-items="appointmentTypeItems"
            :user="user"
            @service-change="onServiceChange"
          />

          <!-- Price Field -->
          <CompanyInfoField
            :ignore-mobile-layout="true"
            class="mb-2"
            v-if="selectedServiceId !== -1 && user.showServicePrice && someOfServicesHaveCustomPrice"
          >
            <v-text-field
              type="number"
              :disabled="!hasSelectedService"
              prepend-icon="mdi-cash"
              @change="changeOverridePrice"
              outlined
              dense
              class="format-box"
              :value="selectedServicePrice"
            >
              <template v-slot:label> <span style="color: red; font-size: 24px">*</span>{{ $t($ts.price) }} </template>
            </v-text-field>
          </CompanyInfoField>

          <!-- Customer Selection -->
          <CompanyInfoField :ignore-mobile-layout="true" class="mb-2" v-if="selectedServiceId !== -1">
            <CustomerSelect
              v-model="selectedCustomerId"
              :defaultEmail="defaultCustomerCreateEmail"
              :defaultPhone="defaultCustomerCreatePhone"
            />
          </CompanyInfoField>

          <!-- Event Description -->
          <CompanyInfoField :ignore-mobile-layout="true" class="mb-2" v-if="selectedServiceId === -1">
            <v-text-field
              v-model="eventDescription"
              :placeholder="$t($ts.pause)"
              outlined
              dense
              class="format-box"
              prepend-icon="sticky_note_2"
            >
              <template v-slot:label>
                {{ $t($ts.event) }}
              </template>
            </v-text-field>
          </CompanyInfoField>

          <!-- Date Picker -->
          <CompanyInfoField :ignore-mobile-layout="true" class="mb-2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="selectedDateISO"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="prettySelectedDate"
                  prepend-icon="event"
                  readonly
                  outlined
                  dense
                  class="format-box"
                  v-on="on"
                >
                  <template v-slot:label>
                    <span style="color: red; font-size: 24px">*</span>{{ $t($ts.date) }}
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="saveDatePicker" v-model="selectedDateISO" no-title scrollable> </v-date-picker>
            </v-menu>
          </CompanyInfoField>

          <!-- Time Selection -->
          <CompanyInfoField :ignoreMobileLayout="true" class="mb-2">
            <v-row style="padding: 0px !important" justify="space-between" align="center">
              <v-col cols="6" xs="6" sm="5" md="5" style="padding-right: 0px !important">
                <v-form ref="fromform" lazy-validation>
                  <v-select
                    prepend-icon="mdi-clock-outline"
                    :items="hoursToChoose"
                    :value="fromTimeToSelect"
                    @change="selectNewHourInstead"
                    @update:search-input="selectNewHourInstead"
                    :filter="selectHourFilter"
                    :disabled="!hasSelectedService"
                    menu-props="auto"
                    outlined
                    dense
                    hide-details
                    class="format-box time-input"
                    :label="$t($ts.from)"
                  >
                    <template v-slot:selection="{ item }">
                      {{ formatTimeForDisplay(item) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ formatTimeForDisplay(item) }}
                    </template>
                  </v-select>
                </v-form>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.xsOnly ? false : true"
                cols="1"
                xs="1"
                sm="1"
                md="1"
                class="d-flex justify-center align-center pa-0"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-col>

              <v-col cols="6" xs="6" sm="5" md="5" style="padding-left: 0px !important">
                <v-form lazy-validation ref="toform">
                  <v-combobox
                    :items="hoursToChooseForEnd"
                    @change="selectNewHourEndInstead"
                    :value="selectHourEndTime"
                    :filter="selectHourFilter"
                    :disabled="selectHour == null"
                    menu-props="auto"
                    outlined
                    dense
                    hide-details
                    class="format-box time-input"
                    :label="$t($ts.to)"
                  >
                    <template v-slot:selection="{ item }">
                      {{ formatTimeForDisplay(item) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ formatTimeForDisplay(item) }}
                    </template>
                  </v-combobox>
                </v-form>
              </v-col>
            </v-row>
          </CompanyInfoField>

          <!-- Payment Handler -->
          <payment-handler
            v-if="moneyAccounts.length > 1"
            ref="paymentHandler"
            :total-amount="selectedServicePrice"
            :customer-id="selectedCustomerId"
            :source-type="1"
            :createLedgerEntries="false"
            :order-date="selectedDateISO"
            @payment-processed="onPaymentProcessed"
            @payment-error="onPaymentError"
          />

          <v-alert
            v-if="overrideDuration !== -1 && selectedServiceDuration !== overrideDuration && selectedServiceId !== -1"
            dense
            border="left"
            type="warning"
            icon="mdi-alert"
          >
            <!-- Replace hardcoded text with localization key -->
            {{ $t($ts.durationMismatch) }}
          </v-alert>

          <v-alert
            v-if="overridePrice !== -1 && originalServicePrice !== overridePrice"
            dense
            border="left"
            type="warning"
            icon="mdi-alert"
          >
            <!-- Replace hardcoded text with localization key -->
            {{ $t($ts.priceMismatch) }}
          </v-alert>
        </v-col>
      </v-card-text>

      <!-- Footer buttons -->
      <v-card-actions class="mb-5">
        <v-spacer></v-spacer>
        <v-btn color="#866afe" outlined @click="cancelCreating">
          {{ $t($ts.cancel) }}
        </v-btn>

        <v-btn v-if="!edit && !disableConfirm" color="#866afe" dark :loading="saving" @click="bookNewAppointment">
          {{ $t($ts.create) }} <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          v-if="!edit && disableConfirm"
          color="rgba(134, 106, 254, 0.5)"
          :loading="saving"
          style="cursor: not-allowed"
          disabled
        >
          {{ $t($ts.create) }} <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn dark v-if="edit" :disabled="disableConfirm" color="#866afe" :loading="saving" @click="editAppointment">
          {{ $t($ts.confirm) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.time-input {
  margin-top: 0;
  margin-bottom: 0;
}

/* Control the overall input height */
.time-input :deep(.v-input__control) {
  min-height: 40px !important;
  max-height: 40px !important;
}

/* Control the field wrapper */
.time-input :deep(.v-field) {
  min-height: 40px !important;
  max-height: 40px !important;
}

/* Control the input area */
.time-input :deep(.v-field__input) {
  min-height: 40px !important;
  max-height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Control the field content */
.time-input :deep(.v-field__field) {
  min-height: 40px !important;
  max-height: 40px !important;
}

/* Control the outline wrapper */
.time-input :deep(.v-field__outline) {
  min-height: 40px !important;
  max-height: 40px !important;
}

/* Prepend icon alignment */
.time-input :deep(.v-input__prepend-outer) {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/* Target the specific filter input */
.time-input :deep(.v-field__input > input) {
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 0 !important;
  padding-left: 48px !important;
}

/* Ensure consistent icon sizing and positioning */
.time-input :deep(.v-input__prepend) {
  margin-right: 8px !important;
}

.time-input :deep(.v-icon) {
  font-size: 20px !important;
}

.time-input :deep(.v-select__selections) {
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  gap: 8px !important;
}

/* Target both the direct text content and the input */
.time-input :deep(.v-select__selections > *) {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 40px !important;
  height: 40px !important;
}

/* Specifically target the readonly input */
.time-input :deep(.v-select__selections input) {
  position: absolute !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 40px !important;
}

/* Ensure the text content is properly aligned */
.time-input :deep(.v-select__selections > :first-child:not(input)) {
  padding-left: 12px !important;
}

/* Adjust the label position */
.time-input :deep(.v-label) {
  top: 8px !important;
  transform: none !important;
}

/* Adjust the label when it's active/floating */
.time-input :deep(.v-label--active) {
  top: -5px !important;
  transform: translateY(0) scale(0.75) !important;
}

/* Ensure consistent field height */
.time-input :deep(.v-input__slot) {
  min-height: 40px !important;
  max-height: 40px !important;
}
</style>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule
