import { VuexModule, Module, Action } from "vuex-class-modules";
import store from "@/store/store";
@Module({ generateMutationSetters: true })
class LayoutModule extends VuexModule {
  // State
  mini: boolean = true;
  mobileNavigation: boolean = false;
  hideMobileFooter: boolean = false;

  @Action
  async setMini(value: boolean) {
    this.mini = value;
  }

  @Action
  async setMobileNavigation(value: boolean) {
    this.mobileNavigation = value;
  }

  @Action
  async setHideMobileFooter(value: boolean) {
    this.hideMobileFooter = value;
  }
}

export const layoutModule = new LayoutModule({ store, name: "layoutModule" });
