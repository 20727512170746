<script lang="ts">
import { Component, Vue, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import CustomInput from "@/components/global/styledComponents/CustomInput.vue";
import { getBookingUrl } from "@/Utilities/urlUtility";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import ImageUpload from "@/components/global/ImageUpload.vue";
import SnackbarMixin from "@/mixins/snackbarMixin";
import { IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Component({
  name: "company-info-card",
  components: {
    CustomInput,
    CustomButton,
    CompanyInfoField,
    Textfield,
    ImageUpload,
  },
  mixins: [SnackbarMixin],
})
export default class CompanyInfoCard extends Mixins(SnackbarMixin) {
  uploadedFiles: Array<File> = [];
  uploadError: number | null = null;
  currentStatus: number | null = null;
  files: File | null = null;
  isShowingUploadSection: boolean = false;
  hasFile: boolean = false;
  isLoading: boolean = false;
  isEditingCompanyInfo: boolean = false;
  currentAdminInfo: Partial<IUserDTO> = {
    emailAddress: "",
    treatmentAdress: "",
    phoneNumber: "",
    companyName: "",
    bankAccount: "",
    organizationNumber: "",
  };

  async saveField(field: string, value: string | number): Promise<void> {
    await userModule.updateUser({ [field]: value });
  }

  setCurrentUser(): void {
    this.currentAdminInfo.emailAddress = userModule.user.emailAddress;
    this.currentAdminInfo.treatmentAdress = userModule.user.treatmentAdress;
    this.currentAdminInfo.phoneNumber = userModule.user.phoneNumber;
    this.currentAdminInfo.companyName = userModule.user.companyName;
  }

  mounted() {
    this.setCurrentUser();
  }

  async onImageSelected(file: File): Promise<void> {
    this.isLoading = true;
    let fb = new FormData();
    fb.append("file", file);
    try {
      await userModule.updateUserImage(fb);
      this.postSnackbarMessage("Image uploaded successfully");
    } catch (error) {
      this.postSnackbarMessage("Failed to upload image");
    } finally {
      this.isLoading = false;
      this.isShowingUploadSection = false;
    }
  }

  get userProfilePicture(): string | undefined {
    return userModule.user.profilePictureUrl;
  }

  get userLink() {
    return getBookingUrl(userModule.user.bookingLink);
  }
}
</script>

<template>
  <div>
    <v-container>
      <!-- Company Name -->
      <CompanyInfoField
        :label="$t($ts.companyInfo.companyName)"
        :description="$t($ts.companyInfo.companyNameDescription)"
      >
        <Textfield
          v-model="currentAdminInfo.companyName"
          @blur="saveField('companyName', currentAdminInfo.companyName)"
        />
      </CompanyInfoField>

      <v-divider />

      <!-- Address -->
      <CompanyInfoField
        :label="$t($ts.companyInfo.companyAddress)"
        :description="$t($ts.companyInfo.companyAddressDescription)"
      >
        <Textfield
          v-model="currentAdminInfo.treatmentAdress"
          @blur="saveField('treatmentAdress', currentAdminInfo.treatmentAdress)"
        />
      </CompanyInfoField>

      <v-divider />

      <!-- Phone Number -->
      <CompanyInfoField
        :label="$t($ts.companyInfo.phoneNumber)"
        :description="$t($ts.companyInfo.phoneNumberDescription)"
      >
        <Textfield
          v-model="currentAdminInfo.phoneNumber"
          @blur="saveField('phoneNumber', currentAdminInfo.phoneNumber)"
        />
      </CompanyInfoField>

      <v-divider />

      <!-- Email -->
      <CompanyInfoField :label="$t($ts.companyInfo.email)" :description="$t($ts.companyInfo.emailDescription)">
        <Textfield
          v-model="currentAdminInfo.emailAddress"
          @blur="saveField('emailAddress', currentAdminInfo.emailAddress)"
        />
      </CompanyInfoField>

      <v-divider />
      <!-- Company Logo -->
      <CompanyInfoField
        :label="$t($ts.companyInfo.companyLogo)"
        :description="$t($ts.companyInfo.companyLogoDescription)"
      >
        <ImageUpload
          :current-image-url="userProfilePicture"
          :size="100"
          :is-loading="isLoading"
          :upload-label="$t($ts.companyInfo.companyLogo)"
          @file-selected="onImageSelected"
        />
      </CompanyInfoField>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
