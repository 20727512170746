<script lang="ts">
import { Bar, Line } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getMonths } from "@/Utilities/dateUtility";
// @ts-ignore
@Component({
  extends: Line, // this is important to add the functionality to your component
})
export default class DailyCommitChart extends Vue<Line> {
  @Prop({ default: null }) data: Array<number>;
  @Prop({ default: null }) year: number;
  @Prop({ default: null }) month: number;
  @Prop({ default: null }) monthName: string;

  public language: any;
  public gradient: any | null;
  public option: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  public mounted(): void {
    let vm = this;

    // Generate array of days in the month
    const daysInMonth = new Date(this.year, this.month, 0).getDate();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    // Overwriting base render method with actual data.
    const canvas: any = this.$refs.canvas;
    const self = this;

    this.gradient = canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(135, 106, 255, 0.8)");
    this.gradient.addColorStop(0.5, "rgba(135, 106, 255, 0.5)");
    this.gradient.addColorStop(1, "rgba(135, 106, 255, 0)");

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 20,
              callback: function (value: number) {
                return self.$t(self.$ts.currency) + " " + value.toLocaleString();
              },
            },
            gridLines: {
              display: true,
              color: "rgba(200, 200, 200, 0.15)",
              zeroLineColor: "rgba(200, 200, 200, 0.25)",
              drawTicks: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              padding: 20,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        padding: 30,
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const value = dataset.data[tooltipItem.index];
            return dataset.label + ": " + self.$t(self.$ts.currency) + " " + value.toLocaleString();
          },
        },
      },
    };

    this.renderChart(
      {
        labels: days,
        datasets: [
          {
            label: vm.monthName,
            borderColor: "#876AFF",
            pointBackgroundColor: "#876AFF",
            borderWidth: 1,
            pointBorderColor: "#876AFF",
            backgroundColor: this.gradient,
            data: this.data,
          },
        ],
      },
      options
    );
  }
}
</script>

<style>
.Chart {
  background: #212733;
  border-radius: 15px;
  box-shadow: 0px 2px 15px rgba(25, 25, 25, 0.27);
  margin: 25px 0;
}

.Chart h2 {
  margin-top: 0;
  padding: 15px 0;
  color: rgba(255, 0, 0, 0.5);
  border-bottom: 1px solid #323d54;
}
</style>
