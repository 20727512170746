import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { CustomerService } from "@/services/api/CustomerService";
import { ICustomerDetailedStatisticsDTO, ICustomerDTO, ICustomerStatisticsDTO } from "@shared/types";
import { i18n } from "@/main";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class CustomersModule extends VuexModule {
  private readonly customerService: CustomerService;

  constructor(options: any) {
    super(options);
    this.customerService = CustomerService.getInstance();
  }

  // State
  customers: ICustomerDTO[] = [];
  customerAmount = 0;
  defaultCustomerToBook: ICustomerDTO | null = null;

  @Action
  async getCustomers() {
    const customers = await this.customerService.getCustomers();
    this.customers = customers;
    this.customerAmount = customers.length;
    return customers;
  }

  @Action
  async getCustomer(customerId: number) {
    return this.customerService.getCustomer(customerId);
  }

  @Action
  async getCustomerStats(customerId: number) {
    return this.customerService.getCustomerStats(customerId);
  }

  @Action
  async putCustomer(customer: ICustomerDTO) {
    await this.customerService.putCustomer(customer);
    await this.getCustomers();
    // this.store.dispatch(
    //   actionStringSnackbar.postSnackbarMessage,
    //   i18n.t("customerModalMessages.customerUpdated").toString()
    // );
  }

  @Action
  async getDetailedCustomerStats(customerId: number): Promise<ICustomerDetailedStatisticsDTO> {
    return this.customerService.getDetailedCustomerStats(customerId);
  }

  @Action
  async postCustomer(customer: any) {
    customer = await this.customerService.postCustomer(customer);
    this.getCustomers();
    return customer;

    // this.store.dispatch(
    //   actionStringSnackbar.postSnackbarMessage,
    //   i18n.t("customerModalMessages.customerAdded").toString()
    // );
  }

  @Action
  async deleteCustomer(id: number) {
    await this.customerService.deleteCustomer(id);
    await this.getCustomers();
    // this.store.dispatch(
    //   actionStringSnackbar.postSnackbarMessage,
    //   i18n.t("customerModalMessages.customerDeleted").toString()
    // );
  }
}

export const customersModule = new CustomersModule({ store, name: "customersModule" });
