import { VuexModule, Module, Action } from "vuex-class-modules";
import { ServiceCategoryService } from "@/services/api/ServiceCategoryService";
import { IPostServiceCategoryDTO, IServiceCategoryDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class ServiceCategoryModule extends VuexModule {
  private readonly serviceCategoryService: ServiceCategoryService;

  constructor(options: any) {
    super(options);
    this.serviceCategoryService = ServiceCategoryService.getInstance();
  }

  // State
  categories: IServiceCategoryDTO[] = [];

  @Action
  async getCategories() {
    const categories = await this.serviceCategoryService.getCategories();
    this.categories = categories;
    return categories;
  }

  @Action
  async changeCategoryOrder(payload: { categoryId: number; order: number }) {
    await this.serviceCategoryService.changeOrderForCategory(payload.categoryId, payload.order);
    await this.getCategories();
  }

  @Action
  async putCategory(category: IPostServiceCategoryDTO) {
    await this.serviceCategoryService.editCategory(category);
    await this.getCategories();
  }

  @Action
  async postCategory(category: IServiceCategoryDTO) {
    await this.serviceCategoryService.postCategory(category);
    await this.getCategories();
  }

  @Action
  async deleteCategory(id: number) {
    await this.serviceCategoryService.deleteCategory(id);
    await this.getCategories();
  }
}

export const serviceCategoryModule = new ServiceCategoryModule({ store, name: "serviceCategoryModule" });
