import { VuexModule, Module, Action } from "vuex-class-modules";
import { DebugLogService } from "@/services/api/DebugLogService";
import { IDebugLogDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class DebugLogModule extends VuexModule {
  private readonly debugLogService: DebugLogService;

  constructor(options: any) {
    super(options);
    this.debugLogService = DebugLogService.getInstance();
  }

  // State
  debugEvents: IDebugLogDTO[] = [];

  @Action
  async getDebugEvents() {
    this.debugEvents = await this.debugLogService.getEvents();
  }
}

export const debugLogModule = new DebugLogModule({ store, name: "debugLogModule" });
