<template>
  <v-card>
    <v-card-title>
      {{ $t($ts.entities.oneTimeExpense) }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t($ts.placeHolder.typeToSearch)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-toolbar flat>
      <v-toolbar-title></v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="openCreateDialog">
        <v-icon left>mdi-plus</v-icon>
        {{ $t($ts.entities.oneTimeExpense) }}
      </v-btn>
    </v-toolbar>

    <responsive-table :items="oneTimeExpenses" :loading="loading">
      <!-- Desktop Headers -->
      <template v-slot:headers>
        <th>{{ $t($ts.dtos.oneTimeExpenseFormDTO.purchaseDate.name) }}</th>
        <th>{{ $t($ts.dtos.oneTimeExpenseFormDTO.amount.name) }}</th>
        <th>{{ $t($ts.category) }}</th>
        <th>{{ $t($ts.dtos.oneTimeExpenseFormDTO.notes.name) }}</th>
        <th class="text-right">{{ $t($ts.fields.actions) }}</th>
      </template>

      <!-- Desktop Row -->
      <template v-slot:desktop-row="{ item }">
        <td>{{ formatDate(item.purchaseDate) }}</td>
        <td>{{ formatCurrency(item.amount) }}</td>
        <td>{{ $t($ts.expenseCategoryAsAction[ExpenseCategory[item.category].toLowerCase()]) }}</td>
        <td>{{ item.notes }}</td>
        <td class="text-right">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </td>
      </template>

      <!-- Mobile Header -->
      <template v-slot:mobile-header="{ item }">
        <div class="d-flex justify-space-between align-center">
          <div>
            <div class="font-weight-medium">{{ formatDate(item.purchaseDate) }}</div>
            <div class="text-caption">{{ $t($ts.expenseCategoryAsAction[ExpenseCategory[item.category].toLowerCase()]) }}</div>
          </div>
          <div class="text-h6">{{ formatCurrency(item.amount) }}</div>
        </div>
      </template>

      <!-- Mobile Content -->
      <template v-slot:mobile-content="{ item }">
        <v-list dense>
          <v-list-item v-if="item.notes">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t($ts.dtos.oneTimeExpenseFormDTO.notes.name) }}</v-list-item-subtitle>
              <v-list-item-title>{{ item.notes }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <div class="d-flex justify-end mt-2">
                <v-btn small text color="primary" class="mr-2" @click="editItem(item)">
                  <v-icon small left>edit</v-icon>
                  {{ $t($ts.actions.edit) }}
                </v-btn>
                <v-btn small text color="error" @click="deleteItem(item)">
                  <v-icon small left>delete</v-icon>
                  {{ $t($ts.actions.delete) }}
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </responsive-table>

    <!-- One-time expense dialog -->
    <one-time-expense-dialog :visible.sync="dialog" :expense="editedItem" :title="formTitle" @save="saveExpense" />

    <!-- Delete dialog -->
    <delete-expense-dialog :visible.sync="dialogDelete" @confirm="deleteItemConfirm" />
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { expensesModule } from "@/store/modules/expenses/expensesModule";
import { IPostOneTimeExpenseDTO, ExpenseCategory, IOneTimeExpenseDTO } from "@shared/types";
import OneTimeExpenseDialog from "@/components/expenses/OneTimeExpenseDialog.vue";
import DeleteExpenseDialog from "@/components/expenses/DeleteExpenseDialog.vue";
import { userModule } from "@/store/modules/user/userModule";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";

@Component({
  components: {
    OneTimeExpenseDialog,
    DeleteExpenseDialog,
    ResponsiveTable,
  },
})
export default class OneTimeExpensesTable extends Vue {
  private search = "";
  private dialog = false;
  private dialogDelete = false;
  private editedIndex = -1;
  private editedItem: IOneTimeExpenseDTO | null = null;
  private loading = false;
  private ExpenseCategory = ExpenseCategory;

  get oneTimeExpenses() {
    return expensesModule.oneTimeExpenses;
  }

  get formTitle() {
    return this.editedIndex === -1 ? this.$t(this.$ts.actions.create) : this.$t(this.$ts.actions.edit);
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }

  private formatDate(date: Date | null | undefined): string {
    if (!date) return "-";
    return new Date(date).toLocaleDateString(this.$i18n.locale);
  }

  private openCreateDialog() {
    this.editedIndex = -1;
    this.dialog = true;
  }

  private editItem(item: IOneTimeExpenseDTO) {
    this.editedIndex = this.oneTimeExpenses.indexOf(item);
    this.editedItem = { ...item };
    this.dialog = true;
  }

  private deleteItem(item: IOneTimeExpenseDTO) {
    this.editedIndex = this.oneTimeExpenses.indexOf(item);
    this.dialogDelete = true;
  }

  private async deleteItemConfirm() {
    try {
      this.loading = true;
      const expense = this.oneTimeExpenses[this.editedIndex];
      if (expense?.id) {
        await expensesModule.deleteOneTimeExpense(expense.id);
        await this.loadExpenses();
      }
    } catch (error) {
      console.error("Failed to delete expense:", error);
    } finally {
      this.loading = false;
    }
  }

  private async saveExpense(expense: IPostOneTimeExpenseDTO) {
    try {
      this.loading = true;
      if (this.editedIndex > -1) {
        const existingExpense = this.oneTimeExpenses[this.editedIndex];
        if (existingExpense?.id) {
          await expensesModule.editOneTimeExpense({
            id: existingExpense.id,
            dto: expense,
          });
        }
      } else {
        await expensesModule.createOneTimeExpense(expense);
      }
      await this.loadExpenses();
    } catch (error) {
      console.error("Failed to save expense:", error);
    } finally {
      this.loading = false;
    }
  }

  private async loadExpenses() {
    if (this.loading) return;

    try {
      this.loading = true;
      await expensesModule.getOneTimeExpenses();
    } catch (error) {
      console.error("Failed to load expenses:", error);
    } finally {
      this.loading = false;
    }
  }

  async created() {
    await this.loadExpenses();
  }
}
</script>
