<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs>
          <v-tab>{{ $t($ts.entities.oneTimeExpense) }}</v-tab>
          <v-tab>{{ $t($ts.entities.recurringExpense) }}</v-tab>

          <v-tab-item>
            <one-time-expenses-table />
          </v-tab-item>

          <v-tab-item>
            <recurring-expenses-table />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import OneTimeExpensesTable from "@/components/expenses/OneTimeExpensesTable.vue";
import RecurringExpensesTable from "@/components/expenses/RecurringExpensesTable.vue";

@Component({
  components: {
    OneTimeExpensesTable,
    RecurringExpensesTable,
  },
})
export default class Expenses extends Vue {}
</script>
