<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Component({
  name: "image-upload",
})
export default class ImageUpload extends Vue {
  @Prop({ required: true }) currentImageUrl!: string | undefined;
  @Prop({ required: true }) size!: number;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: "mdi-account-circle" }) defaultIcon!: string;
  @Prop({ default: "Upload Image" }) uploadLabel!: string;

  files: File | null = null;
  hasFile: boolean = false;
  isShowingUploadSection: boolean = false;

  public previewFiles(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || !input.files[0]) return;

    this.files = input.files[0];

    if (this.files.size > 200000) {
      snackbarModule.postSnackbarMessage(this.$t(this.$ts.snackbar.imageTooLarge).toString());
      this.hasFile = false;
      this.files = null;
      return;
    }

    if (this.files.type === "image/jpeg" || this.files.type === "image/png" || this.files.type === "image/jpg") {
      this.hasFile = true;
      this.$emit("file-selected", this.files);
    } else {
      snackbarModule.postSnackbarMessage(this.$t(this.$ts.snackbar.invalidImageFormat).toString());
      this.hasFile = false;
      this.files = null;
    }
  }

  public toggleUploadSection(): boolean {
    return (this.isShowingUploadSection = !this.isShowingUploadSection);
  }

  clickOnFile() {
    //@ts-ignore
    this.$refs.myFiles.click();
  }
}
</script>

<template>
  <div class="d-flex flex-column align-center">
    <v-hover v-slot:default="{ hover, props }">
      <v-avatar
        v-bind="props"
        :size="size"
        style="border-radius: 50%; position: relative; cursor: pointer"
        @click="toggleUploadSection"
      >
        <v-img v-if="currentImageUrl" :src="currentImageUrl" :class="{ 'on-hover': hover }" alt="profile image" />
        <v-icon v-else :size="size" color="grey lighten-1">{{ defaultIcon }}</v-icon>

        <v-overlay v-if="hover" absolute>
          <form enctype="multipart/form-data">
            <div class="dropbox">
              <input
                type="file"
                ref="myFiles"
                name="photos"
                @change="previewFiles"
                accept="image/*"
                style="display: none"
                class="input-file visually-hidden"
              />
              <v-btn @click="clickOnFile" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </form>
        </v-overlay>

        <v-overlay v-if="isLoading" absolute>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
      </v-avatar>
    </v-hover>
  </div>
</template>

<style lang="scss" scoped>
.on-hover {
  opacity: 0.6;
}
</style>
