import { i18n } from "@/main";
import { DisplayType, PermissionLevel, IFieldMetadata } from "@shared/types";

interface ValueFormatter {
  format(value: any): string;
}

class SwitchValueFormatter implements ValueFormatter {
  format(value: boolean): string {
    return value ? i18n.t("boolean.yes").toString() : i18n.t("boolean.no").toString();
  }
}

class DateValueFormatter implements ValueFormatter {
  format(value: string): string {
    if (!value) return "";
    const date = new Date(value);
    return date.toLocaleDateString();
  }
}

export class FormGeneratorService {
  private valueFormatters: { [key: string]: ValueFormatter } = {
    [DisplayType.Switch]: new SwitchValueFormatter(),
    [DisplayType.Date]: new DateValueFormatter(),
  };

  private getPermissionLevelItems() {
    return [
      {
        text: i18n.t("accountRole.permissionLevels.none"),
        value: PermissionLevel.None,
      },
      {
        text: i18n.t("accountRole.permissionLevels.read"),
        value: PermissionLevel.Read,
      },
      {
        text: i18n.t("accountRole.permissionLevels.readWrite"),
        value: PermissionLevel.ReadWrite,
      },
    ];
  }

  formatFieldValue(type: DisplayType, value: any): string | number {
    if (type === DisplayType.Number) {
      return Number(value);
    }
    const formatter = this.valueFormatters[type];
    return formatter ? formatter.format(value) : value?.toString() || "";
  }

  getFieldComponent(type: DisplayType): string {
    const mappings: { [key in DisplayType]: string } = {
      [DisplayType.Text]: "Textfield",
      [DisplayType.TextArea]: "Textfield",
      [DisplayType.Number]: "Textfield",
      [DisplayType.Switch]: "v-switch",
      [DisplayType.ColorPicker]: "v-color-picker",
      [DisplayType.Dropdown]: "EnumField",
      [DisplayType.Date]: "v-date-picker",
      [DisplayType.DateField]: "DatePickerField",
    };
    return mappings[type as DisplayType] || "Textfield";
  }

  getFieldProps(field: IFieldMetadata): { [key: string]: any } {
    const baseProps: { [key: string]: any } = {};

    switch (field.type) {
      case DisplayType.Number:
        return {
          ...baseProps,
          number: true,
        };
      case DisplayType.TextArea:
        return {
          ...baseProps,
          multiLine: true,
        };
      case DisplayType.Switch:
        return {
          ...baseProps,
          color: "#56dcb6",
          inset: true,
          type: "boolean",
        };
      case DisplayType.ColorPicker:
        return {
          "dot-size": 25,
          "hide-mode-switch": true,
          "show-swatches": true,
          "hide-sliders": true,
          "hide-inputs": true,
          "hide-canvas": true,
          "swatches-max-height": 100,
        };
      case DisplayType.Dropdown:
        // For permission level dropdowns
        return {
          ...baseProps,
          items: this.getPermissionLevelItems(),
          "item-text": "text",
          "item-value": "value",
        };
      case DisplayType.Date:
        return {
          ...baseProps,
          "no-title": true,
          "close-on-content-click": false,
          transition: "scale-transition",
          "offset-y": true,
          "min-width": "auto",
          "prepend-icon": "mdi-calendar",
        };
      case DisplayType.DateField:
        return {
          ...baseProps,
          outlined: true,
          dense: true,
          prependIcon: "",
          maxDate: new Date().toISOString().substr(0, 10),
          minDate: "",
        };

      default:
        return baseProps;
    }
  }
}

// v-model="editingRole.appointmentsAndClients"
//                   :items="permissionLevels"
//                   item-text="text"
//                   item-value="value"
