<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-card flat width="100%" height="100%">
            <v-card-text>
              <h2>{{ $t($ts.calendar.heading) }}</h2>
            </v-card-text>

            <v-card-text>
              <h3>{{ $t($ts.hideDaysOutsideWorkingHours) }}</h3>

              <h4 v-if="hasMultipleStaff">
                {{ $t($ts.cannotActivateWithMultipleStaff) }}
              </h4>

              <v-switch
                :disabled="hasMultipleStaff"
                v-model="hideIrrelevantDaysInCalendar"
                color="#56dcb6"
                :label="booleanToOnOff(hideIrrelevantDaysInCalendar)"
                @change="saveField('hideIrrelevantDaysInCalendar', $event)"
              ></v-switch>
            </v-card-text>

            <v-card-text>
              <h2>{{ $t($ts.events) }}</h2>
            </v-card-text>

            <v-card-text>
              <h3>{{ $t($ts.showServiceNameInEvent) }}</h3>

              <v-switch
                v-model="showServiceNameInEvent"
                color="#56dcb6"
                :label="booleanToOnOff(showServiceNameInEvent)"
                @change="saveField('showServiceNameInEvent', $event)"
              ></v-switch>

              <h3>{{ $t($ts.showStaffInEvent) }}</h3>

              <v-radio-group v-model="showStaffMemberInEvent" row @change="saveField('showStaffMemberInEvent', $event)">
                <v-radio :label="$t($ts.boolean.off)" :value="0"></v-radio>
                <v-radio :label="$t($ts.name)" :value="1"></v-radio>
                <v-radio :label="$t($ts.color)" :value="2"></v-radio>
              </v-radio-group>

              <h3>{{ $t($ts.showTimeInEvent) }}</h3>

              <v-switch
                v-model="showTimeInEvent"
                color="#56dcb6"
                :label="booleanToOnOff(showTimeInEvent)"
                @change="saveField('showTimeInEvent', $event)"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "CalendarStyleSettings",
})
export default class CalendarStyleSettings extends Vue {
  public showInformationBox: boolean = false;
  public informationBoxText: string = "";
  public hideIrrelevantDaysInCalendar: boolean = false;
  public intervalMinutes: number = 0;

  public showStaffMemberInEvent: number = 0;
  public showTimeInEvent: boolean | number = false;
  public showServiceNameInEvent: boolean | number = false;

  intervalMinutesOptions = [90, 60, 30, 15];

  private fieldMessages: {};

  created() {
    this.showInformationBox = userModule.user.showInformationBox;
    this.informationBoxText = userModule.user.informationBoxText;
    this.hideIrrelevantDaysInCalendar = userModule.user.hideIrrelevantDaysInCalendar;
    this.intervalMinutes = userModule.user.intervalMinutes;
    this.showStaffMemberInEvent = userModule.user.showStaffMemberInEvent;
    this.showTimeInEvent = userModule.user.showTimeInEvent;

    this.showServiceNameInEvent = userModule.user.showServiceNameInEvent;

    this.fieldMessages = {
      hideIrrelevantDaysInCalendar: this.$t(this.$ts.fieldsettings.hideDaysOutsideWorkingHours),
      showServiceNameInEvent: this.$t(this.$ts.fieldsettings.showServiceNameInEvent),
      showTimeInEvent: this.$t(this.$ts.fieldsettings.showTimeInEvent),
      showStaffMemberInEvent: this.$t(this.$ts.fieldsettings.showStaffInEvent),
    };
  }

  async saveField(field: string, newValue: boolean | number): Promise<void> {
    try {
      const oldValue = userModule.user[field];
      if (newValue === oldValue) return;

      // Create audit log
      const message =
        field === "showStaffMemberInEvent"
          ? ` endret innstilling '${this.fieldMessages[field]}' til ${this.showStaffMemberInEventLabel(
              newValue as number
            )}`
          : ` endret innstilling '${this.fieldMessages[field]}' til ${this.booleanToOnOff(newValue)}`;

      await auditLogModule.postAuditLog({
        action: 1,
        type: 2,
        comment: "",
        message,
        hideForUser: false,
      });

      // Save the field
      await userModule.updateUser({ [field]: newValue });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this[field] = userModule.user[field];
    }
  }

  showStaffMemberInEventLabel(value: number): string {
    if (value === 0) {
      return this.$t(this.$ts.boolean.off).toString();
    }
    if (value === 1) {
      return this.$t(this.$ts.name).toString();
    }
    if (value === 2) {
      return this.$t(this.$ts.color).toString();
    }
    return "";
  }

  booleanToOnOff(value: boolean | number): string {
    return value || value === 1 ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }

  get isOldDomain(): boolean {
    return window.location.toString().includes("vergba");
  }

  get hasMultipleStaff(): boolean {
    return staffMemberModule.staffMembers.length > 1;
  }
}
</script>

<style scoped></style>
