import { VuexModule, Module, Action } from "vuex-class-modules";
import { EventsService } from "@/services/api/EventsService";
import { IEventDTO, IEventsController, IPaginatedEventsResponseDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class EventsModule extends VuexModule {
  private readonly eventsService: EventsService;

  constructor(options: any) {
    super(options);
    this.eventsService = EventsService.getInstance();
  }

  // State
  events: IEventDTO[] = [];
  unseenEvents: number = 0;
  eventsPagination: Omit<IPaginatedEventsResponseDTO, "items"> | null = null;

  @Action
  async getEvents(options?: Partial<typeof IEventsController.IGetEventsQuery>) {
    const queryParams = {
      page: options?.page || 1,
      itemsPerPage: options?.itemsPerPage || 10,
      sortBy: options?.sortBy || "time",
      desc: options?.desc !== undefined ? String(options.desc) : "true"
    };
    
    const response = await this.eventsService.getEvents(queryParams);
    this.events = response.items;
    this.eventsPagination = {
      total: response.total,
      page: response.page,
      itemsPerPage: response.itemsPerPage,
      sortBy: response.sortBy,
      sortDesc: response.sortDesc,
    };
    return response;
  }

  @Action
  async getUnseenEvents(): Promise<number> {
    this.unseenEvents = await this.eventsService.getUnSeenEvents();
    return this.unseenEvents;
  }

  @Action
  async markEventsAsRead() {
    await this.eventsService.markAsRead();
    await this.getUnseenEvents();
  }
}

export const eventsModule = new EventsModule({ store, name: "eventsModule" });
