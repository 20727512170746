"use strict";

export enum Sector {
    HEALTH = 0,
    CAR = 1,
    HAIRDRESSER = 2,
    GENERAL_BOOKIG = 3,
}



export interface ISector {
    sector:Sector,
    sectorName:string,
}
