import { EventBaseService } from "./Base/EventBaseService";
import { IDebugLogController, IDebugLogDTO } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class DebugLogService extends EventBaseService implements IDebugLogController {
  private static instance: DebugLogService;
  private controller = "DebugLog"; // Matches backend route api/DebugLog

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!DebugLogService.instance) {
      DebugLogService.instance = new DebugLogService();
    }
    return DebugLogService.instance;
  }

  public async getEvents(): Promise<IDebugLogDTO[]> {
    return this.withEventEmission(Feature.DebugLog, EventType.Updated, () =>
      this.http.getAuthenticated<IDebugLogDTO[]>(`${this.controller}/current`)
    );
  }
}
