<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IStaffMemberDTO } from "@shared/types";
import { Watch } from "vue-property-decorator";
import { DialogType, IDialog } from "@/store/dialog";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";

@Component({
  name: "StaffMembersOverview",
  components: {
    ResponsiveTable,
    DynamicForm,
  },
})
export default class StaffMembersOverview extends Vue {
  public isShowingForm: boolean = false;
  public isEditing: boolean = false;
  public editingStaff: IStaffMemberDTO | null = null;

  created() {
    staffMemberModule.getStaffMembers();
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.staffMemberFormDTO });
  }

  get headers() {
    return [
      { text: "", align: "start", sortable: true, value: "severity" },
      {
        text: this.$t(this.$ts.staffMembersOverview.firstName),
        sortable: true,
        value: "firstName",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.lastName),
        sortable: true,
        value: "lastName",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.emailAddress),
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.phoneNumber),
        sortable: true,
        value: "phoneNumber",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.role),
        sortable: true,
        value: "role",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.lastLoggedIn),
        sortable: true,
        value: "lastLoggedIn",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.created),
        align: "start",
        sortable: true,
        value: "created",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.actions),
        align: "center",
        sortable: false,
        value: "actions",
      },
    ];
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  get staffMemberMetadata() {
    return metadataModule.staffMemberMetadata;
  }

  public toggleForm(): void {
    if (!this.isShowingForm) {
      this.editingStaff = null;
      this.isEditing = false;
    }
    this.isShowingForm = !this.isShowingForm;
  }

  editStaff(staff: IStaffMemberDTO): void {
    this.editingStaff = staff;
    this.isEditing = true;
    this.isShowingForm = true;
  }

  async onAddNewStaff(staff: Partial<IStaffMemberDTO>): Promise<void> {
    try {
      await staffMemberModule.postStaffMember(staff as IStaffMemberDTO);
      this.isShowingForm = false;
      this.editingStaff = null;
      this.isEditing = false;
    } catch (error) {
      console.error(error);
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.staffMembersOverview.errorCreatingStaff).toString(),
      });
    }
  }

  async onUpdateStaff(staff: IStaffMemberDTO): Promise<void> {
    try {

      const edit : Partial<IStaffMemberDTO> = {
        firstName: staff.firstName,
        lastName: staff.lastName,
        emailAddress: staff.emailAddress,
        phoneNumber: staff.phoneNumber,
        role: staff.role,
        commissionPercentageProducts: staff.commissionPercentageProducts,
        commissionPercentageServices: staff.commissionPercentageServices,
        eligibleForCommission: staff.eligibleForCommission,
        eligibleForCommissionProducts: staff.eligibleForCommissionProducts,
        eligibleForCommissionServices: staff.eligibleForCommissionServices,
        overrideServicesCommission: staff.overrideServicesCommission,
        overrideProductsCommission: staff.overrideProductsCommission,
      };
  
      //Fix... auto generate patch types.
      let patch: any = {
        staffMemberId: staff.id,
        patch: edit,
      };

      await staffMemberModule.patchStaffMember(patch);
      this.isShowingForm = false;
      this.isEditing = false;
      this.editingStaff = null;
    } catch (error) {
      console.error(error);
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.staffMembersOverview.errorUpdatingStaff).toString(),
      });
    }
  }

  deleteStaff(staff: IStaffMemberDTO): void {
    if (this.staffMembers.length === 1) {
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.staffMembersOverview.minOneStaffRequired).toString(),
      });
      return;
    }

    const vm = this;
    const dialog: IDialog = {
      text: this.$t(this.$ts.staffMembersOverview.confirmDeleteStaff).toString(),
      type: DialogType.Choice,
      action: function () {
        staffMemberModule.deleteStaffMember(staff.id);
      },
    };
    dialogModule.addToDialogQueue(dialog);
  }
}
</script>

<template>
  <div>
    <v-dialog max-width="660" v-model="isShowingForm">
      <DynamicForm
        v-if="isShowingForm"
        :data="editingStaff"
        :metadata="staffMemberMetadata"
        :is-editing="isEditing"
        :is-creating="!isEditing"
        :entity="$t(this.$ts.entities.staff)"
        :translation-path="nonReactiveTranslations"
        @cancel="isShowingForm = false"
        @save="onUpdateStaff"
        @create="onAddNewStaff"
      />
    </v-dialog>

    <v-data-table :headers="headers" :items="staffMembers" :items-per-page="10" class="elevation-1">
      <template #[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editStaff(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteStaff(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!-- interesting AI glitch. -->

    <!-- <v-btn color="primary" fab dark fixed bottom right class="mb-4 mr-4" @click="toggleForm">
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->
  </div>
</template>

<style scoped></style>
