<template>
  <div class="treatment-list">
    <div v-if="customers.length > 0" class="treatment">
      <transition-group name="list">
        <div class="top" v-for="(customer, i) in customers" :key="customer.id" @click.self="toggleCustomer(i)">
          <img src="/img/customer-user-2.svg" alt="customer" />
          <p @click.self="toggleCustomer(i)">
            {{ customer.firstName }}
          </p>
          <img :class="{ rotate: expandedIndex === i }" src="/img/arrow-down.svg" alt="expand" />
          <div class="bottom" v-if="expandedIndex === i">
            <CustomerModal
              @delete="$emit('delete', customer.id)"
              :customer="customer"
              :can-modify="canModify"
            ></CustomerModal>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import CustomerModal from "../CustomerModal.vue";
import { ICustomerDTO } from "@shared/types";

@Component({
  name: "CustomerList",
  components: {
    CustomerModal,
  },
})
export default class CustomerList extends Vue {
  @Prop({ type: Array, required: true }) customers!: Partial<ICustomerDTO>[];
  @Prop({ type: Boolean, required: true }) canModify!: boolean;

  expandedIndex: number | null = null;

  toggleCustomer(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
}
</script>

<style scoped>
.treatment-list {
  margin-top: 20px;
}
.rotate {
  transform: rotate(180deg);
}
.top {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.top img {
  margin-right: 10px;
}
</style>
../../../store/customerModule ../../../store/customerModule/customerModule
../../../store/modules/customerModule/customerModule
