import { i18n } from "@/main";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { userModule } from "@/store/modules/user/userModule";

export function getCurrentMonth() {
  let now = new Date();
  now.setMonth(now.getMonth() + 1);
  return now.getMonth();
}

// Add this helper function
export function getTimezoneAdjustedDate(date: string | Date): Date {
  try {
    if (date instanceof Date) {
      // Validate the Date object
      if (isNaN(date.getTime())) {
        console.warn("Invalid Date object provided, using current date");
        return new Date();
      }
      // If it's already a Date object, create new date at midnight
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      // For Date objects, we don't need timezone adjustment since they're already in local time
      return newDate;
    }
    // Handle string input
    if (!date) {
      console.warn("Invalid date string provided, using current date");
      return new Date();
    }
    // For date strings like "2024-11-20", create date explicitly in local timezone
    const [year, month, day] = date.split("-").map(Number);
    const localDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    if (isNaN(localDate.getTime())) {
      console.warn("Invalid date string format, using current date");
      return new Date();
    }
    return localDate;
  } catch (error) {
    console.warn("Error processing date:", error);
    return new Date();
  }
}

export function getMonthName(d, short = false) {
  const months = [
    i18n.t("months.january").toString(),
    i18n.t("months.february").toString(),
    i18n.t("months.march").toString(),
    i18n.t("months.april").toString(),
    i18n.t("months.may").toString(),
    i18n.t("months.june").toString(),
    i18n.t("months.july").toString(),
    i18n.t("months.august").toString(),
    i18n.t("months.september").toString(),
    i18n.t("months.october").toString(),
    i18n.t("months.november").toString(),
    i18n.t("months.december").toString(),
  ];

  if (short && months[d] != null) {
    return months[d].substring(0, 3);
  }
  return months[d];
}

export function getMonths() {
  const months = [
    i18n.t("months.january").toString(),
    i18n.t("months.february").toString(),
    i18n.t("months.march").toString(),
    i18n.t("months.april").toString(),
    i18n.t("months.may").toString(),
    i18n.t("months.june").toString(),
    i18n.t("months.july").toString(),
    i18n.t("months.august").toString(),
    i18n.t("months.september").toString(),
    i18n.t("months.october").toString(),
    i18n.t("months.november").toString(),
    i18n.t("months.december").toString(),
  ];

  return months;
}

export function getWeekDay(d) {
  return getDays()[d];
}

export function getDays() {
  return [
    i18n.t("days.sunday").toString(),
    i18n.t("days.monday").toString(),
    i18n.t("days.tuesday").toString(),
    i18n.t("days.wednesday").toString(),
    i18n.t("days.thursday").toString(),
    i18n.t("days.friday").toString(),
    i18n.t("days.saturday").toString(),
  ];
}

export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

  // Special handling for end of year
  if (d.getMonth() === 11 && d.getDate() >= 29) {
    // Check if it's December 29-31
    const nextYear = new Date(Date.UTC(d.getFullYear() + 1, 0, 1));
    const daysUntilNextYear = Math.floor((nextYear.getTime() - d.getTime()) / (24 * 60 * 60 * 1000));
    if (daysUntilNextYear <= 3) {
      console.log("Week is 53");
      return 53;
    }
  }

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

  console.log("Week number for : ", weekNo);

  return weekNo;
}

export function allHoursDividedByQuartersWithinOpeningTimes(
  startHour,
  startMinute,
  endHour,
  endMinute,
  forceTwentyFourHoursFormat = false
) {
  return allHoursDividedByQuartersOfStart(startHour, 0, endHour, forceTwentyFourHoursFormat); //Starts 10:00, or 08:00, whatever they start
}

export function allHoursDividedByQuarters() {
  return allHoursDividedByQuartersOfStart(6, 0, 24); //Starts 06:00
}

export function allHoursDividedByQuartersOfStart(startHour, startMinute, endHour, forceTwentyFourHoursFormat = false) {
  const x = 15; //minutes interval
  const times = []; // time array
  let tt = startHour * 60; // start time
  tt += startMinute;

  //loop to increment the time and push results in array
  for (let i = 0; tt <= endHour * 60; i++) {
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = formatTimeWithFormat(hh, mm, false, false, forceTwentyFourHoursFormat); // pushing data in array in format based on user settings
    tt = tt + x;
  }
  return times;
}

export function isValidDate(d) {
  const dAsNumber: number = d as number;
  return d instanceof Date && !isNaN(dAsNumber);
}

export function formatOnlyHour(date: Date) {
  if (date == null) {
    return "";
  }

  let toDateObject = new Date(date as unknown as string);
  return formatTimeWithFormat(toDateObject.getHours(), toDateObject.getMinutes());
}

export function formatDateStringHour(date: Date) {
  if (date == null) {
    return "";
  }

  let toDateObject = new Date(date as unknown as string);
  return (
    formatDate(toDateObject, FormatSpaceType.DOT) +
    " " +
    formatTimeWithFormat(toDateObject.getHours(), toDateObject.getMinutes())
  );
}

export function formatDateString(date: Date) {
  if (date == null) {
    return "";
  }

  let toDateObject = new Date(date as unknown as string);
  return formatDate(toDateObject, FormatSpaceType.DOT);
}

export function getFormattedCurrentDate() {
  const now = new Date();
  const dayName = i18n.t(`days.${now.toLocaleString("en", { weekday: "long" }).toLowerCase()}`).toString();
  const date = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Adjusted to get correct month
  return `${date}.${month} ${dayName}`;
}

// Helper function to format time based on user settings
export function formatTimeWithFormat(
  hour: number,
  minute: number,
  standarizeFormat = false,
  displayAmPm = true,
  forceTwentyFourHoursFormat = false
): string {
  const user = userModule.user;

  let timeFormat = user?.timeFormat || "24h"; // Default to 24h if not set

  if (forceTwentyFourHoursFormat) {
    timeFormat = "24h";
  }

  if (timeFormat === "12h") {
    const period = hour >= 12 ? "PM" : "AM";
    const adjustedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
    const addedZeroToHour = standarizeFormat && adjustedHour < 10 ? "0" : "";
    const addedZeroToMinute = minute < 10 ? "0" : "";
    const amPmString = displayAmPm ? ` ${period}` : "";
    return `${addedZeroToHour}${adjustedHour}:${addedZeroToMinute}${minute}${amPmString}`;
  } else {
    // 24h format
    const addedZeroToHour = hour < 10 ? "0" : "";
    const addedZeroToMinute = minute < 10 ? "0" : "";
    return `${addedZeroToHour}${hour}:${addedZeroToMinute}${minute}`;
  }
}
