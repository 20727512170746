<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { CustomerMergeType, IAuditLogDTO, IUserDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

@Component({
  name: "BookingPageCustomerSettings",
  components: { CompanyInfoField },
})
export default class BookingPageCustomerSettings extends Vue {
  public customerMergeType: CustomerMergeType = CustomerMergeType.None;
  public automaticAddCustomers: boolean = false;

  created() {
    this.customerMergeType = userModule.user.customerMergeType;
    this.automaticAddCustomers = userModule.user.automaticAddCustomers;
  }

  async saveField(field: string, value: boolean | CustomerMergeType): Promise<void> {
    try {
      const oldValue = userModule.user[field];
      if (value === oldValue) return;

      // Create audit log based on field
      if (field === "customerMergeType") {
        const auditLogMessage = this.$t("bookingPageCustomerSettings.auditLogs.changedAssignmentType", {
          setting: this.enumToString(value as CustomerMergeType),
        });

        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: auditLogMessage.toString(),
          hideForUser: false,
        });
      } else if (field === "automaticAddCustomers") {
        const auditLogMessage = this.$t("bookingPageCustomerSettings.auditLogs.changedAutomaticCustomerCreation", {
          setting: this.booleanToOnOff(value as boolean),
        });

        await auditLogModule.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: auditLogMessage.toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await userModule.updateUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this[field] = userModule.user[field];
    }
  }

  enumToString(value: CustomerMergeType): string {
    return value === CustomerMergeType.None
      ? this.$t(this.$ts.boolean.off).toString()
      : this.$t(this.$ts.phoneNumber).toString();
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }
}
</script>

<template>
  <v-container>
    <CompanyInfoField :label="$t($ts.customerAccount)" :description="$t($ts.createAssignCustomerAccountAutomatically)">
      <v-switch
        v-model="automaticAddCustomers"
        color="#56dcb6"
        :label="$t($ts.customerAccountWillBeAssignedBasedOnPhoneNumber)"
        @change="saveField('automaticAddCustomers', $event)"
      ></v-switch>
    </CompanyInfoField>
  </v-container>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
