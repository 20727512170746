import { VuexModule, Module, Action } from "vuex-class-modules";
import { EmailListService } from "@/services/api/EmailListService";
import { IEmailListEntryDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class EmailListModule extends VuexModule {
  private readonly emailListService: EmailListService;

  constructor(options: any) {
    super(options);
    this.emailListService = EmailListService.getInstance();
  }

  // State
  emailList: IEmailListEntryDTO[] = [];

  @Action
  async getEmailList() {
    this.emailList = await this.emailListService.get();
  }
}

export const emailListModule = new EmailListModule({ store, name: "emailListModule" });
