import { VuexModule, Module, Action, Mutation } from "vuex-class-modules";
import store from "@/store/store";
import { IServiceStatStaffMemberDTO, IStaffMemberDTO, IStaffMemberStatisticsDTO } from "@shared/types";
import { IProductStatDTO } from "@shared/types";
import { IPaymentStatDTO } from "@shared/types";
import { IStaffMemberController } from "@shared/types";
import { Container } from "@/services/api/Container";
import { createPatchObject } from "@/Utilities/ObjectUtility";
import { ICommissionDataDTO } from "@shared/types";

export interface IStaffMemberPatch {
  staffMemberId: number;
  patch: Partial<IStaffMemberDTO>;
}

@Module({ generateMutationSetters: true })
export class StaffMemberModule extends VuexModule {
  private readonly staffMemberService: IStaffMemberController;

  constructor(options: any) {
    super(options);
    this.staffMemberService = Container.getInstance().get<IStaffMemberController>("IStaffMemberController");
  }

  // State
  staffMembers: IStaffMemberDTO[] = [];
  currentStatistics: IStaffMemberStatisticsDTO | null = null;
  commissionData: ICommissionDataDTO[] = [];

  // Getters
  get hasMultipleStaff(): boolean {
    return this.staffMembers.length > 1;
  }

  @Mutation
  setStaffMembers(staffMembers: IStaffMemberDTO[]) {
    this.staffMembers = staffMembers;
  }

  @Mutation
  setCurrentStatistics(statistics: IStaffMemberStatisticsDTO | null) {
    this.currentStatistics = statistics;
  }

  @Mutation
  setCommissionData(data: ICommissionDataDTO[]) {
    this.commissionData = data;
  }

  @Action
  async getStaffMembers() {
    const staffMembers = await this.staffMemberService.getStaffMembers();
    this.setStaffMembers(staffMembers);
  }

  @Action
  async editServiceStateOnStaffMember(payload: { staffMemberId: number; serviceId: number; enabled: boolean }) {
    await this.staffMemberService.editServiceStateOnStaffMember(
      payload.staffMemberId,
      payload.serviceId,
      payload.enabled
    );
    await this.getStaffMembers();
  }

  @Action
  async postStaffMember(staffMember: IStaffMemberDTO) {
    await this.staffMemberService.postStaffMember(staffMember);
    await this.getStaffMembers();
  }

  @Action
  async patchStaffMember(payload: IStaffMemberPatch) {
    //@ts-ignore
    const patchDoc = createPatchObject(payload.patch);
    //@ts-ignore
    await this.staffMemberService.patchStaffMember(patchDoc, payload.staffMemberId);
    await this.getStaffMembers();
  }

  @Action
  async deleteStaffMember(staffMemberId: number) {
    await this.staffMemberService.deleteStaffMember(staffMemberId);
    await this.getStaffMembers();
  }

  @Action
  async uploadStaffMemberPicture(payload: { file: any; staffMemberId: number }) {
    await this.staffMemberService.uploadStaffMemberPicture(payload.file, payload.staffMemberId);
    await this.getStaffMembers();
  }

  @Action
  async hasOauthToken(staffMemberId: number): Promise<boolean> {
    return await this.staffMemberService.staffMemberHasOAuth(staffMemberId);
  }

  @Action
  async getStaffMemberStatistics(payload: { staffMemberId: number; year: number; month: number; day: number }) {
    const statistics = await this.staffMemberService.getStaffMemberStatistics(
      payload.staffMemberId,
      payload.year,
      payload.month,
      payload.day
    );
    this.setCurrentStatistics(statistics);
    return statistics;
  }

  @Action
  async getStaffMemberCommissions(payload: { year: number; month: number }) {
    const commissions = await this.staffMemberService.getStaffMemberCommissions(
      payload.year,
      payload.month
    );
    this.setCommissionData(commissions);
    return commissions;
  }
}

export const staffMemberModule = new StaffMemberModule({ store, name: "staffMemberModule" });
