<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { IAppointmentForWeeklyCalenderDTO, IAuditLogDTO } from "@shared/types";
import { getDays } from "@/Utilities/dateUtility";
import { i18n } from "@/main";
import AppointmentListItem from "./AppointmentListItem.vue";
import Loader from "@/components/global/Loader.vue";
import { IDialog } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "TodayAppointmentsList",
  components: {
    AppointmentListItem,
    Loader,
  },
})
export default class TodayAppointmentsList extends Vue {
  @Prop({ required: true }) readonly appointments!: IAppointmentForWeeklyCalenderDTO[] | null;
  @Prop({ required: true }) readonly hasMultipleStaff!: boolean;
  @Prop({ required: true }) readonly isMobile!: boolean;
  @Prop({ required: true }) readonly canWriteAppointmentsAndClients!: boolean;

  get agendaTitle() {
    const days = getDays();
    var d = new Date();
    var dayName = days[d.getDay()];

    let now = new Date();
    let date: any = now.getDate();
    let month: any = now.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    return date + "." + month + " " + dayName.toString();
  }

  get progress() {
    if (!this.appointments) return 0;
    const completedTasks = this.appointments.filter((task) => task.status === "Ferdig").length;
    return (completedTasks / this.appointments.length) * 100;
  }

  get getStyle() {
    if (this.progress === 100) {
      return "color: black";
    } else {
      return "color: black";
    }
  }

  async onReadNote(note: string) {
    const dialog: IDialog = {
      text: note,
      header: i18n.t("dialog.notes").toString(),
    };
    this.$emit("show-dialog", dialog);

    const audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: note,
      message: " read admin note from agenda",
      hideForUser: true,
    };
    this.$emit("post-audit", audit);
  }

  async onReadComment(comment: string) {
    const dialog: IDialog = {
      text: comment,
      header: i18n.t("dialog.customerComment").toString(),
    };
    this.$emit("show-dialog", dialog);

    const audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: comment,
      message: " read comment from agenda",
      hideForUser: true,
    };
    this.$emit("post-audit", audit);
  }

  bookNewAppointment() {
    const audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message: " opened new appointment @ dashboard",
      hideForUser: true,
    };
    this.$emit("post-audit", audit);
    this.$emit("book-appointment");
  }
}
</script>

<template>
  <v-card flat class="mx-auto pa-0">
    <v-toolbar flat>
      <v-toolbar-title style="font-weight: bold">{{ agendaTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="appointments != null">
        <v-progress-circular :value="progress" class="mr-2" :style="getStyle"></v-progress-circular>
        <v-icon v-if="progress === 100" style="position: absolute; right: 28px; color: black">mdi-check</v-icon>
      </div>
    </v-toolbar>

    <v-card flat>
      <Loader v-if="appointments == null"></Loader>
      <div v-if="appointments != null && appointments.length === 0">
        <v-card-title style="word-break: break-word; font-size: 1.4rem">{{ $t("noAppointmentsToday") }}</v-card-title>
        <v-card-text>
          <v-img max-width="500" src="/img/relaxed.jpg" alt=""></v-img>
        </v-card-text>
      </div>
    </v-card>

    <v-list style="background: white" dark v-if="appointments != null" two-line>
      <v-list-item-group>
        <template v-for="(item, index) in appointments">
          <AppointmentListItem
            :key="item.appointmentNumber"
            :appointment="item"
            :hasMultipleStaff="hasMultipleStaff"
            :isMobile="isMobile"
            @toggle-finished="$emit('toggle-finished', $event)"
            @read-note="onReadNote"
            @read-comment="onReadComment"
          />
          <v-divider v-if="index + 1 < appointments.length" :key="index"></v-divider>
        </template>

        <v-list-item
          dark
          class="ma-1 mt-2"
          :style="'background-color: #D9D9D9; border-radius: 4px'"
          :height="78"
          @click="bookNewAppointment()"
          v-if="!isMobile && canWriteAppointmentsAndClients"
        >
          <v-list-item-content>
            <v-row justify="center">
              <v-col align="center">
                <v-btn fab color="black"><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
