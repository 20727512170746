import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { IAppointmentForWeeklyCalenderDTO, IAuditLogDTO, IStaffMemberDTO } from "@shared/types";

import { isMobile, isiPad, isDesktop } from "@/Utilities/deviceUtility";
import { i18n } from "@/main";
import { Store } from "vuex";
import { staffMemberModule } from "../staffMember/staffMemberModule";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class CalendarModule extends VuexModule {
  private store: Store<any>;

  constructor(options: any) {
    super(options);
    this.store = options.store;
  }

  // State
  calendarType: string = "week";
  intervalMinutes: number = 30;
  currentStaffMember: number = 0;
  events: IAppointmentForWeeklyCalenderDTO[] = [];
  filteredEvents: IAppointmentForWeeklyCalenderDTO[] = [];
  selectedEvent: IAppointmentForWeeklyCalenderDTO | null = null;
  showSettings: boolean = false;
  startTime: string = "";
  monthName: string = "";

  get staffMemberCategories() {
    const staffMembers = staffMemberModule.staffMembers;
    return staffMembers.map((x) => x.fullName.trim());
  }

  get calendarOptions() {
    return [
      { value: "week", text: i18n.t("calendar.week") },
      { value: "month", text: i18n.t("calendar.month") },
      { value: "day", text: i18n.t("calendar.day") },
      { value: "category", text: i18n.t("calendar.sideBySide") },
      { value: "4day", text: i18n.t("calendar.threeFourDays") },
    ];
  }

  get staffMemberOptions() {
    const staffMembers = [...(staffMemberModule.staffMembers as Partial<IStaffMemberDTO>[])];

    const allOptions: Partial<IStaffMemberDTO> = {
      fullName: i18n.t("all").toString(),
      id: 0,
    };
    staffMembers.unshift(allOptions);
    return staffMembers;
  }

  @Action
  async setMonthName(name: string) {
    this.monthName = name;
  }

  @Action
  async updateCalendarType(type: string) {
    localStorage.setItem("calendarmode", type);
    this.calendarType = type;
  }

  @Action
  async updateCurrentStaffMember(staffMember: number) {
    localStorage.setItem("currentstaffmember", staffMember.toString());
    this.currentStaffMember = staffMember;
    await this.filterEvents();
  }

  @Action
  async updateIntervalMinutes(minutes: number) {
    localStorage.setItem("calendar-zoom", minutes.toString());
    this.intervalMinutes = minutes;
  }

  @Action
  async setEvents(events: IAppointmentForWeeklyCalenderDTO[]) {
    this.events = events;
    await this.filterEvents();
  }

  @Action
  async selectEvent(event: IAppointmentForWeeklyCalenderDTO | null) {
    this.selectedEvent = event;
  }

  @Action
  async toggleSettings(show: boolean) {
    this.showSettings = show;
  }

  @Action
  async setStartTime(time: string) {
    this.startTime = time;
  }

  @Action
  async filterEvents() {
    this.filteredEvents =
      this.currentStaffMember === 0
        ? this.events
        : this.events.filter((x) => x.staffMemberId === this.currentStaffMember);
  }
}

export const calendarModule = new CalendarModule({ store, name: "calendarModule" });
