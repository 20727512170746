import { TranslationSchema } from "./translation.interface";

export const es: TranslationSchema = {
  dtos: {
    accountRoleDTO: {
      name: {
        name: "Nombre del Rol",
        description: "El nombre del rol",
      },
      appointmentsAndClients: {
        name: "Citas y Clientes",
        description: "Permiso para citas y clientes",
      },
      settings: {
        name: "Configuración",
        description: "Permiso para configuración",
      },
      statisticsAndFinances: {
        name: "Estadísticas y Finanzas",
        description: "Permiso para estadísticas y finanzas",
      },
      administration: {
        name: "Administración",
        description: "Permiso para administración",
      },
    },
    appointmentTypeFormDTO: {
      title: {
        name: "Título",
        description: "Nombre del servicio",
      },
      description: {
        name: "Descripción",
        description: "Descripción del servicio",
      },
      price: {
        name: "Precio",
        description: "Precio del servicio",
      },
      durationInMinutes: {
        name: "Duración (minutos)",
        description: "Duración del servicio en minutos",
      },
      //   serviceDurationInMinutes: {
      //     name: "Duración del servicio (minutos)",
      //     description: "Duración real del servicio en minutos",
      //   },
      hideInBooking: {
        name: "Ocultar en reservas",
        description: "Cuando está activado, este servicio no será visible en la página de reservas",
      },
      autoApply: {
        name: "Auto aplicar",
        description: "Cuando está activado, este servicio se aplicará automáticamente",
      },
      showCustomColor: {
        name: "Mostrar color personalizado",
        description: "Cuando está activado, puedes establecer un color personalizado para este servicio",
      },
      isExtraService: {
        name: "Servicio extra",
        description: "Cuando está activado, este servicio será tratado como un servicio extra",
      },
      color: {
        name: "Color",
        description: "Color personalizado para el servicio",
      },
      unavailableForOnlineBooking: {
        name: "No disponible para reservas en línea",
        description: "Cuando está activado, los clientes no pueden reservar este servicio en línea. Serán redirigidos a tu WhatsApp al seleccionar una hora",
      },
      disablePrice: {
        name: "Desactivar precio",
        description: "Cuando está activado, el precio no se mostrará para este servicio",
      },
      mustPayOnline: {
        name: "Debe pagar en línea",
        description: "Cuando está activado, los clientes deben pagar en línea por este servicio",
      },
      payOnlinePrice: {
        name: "Precio de pago en línea",
        description: "Precio para el pago en línea",
      },
      eligibleForCommission: {
        name: "Elegible para Comisión",
        description: "Cuando está activado, el miembro del personal que proporciona este servicio recibirá una comisión",
      },
      commissionPercentage: {
        name: "Proporción de comisión",
        description: "La proporción de comisión del servicio para el miembro del personal que proporciona el servicio",
      },
      eligibleForCustomPrice: {
        name: "Elegible para Precio Personalizado",
        description: "Cuando está activado, el precio del servicio puede ser personalizado",
      },
    },
    serviceCategoryFormDTO: {
      title: {
        name: "Título",
        description: "El título de la categoría de servicio",
      },
    },
    staffMemberFormDTO: {
      firstName: {
        name: "Nombre",
        description: "El nombre del miembro del personal",
      },
      lastName: {
        name: "Apellido",
        description: "El apellido del miembro del personal",
      },
      phoneNumber: {
        name: "Teléfono",
        description: "El teléfono del miembro del personal (requerido si desea enviar notificaciones directamente al miembro del personal)",
      },
      emailAddress: {
        name: "Email",
        description: "El email del miembro del personal (opcional)",
      },
      eligibleForCommission: {
        name: "Elegible para Comisión",
        description: "Cuando está activado, este miembro del personal será elegible para comisión",
      },
      eligibleForCommissionProducts: {
        name: "Elegible para comisión de productos",
        description: "Cuando está activado, este miembro del personal será elegible para comisión de productos",
      },
      eligibleForCommissionServices: {
        name: "Elegible para comisión de servicios",
        description: "Cuando está activado, este miembro del personal será elegible para comisión de servicios",
      },
      commissionPercentageProducts: {
        name: "Proporción de comisión de productos",
        description: "La proporción de comisión de productos para el miembro del personal que proporciona el servicio",
      },
      commissionPercentageServices: {
        name: "Proporción de comisión de servicios",
        description: "La proporción de comisión de servicios para el miembro del personal que proporciona el servicio",
      },
      overrideProductsCommission: {
        name: "Sobrescribir comisión de productos",
        description: "Cuando está activado, este miembro del personal anulará la comisión de productos",
      },
      overrideServicesCommission: {
        name: "Sobrescribir comisión de servicios",
        description: "Cuando está activado, este miembro del personal anulará la comisión de servicios",
      },
    },
    inventoryItemFormDTO: {
      name: {
        name: "Nombre",
        description: "El nombre del Producto",
      },
      description: {
        name: "Descripción",
        description: "La descripción del Producto",
      },
      salePrice: {
        name: "Precio de Venta",
        description: "El precio de venta del Producto (precio por unidad)",
      },
      currentStock: {
        name: "Stock Actual",
        description: "El stock actual del Producto",
      },
      costPrice: {
        name: "Precio de Coste",
        description: "Precio de compra del Producto (precio por unidad)",
      },
      minimumStockThreshold: {
        name: "Umbral de Stock Mínimo",
        description: "El umbral de stock mínimo del Producto",
      },
      reorderLevel: {
        name: "Nivel de Reaprovisionamiento",
        description: "El nivel de reaprovisionamiento del Producto",
      },
      isStockItem: {
        name: "Es artículo físico",
        description: "Si el artículo debe ser comprado de un proveedor y es un Producto físico que debe ser reaprovisionado",
      },
      eligibleForCommission: {
        name: "Elegible para Comisión",
        description: "Cuando está activado, este Producto será elegible para comisión",
      },
      commissionPercentage: {
        name: "Proporción de comisión",
        description: "La proporción de comisión del Producto para el miembro del personal que vendió el Producto",
      },
    },
    addStockFormDTO: {
      quantity: {
        name: "Cantidad",
        description: "La cantidad de artículos que se agregarán",
      },
      purchaseDate: {
        name: "Fecha de compra",
        description: "La fecha de compra del Producto",
      },
    },
    inventoryItemCategoryFormDTO: {
      name: {
        name: "Nombre",
        description: "El nombre de la categoría de Producto",
      },
      description: {
        name: "Descripción",
        description: "La descripción de la categoría de Producto",
      },
    },
    ledgerEntryFormDTO: {
      amount: {
        name: "Cantidad",
        description: "La cantidad de la entrada de contabilidad",
      },
      description: {
        name: "Descripción",
        description: "La descripción de la entrada de contabilidad",
      },
    },
    oneTimeExpenseFormDTO: {
      amount: {
        name: "Cantidad",
        description: "La cantidad de la gasto",
      },
      notes: {
        name: "Notas",
        description: "Las notas del gasto",
      },
      purchaseDate: {
        name: "Fecha",
        description: "La fecha de compra del gasto",
      },
    },
    recurringExpenseFormDTO: {
      amount: {
        name: "Cantidad",
        description: "La cantidad de la gasto",
      },
      endDate: {
        name: "Fecha de finalización",
        description: "La fecha de finalización del gasto",
      },
      name: {
        name: "Nombre",
        description: "El nombre del gasto",
      },
      startDate: {
        name: "Fecha de inicio",
        description: "La fecha de inicio del gasto",
      },
    },
    moneyAccountFormDTO: {
      name: {
        name: "Nombre",
        description: "El nombre de la cuenta de dinero",
      },
      description: {
        name: "Descripción",
        description: "La descripción de la cuenta de dinero",
      },
    },
  },
  welcome: "Bienvenido",

  months: {
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  days: {
    sunday: "Dom",
    monday: "Lun",
    tuesday: "Mar",
    wednesday: "Mié",
    thursday: "Jue",
    friday: "Vie",
    saturday: "Sáb",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Actualizar",
  },
  subscriptionWarnings: {
    message1: "¡Hola! Has probado el programa gratis durante 14 días. Esperamos que te resulte útil.",
    message2: "Si deseas seguir usando el programa, compra una suscripción en 'Configuración' -> 'Suscripción'",
    message3: "Si tienes alguna pregunta, contáctanos. ¡Gracias!",
  },
  buttons: {
    gotoSubscription: "Ir a Suscripción",
    close: "Cerrar",
    ok: "Ok",
    save: "Guardar",
    delete: "Eliminar",
    cancel: "Cancelar",
    edit: "Editar",
    create: "Crear",
  },

  noAppointmentsToday: "¡No hay citas hoy!",
  readNotes: "Leer notas",
  readCustomerComment: "Leer comentario del cliente",
  dialog: {
    customerComment: "Comentario del Cliente",
    notes: "Notas",
    confirmDeleteService: "¿Estás seguro de que deseas eliminar este servicio?",
    deleteServiceHeader: "Eliminar Servicio",
    cancel: "Cancelar",
    save: "Guardar",
    confirm: "OK",
    yes: "Sí",
    no: "No",
  },
  messages: {
    updated: "Actualizado",
    categoryChanged: "Categoría cambiada",
    categoryOrderChanged: "Orden de categoría cambiado",
    serviceOrderChanged: "Orden de servicio cambiado",
    categoryUpdated: "Categoría actualizada",
    newCategoryAdded: "Nueva categoría añadida",
    categoryDeleted: "Categoría eliminada",
    newServiceAdded: "Nuevo servicio añadido",
    couldNotAddService: "No se pudo añadir el servicio",
    serviceDeleted: "Servicio eliminado",
    serviceUpdated: "Servicio actualizado",
    inProgress: "{feature} está siendo {action}...",
    success: "{feature} fue {action}",
    failed: "No se pudo {action} {feature}",
    imageUploaded: "La imagen se ha subido correctamente",
    imageUploading: "Subiendo imagen...",
    imageUploadSuccess: "Imagen subida correctamente",
    deleteConfirmation: "¿Está seguro de que desea eliminar este artículo?",
    itemCreated: "Artículo creado exitosamente",
    itemUpdated: "Artículo actualizado exitosamente",
    itemDeleted: "Artículo eliminado exitosamente",
  },
  staff: "Personal",
  date: "Fecha",
  service: "Servicio",
  price: "Precio",
  customerAccount: "Cuenta del Cliente",
  automaticAssignExistingCustomerAccount: "Asignar automáticamente la cuenta del cliente existente a nuevas reservas en función del campo.",
  createAssignCustomerAccountAutomatically: "Crear/Asignar cuenta de cliente automáticamente al reservar",
  customerAccountWillBeAssignedBasedOnPhoneNumber: "La cuenta de cliente se asignará a la existente en función del número de teléfono.",
  newCustomField: "Nuevo Campo Personalizado",
  selectFieldsCustomerFillsWhenBooking: "Selecciona los campos que el cliente debe completar al reservar",
  optional: "Opcional",
  customFields: "Campos Personalizados",
  add: "Añadir",
  link: "Enlace",
  yourBookingLinkWillBe: "Tu enlace de reserva será {link}",
  connectSystemWithCommonBookingLink: "Conecta el sistema con un enlace de reserva común",
  yourCommonBookingLinkWillBe: "Tu enlace de reserva común será {link}",
  bookingID: "ID de Reserva",
  commonID: "ID Común",
  locationName: "Nombre de Ubicación",
  showInformationBox: "Mostrar Cuadro de Información",
  shouldInformationBoxBeDisplayed: "¿Debe mostrarse el cuadro de información en la página de reserva?",
  informationBox: "Cuadro de Información",
  informationForCustomerOnBookingPage: "Información para el cliente en la página principal de reserva",
  cancellationInformation: "Información de Cancelación",
  customizeInformationCustomerSeesWhenCancelling: "Personaliza la información que ve el cliente al cancelar",
  receiveEmailOnAppointment: "Recibir Correo Electrónico sobre la Cita",
  doYouWantToReceiveEmailOnNewAppointment: "¿Deseas recibir un correo electrónico cuando se reciba una nueva cita?",
  whichEmailAddressToReceive: "¿A qué dirección de correo electrónico se debe enviar el correo al reservar?",
  receiveSMSOnAppointment: "Recibir SMS sobre la Cita",
  doYouWantToReceiveSMSOnNewAppointment: "¿Deseas recibir un SMS cuando se reciba una nueva cita?",
  whichPhoneNumberToReceiveSMS: "¿A qué número de teléfono se debe enviar el SMS al reservar?",
  ssn: "Número de Seguro Social",
  birthdate: "Fecha de Nacimiento",
  gender: "Género",
  city: "Ciudad",
  postalCode: "Código Postal",
  pause: "Pausa",
  event: "Evento",
  from: "Desde",
  to: "Hasta",
  durationMismatch: "La duración de la reserva difiere de la duración del servicio.",
  priceMismatch: "El precio de la reserva difiere del precio del servicio.",
  cancel: "Cancelar",
  create: "Crear",
  confirm: "Confirmar",
  invalidTimeFormat: "Formato de tiempo inválido",
  enterCustomerName: "Debes ingresar un nombre de cliente.",
  selectService: "Debes seleccionar un servicio.",
  editEndTimeWithoutStart: "Intento de editar la hora de finalización sin establecer la hora de inicio.",
  minutesAbbreviation: "min",
  currency: "$",
  creationCancelled: "Creación cancelada.",
  customEvent: "Evento personalizado",
  monthlyEarningsTitle: "Ganancias de {month}",
  monthlyAppointmentsTitle: "Citas de {month}",
  totalCustomers: "Total de Clientes",
  earningsTrend: "Tendencia de Ganancias",
  servicesBooked: "Servicios Reservados",
  moneyEarnedIn: "Ganancias en {month}",
  appointmentsIn: "Citas en {month}",
  appointmentBookingLinkTitle: "Enlace de Reserva de Cita",
  appointmentBookingLinkDescription: "Usa el enlace a continuación para reservar una cita.",
  bookingLinkLabel: "Enlace de Reserva",
  copyLinkButton: "Copiar Enlace",
  linkCopiedMessage: "¡Enlace copiado al portapapeles!",
  closePreview: "Cerrar Vista Previa",
  previewBookingPage: "Vista Previa de la Página de Reservas",
  contactUs: "Contáctanos",
  sidebar: {
    home: "Inicio",
    customers: "Clientes",
    calendar: "Calendario",
    settings: "Configuración",
    appointments: "Citas",
    overview: "Resumen",
    payments: "Pagos",
    invoices: "Facturas",
    statistics: "Estadísticas",
    export: "Exportar",
    notifications: "Notificaciones",
    contactSupport: "Contactar Soporte",
    log: "Registro",
    journal: "Diario",
    inventory: "Inventario",
    inventoryItems: "Productos",
    orders: "Pedidos",
    expenses: "Gastos",
    commissions: "Comisiones",
    staffManagement: "Gestión de Personal",
    finances: "Finanzas",
    administration: "Administración",
    cashRegister: "Caja",
  },
  name: "Nombre",
  email: "Email",
  phoneNumber: "Teléfono",
  address: "Dirección",
  postalCodeCity: "Código Postal/Ciudad",
  edit: "Editar",
  created: "Creado",
  deleteSelectedCustomers: "Eliminar clientes seleccionados",
  search: "Buscar",
  searchForCustomer: "Buscar cliente",
  noCustomersYet: "No tienes clientes aún",
  noSearchResults: "Sin resultados de búsqueda",
  customerWithoutName: "Cliente sin nombre",
  confirmDeleteCustomers: "¿Estás seguro de que deseas eliminar {count} clientes?",
  deleteCustomerHeader: "¿Eliminar cliente(s)?",
  customersDeleted: "Se han eliminado {count} clientes",
  customer: {
    notFound: "Cliente no encontrado.",
    name: "Nombre",
    email: "Correo Electrónico",
    phoneNumber: "Número de Teléfono",
    address: "Dirección",
    postalCodeCity: "Código Postal/Ciudad",
    edit: "Editar",
    created: "Creado",
    deleteSelectedCustomers: "Eliminar clientes seleccionados",
    search: "Buscar",
    searchForCustomer: "Buscar cliente",
    noCustomersYet: "No tienes clientes aún",
    noSearchResults: "Sin resultados de búsqueda",
    customerWithoutName: "Cliente sin nombre",
    confirmDeleteCustomers: "¿Estás seguro de que deseas eliminar {count} clientes?",
    deleteCustomerHeader: "¿Eliminar cliente(s)?",
    customersDeleted: "Se han eliminado {count} clientes",
    deleted: "Eliminado",
    couldNotDelete: "No se pudo eliminar",
    confirmDeleteCustomer: "¿Estás seguro de que deseas eliminar este cliente?",
    credit: "Saldo pendiente",
    dropIn: "Atención sin nombre",
  },
  calendar: {
    week: "Semana",
    month: "Mes",
    day: "Día",
    sideBySide: "Lado a lado",
    threeFourDays: "3-4 días",
    heading: "Calendario",
  },
  appointment: {
    deleteSelectedAppointments: "Eliminar Citas Seleccionadas",
    filter: "Filtrar",
    all: "Todos",
    noAppointments: "No hay citas",
    showMoreCustomerInfo: "Mostrar Más Información del Cliente",
    select: "Seleccionar",
    loading: "Cargando... Por favor espere...",
    status: {
      ny: "Nuevo",
      invoiceCreated: "Factura Creada",
      invoiceSent: "Factura Enviada",
      invoicePaid: "Factura Pagada",
      ferdig: "Completado",
    },
    comment: "Comentario",
    adminNote: "Nota",
    created: "Creado",
    number: "Número",
    service: "Servicio",
    time: "Hora",
    customer: "Cliente",
    appointmentStatus: "Estado",
    createAppointment: "Crear Cita",
    editAppointment: "Editar Cita",
    markAsUnpaid: "Marcar como Impago",
    markAsUnpaidWarning: "Advertencia: Esta acción es irreversible. Se creará un nuevo registro contable para esta cita. ¿Continuar?",
  },
  companyInfo: {
    companyName: "Nombre de la Empresa",
    companyNameDescription: "El nombre de la empresa tal como se muestra al cliente",
    companyAddress: "Dirección de la Empresa",
    companyAddressDescription: "La dirección de la empresa tal como se muestra al cliente",
    phoneNumber: "Número de Teléfono",
    phoneNumberDescription: "El número de teléfono de la empresa tal como se muestra al cliente",
    email: "Correo Electrónico",
    emailDescription: "El correo electrónico de la empresa tal como se muestra al cliente",
    companyLogo: "Logotipo de la Empresa",
    companyLogoDescription: "El logotipo de la empresa tal como se muestra al cliente",
  },
  snackbar: {
    imageTooLarge: "La imagen debe ser menor de 200 KB",
    invalidImageFormat: "La imagen debe estar en formato JPG o PNG",
    enterServiceName: "Se debe proporcionar el nombre del servicio.",
    negativeDiscountPrice: "El precio de descuento no puede ser negativo.",
    enterDuration: "Se debe proporcionar la duración en minutos.",
    durationMustBeNumber: "La duración en minutos debe ser un número.",
    priceMustBeNumber: "El precio debe ser un número.",
    negativePrice: "El precio no puede ser negativo.",
    invalidDuration: "Duración inválida.",
    durationExceedsLimit: "Un solo servicio no puede durar más de 12 horas.",
  },
  account: {
    allAccounts: "Todas las Cuentas",
    registerNewAccount: "Registrar Nueva Cuenta",
    editAccount: "Editar Cuenta",
    lastSignIn: "Último acceso: {date}",
    created: "Creado: {date}",
    deleted: "Eliminado",
    deleteConfirmation: "¿Estás seguro de que deseas eliminar esta cuenta? La cuenta será desactivada y marcada como eliminada.",
    deleteSuccess: "Cuenta eliminada exitosamente",
    deleteError: "Error al eliminar la cuenta",
    yourAccount: "Tu Cuenta",
  },
  role: {
    administrator: "Administrador",
    user: "Usuario",
  },
  logout: "Cerrar sesión",
  changePassword: "Cambiar Contraseña",
  logoutConfirm: "¿Deseas cerrar sesión?",
  forgotPassword: "¿Deseas enviar un enlace de restablecimiento a {email}?",
  passwordResetEmailSent: "Se ha enviado un correo electrónico para restablecer la contraseña.",
  subscription: {
    yourPlan: "Tu Plan",
    nextPayment: "Próximo Pago",
    previousPayment: "Pago Anterior",
    paymentInformation: "Información de Pago",
    cardEnding: "Visa terminada en {card}",
    noTrial: "Tu período de prueba ha expirado. Compra una suscripción para continuar usando el programa.",
    paymentIssue: "Hay un problema con tu pago. Contáctanos.",
    trialDaysLeft: "Te quedan {days} días de prueba gratuita.",
    cancelledWithDate: "La suscripción no se renovará el próximo mes. Está activa hasta {date}.",
    cancelledWithoutDate: "La suscripción no se renovará el próximo mes. Está activa hasta fin de mes.",
    active: "Tienes una suscripción activa.",
    yearlyWithSMS: "Tienes una suscripción válida hasta {date} con SMS incluido.",
    monthlyCost: "La suscripción cuesta 250kr al mes, sin período de permanencia. Cancela en cualquier momento. Al cancelar, la suscripción durará hasta la próxima fecha de pago.",
    automaticPayment: "El pago es automático cada mes. Si prefieres una factura, no dudes en pedirla.",
    smsCost: "Si has elegido enviar recibos/recordatorios por SMS, o notificación por SMS al reservar, cuesta 1kr por SMS y se añadirá automáticamente al pago.",
    purchase: "Comprar suscripción",
    reactivate: "Reactivar suscripción",
    cancel: "Cancelar suscripción",
    cancelSubscriptionConfirm: "¿Estás seguro de que deseas cancelar tu suscripción?",
    subscription: "Suscripción",
  },
  createCustomer: "Crear Cliente",
  editCustomer: "Editar Cliente",
  lastName: "Apellido",
  dateOfBirth: "Fecha de Nacimiento",
  male: "Masculino",
  female: "Femenino",
  selectInsurance: "Seleccionar Seguro",
  noInsuranceData: "Los seguros pueden añadirse en configuración.",
  customerUpdated: "Cliente actualizado.",
  customerCreated: "Cliente creado.",
  settings: {
    staffSettings: {
      alerts: {
        singleStaffBanner: "Este miembro del personal es el único en el sistema. Por lo tanto, no es posible cambiar las configuraciones de notificación. Por favor, use Configuración -> Reserva -> Alertas",
        notificationPreference: "Preferencia de notificación",
        notificationHint: "Selecciona el método de notificación para este miembro del personal.",
        none: "Ninguno",
        email: "Correo electrónico",
        whatsapp: "WhatsApp",
        sms: "SMS",
      },
    },
    me: "Yo",
    myAccount: "Mi cuenta",
    administration: "Administración",
    companyInfo: "Información de la Empresa",
    subscription: "Suscripción",
    accounts: "Cuentas",
    accountRoles: "Roles de cuenta",
    services: "Servicios",
    extraServices: "Servicios Extras",
    categories: "Categorías",
    resources: "Recursos",
    options: "Opciones",
    staff: "Personal",
    calendar: "Calendario",
    functions: "Funciones",
    newsletter: "Boletín",
    customerAccounts: "Cuentas de Cliente",
    invoicesAndPayments: "Facturas y Pagos",
    booking: "Reserva",
    information: "Información",
    alerts: "Alertas",
    bookingSide: "Página de Reserva",
    customerInformation: "Información del Cliente",
    customerAccount: "Cuentas de Cliente",
    workingHours: "Horario Laboral",
    friday: "Días de Vacaciones",
    googleCalendar: "Google Calendar",
    overview: "Resumen",
    customFields: "Campos Personalizados",
    giftCards: "Tarjetas de Regalo",
    customerFollowUp: "Seguimiento de Cliente",
    cashRegisterSystem: "Sistema de Caja",
    multipleLocations: "Múltiples Ubicaciones",
    system: "Sistema",
    auditLog: "Registro de Auditoría",
    staffUpdated: "Personal actualizado.",
    staffDeleted: "Personal eliminado.",
    roleCreated: "Rol creado exitosamente.",
    roleUpdated: "Rol actualizado exitosamente.",
    roleDeleted: "Rol eliminado exitosamente.",
    onlineTransactions: "Transacciones en línea",
    onlineTransactionsDescription: "Configurar proveedores de pago en línea",
    paymentProvider: "Proveedor de pago",
    paymentProviderDescription: "Seleccione qué proveedor de pago usar para transacciones en línea",
    paymentProviderNone: "Ninguno",
    paymentProviderPayU: "PayU",
    paymentProviderStripe: "Stripe (Próximamente)",
    paymentProviderVipps: "Vipps (Próximamente)",
    products: "Productos",
    productCategories: "Categorías de Productos",
  },
  resourceName: "Nombre del Recurso",
  description: "Descripción",
  save: "Guardar",
  quantity: "Cantidad",
  multipleServicesQuestion: "¿Debe el cliente poder elegir múltiples servicios en una sola reserva?",
  fields: {
    name: "Nombre",
    description: "Descripción",
    actions: "Acciones",
    orderNumber: "Número de Pedido",
    orderDate: "Fecha de Pedido",
    totalAmount: "Total",
    status: "Estado",
  },
  showServicePrice: "Mostrar precio de servicios",
  serviceName: "Nombre del Servicio",
  sort: "Ordenar",
  duration: "Duración",
  category: "Categoría",
  extraServices: "Servicios Extras",
  boolean: {
    yes: "Sí",
    no: "No",
    on: "Activado",
    off: "Desactivado",
  },
  categories: "Categorías",
  services: "Servicios",
  staffMembersOverview: {
    minOneStaffRequired: "Debes tener al menos un miembro del personal para que el programa funcione.",
    confirmDeleteStaff: "¿Deseas eliminar a este miembro del personal?",
    staff: "Personal",
    yourStaff: "Tu Personal",
    newStaff: "Nuevo Personal",
    firstName: "Nombre",
    lastName: "Apellido",
    emailAddress: "Correo Electrónico",
    phoneNumber: "Número de Teléfono",
    created: "Creado",
    delete: "Eliminar",
    lastLoggedIn: "Último Acceso",
    editStaff: "Editar Personal",
    actions: "Acciones",
    allFieldsRequired: "Todos los campos son obligatorios",
    createNewStaff: "Crear Nuevo Miembro del Personal",
    firstNameDescription: "Nombre del miembro del personal",
    lastNameDescription: "Apellido del miembro del personal",
    emailOptional: "Correo electrónico (opcional)",
    phoneOptional: "Número de teléfono (opcional)",
    role: "Rol",
    roleOptional: "Rol del miembro del personal (opcional)",
    requiredFieldsMissing: "El nombre y apellido son obligatorios",
    errorCreatingStaff: "No se pudo crear el miembro del personal",
    errorUpdatingStaff: "No se pudo actualizar el miembro del personal",
    errorDeletingStaff: "No se pudo eliminar el miembro del personal",
    firstNameRequired: "El nombre es obligatorio",
    lastNameRequired: "El apellido es obligatorio",
    invalidEmail: "Correo electrónico inválido",
    invalidPhoneNumber: "Número de teléfono inválido",
  },
  staffMemberDetails: {
    firstName: "Nombre",
    lastName: "Apellido",
    phoneNumber: "Número de teléfono",
    emailAddress: "Correo electrónico",
    colorForCalendar: "Color para el calendario",
    selectColor: "Seleccione 'Color' en 'Calendario' -> 'Mostrar personal en evento' para elegir un color para el personal.",
    save: "Guardar",
    profileImage: "Imagen de perfil",
    uploadImage: "Subir imagen",
  },
  vacationDays: {
    markDayAs: "Marcar día como:",
    available: "Disponible",
    notAvailable: "No Disponible",
  },
  workingHours: {
    timeRequired: "El tiempo es requerido",
    invalidFormat: "Formato inválido (HH:MM)",
    invalidTimeFormat: "No se pudo actualizar el horario de apertura. Verifica si has ingresado el formato correcto (HH:MM)",
    updated: "Horario de apertura actualizado.",
    availableOnThisDay: "Disponible en este día: {value}",
    openingHourFor: "Hora de apertura para {day}",
    closingHourFor: "Hora de cierre para {day}",
    breaks: "Pausas",
    start: "Inicio",
    end: "Fin",
    addBreak: "Añadir Pausa",
    save: "Guardar",
  },
  generalBookingSettings: {
    customerVerification: "Verificación de Cliente",
    customerVerificationDescription: "¿Qué debe verificar el cliente para reservar una cita?",
    cancellationModification: "Cancelación/Modificación de Citas",
    cancellationModificationDescription: "¿Debe el cliente poder cambiar/cancelar la cita?",
    minimumHoursBefore: "Horas mínimas antes de la cita",
    minimumHoursBeforeDescription: "Número de horas antes de la cita en que el cliente puede cancelar",
    remindersReceipts: "Recordatorios/Recibos para Clientes",
    remindersReceiptsDescription: "¿Cómo se deben enviar los recibos/recordatorios para la cita?",
    alwaysSendReceipt: "Enviar siempre recibo",
    alwaysSendReceiptDescription: "La casilla no necesita ser marcada por el cliente",
    alwaysSendReminder: "Enviar siempre recordatorio",
    alwaysSendReminderDescription: "La casilla no necesita ser marcada por el cliente",
    bookingRestrictions: "Restricciones de Reserva",
    bookingRestrictionsDescription: "¿Cuántos días antes puede un cliente reservar?",
    earlyBooking: "¿Con cuánta anticipación puede un cliente reservar?",
    earlyBookingDescription: "Número de horas antes de la cita que se puede reservar",
    noVerification: "Sin Verificación",
    email: "Correo Electrónico",
    sms: "SMS",
    auditVerificationChanged: "Configuración cambiada: 'Verificación de Cliente'",
    auditCancellationChanged: "Configuración cambiada: 'Cancelación/Modificación de Citas'",
    auditHoursToCancelChanged: "Configuración cambiada: 'Horas mínimas antes de la cancelación de cita' a {hours} horas",
    auditReceiptReminderChanged: "Configuración cambiada: 'Recordatorios/Recibos para Clientes'",
    auditMaxDaysChanged: "Configuración cambiada: 'Restricciones de Reserva' número de días que un cliente puede reservar antes a {days} días.",
    auditMinHoursChanged: "Configuración cambiada: 'Restricciones de Reserva' cuántas horas antes puede un cliente reservar {hours} horas.",
    emailRequired: "El campo de correo electrónico ha sido configurado como obligatorio porque la información se envía por correo electrónico.",
    phoneRequired: "El campo de número de teléfono ha sido configurado como obligatorio porque los recibos y recordatorios se envían por SMS.",
    days: "Días",
    hours: "Horas",
    whatsapp: "WhatsApp",
    staffSelection: "Selección de personal",
    staffSelectionDescription: "Permitir que los clientes elijan qué miembro del personal realizará su servicio",
    auditStaffSelectionChanged: "Cambió la configuración de selección de personal",
    serviceSearch: "Búsqueda de servicios",
    serviceSearchDescription: "Permitir que los clientes busquen servicios por nombre",
    auditServiceSearchChanged: "Cambió la configuración de búsqueda de servicios",
  },
  bookingDistribution: {
    timeDivision: "División de Tiempo",
    selectTimeSlots: "Selecciona los intervalos de tiempo que se pueden reservar.",
    confirmChange: "¿Estás seguro de que deseas cambiar la división de tiempo a {minutes} minutos?",
    changeTimeFormat: "Cambiar Formato de Tiempo",
    auditChange: "Cambio en la división de tiempo a {minutes} minutos para reservas",
  },
  // CalendarStyleSettings.vue
  hideDaysOutsideWorkingHours: "Ocultar días fuera del horario laboral",
  cannotActivateWithMultipleStaff: "No se puede activar con múltiples miembros del personal",
  events: "Eventos",
  showServiceNameInEvent: "Mostrar nombre del servicio en el evento",
  showStaffInEvent: "Mostrar personal en el evento",
  showTimeInEvent: "Mostrar hora en el evento",
  color: "Color",

  // EmailListSettings.vue
  newsletter: "Boletín",
  askCustomersToSignUpForNewsletter: "Pide a los clientes que se suscriban al boletín al reservar",
  subscribers: "Suscriptores",
  noSubscribersYet: "Aquí estará el resumen de todos los suscriptores",
  signedUp: "Registrado",
  loading: "Cargando... Por favor espera...",
  downloadExcel: "Descargar Excel (.xlsx)",
  exportAppointments: "Exportar todas las citas",
  eventsOverview: {
    message: "Mensaje",
    time: "Hora",
    comment: "Comentario",
    booking: "Reserva",
    receipt: "Recibo",
    reminder: "Recordatorio",
    seen: "Visto",
    new: "Nuevo",
    previous: "Anterior",
    noEvents: "No tienes eventos",
    guest: "Invitado",
    critical: "Crítico",
    info: "Info",
    warning: "Advertencia",
    customer: "Cliente",
    errorWithSmsSending: "Algo salió mal con el envío de SMS. La verificación por SMS se ha desactivado.",
    timeAddedToExistingCustomer: "La hora reservada se añadió automáticamente al cliente existente, pero había diferencias.",
    timeCancelled: "La hora reservada fue cancelada.",
    timeChanged: "La hora reservada fue cambiada.",
    newCustomer: "Nuevo cliente.",
    customerComment: "Comentario del Cliente",
    bookingCancelled: "Reserva cancelada",
    bookingChanged: "Reserva cambiada",
  },
  onboarding: {
    user: "Usuario",
    company: "Empresa",
    workingHours: "Horario Laboral",
    services: "Servicios",
    finish: "Finalizar",
    pickWorkingHours: "Seleccionar Horario Laboral",
    createServices: "Crear Servicios",
    yourUser: "Tu Usuario",
    yourCompany: "Tu Empresa",
  },
  auditOverview: {
    event: "Evento",
    time: "Hora",
    comment: "Comentario",
    noChanges: "Aquí estará el resumen de todos los cambios realizados",
    customer: "Cliente",
    booking: "Reserva",
    settings: "Configuración",
    staff: "Personal",
    track: "Seguimiento",
    service: "Servicio",
    readComment: "Leer comentario en el registro",
  },
  customerModal: {
    notes: "Notas",
    createdByCustomer: "Creado por el cliente a través de la reserva online",
    createdByStaff: "Creado por el personal",
    statistics: {
      appointmentsThisMonth: "Citas este mes",
      appointmentsOverall: "Citas en total",
      totalUsedForServicesThisMonth: "Total usado para servicios este mes",
      totalUsedForServicesOverall: "Total usado para servicios en total",
    },
    payments: "Pagos",
    newJournal: "Agregar nueva bitácora",
    buttons: {
      cancel: "Cancelar",
      save: "Guardar",
    },
    dialog: {
      unsavedChangesWarning: "Tienes cambios sin guardar en la bitácora. Si sales, se perderán los cambios.",
      exitConfirmation: "Tienes cambios sin guardar en la bitácora. ¿Estás seguro de que deseas salir?",
    },
    journal: "Bitácora",
    generalText: "Texto general",
  },
  nameIsRequired: "El nombre es obligatorio",
  amountMustBeOneOrHigher: "La cantidad debe ser 1 o mayor",
  resources: {
    deleteConfirmation: "¿Estás seguro de que deseas eliminar este recurso?",
    deleteHeader: "Eliminar Recurso",
    name: "Nombre",
    description: "Descripción",
    quantity: "Cantidad",
    createResource: "Crear Recurso",
    editResource: "Editar Recurso",
    nameDescription: "Nombre del recurso",
    descriptionDescription: "Descripción del recurso",
    quantityDescription: "Cantidad del recurso",
  },
  functions: {
    contactUs: "Contáctanos",
    customerLogin: "Acceso de Cliente",
    invoicesAndPayments: "Facturas y Pagos",
    customFields: "Campos Personalizados",
    newsletter: "Boletín",
    giftCards: "Tarjetas de Regalo",
    customerFollowUp: "Seguimiento de Cliente",
  },
  login: {
    fillEmailPassword: "Por favor, introduce tu correo electrónico y contraseña",
    passwordsMustMatch: "Las contraseñas deben coincidir",
    emailInUse: "El correo electrónico ya está en uso",
    invalidEmail: "Correo electrónico inválido",
    weakPassword: "La contraseña es demasiado débil",
    userNotFound: "Usuario no encontrado",
    wrongEmailPassword: "Correo electrónico o contraseña incorrectos",
    fillEmailForReset: "Por favor, introduce tu correo electrónico para restablecer la contraseña",
    sendNewPasswordTo: "Enviar nueva contraseña a",
    emailSent: "Correo electrónico enviado",
    tryFree: "Prueba gratis",
    repeatPassword: "Repetir contraseña",
    password: "Contraseña",
    register: "Registrarse",
    haveAccount: "¿Ya tienes una cuenta?",
    loginHere: "Inicia sesión aquí",
    login: "Iniciar sesión",
    forgot: "¿Olvidaste tu contraseña?",
    next: "Siguiente",
    firstName: "Nombre",
    lastName: "Apellido",
    phoneNumber: "Número de teléfono",
    phoneNumberRequired: "Se requiere número de teléfono",
    firstNameRequired: "Se requiere nombre",
    lastNameRequired: "Se requiere apellido",
    additionalInfo: "Información adicional",
  },
  systemsSettings: {
    selectLanguage: "Seleccionar Idioma",
    languageDescription: "Selecciona el idioma para el sistema, tanto para el panel de control como para el sitio de reservas.",
    language: "Idioma",
    timeFormat: "Formato de Tiempo",
    timeFormatDescription: "Selecciona el formato de tiempo para el sistema, tanto para el panel de control como para el sitio de reservas.",
    timeZone: "Zona de Tiempo",
    timeZoneDescription: "Selecciona la zona de tiempo para el sistema. Contacta con nosotros para cambiar.",
    defaultCountryCode: "Código de País por Defecto",
    defaultCountryCodeDescription: "Selecciona el código de país por defecto para el sistema. Esto se utilizará para establecer el código de país por defecto en la página de reservas y para determinar el código de país para SMS al personal.",
    currency: "Moneda",
    currencyDescription: "Selecciona la moneda para el sistema. Esto se utilizará para mostrar precios e información financiera.",
  },
  user: {
    updateFailed: "No se pudo actualizar el usuario.",
    smsVerificationError: "No se puede usar la verificación por SMS sin una suscripción.",
    bookingLinkExists: "Este nombre de empresa ya existe. ¿Es tu empresa? Contacta con nosotros.",
    loginError: "Algo salió mal con la conexión. Si este problema persiste, por favor contacta con nosotros.",
    imageUploaded: "La imagen ha sido subida.",
    imageUploadError: "La imagen es demasiado grande o tiene un formato incorrecto. Use JPG o PNG.",
  },
  hideInBooking: "Ocultar en reserva",
  discount: "Descuento",
  newPrice: "Nuevo precio",
  assignToAllStaff: "Asignar a todos los empleados",
  appointmentSummary: {
    fromOnlineBooking: "Desde reserva online",
    assignStaff: "Asignar personal",
    yourNotes: "Sus notas",
  },
  appointmentMenu: {
    moveAppointmentMessage: "Haz clic en un tiempo en el calendario para mover la cita.",
    confirmDeleteAppointment: "¿Estás seguro de que deseas eliminar esta cita?",
    deleteAppointmentHeader: "Delete Appointment",
    appointmentDeleted: "La cita ha sido eliminada.",
    edit: "Editar",
    delete: "Eliminar",
    findInCalendar: "Buscar en Calendario",
  },
  customerModalMessages: {
    customerUpdated: "Cliente actualizado.",
    customerUpdateFailed: "No se pudo actualizar el cliente.",
  },
  multipleServices: "Servicios múltiples",
  emailAddress: "Correo Electrónico",
  commitChart: {
    currentYear: "Ganado {year}",
    previousYear: "Ganado {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Mover cita a",
  },
  appointments: {
    creatingAppointment: "Creando cita...",
    appointmentCreated: "La cita ha sido creada!",
    overlappingAppointment: "La reserva se superpone con otra cita. ¿Quieres crear la cita de todos modos?",
    alreadyBooked: "La cita que estás intentando reservar ya ha sido reservada por otra persona.",
    overlappingWithNext: "La cita que estás intentando reservar se superpone con la siguiente cita. Por favor, elige un tiempo más corto.",
  },
  customerName: "Nombre del Cliente",
  createAppointmentMessage: {
    invalidTimeFormat: "Formato de tiempo inválido",
    creationCancelled: "Creación cancelada.",
    enterCustomerName: "Debes introducir un nombre de cliente.",
    selectService: "Debes seleccionar un servicio.",
    editEndTimeWithoutStart: "Intentado editar el tiempo de finalización sin establecer el tiempo de inicio.",
    createNewCustomer: "Crear nuevo cliente para {name}",
    appointmentCreationFailed: "No se pudo crear la cita. Por favor, contacta con el servicio al cliente.",
    overlappingAppointment: {
      title: "Cita superpuesta",
      message: "Este horario se superpone con otra cita. ¿Desea crearla de todos modos?",
    },
  },
  bookingPageCustomerSettings: {
    auditLogs: {
      changedAssignmentType: "Changed setting 'Automatic  assignment new customer' to {setting}",
      changedCustomerAccountCreation: "Changed setting 'Create/Assign customer account automatically upon booking' to {setting}",
    },
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Cambiado configuración 'Booking-ID' a {link}",
      changedCommonLink: "Cambiado configuración 'Common-ID' a {link}",
      changedLocationName: "Cambiado configuración 'Nombre de la ubicación' a {name}",
    },
    validation: {
      bookingLinkTooShort: "El enlace de reserva debe contener al menos 3 caracteres",
    },
  },
  mobileHeader: {
    home: "Inicio",
    calendar: "Calendario",
    customers: "Clientes",
    new: "Nuevo",
    orders: "Reservas",
    settings: "Configuración",
    log: "Log",
    statistics: "Estadísticas",
    overview: "Resumen",
    booking: "Reserva",
    staff: "Personal",
  },
  password: "Contraseña",
  dialogMessages: {
    confirm: "Confirmar",
    cancel: "Cancelar",
    no: "No",
  },
  $vuetify: {
    badge: "Placa",
    close: "Cerrar",
    dataIterator: {
      noResultsText: "Ningún elemento coincide con la búsqueda",
      loadingText: "Cargando...",
    },
    dataTable: {
      itemsPerPageText: "Filas por página:",
      ariaLabel: {
        sortDescending: "Orden descendente.",
        sortAscending: "Orden ascendente.",
        sortNone: "Sin ordenar.",
        activateNone: "Pulse para quitar orden.",
        activateDescending: "Pulse para ordenar de forma descendente.",
        activateAscending: "Pulse para ordenar de forma ascendente.",
      },
      sortBy: "Ordenado por",
    },
    dataFooter: {
      itemsPerPageText: "Elementos por página:",
      itemsPerPageAll: "Todos",
      nextPage: "Página siguiente",
      prevPage: "Página anterior",
      firstPage: "Primera página",
      lastPage: "Última página",
      pageText: "{0}-{1} de {2}",
    },
    datePicker: {
      itemsSelected: "{0} seleccionados",
      nextMonthAriaLabel: "Próximo mes",
      nextYearAriaLabel: "Próximo año",
      prevMonthAriaLabel: "Mes anterior",
      prevYearAriaLabel: "Año anterior",
    },
    noDataText: "No hay datos disponibles",
    carousel: {
      prev: "Visual anterior",
      next: "Visual siguiente",
      ariaLabel: {
        delimiter: "Visual {0} de {1}",
      },
    },
    calendar: {
      moreEvents: "{0} más",
    },
    fileInput: {
      counter: "{0} archivos",
      counterSize: "{0} archivos ({1} en total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Navegación de paginación",
        next: "Página siguiente",
        previous: "Página anterior",
        page: "Ir a la página {0}",
        currentPage: "Página actual, página {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Puntuación {0} de {1}",
      },
    },
    statistics: {
      serviceName: "Nombre del Servicio",
      bookings: "Reservas",
      customers: "Clientes",
    },
  },
  serviceCategories: {
    nameRequired: "El nombre es obligatorio",
    confirmDelete: "¿Estás seguro de que deseas eliminar esta categoría?",
    deleteHeader: "Eliminar servicio",
    editCategory: "Editar categoría",
    name: "Nombre",
    cancel: "Cancelar",
    save: "Guardar",
    categoryName: "Nombre de la categoría",
    create: "Crear",
    sort: "Ordenar",
    table: {
      name: "Nombre",
      sort: "Ordenar",
    },
    validation: {
      nameRequired: "El nombre de la categoría es requerido",
    },
    createCategory: "Crear categoría",
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Cambiado el texto de la información.",
      changedShowInformationBox: "Cambiado la configuración 'Mostrar información' a {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice: "Cambiado la configuración 'Mostrar precio de servicio' a {setting}",
      changedMultipleServices: "Cambiado la configuración 'Selección múltiple de servicios' a {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices: "Cambiado la configuración 'Campo de cliente' a {field}",
    },
  },
  routes: {
    login: "Iniciar sesión",
    login_: "Iniciar sesión", // Note: "login " has a trailing space; adjusted key to "login_"
    Settings: "Configuración",
    CompanyInfo: "Información de la empresa",
    SystemSettings: "Configuración del sistema",
    Services: "Servicios",
    Categories: "Categorías",
    Resources: "Recursos",
    Subscription: "Suscripción",
    Accounts: "Cuentas",
    BookingAlerts: "Alertas de reservas",
    CustomerInformation: "Información del cliente",
    Booking: "Reserva",
    BookingSide: "Página de reserva",
    Options: "Opciones",
    Staff: "Personal",
    CalendarSettings: "Configuración del calendario",
    Newsletter: "Boletín",
    AuditLog: "Registro de auditoría",
    ExtraServices: "Servicios extra",
    BookingInformation: "Información de la reserva",
    CustomerAccount: "Cuenta de cliente",
    Kunder: "Clientes",
    Kunde: "Kunde",
    Kalender: "Calendario",
    KalenderBestilling: "Reserva en calendario",
    Hjem: "Inicio",
    oAuthCallBack: "OAuth de vuelta",
    Onboarding: "Onboarding",
    muscleGroupSelector: "Selector de grupo muscular",
    Statistikk: "Estadísticas",
    Eksporter: "Exportar",
    Bestillinger: "Reservas",
    Bestilling: "Reserva",
    Faktura: "Factura",
    Betalinger: "Pagos",
    Gavekort: "Tarjetas de regalo",
    Tjenester: "Servicios",
    Hendelser: "Eventos",
    Logg: "Log",
    AdminLogg: "Log de administrador",
    Debug: "Depuración",
    Register: "Registrar",
    StaffWorkingHours: "Horario Laboral",
    StaffGoogleCalendar: "Google Calendar",
    StaffFriday: "Días de Vacaciones",
    StaffServices: "Servicios",
    StaffInformation: "Información del Personal",
    StaffOverview: "Resumen del Personal",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Ocultar días fuera de horario laboral",
    showServiceNameInEvent: "Mostrar nombre de servicio en evento",
    showTimeInEvent: "Mostrar tiempo en evento",
    showStaffInEvent: "Mostrar personal en evento",
  },
  statistics: {
    serviceName: "Nombre del Servicio",
    bookings: "Reservas",
    customers: "Clientes",
    allMonths: "Todos los Meses",
    data: "Datos",
    current: "Actual",
    previous: "Anterior",
    day: "Día",
    earnings: "Ganancias",
    month: "Mes",
    year: "Año",
    showPerStaffMember: "Mostrar por Miembro del Personal",
    appointments: "Citas",
    totalExpenses: "Gastos Totales",
    totalNetProfit: "Beneficio Neto Total",
    commission: "Comisión",
  },
  all: "Todos",

  createService: {
    title: "Crear Servicio",
    editTitle: "Editar Servicio",
    assignToAllStaff: "Asignar a todo el personal",
    assignToAllStaffDescription: "Si está marcado, el servicio será asignado a todo el personal.",
    discount: "Descuento",
    discountDescription: "Si está marcado, el servicio tendrá un descuento.",
    newPrice: "Nuevo Precio",
    newPriceDescription: "El nuevo precio del servicio.",
    hideInBooking: "Ocultar en Reservas",
    hideInBookingDescription: "Ocultar el servicio en la página de reservas.",
    serviceName: "Nombre del Servicio",
    serviceNameDescription: "El nombre del servicio.",
    description: "Descripción",
    descriptionDescription: "La descripción del servicio.",
    price: "Precio",
    priceDescription: "El precio del servicio.",
    duration: "Duración",
    durationDescription: "La duración del servicio en minutos.",
    durationForStaff: "Duración para Personal",
    durationForStaffDescription: "La duración en minutos para que el personal complete el servicio.",
    durationForCustomer: "Duración para Cliente",
    durationForCustomerDescription: "La duración en minutos mostrada a los clientes.",
    color: "Color",
    colorDescription: "El color del servicio en el calendario.",
    showCustomColor: "Mostrar color personalizado",
    showCustomColorDescription: "Si está marcado, el servicio tendrá un color personalizado.",
    customColor: "Color personalizado",
    customColorDescription: "El color personalizado del servicio.",
    discountPrice: "Precio con descuento",
    discountPriceDescription: "El precio con descuento del servicio.",
    discountPriceChange: "Precio con descuento cambiado",
    discountPriceChangeDescription: "El precio con descuento del servicio ha sido cambiado.",
    mustPayOnline: "Debe pagar en línea",
    mustPayOnlineDescription: "Si está marcado, los clientes deben pagar en línea al reservar este servicio.",
    payOnlinePrice: "Precio de pago en línea",
    payOnlinePriceDescription: "El monto que debe pagarse en línea al reservar este servicio.",
  },
  validation: {
    required: "Este campo es obligatorio",
    min: "El valor debe ser al menos {value}",
    max: "El valor debe ser menor que {value}",
    number: "Debe ser un número válido",
    email: "Debe ser un correo electrónico válido",
    phone: "Debe ser un número de teléfono válido",
    length: "Debe tener menos de {value} caracteres",
    pattern: "Formato inválido",
  },
  form: {
    fields: {
      name: "Nombre",
      email: "Correo electrónico",
      phone: "Teléfono",
      address: "Dirección",
      city: "Ciudad",
      postalCode: "Código Postal",
      message: "Mensaje",
      password: "Contraseña",
      confirmPassword: "Confirmar Contraseña",
      birthdate: "Fecha de nacimiento",
      ssn: "Número de Seguro Social",
      gender: "Género",
      title: "Título",
      description: "Descripción",
      price: "Precio",
      duration: "Duración",
      color: "Color",
      discount: "Descuento",
      onlinePayment: "Pago en línea",
    },
    descriptions: {
      name: "Ingrese su nombre completo",
      email: "Ingrese su correo electrónico",
      phone: "Ingrese su número de teléfono",
      address: "Ingrese su dirección",
      city: "Ingrese su ciudad",
      postalCode: "Ingrese su código postal",
      message: "Ingrese su mensaje",
      password: "Ingrese su contraseña",
      confirmPassword: "Confirme su contraseña",
      birthdate: "Ingrese su fecha de nacimiento",
      ssn: "Ingrese su número de seguro social",
      gender: "Seleccione su género",
      title: "Ingrese título",
      description: "Ingrese descripción",
      price: "Ingrese precio",
      duration: "Ingrese duración",
      color: "Seleccione color",
      discount: "Ingrese descuento",
      onlinePayment: "Configure pago en línea",
    },
  },
  writeHere: "Escribe aquí...",
  registerFirebase: {
    email: "Email",
    password: "Contraseña",
    passwordAgain: "Contraseña de nuevo",
    register: "Registrar",
    emailAlreadyInUse: "Email ya en uso",
    invalidEmail: "Email inválido",
    passwordTooWeak: "Contraseña demasiado débil",
    emailAndPasswordRequired: "Email y contraseña son requeridos",
    passwordsMustMatch: "Las contraseñas deben coincidir",
  },
  placeHolder: {
    typeToSearch: "Escribe para buscar",
  },
  selectDate: "Seleccionar fecha",
  dashboard: {
    hints: {
      title: "Sugerencias del Panel",
      servicesWithoutStaff: "Servicios sin personal asignado",
      assignStaff: "Asignar Personal",
      serviceUnavailableExplanation: "Este servicio no está disponible para reservar porque ningún miembro del personal está configurado para realizarlo.",
      serviceAssignmentSteps: "Para hacer que este servicio esté disponible para reservar, sigue estos pasos:",
      step1Settings: "Ir a Configuración",
      step2StaffMembers: "Seleccionar Miembros del Personal",
      step3SelectStaff: "Elegir un miembro del personal para editar",
      step4Services: "Ir a la pestaña de Servicios",
      step5EnableService: "Habilitar este servicio para el miembro del personal",
      goToSettings: "Ir a Configuración",
    },
  },
  accountRole: {
    title: "Roles de Cuenta",
    editRole: "Editar Rol",
    createRole: "Crear Nuevo Rol",
    newRole: "Nuevo Rol",
    actions: "Acciones",
    permissionLevels: {
      none: "Ninguno",
      read: "Lectura",
      readWrite: "Lectura/Escritura",
    },
    owner: "Propietario",
    ownerRole: "Rol de Propietario",
  },
  receiveWhatsAppOnAppointment: "Recibir WhatsApp sobre la Cita",
  doYouWantToReceiveWhatsAppOnNewAppointment: "¿Deseas recibir un WhatsApp cuando se reciba una nueva cita?",
  whichWhatsAppNumberToReceive: "¿A qué número de WhatsApp se debe enviar el mensaje al reservar?",
  staffMemberGoogleCalendar: {
    syncGoogleCalendar: "Sincronizar Google Calendar con Availify",
    syncGoogleCalendarWithAvailify: "Sincronizar Google Calendar con Availify",
    benefit1: "Recibe reservas directamente en tus calendarios de Google y Apple.",
    benefit2: "Los eventos en calendarios Google/Apple se añadirán automáticamente a Availify y se bloquearán para reservas.",
    connectGoogleAccount: "Conectar a una cuenta de Google",
    clickButtonToLogin: "Haz clic en el botón para iniciar sesión",
    addEventsToAppleCalendar: "Añadir eventos de Google Calendar al Calendario de Apple",
    useAppleDevices: "¿Usas iPhone/iPad? Sincroniza Google con el Calendario de Apple.",
    addGoogleCalendarToApp: "Añadir Google Calendar a la aplicación",
    start: "Iniciar",
    continue: "Continuar",
    step1: "Paso 1",
    step2: "Paso 2",
    step3: "Paso 3",
    removeSync: "Eliminar Sincronización",
    confirmRemoveSync: "¿Estás seguro de que deseas eliminar la sincronización?",
    setAsDefaultCalendar: "Establecer como calendario predeterminado",
    defaultStep1: "Paso predeterminado 1",
    defaultStep2: "Paso predeterminado 2",
    done: "Hecho",
    synchronizedWithGoogle: "Sincronizado con Google",
    syncBenefit1: "Beneficio de sincronización 1",
    syncBenefit2: "Beneficio de sincronización 2",
    syncBenefit3: "Beneficio de sincronización 3",
    syncBenefit4: "Beneficio de sincronización 4",
    howToConnectAppleCalendar: "Cómo conectar con el Calendario de Apple",
  },
  invoiceSettings: {
    kidNumber: {
      title: "Número KID",
      lengthTitle: "Longitud KID (2-25)",
      lengthDescription: "Establecer la longitud del número KID",
      modTitle: "Dígito de Control KID",
      modDescription: "Establecer el dígito de control para el número KID",
      description: "Configurar los ajustes del número KID para facturas",
      invalidKidLength: "Longitud de número KID inválida",
      useKid: "Usar KID",
      kidLength: "Longitud KID (2-25)",
      kidMod: "Dígito de Control KID",
      agreementNote: "Recuerda configurar un acuerdo OCR antes de usar esta función. Puedes encontrar el formulario en el sitio web de Nets o hacerlo electrónicamente a través del sitio web de tu banco.",
    },
    tax: {
      title: "Impuesto al Valor Agregado (%)",
      description: "Establecer el porcentaje de IVA predeterminado para tus facturas",
    },
    taxNumber: {
      title: "Número de Identificación Fiscal",
      description: "Establecer el número de identificación fiscal para tus facturas. Puede ser número de organización o número de identificación.",
    },
    bankAccount: {
      title: "Cuenta Bancaria",
      description: "Establecer la cuenta bancaria para tus facturas",
    },
    dueDate: {
      title: "Días Hasta Fecha de Vencimiento",
      description: "Establecer cuántos días después de la fecha de la factura se debe establecer la fecha de vencimiento",
    },
    tabs: {
      general: "General",
      kid: "KID",
      dian: "DIAN",
    },
    dian: {
      title: "Configuración DIAN",
      description: "Configurar tus ajustes de DIAN (Dirección de Impuestos y Aduanas Nacionales)",
      comingSoon: "Integración DIAN próximamente",
    },
  },
  invoiceList: {
    invoiceNumber: "No. de Factura",
    orderNumber: "No. de Orden",
    amount: "Monto (USD)",
    customer: "Cliente",
    invoiceDate: "Fecha de Factura",
    dueDate: "Fecha de Vencimiento",
    status: "Estado",
    download: "Descargar",
    send: "Enviar",
    kid: "KID",
    statuses: {
      new: "Nueva",
      sent: "Enviada",
      paid: "Pagada",
    },
    noInvoices: "No hay facturas",
    loading: "Cargando... Por favor espera...",
    couldNotFetch: "No se pudieron obtener las facturas",
    search: "Buscar",
    all: "Todas",
  },
  InvoiceCreator: {
    title: "Crear Factura",
    editTitle: "Editar Factura",
    close: "Cerrar",
    postalCodeRequired: "El código postal es requerido",
    cityRequired: "La ciudad es requerida",
    addressRequired: "La dirección es requerida",
    amountRules: {
      required: "El monto es requerido",
      positive: "El monto debe ser un valor positivo",
    },
    priceRules: {
      required: "El monto es requerido",
      positive: "El monto debe tener un valor positivo",
    },
    discountRules: {
      maxValue: "El descuento no puede ser mayor que el precio",
    },
    createInvoice: "Crear Factura",
    createAndSend: "Crear y Enviar",
    cancel: "Cancelar",
    markAsPaidCheckbox: "Marcar cita como 'Factura Creada'",
    missingOrgNumber: "Por favor, completa tu número de organización en Configuración.",
    missingBankAccount: "Por favor, completa tu número de cuenta bancaria en Configuración.",
    invoiceCreated: "¡Factura creada!",
    couldNotCreate: "No se pudo crear la factura",
    customer: {
      name: "Nombre del Cliente",
      address: "Dirección del Cliente",
      postalCode: "Código Postal",
      city: "Ciudad",
      title: "Cliente",
    },
    invoice: {
      number: "No. de Factura",
      date: "Fecha de Factura",
      dueDate: "Fecha de Vencimiento",
      description: "Descripción",
      price: "Precio",
      amount: "Monto",
      discount: "Descuento",
      vat: "IVA (%)",
      totalAmount: "Monto total a pagar (incl. IVA)",
      paymentInfo: "Información de Pago",
      title: "Factura",
      amountToPay: "Monto a pagar",
    },
    company: {
      orgNumber: "No. de Org.",
      phone: "Teléfono",
      email: "Email",
      bankAccount: "Cuenta Bancaria",
      missingBankAccount: "FALTA CUENTA BANCARIA",
    },
    invoiceNumber: "No. de Factura",
    invoiceDate: "Fecha de Factura",
    dueDate: "Fecha de Vencimiento",
    totalToPay: "Monto a pagar",
    bankAccount: "Cuenta Bancaria",
    today: "[Hoy]",
    thirtyDaysFromNow: "[En 30 días]",
    organization: {
      number: "No. de Org.",
      missing: "Falta número de organización",
      phone: "Teléfono",
      email: "Email",
    },
  },
  entities: {
    account: "Cuenta",
    appointment: "Cita",
    service: "Servicio",
    staff: "Miembro del personal",
    extraService: "Servicio extra",
    giftCard: "Tarjeta de regalo",
    image: "Imagen",
    customer: "Cliente",
    accountRole: "Rol de cuenta",
    category: "Categoría",
    resource: "Recurso",
    option: "Opción",
    notification: "Notificación",
    googleCalendar: "Google Calendar",
    event: "Evento",
    debugLog: "Registro de depuración",
    serviceCategory: "Categoría de servicio",
    statistics: "Estadísticas",
    subscription: "Suscripción",
    expense: "Gasto",
    finance: "Finanzas",
    inventoryItem: "Producto",
    order: "Pedido",
    orderItem: "Artículo de pedido",
    ledgerEntry: "Entrada de contabilidad",
    oneTimeExpense: "Gasto único",
    recurringExpense: "Gasto recurrente",
    expenseCategory: "Categoría de gasto",
    stock: "Inventario",
    orders: "Pedidos",
    inventoryItemCategory: "Categoría de Producto",
    cashRegister: "Caja",
  },
  actions: {
    create: "Crear",
    edit: "Editar",
    delete: "Eliminar",
    upload: "Subir",
    addStock: "Añadir Reservas",
  },
  actionsPastTense: {
    create: "Creado",
    edit: "Editado",
    delete: "Eliminado",
    upload: "Subido",
  },
  appointmentList: {
    createdByCustomer: "Creado por Cliente",
    status: "Estado",
    delete_appointments_message: "¿Estás seguro de que deseas eliminar las citas seleccionadas?",
    delete_appointments_header: "Eliminar Citas",

    delete_appointments_confirmationMessage: "Las citas seleccionadas se han eliminado correctamente",
  },
  selectStaffMember: "Seleccionar Personal",
  expenses: {
    date: "Fecha",
    startDate: "Fecha de inicio",
    endDate: "Fecha de finalización",
  },
  expenseCategory: {
    rent: "Alquiler",
    utilities: "Servicios",
    inventory: "Inventario",
    commission: "Comisión",
    salaries: "Salarios",
    other: "Otro",
  },
  expenseCategoryAsAction: {
    inventory: "Comprar productos",
    salaries: "Pagar salario",
    rent: "Pagar alquiler",
    utilities: "Comprar servicios",
    commission: "Pagar comisión",
    other: "Otro",
  },
  genericEntityDialog: {
    deleteConfirmation: "¿Estás seguro de que deseas eliminar el {entity} seleccionado?",
    deleteHeader: "Eliminar {entity}",
    deleteSuccess: "{entity} eliminado correctamente",
    itemCreated: "{entity} creado correctamente",
    itemUpdated: "{entity} actualizado correctamente",
    itemDeleted: "{entity} eliminado correctamente",
    confirmDelete: "¿Estás seguro de que deseas eliminar el {entity} seleccionado?",
  },
  orderStatus: {
    Completed: "Completado",
    Pending: "Pendiente",
    Cancelled: "Cancelado",
  },
  orders: {
    createOrder: "Crear Pedido",
    viewOrder: "Ver Pedido",
    totalAmount: "Monto Total",
    orderDate: "Fecha del Pedido",
    status: "Estado",
    actions: "Acciones",
    orderNumber: "Número de Pedido",
    title: "Pedidos",
    staffMember: "Miembro del Personal",
    notSet: "No Establecido",
    cancel: "Cancelar",
    create: "Crear",
    cartSummary: "Resumen del Carrito",
    uncategorized: "Sin Categorizar",
    quantity: "Cantidad",
    price: "Precio",
    validation: {
      selectCustomer: "Por favor seleccione un cliente",
      addItems: "Por favor agregue artículos al pedido",
      amountGreaterThanZero: "El monto debe ser mayor que 0",
      amountLessThanTotal: "El monto debe ser menor que el total",
    },
    orderDetails: "Detalles del Pedido",
    items: "Artículos",
    item: "Artículo",
    total: "Total",
    partialPayment: "Pago Parcial",
    partialAmount: "Monto Parcial",
    partialAmountHint: "Ingrese el monto del pago parcial",
    anonymousCustomer: "Cliente Anónimo",
    unknownCustomer: "Cliente Desconocido",
    noOrdersToday: "No hay pedidos hoy",
    noPurchasesToday: "No hay compras hoy",
    noProducts: {
      title: "Sin Productos",
      message: "No hay productos disponibles",
      action: "Agregar Productos",
    },
    searchProducts: "Buscar productos...",
    allProducts: "Todos los Productos",
    inStock: "en stock",
    outOfStock: "Agotado",
    shoppingCart: "Carrito de Compras",
    cartEmpty: "Tu carrito está vacío",
    addItemsToStart: "Agrega artículos del catálogo para comenzar",
    subtotal: "Subtotal",
    steps: {
      customerAndStaff: {
        title: "Cliente y Personal",
        subtitle: "Seleccionar cliente y miembro del personal",
      },
      items: {
        title: "Artículos",
        subtitle: "Seleccionar artículos para el pedido",
      },
      payment: {
        title: "Pago",
        subtitle: "Elegir método de pago",
      },
    },
    sections: {
      customerInfo: "Información del Cliente",
      staffInfo: "Miembro del Personal",
      orderDate: "Fecha del Pedido",
      orderDetails: "Detalles del Pedido",
      financialDetails: "Detalles Financieros",
    },
    navigation: {
      next: "Siguiente",
      back: "Atrás",
    },
    labels: {
      customer: "Cliente:",
      orderDate: "Fecha del Pedido:",
      totalAmount: "Monto Total:",
      partialPayment: "Pago Parcial:",
      remainingBalance: "Saldo Restante:",
      paymentStatus: "Estado del Pago:",
    },
    paymentStatus: {
      unpaid: "Sin Pagar",
      paidInFull: "Pagado Completamente",
    },
    maximumAmount: "Monto máximo:",
  },
  paymentMethod: {
    Cash: "Efectivo",
    Card: "Tarjeta",
    Transfer: "Transferencia",
    Other: "Otro",
    title: "Método de Pago",
  },
  ledgerEntryFormDTO: {
    total: "Total",
    paid: "Pagado",
    remaining: "Restante",
  },
  ledgerEntry: {
    charge: "Cargo",
    payment: "Pago",
    refund: "Reembolso",
    type: "Tipo",
    manual: "Manual",
  },
  ledgerEntryType: {
    charge: "Cargo",
    payment: "Pago",
    refund: "Reembolso",
    adjustment: "Ajuste",
  },
  ledgerEntryHeaders: {
    type: "Tipo",
    source: "Origen",
  },
  staffMemberManagement: {
    servicesPerformed: "Servicios Realizados",
    paymentsReceived: "Pagos Recibidos",
    productsSold: "Productos Vendidos",
  },
  reoccuringExpense: {
    recurrenceInterval: "Intervalo de Recurrencia",
    intervals: {
      daily: "Diario",
      monthly: "Mensual",
      quarterly: "Trimestral",
      yearly: "Anual",
    },
  },
  headers: {
    buyStock: "Comprar productos",
  },
  export: {
    dailyFinancialReport: "Informe Financiero Diario",
    downloadDailyReport: "Descargar Informe Diario",
  },
  itemTooltips: {
    clickForDetails: "Haga clic para ver detalles",
  },
  customerStatistics: {
    appointments: {
      thisMonth: "Citas este mes",
      total: "Total de citas",
    },
    moneySpent: {
      title: "Dinero gastado",
      thisMonth: "Dinero gastado este mes",
      total: "Total de dinero gastado",
    },
    ranking: {
      title: "Clasificación de clientes",
      thisYear: "Este año",
      overall: "General",
      ofCustomers: "de clientes",
    },
    products: {
      thisMonth: "Productos este mes",
      total: "Total de productos",
    },
    charts: {
      mostUsedServices: "Servicios más utilizados",
      mostPurchasedProducts: "Productos más comprados",
      spendingHistory: "Historial de gastos",
    },
  },
  appointmentView: {
    timeFormat: {
      am: "AM",
      pm: "PM"
    },
    customerDetails: "Detalles del Cliente",
    appointmentDetails: "Detalles de la Cita",
    financialDetails: "Detalles Financieros"
  },
  moneyAccountOverview: {
    totalBalance: "Saldo Total",
    totalIncomingToday: "Ingresos de Hoy",
    totalOutgoingToday: "Gastos de Hoy",
    totalNetChangeToday: "Cambio Neto de Hoy",
    ofDailyTotal: "del total diario",
    changeFromTotal: "cambio del total",
    transactionDistribution: "Distribución de Transacciones",
    incoming: "Entrante",
    outgoing: "Saliente",
    addMoney: "Añadir Dinero",
    addMoneyDescription: "Añadir dinero manualmente a la caja registradora",
    manualEntry: "Entrada Manual",
    amount: "Cantidad",
    description: "Descripción"
  },
  addStockFormDTO: {
    totalPrice: "Precio Total",
    unitPrice: "Precio Unitario",
  },
  commissionSettlement: {
    title: "Liquidación de comisiones del personal",
    services: "Comisión por servicios",
    products: "Comisión por productos",
    total: "Comisión total",
    payCommission: "Pagar comisión",
    paid: "Pagado",
    noEligibleStaff: "No hay personal elegible con comisión para esta fecha",
    unassignedAppointmentsWarning: "Hay citas sin personal asignado. Por favor, asigne personal antes de continuar:",
    assign: "Asignar",
    assignStaffTitle: "Asignar miembro del personal",
    selectStaff: "Seleccionar miembro del personal",
    selectMoneyAccountError: "Por favor, seleccione una cuenta de dinero antes de pagar la comisión",
    commissionPayment: "Pago de comisión",
    commissionPaidSuccess: "Comisión pagada con éxito a",
    commissionPaymentError: "Error al pagar la comisión. Por favor, inténtelo de nuevo.",
    staffAssignedSuccess: "Miembro del personal asignado con éxito a la cita",
    staffAssignmentError: "Error al asignar miembro del personal. Por favor, inténtelo de nuevo."
  },
};

export default es;
