<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "UpdateDialog",
})
export default class UpdateDialog extends Vue {
  @Prop({ required: true }) readonly show!: boolean;
  @Prop({ required: true }) readonly updateInfo!: { title: string; html: string };
}
</script>

<template>
  <v-dialog :value="show" @input="$emit('update:show', $event)" :max-width="600">
    <v-card>
      <v-card-title> {{ $t("update.title") }} </v-card-title>
      <v-card-text>
        <div v-html="updateInfo.html"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="$emit('update:show', false)">
          {{ $t("buttons.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template> 