import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { StatisticsService } from "@/services/api/StatisticsService";
import {
  INewCustomerStatsDTO,
  ICustomerStatDTO,
  IServiceStatDTO,
  IYearlyEarningsComparisonDTO,
  IStaffStatisticsAppointmentDTO,
  IDailyProfitDTO,
  IMonthlyProfitDTO,
  IYearlyProfitDTO,
  IStatisticsController,
} from "@shared/types";
import store from "@/store/store";

export interface IEarnedThisAndPreviousYear {
  current: Array<number>;
  previous: Array<number>;
}

@Module({ generateMutationSetters: true })
class StatisticsModule extends VuexModule {
  private statisticsService = StatisticsService.getInstance();

  // State
  moneyMade: number | null = null;
  appointmentsDone: number | null = null;
  serviceStats: IServiceStatDTO[] = [];
  customerStats: ICustomerStatDTO[] = [];
  earnedThisAndPreviousYear: IEarnedThisAndPreviousYear | null = null;
  splitByDay: IDailyProfitDTO | null = null;
  staffEarnings: any[] | null = null;
  dailyStaffEarnings: any[] | null = null;
  staffAppointments: any[] | null = null;
  dailyStaffAppointments: any[] | null = null;
  appointmentsByMonth: IYearlyEarningsComparisonDTO | null = null;
  appointmentsByDay: number[] | null = null;
  monthlyProfit: IMonthlyProfitDTO | null = null;
  yearlyProfit: IYearlyProfitDTO | null = null;
  allYearProfit: Record<number, IYearlyProfitDTO> | null = null;

  @Action
  async getNumberOfAppointments(payload: {
    day?: number;
    month: number;
    year: number;
    staffMemberId?: number;
  }): Promise<number> {
    const count = await this.statisticsService.getNumberOfAppointments(
      payload.day || null,
      payload.month,
      payload.year,
      payload.staffMemberId
    );
    this.appointmentsDone = count;
    return count;
  }

  @Action
  async downloadDailyFinancialReport(
    payload: Partial<typeof IStatisticsController.IDownloadDailyFinancialReportQuery>
  ): Promise<Blob> {
    const blob = await this.statisticsService.downloadDailyFinancialReport(payload);
    return blob;
  }

  @Action
  async getMoneyMadeForDay(payload: { day: number; month: number; year: number }): Promise<number> {
    const money = await this.statisticsService.getMoneyMadeForDay(payload.year, payload.month, payload.day);
    this.moneyMade = money;
    return money;
  }

  @Action
  async getMoneyMadeForYear(year: number): Promise<number> {
    const money = await this.statisticsService.getMoneyMadeForYear(year);
    this.moneyMade = money;
    return money;
  }

  @Action
  async getEarnedThisAndPreviousYear(payload: {
    year: number;
    staffMemberId?: number;
  }): Promise<IYearlyEarningsComparisonDTO> {
    const money = await this.statisticsService.getMoneyMadeSplitByMonth(payload.year, payload.staffMemberId);
    this.earnedThisAndPreviousYear = money;
    return money;
  }

  @Action
  async getMoneyMade(payload: { month: number; staffMemberId?: number; year?: number }): Promise<number> {
    const money = await this.statisticsService.getMoneyMade(payload.month, payload.staffMemberId, payload.year);
    this.moneyMade = money;
    return money;
  }

  @Action
  async getMoneyMadeForMonth(payload: { month: number; year: number; staffMemberId?: number }): Promise<number> {
    const money = await this.statisticsService.getMoneyMadeForMonth(payload.year, payload.month, payload.staffMemberId);
    this.moneyMade = money;
    return money;
  }

  @Action
  async getSplitByDay(payload: { year: number; month: number; staffMemberId?: number }): Promise<IDailyProfitDTO> {
    const data = await this.statisticsService.getMoneyMadeSplitByDay(
      payload.year,
      payload.month,
      payload.staffMemberId
    );
    this.splitByDay = data;
    return data;
  }

  @Action
  async getEarnedByStaff(payload: { year: number }): Promise<any[]> {
    const data = await this.statisticsService.getMoneyMadeSplitByMonthAndStaff(payload.year);
    this.staffEarnings = data;
    return data;
  }

  @Action
  async getDailyEarnedByStaff(payload: { year: number; month: number }): Promise<any[]> {
    const data = await this.statisticsService.getMoneyMadeSplitByDayAndStaff(payload.year, payload.month);
    this.dailyStaffEarnings = data;
    return data;
  }

  @Action
  async getAppointmentsByStaff(payload: { year: number }): Promise<any[]> {
    const data = await this.statisticsService.getAppointmentsSplitByMonthAndStaff(payload.year);
    this.staffAppointments = data;
    return data;
  }

  @Action
  async getDailyAppointmentsByStaff(payload: { year: number; month: number }): Promise<any[]> {
    const data = await this.statisticsService.getAppointmentsSplitByDayAndStaff(payload.year, payload.month);
    this.dailyStaffAppointments = data;
    return data;
  }

  @Action
  async getAppointmentsByMonth(payload?: {
    staffMemberId?: number;
    year?: number;
  }): Promise<IYearlyEarningsComparisonDTO> {
    const currentDate = new Date();
    const yearToUse = payload?.year || currentDate.getFullYear();
    const data = await this.statisticsService.getAppointmentsSplitByMonth(yearToUse, payload?.staffMemberId);
    this.appointmentsByMonth = data;
    return data;
  }

  @Action
  async getAppointmentsByDay(payload: { staffMemberId?: number; year: number; month: number }): Promise<number[]> {
    const data = await this.statisticsService.getAppointmentsSplitByDay(
      payload.year,
      payload.month,
      payload.staffMemberId
    );
    this.appointmentsByDay = data;
    return data;
  }

  @Action
  async newCustomers(year: number): Promise<INewCustomerStatsDTO> {
    return this.statisticsService.newCustomers(year);
  }

  @Action
  async getCustomerBookedStats(): Promise<ICustomerStatDTO[]> {
    const stats = await this.statisticsService.getCustomerBookedStats();
    this.customerStats = stats;
    return stats;
  }

  @Action
  async getServicesBookedStats(payload?: {
    year?: number;
    month?: number;
    staffMemberId?: number;
  }): Promise<IServiceStatDTO[]> {
    const stats = await this.statisticsService.getServicesBookedStats(
      payload?.year,
      payload?.month,
      payload?.staffMemberId
    );
    this.serviceStats = stats;
    return stats;
  }

  @Action
  async getMonthlyProfit(payload: { year: number; month: number; staffMemberId?: number }): Promise<IMonthlyProfitDTO> {
    const data = await this.statisticsService.getMonthlyProfit(payload.year, payload.month, payload.staffMemberId);
    this.monthlyProfit = data;
    return data;
  }

  @Action
  async getYearlyProfit(payload: { year: number; staffMemberId?: number }): Promise<IYearlyProfitDTO> {
    const data = await this.statisticsService.getYearlyProfit(payload.year, payload.staffMemberId);
    this.yearlyProfit = data;
    return data;
  }

  @Action
  async getAllYearProfit(payload?: { staffMemberId?: number }): Promise<Record<number, IYearlyProfitDTO>> {
    const data = await this.statisticsService.getAllYearProfit(payload?.staffMemberId);
    this.allYearProfit = data;
    return data;
  }
}

export const statisticsModule = new StatisticsModule({ store, name: "statisticsModule" });
