import Vue from "vue";
import { IPostAuditLogDTO } from "@shared/types";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";

export type AuditLoggerType = Vue & {
  logAudit(message: string, action?: number, type?: number): void;
};

export default Vue.extend({
  methods: {
    logAudit(message: string, action: number = 3, type: number = 4): void {
      const audit: IPostAuditLogDTO = {
        action,
        type,
        comment: "",
        message,
        hideForUser: true,
      };

      auditLogModule.postAuditLog(audit);
    },
  },
});
