import { EventBaseService } from "./Base/EventBaseService";
import { IAdminController, IFirebaseUserDTO, IFirebaseUserRecordDTO } from "@shared/types";

export class AdminService extends EventBaseService implements IAdminController {
  private static instance: AdminService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!AdminService.instance) {
      AdminService.instance = new AdminService();
    }
    return AdminService.instance;
  }

  public async getToken(uid: string): Promise<string> {
    return this.http.sendRequest<string>(IAdminController.GetTokenRoute, {
      params: { uid },
    });
  }

  public async getUsers(): Promise<IFirebaseUserRecordDTO[]> {
    return this.http.sendRequest<IFirebaseUserRecordDTO[]>(IAdminController.GetUsersRoute);
  }
}
