<template>
  <v-card v-if="hasHints" class="mb-4">
    <v-card-title class="subtitle-1">
      {{ $t($ts.dashboard.hints.title) }}
      <v-spacer></v-spacer>
      <v-btn icon small @click="expanded = !expanded">
        <v-icon>{{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <div v-show="expanded">
        <v-divider></v-divider>

        <v-card-text>
          <!-- Services without staff members -->
          <div v-if="servicesWithoutStaff.length > 0" class="mb-3">
            <div class="d-flex align-center mb-2">
              <v-icon color="warning" class="mr-2">mdi-alert</v-icon>
              <span class="font-weight-medium">{{ $t($ts.dashboard.hints.servicesWithoutStaff) }}</span>
            </div>
            <v-list dense>
              <v-list-item
                v-for="service in servicesWithoutStaff"
                :key="service.id"
                @click="showServiceAvailabilityDialog(service)"
                class="pl-4"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon small color="warning">mdi-star-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    {{ service.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn text small color="primary" @click.stop="showServiceAvailabilityDialog(service)">
                    {{ $t($ts.dashboard.hints.assignStaff) }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

    <!-- Service Availability Dialog -->
    <v-dialog v-model="dialogVisible" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ selectedService ? selectedService.title : "" }}
        </v-card-title>
        <v-card-text>
          <p class="mb-3">
            {{ $t($ts.dashboard.hints.serviceUnavailableExplanation) }}
          </p>
          <p class="mb-3">
            {{ $t($ts.dashboard.hints.serviceAssignmentSteps) }}
          </p>
          <ol class="ml-4">
            <li>{{ $t($ts.dashboard.hints.step1Settings) }}</li>
            <li>{{ $t($ts.dashboard.hints.step2StaffMembers) }}</li>
            <li>{{ $t($ts.dashboard.hints.step3SelectStaff) }}</li>
            <li>{{ $t($ts.dashboard.hints.step4Services) }}</li>
            <li>{{ $t($ts.dashboard.hints.step5EnableService) }}</li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogVisible = false">
            {{ $t($ts.buttons.close) }}
          </v-btn>
          <v-btn color="primary" @click="closeDialog">
            {{ $t($ts.buttons.ok) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { IAppointmentTypeDTO, IStaffMemberDTO } from "@shared/types";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "DashboardHints",
})
export default class DashboardHints extends Vue {
  private expanded: boolean = true;
  private dialogVisible: boolean = false;
  private selectedService: IAppointmentTypeDTO | null = null;

  get servicesWithoutStaff(): IAppointmentTypeDTO[] {
    if (
      !serviceModule.appointmentTypes ||
      !Array.isArray(serviceModule.appointmentTypes) ||
      !staffMemberModule.staffMembers
    )
      return [];

    return serviceModule.appointmentTypes.filter((service) => {
      // Skip services that are hidden from booking
      if (service.hideInBooking) return false;

      // Check if any staff member has this service
      return !staffMemberModule.staffMembers.some((staff) =>
        staff.services?.some((staffService) => staffService.id === service.id)
      );
    });
  }

  //23FRH3FPG7BZ2S9E4R523RLR for twilio

  get hasHints(): boolean {
    return this.servicesWithoutStaff.length > 0;
  }

  showServiceAvailabilityDialog(service: IAppointmentTypeDTO): void {
    this.selectedService = service;
    this.dialogVisible = true;
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }
}
</script>

<style scoped>
.v-card__text {
  padding-top: 12px;
}
</style>
