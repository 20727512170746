import { EventBaseService } from "./Base/EventBaseService";
import { IServiceCategoryDTO, IPostServiceCategoryDTO } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class ServiceCategoryService extends EventBaseService {
  private static instance: ServiceCategoryService;
  private controller = "serviceCategory";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!ServiceCategoryService.instance) {
      ServiceCategoryService.instance = new ServiceCategoryService();
    }
    return ServiceCategoryService.instance;
  }

  public async getCategories() {
    return this.http.getAuthenticated<IServiceCategoryDTO[]>(`${this.controller}/current`);
  }

  public async changeOrderForCategory(categoryId: number, order: number) {
    return this.withEventEmission(Feature.ServiceCategory, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/changeOrder/current?categoryId=${categoryId}&order=${order}`)
    );
  }

  public async postCategory(category: IServiceCategoryDTO) {
    return this.withEventEmission(Feature.ServiceCategory, EventType.Added, () =>
      this.http.postAuthenticated<void>(`${this.controller}/current`, category)
    );
  }

  public async editCategory(category: IPostServiceCategoryDTO) {
    return this.withEventEmission(Feature.ServiceCategory, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current`, category)
    );
  }

  public async deleteCategory(id: number) {
    return this.withEventEmission(Feature.ServiceCategory, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/current?id=${id}`)
    );
  }
}
