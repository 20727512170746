<template>
  <v-sheet tile height="54" class="hidden-sm-and-up">
    <v-container>
      <v-row class="px-5">
        <v-col class="px-0" cols="auto">
          <v-btn icon class="ma-1" @click="prev" style="width: 30px">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>

        <v-col
          style="text-align: center; padding-right: 0; padding-left: 0"
          align-self="center"
          cols="auto"
        >
          <div style="margin: auto; min-width: 110px">
            <v-label class="m-auto">{{ monthName }}</v-label>
          </div>
        </v-col>

        <v-col cols="auto" class="px-0">
          <v-btn icon style="width: 30px" class="ma-1" @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <CalendarSettingsDialog
            :showSettings="showSettings"
            @update:showSettings="$emit('update:showSettings', $event)"
            @close="$emit('close')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarSettingsDialog from "./CalendarSettingsDialog.vue";

@Component({
  components: {
    CalendarSettingsDialog,
  },
})
export default class CalendarMobileHeader extends Vue {
  @Prop({ required: true }) monthName!: string;
  @Prop({ default: false }) showSettings!: boolean;

  prev() {
    this.$emit("prev");
  }

  next() {
    this.$emit("next");
  }
}
</script>

<style scoped>
/* Existing styles (if any) */
</style>
