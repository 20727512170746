<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "company-info-field",
})
export default class CompanyInfoField extends Vue {
  @Prop() public currentAdminInfo: Partial<any>;
  @Prop() public label: string;
  @Prop() public description: string;
  @Prop({ default: false }) public isSwitch: boolean;
  @Prop({ default: false }) public full: boolean;
  @Prop({ default: false }) public ignoreMobileLayout: boolean;
  @Prop({ default: false }) public wrappedInDiv!: boolean;

  get showLabelSection() {
    return this.label || this.description;
  }
}
</script>

<template>
  <v-card flat class="mb-0 px-4">
    <v-row align="center" class="flex-nowrap" v-if="!wrappedInDiv">
      <!-- Desktop layout -->
      <template v-if="$vuetify.breakpoint.mdAndUp || ignoreMobileLayout">
        <v-col
          v-if="showLabelSection"
          :class="{
            'flex-grow-1': !isSwitch,
            'col-9': isSwitch,
          }"
          style="padding-left: 0"
        >
          <h4>{{ label }}</h4>
          <p v-if="description" style="white-space: normal">
            {{ description }}
          </p>
          <slot name="description"></slot>
        </v-col>

        <v-col
          :class="{
            'col-3': isSwitch && showLabelSection,
            'col-12': !showLabelSection,
          }"
          class="d-flex justify-end"
          xs="12"
          style="flex-shrink: 0"
        >
          <slot />
        </v-col>
      </template>

      <!-- Mobile layout -->
      <div v-else class="w-100">
        <v-col v-if="showLabelSection" class="col-12 pt-4 pb-0">
          <h4>{{ label }}</h4>
          <p v-if="description" style="white-space: normal; margin-bottom: 0">
            {{ description }}
          </p>
          <slot name="description"></slot>
        </v-col>
        <v-col
          :class="{
            'col-12': true,
            'pb-2': showLabelSection,
            'py-0': !showLabelSection,
          }"
        >
          <slot />
        </v-col>
      </div>
    </v-row>

    <!-- Alternative layout when wrappedInDiv is true -->
    <div v-else class="w-100">
      <v-col v-if="showLabelSection" class="col-12 pt-4 pb-0">
        <h4>{{ label }}</h4>
        <p v-if="description" style="white-space: normal; margin-bottom: 0">
          {{ description }}
        </p>
        <slot name="description"></slot>
      </v-col>
      <v-col
        :class="{
          'col-12': true,
          'pb-2': showLabelSection,
          'py-0': !showLabelSection,
        }"
      >
        <slot />
      </v-col>
    </div>
  </v-card>
</template>
