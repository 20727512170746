<template>
  <td :colspan="headers.length">
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ customer.phoneNumber || "" }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ customer.address || "" }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ customer.postalCode || "" }},
            {{ customer.city || "" }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            customer.dateAdded ? formatDateStringHour(customer.dateAdded) : ""
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </td>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatDateStringHour } from "../../../Utilities/dateUtility";
import { ICustomerDTO } from "@shared/types";

@Component({
  name: "CustomerExpandedDetails",
})
export default class CustomerExpandedDetails extends Vue {
  @Prop({ type: Array, required: true }) headers!: any[];
  @Prop({ type: Object, required: true }) customer!: Partial<ICustomerDTO>;

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
}
</script>
