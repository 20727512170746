<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { DialogType, IDialog } from "@/store/dialog";
import Loader from "./Loader.vue";
import CustomButton from "./styledComponents/customButton.vue";
import DynamicForm from "../Settings/Services/components/DynamicForm.vue";
import { IServiceCategoryDTO } from "@shared/types";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { serviceCategoryModule } from "@/store/modules/serviceModule/serviceCategoryModule";

@Component({
  name: "ServiceCategories",
  components: { CustomButton, Loader, DynamicForm },
})
export default class ServiceCategories extends Vue {
  public isLoading = false;
  public isShowingForm = false;
  public isEditing = false;
  public editingCategory: IServiceCategoryDTO | null = null;

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.serviceCategoryFormDTO });
  }

  async created() {
    this.isLoading = true;
    await serviceCategoryModule.getCategories();
    this.isLoading = false;
  }

  async onAddNewCategory(category: Partial<IServiceCategoryDTO>): Promise<void> {
    this.isLoading = true;
    //@ts-ignore
    const newCategory: IServiceCategory = {
      ...category,
      order: serviceCategoryModule.categories.length,
    } as IServiceCategoryDTO;

    await serviceCategoryModule.postCategory(newCategory);

    this.isShowingForm = false;
    this.editingCategory = null;
    this.isEditing = false;
    this.isLoading = false;
  }

  async onUpdateCategory(category: IServiceCategoryDTO): Promise<void> {
    this.isLoading = true;
    await serviceCategoryModule.putCategory(category);
    this.isShowingForm = false;
    this.isEditing = false;
    this.editingCategory = null;
    this.isLoading = false;
  }

  public toggleItem(item, index): void {
    item.isShowing = !item.isShowing;
  }

  async archiveCategory(itemId: number): Promise<void> {
    const vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.serviceCategories.confirmDelete).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.serviceCategories.deleteHeader).toString(),
      action: function () {
        serviceCategoryModule.deleteCategory(itemId);
      },
    };
    dialogModule.addToDialogQueue(dialog);
  }

  public toggleForm(): void {
    if (!this.isShowingForm) {
      this.editingCategory = null;
      this.isEditing = false;
    }
    this.isShowingForm = !this.isShowingForm;
  }

  reOrder(categoryId: number, newOrder: number) {
    serviceCategoryModule.changeCategoryOrder({ categoryId: categoryId, order: newOrder });
  }

  editCategory(category) {
    this.editingCategory = category;
    this.isEditing = true;
    this.isShowingForm = true;
  }

  get serviceCategoryMetadata() {
    return metadataModule.serviceCategoryMetadata;
  }

  get categories() {
    return serviceCategoryModule.categories;
  }
}
</script>

<template>
  <div>
    <v-dialog max-width="660" v-model="isShowingForm">
      <DynamicForm
        v-if="isShowingForm"
        :data="editingCategory"
        :metadata="serviceCategoryMetadata"
        :is-editing="isEditing"
        :is-creating="!isEditing"
        :entity="$t(this.$ts.entities.category)"
        :translation-path="nonReactiveTranslations"
        @cancel="isShowingForm = false"
        @save="onUpdateCategory"
        @create="onAddNewCategory"
      />
    </v-dialog>

    <loader v-if="isLoading"></loader>

    <div v-if="!isLoading">
      <v-col>
        <v-row>
          <v-simple-table style="width: 100%">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t($ts.serviceCategories.table.name) }}
                  </th>
                  <th class="text-left">
                    {{ $t($ts.serviceCategories.table.sort) }}
                  </th>
                  <th class="text-left"></th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categories" :key="item.id">
                  <td>{{ item.title }}</td>

                  <td>
                    <v-icon @click="reOrder(item.id, -1)">expand_more</v-icon>
                    <v-icon @click="reOrder(item.id, 1)">expand_less</v-icon>
                  </td>

                  <td>
                    <v-icon @click="editCategory(item)">edit</v-icon>
                  </td>
                  <td>
                    <v-icon @click="archiveCategory(item.id)">delete</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<style scoped></style>
