import store from "@/store/store";
import { VuexModule, Module, Action } from "vuex-class-modules";

export enum DialogType {
  Message,
  Choice,
  YesNo,
}

export enum DialogMutations {
  AddToDialogQueue = "addToDialogQueue",
  SetFulllScreenComponent = "setCurrentFullScreenComponent",
}
export enum DialogActions {
  AddToDialogQueue = "addToDialogQueue",
}

interface IDialogItemList extends Array<IDialog> {}
type Action = () => void;
export interface IDialog {
  header?: string;
  icon?: string;
  text: string;
  actionButtonDelay?: number;
  enabled?: boolean;
  action?: Action | null;
  cancelAction?: Action | null;
  type?: DialogType;
  created?: number;
  setBusyToFalseAfter?: boolean;
  fullScreen?: boolean;
  html?: boolean;
}
@Module({ generateMutationSetters: true })
class DialogModule extends VuexModule {
  // State
  dialogQueue: IDialog[] = [];
  fullscreenDialog: boolean = false;
  currentFullScreenComponent: string = "";
  showCreateCustomerDialog: boolean = false;
  createCustomerDefaultName: string = "";
  latestCreatedCustomerId: number | null = null;

  @Action
  async addToDialogQueue(dialog: IDialog) {
    const lastDialog = this.dialogQueue[this.dialogQueue.length - 1];
    if (lastDialog) {
      const diff = Date.now() - (lastDialog.created || 0);
      if (lastDialog.text === dialog.text && diff < 500) {
        return;
      }
    }

    const newDialog: IDialog = {
      text: dialog.text || "No text",
      type: dialog.type || DialogType.Message,
      action: dialog.action || null,
      cancelAction: dialog.cancelAction || null,
      icon: dialog.icon || "info",
      header: dialog.header || "Info",
      enabled: false,
      actionButtonDelay: dialog.actionButtonDelay || 0,
      created: Date.now(),
      setBusyToFalseAfter: dialog.setBusyToFalseAfter ?? true,
      fullScreen: dialog.fullScreen || false,
      html: dialog.html || false,
    };

    this.dialogQueue.push(newDialog);
    setTimeout(() => {
      newDialog.enabled = true;
    }, 100);
  }

  @Action
  async closeNormalDialog(accepted: boolean) {
    const latestDialog = this.dialogQueue.find((e) => e.enabled);
    if (!latestDialog) return;

    if (accepted && latestDialog.action && typeof latestDialog.action === "function") {
      latestDialog.action();
    } else if (!accepted && latestDialog.cancelAction && typeof latestDialog.cancelAction === "function") {
      latestDialog.cancelAction();
    }

    latestDialog.enabled = false;
  }

  @Action
  async setFullScreenDialog(isFullScreen: boolean) {
    this.fullscreenDialog = isFullScreen;
  }

  @Action
  async setCurrentFullScreenComponent(component: string) {
    this.currentFullScreenComponent = component;
  }

  @Action
  async openCreateCustomer(defaultName: string = "") {
    this.createCustomerDefaultName = defaultName;
    this.showCreateCustomerDialog = true;
  }

  @Action
  async closeCreateCustomer(newCustomerId?: number) {
    this.showCreateCustomerDialog = false;
    this.createCustomerDefaultName = "";
    console.log("Closing create customer with ID: ", newCustomerId);
    if (newCustomerId) {
      this.latestCreatedCustomerId = newCustomerId;
    }
  }
}

export const dialogModule = new DialogModule({ store, name: "dialogModule" });
