<template>
  <v-menu
    :value="selectedOpen"
    @input="$emit('update:selectedOpen', $event)"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
  >
    <v-card v-if="selectedEvent" color="grey lighten-4" flat style="padding: 0" class="eventMenu">
      <v-toolbar color="#e6e6e6" flat height="50">
        <a
          style="text-decoration: underline"
          @click="gotoAppointment(selectedEvent.appointmentNumber)"
          v-if="selectedEvent.appointmentNumber !== 0"
        >
          #{{ selectedEvent.appointmentNumber }}
        </a>
        <v-spacer></v-spacer>

        <appointment-menu
          v-if="selectedEvent && canWriteAppointmentsAndClients"
          :appointmentDate="selectedEvent.start"
          :appointment="selectedEvent"
          :appointmentId="selectedEvent.appointmentId"
          @deleted="$emit('deleted')"
          @moved="$emit('moved')"
          @changecustomer="$emit('moved')"
          @move="$emit('move', $event)"
          :spread="true"
        >
        </appointment-menu>

        <v-btn class="ml-1" style="margin-right: -5px" fab x-small @click="closeMenu">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <appointment-summary @close="onSummaryClose" :selectedEvent="selectedEvent" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import AppointmentMenu from "../../AppointmentMenu.vue";
import AppointmentSummary from "@/components/global/AppointmentSummary.vue";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";

@Component({
  components: {
    AppointmentMenu,
    AppointmentSummary,
  },
})
export default class EventMenu extends Vue {
  @Prop({ default: null }) selectedEvent!: any; // Replace 'any' with the correct type
  @Prop({ required: true }) selectedOpen!: boolean;
  @Prop({ required: true }) selectedElement!: Element | null;

  get canWriteAppointmentsAndClients() {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }
  closeMenu() {
    this.$emit("update:selectedOpen", false);
  }

  gotoAppointment(appointmentNumber: number) {
    this.$emit("goto-appointment", appointmentNumber);
  }

  onSummaryClose() {
    this.$emit("summary-close");
  }
}
</script>

<style scoped>
/* Existing styles (if any) */
</style>
