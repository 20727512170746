import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { ILedgerPaymentDTO, IOrderDTO, IPostLedgerPaymentDTO, IPostOrderDTO } from "@shared/types";
import { OrderService } from "@/services/api/OrderService";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class OrdersModule extends VuexModule {
  private orderService = OrderService.getInstance();

  public orders: IOrderDTO[] = [];

  @Action
  async getOrders(): Promise<void> {
    this.orders = await this.orderService.getOrders();
  }

  @Action
  async getOrder(id: number): Promise<IOrderDTO> {
    return await this.orderService.getOrder(id);
  }

  @Action
  async getOrdersByCustomerId(customerId: number): Promise<IOrderDTO[]> {
    return await this.orderService.getOrdersByCustomerId(customerId);
  }

  @Action
  async createOrder(dto: IPostOrderDTO): Promise<IOrderDTO> {
    const order = await this.orderService.createOrder(dto);
    this.getOrders();
    return order;
  }

  @Action
  async processPayment(dto: IPostLedgerPaymentDTO): Promise<ILedgerPaymentDTO> {
    const payment = await this.orderService.processPayment(dto);
    this.getOrders();
    return payment;
  }
}

export const ordersModule = new OrdersModule({ store, name: "ordersModule" });
