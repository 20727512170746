<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IStaffMemberDTO, IUserDTO } from "@shared/types";
import { vacationDaysModule } from "@/store/modules/vacationDays/vacationDaysModule";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "VacationDays",
  components: {},
})
export default class VacationDays extends Vue {
  @Prop({ default: null }) staffMember: IStaffMemberDTO;

  selectedVacationDays = [];

  @Watch("staffMember")
  async onStaffMemberChange() {
    //If the staff member is changed without destorying the component
    await vacationDaysModule.getVacationDays(this.staffMemberId);
    await workingDaysModule.getAllWorkingDays(this.staffMemberId);
  }

  public async created(): Promise<void> {
    await vacationDaysModule.getVacationDays(this.staffMemberId);
    await workingDaysModule.getAllWorkingDays(this.staffMemberId);
  }

  removeCurrentSelectedDateToArrayEvents() {
    const commaSepertedString = this.selectedVacationDays.join();
    vacationDaysModule.removeVacationDay({
      vacationDaysString: commaSepertedString,
      staffMemberId: this.staffMemberId,
    });
  }

  get staffMemberId() {
    let staffMemberId = 0;
    if (this.staffMember != null) {
      staffMemberId = this.staffMember.id;
    }
    return staffMemberId;
  }

  minTwoDigits(n) {
    return (n < 10 ? "0" : "") + n;
  }

  get user(): IUserDTO {
    return userModule.user;
  }

  get allWorkingDays() {
    return workingDaysModule.allWorkingDays;
  }

  get vacationDays() {
    return vacationDaysModule.vacationDays;
  }

  get vacationDaysEvents() {
    console.log("Vacation days: ", this.vacationDays);
    let mappedDays = this.vacationDays.map((x) => {
      return x.year + "-" + this.minTwoDigits(x.month) + "-" + this.minTwoDigits(x.day);
    });
    return mappedDays;
  }

  addCurrentSelectedDateToArrayEvents() {
    const commaSepertedString = this.selectedVacationDays.join();
    vacationDaysModule.addVacationDays({ vacationDaysString: commaSepertedString, staffMemberId: this.staffMemberId });
  }

  disableNonWorkingDays(selectedDay) {
    let date = new Date(selectedDay);
    let weekDay = date.getDay(); //0 is sunday
    const isWorkingToday = this.allWorkingDays[weekDay];
    return isWorkingToday;
  }
}
</script>

<template>
  <div>
    <v-date-picker
      no-title
      locale="nb-no"
      :events="vacationDaysEvents"
      event-color="red lighten-1"
      v-model="selectedVacationDays"
      :allowed-dates="disableNonWorkingDays"
      range
    >
      <v-col class="pa-0">
        <v-row justify="center">
          <h3 class="text-center">{{ $t($ts.vacationDays.markDayAs) }}</h3>
        </v-row>
        <v-row justify="space-between">
          <v-col class="pa-1" cols="auto">
            <v-btn color="primary" @click="removeCurrentSelectedDateToArrayEvents()">{{
              $t($ts.vacationDays.available)
            }}</v-btn>
          </v-col>
          <v-col class="pa-1" cols="auto">
            <v-btn color="error" @click="addCurrentSelectedDateToArrayEvents()">{{
              $t($ts.vacationDays.notAvailable)
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-date-picker>
  </div>
</template>

<style scoped></style>
