import { BOOKING_URL, NEW_BOOKING_URL, CITARO_BOOKING_URL } from "../main";

export function getBookingUrl(bookingLink: string) {
  //Our first customer uses the app.vergba domain instead of availify - i don't want to disturb him by confusing him with new domains - but this is strictly not necessary
  if (window.location.toString().includes("availify")) {
    return NEW_BOOKING_URL + bookingLink;
  } else if (window.location.toString().includes("citaro")) {
    return CITARO_BOOKING_URL + bookingLink;
  } else {
    return BOOKING_URL + bookingLink;
  }
}
