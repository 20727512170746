export enum DialogType {
  Message,
  Choice,
  YesNo,
}

type Action = () => void;
export interface IDialog {
  header?: string;
  icon?: string;
  text: string;
  actionButtonDelay?: number;
  enabled?: boolean;
  action?: Action | null;
  cancelAction?: Action | null;
  type?: DialogType;
  created?: number;
  setBusyToFalseAfter?: boolean;
  fullScreen?: boolean;
  html?: boolean;
}
