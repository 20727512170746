import { BaseService } from "./Base/BaseService";
import { IUpdateLogDTO } from "@shared/types";

export class UpdateLogService extends BaseService {
  private static instance: UpdateLogService;
  private controller = "UpdateLog"; // Matches backend route api/UpdateLog

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!UpdateLogService.instance) {
      UpdateLogService.instance = new UpdateLogService();
    }
    return UpdateLogService.instance;
  }

  public async getCurrentUpdateLogs(): Promise<IUpdateLogDTO> {
    return this.http.getAuthenticated<IUpdateLogDTO>(
      `${this.controller}/current`
    );
  }

  public async postAudit(dto: IUpdateLogDTO): Promise<void> {
    return this.http.postAuthenticated<void>(`${this.controller}/admin`, dto);
  }
}
