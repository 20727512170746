<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Mutation } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { DialogType, IDialog } from "@/store/dialog";
import CreateCustomer from "./global/CreateCustomer.vue";
import SelectCustomer from "./global/CreateAppointment.vue";
import { formatTime } from "@/types/formatDateHelper";
import { IAppointmentInfoDTO, IAuditLogDTO, IStaffMemberDTO } from "@shared/types";
import CreateAppointment from "./global/CreateAppointment.vue";
import { AppointmentInfo } from "@/store/appointments/appointmentTypings";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";

@Component({
  name: "AppointmentMenu",
  components: { SelectCustomer, CreateCustomer, CreateAppointment },
})
export default class AppointmentMenu extends Vue {
  @Prop({}) appointment: IAppointmentInfoDTO;
  @Prop({}) appointmentDate;
  @Prop({}) appointmentId;
  @Prop({ default: false }) displayShowInCalendar;
  @Prop({ default: false }) spread;
  @Prop({ default: false }) large;

  get canWriteAppointmentsAndClients(): boolean {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get staffMembers(): IStaffMemberDTO[] {
    return staffMemberModule.staffMembers;
  }

  get appointmentsForToday() {
    return appointmentsModule.appointmentsForToday;
  }

  get customers() {
    return customersModule.customers;
  }

  get appointmentTypes() {
    return serviceModule.appointmentTypes;
  }

  public selectedCustomerId: number = 0;
  public moveAppointment: boolean = false;
  public selectedAppointmentType: number = -1;
  public defaultSelectedServiceId: number = 0;
  private defaultSelectedCustomerId: number;

  @Watch("appointment")
  onValCahnge() {
    //@ts-ignore
    this.selectedAppointmentType = this.appointment.appointmentTypeId;
  }

  created() {
    //@ts-ignore
    this.selectedAppointmentType = this.appointment.appointmentTypeId;
    //@ts-ignore
    this.defaultSelectedServiceId = this.appointment.appointmentTypeId;

    if (this.appointment.customerId !== 0 && this.appointment.customerId !== -1) {
      this.selectedCustomerId = this.appointment.customerId;
      this.defaultSelectedCustomerId = this.selectedCustomerId;
    }
  }

  move() {
    snackbarModule.postSnackbarMessage(this.$t(this.$ts.appointmentMenu.moveAppointmentMessage).toString());

    //@ts-ignore
    console.log("ID: ", this.appointment.appointmentId);
    //@ts-ignore
    this.$emit("move", this.appointment.appointmentId);
  }

  findInCalendar() {
    auditLogModule.postAudit({
      dto: {
        action: 3,
        type: 4,
        comment: "",
        message: " clicked on search in calendar for " + this.appointment.number,
        hideForUser: true,
      },
    });

    this.$router.push("/kalender/" + this.appointment.number).catch(() => {});
  }

  public deleteAppointment(): void {
    let vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.appointmentMenu.confirmDeleteAppointment).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.appointmentMenu.deleteAppointmentHeader).toString(),
      action: async function () {
        await appointmentsModule.deleteBookedAppointment(vm.appointmentId);

        vm.$emit("deleted");
        snackbarModule.postSnackbarMessage(vm.$t(vm.$ts.appointmentMenu.appointmentDeleted).toString());
      },
    };

    dialogModule.addToDialogQueue(dialog);
  }

  onMovedAppointment() {
    this.$emit("moved");
  }

  onClosed() {
    this.moveAppointment = false;
    this.$emit("close");
    this.$emit("confirmed");
  }

  moveAppointmentAction() {
    appointmentsModule.setDefaultCustomerToBook(null);

    let now = new Date(
      this.appointment.year,
      this.appointment.month - 1,
      this.appointment.day,
      this.appointment.hour,
      this.appointment.minute,
      0
    );
    appointmentsModule.selectDateToBook({
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    });

    appointmentsModule.selectHourToBook(this.hourAndMinuteOfAppointment());

    this.moveAppointment = true;
  }

  hourAndMinuteOfAppointment() {
    return this.formatTimeCorrectly(this.appointment.hour, this.appointment.minute);
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return formatTime(hour, minute);
  }

  customerFilter(item, queryText) {
    const textOne = item.firstName.toLowerCase();

    return textOne.includes(queryText.toLowerCase());
  }

  get overrideCustomerName() {
    let a: any = this.appointment as any;

    if (a.name != null && a.name != "") {
      return a.name;
    }
    if (this.appointment.customerName != null && this.appointment.customerName != "") {
      return this.appointment.customerName;
    }
    return null;
  }

  getServiceIds(appointment) {
    //TODO: Appointment from Weekly calendar has field "appointmentTypeIds" while from "Appointment page" aka "GetInformation (AppointmentInfoDTO" has "serviceIds"

    if (appointment.appointmentTypeIds != null) {
      return appointment.appointmentTypeIds;
    } else if (appointment.serviceIds != null) {
      return appointment.serviceIds;
    }
    return [];
  }
}
</script>

<template>
  <div>
    <v-dialog v-model="moveAppointment" :close-on-content-click="false" offset-x max-width="700px">
      <create-appointment
        v-if="moveAppointment"
        :open="moveAppointment"
        @close="onClosed"
        :shoulScroll="false"
        @added="onMovedAppointment"
        :edit="true"
        :defaultSelectedCustomerId="appointment.customerId"
        :defaultSelectedServiceIds="getServiceIds(appointment)"
        :defaultOverrideDuration="appointment.duration"
        :defaultOverrideCustomerName="overrideCustomerName"
        :defaultOverridePrice="appointment.price"
        :editingAppointmentId="appointmentId"
        :defaultCustomerCreateEmail="appointment.customerEmail"
        :defaultCustomerCreatePhone="appointment.customerPhone"
        :default-override-staff-member="appointment.staffMemberId"
      ></create-appointment>
    </v-dialog>

    <v-menu v-if="!spread" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="!appointment.isCustom && canWriteAppointmentsAndClients"
          :key="1"
          @click="moveAppointmentAction()"
        >
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t($ts.appointmentMenu.edit) }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="canWriteAppointmentsAndClients" :key="2" @click="deleteAppointment()">
          <v-list-item-icon>
            <v-icon>delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t($ts.appointmentMenu.delete) }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="displayShowInCalendar" :key="3" @click="findInCalendar()">
          <v-list-item-icon>
            <v-icon>mdi-file-find</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t($ts.appointmentMenu.findInCalendar) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      class="mx-1"
      v-if="!large && spread && !appointment.isCustom && canWriteAppointmentsAndClients"
      fab
      x-small
      @click="moveAppointmentAction()"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-btn
      class="mx-1"
      v-if="!large && spread && canWriteAppointmentsAndClients"
      fab
      x-small
      @click="deleteAppointment()"
      ><v-icon>delete</v-icon></v-btn
    >
    <v-btn class="mx-1" v-if="!large && spread && displayShowInCalendar" fab x-small @click="findInCalendar()"
      ><v-icon>mdi-file-find</v-icon></v-btn
    >
    <v-btn x-small class="mx-1" v-if="!large && spread && canWriteAppointmentsAndClients" fab @click="move()"
      ><v-icon>mdi-arrow-all</v-icon></v-btn
    >

    <v-btn
      small
      class="mx-1"
      v-if="large && spread && !appointment.isCustom && canWriteAppointmentsAndClients"
      fab
      @click="moveAppointmentAction()"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-btn small class="mx-1" v-if="large && spread && canWriteAppointmentsAndClients" fab @click="deleteAppointment()"
      ><v-icon>delete</v-icon></v-btn
    >
    <v-btn small class="mx-1" v-if="large && spread && displayShowInCalendar" fab @click="findInCalendar()"
      ><v-icon>mdi-file-find</v-icon></v-btn
    >
  </div>
</template>

<style scoped></style>
