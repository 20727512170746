<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="closeDialog" persistent>
    <v-card class="order-card">
      <v-card-title style="background-color: #866afe" class="white--text">
        <v-icon left color="white">mdi-shopping</v-icon>
        <span class="headline">{{ $t($ts.orders.orderDetails) }} #{{ orderId }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-container fluid>
          <!-- Date Section -->
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-card outlined class="time-card">
                <v-card-text>
                  <div class="d-flex align-center mb-2">
                    <v-icon style="color: #866afe" class="mr-2">mdi-calendar</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.orders.orderDate) }}</div>
                      <div>{{ formatDate(order.orderDate) }}</div>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-clock-outline</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.created) }}</div>
                      <div>{{ formatDate(order.createdAt) }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Status Section -->
            <v-col cols="12" sm="6">
              <v-card outlined class="payment-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-credit-card</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.paymentMethod.title) }}</div>
                      <div class="mt-1">{{ $t($ts.paymentMethod[order.paymentMethod]) }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Customer and Staff Section -->
          <v-row class="mb-4">
            <v-col cols="12" sm="6">
              <v-card outlined class="customer-card" @click="goToCustomer" style="cursor: pointer;">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-account</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.entities.customer) }}</div>
                      <div class="d-flex align-center">
                        {{ customerName }}
                        <v-icon small class="ml-1" color="#866afe">mdi-open-in-new</v-icon>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card outlined class="staff-card">
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-icon style="color: #866afe" class="mr-2">mdi-account-tie</v-icon>
                    <div>
                      <div class="subtitle-1">{{ $t($ts.orders.staffMember) }}</div>
                      <div>{{ staffName }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Items Section -->
          <v-row v-if="order.salesItems && order.salesItems.length > 0" class="mb-4">
            <v-col cols="12">
              <v-card outlined class="items-card">
                <v-card-title class="subtitle-1">
                  <v-icon style="color: #866afe" class="mr-2">mdi-cart</v-icon>
                  {{ $t($ts.orders.items) }}
                </v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>{{ $t($ts.orders.item) }}</th>
                          <th>{{ $t($ts.orders.quantity) }}</th>
                          <th>{{ $t($ts.orders.price) }}</th>
                          <th>{{ $t($ts.orders.total) }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in order.salesItems" :key="item.id">
                          <td>{{ getInventoryItemName(item.inventoryItemId) }}</td>
                          <td>{{ item.quantity }}</td>
                          <td>{{ formatCurrency(item.price) }}</td>
                          <td>{{ formatCurrency(item.totalPrice) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Total Amount Section -->
          <v-row>
            <v-col cols="12">
              <v-card outlined class="total-card">
                <v-card-text class="d-flex align-center justify-end">
                  <v-icon style="color: #866afe" class="mr-2">mdi-cash-multiple</v-icon>
                  <span class="subtitle-1 mr-2">{{ $t($ts.orders.totalAmount) }}:</span>
                  <span class="text-h5 font-weight-bold" style="color: #866afe">
                    {{ formatCurrency(order.totalAmount) }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <!-- 
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn style="color: #866afe" text @click="closeDialog">
          <v-icon left>mdi-close-circle</v-icon>
          {{ $t("Close") }}
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IOrderDTO, OrderStatus, PaymentMethod } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { formatDate } from "@/types/formatDateHelper";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { inventoryModule } from "@/store/modules/inventory/inventoryModule";

@Component
export default class ViewOrderDialog extends Vue {
  @Prop({ type: Object, required: false, default: null }) order!: IOrderDTO | null;
  @Prop({ type: Boolean, required: true }) value!: boolean;

  private customerName: string = "";
  private staffName: string = "";

  async created() {
    await inventoryModule.getAllItems();
  }

  getInventoryItemName(inventoryItemId: number): string {
    const item = inventoryModule.inventoryItems.find((item) => item.id === inventoryItemId);
    return item ? item.name : `#${inventoryItemId}`;
  }

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit("input", value);
  }

  get orderId(): number {
    if (!this.order) return 0;
    return typeof this.order.id === "string" ? parseInt(this.order.id) : this.order.id;
  }

  private formatDate(date: string): string {
    return formatDate(new Date(date));
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user?.currency || "USD",
    }).format(value);
  }

  @Watch("order", { immediate: true })
  private async onOrderChange() {
    if (this.order) {
      try {
        const customerId =
          typeof this.order.customerId === "string" ? parseInt(this.order.customerId) : this.order.customerId;
        const customer = await customersModule.getCustomer(customerId);
        this.customerName = `${customer.firstName} ${customer.lastName}`;
      } catch (error) {
        this.customerName = `#${this.order.customerId}`;
      }

      const staffId = typeof this.order.staffId === "string" ? parseInt(this.order.staffId) : this.order.staffId;
      const staff = staffMemberModule.staffMembers.find((s) => s.id === staffId);
      this.staffName = staff ? staff.fullName : `#${this.order.staffId}`;
    }
  }

  closeDialog() {
    this.$emit("input", false);
  }

  goToCustomer() {
    if (this.order && this.order.customerId) {
      const customerId = typeof this.order.customerId === "string" ? parseInt(this.order.customerId) : this.order.customerId;
      this.$router.push(`/customer/${customerId}`);
    }
  }
}
</script>

<style scoped>
.order-card {
  border-radius: 8px;
}

.time-card,
.payment-card,
.customer-card,
.staff-card,
.items-card,
.total-card {
  border-radius: 4px;
  transition: all 0.3s;
}

.time-card:hover,
.payment-card:hover,
.customer-card:hover,
.staff-card:hover,
.items-card:hover,
.total-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
  font-weight: 500;
  font-size: 1.5rem;
}

.subtitle-1 {
  font-weight: 500;
}

.v-card-title {
  word-break: break-word;
}

.v-data-table {
  background-color: transparent !important;
}

.v-data-table th {
  color: #866afe !important;
  font-weight: 500 !important;
}

.v-data-table td {
  height: 40px !important;
}

.total-card {
  background-color: #f8f9fa;
}
</style>
