export enum PermissionArea {
  APPOINTMENTS_AND_CLIENTS = "appointmentsAndClients",
  SETTINGS = "settings",
  STATISTICS_AND_FINANCES = "statisticsAndFinances",
  ADMINISTRATION = "administration",
}

export enum PermissionLevel {
  NONE = "none",
  READ = "read",
  READ_WRITE = "readwrite",
}
