<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { ICustomFieldDTO, IPostAuditLogDTO, IUserDTO, VerificationType } from "@shared/types";
import ToolTip from "../../../ToolTip.vue";
import { Watch } from "vue-property-decorator";
import { userModule } from "@/store/modules/user/userModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { customFieldModule } from "@/store/modules/customFieldsModule/customFieldsModule";

@Component({
  name: "BookingPageFieldSettings",
  components: { ToolTip },
})
export default class BookingPageFieldSettings extends Vue {
  public customerRequiredEmail: boolean = false;
  public customerRequiredEmailOptional: boolean = false;
  public customerRequiredPhoneNumber: boolean = false;
  public customerRequiredPhoneNumberOptional: boolean = false;
  public customerRequiredName: boolean = false;
  public customerRequiredAddress: boolean = false;
  public customerRequiredPostalCode: boolean = false;
  public customerRequiredCity: boolean = false;
  public customerRequiredSSN: boolean = false;
  public customerRequiredBirthdate: boolean = false;
  public customerRequiredGender: boolean = false;
  public useCustomerAccounts: boolean = false;

  public editedCustomFields: Array<ICustomFieldDTO> = [];

  public customerReceiptAndReminderMode: string = "";
  public verificationType: VerificationType = VerificationType.None;

  public showAddCustomField: boolean = false;
  public newCustomField: ICustomFieldDTO | null = null;

  @Watch("customFields")
  onCustomFieldsChange(newValue: Array<ICustomFieldDTO>) {
    this.editedCustomFields = newValue;
  }

  created() {
    this.customerRequiredEmail = userModule.user.customerRequiredEmail;
    this.customerRequiredEmailOptional = userModule.user.customerRequiredEmailOptional;
    this.customerRequiredPhoneNumber = userModule.user.customerRequiredPhoneNumber;
    this.customerRequiredPhoneNumberOptional = userModule.user.customerRequiredPhoneNumberOptional;
    this.customerRequiredName = userModule.user.customerRequiredName;
    this.customerRequiredAddress = userModule.user.customerRequiredAddress;

    this.customerRequiredPostalCode = userModule.user.customerRequiredPostalCode;
    this.customerRequiredCity = userModule.user.customerRequiredCity;
    this.customerRequiredSSN = userModule.user.customerRequiredSSN;
    this.customerRequiredBirthdate = userModule.user.customerRequiredBirthdate;
    this.customerRequiredGender = userModule.user.customerRequiredGender;

    this.customerReceiptAndReminderMode = userModule.user.customerReceiptAndReminderMode;
    this.verificationType = userModule.user.verificationType;

    customFieldModule.getCustomFields();
  }

  addCustomField() {
    this.newCustomField = {
      label: "",
    } as ICustomFieldDTO;

    this.showAddCustomField = true;
  }

  async saveFieldChange(field: string, value: boolean): Promise<void> {
    try {
      //1. this seems out of place, and 2. i want to translate it.

      const audit: IPostAuditLogDTO = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t("bookingPageFieldSettings.auditLogs.changedMultipleServices", {
          field: this.$t(field),
        }).toString(),
        hideForUser: false,
      };
      await auditLogModule.postAuditLog(audit);

      const updatedUserPayload: Partial<IUserDTO> = {
        [field]: value,
      };

      await userModule.updateUser(updatedUserPayload);
    } catch (error) {
      console.error(error);
    }
  }

  async confirmAddCustomField() {
    if (this.newCustomField) {
      await customFieldModule.postCustomField(this.newCustomField);
      this.newCustomField = null;
      this.showAddCustomField = false;
    }
  }

  async saveCustomField(field: ICustomFieldDTO): Promise<void> {
    try {
      await customFieldModule.putCustomField(field);
    } catch (error) {
      console.error(error);
    }
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-card flat>
            <v-card-text>
              <h2>{{ $t($ts.selectFieldsCustomerFillsWhenBooking) }}</h2>
            </v-card-text>
            <v-card-text>
              <v-checkbox
                v-model="customerRequiredName"
                :label="$t($ts.name)"
                :readonly="customerRequiredName === true"
                :disabled="customerRequiredName === true"
                @change="saveFieldChange('customerRequiredName', $event)"
              ></v-checkbox>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="customerRequiredEmail"
                    :label="$t($ts.email)"
                    :readonly="customerReceiptAndReminderMode === 'email' || verificationType == 1"
                    :disabled="customerReceiptAndReminderMode === 'email' || verificationType == 1"
                    @change="saveFieldChange('customerRequiredEmail', $event)"
                  ></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox
                    v-if="customerRequiredEmail"
                    v-model="customerRequiredEmailOptional"
                    :label="$t($ts.optional)"
                    :readonly="customerReceiptAndReminderMode === 'email' || verificationType == 1"
                    :disabled="customerReceiptAndReminderMode === 'email' || verificationType == 1"
                    @change="saveFieldChange('customerRequiredEmailOptional', $event)"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="customerRequiredPhoneNumber"
                    :label="$t($ts.phoneNumber)"
                    :readonly="useCustomerAccounts || customerReceiptAndReminderMode === 'sms'"
                    :disabled="useCustomerAccounts || customerReceiptAndReminderMode === 'sms'"
                    @change="saveFieldChange('customerRequiredPhoneNumber', $event)"
                  ></v-checkbox>
                </v-col>

                <v-col>
                  <v-checkbox
                    v-if="customerRequiredPhoneNumber"
                    v-model="customerRequiredPhoneNumberOptional"
                    :label="$t($ts.optional)"
                    :readonly="useCustomerAccounts || customerReceiptAndReminderMode === 'sms'"
                    :disabled="useCustomerAccounts || customerReceiptAndReminderMode === 'sms'"
                    @change="saveFieldChange('customerRequiredPhoneNumberOptional', $event)"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-checkbox
                v-model="customerRequiredAddress"
                :label="$t($ts.address)"
                @change="saveFieldChange('customerRequiredAddress', $event)"
              ></v-checkbox>
              <v-checkbox
                v-model="customerRequiredPostalCode"
                :label="$t($ts.postalCode)"
                @change="saveFieldChange('customerRequiredPostalCode', $event)"
              ></v-checkbox>
              <v-checkbox
                v-model="customerRequiredCity"
                :label="$t($ts.city)"
                @change="saveFieldChange('customerRequiredCity', $event)"
              ></v-checkbox>
              <v-checkbox
                v-model="customerRequiredSSN"
                :label="$t($ts.ssn)"
                @change="saveFieldChange('customerRequiredSSN', $event)"
              ></v-checkbox>
              <v-checkbox
                v-model="customerRequiredBirthdate"
                :label="$t($ts.birthdate)"
                @change="saveFieldChange('customerRequiredBirthdate', $event)"
              ></v-checkbox>
              <v-checkbox
                v-model="customerRequiredGender"
                :label="$t($ts.gender)"
                @change="saveFieldChange('customerRequiredGender', $event)"
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
