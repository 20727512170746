<template>
  <div>
    <CompanyInfoField
      :label="$t($ts.invoiceSettings.dian.title)"
      :description="$t($ts.invoiceSettings.dian.description)"
    >
      <p>{{ $t($ts.invoiceSettings.dian.comingSoon) }}</p>
    </CompanyInfoField>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class DianSettings extends Vue {}
</script>
