<template>
  <div>
    <div v-if="error" class="text-center error--text mb-4">
      {{ error }}
    </div>

    <Loader v-if="isLoading"></Loader>

    <div v-else-if="!error && localStaffMember">
      <v-form v-model="allHoursValid" lazy-validation>
        <v-card-text>
          <!-- Weekday buttons with mobile-friendly scrolling container -->
          <div class="weekday-buttons-container">
            <v-btn-toggle v-model="dayIndex" shaped mandatory small color="#00b884" class="weekday-buttons">
              <v-btn
                v-for="(day, i) in days"
                :key="i + 'day.index'"
                @click="selectDayToPickWorkingHours(day)"
                class="weekday-btn"
              >
                {{ day.label.substr(0, 3) }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-card-text>

        <v-card-text>
          <div>
            <v-switch
              v-if="isEditingHours === false"
              v-model="isWorkingToday"
              color="#56dcb6"
              @change="toggleWorkingDay"
              :label="
                $t('workingHours.availableOnThisDay', {
                  value: booleanToNorwegian(isWorkingToday),
                }).toString()
              "
            ></v-switch>

            <template v-if="isWorkingToday && selectedDay">
              <day-working-hours
                :day-label="selectedDay.label"
                :open-time="getDayOpenTime"
                :close-time="getDayCloseTime"
                @update="updateDayHours"
              />

              <break-times
                v-if="isWorkingToday"
                :pause-times="pauseTimesOnSelectedWeekDay"
                :start.sync="newPauseStart"
                :end.sync="newPauseEnd"
                @delete="onDeletePause"
                @add="addPause"
              />
            </template>
          </div>
        </v-card-text>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IPostPauseTimeDTO, IStaffMemberDTO, IUserBookingSettingsAsHoursAndMinutesDTO, IUserDTO } from "@shared/types";
import DayWorkingHours from "./components/DayWorkingHours.vue";
import BreakTimes from "./components/BreakTimes.vue";
import Loader from "@/components/global/Loader.vue";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { pauseTimeModule } from "@/store/modules/pauseTimes/pauseTimeModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "WorkingHours",
  components: {
    Loader,
    DayWorkingHours,
    BreakTimes,
  },
})
export default class WorkingHours extends Vue {
  @Prop({ default: null }) staffMember: IStaffMemberDTO;
  bookingSettings: IUserBookingSettingsAsHoursAndMinutesDTO | [] = [];

  //Vuex

  get pauseTimes() {
    return pauseTimeModule.pauseTimes;
  }

  get user() {
    return userModule.user;
  }

  get allWorkingDays() {
    return workingDaysModule.allWorkingDays;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  public isWorkingToday = false;
  public dayIndex = 0;
  public isEditingHours = false;
  public isLoading: boolean = true;
  public error: string | null = null;
  public localStaffMember: IStaffMemberDTO | null = null;
  public isInitializing: boolean = true;
  public newPauseStart = "";
  public newPauseEnd = "";
  public selectedDay = null;
  public allHoursValid: boolean = true;

  @Watch("staffMember")
  async onStaffMemberChange(newStaffMember: IStaffMemberDTO) {
    if (newStaffMember) {
      this.localStaffMember = newStaffMember;
      await this.initializeWorkingHours();
    }
  }

  @Watch("bookingSettings", { deep: true })
  async onBookingSettingsChange() {
    console.log("onBookingSettingsChange");
    if (this.isInitializing) {
      console.log("isInitializing", this.isInitializing);
      return;
    }

    console.log("bookingSettings", this.bookingSettings);
    console.log("allHoursValid", this.allHoursValid);
    if (this.bookingSettings && this.allHoursValid) {
      try {
        await userModule.updateBookingSettings({
          staffMemberId: this.staffMemberId,
          //@ts-ignore
          settings: this.bookingSettings,
        });

        await Promise.all([
          userModule.getBookingIntervalSettings(this.staffMemberId),
          userModule.getBookingSettings(this.staffMemberId),
          workingDaysModule.getAllWorkingDays(this.staffMemberId),
        ]);

        // snackbarModule.postSnackbarMessage(this.$t(this.$ts.workingHours.updated).toString());
      } catch (err) {
        this.error = "Failed to save changes";
        console.error("Error saving changes:", err);
      }
    } else if (!this.allHoursValid) {
      // dialogModule.addToDialogQueue({
      //   text: this.$t(this.$ts.workingHours.invalidTimeFormat).toString(),
      // });
    }
  }

  get days() {
    return [
      {
        label: this.$t(this.$ts.days.monday).toString(),
        weekDayName: "Monday",
        weekdayIndex: 1,
      },
      {
        label: this.$t(this.$ts.days.tuesday).toString(),
        weekDayName: "Tuesday",
        weekdayIndex: 2,
      },
      {
        label: this.$t(this.$ts.days.wednesday).toString(),
        weekDayName: "Wednesday",
        weekdayIndex: 3,
      },
      {
        label: this.$t(this.$ts.days.thursday).toString(),
        weekDayName: "Thursday",
        weekdayIndex: 4,
      },
      {
        label: this.$t(this.$ts.days.friday).toString(),
        weekDayName: "Friday",
        weekdayIndex: 5,
      },
      {
        label: this.$t(this.$ts.days.saturday).toString(),
        weekDayName: "Saturday",
        weekdayIndex: 6,
      },
      {
        label: this.$t(this.$ts.days.sunday).toString(),
        weekDayName: "Sunday",
        weekdayIndex: 0,
      },
    ];
  }

  get staffMemberId(): number {
    return this.localStaffMember?.id || 0;
  }

  get getDayOpenTime(): string {
    if (!this.selectedDay || !this.bookingSettings) return "";
    const dayMap = {
      0: "sundayOpenFromMidnight",
      1: "mondayOpenFromMidnight",
      2: "tuesdayOpenFromMidnight",
      3: "wednesdayOpenFromMidnight",
      4: "thursdayOpenFromMidnight",
      5: "fridayOpenFromMidnight",
      6: "saturdayOpenFromMidnight",
    };
    return this.bookingSettings[dayMap[this.selectedDay.weekdayIndex]] || "";
  }

  get getDayCloseTime(): string {
    if (!this.selectedDay || !this.bookingSettings) return "";
    const dayMap = {
      0: "sundayCloseFromMidnight",
      1: "mondayCloseFromMidnight",
      2: "tuesdayCloseFromMidnight",
      3: "wednesdayCloseFromMidnight",
      4: "thursdayCloseFromMidnight",
      5: "fridayCloseFromMidnight",
      6: "saturdayCloseFromMidnight",
    };
    return this.bookingSettings[dayMap[this.selectedDay.weekdayIndex]] || "";
  }

  updateDayHours({ openTime, closeTime }) {
    console.log("updateDayHours", openTime, closeTime);
    console.log("selectedDay", this.selectedDay);
    console.log("bookingSettings", this.bookingSettings);
    if (!this.selectedDay || !this.bookingSettings) return;

    const openMap = {
      0: "sundayOpenFromMidnight",
      1: "mondayOpenFromMidnight",
      2: "tuesdayOpenFromMidnight",
      3: "wednesdayOpenFromMidnight",
      4: "thursdayOpenFromMidnight",
      5: "fridayOpenFromMidnight",
      6: "saturdayOpenFromMidnight",
    };

    const closeMap = {
      0: "sundayCloseFromMidnight",
      1: "mondayCloseFromMidnight",
      2: "tuesdayCloseFromMidnight",
      3: "wednesdayCloseFromMidnight",
      4: "thursdayCloseFromMidnight",
      5: "fridayCloseFromMidnight",
      6: "saturdayCloseFromMidnight",
    };

    // Create a new object to trigger the watcher
    const newSettings = { ...this.bookingSettings };
    newSettings[openMap[this.selectedDay.weekdayIndex]] = openTime;
    newSettings[closeMap[this.selectedDay.weekdayIndex]] = closeTime;
    this.bookingSettings = newSettings;
  }

  async mounted() {
    try {
      this.isLoading = true;

      if (this.staffMember) {
        this.localStaffMember = this.staffMember;
      } else {
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await staffMemberModule.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find((staff) => staff.id == Number(staffId));

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }
      }

      await this.initializeWorkingHours();
    } catch (err) {
      this.error = err.message || "Failed to load working hours";
      console.error("Error loading working hours:", err);
    } finally {
      this.isLoading = false;
    }
  }

  async initializeWorkingHours() {
    try {
      this.isLoading = true;
      this.error = null;
      this.isInitializing = true;

      if (!this.localStaffMember) {
        throw new Error("No staff member selected");
      }

      // Reset all data first
      this.bookingSettings = null;
      this.isWorkingToday = false;
      this.dayIndex = 0;
      this.selectedDay = null;

      // Then load new data
      this.bookingSettings = await userModule.getBookingSettings(this.staffMemberId);

      await Promise.all([
        userModule.getBookingIntervalSettings(this.staffMemberId),
        workingDaysModule.getAllWorkingDays(this.staffMemberId),
        pauseTimeModule.getPauseTimes(this.staffMemberId),
      ]);

      this.isWorkingToday = this.allWorkingDays[this.days[0].weekdayIndex];
      this.selectDayToPickWorkingHours(this.days[0]); // Choose monday for editing by default
    } catch (err) {
      this.error = err.message || "Failed to initialize working hours";
      console.error("Error initializing working hours:", err);
    } finally {
      this.isLoading = false;
      this.$nextTick(() => {
        this.isInitializing = false;
      });
    }
  }

  public selectDayToPickWorkingHours(selectedDay) {
    this.selectedDay = selectedDay;
    this.dayIndex = this.days.indexOf(selectedDay);
    this.updateWorkingHoursAndWorkingDaysListOnDay(selectedDay);
    this.isWorkingToday = this.allWorkingDays[this.selectedDay.weekdayIndex];
  }

  public booleanToNorwegian(value) {
    return value ? this.$t(this.$ts.boolean.yes).toString() : this.$t(this.$ts.boolean.no).toString();
  }

  public async toggleWorkingDay(newValue: boolean): Promise<void> {
    try {
      if (!this.selectedDay) return;

      let payload = {
        dayOfWeek: this.selectedDay.weekDayName,
        isWorking: newValue,
        staffMemberId: this.staffMemberId,
      };

      await workingDaysModule.updateWorkingDays(payload);
      this.allWorkingDays[this.dayIndex] = newValue;
    } catch (err) {
      this.error = "Failed to update working day";
      console.error("Error updating working day:", err);
    }
  }

  public async updateWorkingHoursAndWorkingDaysListOnDay(day): Promise<void> {
    if (!day) return;
    this.dayIndex = day.weekDayIndex;
    this.isWorkingToday = this.allWorkingDays[this.dayIndex];
  }

  get pauseTimesOnSelectedWeekDay() {
    if (!this.pauseTimes?.filter || !this.selectedDay) return [];

    return this.pauseTimes.filter((x) => x.dayOfWeek == this.selectedDay.weekdayIndex);
  }

  async onDeletePause(id) {
    try {
      await pauseTimeModule.deletePauseTime(id);
    } catch (err) {
      this.error = "Failed to delete pause time";
      console.error("Error deleting pause time:", err);
    }
  }

  async addPause() {
    try {
      if (!this.selectedDay) return;

      const payload: IPostPauseTimeDTO = {
        staffId: this.staffMemberId,
        dayOfWeek: this.selectedDay.weekdayIndex,
        start: this.newPauseStart,
        end: this.newPauseEnd,
      };

      await pauseTimeModule.addPauseTime(payload);
      this.newPauseStart = "";
      this.newPauseEnd = "";
    } catch (err) {
      this.error = "Failed to add pause time";
      console.error("Error adding pause time:", err);
    }
  }
}
</script>

<style scoped>
.weekday-buttons-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: -16px -16px 0;
  padding: 16px;
}

.weekday-buttons {
  display: flex;
  min-width: min-content;
}

.weekday-btn {
  flex: 0 0 auto;
  min-width: 60px;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.weekday-buttons-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.weekday-buttons-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 600px) {
  .weekday-btn {
    min-width: 50px;
    padding: 0 8px;
  }
}
</style>
