<template>
  <v-container>

    <!-- Add Selection Filters -->
    <v-row align="center" class="mb-4" justify="space-between">
      <v-col cols="12" sm="6" md="4">
        <v-card flat>
          <v-card-text>
            <v-select
              v-model="selectedStaffMember"
              :items="staffMemberOptions"
              item-value="id"
              item-text="fullName"
              dense
              outlined
              hide-details
              :label="$t($ts.selectStaffMember)"
              class="header-select"
              @change="onStaffMemberChange"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-account-supervisor-circle</v-icon>
              </template>
            </v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8">
        <v-card flat>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="selectedYear"
                  :items="yearOptions"
                  dense
                  outlined
                  hide-details
                  class="header-select"
                  @change="refreshData"
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="4">
                <v-select
                  v-model="selectedMonth"
                  :items="monthOptions"
                  item-value="value"
                  item-text="text"
                  dense
                  outlined
                  hide-details
                  class="header-select"
                  @change="onMonthChange"
                >
                  <template v-slot:prepend>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="4" v-if="selectedMonth !== -1">
                <v-select
                  v-model="selectedDay"
                  :items="dayOptions"
                  item-value="value"
                  item-text="text"
                  dense
                  outlined
                  hide-details
                  class="header-select"
                  @change="refreshData"
                >
                  <template v-slot:prepend>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- Staff Member Profile Section -->
      <v-col cols="12" md="4">
        <v-card class="mb-4">
          <v-card-title class="headline d-flex justify-space-between">
            {{ staffMember ? `${staffMember.firstName} ${staffMember.lastName}` : $t($ts.staffMembersOverview.staff) }}
            <v-icon v-if="staffMember" small @click="editStaff" color="#866afe">mdi-pencil</v-icon>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-if="staffMember">
                <v-list-item-avatar size="100">
                  <v-img
                    v-if="staffMember.profilePictureUrl"
                    :src="staffMember.profilePictureUrl"
                    :alt="staffMember.firstName"
                  ></v-img>
                  <v-icon v-else size="100" color="grey lighten-1">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t($ts.form.fields.email) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ staffMember?.emailAddress || "-" }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t($ts.form.fields.phone) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ staffMember?.phoneNumber || "-" }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t($ts.form.fields.title) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ staffMember?.role || "-" }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t($ts.dtos.staffMemberFormDTO.eligibleForCommission.name)
                  }}</v-list-item-subtitle>
                  <v-list-item-title class="d-flex align-center">
                    <v-icon :color="staffMember?.eligibleForCommission ? 'success' : 'error'" class="mr-2">
                      {{ staffMember?.eligibleForCommission ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon>
                    {{ staffMember?.eligibleForCommission ? $t($ts.dialog.yes) : $t($ts.dialog.no) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Add Edit Dialog -->
        <v-dialog max-width="660" v-model="isShowingForm">
          <DynamicForm
            v-if="isShowingForm"
            :data="editingStaff"
            :metadata="staffMemberMetadata"
            :is-editing="true"
            :is-creating="false"
            :entity="$t(this.$ts.entities.staff)"
            :translation-path="nonReactiveTranslations"
            @cancel="isShowingForm = false"
            @save="onUpdateStaff"
          />
        </v-dialog>
      </v-col>

      <v-col cols="12" md="8">
        <!-- Services Provided Section -->
        <v-card class="mb-4">
          <v-card-title class="headline">
            {{ $t($ts.staffMemberManagement.servicesPerformed) }}
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" text @click="toggleServiceStats">
              {{ showServiceStats ? $t($ts.orders.items) : $t($ts.statistics.data) }}
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="!showServiceStats && !isLoading"
              :headers="serviceHeaders"
              :items="currentStatisticsServices"
              :items-per-page="5"
              class="elevation-1"
            >
              <template #[`item.date`]="{ item }">
                {{ item.isTotal ? "" : formatDate(item.date) }}
              </template>
              <template #[`item.revenue`]="{ item }">
                {{ formatCurrency(item.revenue) }}
              </template>
              <template #[`item.commission`]="{ item }">
                {{ formatCurrency(item.commission) }}
              </template>
              <template #[`item.name`]="{ item }">
                <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.name }}</span>
              </template>
              <template #[`item.count`]="{ item }">
                <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.count }}</span>
              </template>
              <template #item="{ item }">
                <tr :class="{ 'total-row': item.isTotal }">
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">
                      {{ item.isTotal ? "" : formatDate(item.date) }}
                    </span>
                  </td>
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.name }}</span>
                  </td>
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.count }}</span>
                  </td>
                  <td>{{ formatCurrency(item.revenue) }}</td>
                  <td>{{ formatCurrency(item.commission) }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-else-if="isLoading" class="pa-4">
              <v-skeleton-loader v-for="n in 5" :key="n" type="list-item-two-line" class="mb-2"></v-skeleton-loader>
            </div>
            <div v-else class="chart-container">
              <p class="text-center">Chart temporarily disabled</p>
            </div>
          </v-card-text>
        </v-card>

        <!-- Payment History Section -->
        <v-card class="mb-4">
          <v-card-title class="headline">
            {{ $t($ts.staffMemberManagement.paymentsReceived) }}
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" text @click="togglePaymentStats">
              {{ showPaymentStats ? $t($ts.orders.items) : $t($ts.statistics.data) }}
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="!showPaymentStats && !isLoading"
              :headers="paymentHeaders"
              :items="currentStatisticsPayments"
              :items-per-page="5"
              class="elevation-1"
            >
              <template #[`item.date`]="{ item }">
                {{ item.isTotal ? "" : formatDate(item.date) }}
              </template>
              <template #[`item.amount`]="{ item }">
                {{ formatCurrency(item.amount) }}
              </template>
              <template #item="{ item }">
                <tr>
                  <td>
                    <span>
                      {{ formatDate(item.date) }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ false ? $t($ts.orders.total) : $t($ts.expenseCategory[item.type]) }}
                    </span>
                  </td>
                  <td>{{ formatCurrency(item.amount) }}</td>
                  <td>{{ false ? "" : item.status }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-else-if="isLoading" class="pa-4">
              <v-skeleton-loader v-for="n in 5" :key="n" type="list-item-two-line" class="mb-2"></v-skeleton-loader>
            </div>
            <div v-else class="chart-container">
              <p class="text-center">Chart temporarily disabled</p>
            </div>
          </v-card-text>
        </v-card>

        <!-- Product Sales Section -->
        <v-card>
          <v-card-title class="headline">
            {{ $t($ts.staffMemberManagement.productsSold) }}
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" text @click="toggleProductStats">
              {{ showProductStats ? $t($ts.orders.items) : $t($ts.statistics.data) }}
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="!showProductStats && !isLoading"
              :headers="productHeaders"
              :items="currentStatisticsProducts"
              :items-per-page="5"
              class="elevation-1"
            >
              <template #[`item.date`]="{ item }">
                {{ item.isTotal ? "" : formatDate(item.date) }}
              </template>
              <template #[`item.revenue`]="{ item }">
                {{ formatCurrency(item.revenue) }}
              </template>
              <template #[`item.commission`]="{ item }">
                {{ formatCurrency(item.commission) }}
              </template>
              <template #item="{ item }">
                <tr :class="{ 'total-row': item.isTotal }">
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">
                      {{ item.isTotal ? "Total" : formatDate(item.date) }}
                    </span>
                  </td>
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.name }}</span>
                  </td>
                  <td>
                    <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.quantity }}</span>
                  </td>
                  <td>{{ formatCurrency(item.revenue) }}</td>
                  <td>{{ formatCurrency(item.commission) }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-else-if="isLoading" class="pa-4">
              <v-skeleton-loader v-for="n in 5" :key="n" type="list-item-two-line" class="mb-2"></v-skeleton-loader>
            </div>
            <div v-else class="chart-container">
              <p class="text-center">Chart temporarily disabled</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { IStaffMemberDTO, IStaffMemberStatisticsDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { staffMemberStatisticsModule } from "@/store/modules/staffMember/staffMemberStatisticsModuke";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import CreateCommissionsDialog from "@/components/expenses/CreateCommissionsDialog.vue";

@Component({
  name: "StaffMemberManagement",
  components: {
    DynamicForm,
    CreateCommissionsDialog,
  },
})
export default class StaffMemberManagement extends Vue {
  // Data properties
  selectedStaffMember: number | null = null;
  selectedYear = new Date().getFullYear();
  selectedMonth = new Date().getMonth() + 1;
  selectedDay = new Date().getDate();
  showServiceStats = false;
  showPaymentStats = false;
  showProductStats = false;
  isLoading = false;

  // Table headers
  serviceHeaders: any[] = [];
  paymentHeaders: any[] = [];
  productHeaders: any[] = [];

  private isShowingForm: boolean = false;
  private editingStaff: IStaffMemberDTO | null = null;

  get currentStatisticsServices() {
    const services = staffMemberStatisticsModule.currentStatisticsServices || [];
    if (services.length === 0) return [];

    // Calculate totals
    const totalRow = {
      date: null,
      name: this.$t(this.$ts.orders.total),
      count: services.reduce((sum, item) => sum + (item.count || 0), 0),
      revenue: services.reduce((sum, item) => sum + (item.revenue || 0), 0),
      commission: services.reduce((sum, item) => sum + (item.commission || 0), 0),
      isTotal: true,
    };

    return [...services, totalRow];
  }

  get currentStatisticsPayments() {
    return staffMemberStatisticsModule.currentStatisticsPayments;
  }

  get currentStatisticsProducts() {
    const products = staffMemberStatisticsModule.currentStatisticsProducts || [];
    if (products.length === 0) return [];

    // Calculate totals
    const totalRow = {
      date: null,
      name: this.$t(this.$ts.orders.total),
      quantity: products.reduce((sum, item) => sum + (item.quantity || 0), 0),
      revenue: products.reduce((sum, item) => sum + (item.revenue || 0), 0),
      commission: products.reduce((sum, item) => sum + (item.commission || 0), 0),
      isTotal: true,
    };

    return [...products, totalRow];
  }

  get hasMultipleStaff(): boolean {
    return staffMemberModule.hasMultipleStaff;
  }

  get staffMemberOptions() {
    return staffMemberModule.staffMembers;
  }

  get staffMember(): IStaffMemberDTO | undefined {
    const member = staffMemberModule.staffMembers.find((s) => s.id === this.selectedStaffMember);
    return member;
  }

  @Watch("statistics", { deep: true })
  onStatisticsChange(newVal: IStaffMemberStatisticsDTO) {}

  async onStaffMemberInput(value: number) {}

  async onStaffMemberChange() {
    await this.refreshData();
    this.showServiceStats = false;
    this.showPaymentStats = false;
    this.showProductStats = false;
  }

  async refreshData() {
    this.isLoading = true;

    try {
      const params = {
        staffMemberId: this.selectedStaffMember,
        year: this.selectedYear,
        month: this.selectedMonth,
        day: this.selectedDay,
      };

      await staffMemberStatisticsModule.getStaffMemberStatistics(params);
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  onMonthChange() {
    const currentDate = new Date();
    const isCurrentMonth =
      this.selectedYear === currentDate.getFullYear() && this.selectedMonth === currentDate.getMonth() + 1;

    this.selectedDay = isCurrentMonth ? currentDate.getDate() : 1;
    this.refreshData();
  }

  toggleServiceStats() {
    this.showServiceStats = !this.showServiceStats;
  }

  togglePaymentStats() {
    this.showPaymentStats = !this.showPaymentStats;
  }

  toggleProductStats() {
    this.showProductStats = !this.showProductStats;
  }

  formatDate(date: Date | null) {
    if (!date) return "";
    return new Date(date).toLocaleDateString();
  }

  formatCurrency(amount: number) {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(amount);
  }

  get yearOptions() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  get monthOptions() {
    return [
      { value: -1, text: this.$t(this.$ts.statistics.allMonths) },
      { value: 1, text: this.$t(this.$ts.months.january) },
      { value: 2, text: this.$t(this.$ts.months.february) },
      { value: 3, text: this.$t(this.$ts.months.march) },
      { value: 4, text: this.$t(this.$ts.months.april) },
      { value: 5, text: this.$t(this.$ts.months.may) },
      { value: 6, text: this.$t(this.$ts.months.june) },
      { value: 7, text: this.$t(this.$ts.months.july) },
      { value: 8, text: this.$t(this.$ts.months.august) },
      { value: 9, text: this.$t(this.$ts.months.september) },
      { value: 10, text: this.$t(this.$ts.months.october) },
      { value: 11, text: this.$t(this.$ts.months.november) },
      { value: 12, text: this.$t(this.$ts.months.december) },
    ];
  }

  get dayOptions() {
    if (this.selectedMonth === -1) return [];

    const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    const options = [{ value: -1, text: this.$t(this.$ts.all) }];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(this.selectedYear, this.selectedMonth - 1, i);
      options.push({
        value: i,
        text: date.toLocaleDateString(this.$i18n.locale),
      });
    }
    return options;
  }

  async created() {
    // Initialize table headers
    this.serviceHeaders = [
      { text: this.$t(this.$ts.appointment.time), value: "date" },
      { text: this.$t(this.$ts.service), value: "name" },
      { text: this.$t(this.$ts.statistics.appointments), value: "count" },
      { text: this.$t(this.$ts.statistics.earnings), value: "revenue" },
      { text: this.$t(this.$ts.statistics.commission), value: "commission" },
    ];

    this.paymentHeaders = [
      { text: this.$t(this.$ts.appointment.time), value: "date" },
      // { text: this.$t(this.$ts.appointment.service), value: "type" },
      { text: this.$t(this.$ts.statistics.earnings), value: "amount" },
      // { text: this.$t(this.$ts.appointment.appointmentStatus), value: "status" },
    ];

    this.productHeaders = [
      { text: this.$t(this.$ts.appointment.time), value: "date" },
      { text: this.$t(this.$ts.orders.item), value: "name" },
      { text: this.$t(this.$ts.orders.quantity), value: "quantity" },
      { text: this.$t(this.$ts.statistics.earnings), value: "revenue" },
      { text: this.$t(this.$ts.statistics.commission), value: "commission" },
    ];

    try {
      this.isLoading = true;
      await staffMemberModule.getStaffMembers();
      const staffMembers = staffMemberModule.staffMembers;

      if (staffMembers && staffMembers.length > 0) {
        this.selectedStaffMember = staffMembers[0].id;

        // Wait for the next tick before refreshing data
        await this.$nextTick();
        if (this.selectedStaffMember) {
          await this.refreshData();
        }
      } else {
      }
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.staffMemberFormDTO });
  }

  get staffMemberMetadata() {
    return metadataModule.staffMemberMetadata;
  }

  editStaff(): void {
    if (this.staffMember) {
      this.editingStaff = { ...this.staffMember };
      this.isShowingForm = true;
    }
  }

  async onUpdateStaff(staff: Partial<IStaffMemberDTO>): Promise<void> {
    try {
      const edit : Partial<IStaffMemberDTO> = {
        firstName: staff.firstName,
        lastName: staff.lastName,
        emailAddress: staff.emailAddress,
        phoneNumber: staff.phoneNumber,
        role: staff.role,
        commissionPercentageProducts: staff.commissionPercentageProducts,
        commissionPercentageServices: staff.commissionPercentageServices,
        eligibleForCommission: staff.eligibleForCommission,
        eligibleForCommissionProducts: staff.eligibleForCommissionProducts,
        eligibleForCommissionServices: staff.eligibleForCommissionServices,
        overrideServicesCommission: staff.overrideServicesCommission,
        overrideProductsCommission: staff.overrideProductsCommission,
      };

      //Fix... auto generate patch types.
      let patch: any = {
        staffMemberId: staff.id,
        patch: edit,
      };
      await staffMemberModule.patchStaffMember(patch);
      this.isShowingForm = false;
      this.editingStaff = null;

      // Refresh the staff member data
      if (this.selectedStaffMember) {
        await this.onStaffMemberChange();
      }
    } catch (error) {
      console.error(error);
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.staffMembersOverview.errorUpdatingStaff).toString(),
      });
    }
  }
}
</script>

<style scoped>
.v-card {
  border-radius: 8px;
}

.headline {
  font-size: 1.25rem !important;
  font-weight: 500;
}

.v-list-item-subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}

.chart-container {
  position: relative;
  height: 300px;
}

/* Styles for total rows in services and orders tables */
.total-row td {
  background-color: #f5f5f5 !important;
  font-weight: bold;
  border-top: 2px solid #000000;
}
</style>
