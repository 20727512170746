<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/global/Sidebar.vue";
import HeaderBar from "@/components/global/Sidebar.vue";
import { Action, Getter, Mutation } from "vuex-class";
import SelectWorktime from "@/components/global/selectWorktime.vue";
import Treatment_types from "@/components/Settings/Services/TreatmentTypes.vue";
import RegisterUser from "@/components/global/onboarding/RegisterUser.vue";
import RegisterCompany from "@/components/global/onboarding/RegisterCompany.vue";
import { userModule } from "@/store/modules/user/userModule";
import { workingDaysModule } from "@/store/modules/workingDays/workingDaysModule";

@Component({
  name: "Onboarding",
  components: {
    RegisterCompany,
    RegisterUser,
    Treatment_types,
    SelectWorktime,
    Sidebar,
    HeaderBar,
  },
})
export default class Onboarding extends Vue {
  public defaultColor: string = "#876AFF";
  public el = -1;
  public currentProgress: string;

  async getCurrentUser() {
    await userModule.getCurrentUser();
  }

  async getAllWorkingDays() {
    await workingDaysModule.getAllWorkingDays();
  }

  validateCompanyInputBeforeNextStep(isValidated) {
    this.completeOnboarding();
  }

  validateUserInputBeforeNextStep(isValidated) {
    isValidated ? (this.el = 2) : (this.el = 1);
  }

  completeOnboarding(): void {
    this.$router.push("/admin").catch(() => {});
  }

  setBodyBacckgroundOnMobile(color: string): void {
    let windowWidth: number = window.innerWidth;
    if (windowWidth < 800) {
      document.body.style.backgroundColor = color;
    }
  }

  //Lifecycle
  public async created(): Promise<any> {
    this.setBodyBacckgroundOnMobile("white");
    this.el = 1;
    await this.getCurrentUser();
    await this.getAllWorkingDays();
  }

  destroyed(): void {
    this.setBodyBacckgroundOnMobile("#ECECF3");
  }
}
</script>

<template>
  <div>
    <div class="content-onboarding">
      <v-stepper v-model="el">
        <v-stepper-header>
          <v-stepper-step :complete="el > 1" step="1" :color="defaultColor">{{
            $t($ts.onboarding.user)
          }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="el > 2" step="2" :color="defaultColor">{{
            $t($ts.onboarding.company)
          }}</v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
          <!-- CREATE A USER -->
          <v-stepper-content step="1">
            <h2 class="user">{{ $t($ts.onboarding.yourUser) }}</h2>
            <br />
            <register-user @register-user-click="validateUserInputBeforeNextStep" :currentStep="el"></register-user>
          </v-stepper-content>

          <!-- CREATE A COMPANY -->
          <v-stepper-content step="2">
            <h2 class="company">{{ $t($ts.onboarding.yourCompany) }}</h2>
            <br />
            <register-company @register-user-click="validateCompanyInputBeforeNextStep" :el="el"></register-company>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-onboarding {
  float: left;
  width: 100%;
  height: auto;
  padding: 70px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.primary {
  background: #876aff !important;
}
.v-card {
  box-shadow: none !important;
}
.mb-5 {
  padding: 10px;
}
h2 {
  padding: 5px 33px;
  color: #876aff;
  &::before {
    position: relative;
    top: 5px;
    right: 17px;
  }
}
.company:before {
  content: url("/img/business-onboarding.png");
}
.user:before {
  content: url("/img/user-onboarding.png");
}
.timeformat:before {
  content: url("/img/24-hours-onboarding.png");
}
.appointment-type:before {
  content: url("/img/service-onboarding.png");
}
.v-stepper__step__step {
  box-shadow: 0px 0px 9px 1px #5740b580;
}

.primary {
  background: #876aff !important;
  margin-top: 50px;
  margin-left: 25px;
}

@media only screen and (max-width: 1264px) {
  body {
    background: white !important;
  }
  .content-onboarding {
    background: yellow;
    padding: 0;
  }
  .v-stepper {
    box-shadow: none;
  }
}
</style>
