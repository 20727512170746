import axios, { AxiosRequestConfig } from "axios";
import authConfig from "@/Utilities/authConfig";
import { IRouteDefinition, HttpVerb } from "@shared/types";

interface RequestOptions {
  params?: Record<string, string | number>;
  data?: any;
  config?: AxiosRequestConfig;
  query?: Record<string, string | number | boolean>;
}

export class HttpService {
  private static instance: HttpService;
  private baseUrl: string;

  private constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public static getInstance(baseUrl: string): HttpService {
    if (!HttpService.instance) {
      HttpService.instance = new HttpService(baseUrl);
    }
    return HttpService.instance;
  }

  private getUrl(endpoint: string): string {
    return `${this.baseUrl}${endpoint}`;
  }

  public buildUrl(base: string, route: string, params?: Record<string, string | number>): string {
    let fullUrl = `${base}/${route}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        fullUrl = fullUrl.replace(`{${key}}`, params[key].toString());
      });
    }
    return fullUrl;
  }

  private buildQueryString(query?: string | Record<string, string | number | boolean>): string {
    if (!query) return "";
    if (typeof query === "string") return query;

    const params = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        params.append(key, value.toString());
      }
    });
    const queryString = params.toString();
    return queryString ? `?${queryString}` : "";
  }

  public buildRouteUrl(route: IRouteDefinition, options?: RequestOptions): string {
    let fullUrl = route.url;
    if (options?.params) {
      Object.keys(options.params).forEach((key) => {
        const value = options.params![key];
        if (value !== undefined && value !== null) {
          fullUrl = fullUrl.replace(`{${key}}`, value.toString());
        }
      });
    }

    return fullUrl + this.buildQueryString(options?.query);
  }

  public async sendRequest<T>(route: IRouteDefinition, options: RequestOptions = {}): Promise<T> {
    const url = this.buildRouteUrl(route, options);
    console.log(url);

    const authHeader = await this.getAuthHeader();
    const config = {
      ...authHeader,
      ...options.config,
      headers: {
        ...authHeader.headers,
        ...(options.config?.headers || {}),
      },
    };

    switch (route.method) {
      case HttpVerb.Get:
        return this.getAuthenticated<T>(url, config);
      case HttpVerb.Post:
        return this.postAuthenticated<T>(url, options.data, config);
      case HttpVerb.Put:
        return this.putAuthenticated<T>(url, options.data, config);
      case HttpVerb.Delete:
        return this.deleteAuthenticated<T>(url, config);
      case HttpVerb.Patch:
        return this.patchAuthenticated<T>(url, options.data, config);
      default:
        throw new Error(`Unsupported HTTP method: ${route.method}`);
    }
  }

  public async sendFormDataRequest<T>(route: IRouteDefinition, options: RequestOptions = {}): Promise<T> {
    const url = this.buildRouteUrl(route, options);

    switch (route.method) {
      case HttpVerb.Put:
        return this.putAuthenticatedFormData<T>(url, options.data, options.config);
      default:
        throw new Error(`Unsupported HTTP method: ${route.method}`);
    }
  }

  private async getAuthHeader(): Promise<AxiosRequestConfig> {
    return await authConfig();
  }

  // Anonymous requests
  async get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.get<T>(this.getUrl(endpoint), config);
    return response.data;
  }

  async post<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.post<T>(this.getUrl(endpoint), data, config);
    return response.data;
  }

  async put<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.put<T>(this.getUrl(endpoint), data, config);
    return response.data;
  }

  async delete<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.delete<T>(this.getUrl(endpoint), config);
    return response.data;
  }

  async patch<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.patch<T>(this.getUrl(endpoint), data, config);
    return response.data;
  }

  // Authenticated requests
  async getAuthenticated<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.get<T>(this.getUrl(endpoint), {
      ...authHeader,
      ...config,
    });
    return response.data;
  }

  async postAuthenticated<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.post<T>(this.getUrl(endpoint), data, {
      ...authHeader,
      ...config,
    });
    return response.data;
  }

  async putAuthenticatedFormData<T>(endpoint: string, formData: any, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.put<T>(this.getUrl(endpoint), formData, {
      ...authHeader,
      headers: {
        ...authHeader.headers,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      ...config,
    });
    return response.data;
  }

  async putAuthenticated<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.put<T>(this.getUrl(endpoint), data, {
      ...authHeader,
      ...config,
    });
    return response.data;
  }

  async deleteAuthenticated<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.delete<T>(this.getUrl(endpoint), {
      ...authHeader,
      ...config,
    });
    return response.data;
  }

  async patchAuthenticated<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const authHeader = await this.getAuthHeader();
    const response = await axios.patch<T>(this.getUrl(endpoint), data, {
      ...authHeader,
      ...config,
    });
    return response.data;
  }
}
