<template>
  <BaseFormDialog
    ref="baseForm"
    :title="overrideHeader || title"
    :is-editing="isEditing"
    :is-creating="isCreating"
    :is-saving="isSaving"
    :save-text="saveText"
    :create-text="createText"
    :cancel-text="cancelText"
    :create-button-disabled="createButtonDisabled"
    @save="onSave"
    @create="onCreate"
    @cancel="onCancel"
  >
    <div v-if="renderChildren" v-for="field in metadata" :key="field.name">
      <div v-if="shouldShowField(field)">
        <CompanyInfoField
          :label="getFieldLabel(field)"
          :description="getFieldDescription(field)"
          :isSwitch="isSwitch(field)"
        >
          <component
            :is="getFieldComponent(field)"
            v-model="formData[toCamelCase(field.name)]"
            v-bind="getFieldProps(field)"
            :label="getLabel(field)"
            :hint="getFieldDescription(field)"
            :data-cy="`form-field-${String(toCamelCase(field.name))}`"
            @update:modelValue="(value) => onFieldInput(field.name, value)"
          />
        </CompanyInfoField>
        <v-divider />
      </div>
    </div>
    <!-- Custom content slot -->
    <slot name="custom-fields"></slot>
  </BaseFormDialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import DatePickerField from "@/components/global/DatePickerField.vue";
import { FormGeneratorService } from "@/services/FormGeneratorService";
import { ValidationStrategyService } from "@/services/ValidationStrategyService";
import { IFieldMetadata, DisplayType, ValidationType } from "@shared/types";
import { metadataModule } from "@/store/modules/metadata/metadataModule";

@Component({
  name: "dynamic-form",
  components: {
    CompanyInfoField,
    Textfield,
    BaseFormDialog,
    DatePickerField,
  },
})
export default class DynamicForm extends Vue {
  @Prop({ required: true }) data!: any;
  @Prop({ required: true }) isEditing!: boolean;
  @Prop({ required: true }) isCreating!: boolean;
  @Prop({ required: true }) translationPath!: {
    [key: string]: { name: string; description: string };
  };
  @Prop({ required: true }) metadata!: IFieldMetadata[];
  @Prop({ required: false }) entity?: string;
  @Prop({ required: false }) overrideHeader?: string;
  @Prop({ required: false, default: true }) renderChildren?: boolean;
  @Prop({ required: false, default: false }) createButtonDisabled?: boolean;

  private formData: { [key: string]: any } = {};
  private isSaving = false;
  private formGenerator = new FormGeneratorService();
  private validationService = new ValidationStrategyService();
  private DisplayType = DisplayType;

  get title(): string {
    if (this.isEditing) {
      return this.$t(this.$ts.actions.edit).toString() + " " + this.$t(this.entity).toString();
    }
    if (this.isCreating) {
      return this.$t(this.$ts.actions.create).toString() + " " + this.$t(this.entity).toString();
    }
    return this.$t(this.isEditing ? this.$ts.createService.editTitle : this.$ts.createService.title).toString();
  }

  get saveText(): string {
    return this.$t(this.$ts.buttons.save).toString();
  }

  get createText(): string {
    return this.$t(this.$ts.buttons.create).toString();
  }

  get cancelText(): string {
    return this.$t(this.$ts.buttons.cancel).toString();
  }

  @Watch("data", { immediate: true, deep: true })
  onDataChange(val: any): void {
    this.formData = { ...val };
  }

  toCamelCase(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1).replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
  }

  getFieldLabel(field: IFieldMetadata): string {
    const fieldName = this.toCamelCase(field.name);
    return this.$t(this.translationPath[fieldName]?.name || this.toCamelCase(field.name)).toString();
  }

  getFieldDescription(field: IFieldMetadata): string {
    const fieldName = this.toCamelCase(field.name);
    return this.$t(this.translationPath[fieldName]?.description || this.toCamelCase(field.name)).toString();
  }

  getFieldComponent(field: IFieldMetadata): string {
    return this.formGenerator.getFieldComponent(field.type as DisplayType);
  }

  getFieldProps(field: IFieldMetadata): object {
    const props = this.formGenerator.getFieldProps(field);

    if (field.type === DisplayType.Number) {
      props.type = "number";
      props.number = true;
    }

    if (field.validationRules?.length) {
      const fieldName = this.toCamelCase(field.name);
      props.rules = field.validationRules.map((rule) => {
        return this.validationService.createValidator(rule, fieldName);
      });
      props.name = fieldName;
      props.validateOnBlur = true;
      props.required = field.validationRules.some((rule) => rule.type === ValidationType.Required);
    } else {
      props.rules = [];
    }

    return props;
  }

  shouldShowField(field: IFieldMetadata): boolean {
    if (!field.showWhen) return true;
    const dependentField = this.toCamelCase(field.showWhen);
    return !!this.formData[dependentField];
  }

  onFieldInput(fieldName: string, value: any): void {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
    const camelFieldName = this.toCamelCase(fieldName);
    this.$emit("field-change", {
      name: camelFieldName,
      value: value,
    });
  }

  onCancel(): void {
    this.$emit("cancel");
  }

  async onSave(): Promise<void> {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.$emit("save", this.prepareFormData());
    }
  }

  async onCreate(): Promise<void> {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.$emit("create", this.prepareFormData());
    }
  }

  resetSavingState(): void {
    this.isSaving = false;
  }

  private prepareFormData(): any {
    const data = { ...this.formData };
    if (data.color && typeof data.color === "object") {
      data.color = (data.color as any).hex;
    }
    return data;
  }

  isSwitch(field: IFieldMetadata): boolean {
    return field.type === DisplayType.Switch;
  }

  getLabel(field: IFieldMetadata): string | number {
    return field.type === DisplayType.Switch
      ? this.formGenerator.formatFieldValue(field.type, this.formData[this.toCamelCase(field.name)])
      : this.getFieldLabel(field);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
