import { VuexModule, Module, Action } from "vuex-class-modules";
import { AdminService } from "@/services/api/AdminService";
import { IFirebaseUserDTO, IFirebaseUserRecordDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class AdminModule extends VuexModule {
  private readonly adminService: AdminService;

  constructor(options: any) {
    super(options);
    this.adminService = AdminService.getInstance();
  }

  // State
  users: IFirebaseUserRecordDTO[] = [];

  @Action
  async getToken(uid: string): Promise<string> {
    return await this.adminService.getToken(uid);
  }

  @Action
  async getUsers(): Promise<IFirebaseUserRecordDTO[]> {
    this.users = await this.adminService.getUsers();
    return this.users;
  }
}

export const adminModule = new AdminModule({ store, name: "adminModule" });
