<template>
  <div v-if="shouldShowHeader && isMobile">
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      style="height: 56px"
      class="route-header"
    >
      <v-container>
        <v-row align="center" no-gutters>
          <v-col cols="2">
            <v-btn icon @click="goBack">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center">
            <span class="text-subtitle-1 font-weight-medium">{{
              currentRouteName
            }}</span>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <!-- Add spacing to prevent content from hiding under the fixed header -->
    <div style="height: 56px"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  name: "RouteHeader",
})
export default class RouteHeader extends Vue {
  get shouldShowHeader(): boolean {
    const path = this.$route.path;
    const segments = path.split("/").filter((segment) => segment);
    return segments.length >= 2 && !this.$route.meta?.hideMobileHeader;
  }

  get currentRouteName(): string {
    return this.$t(this.$ts.routes + "." + this.$route.name).toString() || "";
  }

  get isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  goBack(): void {
    const segments = this.$route.path.split("/").filter((segment) => segment);
    if (segments.length >= 1) {
      this.$router.push(`/${segments[0]}`).catch(() => {});
    }
  }
}
</script>

<style scoped>
.route-header {
  top: 0;
  z-index: 100;
}
</style>
