<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          :label="label"
          :hint="hint"
          :rules="rules"
          :required="required"
          :error-messages="errorMessages"
          :disabled="disabled"
          :style="textFieldStyle"
          class="format-box"
          readonly
          outlined
          dense
          :prepend-icon="prependIcon"
          validate-on-blur
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="localValue" @input="handleDateSelection" :max="maxDate" :min="minDate"></v-date-picker>
    </v-menu>
    <p v-if="errorMessages.length > 0" class="error--text">
      {{ errorMessages[0] }}
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "date-picker-field",
})
export default class DatePickerField extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "" }) hint!: string;
  @Prop({ default: "mdi-calendar" }) prependIcon!: string;
  @Prop({ default: () => new Date().toISOString().substr(0, 10) }) maxDate!: string;
  @Prop({ default: "" }) minDate!: string;
  @Prop({ default: () => [] }) rules!: Array<(value: any) => boolean | string>;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => [] }) errorMessages!: string[];

  private menu = false;
  private localValue = "";

  get textFieldStyle() {
    return {
      borderRadius: "8px",
      maxWidth: "250px",
      minWidth: "250px",
    };
  }

  created() {
    this.setLocalValue(this.value);
  }

  @Watch("value")
  onValueChange(newValue: string) {
    this.setLocalValue(newValue);
  }

  private setLocalValue(value: string) {
    // Handle empty or invalid values
    if (!value) {
      this.localValue = "";
      return;
    }

    try {
      // Try to parse the date and format it as YYYY-MM-DD
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        this.localValue = "";
        return;
      }
      this.localValue = date.toISOString().split("T")[0];
    } catch {
      this.localValue = "";
    }
  }

  get formattedDate(): string {
    if (!this.localValue) return "";
    try {
      const date = new Date(this.localValue);
      if (isNaN(date.getTime())) return "";
      return date.toLocaleDateString(this.$i18n?.locale || 'en');
    } catch {
      return "";
    }
  }

  handleDateSelection(date: string) {
    this.menu = false;
    if (!date) {
      this.localValue = "";
      this.$emit("input", "");
      this.$emit("update:modelValue", "");
      return;
    }

    try {
      const selectedDate = new Date(date);
      if (isNaN(selectedDate.getTime())) {
        this.localValue = "";
        this.$emit("input", "");
        this.$emit("update:modelValue", "");
        return;
      }

      const formattedDate = selectedDate.toISOString().split("T")[0];
      this.localValue = formattedDate;
      this.$emit("input", formattedDate);
      this.$emit("update:modelValue", formattedDate);
    } catch {
      this.localValue = "";
      this.$emit("input", "");
      this.$emit("update:modelValue", "");
    }
  }
}
</script>
