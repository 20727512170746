<template>
  <v-dialog
    v-model="dialogVisible"
    :close-on-content-click="false"
    fullscreen
    transition="dialog-bottom-transition"
    style="border-radius: 0px"
  >
    <v-card>
      <v-toolbar style="border-radius: 0px" dark color="#7441ff">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t($ts.mobileHeader.settings) }}</v-toolbar-title>
      </v-toolbar>
      <settings-mobile />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SettingsMobile from "../../pages/admin/SettingsMobile.vue";
import AuditLogger, { AuditLoggerType } from "../../mixins/AuditLogger";

@Component({
  name: "SettingsDialog",
  components: { SettingsMobile },
  mixins: [AuditLogger],
})
export default class SettingsDialog extends Vue implements AuditLoggerType {
  @Prop({ required: true }) value!: boolean;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get dialogVisible(): boolean {
    return this.value;
  }

  set dialogVisible(value: boolean) {
    this.$emit("input", value);
  }

  closeDialog(): void {
    this.logAudit(" closed settings dialog");
    this.dialogVisible = false;
  }
}
</script>
