import { EventBaseService } from "./Base/EventBaseService";
import {
  IUserController,
  IUserDTO,
  IPostInitialProfileDTO,
  IPostUserDTO,
  ICalendarInterval,
  IUserBookingSettingsAsHoursAndMinutesDTO,
  IStaffMemberCalendarIntervalsDTO,
  IPostFreshUserDTO,
} from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class UserService extends EventBaseService implements IUserController {
  private static instance: UserService;
  private controller = "user";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public async getCurrentUser() {
    return this.http.getAuthenticated<IUserDTO>(`${this.controller}/current`);
  }

  public async getUser(userId: string) {
    return this.http.get<IUserDTO>(`${this.controller}/${userId}`);
  }

  public async getUserByUsername(companyName: string) {
    return this.http.get<IUserDTO>(`${this.controller}/ByUser/${companyName}`);
  }

  public async putUserProfileOnly(userDto: IPostInitialProfileDTO) {
    return this.withEventEmission(Feature.User, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current/profile`, userDto)
    );
  }

  public async patchUser(patchDoc: JsonPatchOperation[]) {
    return this.withEventEmission(Feature.User, EventType.Updated, () =>
      this.http.patchAuthenticated<void>(`${this.controller}/current`, patchDoc)
    );
  }

  public async putUser(userDto: IPostUserDTO) {
    return this.withEventEmission(Feature.User, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current`, userDto)
    );
  }

  public async postUser(userDTO: IPostFreshUserDTO) {
    return this.withEventEmission(Feature.User, EventType.Added, () =>
      this.http.postAuthenticated<void>(`${this.controller}/current`, userDTO)
    );
  }

  public async deleteCompanyEmployee(id: string) {
    return this.withEventEmission(Feature.User, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/${id}`)
    );
  }

  public async getBookingIntervalSettings(staffMemberId?: number) {
    let url = `${this.controller}/current/bookingSettings/intervals`;
    if (staffMemberId !== undefined) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IStaffMemberCalendarIntervalsDTO[]>(url);
  }

  public async getBookingSettings(staffMemberId?: number) {
    let url = `${this.controller}/current/bookingSettings`;
    if (staffMemberId !== undefined) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IUserBookingSettingsAsHoursAndMinutesDTO>(url);
  }

  public debugLogForChangeOpeningTime(
    originalOpen: number,
    originalClose: number,
    dayOfWeek: string,
    open: number,
    close: number,
    openString: string,
    closeString: string,
    fbUser: any,
    staff: any,
    user: any
  ) {
    console.log("Debug log for change opening time:", {
      originalOpen,
      originalClose,
      dayOfWeek,
      open,
      close,
      openString,
      closeString,
      fbUser,
      staff,
      user,
    });
  }

  public async putBookingSettings(
    bookingSettingsDto: IUserBookingSettingsAsHoursAndMinutesDTO,
    staffMemberId?: number
  ) {
    let url = `${this.controller}/current/bookingSettings`;
    if (staffMemberId !== undefined) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.withEventEmission(Feature.User, EventType.Updated, () =>
      this.http.putAuthenticated<void>(url, bookingSettingsDto)
    );
  }

  public async postPicture(file: any) {
    return this.withEventEmission(Feature.Image, EventType.Uploaded, () =>
      this.http.putAuthenticatedFormData<void>(`${this.controller}/current/Picture`, file)
    );
  }
}
