import { BaseService } from "./Base/BaseService";
import { ICustomFieldController, ICustomFieldDTO } from "@shared/types";

export class CustomFieldService
  extends BaseService
  implements ICustomFieldController
{
  private static instance: CustomFieldService;
  private controller = "CustomField"; // Matches backend route api/CustomField

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!CustomFieldService.instance) {
      CustomFieldService.instance = new CustomFieldService();
    }
    return CustomFieldService.instance;
  }

  public async get() {
    return this.http.getAuthenticated<ICustomFieldDTO[]>(
      `${this.controller}/current`
    );
  }

  public async getAsAnonymous(userId: string) {
    return this.http.get<ICustomFieldDTO[]>(
      `${this.controller}/asAnonymous/${userId}`
    );
  }

  public async put(DTO: ICustomFieldDTO) {
    return this.http.putAuthenticated<void>(`${this.controller}/current`, DTO);
  }

  public async post(DTO: ICustomFieldDTO) {
    return this.http.postAuthenticated<void>(`${this.controller}/current`, DTO);
  }

  public async delete(id: number) {
    return this.http.deleteAuthenticated<void>(
      `${this.controller}/current?id=${id}`
    );
  }
}
