<template>
  <v-container>
    <div v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <template v-else>
      <template v-if="isSingleStaffMember">
        <v-alert type="info" variant="tonal">{{ $t($ts.settings.staffSettings.alerts.singleStaffBanner) }}</v-alert>
      </template>

      <v-card flat>
        <v-card-text>
          <p class="text-subtitle-1 mb-4">
            {{ $t($ts.settings.staffSettings.alerts.notificationPreference) }}
          </p>
          <p class="text-caption mb-4 grey--text">
            {{ $t($ts.settings.staffSettings.alerts.notificationHint) }}
          </p>

          <v-radio-group
            :disabled="isSingleStaffMember"
            v-model="selectedNotificationType"
            @change="handleNotificationChange"
          >
            <v-radio :label="$t($ts.settings.staffSettings.alerts.none)" :value="NotificationType.None"></v-radio>
            <v-radio :label="$t($ts.settings.staffSettings.alerts.email)" :value="NotificationType.Email"></v-radio>
            <v-radio
              :label="$t($ts.settings.staffSettings.alerts.whatsapp)"
              :value="NotificationType.Whatsapp"
            ></v-radio>
            <v-radio :label="$t($ts.settings.staffSettings.alerts.sms)" :value="NotificationType.Sms"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { IStaffMemberDTO, NotificationType } from "@shared/types";
import { IStaffMemberPatch, staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";

@Component({
  name: "StaffMemberAlertSettings",
})
export default class StaffMemberAlertSettings extends Vue {
  @Prop({}) staffMember!: IStaffMemberDTO;

  NotificationType = NotificationType;

  public selectedNotificationType = NotificationType.None;
  public isLoading = false;
  public error: string | null = null;
  public localStaffMember: IStaffMemberDTO | null = null;

  @Watch("staffMember")
  onStaffMemberChange(newStaffMember: IStaffMemberDTO) {
    if (newStaffMember) {
      this.localStaffMember = newStaffMember;
      this.initializeData();
    }
  }

  get isSingleStaffMember(): boolean {
    return staffMemberModule.staffMembers.length === 1;
  }

  async handleNotificationChange(value: NotificationType) {
    if (!this.localStaffMember) return;

    const patch: IStaffMemberPatch = {
      staffMemberId: this.localStaffMember.id,
      patch: {
        notificationType: value,
      },
    };
    await staffMemberModule.patchStaffMember(patch);
  }

  async mounted() {
    this.isLoading = true;
    try {
      if (this.staffMember) {
        this.localStaffMember = this.staffMember;
        this.initializeData();
      }
    } catch (err) {
      this.error = err.message || "Failed to load staff member";
      console.error("Error loading staff member:", err);
    } finally {
      this.isLoading = false;
    }
  }

  initializeData() {
    if (!this.localStaffMember) return;
    this.selectedNotificationType = this.localStaffMember.notificationType || NotificationType.None;
  }
}
</script>

<style scoped>
.v-card {
  border-radius: 8px;
}
</style>
