<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline primary--text">
        {{ $t($ts.moneyAccountOverview.manualEntry) }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="amount"
                :label="$t($ts.moneyAccountOverview.amount)"
                type="number"
                outlined
                dense
                :error-messages="amountErrors"
                @input="validateAmount"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="description"
                :label="$t($ts.moneyAccountOverview.description)"
                outlined
                dense
                :error-messages="descriptionErrors"
                @input="validateDescription"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">
          {{ $t($ts.buttons.cancel) }}
        </v-btn>
        <v-btn 
          color="primary" 
          text 
          @click="saveEntry"
          :disabled="!isValid"
        >
          {{ $t($ts.buttons.save) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import {
  ILedgerEntryDTO,
  LedgerEntrySourceType,
  LedgerEntryType,
  LedgerEntryStatus,
} from "@shared/types";

@Component
export default class ManualLedgerEntryDialog extends Vue {
  @Prop({ required: true, type: Boolean }) value!: boolean;
  @Prop({ required: true }) moneyAccountId!: number;

  private amount = "";
  private description = "";
  private amountErrors: string[] = [];
  private descriptionErrors: string[] = [];

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit("input", value);
  }

  get isValid(): boolean {
    return this.amountErrors.length === 0 && 
           this.descriptionErrors.length === 0 && 
           this.amount !== "" && 
           this.description !== "";
  }

  mounted() {
    this.resetForm();
  }

  @Watch("value")
  onDialogOpen(newValue: boolean) {
    if (newValue) {
      this.resetForm();
    }
  }

  private resetForm() {
    this.amount = "";
    this.description = "";
    this.amountErrors = [];
    this.descriptionErrors = [];
  }

  private validateAmount() {
    this.amountErrors = [];
    const numAmount = parseFloat(this.amount);
    
    if (!this.amount) {
      this.amountErrors.push(this.$t(this.$ts.validation.required).toString());
    } else if (isNaN(numAmount)) {
      this.amountErrors.push(this.$t(this.$ts.validation.number).toString());
    } else if (numAmount <= 0) {
      this.amountErrors.push(this.$t(this.$ts.validation.min, { value: 0 }).toString());
    }
  }

  private validateDescription() {
    this.descriptionErrors = [];
    
    if (!this.description) {
      this.descriptionErrors.push(this.$t(this.$ts.validation.required).toString());
    }
  }

  private closeDialog() {
    this.dialog = false;
    this.resetForm();
  }

  private async saveEntry() {
    try {
      if (!this.isValid) return;

      const entry: ILedgerEntryDTO = {
        id: 0,
        amount: parseFloat(this.amount),
        customerId: -1,
        dateTime: new Date().toISOString(),
        description: this.description,
        sourceId: -1,
        sourceType: LedgerEntrySourceType.Manual,
        status: LedgerEntryStatus.Paid,
        type: LedgerEntryType.Payment,
        moneyAccountId: this.moneyAccountId,
      };
      
      const savedEntry = await ledgerEntriesModule.createManualLedgerEntry(entry);
      this.$emit("save", savedEntry);
      this.closeDialog();
      snackbarModule.postSnackbarMessage(
        this.$t(this.$ts.entities.ledgerEntry) + " " + this.$t(this.$ts.actionsPastTense.create)
      );
    } catch (error) {
      console.error("Error creating ledger entry:", error);
      snackbarModule.postSnackbarMessage(
        `${this.$t(this.$ts.entities.ledgerEntry)} error: ${error.response?.data || error.message}`
      );
    }
  }
}
</script> 