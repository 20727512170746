<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import { IAppointmentDTO, IAppointmentForWeeklyCalenderDTO, IAuditLogDTO, IOrderDTO } from "@shared/types";
import { formatCurrency } from "@/Utilities/currencyHelper";
import { PermissionArea, PermissionLevel } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { statisticsModule } from "@/store/modules/statistics/statisticsModule";
import { userModule } from "@/store/modules/user/userModule";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { subscriptionModule } from "@/store/modules/subscription/subscriptionModule";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import { updateLogModule } from "@/store/modules/updateLog/updateLogModule";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import CreateOrder from "@/components/global/CreateOrder.vue";
import ViewOrderDialog from "@/components/global/ViewOrderDialog.vue";

import CreateAppointment from "@/components/global/CreateAppointment.vue";
import GenericDialog from "@/components/global/GenericDialog.vue";
import DashboardHints from "@/components/Dashboard/DashboardHints.vue";
import SubscriptionReminderDialog from "@/components/Dashboard/SubscriptionReminderDialog.vue";
import UpdateDialog from "@/components/Dashboard/UpdateDialog.vue";
import TodayAppointmentsList from "@/components/Dashboard/TodayAppointmentsList.vue";
import StatisticsCards from "@/components/Dashboard/StatisticsCards.vue";
import ApiEnvironmentSwitcher from "@/components/Dashboard/ApiEnvironmentSwitcher.vue";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";
import { ISimpleDate } from "@/store/modules/appointments/appointmentTypings";
import { ordersModule } from "@/store/modules/orders/ordersModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";
import { askForPermissioToReceiveNotifications } from "@/Utilities/authConfig";
import { CurrencyFormatter } from "@/Utilities/CurrencyFormatter";

@Component({
  name: "Dashboard",
  components: {
    GenericDialog,
    CreateAppointment,
    DashboardHints,
    SubscriptionReminderDialog,
    UpdateDialog,
    TodayAppointmentsList,
    StatisticsCards,
    ApiEnvironmentSwitcher,
    CreateOrder,
    ViewOrderDialog,
  },
})
export default class Dashboard extends Vue {
  private hasLoadedStatistics = false;
  private showSubscriptionReminder = false;
  private showUpdateDialog = false;
  private bookOpen = false;
  private isLoading = true;
  private showCreateOrderDialog = false;
  private ordersLoading = false;
  private orderHeaders: Array<{ text: TranslateResult; value: string; sortable?: boolean }> = [];
  private orderDate: string = new Date().toISOString().split("T")[0];
  private selectedOrder: IOrderDTO | null = null;
  private showViewOrderDialog = false;

  get user() {
    return userModule.user;
  }

  get moneyMade() {
    return statisticsModule.moneyMade;
  }

  get formattedToCurrencyMoneyMade() {
    if (this.moneyMade) {
      return this.$t(this.$ts.currency) + " " + formatCurrency(this.moneyMade);
    }
    return "";
  }

  get appointmentsDone() {
    return statisticsModule.appointmentsDone;
  }

  get latestUpdate() {
    return updateLogModule.latestUpdate;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }

  get canWriteAppointmentsAndClients() {
    return userPermissionsModule.canWriteAppointmentsAndClients;
  }

  get canViewAppointmentsAndClients() {
    return userPermissionsModule.canViewAppointmentsAndClients;
  }

  get canViewSettings() {
    return userPermissionsModule.canViewSettings;
  }

  get canViewStatistics() {
    return this.hasPermission(PermissionArea.STATISTICS_AND_FINANCES, PermissionLevel.READ);
  }

  get canViewAdministration(): boolean {
    return userPermissionsModule.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ);
  }

  get hasPermission() {
    return userPermissionsModule.hasPermission;
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  get appointmentsForToday() {
    return appointmentsModule.appointmentsForToday;
  }



  get todaysOrders() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return ordersModule.orders.filter((order) => {
      const orderDate = new Date(order.orderDate);
      return orderDate >= today;
    });
  }

  formatCurrency(value: number): string {
    return CurrencyFormatter.format(value, this.$i18n.locale);
  }

  formatTime(date: string): string {
    const dateObj = new Date(date);
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    return formatTimeWithFormat(hour, minute, false, true);
  }

  getCustomerName(customerId: number | null): string {
    if (!customerId) return this.$t(this.$ts.orders.anonymousCustomer).toString();
    const customer = customersModule.customers.find((c) => c.id === customerId);
    return customer
      ? `${customer.firstName} ${customer.lastName}`
      : this.$t(this.$ts.orders.unknownCustomer).toString();
  }

  async viewOrder(order: IOrderDTO) {
    this.ordersLoading = true;
    const orderId = Number(order.id);
    try {
      this.selectedOrder = await ordersModule.getOrder(orderId);
      this.showViewOrderDialog = true;
    } finally {
      this.ordersLoading = false;
    }
  }

  async created() {
    this.orderHeaders = [
      { text: this.$t(this.$ts.orders.orderDate), value: "orderDate" },
      { text: this.$t(this.$ts.entities.customer), value: "customerName" },
      { text: this.$t(this.$ts.orders.totalAmount), value: "totalAmount" },
      { text: this.$t(this.$ts.fields.actions), value: "actions", sortable: false },
    ];

    try {
      // Ensure user data is loaded first
      if (!this.user || !this.user.currency) {
        await userModule.getCurrentUser();
      }

      if (this.staffMembers == null || this.staffMembers.length === 0) {
        await this.getStaffMembers();
      } else {
        this.getStaffMembers();
      }

      let date = new Date();
      let month = date.getMonth() + 1;
      await this.getEvents();

      await this.getMoneyMade(month);
      await this.getAppointmentsDone(month);
      this.hasLoadedStatistics = true;

      await this.getLatestUpdate();

      if (this.latestUpdate != null && this.latestUpdate.title && this.latestUpdate.html) {
        this.showUpdateDialog = true;
      }

      if (this.canViewAdministration && ordersModule.orders.length === 0) {
        this.ordersLoading = true;
        try {
          await Promise.all([ordersModule.getOrders(), customersModule.getCustomers()]);
        } finally {
          this.ordersLoading = false;
        }
      }
    } catch (error) {
      console.error("Error in mounted:", error);
      this.isLoading = false;
    }
  }

  async getStaffMembers() {
    await staffMemberModule.getStaffMembers();
  }

  async getUserSubscription() {
    return subscriptionModule.getSubscriptionStatus();
  }

  async getEvents() {
    let date = new Date();
    await appointmentsModule.getBookedAppointmentsForDay(date);
  }

  async getServices() {
    await serviceModule.getAppointmentTypes();
  }

  async getMoneyMade(month: number) {
    const year = new Date().getFullYear();
    await statisticsModule.getMoneyMadeForMonth({ month, year });
  }

  async getAppointmentsDone(month: number) {
    const year = new Date().getFullYear();
    await statisticsModule.getNumberOfAppointments({ month, year });
  }

  async getLatestUpdate() {
    return updateLogModule.getCurrentUpdateLogs();
  }

  async addToDialogQueue(dialog: any) {
    await dialogModule.addToDialogQueue(dialog);
  }

  async postAuditLog(audit: Partial<IAuditLogDTO>) {
    const dto = {
      action: audit.action || 0,
      type: audit.type || 0,
      comment: audit.comment || "",
      message: audit.message || "",
      hideForUser: audit.hideForUser || false,
    };
    return auditLogModule.postAudit({ dto });
  }

  async toggleFinished(status: IAppointmentForWeeklyCalenderDTO) {
    let update: Partial<IAppointmentDTO> = {
      status: status.status,
    };

    await appointmentsModule.patchAppointment({
      appointmentId: status.appointmentId,
      patch: update,
    });
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents();
  }

  async bookAppointment() {
    let now = new Date();
    let selectDate: ISimpleDate = {
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
    };
    appointmentsModule.selectDateToBook(selectDate);
    this.bookOpen = true;
  }

  async openCreateOrderDialog() {
    if (!this.user || !this.user.currency) {
      await userModule.getCurrentUser();
    }
    this.showCreateOrderDialog = true;
  }
}
</script>

<template>
  <div>
    <div class="g-signin2" data-onsuccess="onSignIn" data-theme="dark"></div>

    <SubscriptionReminderDialog :show.sync="showSubscriptionReminder" />

    <!-- <draggable-calendar></draggable-calendar> -->
    <UpdateDialog
      v-if="latestUpdate != null && latestUpdate.title && latestUpdate.html"
      :show.sync="showUpdateDialog"
      :updateInfo="latestUpdate"
    />

    <GenericDialog :model="bookOpen" :max-width="700" @exit="bookOpen = false">
      <CreateAppointment :open="bookOpen" @close="bookOpen = false" :shoulScroll="false" @added="addedAppointment" />
    </GenericDialog>

    <v-container>
      <v-row>
        <v-col v-if="canViewAppointmentsAndClients" xs="12" md="6">
          <TodayAppointmentsList
            :appointments="appointmentsForToday"
            :hasMultipleStaff="hasMultipleStaff"
            :isMobile="isMobile"
            :canWriteAppointmentsAndClients="canWriteAppointmentsAndClients"
            @toggle-finished="toggleFinished"
            @show-dialog="addToDialogQueue"
            @post-audit="postAuditLog"
            @book-appointment="bookAppointment"
          />
        </v-col>

        <v-col v-if="!isMobile && canViewStatistics && user != null">
          <StatisticsCards
            :appointmentsDone="appointmentsDone"
            :moneyMade="formattedToCurrencyMoneyMade"
            :showServicePrice="user.showServicePrice"
          />

          <!-- <v-btn @click="askForPermissioToReceiveNotifications">Ask for permission to receive notifications</v-btn> -->

          <v-row>
            <v-col>
              <v-card class="mb-4">
                <v-card-title class="d-flex align-center">
                  {{
                    new Date().toLocaleDateString(this.$i18n.locale, {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                    })
                  }}
                  <v-spacer></v-spacer>
                  <v-btn color="primary" fab small @click="openCreateOrderDialog" data-test="new-order-button">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text v-if="todaysOrders.length > 0">
                  <v-data-table
                    :headers="orderHeaders"
                    :items="todaysOrders"
                    :loading="ordersLoading"
                    :loading-text="$t($ts.loading)"
                    hide-default-footer
                    class="elevation-0"
                  >
                    <template #[`item.totalAmount`]="{ item }">
                      {{ formatCurrency(item.totalAmount) }}
                    </template>

                    <template #[`item.orderDate`]="{ item }">
                      {{ formatTime(item.orderDate) }}
                    </template>

                    <template #[`item.customerName`]="{ item }">
                      <router-link v-if="item.customerId" :to="`/kunder/${item.customerId}`" class="customer-link">
                        {{ getCustomerName(item.customerId) }}
                      </router-link>
                      <span v-else>{{ getCustomerName(item.customerId) }}</span>
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="viewOrder(item)">mdi-eye</v-icon>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-text v-else-if="ordersLoading" class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-card-text>
                <v-card-text v-else class="text-center grey--text">
                  {{ $t($ts.orders.noPurchasesToday) }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ApiEnvironmentSwitcher />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="canViewSettings">
        <v-col cols="12">
          <DashboardHints />
        </v-col>
      </v-row>
    </v-container>

    <!-- Create Order Dialog -->
    <v-dialog fullscreen v-model="showCreateOrderDialog" max-width="1200px">
      <CreateOrder
        :visible="showCreateOrderDialog"
        @cancel="showCreateOrderDialog = false"
        @created="showCreateOrderDialog = false"
      />
    </v-dialog>

    <ViewOrderDialog v-if="selectedOrder" :order="selectedOrder" v-model="showViewOrderDialog" />
  </div>
</template>

<style lang="scss">
.scroll-container {
  overflow-y: hidden !important;
  @media only screen and (max-width: 1264px) {
    overflow-y: scroll !important;
  }
}
.content {
  padding-right: -10px !important;
}
@media only screen and (max-width: 1264px) {
  .content {
    margin: 0 0 0;
    width: 100%;
    padding: 0;

    .statistics {
      width: 100% !important;
      margin: 0;
    }
  }
}

.agenda-disabled {
  background: repeating-linear-gradient(-45deg, #d9d9d9, #d9d9d9 10px, #d1cfcf 10px, #d1cfcf 20px);
}

.customer-link {
  text-decoration: none;
  color: var(--v-primary-base);
  &:hover {
    text-decoration: underline;
  }
}
</style>
