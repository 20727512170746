import { EventBaseService } from "./Base/EventBaseService";
import { IAccountRoleController, IAccountRoleDTO } from "@shared/types";

export class AccountRoleService extends EventBaseService implements IAccountRoleController {
  private static instance: AccountRoleService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!AccountRoleService.instance) {
      AccountRoleService.instance = new AccountRoleService();
    }
    return AccountRoleService.instance;
  }

  public async getRoles() {
    return this.http.sendRequest<IAccountRoleDTO[]>(IAccountRoleController.GetRolesRoute);
  }

  public async getRole(id: number) {
    return this.http.sendRequest<IAccountRoleDTO>(IAccountRoleController.GetRoleRoute, { params: { id } });
  }

  public async createRole(roleDto: IAccountRoleDTO) {
    return this.http.sendRequest<IAccountRoleDTO>(IAccountRoleController.CreateRoleRoute, { data: roleDto });
  }

  public async updateRole(id: number, roleDto: IAccountRoleDTO) {
    return this.http.sendRequest<void>(IAccountRoleController.UpdateRoleRoute, { params: { id }, data: roleDto });
  }

  public async deleteRole(id: number) {
    return this.http.sendRequest<void>(IAccountRoleController.DeleteRoleRoute, { params: { id } });
  }
}
