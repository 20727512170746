import { BaseService } from "./Base/BaseService";
import { IWorkingDaysController } from "@shared/types";

export class WorkingDaysService extends BaseService implements IWorkingDaysController {
  private static instance: WorkingDaysService;
  private controller = "WorkingDays"; // Matches backend route api/WorkingDays

  private constructor() {
    super();
  }
  public getAllWorkingDaysAnonymous(userId: string, serviceIds: string): Promise<boolean[]> {
    return this.http.get<boolean[]>(`${this.controller}/all?userId=${userId}&serviceIds=${serviceIds}`);
  }

  public static getInstance() {
    if (!WorkingDaysService.instance) {
      WorkingDaysService.instance = new WorkingDaysService();
    }
    return WorkingDaysService.instance;
  }

  public async toggleWorkingHourDay(dayOfWeek: number, isWorking: boolean, staffMemberId?: number) {
    let url = `${this.controller}/current?dayOfWeek=${dayOfWeek}&isWorking=${isWorking}`;
    if (staffMemberId !== undefined) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.putAuthenticated<void>(url);
  }

  public async getAllWorkingDays(staffMemberId?: number | string): Promise<boolean[]> {
    let url = `${this.controller}/all/current`;
    if (staffMemberId !== undefined) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<boolean[]>(url);
  }
}
