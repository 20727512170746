<template></template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationDropdown from "./NotificationDropdown.vue";
import { Action, Getter } from "vuex-class";
import { logout } from "@/Utilities/authConfig";
import NotificationBell from "./NotificationBell.vue";
import { IUserDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { layoutModule } from "@/store/modules/layout/layoutModule";

@Component({
  name: "main-header",
  components: { NotificationBell, NotificationDropdown },
})
export default class MainHeader extends Vue {
  public isShowing: boolean = false;
  public isShowingMenu: boolean = false;

  public group = null;

  get user() {
    return userModule.user;
  }

  get mini() {
    return layoutModule.mini;
  }

  public pageTitle: string = "Hjem";

  @Watch("$route")
  async onRouterChange() {
    this.pageTitle = this.$router.currentRoute.name;
  }

  created() {
    //Page title is set if one enters the page directly ... reloading /kalender, for example
    this.pageTitle = this.$router.currentRoute.name;
  }

  gotoEvents() {
    this.$router.push("/hendelser/").catch(() => {});
  }

  hideMenu(): void {
    this.isShowing = false;
    this.isShowingMenu = false;
  }
}
</script>
<style lang="scss" scoped>
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  border-color: rgba(255, 255, 255, 0) !important;
}

.overlay-wrapper {
  background: white !important;
  height: 100vh !important;
  width: 100% !important;
}
.router-link-active {
  font-weight: bold;
  font-size: 0.9rem;
  text-decoration: underline;
}

.hidden {
  display: none;
}
.mobile {
  display: none;
}
.header {
  width: calc(100% - 70px);
  height: 70px;
  background: white;
  margin-left: 70px;
  float: left;
  // .header-mobile{
  //   display: none;
  // }
  div {
    img {
      justify-content: center;
      height: 30px;
      display: block;
      margin: 10px auto 0;

      span {
        font-size: 0.7rem;
        margin-top: 2px;
        /* float: left; */
        height: 10px;
        top: -73px;
        line-height: -37px;
        position: relative;
        left: 10px;
        padding: 5px;
        border-radius: 16px;
        color: black;
        font-weight: bold;
      }
    }
    p {
      font-size: 0.7rem;
      margin-top: -28px;
    }
    .notify-text {
      margin-top: -110px 0;
    }
  }
  .dropdown-menu-wrapper {
    width: 100%;
    display: inline-block;
    height: calc(100% - 70px);
    background: #f8f8f8;
    z-index: 50;
    position: absolute;
    margin: 70px 0 0 0;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #cfc8c8;
    div {
      width: 100%;
      float: left;
      margin: 0;
      height: 50px;
      border-bottom: 1px solid #e1e1e1;
      background: white;
      img {
        float: left;
        margin-left: 25px;
      }
      .logout {
        transform: rotate(180deg);
      }
      p {
        float: left;
        text-indent: 10px;
        font-size: 1.2rem;
        margin-top: -14px;
      }
    }
  }
  .chat-icon {
    margin-left: 0px;
  }
  .notification-wrapper {
    width: 100px;
    height: 70px;
    float: right;
    border: none;
    border-right: 1px solid #cccccc;
    cursor: pointer;
    border-left: 1px solid #cccccc;
    &:hover {
      background: #ececf3;
    }
    img {
      display: block;
      margin: 20px auto;
    }
    .notification-amount {
      background: #e4405a;
      padding: 4px;
      color: #fff;
      margin-top: -60px;
      width: 24px;
      border-radius: 50%;
      margin-left: 53px;
      position: relative;
      height: 25px;
      text-align: center;
    }
  }

  .top-bar-text {
    color: #352776;
  }
}

@media only screen and (max-width: 1264px) {
  //  .header-mobile{
  //     display: -webkit-inline-box !important;;
  //   }
  .mobile {
    display: block;
    background: white;
  }
  .header {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    border-bottom: 1px solid #ebebeb;
    z-index: 10;
    div {
      display: block;
      width: 140px;
      margin: 3px 10px;
      text-align: center;
      line-height: 75px;
      font-size: 30px;

      .notify {
        background: red;
        color: white;
        border-radius: 10px;
        font-size: 1rem;
        padding: 2px 5px;
        top: -70px;
        position: relative;
        left: 9px;
        font-weight: bold;
      }
      .notify-text {
        margin: -108px 0px;
      }
    }
    .notification-wrapper {
      display: none;
    }
  }
}
</style>
