import { EventEmitter, ServiceEvent, Status, EventType, Feature } from "../api/Events/ServiceEvents";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { i18n } from "@/main";

export class SnackbarEventSubscriber {
  private static instance: SnackbarEventSubscriber;
  private eventEmitter: EventEmitter;

  private constructor() {
    this.eventEmitter = EventEmitter.getInstance();
    this.eventEmitter.subscribe(this.handleEvent.bind(this));
  }

  public static getInstance(): SnackbarEventSubscriber {
    if (!SnackbarEventSubscriber.instance) {
      SnackbarEventSubscriber.instance = new SnackbarEventSubscriber();
    }
    return SnackbarEventSubscriber.instance;
  }

  private getFeatureTranslationKey(feature: Feature): string {
    switch (feature) {
      case Feature.User:
        return String(i18n.t("entities.account"));
      case Feature.Appointment:
        return String(i18n.t("entities.appointment"));
      case Feature.Service:
        return String(i18n.t("entities.service"));
      case Feature.StaffMember:
        return String(i18n.t("entities.staff"));
      case Feature.Image:
        return String(i18n.t("entities.image"));
      case Feature.Notification:
        return String(i18n.t("entities.notification"));
      case Feature.GoogleCalendar:
        return String(i18n.t("entities.googleCalendar"));
      case Feature.Event:
        return String(i18n.t("entities.event"));
      case Feature.DebugLog:
        return String(i18n.t("entities.debugLog"));
      case Feature.Resource:
        return String(i18n.t("entities.resource"));
      case Feature.ServiceCategory:
        return String(i18n.t("entities.serviceCategory"));
      case Feature.Statistics:
        return String(i18n.t("entities.statistics"));
      case Feature.Subscription:
        return String(i18n.t("entities.subscription"));
      case Feature.Customer:
        return String(i18n.t("entities.customer"));
      case Feature.Expense:
        return String(i18n.t("entities.expense"));
      case Feature.Finance:
        return String(i18n.t("entities.finance"));
      case Feature.InventoryItem:
        return String(i18n.t("entities.inventoryItem"));
      case Feature.LedgerEntry:
        return String(i18n.t("entities.ledgerEntry"));
      case Feature.Order:
        return String(i18n.t("entities.order"));
      case Feature.OrderItem:
        return String(i18n.t("entities.orderItem"));
      case Feature.OneTimeExpense:
        return String(i18n.t("entities.oneTimeExpense"));
      case Feature.RecurringExpense:
        return String(i18n.t("entities.recurringExpense"));
      case Feature.ExpenseCategory:
        return String(i18n.t("entities.expenseCategory"));
      default:
        return feature;
    }
  }

  private getActionTranslationKey(type: EventType): string {
    switch (type) {
      case EventType.Added:
        return String(i18n.t("actionsPastTense.create"));
      case EventType.Updated:
        return String(i18n.t("actionsPastTense.edit"));
      case EventType.Deleted:
        return String(i18n.t("actionsPastTense.delete"));
      case EventType.Uploaded:
        return String(i18n.t("actionsPastTense.upload"));
      default:
        return "unknown";
    }
  }

  private getProgressMessage(feature: Feature, type: EventType): string {
    const featureName = this.getFeatureTranslationKey(feature);
    const actionName = this.getActionTranslationKey(type);
    return String(i18n.t("messages.inProgress", { feature: featureName, action: actionName }));
  }

  private getSuccessMessage(feature: Feature, type: EventType): string {
    const featureName = this.getFeatureTranslationKey(feature);
    const actionName = this.getActionTranslationKey(type);
    return String(i18n.t("messages.success", { feature: featureName, action: actionName }));
  }

  private getErrorMessage(feature: Feature, type: EventType, error?: Error): string {
    const featureName = this.getFeatureTranslationKey(feature);
    const actionName = this.getActionTranslationKey(type);
    const baseMessage = String(i18n.t("messages.failed", { feature: featureName, action: actionName }));
    return error?.message ? `${baseMessage}: ${error.message}` : baseMessage;
  }

  private handleEvent(event: ServiceEvent): void {
    // Use custom message if provided, otherwise generate one
    if (event.status === Status.InProgress) {
      snackbarModule?.postLoadingSnackbarMessage(event.message || this.getProgressMessage(event.feature, event.type));
      return;
    }

    if (event.status === Status.Success) {
      snackbarModule?.postSuccessSnackbarMessage(event.message || this.getSuccessMessage(event.feature, event.type));
      return;
    }

    if (event.status === Status.Failed) {
      snackbarModule?.postErrorSnackbarMessage(event.message || this.getErrorMessage(event.feature, event.type));
      return;
    }
  }
}
