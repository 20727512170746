import { VuexModule, Module, Action } from "vuex-class-modules";
import { IAppointmentTypeDTO, IServiceController } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { LocalStorageService } from "@/services/LocalStorageService";
import store from "@/store/store";
import { Container } from "@/services/api/Container";

@Module({ generateMutationSetters: true })
class ServiceModule extends VuexModule {
  private readonly serviceService: IServiceController;

  constructor(options: any) {
    super(options);
    this.serviceService = Container.getInstance().get<IServiceController>("IServiceController");
  }

  // State
  appointmentTypes: IAppointmentTypeDTO[] = [];
  extraServices: IAppointmentTypeDTO[] = [];

  @Action
  async getAppointmentTypes(extra?: boolean) {
    const services = await this.serviceService.getServices({ extra: extra ? "true" : undefined });
    if (extra) {
      this.extraServices = services;
    } else {
      this.appointmentTypes = services;
      // Save the services count in localStorage
      LocalStorageService.saveItemCount("services", services.length);
    }
    return services;
  }

  @Action
  async getTreatmentType(appointmentTypeId: number) {
    return await this.serviceService.getTreatmentType(appointmentTypeId);
  }

  @Action
  async changeServiceOrder(payload: { serviceId: number; order: number }) {
    await this.serviceService.changeOrderForService(payload.serviceId, payload.order);
    await this.getAppointmentTypes();
  }

  @Action
  async changeExtraService(payload: any) {
    await this.serviceService.changeExtraService(payload);
    await this.getAppointmentTypes();
  }

  @Action
  async editService(service: IAppointmentTypeDTO) {
    await this.serviceService.editService(service);
    await this.getAppointmentTypes();
  }

  @Action
  async postService(service: IAppointmentTypeDTO) {
    await this.serviceService.postService(service);
    await this.getAppointmentTypes();
  }

  @Action
  async deleteService(id: number) {
    await this.serviceService.deleteTreatmentType(id);
    await this.getAppointmentTypes();
  }

  @Action
  async assignAppointmentTypeToUser(treatmentTypeId: number) {
    await this.serviceService.assignAppointmentTypeToUser(treatmentTypeId);
    await this.getAppointmentTypes();
  }

  @Action
  async putAppointmentType(payload: { id: number; categoryId: number; order: number }) {
    await this.serviceService.putAppointmentType(payload.id, payload.categoryId, payload.order);
    await this.getAppointmentTypes();
  }

  @Action
  async getServicesAsAnonymous(userId: string) {
    return await this.serviceService.getServicesAsAnonymous(userId);
  }
}

export const serviceModule = new ServiceModule({ store, name: "serviceModule" });
