import {
  IUserController,
  IServiceController,
  IStaffMemberController,
  IInventoryItemCategoryController,
} from "@shared/types";
import { UserService } from "./UserService";
import { MockUserService } from "./MockUserService";
import { ServiceService } from "./ServiceService";
import { MockServiceService } from "./MockServiceService";
import { StaffMemberService } from "./StaffMemberService";
import { MockStaffMemberService } from "./MockStaffMemberService";
import { InventoryItemCategoryService } from "./InventoryItemCategoryService";
import { MockInventoryItemCategoryService } from "./MockInventoryItemCategoryService";

// Service type mapping
interface ServiceTypeMap {
  IUserController: IUserController;
  IServiceController: IServiceController;
  IStaffMemberController: IStaffMemberController;
  IInventoryItemCategoryController: IInventoryItemCategoryController;
}

export class Container {
  private static instance: Container;
  private bindings = new Map<keyof ServiceTypeMap, () => any>();

  private constructor() {
    this.setupBindings();
  }

  private setupBindings() {
    const isCypress = (window as any).Cypress;
    this.bind<IUserController>("IUserController", () =>
      isCypress ? MockUserService.getInstance() : UserService.getInstance()
    );
    this.bind<IServiceController>("IServiceController", () =>
      isCypress ? MockServiceService.getInstance() : ServiceService.getInstance()
    );
    this.bind<IStaffMemberController>("IStaffMemberController", () =>
      isCypress ? MockStaffMemberService.getInstance() : StaffMemberService.getInstance()
    );
    this.bind<IInventoryItemCategoryController>("IInventoryItemCategoryController", () =>
      isCypress ? MockInventoryItemCategoryService.getInstance() : InventoryItemCategoryService.getInstance()
    );
  }

  public static getInstance(): Container {
    if (!Container.instance) {
      Container.instance = new Container();
    }
    return Container.instance;
  }

  public bind<T extends ServiceTypeMap[K], K extends keyof ServiceTypeMap = keyof ServiceTypeMap>(
    key: K,
    factory: () => T
  ): void {
    this.bindings.set(key, factory);
  }

  public get<T extends ServiceTypeMap[K], K extends keyof ServiceTypeMap = keyof ServiceTypeMap>(
    key: K = "IUserController" as K
  ): T {
    const factory = this.bindings.get(key);
    if (!factory) {
      throw new Error(`No binding registered for type: ${key}`);
    }
    return factory() as T;
  }
}
