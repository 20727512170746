import { EventBaseService } from "./Base/EventBaseService";
import {
  IAppointmentDTO,
  IPostAppointmentDTO,
  IAppointmentController,
  IAppointmentChangeAsCustomAccount,
  IAppointmentInfoDTO,
  ICustomerAccountDTO,
  IEditAppointmentDTO,
  IPostBookingDTO,
  IAppointmentForWeeklyCalenderDTO,
  IAvailableHoursRequestDTO,
  ILoadedHourToBookDTO,
  IHourToBookDTO,
  IPaginatedAppointmentResponseDTO,
} from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class AppointmentService extends EventBaseService implements IAppointmentController {
  private static instance: AppointmentService;
  private controller = "Appointment";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!AppointmentService.instance) {
      AppointmentService.instance = new AppointmentService();
    }
    return AppointmentService.instance;
  }

  public async getFirstAvailableDayForBooking(request: IAvailableHoursRequestDTO) {
    return this.http.sendRequest<Date | null>(IAppointmentController.GetFirstAvailableDayForBookingRoute, {
      data: request,
    });
  }

  public async getAvailableHoursCache(request: IAvailableHoursRequestDTO) {
    return this.http.sendRequest<ILoadedHourToBookDTO[]>(IAppointmentController.GetAvailableHoursCacheRoute, {
      data: request,
    });
  }

  public async getAvailableHoursForBookingPOST(request: IAvailableHoursRequestDTO) {
    return this.http.sendRequest<IHourToBookDTO[]>(IAppointmentController.GetAvailableHoursForBookingPOSTRoute, {
      data: request,
    });
  }

  public async getAppointmentsForAdmin(query: Partial<typeof IAppointmentController.IGetAppointmentsForAdminQuery>) {
    return this.http.sendRequest<IPaginatedAppointmentResponseDTO>(
      IAppointmentController.GetAppointmentsForAdminRoute,
      { query: query }
    );
  }

  public async downloadExcelRapport() {
    try {
      this.emitEvent({ feature: Feature.Appointment, type: EventType.Updated, status: Status.InProgress });
      const result = await this.http.getAuthenticated<Blob>(`${this.controller}/current/download`, {
        responseType: "blob",
      });
      this.emitEvent({ feature: Feature.Appointment, type: EventType.Updated, status: Status.Success });
      return result;
    } catch (error) {
      this.emitEvent({ feature: Feature.Appointment, type: EventType.Updated, status: Status.Failed });
      throw error;
    }
  }

  public async getAppointmentByNumber(appointmentNumber: number) {
    return this.http.sendRequest<IAppointmentInfoDTO>(IAppointmentController.GetAppointmentByNumberRoute, {
      params: { appointmentNumber },
    });
  }

  public async getAppointmentsForAdminCustomer(customerId: number) {
    return this.http.sendRequest<IAppointmentInfoDTO[]>(IAppointmentController.GetAppointmentsForAdminCustomerRoute, {
      params: { customerId },
    });
  }

  public async changeAsCustomerAccount(customerAccount: IAppointmentChangeAsCustomAccount) {
    return this.http.sendRequest<void>(IAppointmentController.ChangeAsCustomerAccountRoute, {
      data: customerAccount,
    });
  }

  public async getAppointmentsForAdminForEventCalender(day: number, month: number, year: number) {
    return this.http.getAuthenticated<IAppointmentForWeeklyCalenderDTO[]>(
      `${this.controller}/onEvent/forCalender/current?day=${day}&month=${month}&year=${year}`
    );
  }

  public async getAppointmentsForMonthAdminForCalender(month: number, year: number) {
    return this.http.getAuthenticated<IAppointmentForWeeklyCalenderDTO[]>(
      `${this.controller}/onMonth/forCalender/current?month=${month}&year=${year}`
    );
  }

  public async getAppointmentsForWeekAdminForCalender(week: number, year: number, extraWeek: boolean = false) {
    const extraWeekParam = extraWeek ? "&extraWeek=true" : "";
    return this.http.getAuthenticated<IAppointmentForWeeklyCalenderDTO[]>(
      `${this.controller}/onWeek/forCalender/current?week=${week}&year=${year}${extraWeekParam}`
    );
  }

  public async getAvailableHoursForMonth(request: IAvailableHoursRequestDTO) {
    return this.http.post<ILoadedHourToBookDTO[]>(`${this.controller}/availableHoursForMonth`, request);
  }

  public async getAppointment(appointmentId: number) {
    return this.http.getAuthenticated<IAppointmentDTO>(`${this.controller}/${appointmentId}`);
  }

  public async editAppointment(appointment: IEditAppointmentDTO) {
    return this.withEventEmission(Feature.Appointment, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current`, appointment)
    );
  }

  public async changeStaffMember(staffMemberId: number, appointmentId: number) {
    return this.withEventEmission(Feature.Appointment, EventType.Updated, () =>
      this.http.patchAuthenticated<void>(
        `${this.controller}/current/staffMember?staffMemberId=${staffMemberId}&appointmentId=${appointmentId}`,
        {}
      )
    );
  }

  public async patchAppointment(patchDoc: JsonPatchOperation[], appointmentId: number) {
    return this.withEventEmission(Feature.Appointment, EventType.Updated, () =>
      this.http.patchAuthenticated<void>(`${this.controller}/current?appointmentId=${appointmentId}`, patchDoc)
    );
  }

  public async postNewBooking(bookingPost: IPostBookingDTO, userId: string) {
    return this.withEventEmission(Feature.Appointment, EventType.Added, () =>
      this.http.post<void>(`${this.controller}?userId=${userId}`, bookingPost)
    );
  }

  public async postAppointment(appointment: IPostAppointmentDTO) {
    return this.withEventEmission(Feature.Appointment, EventType.Added, async () => {
      // Create the appointment
      await this.http.postAuthenticated<void>(`${this.controller}/current`, appointment);
    });
  }

  public async checkAppointmentOverlap(appointment: IPostAppointmentDTO) {
    return this.http.postAuthenticated<boolean>(`${this.controller}/current/check-overlap`, appointment);
  }

  public async archiveAppointment(id: number) {
    return this.withEventEmission(Feature.Appointment, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/current?id=${id}`)
    );
  }

  public async verifyAppointment(verificationCode: string, userId: string) {
    return this.withEventEmission(Feature.Appointment, EventType.Updated, () =>
      this.http.post<void>(`${this.controller}/verify`, {
        verificationCode,
        userId,
      })
    );
  }

  public async deleteAppointmentByCustomerAccountLogin(
    appointmentId: number,
    customerToken: string,
    phoneNumber: string,
    userId: string
  ) {
    return this.withEventEmission(Feature.Appointment, EventType.Deleted, () =>
      this.http.delete<void>(
        `${this.controller}/cancelByCustomer?appointmentId=${appointmentId}&customerToken=${customerToken}&phoneNumber=${phoneNumber}&userId=${userId}`
      )
    );
  }

  public async getCustomerAccountAppointments(customerAccount: ICustomerAccountDTO) {
    return this.http.post<IAppointmentInfoDTO[]>(`${this.controller}/forCustomerAccount`, customerAccount);
  }
}
