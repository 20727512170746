<template>
  <v-container>
    <v-card class="mb-4 pa-4">
      <v-card-title class="headline d-flex justify-space-between">
        <div class="d-flex align-center">
          <v-icon color="primary" class="mr-2">mdi-cash-multiple</v-icon>
          {{ $t($ts.commissionSettlement.title) }}
        </div>
      </v-card-title>

      <v-card-text>
        <!-- Date Selection -->
        <v-row align="center" class="mb-4">
          <v-col cols="12" sm="4">
            <v-select
              v-model="selectedYear"
              :items="yearOptions"
              dense
              outlined
              hide-details
              class="header-select"
              @change="refreshData"
              :label="$t($ts.statistics.year)"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-calendar</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="selectedMonth"
              :items="monthOptions"
              item-value="value"
              item-text="text"
              dense
              outlined
              hide-details
              class="header-select"
              @change="refreshData"
              :label="$t($ts.statistics.month)"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-calendar-month</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="selectedDay"
              :items="dayOptions"
              item-value="value"
              item-text="text"
              dense
              outlined
              hide-details
              class="header-select"
              @change="refreshData"
              :label="$t($ts.statistics.day)"
            >
              <template v-slot:prepend>
                <v-icon left>mdi-calendar-day</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- Warning for unassigned appointments -->
        <v-alert
          v-if="unassignedAppointments.length > 0"
          type="warning"
          class="mb-4"
          dense
          outlined
        >
          <div class="font-weight-bold mb-2">{{ $t($ts.commissionSettlement.unassignedAppointmentsWarning) }}</div>
          <div v-for="appointment in unassignedAppointments" :key="appointment.id" class="ml-4">
            <span class="font-weight-medium">{{ formatTime(appointment.startTime) }}</span> - 
            <span>{{ appointment.serviceName }}</span> - 
            <span>{{ appointment.title }}</span>
            <v-btn
              x-small
              text
              color="primary"
              class="ml-2"
              @click="openAssignStaffDialog(appointment)"
            >
              {{ $t($ts.commissionSettlement.assign) }}
            </v-btn>
          </div>
        </v-alert>

        <!-- Skeleton Loaders for Staff Commission Cards -->
        <div v-if="isLoading && eligibleStaffCount > 0" class="staff-commission-grid">
          <v-card
            v-for="i in eligibleStaffCount"
            :key="`skeleton-${i}`"
            outlined
            class="staff-commission-card"
          >
            <v-card-title class="d-flex justify-space-between align-center py-2">
              <div class="d-flex align-center">
                <v-skeleton-loader
                  type="avatar"
                  width="36"
                  height="36"
                  class="mr-2"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="text"
                  width="120"
                ></v-skeleton-loader>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pt-3">
              <div class="d-flex justify-space-between mb-2">
                <v-skeleton-loader type="text" width="80"></v-skeleton-loader>
                <v-skeleton-loader type="text" width="60"></v-skeleton-loader>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <v-skeleton-loader type="text" width="80"></v-skeleton-loader>
                <v-skeleton-loader type="text" width="60"></v-skeleton-loader>
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="d-flex justify-space-between">
                <v-skeleton-loader type="text" width="80"></v-skeleton-loader>
                <v-skeleton-loader type="text" width="80"></v-skeleton-loader>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-skeleton-loader type="button" width="100%"></v-skeleton-loader>
            </v-card-actions>
          </v-card>
        </div>

        <!-- Staff Commission Cards -->
        <div v-else-if="staffCommissions.length > 0" class="staff-commission-grid">
          <v-card
            v-for="staff in staffCommissions"
            :key="staff.staffMemberId"
            outlined
            class="staff-commission-card"
            :class="{ 'commission-paid': isCommissionPaid(staff.staffMemberId) }"
          >
            <v-card-title class="d-flex justify-space-between align-center py-2">
              <div class="d-flex align-center">
                <v-avatar size="36" class="mr-2">
                  <v-img
                    v-if="staff.profilePictureUrl"
                    :src="staff.profilePictureUrl"
                    :alt="staff.staffMemberName"
                  ></v-img>
                  <v-icon v-else size="36" color="grey lighten-1">mdi-account</v-icon>
                </v-avatar>
                <span class="text-subtitle-1">{{ staff.staffMemberName }}</span>
              </div>
              <v-chip
                v-if="isCommissionPaid(staff.staffMemberId)"
                color="success"
                small
                outlined
              >
                {{ $t($ts.commissionSettlement.paid) }}
              </v-chip>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pt-3">
              <div class="d-flex justify-space-between mb-2">
                <span class="text-subtitle-2">{{ $t($ts.commissionSettlement.services) }}:</span>
                <span class="font-weight-medium">{{ formatCurrency(staff.servicesCommission) }}</span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-subtitle-2">{{ $t($ts.commissionSettlement.products) }}:</span>
                <span class="font-weight-medium">{{ formatCurrency(staff.productsCommission) }}</span>
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="d-flex justify-space-between">
                <span class="text-subtitle-1 font-weight-bold">{{ $t($ts.commissionSettlement.total) }}:</span>
                <span class="text-subtitle-1 font-weight-bold primary--text">{{ formatCurrency(staff.totalCommission) }}</span>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="d-flex flex-column w-100">
                <money-account-selector
                  v-if="!isCommissionPaid(staff.staffMemberId)"
                  v-model="selectedMoneyAccountId"
                  :showPaymentMethod="false"
                  class="mb-2"
                  data-cy="commission-money-account-select"
                />
                <v-btn
                  block
                  color="primary"
                  :disabled="!canPayCommission(staff.staffMemberId) || isProcessingPayment[staff.staffMemberId]"
                  @click="payCommission(staff)"
                >
                  <v-progress-circular
                    v-if="isProcessingPayment[staff.staffMemberId]"
                    indeterminate
                    color="white"
                    size="20"
                    width="2"
                    class="mr-2"
                  ></v-progress-circular>
                  {{ $t($ts.commissionSettlement.payCommission) }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>

        <v-card v-else-if="!isLoading" flat class="text-center pa-4">
          <v-icon size="64" color="grey lighten-1" class="mb-2">mdi-account-off</v-icon>
          <div class="text-h6 grey--text">{{ $t($ts.commissionSettlement.noEligibleStaff) }}</div>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- Dialog for assigning staff to appointments -->
    <v-dialog v-model="assignStaffDialog" max-width="500">
      <v-card>
        <v-card-title>{{ $t($ts.commissionSettlement.assignStaffTitle) }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedStaffForAssignment"
            :items="staffOptions"
            item-text="fullName"
            item-value="id"
            outlined
            dense
            :label="$t($ts.commissionSettlement.selectStaff)"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="assignStaffDialog = false">{{ $t($ts.buttons.cancel) }}</v-btn>
          <v-btn color="primary" @click="assignStaffToAppointment">{{ $t($ts.buttons.save) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { staffMemberStatisticsModule } from "@/store/modules/staffMember/staffMemberStatisticsModuke";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { commissionModule } from "@/store/modules/commission/commissionModule";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { IStaffMemberDTO, IAppointmentForWeeklyCalenderDTO, LedgerEntryType, LedgerEntrySourceType, ExpenseCategory, ILedgerEntryDTO, IPostOneTimeExpenseDTO, CommissionStatus } from "@shared/types";
import MoneyAccountSelector from "@/components/global/MoneyAccountSelector.vue";
import OneTimeExpenseDialog from "@/components/expenses/OneTimeExpenseDialog.vue";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { expensesModule } from "@/store/modules/expenses/expensesModule";
import { CurrencyFormatter } from "@/Utilities/CurrencyFormatter";


interface StaffCommission {
  staffMemberId: number;
  staffMemberName: string;
  profilePictureUrl?: string;
  servicesCommission: number;
  productsCommission: number;
  totalCommission: number;
}

interface AppointmentWithDetails extends IAppointmentForWeeklyCalenderDTO {
  id: number;
  title?: string;
  serviceName?: string;
  startTime?: string;
}

@Component({
  name: "CommissionSettlement",
  components: {
    MoneyAccountSelector,
    OneTimeExpenseDialog
  }
})
export default class CommissionSettlement extends Vue {
  // Data properties
  selectedYear = new Date().getFullYear();
  selectedMonth = new Date().getMonth() + 1;
  selectedDay = new Date().getDate();
  selectedMoneyAccountId: number | null = null;
  staffCommissions: StaffCommission[] = [];
  unassignedAppointments: AppointmentWithDetails[] = [];
  paidCommissions: Record<number, boolean> = {};
  isProcessingPayment: Record<number, boolean> = {};
  isLoading = false;
  ledgerEntries: ILedgerEntryDTO[] = [];
  eligibleStaffCount = 0;
  
  // Dialog properties
  assignStaffDialog = false;
  selectedStaffForAssignment: number | null = null;
  currentAppointment: AppointmentWithDetails | null = null;
  
  // Computed properties
  get yearOptions() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  get monthOptions() {
    return [
      { value: 1, text: this.$t(this.$ts.months.january) },
      { value: 2, text: this.$t(this.$ts.months.february) },
      { value: 3, text: this.$t(this.$ts.months.march) },
      { value: 4, text: this.$t(this.$ts.months.april) },
      { value: 5, text: this.$t(this.$ts.months.may) },
      { value: 6, text: this.$t(this.$ts.months.june) },
      { value: 7, text: this.$t(this.$ts.months.july) },
      { value: 8, text: this.$t(this.$ts.months.august) },
      { value: 9, text: this.$t(this.$ts.months.september) },
      { value: 10, text: this.$t(this.$ts.months.october) },
      { value: 11, text: this.$t(this.$ts.months.november) },
      { value: 12, text: this.$t(this.$ts.months.december) },
    ];
  }

  get dayOptions() {
    const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    const options = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(this.selectedYear, this.selectedMonth - 1, i);
      options.push({
        value: i,
        text: date.toLocaleDateString(this.$i18n.locale),
      });
    }
    return options;
  }

  get staffOptions() {
    return staffMemberModule.staffMembers;
  }

  // Lifecycle hooks
  async created() {
    this.isLoading = true;
    try {
      await staffMemberModule.getStaffMembers();
      this.eligibleStaffCount = staffMemberModule.staffMembers.filter(staff => staff.eligibleForCommission).length;
      await this.refreshData();
    } finally {
      this.isLoading = false;
    }
  }

  // Methods
  async refreshData() {
    this.isLoading = true;
    try {
      // Get appointments for the selected date
      const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
      await appointmentsModule.getBookedAppointmentsForDay(selectedDate);
      
      // Check for unassigned appointments
      this.unassignedAppointments = (appointmentsModule.appointmentsForToday || [])
        .filter(appointment => !appointment.staffMemberId)
        .map(appointment => appointment as AppointmentWithDetails);
      
      // Get staff members with commission data
      this.staffCommissions = [];
      
      const eligibleStaff = staffMemberModule.staffMembers.filter(staff => staff.eligibleForCommission);
      this.eligibleStaffCount = eligibleStaff.length;
      
      for (const staff of eligibleStaff) {
        // Get statistics for each staff member
        const stats = await staffMemberStatisticsModule.getStaffMemberStatistics({
          staffMemberId: staff.id,
          year: this.selectedYear,
          month: this.selectedMonth,
          day: this.selectedDay
        });
        
        // Calculate total commission
        const servicesCommission = stats.services.reduce((sum, service) => sum + (service.commission || 0), 0);
        const productsCommission = stats.products.reduce((sum, product) => sum + (product.commission || 0), 0);
        const totalCommission = servicesCommission + productsCommission;
        
        if (totalCommission > 0) {
          const existingCommission = this.staffCommissions.find(commission => commission.staffMemberId === staff.id);
          if (!existingCommission) {
            this.staffCommissions.push({
              staffMemberId: staff.id,
              staffMemberName: `${staff.firstName} ${staff.lastName}`,
              profilePictureUrl: staff.profilePictureUrl,
              servicesCommission,
              productsCommission,
              totalCommission
            });
          }
        }
      }
      
      // Check if commissions have been paid today
      await this.checkPaidCommissions();
      
    } finally {
      this.isLoading = false;
    }
  }
  
  async   checkPaidCommissions() {
    // Reset paid status
    this.paidCommissions = {};
    
    // Get commissions for the selected date
    const commissions = await commissionModule.getCommissionsForDate({
      year: this.selectedYear,
      month: this.selectedMonth,
      day: this.selectedDay
    });
    
    // For each staff member, check if they have a commission payment for the selected date
    for (const staff of this.staffCommissions) {
      // Check if there's a commission for this staff member
      const hasPaidCommission = commissions.some(commission => 
        commission.staffId === staff.staffMemberId && 
        commission.status === CommissionStatus.Paid
      );
      
      // Use Vue.set to ensure reactivity
      this.$set(this.paidCommissions, staff.staffMemberId, hasPaidCommission);
    }
  }
  
  isCommissionPaid(staffMemberId: number): boolean {
    return this.paidCommissions[staffMemberId] || false;
  }
  
  canPayCommission(staffMemberId: number): boolean {
    return !this.isCommissionPaid(staffMemberId) && !!this.selectedMoneyAccountId;
  }
  
  async payCommission(staff: StaffCommission) {
    if (!this.selectedMoneyAccountId) {
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.commissionSettlement.selectMoneyAccountError).toString()
      });
      return;
    }
    
    // Set loading state for this specific staff member
    this.$set(this.isProcessingPayment, staff.staffMemberId, true);
    
    try {
      // Create ledger entry for commission payment
      await ledgerEntriesModule.createManualLedgerEntry({
        id: 0,
        amount: staff.totalCommission,
        customerId: 0, // No customer for commission payments
        sourceId: staff.staffMemberId,
        sourceType: LedgerEntrySourceType.Commission,
        status: 0,
        type: LedgerEntryType.Charge,
        moneyAccountId: this.selectedMoneyAccountId,
        description: `${this.$t(this.$ts.commissionSettlement.commissionPayment)} - ${staff.staffMemberName}`
      });


      const date = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);

      await commissionModule.createCommission({
        staffId: staff.staffMemberId,
        amount: staff.totalCommission,
        moneyAccountId: this.selectedMoneyAccountId,
        description: `${this.$t(this.$ts.commissionSettlement.commissionPayment)} - ${staff.staffMemberName}`,
        date: date
      });

      
      // Create one-time expense
      const expense: IPostOneTimeExpenseDTO = {
      amount: staff.totalCommission,
      category: ExpenseCategory.Commission,
      purchaseDate: date,
      receipt: "",
      notes: `${this.$t(this.$ts.commissionSettlement.commissionPayment)} - ${staff.staffMemberName}`,
      sourceId: staff.staffMemberId,
      moneyAccountId: this.selectedMoneyAccountId,
      name: ""
      };

      await expensesModule.createOneTimeExpense(expense);
      
      
      // Refresh paid status
      await this.checkPaidCommissions();
      
    } catch (error) {

    } finally {
      // Clear loading state
      this.$set(this.isProcessingPayment, staff.staffMemberId, false);
    }
  }
  
  openAssignStaffDialog(appointment: AppointmentWithDetails) {
    this.currentAppointment = appointment;
    this.selectedStaffForAssignment = null;
    this.assignStaffDialog = true;
  }
  
  async assignStaffToAppointment() {
    if (!this.currentAppointment || !this.selectedStaffForAssignment) {
      return;
    }
    
    try {
      await appointmentsModule.changeAppointmentStaff({
        staffMemberId: this.selectedStaffForAssignment,
        appointmentId: this.currentAppointment.id
      });
      
      // Close dialog
      this.assignStaffDialog = false;
      
      // Refresh data
      await this.refreshData();
      
      
    } catch (error) {
      console.error("Failed to assign staff:", error);
      dialogModule.addToDialogQueue({
        text: this.$t(this.$ts.commissionSettlement.staffAssignmentError).toString(),
      });
    }
  }
  
  formatCurrency(amount: number): string {
    return CurrencyFormatter.format(amount, this.$i18n.locale);
  }
  
  formatTime(dateTime: string): string {
    return new Date(dateTime).toLocaleTimeString(this.$i18n.locale, {
      hour: '2-digit',
      minute: '2-digit'
    });
  }
}
</script>

<style lang="scss" scoped>
.staff-commission-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.staff-commission-card {
  transition: all 0.3s ease;
  
  &.commission-paid {
    border-color: var(--v-success-base);
    background-color: rgba(var(--v-success-base), 0.05);
  }
}

.header-select {
  min-width: 150px;
}
</style> 