import { EventBaseService } from "./Base/EventBaseService";
import { IGoogleCalendarController } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class GoogleCalendarService extends EventBaseService implements IGoogleCalendarController {
  private static instance: GoogleCalendarService;
  private controller = "GoogleCalendar";

  private constructor() {
    super();
  }

  public static getInstance(): GoogleCalendarService {
    if (!GoogleCalendarService.instance) {
      GoogleCalendarService.instance = new GoogleCalendarService();
    }
    return GoogleCalendarService.instance;
  }

  public async syncAppWithCalendar(): Promise<string> {
    return this.http.getAuthenticated<string>(`${this.controller}/current/events`);
  }

  public async revokeToken(staffMemberID: number): Promise<void> {
    return this.withEventEmission(Feature.GoogleCalendar, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/oauth/${staffMemberID}`)
    );
  }

  public async authenticateByCode(code: { Code: string; Staff: number }): Promise<void> {
    return this.withEventEmission(Feature.GoogleCalendar, EventType.Added, () =>
      this.http.postAuthenticated<void>(`${this.controller}/current/code`, code)
    );
  }
}
