<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import RegisterFirebaseAccount from "../RegisterFirebaseAccount.vue";
import PersonalAccountSettings from "./PersonalAccountSettings.vue";
import AccountRoleSettings from "./AccountRoleSettings.vue";
import { Watch } from "vue-property-decorator";
import { DialogType } from "@/store/dialog";
import { firebaseUserModule } from "@/store/modules/firebaseUser/firebaseUserModule";
import { accountRoleModule } from "@/store/modules/accountRoleModule/accountRoleModule";
import { IFirebaseUserDTO } from "@shared/types";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "AccountSettings",
  components: {
    PersonalAccountSettings,
    RegisterFirebaseAccount,
    AccountRoleSettings,
  },
})
export default class AccountSettings extends Vue {
  public addingNewAccount: boolean = false;
  public newAccount: any = {};
  private isEditing: boolean = false;

  @Watch("addingNewAccount")
  onIsEditingNewAccount(newValue: boolean) {
    if (!newValue) this.isEditing = false;
  }

  initNewAccount() {
    this.newAccount = {
      email: "",
      roleId: null,
      password: "",
      password2: "",
      name: "",
    };
  }

  async created() {
    await Promise.all([firebaseUserModule.getAllFirebaseUsers(), accountRoleModule.getRoles()]);
    this.initNewAccount();
  }

  async addNewAccount() {
    await firebaseUserModule.postFirebaseAccount(this.newAccount);
    this.addingNewAccount = false;
  }

  async saveAccountChanges(): Promise<void> {
    try {
      const updates: Partial<IFirebaseUserDTO> = {
        name: this.newAccount.name,
      };

      //TODO: Delete this if this is legacy

      // if (this.newAccount.roleId) {
      //   updates.role = { id: this.newAccount.roleId };
      // }

      const payload = {
        firebaseUserId: this.newAccount.userId,
        patch: updates,
      };

      await firebaseUserModule.patchFirebaseUser(payload);
      await firebaseUserModule.getAllFirebaseUsers();
      this.addingNewAccount = false;
    } catch (error) {
      console.error(error);
    }
  }

  isOwner(account: any): boolean {
    return account === firebaseUserModule.allFirebaseUsers[0];
  }

  getRoleDisplayName(account: any): string {
    if (this.isOwner(account)) {
      return this.$t(this.$ts.accountRole.owner).toString();
    }
    const role = accountRoleModule.roles.find((r) => r.id === account.roleId);
    return role ? role.name : "";
  }

  formatDate(timestamp: string): string {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat(this.$i18n.locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  }

  public openNewAccountDialog(): void {
    this.initNewAccount();
    this.addingNewAccount = true;
  }

  editUser(account: any) {
    this.isEditing = true;
    this.addingNewAccount = true;
    this.newAccount = {
      ...account,
      roleId: account.role?.id,
    };
  }

  async deleteUser(account: any) {
    const vm = this;
    const dialog = {
      text: this.$t(this.$ts.account.deleteConfirmation).toString(),
      header: account.email,
      type: DialogType.Choice,
      action: async function () {
        await firebaseUserModule.deleteFirebaseUser(account.userId);
        dialogModule.addToDialogQueue({
          text: vm.$t(vm.$ts.account.deleteSuccess).toString(),
        });
      },
    };

    dialogModule.addToDialogQueue(dialog);
  }

  get roles() {
    return accountRoleModule.roles;
  }

  get firebaseUsers() {
    return firebaseUserModule.allFirebaseUsers;
  }
}
</script>

<template>
  <div>
    <v-dialog v-model="addingNewAccount" max-width="350">
      <v-card flat>
        <v-card-title v-if="!isEditing">{{ $t($ts.account.registerNewAccount) }}</v-card-title>
        <v-card-title v-if="isEditing">{{ $t($ts.account.editAccount) }}</v-card-title>

        <v-card-text>
          <v-text-field :label="$t($ts.name)" v-model="newAccount.name"></v-text-field>
          <v-text-field v-if="!isEditing" :label="$t($ts.email)" v-model="newAccount.email"></v-text-field>
          <v-text-field
            v-if="!isEditing"
            :label="$t($ts.password)"
            type="password"
            v-model="newAccount.password"
          ></v-text-field>

          <v-text-field
            v-if="!isEditing"
            :label="$t($ts.login.repeatPassword)"
            type="password"
            v-model="newAccount.password2"
          ></v-text-field>

          <v-select
            v-if="!isOwner(newAccount)"
            :label="$t($ts.accountRole.title)"
            :items="roles"
            item-text="name"
            item-value="id"
            v-model="newAccount.roleId"
          ></v-select>
          <div v-else class="caption">{{ $t($ts.accountRole.ownerRole) }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="addingNewAccount = false">{{ $t($ts.cancel) }}</v-btn>
          <v-btn v-if="!isEditing" color="success" @click="addNewAccount()">{{ $t($ts.create) }}</v-btn>
          <v-btn v-if="isEditing" color="success" @click="saveAccountChanges()">{{ $t($ts.save) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-list three-line>
        <template v-for="(fbUser, index) in firebaseUsers">
          <v-divider :key="'divider-' + index" :inset="true"></v-divider>

          <v-list-item :key="fbUser.email">
            <v-list-item-avatar>
              <v-img src="/img/login.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ fbUser.email }}</v-list-item-title>
              <v-list-item-subtitle class="font-weight-medium">
                {{ getRoleDisplayName(fbUser) }}
              </v-list-item-subtitle>

              <v-row>
                <v-col cols="auto">
                  <v-list-item-subtitle>{{
                    $t("account.lastSignIn", {
                      date: formatDate(fbUser.metadata?.lastSignInTimestamp),
                    })
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    $t("account.created", {
                      date: formatDate(fbUser.metadata?.creationTimestamp),
                    })
                  }}</v-list-item-subtitle>
                </v-col>

                <v-col>
                  <v-btn @click="deleteUser(fbUser)" icon :disabled="isOwner(fbUser)"><v-icon>delete</v-icon></v-btn>
                </v-col>

                <v-col>
                  <v-btn @click="editUser(fbUser)" icon :disabled="isOwner(fbUser)"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<style scoped></style>
