<template>
  <!-- <v-dialog v-model="dialogModel" :max-width="maxWidth"> -->
  <v-card class="mobile-padding">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isValid" @submit.prevent>
        <div @input="onInput" @change="onInput">
          <slot></slot>
        </div>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="isSaving" color="#866afe" outlined @click="$emit('cancel')" data-cy="dialog-cancel-btn">
        {{ cancelText }}
      </v-btn>
      <v-btn
        v-if="isEditing && isValid"
        dark
        color="#866afe"
        :loading="isSaving"
        @click="$emit('save')"
        data-cy="dialog-save-btn"
      >
        {{ saveText }} <v-icon>save</v-icon>
      </v-btn>
      <v-btn
        v-if="isCreating && isValid && !createButtonDisabled"
        dark
        color="#866afe"
        :loading="isSaving"
        @click="$emit('create')"
        data-cy="dialog-create-btn"
      >
        {{ createText }} <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn
        v-if="isEditing && !isValid"
        color="rgba(134, 106, 254, 0.5)"
        :loading="isSaving"
        style="cursor: not-allowed"
        disabled
        data-cy="dialog-save-btn-disabled"
      >
        {{ saveText }} <v-icon>save</v-icon>
      </v-btn>
      <v-btn
        v-if="isCreating && (!isValid || createButtonDisabled)"
        color="rgba(134, 106, 254, 0.5)"
        :loading="isSaving"
        style="cursor: not-allowed"
        disabled
        data-cy="dialog-create-btn-disabled"
      >
        {{ createText }} <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";

@Component({
  name: "base-form-dialog",
})
export default class BaseFormDialog extends Vue {
  //@Model("input", { type: Boolean, default: false }) readonly value!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) isEditing!: boolean;
  @Prop({ required: true }) isCreating!: boolean;
  @Prop({ default: false }) isSaving!: boolean;
  @Prop({ default: "" }) saveText!: string;
  @Prop({ default: "" }) createText!: string;
  @Prop({ default: "" }) cancelText!: string;
  @Prop({ default: 660 }) maxWidth!: number;
  @Prop({ default: false }) createButtonDisabled!: boolean;

  private isValid: boolean = false;

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }

  onInput() {
    const form = this.$refs.form as any;
    if (form) {
      this.isValid = form.validate();
    }
  }

  public validate(): boolean {
    const form = this.$refs.form as any;
    if (!form) return false;
    this.isValid = form.validate();
    return this.isValid;
  }

  public reset(): void {
    const form = this.$refs.form as any;
    if (form) {
      form.reset();
    }
  }

  //   get dialogModel() {
  //     return this.value;
  //   }

  //   set dialogModel(value: boolean) {
  //     this.$emit("input", value);
  //   }
}
</script>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
