<template>
  <v-dialog v-model="dialog" max-width="660">
    <DynamicForm
      v-if="currentEntry"
      :data="currentEntry"
      :entity="$t(this.$ts.entities.ledgerEntry)"
      :metadata="ledgerEntryMetadata"
      :is-editing="false"
      :is-creating="true"
      :translation-path="nonReactiveTranslations"
      @cancel="closeDialog"
      @create="saveEntry"
    >
      <template v-slot:custom-fields>
        <!-- <v-card flat class="mb-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <div class="subtitle-1 font-weight-bold">{{ $t($ts.entities.customer) }}</div>
                <div>{{ customerName }}</div>
                <div v-if="customerEmail" class="grey--text">{{ customerEmail }}</div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="subtitle-1 font-weight-bold">{{ $t($ts.entities.appointment) }}</div>
                <div># {{ appointmentNumber }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card> -->

        <CompanyInfoField :label="$t($ts.entities.customer)">
          <CustomerSelect
            v-model="currentEntry.customerId"
            :hide-decorators="true"
            :customers="[{ id: defaultCustomerId, firstName: customerName, lastName: '', emailAddress: customerEmail }]"
            :disabled="true"
          />
        </CompanyInfoField>
        <CompanyInfoField v-if="defaultAppointmentId" :label="$t($ts.entities.appointment)">
          <v-select
            v-model="currentEntry.sourceId"
            :items="[{ id: defaultAppointmentId, text: appointmentNumber }]"
            item-text="text"
            item-value="id"
            :disabled="true"
            hide-details
            dense
            outlined
          ></v-select>
        </CompanyInfoField>
        <CompanyInfoField :label="$t($ts.ledgerEntry.type)">
          <v-select
            v-model="currentEntry.type"
            :items="ledgerEntryTypes"
            item-text="text"
            item-value="value"
            hide-details
            dense
            outlined
          ></v-select>
        </CompanyInfoField>
        <CompanyInfoField v-if="currentEntry.type === LedgerEntryType.Payment" :label="$t($ts.sidebar.cashRegister)">
          <MoneyAccountSelector
            v-model="selectedMoneyAccountId"
            :money-accounts="moneyAccounts"
          />
        </CompanyInfoField>
        <v-divider class="my-4" />
      </template>
    </DynamicForm>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import CustomerSelect from "@/components/global/CustomerSelect.vue";
import MoneyAccountSelector from "@/components/global/MoneyAccountSelector.vue";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import { moneyAccountModule } from "@/store/modules/moneyAccount/moneyAccountModule";

import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import {
  ILedgerEntryDTO,
  LedgerEntrySourceType,
  LedgerEntryType,
  LedgerEntryStatus,
  FormMetadata,
  ICustomerDTO,
  PaymentMethod,
} from "@shared/types";

@Component({
  components: {
    DynamicForm,
    CompanyInfoField,
    CustomerSelect,
    MoneyAccountSelector,
  },
})
export default class CreateLedgerEntryDialog extends Vue {
  @Prop({ required: true }) defaultCustomerId!: number;
  @Prop({ required: true }) defaultAppointmentId!: number;
  @Prop({ required: true }) customerName!: string;
  @Prop({ required: true }) customerEmail!: string;
  @Prop({ required: true }) appointmentNumber!: string;
  @Prop({ required: true, type: Boolean }) value!: boolean;

  private currentEntry: Partial<ILedgerEntryDTO> | null = null;
  private selectedMoneyAccountId: number | null = null;
  private selectedPaymentMethod: PaymentMethod = PaymentMethod.Card;


  LedgerEntryType = LedgerEntryType;

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit("input", value);
  }

  get ledgerEntryMetadata() {
    return FormMetadata.ledgerentry;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.ledgerEntryFormDTO });
  }

  get ledgerEntryTypes() {
    return [
      { text: this.$t(this.$ts.ledgerEntry.charge), value: LedgerEntryType.Charge },
      { text: this.$t(this.$ts.ledgerEntry.payment), value: LedgerEntryType.Payment },
      { text: this.$t(this.$ts.ledgerEntry.refund), value: LedgerEntryType.Refund },
    ];
  }

  get moneyAccounts() {
    return moneyAccountModule.moneyAccounts;
  }

  get paymentMethodOptions() {
    return [
      { text: this.$t(this.$ts.paymentMethod.Cash), value: PaymentMethod.Cash },
      { text: this.$t(this.$ts.paymentMethod.Card), value: PaymentMethod.Card },
      { text: this.$t(this.$ts.paymentMethod.Transfer), value: PaymentMethod.Transfer },
      { text: this.$t(this.$ts.paymentMethod.Other), value: PaymentMethod.Other },
    ];
  }

  async mounted() {
    await moneyAccountModule.getAllAccounts();
    this.initializeEntry();
  }

  @Watch("value")
  onDialogOpen(newValue: boolean) {
    if (newValue) {
      this.initializeEntry();
    }
  }

  private initializeEntry() {
    this.currentEntry = {
      amount: 0,
      customerId: this.defaultCustomerId,
      dateTime: new Date().toISOString(),
      description: "",
      sourceId: this.defaultAppointmentId || 0,
      sourceType: this.defaultAppointmentId ? LedgerEntrySourceType.Appointment : LedgerEntrySourceType.Manual,
      status: LedgerEntryStatus.Unpaid,
      type: LedgerEntryType.Payment,
      moneyAccountId: null,
    };
    this.selectedMoneyAccountId = null;
    this.selectedPaymentMethod = PaymentMethod.Card;
  }

  private closeDialog() {
    this.dialog = false;
    this.currentEntry = null;
  }

  private async saveEntry(entry: ILedgerEntryDTO) {
    try {
      const entryToSave = {
        ...entry,
        moneyAccountId: this.selectedMoneyAccountId,
      };
      
      console.log("Sending entry:", entryToSave);
      const savedEntry = await ledgerEntriesModule.createManualLedgerEntry(entryToSave);
      this.$emit("save", savedEntry);
      this.closeDialog();
      snackbarModule.postSnackbarMessage(
        this.$t(this.$ts.entities.ledgerEntry) + " " + this.$t(this.$ts.actions.create)
      );
    } catch (error) {
      console.error("Error creating ledger entry:", error);
      snackbarModule.postSnackbarMessage(
        `${this.$t(this.$ts.entities.ledgerEntry)} error: ${error.response?.data || error.message}`
      );
    }
  }
}
</script>
