import {
  IInventoryItemCategoryController,
  IInventoryItemCategoryDTO,
  IPostInventoryItemCategoryDTO,
  IUpdateInventoryItemCategoryDTO,
} from "@shared/types";
import { LocalRepository } from "./LocalRepository";
import { MockStaffMemberService } from "./MockStaffMemberService";

export class MockInventoryItemCategoryService
  extends LocalRepository<IInventoryItemCategoryDTO>
  implements IInventoryItemCategoryController
{
  public static instance: MockInventoryItemCategoryService;

  private constructor() {
    const initialData: IInventoryItemCategoryDTO[] = [
      {
        id: 1,
        name: "Test Category 1",
        description: "Test Description 1",
        isActive: true,
        userId: "test-user",
        createdAt: new Date(),
        updatedAt: null,
      },
      {
        id: 2,
        name: "Test Category 2",
        description: "Test Description 2",
        isActive: true,
        userId: "test-user",
        createdAt: new Date(),
        updatedAt: null,
      },
    ];

    super(initialData);
  }

  // For testing purposes only
  public resetInstance(): void {
    MockInventoryItemCategoryService.instance = new MockInventoryItemCategoryService();
  }

  public static getInstance(): MockInventoryItemCategoryService {
    if (!MockInventoryItemCategoryService.instance) {
      MockInventoryItemCategoryService.instance = new MockInventoryItemCategoryService();
    }
    return MockInventoryItemCategoryService.instance;
  }

  public async getCategories(): Promise<IInventoryItemCategoryDTO[]> {
    return this.getAll();
  }

  public async getCategory(id: number): Promise<IInventoryItemCategoryDTO | null> {
    return this.getById(id);
  }

  public async createCategory(dto: IPostInventoryItemCategoryDTO): Promise<IInventoryItemCategoryDTO> {
    const newCategory = await this.create({
      ...dto,
      userId: "test-user",
      isActive: true,
      createdAt: new Date(),
      updatedAt: null,
    });

    if (!newCategory.name?.trim()) {
      throw new Error("Name is required");
    }

    return newCategory;
  }

  public async updateCategory(id: number, dto: IUpdateInventoryItemCategoryDTO): Promise<IInventoryItemCategoryDTO> {
    if (!dto.name?.trim()) {
      throw new Error("Name is required");
    }

    return this.update(id, {
      ...dto,
      updatedAt: new Date(),
    });
  }

  public async deleteCategory(id: number): Promise<void> {
    return this.delete(id);
  }
}
