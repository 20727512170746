<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t($ts.placeHolder.typeToSearch)"
          single-line
          hide-details
          class="mr-4"
          data-cy="category-search"
        ></v-text-field>
      </v-card-title>

      <v-dialog max-width="660" v-model="dialog">
        <DynamicForm
          v-if="currentCategory"
          :data="currentCategory"
          :entity="$t(this.$ts.entities.inventoryItemCategory)"
          :metadata="inventoryItemCategoryMetadata"
          :is-editing="isEditing"
          :is-creating="!isEditing"
          :translation-path="nonReactiveTranslations"
          @cancel="closeDialog"
          @save="saveCategory"
          @create="saveCategory"
          data-cy="category-form"
        >
        </DynamicForm>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="categories"
        :search="search"
        :loading="loading"
        :loading-text="$t($ts.loading)"
        class="elevation-1"
        data-cy="categories-table"
      >
        <template #[`item.isActive`]="{ item }">
          <v-chip :color="item.isActive ? 'success' : 'error'" text-color="white" small>
            {{ item.isActive ? $t($ts.boolean.yes) : $t($ts.boolean.no) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editCategory(item)" data-cy="edit-category-btn">mdi-pencil</v-icon>
          <v-icon small @click="deleteCategory(item)" data-cy="delete-category-btn">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { inventoryItemCategoryModule } from "@/store/modules/inventory/inventoryItemCategoryModule";
import { metadataModule } from "@/store/modules/metadata/metadataModule";
import { IInventoryItemCategoryDTO, IPostInventoryItemCategoryDTO } from "@shared/types";
import { DialogType, type IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";

@Component({
  components: {
    DynamicForm,
  },
})
export default class InventoryCategories extends Vue {
  private search = "";
  private dialog = false;
  private currentCategory: IPostInventoryItemCategoryDTO | IInventoryItemCategoryDTO | null = null;
  private isEditing = false;
  private headers = [];

  get loading() {
    return inventoryItemCategoryModule.loading;
  }

  get categories() {
    return inventoryItemCategoryModule.categories;
  }

  get inventoryItemCategoryMetadata() {
    return metadataModule.inventoryItemCategoryMetadata;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.inventoryItemCategoryFormDTO });
  }

  private openCreateDialog() {
    this.isEditing = false;
    this.currentCategory = {
      name: "",
      description: "",
      isActive: true,
    };
    this.dialog = true;
  }

  private editCategory(item: IInventoryItemCategoryDTO) {
    this.isEditing = true;
    this.currentCategory = { ...item };
    this.dialog = true;
  }

  private closeDialog() {
    this.dialog = false;
    this.currentCategory = null;
    this.isEditing = false;
  }

  private deleteCategory(item: IInventoryItemCategoryDTO) {
    const dialog: IDialog = {
      text: this.$t(this.$ts.genericEntityDialog.deleteConfirmation, {
        entity: this.$t(this.$ts.entities.inventoryItemCategory),
      }).toString(),
      type: DialogType.Choice,
      action: () => this.deleteCategoryConfirm(item.id),
    };
    dialogModule.addToDialogQueue(dialog);
  }

  private async deleteCategoryConfirm(id: number) {
    await inventoryItemCategoryModule.deleteCategory(id);
  }

  private async saveCategory(category: IPostInventoryItemCategoryDTO) {
    try {
      if (this.isEditing && (this.currentCategory as IInventoryItemCategoryDTO)?.id) {
        await inventoryItemCategoryModule.updateCategory({
          id: (this.currentCategory as IInventoryItemCategoryDTO).id,
          category: { ...category, isActive: (this.currentCategory as IInventoryItemCategoryDTO).isActive },
        });
      } else {
        await inventoryItemCategoryModule.createCategory(category);
      }
    } catch (error) {
    } finally {
      this.closeDialog();
    }
  }

  async created() {
    this.headers = [
      { text: this.$t(this.$ts.dtos.inventoryItemCategoryFormDTO.name.name), value: "name" },
      { text: this.$t(this.$ts.dtos.inventoryItemCategoryFormDTO.description.name), value: "description" },
      { text: this.$t(this.$ts.fields.status), value: "isActive" },
      { text: this.$t(this.$ts.fields.actions), value: "actions", sortable: false },
    ];
    await Promise.all([inventoryItemCategoryModule.getCategories()]);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .v-chip {
    font-size: 12px;
  }
}

.v-card-title {
  .v-text-field {
    max-width: 300px;
  }
}
</style>
