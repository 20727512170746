import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { InventoryService } from "@/services/api/InventoryService";
import { IInventoryItemDTO, IPostInventoryItemDTO, IUpdateInventoryItemDTO, IAddStockDTO } from "@shared/types";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class InventoryModule extends VuexModule {
  private inventoryService = InventoryService.getInstance();

  public inventoryItems: IInventoryItemDTO[] = [];
  public loading: boolean = false;

  // constructor(store: Store<any>) {
  //   super({ store, name: "inventoryModule" });
  //   this.inventoryService = new InventoryService();
  // }

  @Action
  async getAllItems(): Promise<void> {
    this.loading = true;
    try {
      this.inventoryItems = await this.inventoryService.getAll();
    } finally {
      this.loading = false;
    }
  }

  @Action
  async createItem(item: IPostInventoryItemDTO): Promise<IInventoryItemDTO> {
    this.loading = true;
    try {
      const newItem = await this.inventoryService.create(item);
      await this.getAllItems();
      return newItem;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async updateItem(payload: { id: number; item: IUpdateInventoryItemDTO }): Promise<IInventoryItemDTO> {
    this.loading = true;
    try {
      const updatedItem = await this.inventoryService.update(payload.id, payload.item);
      await this.getAllItems();
      return updatedItem;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async deleteItem(id: number): Promise<void> {
    this.loading = true;
    try {
      await this.inventoryService.delete(id);
      await this.getAllItems();
    } finally {
      this.loading = false;
    }
  }

  @Action
  async uploadItemImage(payload: { itemId: number; formData: FormData }): Promise<string> {
    this.loading = true;
    try {
      const imageUrl = await this.inventoryService.uploadItemPicture(payload.formData, payload.itemId);
      await this.getAllItems();
      return imageUrl;
    } finally {
      this.loading = false;
    }
  }

  @Action
  async addStock(stockData: IAddStockDTO): Promise<IInventoryItemDTO> {
    this.loading = true;
    try {
      const updatedItem = await this.inventoryService.addStock(stockData);
      await this.getAllItems();
      return updatedItem;
    } finally {
      this.loading = false;
    }
  }
}

export const inventoryModule = new InventoryModule({ store, name: "inventoryModule" });
