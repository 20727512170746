<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "custom-input",
})
export default class CustomInput extends Vue {
  @Prop(String) public inputType: string;
  @Prop(String) public placeholderText: string;
  @Prop(String) public value: string;
  @Prop(Number) public width: number;
  @Prop(Number) public height: number;

  public defaultWidth: number = 100;
  public defaultHeight: number = 45;

  public get calculateWidth(): number {
    if (this.width === undefined) {
      return this.defaultWidth;
    } else {
      return this.width;
    }
  }

  public get calculateHeight(): number {
    if (this.height === undefined) {
      return this.defaultHeight;
    } else {
      return this.height;
    }
  }

  created() {}
}

//Props
</script>
<template>
  <div>
    <label>
      <input
        :style="{ width: calculateWidth + '%' }"
        :type="inputType"
        @input="$emit('input', $event.target)"
        :value="value"
        :placeholder="placeholderText"
      />
    </label>
  </div>
</template>

<style lang="scss" scoped>
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  float: left;
  height: 45px;
  padding: 25px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 4px 0px;
  border: none;
  border-radius: 4px;
  font-size: 19px;
  -webkit-box-shadow: 0px 0px 1px 1px #2d2d2d2d;
  box-shadow: 0px 0px 1px 1px #2d2d2d2d;
}
</style>
