<template>
  <div>
    <CompanyInfoField
      :label="$t($ts.invoiceSettings.kidNumber.title)"
      :description="$t($ts.invoiceSettings.kidNumber.description)"
    >
      <p>{{ $t($ts.invoiceSettings.kidNumber.agreementNote) }}</p>

      <v-switch
        v-model="useKID"
        color="#56dcb6"
        :label="booleanToNorwegian(useKID)"
        @change="saveChanges('useKID', useKID)"
      ></v-switch>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.invoiceSettings.kidNumber.lengthTitle)"
      :description="$t($ts.invoiceSettings.kidNumber.lengthDescription)"
    >
      <v-text-field
        solo
        v-model="kidLength"
        :disabled="!useKID"
        :label="$t($ts.invoiceSettings.kidNumber.kidLength)"
        type="number"
        @blur="saveChanges('kidLength', kidLength)"
      ></v-text-field>
    </CompanyInfoField>

    <CompanyInfoField
      :label="$t($ts.invoiceSettings.kidNumber.modTitle)"
      :description="$t($ts.invoiceSettings.kidNumber.modDescription)"
    >
      <v-radio-group
        v-model="kidMod"
        :mandatory="true"
        :multiple="false"
        :readonly="!useKID"
        :disabled="!useKID"
        @change="saveChanges('kidMod', kidMod)"
      >
        <v-radio :value="0" label="MOD 10"></v-radio>
        <v-radio :value="1" label="MOD 11"></v-radio>
      </v-radio-group>
    </CompanyInfoField>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { IUserDTO } from "@shared/types";
import { IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class KidSettings extends Vue {
  @Prop({ required: true }) user!: IUserDTO;

  useKID: boolean = false;
  kidLength: number = -1;
  kidMod: number = -1;

  created() {
    this.useKID = this.user.useKID;
    this.kidLength = this.user.kidLength;
    this.kidMod = this.user.kidMod;
  }

  async saveChanges(field: string, value: any) {
    if (field === "kidLength" && (value > 25 || value < 2)) {
      let dialog: IDialog = {
        text: this.$t(this.$ts.invoiceSettings.kidNumber.invalidKidLength).toString(),
      };
      dialogModule.addToDialogQueue(dialog);
      return;
    }

    const payload: Partial<IUserDTO> = {
      [field]: value,
    };
    await userModule.updateUser(payload);
    dialogModule.addToDialogQueue({
      text: this.$t(this.$ts.messages.updated).toString(),
    });
  }

  booleanToNorwegian(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.yes).toString() : this.$t(this.$ts.boolean.no).toString();
  }
}
</script>
@/store/dialogModule@/store/dialog @/store/modules/dialogModule/dialogModule
