<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { IAppointmentTypeDTO, IStaffMemberDTO } from "@shared/types";
import { Prop, Watch } from "vue-property-decorator";
import Loader from "../../../global/Loader.vue";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { appointmentsModule } from "@/store/modules/appointments/appointmentModule";
import { serviceModule } from "@/store/modules/serviceModule/serviceModule";

@Component({
  name: "StaffMemberServicesSettings",
  components: { Loader },
})
export default class StaffMemberServicesSettings extends Vue {
  @Prop({}) staffMember!: IStaffMemberDTO;

  public loading: boolean = false;
  public error: string | null = null;
  public localStaffMember: IStaffMemberDTO | null = null;

  public async getAppointmentTypes() {
    return await serviceModule.getAppointmentTypes();
  }

  public async getStaffMembers() {
    return await staffMemberModule.getStaffMembers();
  }

  get appointmentType(): IAppointmentTypeDTO[] {
    return serviceModule.appointmentTypes;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  @Watch("staffMember")
  async onStaffMemberChange(newStaffMember: IStaffMemberDTO) {
    if (newStaffMember) {
      this.loading = true;
      this.localStaffMember = newStaffMember;

      try {
        // Load appointment types
        await this.getAppointmentTypes();
      } catch (err) {
        this.error = err.message || "Failed to load staff member services";
        console.error("Error loading staff member services:", err);
      } finally {
        this.loading = false;
      }
    }
  }

  async mounted() {
    try {
      this.loading = true;

      // Initialize staff member
      if (this.staffMember) {
        this.localStaffMember = this.staffMember;
      } else {
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await this.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find((staff) => staff.id == Number(staffId));

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }
      }

      // Load appointment types
      await this.getAppointmentTypes();
    } catch (err) {
      this.error = err.message || "Failed to load staff member services";
      console.error("Error loading staff member services:", err);
    } finally {
      this.loading = false;
    }
  }

  getName(service: IAppointmentTypeDTO): string {
    return service?.title || "";
  }

  staffMemberHasService(service: IAppointmentTypeDTO): boolean {
    if (!this.localStaffMember?.services) return false;

    const foundService = this.localStaffMember.services.find((x) => {
      return x.id == service.id;
    });
    return foundService != null;
  }

  async onServiceSwitch(service: IAppointmentTypeDTO, event: boolean): Promise<void> {
    if (!this.localStaffMember) return;

    try {
      const payload = {
        serviceId: service.id,
        staffMemberId: this.localStaffMember.id,
        enabled: event,
      };
      await staffMemberModule.editServiceStateOnStaffMember(payload);
    } catch (err) {
      this.error = "Failed to update service";
      console.error("Error updating service:", err);
    }
  }
}
</script>

<template>
  <div>
    <v-col>
      <div v-if="error" class="text-center error--text mb-4">
        {{ error }}
      </div>

      <v-list v-if="!loading && !error && appointmentType != null && localStaffMember">
        <v-list-item v-for="service in appointmentType" :key="service.id">
          <v-list-item-content>
            <h2>{{ getName(service) }}</h2>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              @change="onServiceSwitch(service, $event)"
              :input-value="staffMemberHasService(service)"
              :disabled="loading"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <loader v-else-if="loading"></loader>
    </v-col>
  </div>
</template>

<style scoped></style>
