<template>
  <div class="header">
    <img style="width: 40px" @click="navigateHome" src="/img/Availify logo Only 100x100.png" alt="logo" />
    <div class="login-btn">
      <slot><!-- Login btn --></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "FrontPageHeader",
})
export default class FrontPageHeader extends Vue {
  navigateHome(): void {
    this.$router.push("/").catch(() => {});
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background: white;
  float: left;
  img:first-child {
    margin-top: 15px;
    width: 141px;
    float: left;
    margin-left: 20px;
    cursor: pointer;
  }
  .login-btn {
    float: right;
    margin-top: -6px;
    font-weight: bold;
    color: #333333;
    margin-right: 29px;
    font-size: 18px;
    cursor: pointer;
    button {
      border-radius: 20px;
      border: 2px solid #333333;
      margin-top: 26px;
      padding: 5px 22px 5px 33px;
      transition: 0.3s;
      box-shadow: 3px 3px 10px 0px #cacaca;

      &:hover {
        transition: 0.3s;
      }
      img {
        width: 20px;
        margin-top: 0px;
        height: auto;
        float: left;
        position: absolute;
      }
    }
  }
}
</style>
