<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { logout } from "../../Utilities/authConfig";
import { DialogType, IDialog } from "@/store/dialog";
import firebaseAuth from "firebase/app";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { firebaseUserModule } from "@/store/modules/firebaseUser/firebaseUserModule";

@Component({
  name: "PersonalAccountSettings",
  components: {},
})
export default class PersonalAccountSettings extends Vue {
  created() {}

  getRoleDisplayName(role) {
    if (role == 0) {
      return this.$t(this.$ts.role.administrator).toString();
    }
    if (role == 1) {
      return this.$t(this.$ts.role.user).toString();
    }
  }

  logoutConfirm() {
    let dialog: IDialog = {
      text: this.$t(this.$ts.logoutConfirm).toString(),
      action: logout,
      header: this.$t(this.$ts.logout).toString(),
      type: DialogType.Choice,
    };

    dialogModule.addToDialogQueue(dialog);
  }

  public forgot(): void {
    let dialog: IDialog = {
      type: DialogType.Choice,
      text: this.$t(this.$ts.forgotPassword, {
        email: firebaseUserModule.currentFirebaseUser.email,
      }).toString(),
      action: this.forgotYes,
    };
    dialogModule.addToDialogQueue(dialog);
  }

  forgotYes() {
    let vm = this;
    firebaseAuth
      .auth()
      .sendPasswordResetEmail(firebaseUserModule.currentFirebaseUser.email)
      .then(function () {
        dialogModule.addToDialogQueue({
          text: vm.$t(vm.$ts.passwordResetEmailSent).toString(),
        });
      })

      .catch(function (error) {
        dialogModule.addToDialogQueue({ text: error.message });
      });
  }

  get firebaseUser() {
    return firebaseUserModule.currentFirebaseUser;
  }
}
</script>

<template>
  <v-card flat>
    <v-card-text> {{ $t($ts.email) }}: {{ firebaseUser.email }} </v-card-text>
    <v-card-actions>
      <v-btn @click="logoutConfirm"><v-icon>subdirectory_arrow_left</v-icon>{{ $t($ts.logout) }}</v-btn>
      <v-btn @click="forgot"><v-icon>mdi-lock-reset</v-icon>{{ $t($ts.changePassword) }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>
