<template>
  <div class="statistics-container" :class="{ 'is-loading': isLoading }">
    <!-- Statistics Cards Row -->
    <v-row>
      <!-- Appointments Card -->
      <v-col cols="12" md="6">
        <v-card class="stat-card">
          <v-card-title class="headline">{{ $t("entities.appointment") }}</v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.appointments.thisMonth") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="60"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ statistics?.appointmentsThisMonth || 0 }}</template>
                </div>
              </div>
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.appointments.total") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="60"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ statistics?.appointmentsTotal || 0 }}</template>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Money Spent Card -->
      <v-col cols="12" md="6">
        <v-card class="stat-card">
          <v-card-title class="headline">{{ $t("customerStatistics.moneySpent.title") }}</v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.moneySpent.thisMonth") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="120"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ formatCurrency(statistics?.servicesMoneyThisMonth || 0) }}</template>
                </div>
              </div>
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.moneySpent.total") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="120"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ formatCurrency(statistics?.servicesMoneyTotal || 0) }}</template>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Customer Ranking Card -->
      <v-col cols="12" md="6">
        <v-card class="ranking-card">
          <v-card-title class="headline">{{ $t("customerStatistics.ranking.title") }}</v-card-title>
          <v-card-text>
            <div class="ranking-content">
              <div class="ranking-item">
                <div class="ranking-label">{{ $t("customerStatistics.ranking.thisYear") }}</div>
                <div class="ranking-value">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="40"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>#{{ statistics?.ranking.rankThisYear || "-" }}</template>
                </div>
                <div class="ranking-subtext">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="120"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>
                    {{
                      $t("customerStatistics.ranking.ofCustomers", { count: statistics?.ranking?.totalCustomers || 0 })
                    }}
                  </template>
                </div>
              </div>
              <div class="ranking-item">
                <div class="ranking-label">{{ $t("customerStatistics.ranking.overall") }}</div>
                <div class="ranking-value">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="40"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>#{{ statistics?.ranking.rankAllTime || "-" }}</template>
                </div>
                <div class="ranking-subtext">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="120"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>
                    {{
                      $t("customerStatistics.ranking.ofCustomers", { count: statistics?.ranking?.totalCustomers || 0 })
                    }}
                  </template>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Products Sold Card -->
      <v-col cols="12" md="6">
        <v-card class="products-card">
          <v-card-title class="headline">{{ $t("entities.inventoryItem") }}</v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.products.thisMonth") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="60"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ statistics?.productsSoldThisMonth || 0 }}</template>
                </div>
              </div>
              <div>
                <div class="subtitle-1">{{ $t("customerStatistics.products.total") }}</div>
                <div class="display-1">
                  <v-skeleton-loader
                    v-if="isLoading"
                    type="text"
                    width="60"
                    class="skeleton-loader"
                    :loading="isLoading"
                  />
                  <template v-else>{{ statistics?.productsSoldTotal || 0 }}</template>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Charts Row -->
    <v-row class="mt-4">
      <!-- Services Pie Chart -->
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t("customerStatistics.charts.mostUsedServices") }}</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              v-if="isLoading"
              type="image"
              height="300"
              class="skeleton-loader chart-skeleton"
              :loading="isLoading"
            />
            <services-chart
              v-else-if="statistics?.topServices"
              :chart-data="servicesChartData"
              :options="pieChartOptions"
              style="height: 300px"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Products Pie Chart -->
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t("customerStatistics.charts.mostPurchasedProducts") }}</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              v-if="isLoading"
              type="image"
              height="300"
              class="skeleton-loader chart-skeleton"
              :loading="isLoading"
            />
            <products-chart
              v-else-if="statistics?.topProducts"
              :chart-data="productsChartData"
              :options="pieChartOptions"
              style="height: 300px"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Spending History Chart -->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ $t("customerStatistics.charts.spendingHistory") }}</v-card-title>
          <v-card-text>
            <v-skeleton-loader
              v-if="isLoading"
              type="image"
              height="300"
              class="skeleton-loader chart-skeleton"
              :loading="isLoading"
            />
            <spending-chart
              v-else-if="statistics?.monthlySpending"
              :chart-data="spendingChartData"
              :options="spendingChartOptions"
              style="height: 300px"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { userModule } from "@/store/modules/user/userModule";
import ServicesChart from "./charts/ServicesChart.vue";
import ProductsChart from "./charts/ProductsChart.vue";
import SpendingChart from "./charts/SpendingChart.vue";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { ICustomerDetailedStatisticsDTO } from "@shared/types";
import { CurrencyFormatter } from "@/Utilities/CurrencyFormatter";

@Component({
  name: "CustomerStatistics",
  components: {
    ServicesChart,
    ProductsChart,
    SpendingChart,
  },
})
export default class CustomerStatistics extends Vue {
  @Prop({ required: true }) customerId!: number;

  statistics: ICustomerDetailedStatisticsDTO | null = null;
  isLoading = false;

  async mounted() {
    this.isLoading = true;
    this.statistics = null; // Clear existing data while loading
    try {
      this.statistics = await customersModule.getDetailedCustomerStats(this.customerId);
    } finally {
      this.isLoading = false;
    }
  }

  get user() {
    return userModule.user;
  }

  get servicesChartData() {
    if (!this.statistics.topServices) return null;

    return {
      labels: this.statistics.topServices.map((s: any) => s.serviceName),
      datasets: [
        {
          data: this.statistics.topServices.map((s: any) => s.usageCount),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        },
      ],
    };
  }

  get productsChartData() {
    if (!this.statistics.topProducts) return null;

    return {
      labels: this.statistics.topProducts.map((p: any) => p.productName),
      datasets: [
        {
          data: this.statistics.topProducts.map((p: any) => p.quantity),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        },
      ],
    };
  }

  get spendingChartData() {
    if (!this.statistics.monthlySpending) return null;

    return {
      labels: this.statistics.monthlySpending.map((m: any) => `${m.year}-${m.month}`),
      datasets: [
        {
          label: "Monthly Spending",
          data: this.statistics.monthlySpending.map((m: any) => m.totalSpent),
          fill: true,
          borderColor: "#866afe",
          backgroundColor: "rgba(134, 106, 254, 0.1)",
          tension: 0.4,
        },
      ],
    };
  }

  get pieChartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "right" as const,
        },
      },
    };
  }

  get spendingChartOptions() {
    const self = this;
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value: number) => this.formatCurrency(value),
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => this.formatCurrency(context.raw),
          },
        },
      },
    };
  }

  formatCurrency(value: number): string {
    if (value == null) value = 0;
    return CurrencyFormatter.format(value, this.$i18n.locale);
  }
}
</script>

<style lang="scss" scoped>
.statistics-container {
  padding: 16px;

  &.is-loading {
    .skeleton-loader {
      border-radius: 4px;

      &.chart-skeleton {
        border-radius: 8px;
      }
    }
  }
}

.stat-card {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  height: 100%;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }
}

.stat-label {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  line-height: 1.2;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 8px;
  line-height: 1;
}

.ranking-card,
.products-card {
  height: 100%;

  .ranking-content {
    display: flex;
    justify-content: space-around;
    padding: 16px 0;
  }

  .ranking-item {
    text-align: center;
  }

  .ranking-label {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
  }

  .ranking-value {
    font-size: 2rem;
    font-weight: bold;
    color: #866afe;
  }

  .ranking-subtext {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4px;
  }
}

.v-icon {
  font-size: 1.5rem;
}

.headline {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
}

// Skeleton loader customization
::v-deep .v-skeleton-loader__text {
  border-radius: 4px !important;
  height: 24px !important;

  &.chart-skeleton {
    height: 300px !important;
  }
}

::v-deep .v-skeleton-loader__image {
  border-radius: 8px !important;
}

.skeleton-loader {
  &.v-skeleton-loader--is-loading {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
</style>
