/* eslint-disable */
// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation } from "vuex-class";
import { mutationStringAppointments } from "@/store/appointments/appointmentModule";

export interface workHour {
  hour: string;
  isBooked: boolean;
  isWorking: boolean;
}
const NOW = new Date();

@Component({})
export default class nCalendar extends Vue {
  /*********** CALENDAR DATA  ************/

  public inst_date: Date = NOW;
  public CURR: Date | null = null;

  //Used my Statistics.vue only. Totally unnecessary
  public get currYear(): number {
    return this.inst_date.getFullYear();
  }

  public get currMonth(): number {
    return this.inst_date.getMonth();
  }
}
