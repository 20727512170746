<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { IEmailListEntryDTO, IStaffMemberDTO, IUserDTO } from "@shared/types";
import { formatDateStringHour } from "@/Utilities/dateUtility";
import { emailListModule } from "@/store/modules/emailListModule/emailListModule";
import { userModule } from "@/store/modules/user/userModule";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";

@Component({
  name: "EmailListSettings",
})
export default class EmailListSettings extends Vue {
  public activateEmailList: boolean = false;
  public isLoading: boolean = false;

  public headers = [
    { text: this.$t(this.$ts.email), sortable: true, value: "email" },
    { text: this.$t(this.$ts.name), sortable: true, value: "name" },
    { text: this.$t(this.$ts.signedUp), sortable: true, value: "signupDate" },
  ];

  async created() {
    this.activateEmailList = userModule.user.activateEmailList;
    this.isLoading = true;
    await emailListModule.getEmailList();
    this.isLoading = false;
  }

  async saveEmailListSetting(value: boolean): Promise<void> {
    try {
      let update: Partial<IUserDTO> = {
        activateEmailList: value,
      };
      await userModule.updateUser(update);
    } catch (error) {
      console.error(error);
      this.activateEmailList = userModule.user.activateEmailList;
    }
  }

  formatDateStringHour(date: Date): string {
    return formatDateStringHour(date);
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on).toString() : this.$t(this.$ts.boolean.off).toString();
  }

  get emailList(): IEmailListEntryDTO[] {
    return emailListModule.emailList;
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-card flat width="100%" height="100%">
            <v-card-text>
              <h2>{{ $t($ts.newsletter) }}</h2>
            </v-card-text>
            <v-card-text>
              <h3>{{ $t($ts.askCustomersToSignUpForNewsletter) }}</h3>
              <v-switch
                v-model="activateEmailList"
                color="#56dcb6"
                :label="booleanToOnOff(activateEmailList)"
                @change="saveEmailListSetting($event)"
              ></v-switch>
            </v-card-text>

            <v-card-text>
              <h3>{{ $t($ts.subscribers) }}</h3>
            </v-card-text>
            <v-card-text>
              <v-data-table
                v-if="!isLoading"
                :headers="headers"
                :items="emailList"
                :items-per-page="10"
                :single-select="false"
              >
                <template v-slot:no-data>
                  {{ $t($ts.noSubscribersYet) }}
                </template>

                <template v-slot:item.signupDate="{ item }">
                  <span>{{ formatDateStringHour(item.signupDate) }}</span>
                </template>
              </v-data-table>
              <v-data-table v-else loading :loading-text="$t($ts.loading)"></v-data-table>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
