import { EventBaseService } from "./Base/EventBaseService";
import { INotificationController, INotificationDTO } from "@shared/types";
import { Feature, EventType, Status } from "./Events/ServiceEvents";

export class NotificationService extends EventBaseService implements INotificationController {
  private static instance: NotificationService;
  private controller = "Notification";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public async getNotifications() {
    return this.http.sendRequest<INotificationDTO[]>(INotificationController.GetNotificationsRoute);
  }

  public async getNotification(notificationId: number) {
    return this.http.sendRequest<INotificationDTO>(INotificationController.GetNotificationRoute, {
      params: { notificationId },
    });
  }

  public async getNotificationsForCurrentUser() {
    return this.http.sendRequest<INotificationDTO[]>(INotificationController.GetNotificationsForCurrentUserRoute);
  }

  public async markNotifcationAsRead(notificationId: number) {
    return this.withEventEmission(Feature.Notification, EventType.Updated, () =>
      this.http.sendRequest<void>(INotificationController.MarkNotifcationAsReadRoute, { params: { notificationId } })
    );
  }

  public async markAllNotifcationsAsRead() {
    return this.withEventEmission(Feature.Notification, EventType.Updated, () =>
      this.http.sendRequest<void>(INotificationController.MarkAllNotifcationsAsReadRoute)
    );
  }

  public async postNotification(notification: any) {
    return this.withEventEmission(Feature.Notification, EventType.Added, () =>
      this.http.sendRequest<void>(INotificationController.PostNotificationRoute, notification)
    );
  }

  public async deleteNotification(id: number) {
    return this.withEventEmission(Feature.Notification, EventType.Deleted, () =>
      this.http.sendRequest<void>(INotificationController.DeleteNotificationRoute, { params: { id } })
    );
  }
}
