<template>
  <div>
    <!-- MODAL EDIT CUSTOMER -->

    <invoice-sender @sent="onSent"></invoice-sender>

    <v-container class="grey lighten-5">

      <v-row>
        <v-col>
          <v-card flat>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              left
              slider-color="#56DCB6"
              class="customer-slider"
            >
              <v-tab class="customer-tab" v-for="item in tabs" :key="item.id">
                <v-icon> {{ item.icon }}</v-icon>
                <h4 class="hidden-sm-and-down">{{ item.label }}</h4>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item :key="0">
                <LedgerEntriesOverview
                  :customer-id="customer.id"
                  :customer-name="customer.firstName + ' ' + customer.lastName"
                  :customer-email="customer.emailAddress"
                />
              </v-tab-item>

              <v-tab-item :key="1">
                <CustomerStatistics :customerId="customer.id" />
              </v-tab-item>

              <v-tab-item :key="2">
                <AppointmentList :customerId="customer.id" />
              </v-tab-item>

              <v-tab-item :key="3">
                <muscle-selector :customerId="customer.id"></muscle-selector>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EditCustomer from "./EditCustomer.vue";
import InvoicesList from "../InvoicesList.vue";
import PaymentList from "../PaymentList.vue";
import PaymentCreator from "./PaymentCreator.vue";
import InvoiceSender from "../InvoiceSender.vue";
import Loader from "./Loader.vue";
import DatePicker from "./DatePicker.vue";
import { formatDateString } from "../../Utilities/dateUtility";
import { ICustomerDTO } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { statisticsModule } from "@/store/modules/statistics/statisticsModule";
import LedgerEntriesOverview from "@/components/global/LedgerEntriesOverview.vue";
import CustomerStatistics from "@/components/global/CustomerStatistics.vue";

@Component({
  name: "CustomerModal",
  components: {
    InvoiceSender,
    PaymentCreator,
    PaymentList,
    InvoicesList,
    EditCustomer,
    Loader,
    DatePicker,
    LedgerEntriesOverview,
    CustomerStatistics,
    AppointmentList: () => import("@/pages/admin/AppointmentList.vue"),
  },
})
export default class CustomerModal extends Vue {
  @Prop({}) customer;
  @Prop({}) toglgeIndex;
  @Prop({ default: false }) hideAppointments;
  @Prop({ default: true }) showCustomerName;

  get user() {
    return userModule.user;
  }

  public showJournals: boolean = true;
  public editing: boolean = false;

  public statistics: any = {};

  public isShowingDatePicker: boolean = false;
  public formType: string = "";
  public isReadingJournal: boolean = false;
  currentReadingJournal: any = {};
  public isEditingJournal: boolean = false;
  public isLockedForEditing: boolean = false;
  public currentJournalText: string = "";
  public currentJournalId: string = "";
  public isShowingNewJournalBox: boolean = false;
  public newJournal = { text: "" };
  public currentJournalCustomerId: string = "";
  public newPayment: boolean = false;
  public isLoadingPayments: boolean = false;

  public tab: number = 0;

  get tabs() {
    if (userModule.user.enablePaymentModule) {
      return [
        { label: this.$t(this.$ts.entities.ledgerEntry), id: 0, icon: "receipt" },
        {
          label: this.$t(this.$ts.sidebar.statistics),
          id: 1,
          icon: "trending_up",
        },
        {
          label: this.$t(this.$ts.sidebar.appointments),
          id: 2,
          icon: "calendar_today",
        },
        { label: this.$t(this.$ts.sidebar.journal), id: 3, icon: "assignment" },
      ];
    } else {
      return [
        { label: this.$t(this.$ts.entities.ledgerEntry), id: 0, icon: "mdi-cash-register" },
        {
          label: this.$t(this.$ts.sidebar.statistics),
          id: 1,
          icon: "trending_up",
        },
        {
          label: this.$t(this.$ts.sidebar.appointments),
          id: 2,
          icon: "calendar_today",
        },
        { label: this.$t(this.$ts.sidebar.journal), id: 3, icon: "assignment" },
      ];
    }
  }

  public secondMenuActiveIndex: number = 0;
  isAddingNewJournal: boolean = false;

  public journalHtml: string = "";
  // public invoices: Array<IInvoice> = [];

  @Watch("customerJournal")
  onCustomerJorunalChanged() {
    this.isAddingNewJournal = false;
  }

  async onFinished() {
    await this.loadPayments();
    this.newPayment = false;
  }

  onSent() {}

  formatDate(value) {
    return this.formatDateString(value);
  }

  formatDateString(date: Date) {
    return formatDateString(date);
  }

  booleanToNorwegian(value: boolean): string {
    if (value) {
      return this.$t(this.$ts.boolean.yes).toString();
    } else {
      return this.$t(this.$ts.boolean.no).toString();
    }
  }

  finished() {
    this.editing = false;
    this.$emit("finished");
  }

  public async created(): Promise<any> {
    try {
      // Fetch detailed statistics
      const response = await fetch(`/api/Customer/current/detailed-statistics/${this.customer.id}`);
      if (response.ok) {
        this.statistics = await response.json();
      }
    } catch (error) {
      console.error("Error fetching detailed statistics:", error);
    }
  }

  async loadPayments() {}

  public showDatePicker(formType: string): void {
    this.formType = formType;
    this.isShowingDatePicker = true;
  }

  public gotoEditAnamnese() {
    this.secondMenuActiveIndex = 2;
  }
  public get journalText(): string {
    return this.$t(this.$ts.customerModal.journal).toString();
  }
  public get customerDescriptionText(): string {
    return this.$t(this.$ts.customerModal.generalText).toString();
  }

  public closeNewJournalBox() {
    this.newJournal.text = "";
    this.isShowingNewJournalBox = false;
  }

  public cancelDate(): void {
    this.isShowingDatePicker = false;
  }

  formatNumber(v) {
    if (v != null) {
      return this.$t(this.$ts.currency).toString() + " " + v;
    } else {
      return this.$t(this.$ts.currency).toString() + " " + 0;
    }
  }
}
</script>
