<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <company-info-field
          :label="$t($ts.settings.paymentProvider)"
          :description="$t($ts.settings.paymentProviderDescription)"
        >
          <v-radio-group v-model="selectedProvider" @change="onProviderChange">
            <v-radio :label="$t($ts.settings.paymentProviderNone)" :value="OnlineTransactionProvider.None"></v-radio>
            <v-radio :label="$t($ts.settings.paymentProviderPayU)" :value="OnlineTransactionProvider.PayU"></v-radio>
            <v-radio
              :label="$t($ts.settings.paymentProviderStripe)"
              :value="OnlineTransactionProvider.Stripe"
              disabled
            ></v-radio>
            <v-radio
              :label="$t($ts.settings.paymentProviderVipps)"
              :value="OnlineTransactionProvider.Vipps"
              disabled
            ></v-radio>
          </v-radio-group>
        </company-info-field>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { IUserDTO, OnlineTransactionProvider } from "@shared/types";
import { userModule } from "@/store/modules/user/userModule";
@Component({
  components: {
    CompanyInfoField,
  },
})
export default class OnlineTransactionsSettings extends Vue {
  OnlineTransactionProvider = OnlineTransactionProvider;

  get selectedProvider(): OnlineTransactionProvider {
    return userModule.user.onlineTransactionProvider || OnlineTransactionProvider.None;
  }

  set selectedProvider(value: OnlineTransactionProvider) {
    // This is handled by onProviderChange
  }

  async onProviderChange(value: OnlineTransactionProvider): Promise<void> {
    await userModule.updateUser({ onlineTransactionProvider: value });
  }
}
</script>
