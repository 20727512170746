<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { notificationsModule } from "@/store/modules/notifications/notificationsModule";
import { userModule } from "@/store/modules/user/userModule";
import { eventsModule } from "@/store/modules/events/eventsModule";

@Component({
  name: "NotificationBell",
  components: {},
})
export default class NotificationBell extends Vue {
  @Prop({}) useImage;
  @Prop({}) group2;
  @Prop({}) width;
  @Prop({}) height;

  get showEventBadge() {
    return eventsModule.unseenEvents > 0;
  }

  @Watch("user")
  async onUserChanged(newVal) {
    if (newVal != null) {
      eventsModule.getUnseenEvents();
    }
  }

  get user() {
    return userModule.user;
  }

  get router() {
    return this.$router.currentRoute;
  }
  @Watch("router")
  onNewPath(newV) {
    console.log("New path: ", newV);
  }

  get hasUnseenEvents() {
    return eventsModule.unseenEvents > 0;
  }

  get unseenEvents() {
    return eventsModule.unseenEvents;
  }

  clickMe() {
    this.$emit("clicked");
    this.$router.push("/hendelser").catch(() => {});
  }
}
</script>

<template>
  <div>
    <div v-if="useImage == null || !useImage">
      <v-tooltip color="#2f1d6e" bottom>
        <template v-slot:activator="{ on: onToolTip }">
          <v-btn icon large @click="clickMe" link v-on="onToolTip">
            <v-badge offset-y="10" color="red" v-model="showEventBadge">
              <template v-slot:badge>
                <span>{{ unseenEvents }}</span>
              </template>

              <div v-if="useImage == null || !useImage">
                <v-icon large color="#573DB9" v-if="!showEventBadge">notifications</v-icon>
                <v-icon large color="#573DB9" v-else>notifications_active</v-icon>
              </div>
              <div v-else>
                <div v-if="group2 === 4">
                  <img
                    :width="width"
                    :height="height"
                    v-if="!showEventBadge"
                    :src="'../img/mobile/bell_fill.svg'"
                    alt=""
                  />
                  <img :width="width" :height="height" v-else :src="'../img/mobile/bell_fill.svg'" alt="" />
                </div>
                <div v-else>
                  <img :width="width" :height="height" v-if="!showEventBadge" :src="'../img/mobile/bell.svg'" alt="" />
                  <img :width="width" :height="height" v-else :src="'../img/mobile/bell.svg'" alt="" />
                </div>
              </div>
            </v-badge>
          </v-btn>
        </template>
        <span v-if="useImage == null || !useImage"
          ><strong>{{ $t($ts.events) }}</strong></span
        >
      </v-tooltip>
    </div>
    <div v-else>
      <v-btn icon large @click="clickMe" link class="mr-5">
        <v-badge offset-y="10" color="red" v-model="showEventBadge">
          <template v-slot:badge>
            <span>{{ unseenEvents }}</span>
          </template>

          <div v-if="useImage == null || !useImage">
            <v-icon v-if="!showEventBadge">notifications</v-icon>
            <v-icon v-else>notifications_active</v-icon>
          </div>
          <div v-else style="margin-top: 3px">
            <div v-if="group2 === 4">
              <img :width="width" :height="height" v-if="!showEventBadge" :src="'../img/mobile/bell_fill.svg'" alt="" />
              <img :width="width" :height="height" v-else :src="'../img/mobile/bell_fill.svg'" alt="" />
            </div>
            <div v-else>
              <img :width="width" :height="height" v-if="!showEventBadge" :src="'../img/mobile/bell.svg'" alt="" />
              <img :width="width" :height="height" v-else :src="'../img/mobile/bell.svg'" alt="" />
            </div>
          </div>
        </v-badge>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
/* Add this style to center the bell icon */
.v-btn {
}
</style>
../../store/modules/user
