
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import Loader from "@/components/global/Loader.vue";
    import StripePayment from "./StripePayment.vue";

    @Component({
    name:'genericdialog',
    components:{StripePayment, Loader, CustomButton}
})
export default class GenericDialog extends Vue {

      @Prop({}) model;
      @Prop({}) maxWidth;


      get enabled(){
        return this.model;
      }

      set enabled(value){
        this.$emit("exit");
      }

      get width(){
        return this.maxWidth + "px"
      }
    }
</script>

<template>
  <div>

    <v-dialog                 offset-x
                              :close-on-content-click="false" v-model="enabled" :max-width="width">
<!--      <v-btn class="closeDialog" fab small right><v-icon>close</v-icon> </v-btn>-->

<!--      <v-card color="grey lighten-4"  height="40px" style="border-radius: 0">-->
<!--          <v-btn icon @click="dialog = false" >-->
<!--            <v-icon>mdi-close</v-icon>-->
<!--          </v-btn>-->
<!--      </v-card>-->


      <slot></slot>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>

.closeDialog{
  margin-bottom: 10px;
  padding: 10px;
  position: absolute;
  top: 250px;
  z-index: 100;
  left: 360px;
}


</style>
