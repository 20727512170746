<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CompanyInfoField from "../CompanyInfoField.vue";
import Textfield from "../Textfield.vue";
import { IUserDTO } from "@shared/types";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";
import { userModule } from "@/store/modules/user/userModule";
@Component({
  name: "Register-company",
  components: {
    Textfield,
    CompanyInfoField,
  },
})
export default class RegisterCompany extends Vue {
  @Prop() public el;

  public localUser = {
    companyName: "",
    treatmentAdress: "",
  };
  public hasCompanyName: Boolean = false;

  validateFormFields(): boolean {
    if (this.user.companyName === null) {
      dialogModule.addToDialogQueue({
        text: "Du må oppgi et bedriftsnavn",
      });
      return false;
    }

    if (this.user.companyName.length < 3) {
      dialogModule.addToDialogQueue({
        text: "Bedriftsnavnet må inneholde minst 3 bokstaver",
      });
      return false;
    }

    if (this.user.treatmentAdress === null) {
      dialogModule.addToDialogQueue({
        text: "Du må oppgi en adresse",
      });
      return false;
    }

    return true;
  }

  clickHandler(): void {
    this.$emit("register-user-click", this.validateFormFields());
  }

  get user() {
    return userModule.user;
  }

  @Watch("el")
  async checkElValue(newval) {
    //Check onboarding progress
    if (newval == 2) {
      if (userModule.user.companyName !== "") {
        this.hasCompanyName = true;
      }
    }

    if (newval == 3) {
      let updatedUser: Partial<IUserDTO> = {
        companyName: userModule.user.companyName,
        treatmentAdress: userModule.user.treatmentAdress,
      }; //The order matters, unfortunately

      if (this.validateFormFields()) {
        await userModule.updateUser(updatedUser);
      }
    }
  }

  public created(): void {
    userModule.getCurrentUser();
  }
}
</script>

<template>
  <v-card color="white" style="width: 500px" v-if="el === 2">
    <v-card-text>
      <CompanyInfoField :label="$t($ts.companyInfo.companyName)">
        <Textfield v-model="user.companyName" :label="$t($ts.companyInfo.companyName)" />
      </CompanyInfoField>
      <CompanyInfoField :label="$t($ts.companyInfo.companyAddress)">
        <Textfield v-model="user.treatmentAdress" :label="$t($ts.companyInfo.companyAddress)" />
      </CompanyInfoField>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" @click="clickHandler">
        {{ $t($ts.login.next) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
