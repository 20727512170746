<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Dialog from "@/components/global/Dialog.vue";
import { IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

//* Component definition ************************************************************

@Component({
  name: "DialogQueueDisplay",
  components: { Dialog },
})

//* Class ***************************************************************************
export default class DialogQueueDisplay extends Vue {
  get getDialogQueue() {
    return dialogModule.dialogQueue;
  }

  maxWidth(dialog: IDialog) {
    if (dialog.fullScreen) {
      return "800";
    } else {
      return "290";
    }
  }
}
</script>

<template>
  <div>
    <v-dialog
      v-for="dialog in getDialogQueue"
      :key="dialog.text"
      v-model="dialog.enabled"
      persistent
      :max-width="maxWidth(dialog)"
    >
      <Dialog :dialog="dialog"></Dialog>
    </v-dialog>
  </div>
</template>

<style scoped></style>
