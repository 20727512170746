<template>
  <v-card flat class="d-flex flex-column flex-grow-1">
    <v-card-title class="primary--text d-flex align-center">
      <div class="d-flex align-center">
        <v-icon large color="primary" class="mr-2">mdi-cart-plus</v-icon>
        {{ $t($ts.orders.title) }}
      </div>
      <v-spacer></v-spacer>
      <v-btn icon large class="close-button" @click="cancel" data-cy="close-create-order">
        <v-icon size="32">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="flex-grow-1">
      <!-- Mobile Cart Summary -->
      <v-slide-y-transition>
        <v-card v-if="$vuetify.breakpoint.smAndDown && cartItems.length > 0" 
          class="mobile-cart-summary mb-4" 
          outlined
          style="position: sticky; top: 56px; z-index: 2; background: white;"
        >
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-space-between align-center">
                  <div class="text-subtitle-2">
                    <v-icon small color="primary" class="mr-1">mdi-cart</v-icon>
                    {{ getTotalQuantity }} {{ $t($ts.orders.items) }}
                  </div>
                  <div class="text-h6 primary--text">{{ formatCurrency(calculateTotalAmount) }}</div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="item in cartItems" :key="item.id" dense class="py-1">
              <v-list-item-content>
                <div class="d-flex justify-space-between align-center">
                  <div class="text-body-2">
                    {{ item.quantity }}x {{ item.name }}
                  </div>
                  <div class="text-body-2">{{ formatCurrency(item.salePrice * item.quantity) }}</div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-slide-y-transition>

      <v-stepper v-model="currentStep" class="elevation-0">
        <v-stepper-header v-if="$vuetify.breakpoint.mdAndUp" class="elevation-0 mb-6">
          <v-stepper-step :complete="currentStep > 1" step="1" :error="showStep1Error">
            {{ $t($ts.orders.steps.customerAndStaff.title) }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2" step="2" :error="showStep2Error">
            {{ $t($ts.orders.steps.items.title) }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" :error="showStep3Error">
            {{ $t($ts.orders.steps.payment.title) }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- Step 1: Customer & Staff -->
          <v-stepper-content step="1">
            <v-card flat>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card outlined class="pa-4">
                    <div class="d-flex align-center mb-4"></div>
                    <CustomerSelect v-model="selectedCustomerId" data-cy="order-customer-select" />
                  </v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card outlined class="pa-4">
                    <div class="d-flex align-center mb-4"></div>
                    <v-select
                      v-model="selectedStaffId"
                      :items="staffOptions"
                      item-text="text"
                      item-value="value"
                      :label="$t($ts.orders.staffMember)"
                      outlined
                      dense
                      clearable
                      prepend-icon="mdi-account-tie"
                      data-cy="order-staff-select"
                    />
                  </v-card>
                </v-col>

                <!-- <v-col cols="12">
                  <v-card outlined class="pa-4">
                    <div class="d-flex align-center mb-4"></div>
                    <DatePickerField
                      v-model="orderDate"
                      :label="$t($ts.orders.orderDate)"
                      :max="new Date().toISOString().substr(0, 10)"
                      data-cy="order-date-picker"
                    />
                  </v-card>
                </v-col> -->
              </v-row>
            </v-card>

            <div class="d-flex justify-end mt-4">
              <v-btn color="primary" :disabled="!isStep1Valid" @click="goToStep2" data-cy="order-next-step-btn">
                {{ $t($ts.orders.navigation.next) }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- Step 2: Items -->
          <v-stepper-content step="2">
            <v-card flat>
              <SelectSalesItems
                :inventory-items="inventoryItems"
                @update:cart="handleCartUpdate"
                data-cy="order-sales-items"
              />
            </v-card>

            <div class="d-flex justify-space-between mt-4">
              <v-btn text @click="currentStep = 1">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t($ts.orders.navigation.back) }}
              </v-btn>
              <v-btn color="primary" :disabled="!cartItems.length" @click="goToStep3" data-cy="order-next-step-btn">
                {{ $t($ts.orders.navigation.next) }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- Step 3: Payment -->
          <v-stepper-content step="3">
            <PaymentHandler
              :total-amount="calculateTotalAmount"
              :customer-id="selectedCustomerId"
              :source-type="1"
              :order-date="orderDate"
              ref="paymentHandler"
              @payment-processed="handlePaymentProcessed"
              @payment-error="handlePaymentError"
            />

            <div class="d-flex justify-space-between mt-4">
              <v-btn text @click="currentStep = 2">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t($ts.orders.navigation.back) }}
              </v-btn>
              <v-btn
                color="success"
                :loading="isCreating"
                :disabled="!$refs.paymentHandler?.isValid"
                @click="attemptCreateOrder"
                data-cy="order-create-btn"
              >
                <v-icon left>mdi-check</v-icon>
                {{ $t($ts.orders.create) }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { customersModule } from "@/store/modules/customerModule/customerModule";
import { inventoryModule } from "@/store/modules/inventory/inventoryModule";
import { ordersModule } from "@/store/modules/orders/ordersModule";
import { ledgerEntriesModule } from "@/store/modules/ledger/ledgerEntriesModule";
import CustomerSelect from "@/components/global/CustomerSelect.vue";
import SelectSalesItems from "@/components/global/SelectSalesItems.vue";
import DatePickerField from "@/components/global/DatePickerField.vue";
import PaymentHandler from "@/components/global/PaymentHandler.vue";
import { ICustomerDTO, IStaffMemberDTO, IInventoryItemDTO, IPostOrderDTO, LedgerEntryType } from "@shared/types";
import { PaymentMethod } from "@shared/types";
import { snackbarModule } from "@/store/modules/snackbar/snackbarModule";
import { userModule } from "@/store/modules/user/userModule";

interface CartItem extends IInventoryItemDTO {
  quantity: number;
}

enum PaymentStatus {
  Paid = "paid",
  PartiallyPaid = "partially_paid",
  Unpaid = "unpaid",
}

interface PaymentHandlerComponent extends Vue {
  isValid: boolean;
  selectedPaymentMethod: PaymentMethod;
  referenceNumber: string;
  processPayment(orderId?: number): Promise<void>;
}

interface PaymentResult {
  paymentMethod: PaymentMethod;
  paymentStatus: string;
  partialPaymentAmount: number;
}

@Component({
  name: "create-order",
  components: {
    CustomerSelect,
    SelectSalesItems,
    DatePickerField,
    PaymentHandler,
  },
})
export default class CreateOrder extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;

  readonly PaymentStatus = PaymentStatus;

  $refs!: {
    paymentHandler: PaymentHandlerComponent;
  };

  @Watch("visible")
  onVisibilityChange(newValue: boolean) {
    if (newValue) {
      // Reset form when dialog is opened
      this.resetForm();
      // Load necessary data
      this.loadData();
    }
  }

  private selectedCustomerId: number | null = -1;
  private selectedStaffId: number | null = null;
  private cartItems: CartItem[] = [];
  private orderDate: string = new Date().toISOString().substr(0, 10);
  private selectedPaymentMethod: PaymentMethod = PaymentMethod.Card;
  private paymentStatus: PaymentStatus = PaymentStatus.Paid;
  private partialPaymentAmount: number = 0;
  private currentStep = 1;
  private isCreating = false;
  private showStep1Error = false;
  private showStep2Error = false;
  private showStep3Error = false;

  @Watch('selectedStaffId')
  onStaffIdChange(newValue: number | null) {
    if (newValue !== null) {
      localStorage.setItem('lastSelectedStaffId', newValue.toString());
    }
  }

  get customers() {
    return customersModule.customers;
  }

  get staffMembers() {
    return staffMemberModule.staffMembers;
  }

  get inventoryItems() {
    return inventoryModule.inventoryItems;
  }

  get cartItemsByCategory(): Map<number, CartItem[]> {
    const groupedItems = new Map<number, CartItem[]>();

    this.cartItems.forEach((item: CartItem) => {
      const categoryId = item.categoryId || 0;
      if (!groupedItems.has(categoryId)) {
        groupedItems.set(categoryId, []);
      }
      const items = groupedItems.get(categoryId);
      if (items) {
        items.push(item);
      }
    });

    return groupedItems;
  }

  get cartItemsByCategoryEntries(): [number, CartItem[]][] {
    return Array.from(this.cartItemsByCategory.entries());
  }

  get staffOptions() {
    const options = [{ text: this.$t(this.$ts.orders.notSet), value: null }];

    return options.concat(
      this.staffMembers.map((staff) => ({
        text: `${staff.firstName} ${staff.lastName}`,
        value: staff.id,
      }))
    );
  }

  get isValid() {
    const valid = this.selectedCustomerId != null && this.orderDate != null && this.selectedStaffId != null;
    console.log("Validation state:", {
      customerId: this.selectedCustomerId,
      staffId: this.selectedStaffId,
      cartItems: this.cartItems.length,
      orderDate: this.orderDate,
      isValid: valid,
    });
    return valid;
  }

  get paymentMethodOptions() {
    return [
      { text: this.$t(this.$ts.paymentMethod.Cash), value: PaymentMethod.Cash },
      { text: this.$t(this.$ts.paymentMethod.Card), value: PaymentMethod.Card },
      { text: this.$t(this.$ts.paymentMethod.Transfer), value: PaymentMethod.Transfer },
      { text: this.$t(this.$ts.paymentMethod.Other), value: PaymentMethod.Other },
    ];
  }

  get calculateTotalAmount(): number {
    return this.cartItems.reduce((total, item) => total + item.salePrice * item.quantity, 0);
  }

  get getTotalQuantity() {
    return this.cartItems.reduce((total, item) => total + item.quantity, 0);
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.$i18n.locale, {
      style: "currency",
      currency: userModule.user.currency,
    }).format(value);
  }

  handleCreateNewCustomer(customerName: string) {
    // We'll implement this later when we add customer creation
    console.log("Create new customer:", customerName);
  }

  handleCartUpdate(items: CartItem[]) {
    console.log("Cart updated:", items);
    this.cartItems = items;
  }

  resetForm() {
    this.selectedCustomerId = -1;
    this.selectedStaffId = null;
    this.cartItems = [];
    this.orderDate = new Date().toISOString().substr(0, 10);
    this.selectedPaymentMethod = PaymentMethod.Card;
    this.paymentStatus = PaymentStatus.Paid;
    this.partialPaymentAmount = 0;
    this.currentStep = 1;
  }

  private async loadData() {
    await Promise.all([
      customersModule.getCustomers(),
      staffMemberModule.getStaffMembers(),
      inventoryModule.getAllItems(),
    ]);
  }

  cancel() {
    this.resetForm();
    this.$emit("cancel");
  }

  get isStep1Valid() {
    return this.orderDate != null && this.selectedStaffId != null;
  }

  get isStep3Valid() {
    if (!this.selectedPaymentMethod) return false;

    switch (this.paymentStatus) {
      case PaymentStatus.Paid:
        return true;
      case PaymentStatus.PartiallyPaid:
        return this.partialPaymentAmount > 0 && this.partialPaymentAmount < this.calculateTotalAmount;
      case PaymentStatus.Unpaid:
        return true;
      default:
        return false;
    }
  }

  get paymentStatusOptions() {
    return [
      { text: this.$t(this.$ts.orders.paymentStatus.paidInFull), value: PaymentStatus.Paid },
      { text: this.$t(this.$ts.orders.partialPayment), value: PaymentStatus.PartiallyPaid },
      { text: this.$t(this.$ts.orders.paymentStatus.unpaid), value: PaymentStatus.Unpaid },
    ];
  }

  async attemptCreateOrder() {
    if (!this.$refs.paymentHandler?.isValid) {
      this.showStep3Error = true;
      return;
    }
    
    // Create the order first
    await this.createOrder();
  }

  async handlePaymentProcessed(paymentResult: PaymentResult) {
    // Payment has been processed after order creation
    this.$emit("created");
  }

  handlePaymentError(error: Error) {
    console.error("Payment processing failed:", error);
  }

  async createOrder() {
    if (this.cartItems.length === 0) return;

    this.isCreating = true;
    try {
      const dto: IPostOrderDTO = {
        customerId: this.selectedCustomerId,
        staffId: this.selectedStaffId,
        orderDate: this.orderDate,
        paymentMethod: this.$refs.paymentHandler.selectedPaymentMethod,
        salesItems: this.cartItems.map((item) => ({
          inventoryItemId: item.id,
          quantity: item.quantity,
        })),
      };

      // Create the order first
      const createdOrder = await ordersModule.createOrder(dto);
      
      // Update the payment handler with the order reference and process payment
      if (this.$refs.paymentHandler) {
        this.$refs.paymentHandler.referenceNumber = `#${createdOrder.number.toString()}`;
        // Process payment with the created order ID
        await this.$refs.paymentHandler.processPayment(createdOrder.id);
      }
    } catch (error) {
      console.error("Failed to create order:", error);
    } finally {
      this.isCreating = false;
    }
  }

  async created() {
    // Initial data load
    await this.loadData();
    
    // Restore last selected staff member from localStorage
    const lastSelectedStaffId = localStorage.getItem('lastSelectedStaffId');
    if (lastSelectedStaffId) {
      this.selectedStaffId = parseInt(lastSelectedStaffId, 10);
    }
  }

  get getCustomerName(): string {
    const customer = this.customers.find((c) => c.id === this.selectedCustomerId);
    if (!customer) return "Not selected";
    return `${customer.firstName} ${customer.lastName || ""}`.trim();
  }

  get getStaffName(): string {
    const staff = this.staffMembers.find((s) => s.id === this.selectedStaffId);
    if (!staff) return "Not selected";
    return `${staff.firstName} ${staff.lastName || ""}`.trim();
  }

  formatDate(date: string): string {
    return new Date(date).toLocaleDateString(this.$i18n.locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  async goToStep2() {
    if (!this.isStep1Valid) {
      this.showStep1Error = true;
      return;
    }
    this.currentStep = 2;
  }

  async goToStep3() {
    if (!this.cartItems.length) {
      this.showStep2Error = true;
      return;
    }
    this.currentStep = 3;
  }

  @Watch("currentStep")
  onStepChange() {
    this.showStep1Error = false;
    this.showStep2Error = false;
    this.showStep3Error = false;
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.v-card-title {
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
}

.close-button {
  position: fixed;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  &:hover {
    background: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

.v-stepper {
  box-shadow: none;
}

.v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.v-card.outlined {
  transition: all 0.3s ease;

  &:hover {
    border-color: var(--v-primary-base);
    transform: translateY(-2px);
  }
}

.subtitle-1 {
  font-weight: 500;
}

.v-data-table {
  background: transparent !important;
}

.v-radio-group {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  margin: 0 !important;
  padding: 0 !important;

  ::v-deep .v-input__control {
    width: 100%;

    .v-input__slot {
      margin: 0;
      justify-content: space-between;

      .v-radio {
        flex: 1;
        margin: 0 8px;
        padding: 12px;
        border: 2px solid currentColor;
        border-radius: 8px;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0.85;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .v-input--selection-controls__input {
          display: none;
        }

        .v-label {
          width: 100%;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 500;
        }

        &:hover {
          opacity: 1;
          transform: translateY(-1px);
        }

        &.success {
          color: var(--v-success-base);
          background-color: var(--v-success-lighten5);

          &.v-item--active {
            background-color: var(--v-success-base);
            border-color: var(--v-success-base);
          }
        }

        &.warning {
          color: var(--v-warning-base);
          background-color: var(--v-warning-lighten5);

          &.v-item--active {
            background-color: var(--v-warning-base);
            border-color: var(--v-warning-base);
          }
        }

        &.error {
          color: var(--v-error-base);
          background-color: var(--v-error-lighten5);

          &.v-item--active {
            background-color: var(--v-error-base);
            border-color: var(--v-error-base);
          }
        }

        &.v-item--active {
          opacity: 1;
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

          .v-label {
            color: white !important;
            font-weight: 600;
          }

          .v-icon {
            color: white !important;
          }
        }
      }
    }
  }
}

.order-summary-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

.order-summary-section {
  .section-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--v-primary-base);
    padding-bottom: 8px;
    border-bottom: 2px solid var(--v-primary-base);
  }
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }

  .label {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }

  .value {
    font-weight: 500;
  }
}

.v-stepper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-stepper__items {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.v-stepper__content {
  height: auto !important;
}

.v-card__text {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.d-flex.justify-space-between.mt-4 {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px 0;
  margin-top: 0 !important;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.payment-method-field {
  max-width: 250px;
}

.payment-status-card,
.v-col.cols-12.md-4 .v-card.outlined {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}

.summary-row {
  .value {
    min-width: 200px;
    text-align: right;
  }
}

.payment-status-radio-group {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  margin: 0 !important;
  padding: 0 !important;

  ::v-deep .v-input__control {
    width: 100%;

    .v-input__slot {
      margin: 0;
      justify-content: space-between;

      .v-radio {
        flex: 1;
        margin: 0 8px;
        padding: 12px;
        border: 2px solid currentColor;
        border-radius: 8px;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0.85;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .v-input--selection-controls__input {
          display: none;
        }

        .v-label {
          width: 100%;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 500;
        }

        &:hover {
          opacity: 1;
          transform: translateY(-1px);
        }

        &.success {
          color: var(--v-success-base);
          background-color: var(--v-success-lighten5);

          &.v-item--active {
            background-color: var(--v-success-base);
            border-color: var(--v-success-base);
          }
        }

        &.warning {
          color: var(--v-warning-base);
          background-color: var(--v-warning-lighten5);

          &.v-item--active {
            background-color: var(--v-warning-base);
            border-color: var(--v-warning-base);
          }
        }

        &.error {
          color: var(--v-error-base);
          background-color: var(--v-error-lighten5);

          &.v-item--active {
            background-color: var(--v-error-base);
            border-color: var(--v-error-base);
          }
        }

        &.v-item--active {
          opacity: 1;
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

          .v-label {
            color: white !important;
            font-weight: 600;
          }

          .v-icon {
            color: white !important;
          }
        }
      }
    }
  }
}

// Remove old button styles
.payment-status-toggle,
.payment-status-btn {
  display: none;
}

.paid-status-text {
  color: #2e7d32 !important;
  font-weight: 600;
}

.partial-payment-text {
  color: #f57c00 !important;
  font-weight: 600;
}
</style>
