<template>
  <div class="notification-dropdown-wrapper">
    <div class="notifcation-header">
      <p>Varsler</p>
      <a class="mark-as-read" @click="markAsRead()">Marker alle som lest</a>
    </div>
    <router-link to="/bestillinger">
      <div class="notificationlist" v-for="(notify, i) in notifications" :key="i">
        <p class="notification-date">{{ notify.createdTime }}</p>
        <p class="notification-description">{{ notify.title }}</p>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { notificationsModule } from "@/store/modules/notifications/notificationsModule";

@Component({
  name: "notification-dropdown",
  components: {},
})
export default class NotificationDropdown extends Vue {
  //Vuex

  public markAsRead(): void {
    notificationsModule.markAllAsRead();
  }

  public created(): void {
    notificationsModule.getNotifications();
  }

  get notifications() {
    return notificationsModule.notifications;
  }
}
</script>
<style lang="scss" scoped>
a {
  color: black;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-dropdown-wrapper {
  float: right;
  min-height: 299px;
  max-height: 299px;
  background: white;
  width: 291px;
  position: absolute;
  border: 1px solid #cccccc;
  -webkit-box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  right: 0px;
  float: right;
  top: 70px;
  overflow-y: scroll;
  z-index: 1;
  .notifcation-header {
    width: 100%;
    float: left;
    height: 45px;
    border-bottom: 1px solid #dcdcdc;
    p {
      text-indent: 16px;
      margin-top: 16px;
    }
    a {
      float: right;
      margin-right: 16px;
      margin-top: -15px;
      font-size: 0.9em;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .notificationlist {
    width: 100%;
    float: left;
    height: 59px;
    text-indent: 5px;
    color: #5a5a5a !important;
    border-bottom: 1px solid #efefef;
    padding-top: 10px;
    padding-left: 46px;

    .notification-description {
      width: 100%;
      float: left;
      font-weight: bold;
      margin-top: 11px;
      margin-left: -10px;
      &::before {
        content: url("/img/invoice.png");
        float: left;
        margin-top: -8px;
        position: absolute;
        left: 2px;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
      }
    }
    p {
      margin: inherit;
    }
    .new-order {
      color: #876aff;
    }

    .notification-date {
      width: 100%;
      float: left;
      margin-top: -6px;
      color: #ccc;
      margin-left: -37px;
    }
    &:hover {
      background: #ececf3;
    }
  }
}

/* Media queries - mobile view */
@media only screen and (max-width: 1264px) {
  .notification-dropdown-wrapper {
    width: 100% !important;
    position: absolute;
    margin: 0px !important;
    min-height: calc(100% - 70px);
    overflow-y: hidden;
    z-index: 99;
    p {
      float: left;
      margin: -13px -10px;
      font-size: 1rem;
    }
    a {
      margin-top: -14px !important;
      font-size: 1rem !important;
    }
    .notifcation-header {
      p {
        margin-top: -14px;
      }
    }
  }
}
</style>
