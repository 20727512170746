<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { IDialog } from "@/store/dialog";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

//* Component definition ************************************************************

@Component({
  name: "Dialog",
  components: { CustomButton },
})

//* Class ***************************************************************************
export default class Dialog extends Vue {
  @Prop({}) dialog: IDialog;

  private buttonEnabled: boolean = false;

  mounted() {
    let vm = this;
    if (this.dialog.actionButtonDelay != 0) {
      this.buttonEnabled = false;

      setTimeout(function () {
        vm.buttonEnabled = true;
      }, vm.dialog.actionButtonDelay * 1000);
    } else {
      this.buttonEnabled = true;
    }
  }

  closeNormalDialog() {
    dialogModule.closeNormalDialog(true);
  }
  closeNormalDialogCancel() {
    dialogModule.closeNormalDialog(false);
  }

  get isPng() {
    return this.dialog.icon.includes(".png");
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-icon v-if="dialog.type === 0">info</v-icon>
      <v-icon v-if="dialog.type === 1">help</v-icon>

      <span class="title font-weight-light">{{ dialog.header }}</span>
    </v-card-title>
    <v-card-text v-if="dialog.html === false">
      {{ dialog.text }}
    </v-card-text>
    <v-card-text v-html="dialog.text" v-if="dialog.html === true"> </v-card-text>

    <div v-if="dialog.type === 0">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNormalDialog" :disabled="!buttonEnabled" data-cy="dialog-ok-btn">Ok</v-btn>
      </v-card-actions>
    </div>
    <div v-if="dialog.type === 1">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNormalDialogCancel" :disabled="!buttonEnabled" data-cy="dialog-cancel-btn">
          {{ $t($ts.dialog.cancel) }}
        </v-btn>
        <v-btn @click="closeNormalDialog" color="success" :disabled="!buttonEnabled" data-cy="dialog-confirm-btn">
          {{ $t($ts.dialog.confirm) }}
        </v-btn>
      </v-card-actions>
    </div>
    <div v-if="dialog.type === 2">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!buttonEnabled" @click="closeNormalDialogCancel" data-cy="dialog-no-btn">
          {{ $t($ts.dialog.no) }}
        </v-btn>
        <v-btn color="success" :disabled="!buttonEnabled" @click="closeNormalDialog" data-cy="dialog-yes-btn">
          {{ $t($ts.dialog.yes) }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<style scoped>
button {
  margin: 0 5px;
}
</style>
