import { BaseService } from "./Base/BaseService";
import { ISubscriptionController, ISubscriptionPlanInfoDTO } from "@shared/types";

export class SubscriptionService extends BaseService implements ISubscriptionController {
  private static instance: SubscriptionService;
  private controller = "Subscription";

  private constructor() {
    super();
  }

  public static getInstance(): SubscriptionService {
    if (!SubscriptionService.instance) {
      SubscriptionService.instance = new SubscriptionService();
    }
    return SubscriptionService.instance;
  }

  public async getSubscription(): Promise<ISubscriptionPlanInfoDTO> {
    return this.http.getAuthenticated<ISubscriptionPlanInfoDTO>(`${this.controller}/current`);
  }

  public async status(): Promise<string> {
    return this.http.getAuthenticated<string>(`${this.controller}/Status/current`);
  }

  public async statusByUserId(userId: string): Promise<string> {
    return this.http.getAuthenticated<string>(`${this.controller}/StatusOnUser?userId=${userId}`);
  }

  public async createSubscription(stripeEmail: string, stripeToken: string): Promise<void> {
    return this.http.postAuthenticated<void>(`${this.controller}/Create/current`, { stripeEmail, stripeToken });
  }

  public async cancelSubscription(cancelAtTheEndOfTheMonth: boolean): Promise<void> {
    return this.http.postAuthenticated<void>(`${this.controller}/cancel`, { cancelAtTheEndOfTheMonth });
  }

  public async getPublishKey(): Promise<string> {
    return this.http.getAuthenticated<string>(`${this.controller}/PublicKey`);
  }
}
