import { HttpService } from "./HttpService";
import { ConfigService } from "./ConfigService";

export abstract class BaseService {
  protected http: HttpService;
  protected config: ConfigService;

  constructor() {
    this.config = ConfigService.getInstance();
    this.http = HttpService.getInstance(this.config.apiUrl);
  }
}
