<script lang="ts">
"use strict";

import Vue from "vue";
import { Component } from "vue-property-decorator";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "../../components/global/ServiceCategories.vue";

@Component({
  name: "services",
  components: { ServiceCategories, TreatmentTypes },
})
export default class services extends Vue {}
</script>

<template>
  <div>
    <v-row>
      <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="service-wrapper">
          <h1>{{ $t($ts.settings.services) }}</h1>
          <treatment-types></treatment-types>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="service-wrapper">
          <h1>{{ $t($ts.settings.categories) }}</h1>
          <ServiceCategories></ServiceCategories>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.service-wrapper {
  .auto-heigth {
    height: auto !important;
  }

  button:focus {
    outline: none !important;
  }

  .minusBtn {
    padding: 6px 13px !important;
    background: #ff8e8e !important;
    transition: 0.3s;
  }

  .add-treatment-form {
    padding: 20px;
    transition: 0.3s;

    input[type="text"] {
      float: left;
      width: 100%;
      height: 35px;
      box-sizing: border-box;
      margin-top: 4px;
      border: none;
      border-bottom: 2px solid #f2f2f2;
      color: #333;
      font-size: 15px;
      &:focus {
        outline: none;
      }
    }
    button {
      margin: 15px 0px 25px 0px;
      float: left;
      background: #56dcb6;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #b1b1b1;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #b1b1b1;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #b1b1b1;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #b1b1b1;
    }
  }

  .treatment-widget {
    width: 100%;
    float: left;
    height: 350px;
    margin-top: 40px;
    // box-shadow: $boxShadow;
    border-radius: 5px;
    // border:2px solid #e6e6e6;
    .treatment-header {
      padding: 0px 20px;
      p {
        margin: 24px 0px 12px 0px;
        float: left;
        width: 100%;
        font-size: 2em;
        &::before {
          content: url("/img/massager.png");
          margin-right: 5px;
        }
      }
      button {
        margin-bottom: 10px;
      }
      .action-btn {
        position: relative;
        left: 20px;
        bottom: 18px;
        background: #56dcb6;
        color: white;
        font-weight: bold;
        border-radius: 50%;
        /* padding: 5px 13px; */
        font-size: 20px;
        float: right;
        margin-top: -62px;
        height: 40px;
        width: 40px;
      }
    }

    .treatment-list {
      width: 100%;
      float: left;
      height: 231px;
      overflow-y: scroll;
    }
    &:hover ::-webkit-scrollbar {
      // display: block;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      display: none;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 69px none #ffffff;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #2c2c2c;
    }
    ::-webkit-scrollbar-track {
      background: #ffffff;
      border: 43px none #ffffff;
      border-radius: 91px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-track:active {
      background: #ffffff;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .treatment {
      width: 100%;
      float: lefT;
      height: auto;
      // margin-top: 4px;
      background: #fff;
      border-top: 1px solid #f3f3f3;
      box-sizing: border-box;
      padding: 4px;
      p {
        float: left;
      }
      .top {
        p {
          float: left;
          margin: 10px 10px 2px 10px;
          font-size: 1.2em;
          color: #636363;
        }
        img {
          width: 15px;
          float: right;
          margin: 15px 24px;
          box-sizing: border-box;
          transition: 0.3s;
        }
        .rotate {
          transition: 0.3s;
          transform: rotate(180deg);
        }
      }

      .top,
      .bottom {
        width: 100%;
        float: left;
        height: 50%;
        transition: 0.3s;
      }
      .bottom {
        padding: 0 40px 0px 10px;
        box-sizing: border-box;
        color: #adadad;
        overflow: hidden;
      }
    }
  }
}

.service-wrapper {
  background: white;
  width: 100%;
  float: left;
  height: auto;
  -webkit-box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  border: 2px solid #e6e6e6;
  font-weight: bold;
  padding: 20px;
}
</style>
