<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { IWorkHourSplit } from "@/Utilities/workingHourUtilities";
import { DialogType, IDialog } from "@/store/dialog";
import ToolTip from "../../ToolTip.vue";
import { IAuditLogDTO, IPostAuditLogDTO, IUserDTO } from "@shared/types";

import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { auditLogModule } from "@/store/modules/auditLog/auditLogModule";
import { userModule } from "@/store/modules/user/userModule";
import { dialogModule } from "@/store/modules/dialogModule/dialogModule";

@Component({
  name: "BookingDistribution",
  components: { ToolTip, CompanyInfoField },
})
export default class BookingDistribution extends Vue {
  public buttonIndex = 0;

  private appointmentDistributionSplit: Array<IWorkHourSplit> = [
    { number: 15, isActive: false, buttonIndex: 0 },
    { number: 30, isActive: false, buttonIndex: 1 },
    { number: 60, isActive: false, buttonIndex: 2 },
    { number: 90, isActive: false, buttonIndex: 3 },
  ];

  created() {
    this.updateButtonIndex();
  }

  updateButtonIndex() {
    let index = this.appointmentDistributionSplit.find((x) => {
      return x.number === userModule.user.appointmentDistributionInMinutes;
    });
    if (index != null) {
      this.buttonIndex = index.buttonIndex;
    }

    return 0;
  }

  public setAppointmentDistribution(selectedSplit: number): void {
    let vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.bookingDistribution.confirmChange, {
        minutes: selectedSplit,
      }).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.bookingDistribution.changeTimeFormat).toString(),
      cancelAction: async function () {
        vm.updateButtonIndex();
      },
      action: async function () {
        let audit: IPostAuditLogDTO = {
          action: 1,
          type: 2,
          comment: "",
          message: vm.$t("bookingDistribution.auditChange", { minutes: selectedSplit }).toString(),
          hideForUser: false,
        };
        await auditLogModule.postAuditLog(audit);

        let update: Partial<IUserDTO> = {
          appointmentDistributionInMinutes: selectedSplit,
        };
        await userModule.updateUser(update);
        await userModule.getCurrentUser();
        vm.updateButtonIndex();
      },
    };

    dialogModule.addToDialogQueue(dialog);
  }
}
</script>

<template>
  <CompanyInfoField :label="$t($ts.bookingDistribution.timeDivision)">
    <template #description>
      <p>
        {{ $t($ts.bookingDistribution.selectTimeSlots) }}

        <br />
        <strong>15 min</strong>: <code>06:00</code> <code>06:15</code>
        <code>06:30</code>

        <br />
        <strong>30 min</strong>: <code>06:00</code> <code>06:30</code>
        <code>07:00</code>

        <br />
        <strong>60 min</strong>: <code>06:00</code> <code>07:00</code>
        <code>08:00</code>

        <br />
        <strong>90 min</strong>: <code>06:00</code> <code>07:30</code>
        <code>09:00</code>
      </p>
    </template>

    <v-btn-toggle v-model="buttonIndex" shaped mandatory color="#876AFF">
      <v-btn
        v-for="(split, i) in appointmentDistributionSplit"
        :key="i + 'appointDist'"
        @click="setAppointmentDistribution(split.number)"
      >
        {{ split.number }} min
      </v-btn>
    </v-btn-toggle>
  </CompanyInfoField>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;
</style>
