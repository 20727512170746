import { ICommissionController, ICommissionDTO, ICreateCommissionDTO } from "@shared/types";
import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType } from "./Events/ServiceEvents";



export class CommissionService extends EventBaseService implements ICommissionController {
  private static instance: CommissionService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!CommissionService.instance) {
      CommissionService.instance = new CommissionService();
    }
    return CommissionService.instance;
  }

  public async createCommission(dto: ICreateCommissionDTO): Promise<ICommissionDTO> {
    return this.withEventEmission(Feature.Finance, EventType.Added, () =>
      this.http.sendRequest<ICommissionDTO>(ICommissionController.CreateCommissionRoute, { data: dto })
    );
  }

  public async getCommissionsForDate(year: number, month: number, day: number): Promise<ICommissionDTO[]> {
    return this.http.sendRequest<ICommissionDTO[]>(ICommissionController.GetCommissionsForDateRoute, {
      params: { year, month, day },
    });
  }
} 