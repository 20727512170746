import { EventBaseService } from "./Base/EventBaseService";
import { IEventsController, IEventDTO, IPaginatedEventsResponseDTO } from "@shared/types";

export class EventsService extends EventBaseService implements IEventsController {
  private static instance: EventsService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!EventsService.instance) {
      EventsService.instance = new EventsService();
    }
    return EventsService.instance;
  }

  public async getUnSeenEvents(): Promise<number> {
    return this.http.sendRequest<number>(IEventsController.GetUnSeenEventsRoute);
  }

  public async markAsRead(): Promise<IEventDTO[]> {
    return await this.http.sendRequest<IEventDTO[]>(IEventsController.MarkAsReadRoute);
  }

  public async getEvents(
    query: Partial<typeof IEventsController.IGetEventsQuery>
  ): Promise<IPaginatedEventsResponseDTO> {
    return this.http.sendRequest<IPaginatedEventsResponseDTO>(IEventsController.GetEventsRoute, { query: query });
  }
}
