import {
  IAddStockDTO,
  IInventoryItemController,
  IInventoryItemDTO,
  IPostInventoryItemDTO,
  IUpdateInventoryItemDTO,
} from "@shared/types";
import { EventBaseService } from "./Base/EventBaseService";
import { EventType, Feature } from "./Events/ServiceEvents";

export class InventoryService extends EventBaseService implements IInventoryItemController {
  private static instance: InventoryService;

  private constructor() {
    super();
  }

  public static getInstance(): InventoryService {
    if (!InventoryService.instance) {
      InventoryService.instance = new InventoryService();
    }
    return InventoryService.instance;
  }

  public getById(id: number): Promise<IInventoryItemDTO> {
    return this.http.sendRequest<IInventoryItemDTO>(IInventoryItemController.GetByIdRoute, { params: { id } });
  }

  public getLowStockItems(): Promise<IInventoryItemDTO[]> {
    return this.http.sendRequest<IInventoryItemDTO[]>(IInventoryItemController.GetLowStockItemsRoute);
  }

  public addStock(dto: IAddStockDTO): Promise<IInventoryItemDTO> {
    return this.withEventEmission(Feature.InventoryItem, EventType.Updated, () =>
      this.http.sendRequest<IInventoryItemDTO>(IInventoryItemController.AddStockRoute, { data: dto })
    );
  }

  public uploadItemPicture(file: any, id: number): Promise<any> {
    return this.withEventEmission(Feature.Image, EventType.Uploaded, () =>
      this.http.sendRequest<string>(IInventoryItemController.UploadItemPictureRoute, { params: { id }, data: file })
    );
  }

  public getAll(): Promise<IInventoryItemDTO[]> {
    return this.http.sendRequest<IInventoryItemDTO[]>(IInventoryItemController.GetAllRoute);
  }

  public create(dto: IPostInventoryItemDTO): Promise<IInventoryItemDTO> {
    return this.withEventEmission(Feature.InventoryItem, EventType.Added, () =>
      this.http.sendRequest<IInventoryItemDTO>(IInventoryItemController.CreateRoute, { data: dto })
    );
  }

  public update(id: number, dto: IUpdateInventoryItemDTO): Promise<IInventoryItemDTO> {
    return this.withEventEmission(Feature.InventoryItem, EventType.Updated, () =>
      this.http.sendRequest<IInventoryItemDTO>(IInventoryItemController.UpdateRoute, { params: { id }, data: dto })
    );
  }

  public delete(id: number): Promise<void> {
    return this.withEventEmission(Feature.InventoryItem, EventType.Deleted, () =>
      this.http.sendRequest<void>(IInventoryItemController.DeleteRoute, { params: { id } })
    );
  }
}
