import { VuexModule, Module, Action } from "vuex-class-modules";
import { Store } from "vuex";
import { SubscriptionService } from "@/services/api/SubscriptionService";
import { ISubscriptionPlanInfoDTO } from "@shared/types";
import { ISubscriptionStatus } from "@/types/HasSubscription";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class SubscriptionModule extends VuexModule {
  private subscriptionService = SubscriptionService.getInstance();

  // State
  subscriptionStatus: ISubscriptionStatus | null = null;
  subscriptionPlan: ISubscriptionPlanInfoDTO | null = null;

  @Action
  async getSubscriptionStatus(userId?: string): Promise<string> {
    const status = userId
      ? await this.subscriptionService.statusByUserId(userId)
      : await this.subscriptionService.status();
    return status;
  }

  @Action
  async getSubscriptionPlan(): Promise<ISubscriptionPlanInfoDTO> {
    const plan = await this.subscriptionService.getSubscription();
    this.subscriptionPlan = plan;
    return plan;
  }

  @Action
  async createSubscription(payload: { stripeEmail: string; stripeToken: string }): Promise<void> {
    await this.subscriptionService.createSubscription(payload.stripeEmail, payload.stripeToken);
  }

  @Action
  async cancelSubscription(cancelAtTheEndOfTheMonth: boolean): Promise<void> {
    await this.subscriptionService.cancelSubscription(cancelAtTheEndOfTheMonth);
  }

  @Action
  async getPublishKey(): Promise<string> {
    return this.subscriptionService.getPublishKey();
  }
}

export const subscriptionModule = new SubscriptionModule({ store, name: "subscriptionModule" });
