import { EventBaseService } from "./Base/EventBaseService";
import {
  IUserController,
  IUserDTO,
  IPostInitialProfileDTO,
  IPostUserDTO,
  IUserBookingSettingsAsHoursAndMinutesDTO,
  IStaffMemberCalendarIntervalsDTO,
  IPostFreshUserDTO,
} from "@shared/types";
import { JsonPatchOperation } from "@/Utilities/ObjectUtility";

export class MockUserService implements IUserController {
  private static instance: MockUserService;
  private mockUserData: IUserDTO;
  private mockBookingSettings: IUserBookingSettingsAsHoursAndMinutesDTO;

  private constructor() {
    // Initialize with mock data
    this.mockUserData = {
      userId: "test-uid",
      staffMemberId: 1,
      companyId: 1,
      dateOfBirth: null,
      profilePictureUrl: "",
      signUpDate: new Date(),
      lastYearlySubscription: null,
      smsVerification: false,
      smsCredits: 0,
      emailVerified: true,
      companyRole: 1,
      sector: 1,
      firstName: "Test",
      lastName: "User",
      phoneNumber: "+4712345678",
      gender: "male",
      emailAddress: "test@example.com",
      companyName: "Test Company",
      treatmentAdress: "Test Address",
      ssn: 12345,
      bankAccount: "12345678901",
      organizationNumber: "123456789",
      bookingLink: "test-company",
      useKID: false,
      kidLength: 0,
      kidMod: 0,
      taxPercentage: 25,
      dueDateLength: 14,
      verificationType: 1,
      customerMergeType: 1,
      automaticCreateCustomers: false,
      showInformationBox: false,
      informationBoxText: "",
      cancellationBoxText: "",
      useCustomerAccounts: false,
      customerRequiredEmail: true,
      customerRequiredPhoneNumber: true,
      customerRequiredName: true,
      customerRequiredAddress: false,
      customerRequiredPostalCode: false,
      customerRequiredCity: false,
      customerRequiredSSN: false,
      customerRequiredBirthdate: false,
      customerRequiredGender: false,
      recieveEmailOnAppointment: true,
      emailAddressToReceive: "test@example.com",
      enablePaymentModule: false,
      maxDaysInAdvanceForBooking: 60,
      minumumHourBeforeBooking: 1,
      customerRequiredEmailOptional: false,
      customerRequiredPhoneNumberOptional: false,
      customerReceiptAndReminderMode: "both",
      recieveSMSOnAppointment: false,
      phoneNumberToReceive: "",
      recieveWhatsAppOnAppointment: false,
      whatsAppNumberToReceive: "",
      multipleServices: false,
      showServicePrice: true,
      connectedBookingLink: "",
      bookingLocationName: "",
      hideIrrelevantDaysInCalendar: true,
      intervalMinutes: 15,
      showStaffMemberInEvent: 1,
      showTimeInEvent: 1,
      showServiceNameInEvent: 1,
      activateEmailList: false,
      sendAutomaticallyReminder: true,
      sendAutomaticallyReceipt: true,
      language: "en",
      country: "NO",
      currency: "NOK",
      timeFormat: "24",
      timeZone: "Europe/Oslo",
      defaultPhoneNumberCountryCode: 47,
      customerCanSelectStaff: true,
      onlineTransactionProvider: 1,
      subscriptionType: 1,
    } as IUserDTO;

    this.mockBookingSettings = {
      mondayOpenFromMidnight: "480",
      tuesdayOpenFromMidnight: "480",
      wednesdayOpenFromMidnight: "480",
      thursdayOpenFromMidnight: "480",
      fridayOpenFromMidnight: "480",
      saturdayOpenFromMidnight: "0",
      sundayOpenFromMidnight: "0",
      mondayCloseFromMidnight: "1020",
      tuesdayCloseFromMidnight: "1020",
      wednesdayCloseFromMidnight: "1020",
      thursdayCloseFromMidnight: "1020",
      fridayCloseFromMidnight: "1020",
      saturdayCloseFromMidnight: "0",
      sundayCloseFromMidnight: "0",
    };
  }

  public static getInstance() {
    if (!MockUserService.instance) {
      MockUserService.instance = new MockUserService();
    }
    return MockUserService.instance;
  }

  public async getCurrentUser(): Promise<IUserDTO> {
    return Promise.resolve(this.mockUserData);
  }

  public async getUser(userId: string): Promise<IUserDTO> {
    return Promise.resolve(this.mockUserData);
  }

  public async getUserByUsername(companyName: string): Promise<IUserDTO> {
    return Promise.resolve(this.mockUserData);
  }

  public async putUserProfileOnly(userDto: IPostInitialProfileDTO): Promise<void> {
    return Promise.resolve();
  }

  public async patchUser(patchDoc: JsonPatchOperation[]): Promise<void> {
    return Promise.resolve();
  }

  public async putUser(userDto: IPostUserDTO): Promise<void> {
    return Promise.resolve();
  }

  public async postUser(userDTO: IPostFreshUserDTO): Promise<void> {
    return Promise.resolve();
  }

  public async deleteCompanyEmployee(id: string): Promise<void> {
    return Promise.resolve();
  }

  public async getBookingIntervalSettings(staffMemberId?: number): Promise<IStaffMemberCalendarIntervalsDTO[]> {
    return Promise.resolve([]);
  }

  public async getBookingSettings(staffMemberId?: number): Promise<IUserBookingSettingsAsHoursAndMinutesDTO> {
    return Promise.resolve(this.mockBookingSettings);
  }

  public async putBookingSettings(
    bookingSettingsDto: IUserBookingSettingsAsHoursAndMinutesDTO,
    staffMemberId?: number
  ): Promise<void> {
    return Promise.resolve();
  }

  public async postPicture(file: any): Promise<void> {
    return Promise.resolve();
  }
}
