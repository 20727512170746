<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Subscription } from "../../types/HasSubscription";
import Loader from "../global/Loader.vue";
import Checkout from "@/components/global/Checkout.vue";
import { userModule } from "@/store/modules/user/userModule";
import { subscriptionModule } from "@/store/modules/subscription/subscriptionModule";

@Component({
  name: "subscription-information",
  components: { Checkout, Loader },
})
export default class SubscriptionInformation extends Vue {
  nextPayment: string | null = null;
  isLoading: boolean = false;

  async created() {
    try {
      this.isLoading = true;
      let plan = await subscriptionModule.getSubscriptionPlan();

      if (plan) {
        this.nextPayment = plan.nextPayment as string | null;
      } else {
        this.nextPayment = null;
      }
    } catch (e) {
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  get hasCanceledSubscriptionPlan(): boolean {
    return subscriptionModule.subscriptionStatus.status === Subscription.CancelNextPayment;
  }

  get subscriptionPlan() {
    return subscriptionModule.subscriptionPlan;
  }

  get user() {
    return userModule.user;
  }
}
</script>

<template>
  <div>
    <v-card-text>
      <checkout :subscriptionExpirationDate="nextPayment"></checkout>
    </v-card-text>

    <loader v-if="isLoading"></loader>
    <v-card-text v-if="!isLoading && subscriptionPlan && user.lastYearlySubscription == null">
      <div>
        <v-divider></v-divider>

        <div>
          <h3>{{ $t($ts.subscription.yourPlan) }}</h3>
          <p>{{ subscriptionPlan.name }} - {{ subscriptionPlan.monthlyPrice }}</p>
        </div>

        <v-divider></v-divider>
        <div v-if="!hasCanceledSubscriptionPlan">
          <h3>{{ $t($ts.subscription.nextPayment) }}</h3>
          <p>{{ subscriptionPlan.nextPayment }}</p>
        </div>
        <div v-if="hasCanceledSubscriptionPlan">
          <h3 style="text-decoration: line-through">
            {{ $t($ts.subscription.nextPayment) }}
          </h3>
          <p style="text-decoration: line-through">
            {{ subscriptionPlan.nextPayment }}
          </p>
        </div>

        <v-divider></v-divider>
        <div>
          <h3>{{ $t($ts.subscription.previousPayment) }}</h3>
          <p>{{ subscriptionPlan.previousPayment }}</p>
        </div>
        <v-divider></v-divider>

        <div>
          <h3>{{ $t($ts.subscription.paymentInformation) }}</h3>
          <p>
            {{
              $t("subscription.cardEnding", {
                card: subscriptionPlan.cardInformation,
              })
            }}
          </p>
        </div>
        <v-divider></v-divider>
      </div>
    </v-card-text>
  </div>
</template>

<style lang="scss" scoped>
.subscriptionDetail {
  padding-top: 20px;
  width: 100%;
  display: inline-block;
  h3 {
    width: 50%;
    float: left;
  }
  p {
    width: 50%;
    float: left;
  }
}
.select-worktime-wrapper {
  padding: 20px;
  width: 100%;
  float: left;
  height: auto;
  box-sizing: border-box;

  label {
    color: red;
  }

  p {
    /*margin-top: 10px;*/
  }
  .subButton {
    float: right;
    position: relative;
    bottom: 10px;
  }
}
</style>
