import { IRecurringExpenseDTO, IPostOneTimeExpenseDTO, IOneTimeExpenseDTO } from "@shared/types/reinforcedtypings";
import { EventBaseService } from "./Base/EventBaseService";
import { Feature, EventType } from "./Events/ServiceEvents";
import { IExpenseController, IPostRecurringExpenseDTO, ExpenseCategory } from "@shared/types";

export class ExpensesService extends EventBaseService implements IExpenseController {
  private static instance: ExpensesService;

  private constructor() {
    super();
  }

  public getSingleOneTime(id: number): Promise<IOneTimeExpenseDTO> {
    return this.http.sendRequest<IOneTimeExpenseDTO>(IExpenseController.GetSingleOneTimeRoute, { params: { id } });
  }

  public getSingleRecurring(id: number): Promise<IRecurringExpenseDTO> {
    return this.http.sendRequest<IRecurringExpenseDTO>(IExpenseController.GetSingleRecurringRoute, { params: { id } });
  }

  public createRecurring(dto: any): Promise<IRecurringExpenseDTO> {
    return this.withEventEmission(Feature.Expense, EventType.Added, () =>
      this.http.sendRequest<IRecurringExpenseDTO>(IExpenseController.CreateRecurringRoute, { data: dto })
    );
  }
  public createOneTime(dto: IPostOneTimeExpenseDTO): Promise<IOneTimeExpenseDTO> {
    return this.withEventEmission(Feature.Expense, EventType.Added, () =>
      this.http.sendRequest<IOneTimeExpenseDTO>(IExpenseController.CreateOneTimeRoute, { data: dto })
    );
  }

  public editOneTime(id: number, dto: IPostOneTimeExpenseDTO): Promise<IOneTimeExpenseDTO> {
    return this.withEventEmission(Feature.Expense, EventType.Updated, () =>
      this.http.sendRequest<IOneTimeExpenseDTO>(IExpenseController.EditOneTimeRoute, { params: { id }, data: dto })
    );
  }
  public editRecurring(id: number, dto: any): Promise<IRecurringExpenseDTO> {
    return this.withEventEmission(Feature.Expense, EventType.Updated, () =>
      this.http.sendRequest<IRecurringExpenseDTO>(IExpenseController.EditRecurringRoute, { params: { id }, data: dto })
    );
  }

  public getAllRecurring(): Promise<any[]> {
    return this.http.sendRequest<any[]>(IExpenseController.GetAllRecurringRoute);
  }
  public getAllOneTime(): Promise<IOneTimeExpenseDTO[]> {
    return this.http.sendRequest<IOneTimeExpenseDTO[]>(IExpenseController.GetAllOneTimeRoute);
  }

  public static getInstance() {
    if (!ExpensesService.instance) {
      ExpensesService.instance = new ExpensesService();
    }
    return ExpensesService.instance;
  }

  public async deleteOneTime(id: number): Promise<any> {
    return this.withEventEmission(Feature.Expense, EventType.Deleted, () =>
      this.http.sendRequest<void>(IExpenseController.DeleteOneTimeRoute, { params: { id } })
    );
  }

  public async deleteRecurring(id: number): Promise<any> {
    return this.withEventEmission(Feature.Expense, EventType.Deleted, () =>
      this.http.sendRequest<void>(IExpenseController.DeleteRecurringRoute, { params: { id } })
    );
  }
}
