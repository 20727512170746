import { VuexModule, Module, Action } from "vuex-class-modules";
import { FirebaseUserService } from "@/services/api/FirebaseUserService";
import { IFirebaseUserDTO } from "@shared/types";
import { createPatchObject, JsonPatchOperation } from "@/Utilities/ObjectUtility";
import { Store } from "vuex";
import { userPermissionsModule } from "@/store/modules/userPermissionsModule/userPermissionsModule";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class FirebaseUserModule extends VuexModule {
  private readonly firebaseUserService: FirebaseUserService;
  private store: Store<any>;

  constructor(options: any) {
    super(options);
    this.store = options.store;
    this.firebaseUserService = FirebaseUserService.getInstance();
  }

  // State
  currentFirebaseUser: IFirebaseUserDTO | null = null;
  allFirebaseUsers: IFirebaseUserDTO[] = [];
  @Action
  async getCurrentFirebaseUser() {
    this.currentFirebaseUser = await this.firebaseUserService.getCurrentFirebaseUser();
    userPermissionsModule.setUserPermissions(this.currentFirebaseUser); //TODO: Maybe a obsever pattern
  }

  @Action
  async postFirebaseAccount(staffMember: any) {
    await this.firebaseUserService.postFirebaseAccountAsync(staffMember);
    await this.getAllFirebaseUsers();
  }

  @Action
  async patchFirebaseUser(payload: { patch: Partial<IFirebaseUserDTO>; firebaseUserId: string }) {
    let patchPayload = createPatchObject(payload.patch);
    await this.firebaseUserService.patchFirebaseUser(patchPayload, payload.firebaseUserId);
    await this.getAllFirebaseUsers();
  }

  @Action
  async deleteFirebaseUser(firebaseUserId: string) {
    await this.firebaseUserService.deleteFirebaseUser(firebaseUserId);
    await this.getAllFirebaseUsers();
  }

  @Action
  async getAllFirebaseUsers() {
    this.allFirebaseUsers = await this.firebaseUserService.getAll();
    return this.allFirebaseUsers;
  }
}

export const firebaseUserModule = new FirebaseUserModule({ store, name: "firebaseUserModule" });
