import { BaseService } from "./Base/BaseService";
import {
  IStatisticsController,
  INewCustomerStatsDTO,
  ICustomerStatDTO,
  IServiceStatDTO,
  IYearlyEarningsComparisonDTO,
  IStaffStatisticsAppointmentDTO,
  IDailyProfitDTO,
  IMonthlyProfitDTO,
  IYearlyProfitDTO,
} from "@shared/types";

export class StatisticsService extends BaseService implements IStatisticsController {
  private static instance: StatisticsService;
  private controller = "statistics";

  private constructor() {
    super();
  }
  public async downloadDailyFinancialReport(
    query: Partial<typeof IStatisticsController.IDownloadDailyFinancialReportQuery>
  ) {
    return this.http.sendRequest<any>(IStatisticsController.DownloadDailyFinancialReportRoute, {
      query: query,
      config: {
        responseType: "arraybuffer",
      },
    });
  }

  public static getInstance() {
    if (!StatisticsService.instance) {
      StatisticsService.instance = new StatisticsService();
    }
    return StatisticsService.instance;
  }

  public async getNumberOfAppointments(day: number | null, month: number, year: number, staffMemberId?: number) {
    let url = `${this.controller}/current/appointments?month=${month}&year=${year}`;
    if (day !== null) {
      url += `&day=${day}`;
    }
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<number>(url);
  }

  public async getMoneyMadeSplitByMonth(year: number, staffMemberId?: number) {
    let url = `${this.controller}/current/moneyMade/splitByMonth?year=${year}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IYearlyEarningsComparisonDTO>(url);
  }

  public async getMoneyMadeForYear(year: number): Promise<number> {
    return this.http.getAuthenticated<number>(`${this.controller}/current/moneyMade/year/${year}`);
  }

  public async getMoneyMade(month: number, staffMemberId?: number, year?: number): Promise<number> {
    let url = `${this.controller}/current/moneyMade?month=${month}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    if (year) {
      url += `&year=${year}`;
    }
    return this.http.getAuthenticated<number>(url);
  }

  public async getMoneyMadeForMonth(year: number, month: number, staffMemberId?: number): Promise<number> {
    let url = `${this.controller}/current/moneyMade?month=${month}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    if (year) {
      url += `&year=${year}`;
    }
    return this.http.getAuthenticated<number>(url);
  }

  public async getMoneyMadeForDay(year: number, month: number, day: number): Promise<number> {
    return this.http.getAuthenticated<number>(
      `${this.controller}/current/moneyMade/year/${year}/month/${month}/day/${day}`
    );
  }

  public async getMoneyMadeSplitByDay(year: number, month: number, staffMemberId?: number): Promise<IDailyProfitDTO> {
    let url = `${this.controller}/current/moneyMade/splitByDay?year=${year}&month=${month}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IDailyProfitDTO>(url);
  }

  public async getMoneyMadeSplitByMonthAndStaff(year: number) {
    return this.http.getAuthenticated<any[]>(`${this.controller}/current/moneyMade/splitByMonthAndStaff?year=${year}`);
  }

  public async getMoneyMadeSplitByDayAndStaff(year: number, month: number) {
    return this.http.getAuthenticated<any[]>(
      `${this.controller}/current/moneyMade/splitByDayAndStaff?year=${year}&month=${month}`
    );
  }

  public async getAppointmentsSplitByMonthAndStaff(year: number) {
    return this.http.getAuthenticated<any[]>(
      `${this.controller}/current/appointments/splitByMonthAndStaff?year=${year}`
    );
  }

  public async getAppointmentsSplitByDayAndStaff(year: number, month: number) {
    return this.http.getAuthenticated<any[]>(
      `${this.controller}/current/appointments/splitByDayAndStaff?year=${year}&month=${month}`
    );
  }

  public async getAppointmentsSplitByMonth(year: number, staffMemberId?: number) {
    let url = `${this.controller}/current/appointments/splitByMonth?year=${year}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IYearlyEarningsComparisonDTO>(url);
  }

  public async getAppointmentsSplitByDay(year: number, month: number, staffMemberId?: number) {
    let url = `${this.controller}/current/appointments/splitByDay?year=${year}&month=${month}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<number[]>(url);
  }

  public async newCustomers(year: number) {
    return this.http.getAuthenticated<INewCustomerStatsDTO>(`${this.controller}/current/newCustomers?year=${year}`);
  }

  public async getCustomerBookedStats(year?: number, month?: number) {
    let url = `${this.controller}/current/customerBooked`;
    if (year !== undefined) {
      url += `?year=${year}`;
      if (month !== undefined) {
        url += `&month=${month}`;
      }
    }
    return this.http.getAuthenticated<ICustomerStatDTO[]>(url);
  }

  public async getServicesBookedStats(year?: number, month?: number, staffMemberId?: number) {
    let url = `${this.controller}/current/servicesBooked`;
    const params = new URLSearchParams();

    if (staffMemberId) {
      params.append("staffMemberId", staffMemberId.toString());
    }
    if (year !== undefined && year !== -1) {
      params.append("year", year.toString());
    }
    if (month !== undefined && month !== -1) {
      params.append("month", month.toString());
    }

    const queryString = params.toString();
    if (queryString) {
      url += "?" + queryString;
    }

    return this.http.getAuthenticated<IServiceStatDTO[]>(url);
  }

  public async getMonthlyProfit(year: number, month: number, staffMemberId?: number): Promise<IMonthlyProfitDTO> {
    let url = `${this.controller}/current/profit/monthly?year=${year}&month=${month}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IMonthlyProfitDTO>(url);
  }

  public async getYearlyProfit(year: number, staffMemberId?: number): Promise<IYearlyProfitDTO> {
    let url = `${this.controller}/current/profit/yearly?year=${year}`;
    if (staffMemberId) {
      url += `&staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<IYearlyProfitDTO>(url);
  }

  public async getAllYearProfit(staffMemberId?: number): Promise<Record<number, IYearlyProfitDTO>> {
    let url = `${this.controller}/current/profit/all-years`;
    if (staffMemberId) {
      url += `?staffMemberId=${staffMemberId}`;
    }
    return this.http.getAuthenticated<Record<number, IYearlyProfitDTO>>(url);
  }
}
