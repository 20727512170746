import { BaseService } from "./Base/BaseService";
import { IInvoiceController, IInvoiceDTO, IPostInvoiceDTO, ISendInvoiceDTO } from "@shared/types";

export class InvoiceService extends BaseService implements IInvoiceController {
  private static instance: InvoiceService;

  private constructor() {
    super();
  }

  public static getInstance(): InvoiceService {
    if (!InvoiceService.instance) {
      InvoiceService.instance = new InvoiceService();
    }
    return InvoiceService.instance;
  }

  public async get(): Promise<IInvoiceDTO[]> {
    return this.http.sendRequest<IInvoiceDTO[]>(IInvoiceController.GetRoute);
  }

  public async post(dto: IPostInvoiceDTO): Promise<IInvoiceDTO> {
    return this.http.sendRequest<IInvoiceDTO>(IInvoiceController.PostRoute, { data: dto });
  }

  public async getInvoiceNumber(): Promise<number> {
    return this.http.sendRequest<number>(IInvoiceController.GetInvoiceNumberRoute);
  }

  public async changeStatus(invoiceId: number, status: string): Promise<void> {
    return this.http.sendRequest<void>(IInvoiceController.ChangeStatusRoute, {
      params: { invoiceId },
      data: { status },
    });
  }

  public async sendInvoice(dto: ISendInvoiceDTO): Promise<void> {
    return this.http.sendRequest<void>(IInvoiceController.SendInvoiceRoute, { data: dto });
  }

  public async getInvoicesForCustomer(id: number): Promise<IInvoiceDTO[]> {
    return this.http.sendRequest<IInvoiceDTO[]>(IInvoiceController.GetInvoicesForCustomerRoute, { params: { id } });
  }

  public async getInvoicesForAppointment(appointmentId: number): Promise<IInvoiceDTO[]> {
    return this.http.sendRequest<IInvoiceDTO[]>(IInvoiceController.GetInvoicesForAppointmentRoute, {
      params: { appointmentId },
    });
  }

  public async getPdf(invoiceId: number): Promise<Blob> {
    return this.http.sendRequest<Blob>(IInvoiceController.GetPdfRoute, { params: { invoiceId } });
  }
}
