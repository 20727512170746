import { EventBaseService } from "./Base/EventBaseService";
import {
  ICustomerDetailedStatisticsDTO,
  ICustomerDTO,
  ICustomersController,
  ICustomerStatisticsDTO,
  ICustomerTokenDataDTO,
} from "@shared/types";
import { Feature, EventType } from "./Events/ServiceEvents";

export class CustomerService extends EventBaseService implements ICustomersController {
  private static instance: CustomerService;
  private controller = "Customer";

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService();
    }
    return CustomerService.instance;
  }

  public async getCustomerByToken(customerToken: string, userId: string, phoneNumber: string) {
    return this.http.get<ICustomerTokenDataDTO>(
      `${this.controller}/byToken?customerToken=${customerToken}&userId=${userId}&phoneNumber=${phoneNumber}`
    );
  }

  public async getCustomerStats(customerId: number) {
    return this.http.getAuthenticated<ICustomerStatisticsDTO>(`${this.controller}/current/statistics/${customerId}`);
  }

  public async getCustomer(customerId: number) {
    return this.http.getAuthenticated<ICustomerDTO>(`${this.controller}/current/${customerId}`);
  }

  public async getCustomers() {
    return this.http.getAuthenticated<ICustomerDTO[]>(`${this.controller}/current`);
  }

  public async putCustomer(customer: ICustomerDTO) {
    return this.withEventEmission(Feature.Customer, EventType.Updated, () =>
      this.http.putAuthenticated<void>(`${this.controller}/current`, customer)
    );
  }

  public async postCustomer(customer: any) {
    return this.withEventEmission(Feature.Customer, EventType.Added, () =>
      this.http.postAuthenticated<void>(`${this.controller}/current`, customer)
    );
  }

  public async deleteCustomer(id: number) {
    return this.withEventEmission(Feature.Customer, EventType.Deleted, () =>
      this.http.deleteAuthenticated<void>(`${this.controller}/current?id=${id}`)
    );
  }
  public async getDetailedCustomerStats(customerId: number) {
    return this.http.getAuthenticated<ICustomerDetailedStatisticsDTO>(
      `${this.controller}/current/detailed-statistics/${customerId}`
    );
  }
}
