<script lang="ts">
"use strict";

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Sidebar from "@/components/global/Sidebar.vue";
import MainHeader from "@/components/global/MainHeader.vue";
import UpdateMessage from "@/components/global/UpdateMessage.vue";
import { ISubscriptionStatus, Subscription } from "../types/HasSubscription";

//* Component definition ************************************************************

@Component({
  name: "layout",
  components: { UpdateMessage, MainHeader, Sidebar },
})

//* Class ***************************************************************************
export default class Layout extends Vue {
  subscriptionStatus: ISubscriptionStatus;
  trialHasEnded: boolean = false;

  @Watch("subscriptionStatus")
  checkForChange(newValue: ISubscriptionStatus): void {
    if (newValue.status === Subscription.Active) {
      this.trialHasEnded = false;
    }
  }

  async created(): Promise<any> {}
}
</script>

<template>
  <div>
    <div class="content">
      <slot> </slot>
    </div>
  </div>
</template>

<style lang="scss">
.hasPurchaseMessage {
  margin-top: 50px;
}
.content {
  //float: left;
  //width: calc(100% - 70px);
  max-width: 1540px;
  margin: auto;
  height: auto;
  padding: 40px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 1264px) {
  .content {
    width: 98%;
    padding: 0;
    margin: 80px 1%;
  }
}
</style>
../store/modules/user
