<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "./MainHeader.vue";
import { Action, Getter } from "vuex-class";
import { getBookingUrl } from "../../Utilities/urlUtility";
import { userModule } from "@/store/modules/user/userModule";

@Component({
  name: "appointment-booking-link",
  components: { Sidebar, HeaderBar },
})
export default class AppointmentBookingLink extends Vue {
  public bookingLink: string = "";
  private isShowingBookingPage: boolean = false;

  @Prop({ default: false }) isShowingPreviewButton: boolean;

  //Vuex

  public copyLink(): void {
    navigator.clipboard.writeText(this.bookingLink).then(() => {});
  }

  gotoLink(): void {
    window.open(this.bookingLink);
  }

  private toggleBookingPage(): void {
    this.isShowingBookingPage = !this.isShowingBookingPage;
  }

  public created(): void {
    if (userModule.user === null) {
      userModule.getCurrentUser().then(() => {
        this.bookingLink = getBookingUrl(userModule.user.bookingLink);
      });
    } else {
      this.bookingLink = getBookingUrl(userModule.user.bookingLink);
    }
  }

  get currentBookingLink(): string {
    return getBookingUrl(userModule.user.bookingLink);
  }
}
</script>

<template>
  <v-card flat class="appointment-link-widget">
    <p>{{ $t($ts.appointmentBookingLinkTitle) }}</p>

    <input type="text" ref="copy" :value="currentBookingLink" />

    <v-card-actions>
      <v-btn @click="gotoLink" icon><v-icon>public</v-icon></v-btn>
      <v-btn @click="copyLink" icon><v-icon>file_copy</v-icon></v-btn>
      <button
        :class="{ exit: isShowingBookingPage }"
        class="preview"
        v-if="isShowingPreviewButton"
        @click="toggleBookingPage"
      >
        {{ isShowingBookingPage ? $t($ts.closePreview) : $t($ts.previewBookingPage) }}
      </button>
    </v-card-actions>

    <iframe v-if="isShowingBookingPage" width="100%" height="400px" :src="bookingLink"></iframe>
  </v-card>
</template>

<style lang="scss" scoped>
@use "@/styles/global" as *;

.appointment-link-widget {
  /*background: white;*/
  /*width: 100%;*/
  /*float: left;*/
  /*min-height: 150px;*/
  /*border-radius: 5px;*/
  /*padding: 25px;*/

  iframe {
    margin-top: 50px;
    box-shadow: 0px 0px 9px 1px #c5c5c5;
    border: none;
  }

  input[type="text"] {
    width: 100%;
    float: left;
    height: 45px;
    padding: 25px 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 4px 0px;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    -webkit-box-shadow: 0px 0px 1px 1px #2d2d2d2d;
    box-shadow: 0px 0px 1px 1px #2d2d2d2d;
    color: rgba(8, 46, 51, 0.34);
  }

  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    padding: 10px 25px;
    margin-left: 10px;
  }

  .preview {
    margin-left: 10px;
    text-decoration: underline;
    color: #292b2a;
    font-weight: bold;
  }
}
</style>
../../store/modules/user
