<template>
  <div v-if="!hideMobileFooter" class="hidden-xs-only">
    <v-app-bar class="header" flat fixed :style="headerStyle">
      <v-container fluid class="pa-0">
        <v-row class="align-center fill-height ma-0">
          <!-- Previous Button -->
          <v-col cols="auto" class="d-flex align-center mr-2">
            <v-btn icon @click="$emit('prev')" class="nav-btn">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>

          <!-- Month Name -->
          <v-col cols="auto" class="d-flex align-center">
            <span class="text-h6 font-weight-medium">{{ monthName }}</span>
          </v-col>

          <!-- Next Button -->
          <v-col cols="auto" class="d-flex align-center ml-2">
            <v-btn icon @click="$emit('next')" class="nav-btn">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>

          <!-- Spacer -->
          <v-spacer></v-spacer>

          <!-- Interval Select -->
          <v-col cols="auto" class="d-flex align-center px-4">
            <v-select
              v-model="intervalMinutes"
              :items="intervalOptions"
              dense
              outlined
              hide-details
              class="interval-select"
              style="max-width: 120px"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
              <template v-slot:append-inner>
                <span>min</span>
              </template>
            </v-select>
          </v-col>

          <!-- Staff Member Select -->
          <v-col v-if="hasMultipleStaff" cols="auto" class="d-flex align-center ml-4">
            <v-select
              v-model="currentStaffMember"
              :items="staffMemberOptions"
              item-value="id"
              item-text="fullName"
              style="max-width: 200px"
              dense
              outlined
              hide-details
              class="header-select"
            >
              <template v-slot:item="data">
                <v-icon left>mdi-account-supervisor-circle</v-icon>
                {{ data.item.fullName }}
              </template>
              <template v-slot:selection="data">
                <v-icon left>mdi-account-supervisor-circle</v-icon>
                {{ data.item.fullName }}
              </template>
            </v-select>
          </v-col>

          <!-- Calendar Type Select -->
          <v-col cols="auto" class="d-flex align-center ml-4">
            <v-select
              hide-details
              :items="calendarOptions"
              v-model="calendarType"
              item-value="value"
              item-text="text"
              style="max-width: 200px"
              dense
              outlined
              class="header-select"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { calendarModule } from "@/store/modules/calendarModule/calendarModule";
import { IStaffMemberDTO } from "@shared/types";
import { staffMemberModule } from "@/store/modules/staffMember/staffMemberModule";
import { layoutModule } from "@/store/modules/layout/layoutModule";

@Component({
  name: "CalendarDesktopHeader",
})
export default class CalendarDesktopHeader extends Vue {
  @Prop({ default: false }) private hideMobileFooter!: boolean;
  @Prop({ required: true }) private monthName!: string;

  get staffMemberOptions() {
    return calendarModule.staffMemberOptions;
  }

  get calendarOptions() {
    return calendarModule.calendarOptions;
  }

  get mini() {
    return layoutModule.mini;
  }

  get hasMultipleStaff() {
    return staffMemberModule.hasMultipleStaff;
  }

  get intervalMinutes() {
    return calendarModule.intervalMinutes;
  }

  get intervalOptions() {
    return [15, 30, 60, 90];
  }

  set intervalMinutes(value: number) {
    calendarModule.updateIntervalMinutes(value);
  }

  get currentStaffMember() {
    return calendarModule.currentStaffMember;
  }

  set currentStaffMember(value: number) {
    calendarModule.updateCurrentStaffMember(value);
  }

  get calendarType() {
    return calendarModule.calendarType;
  }

  set calendarType(value: string) {
    calendarModule.updateCalendarType(value);
  }

  get headerStyle() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // No header
    if (width < 1263) {
      return {
        top: "0px",
        boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
        width: "100%",
        marginLeft: "0px",
      };
    } else {
      // Has main header
      if (this.mini) {
        return {
          top: "0",
          boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
          width: "calc(100% - 80px)",
          marginLeft: "80px",
        };
      } else {
        return {
          top: "0",
          boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
          width: "calc(100% - 240px)",
          marginLeft: "240px",
        };
      }
    }
  }
}
</script>

<style scoped>
.header {
  z-index: 4;
  background-color: white;
  height: 64px;
}

.nav-btn {
  transition: background-color 0.2s;
}

.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.interval-select {
  font-size: 14px;
}

.interval-select ::v-deep .v-input__slot {
  min-height: 36px !important;
  margin-bottom: 0 !important;
}

.header-select {
  font-size: 14px;
}

.header-select ::v-deep .v-input__slot {
  min-height: 36px !important;
  margin-bottom: 0 !important;
}

.v-container {
  height: 100%;
}

.v-row {
  height: 100%;
}
</style>
