import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { AccountRoleService } from "@/services/api/AccountRoleService";
import { IAccountRoleDTO } from "@shared/types";
import { i18n } from "@/main";
import store from "@/store/store";

@Module({ generateMutationSetters: true })
class AccountRoleModule extends VuexModule {
  private readonly accountRoleService: AccountRoleService;

  constructor(options: any) {
    super(options);
    this.accountRoleService = AccountRoleService.getInstance();
  }

  // State
  roles: IAccountRoleDTO[] = [];

  @Action
  async getRoles() {
    try {
      const roles = await this.accountRoleService.getRoles();
      this.roles = roles;
      return roles;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action
  async createRole(role: IAccountRoleDTO) {
    try {
      await this.accountRoleService.createRole(role);
      await this.getRoles();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action
  async updateRole(payload: { id: number; role: IAccountRoleDTO }) {
    try {
      await this.accountRoleService.updateRole(payload.id, payload.role);
      await this.getRoles();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action
  async deleteRole(roleId: number) {
    try {
      await this.accountRoleService.deleteRole(roleId);
      await this.getRoles();
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export const accountRoleModule = new AccountRoleModule({ store, name: "accountRoleModule" });
